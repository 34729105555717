import { SelectInput } from '@movicoders/movicoders-components'
import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Colors } from '../../../../constants'
import DashboardViewModel from '../../../../viewmodels/dashboard/dashboard-view-model'

export const MapFilter: React.FC<{ vm: DashboardViewModel; }> = observer(({ vm }) => {

  return (
    <Grid container>
      <Grid item xs={12} md={12} />
      <Grid item xs={12} md={12}>
        <SelectInput
          id='mapFilterSelectInput'
          value={"A"}
          disabled={false}
          size={"small"}
          multipleSelection={false}
          options={['A', 'B', 'C', 'D', 'E', 'G']}
          colorRenderer={Colors.textPrimary}
          variant={"outlined"}
          onValueChanged={(val: string) => vm.setEnergyEfficiencyFilter(val)}
          renderer={(value: string) => value}
          label={vm.translate("report.block1.certificate")} />
      </Grid>
    </Grid>
  )
})
