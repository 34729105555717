import { Button, DialogMovicoders, IconButton, SelectInput, TypographyTheme } from "@movicoders/movicoders-components";
import { ConfirmDialogStrings } from "@movicoders/movicoders-components";
import { Icon, IconsList } from "@movicoders/movicoders-components";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import {
  CollegiateDTO,
  CommunityDTO,
  ProvinceDTO,
  TechnicianDTO,
  TechnicianDTORoleEnum,
  TechnicianDTOTypeEnum,
  TechnicianParticipationDTO,
  TownDTO,
} from "../../../clients";
import TextFieldGrid from "../../../components/text-fields/textfield-grid";
import { Colors } from "../../../constants";
import ReportViewModel from "../../../viewmodels/report/report-view-model";
import { ReportStyle } from "../report-style";
import { TTechnicianRedactors } from "../reportComponents/block0/technicianredactors/technician-redactors";
import * as RegexHelper from "../../../utils/regexHelper";

export const ProfileTechnician: React.FC<{ vm: ReportViewModel; props: TTechnicianRedactors }> = observer(({ vm, props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleOpenSnackBar = (snackKey: string, variants: undefined | "default" | "error" | "success" | "warning" | "info") => {
    enqueueSnackbar(snackKey, { variant: variants, preventDuplicate: true, key: snackKey, autoHideDuration: 3000 });
  };

  const classes = ReportStyle();
  const [newParticipation, setNewParticipation] = useState<TechnicianParticipationDTO>({});
  const [newParticipant, setNewParticipant] = useState<TechnicianDTO>({ role: TechnicianDTORoleEnum.Technician });
  const [community, setCommunity] = useState<CommunityDTO>({});
  const [province, setProvince] = useState<ProvinceDTO>({});
  const [town, setTown] = useState<TownDTO>({});
  const [technicianId, setTechnicianId] = useState("");
  const [showInvite, setShowInvite] = useState(false);
  const [showActive, setShowActive] = useState(false);
  const [technicianFound, setTechnicianFound] = useState(false);

  const InviteDialogContent = () => {
    return (
      <Grid container display={"grid"}>
        <div style={{ width: 1, height: 10 }} />
        <TypographyTheme color={Colors.textPrimary} text={vm.translate("report.block0.invite.notFound")} />
        <Grid item xs={12}>
          <TextFieldGrid
            required
            title="Nombre"
            defaultValue={vm.invitedTechnicianName}
            onChange={(event: any) => (vm.invitedTechnicianName = event.target.value)}
            id={"inviteTechnicianNameField"}
          />
          <TextFieldGrid
            required
            title="Email"
            defaultValue={vm.invitedTechnicianEmail}
            onChange={(event: any) => (vm.invitedTechnicianEmail = event.target.value)}
            id={"inviteTechnicianEmailField"}
          />
          <Button
            style={{ marginTop: 10 }}
            onClick={async () => {
              if (await vm.inviteTechnician()) {
                setShowInvite(false);
                //Show alert success
                handleOpenSnackBar(vm.translate("report.block0.invite.success"), "success");
                vm.profileTechnician = false;
              } else {
                //Show alert error
                handleOpenSnackBar(vm.translate("report.block0.invite.error"), "error");
              }
            }}
            variant="contained"
            text={vm.translate("report.profile.technician.invite")}
          />
        </Grid>
      </Grid>
    );
  };

  const dialogStrings: ConfirmDialogStrings = {
    title: vm.translate("report.block0.invite.user"),
    content: <InviteDialogContent />,
  };

  const ActiveDialogContent = () => {
    return (
      <Grid container>
        <div style={{ width: 1, height: 10 }} />
        <TypographyTheme text={vm.translate("report.block0.invite.inactive")} />
        <Grid item xs={12}>
          <Button
            onClick={async () => {
              setShowActive(false);
            }}
            variant="contained"
            text={vm.translate("report.block0.invite.accept")}
          />
        </Grid>
      </Grid>
    );
  };

  const dialogStringsActiveUser: ConfirmDialogStrings = {
    title: vm.translate("report.block0.invite.user"),
    content: <ActiveDialogContent />,
  };

  const onChangeParticipation = (value: string) => {
    setNewParticipation({ ...newParticipation, participation: +value });
  };
  const onChangeCollege = (value?: any) => {
    if (value) {
      vm.college = value;
      vm.profession = vm.translate("technician.participation.profesion." + value.college.type);
      setNewParticipation({ ...newParticipation, college: value.college.id });
    }
  };

  const handleSaveTechnician = async () => {
    if (!vm.college || !newParticipation.college) {
      handleOpenSnackBar(vm.translate("report.block0.invite.college.participation"), "error");
      return;
    }
    if (!newParticipation.participation || newParticipation.participation === 0) {
      handleOpenSnackBar(vm.translate("report.block0.invite.technician.participation"), "error");
      return;
    }
    if (newParticipant.id) {
      try {
        vm.loadingBook = true;
        await vm.bookRepository.addTechnician(vm.userBook.id!!, {
          id: newParticipant.credentials,
          participation: newParticipation.participation,
          college: newParticipation.college,
        }).then(res => {
          vm.userBook.technicians = [...vm.userBook.technicians ?? [], {
            id: newParticipant.credentials,
            participation: newParticipation.participation,
            college: newParticipation.college,
            name: newParticipant.name + " " + newParticipant?.surname,
          }];
        })
        handleOpenSnackBar(vm.translate("report.block0.invite.technician.added"), "success");
        vm.loadingBook = false;
      } catch (e) {
        vm.loadingBook = false;
        handleOpenSnackBar(vm.translate("report.block0.invite.technician.notValid"), "error");
      }
    }
    vm.profileTechnician = false;
  };

  const getProvinces = async (communityCode: string) => {
    const result = await vm.parent.communityRepository.getProvinceByCommunityCode(communityCode).catch((e) => []);
    return result;
  };

  const getTowns = async (provinceCode: string) => {
    const result = await vm.parent.provincesRepository.getAllTownByProvinceCode(provinceCode).catch((e) => []);
    return result;
  };

  const getTechnicianByIdNumber = async () => {
    let technician: TechnicianDTO | undefined;
    try {
      vm.loadingBook = true;
      technician = await vm.techniciansRepository.searchTechnician({ idNumber: technicianId });
      let exists = false;
      vm.userBook.technicians?.map((item) => {
        if (item.id === technician?.credentials) {
          handleOpenSnackBar(vm.translate("report.block0.invite.technician.already.included"), "error");
          setTechnicianFound(false);
          exists = true;
        }
        vm.loadingBook = false;
      });
      if (!exists) {
        setNewParticipant(technician);
        technician && technician.deleted === false ? setShowActive(false) : setShowActive(true);
        technician && technician.deleted === false && setTechnicianFound(true);
      }
      vm.loadingBook = false;
    } catch {
      vm.loadingBook = false;
      setTechnicianFound(false);
    }

    if (technician) {
      try {
        const community = vm.parent.communities?.find((co: CommunityDTO) => co.name === technician?.address?.community);
        const province = (await getProvinces(community?.code ?? ""))?.find((prov: ProvinceDTO) => prov.name === technician?.address?.province);
        const town = (await getTowns(province?.code ?? "")).find((t: TownDTO) => t.name === technician?.address?.town);
        if (community && province && town) {
          setCommunity(community);
          setProvince(province);
          setTown(town);
        }
      } catch { }
    } else {
      vm.invitedTechnicianEmail = "";
      vm.invitedTechnicianName = "";
      setShowInvite(true);
    }
  };

  return (
    <Grid container>
      <Grid container item xs={12} alignItems="center" style={{ marginBottom: 15, marginTop: 10 }}>
        <TextFieldGrid
          gridClassName=""
          id="profileTechnicianIdCheck"
          value={technicianId}
          title={vm.translate("report.block0.user.id")}
          error={
            !(RegexHelper.validCif.test(technicianId.toUpperCase()) || RegexHelper.CIF_REGEX.test(technicianId.toUpperCase())) || technicianId.length !== 9
          }
          gridTextField={{ xs: 6, md: 6 }}
          onChange={(evt: any) => {
            setTechnicianId(evt.target.value);
          }}
        />
        <IconButton
          id="addTechnicianSearchUser"
          icon={<Icon element={IconsList.Search} size="small" />}
          tooltip={vm.translate("search")}
          iconButtonSize="small"
          onClick={getTechnicianByIdNumber}
          style={{ height: 40, width: 40, marginRight: 5 }}
        />
        <IconButton
          id="addTechnicianWipeData"
          icon={<Icon element={IconsList.Clear} size="small" />}
          tooltip={vm.translate("report.block0.wipeData")}
          iconButtonSize="small"
          onClick={() => {
            setTechnicianId("");
            setTechnicianFound(false);
          }}
          style={{ height: 40, width: 40 }}
        />
        {/* <Button id={"profileTechnicianIdCheckButton"} style={{ marginTop: 3 }} size="small" text={vm.translate("report.block0.user.id.check")} onClick={getTechnicianByIdNumber} /> */}
        {technicianFound && (
          <>
            <Grid item xs={6} md={6} className={classes.report_textFieldGrid}>
              <SelectInput
                id={"profileTechnicianTypeEnum"}
                value={newParticipant.type && newParticipant.type === TechnicianDTOTypeEnum.Legal ? "Persona jurídica" : "Persona física"}
                disabled
                size={"small"}
                multipleSelection={false}
                options={["Persona física", "Persona jurídica"]}
                colorRenderer={Colors.textPrimary}
                variant={"outlined"}
                onValueChanged={() => { }}
                renderer={(value: string) => value}
                label={vm.translate("report.block0.type")}
              />
            </Grid>
            <Grid item xs={12} md={12} className={classes.report_generalDataGrid}>
              <TextFieldGrid
                id={"profileTechnicianName"}
                disabled
                title={vm.translate("report.block0.name")}
                value={newParticipant.name ?? ""}
                style={{ marginRight: 5 }}
                gridTextField={{ xs: 6, md: 6 }}
              />
              <TextFieldGrid
                id="profileTechnicianSurmane"
                disabled
                title={vm.translate("report.block0.surname")}
                value={newParticipant.surname ?? ""}
                gridTextField={{ xs: 6, md: 6 }}
              />
            </Grid>
            <Grid item xs={12} md={12} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <TextFieldGrid
                id="profileTechnicianNif"
                disabled
                title={vm.translate("report.block0.nif")}
                value={newParticipant.idNumber ?? ""}
                gridTextField={{ xs: 6, md: 6 }}
                error={!RegexHelper.validCif.test(newParticipant.idNumber!.toUpperCase()) || newParticipant?.idNumber!.length !== 9}
              />
              {vm.college && (
                <TextFieldGrid
                  id="profileTechnicianProfesion"
                  disabled
                  title={vm.translate("report.block0.profession")}
                  value={vm.profession}
                  gridTextField={{ xs: 6, md: 6 }}
                />
              )}
            </Grid>
            <Grid item xs={12} md={12} className={`${classes.report_generalDataGrid} ${classes.report_textFieldGrid}`}>
              <Grid item xs={12} md={12} style={{ marginRight: 5 }}>
                <Autocomplete
                  disablePortal
                  id={"profileTechnicianColleges"}
                  value={vm.college}
                  options={newParticipant.collegiated ? newParticipant.collegiated : []}
                  fullWidth
                  clearIcon={<></>}
                  onChange={(evt: any, value: any) => {
                    onChangeCollege(value);
                  }}
                  getOptionLabel={(option: CollegiateDTO) => option.college?.name ?? ""}
                  renderInput={(params) => <TextField {...params} size="small" label={vm.translate("report.block0.college")} />}
                /* onChange={(evt: any) => { }}
              getOptionLabel={(option: CollegeDTO) => option.name ?? ""}
              renderInput={(params) => <TextField {...params} className={classes.report_autocomplete} size="small" label={vm.translate("report.block0.college")} />} */
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} className={classes.report_displayGrid}>
              <Grid item xs={12} md={12} className={`${classes.report_generalDataGrid} ${classes.report_textFieldGrid}`}>
                <Grid item xs={4} md={4} style={{ marginRight: 5 }}>
                  <Autocomplete
                    disablePortal
                    id={"profileTechinicianCommunity"}
                    value={community}
                    disabled
                    options={[]}
                    fullWidth
                    clearIcon={<></>}
                    getOptionLabel={(option: CommunityDTO) => option.name ?? ""}
                    renderInput={(params) => (
                      <TextField {...params} className={classes.report_autocomplete} size="small" label={vm.translate("report.block0.ccaa")} />
                    )}
                  />
                </Grid>
                <Grid item xs={4} md={4} style={{ marginRight: 5 }}>
                  <Autocomplete
                    disablePortal
                    id={"profileTechnicianProvince"}
                    value={province}
                    disabled
                    options={[]}
                    fullWidth
                    clearIcon={<></>}
                    getOptionLabel={(option: ProvinceDTO) => option.name ?? ""}
                    renderInput={(params) => (
                      <TextField {...params} className={classes.report_autocomplete} size="small" label={vm.translate("report.block0.province")} />
                    )}
                  />
                </Grid>
                <Grid item xs={4} md={4}>
                  <Autocomplete
                    disablePortal
                    id="profileTechnicianTown"
                    value={town}
                    disabled
                    options={[]}
                    fullWidth
                    clearIcon={<></>}
                    getOptionLabel={(option: TownDTO) => option.name ?? ""}
                    renderInput={(params) => (
                      <TextField {...params} className={classes.report_autocomplete} size="small" label={vm.translate("report.block1.town")} />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} className={classes.report_generalDataGrid}>
                <TextFieldGrid
                  id="profileTechnicianAddress"
                  disabled
                  title={vm.translate("report.block1.address")}
                  value={newParticipant.address?.streetName ?? ""}
                  style={{ marginRight: 5 }}
                  gridTextField={{ xs: 6, md: 6 }}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.report_generalDataGrid}>
                <TextFieldGrid
                  id="profileTechnicianEmail"
                  disabled
                  title={vm.translate("report.block0.email")}
                  value={newParticipant.email ?? ""}
                  style={{ marginRight: 5 }}
                  gridTextField={{ xs: 6, md: 6 }}
                // onChange={(e:any) => setNewParticipant({...newParticipant, email:e.target.value})}
                />
                <TextFieldGrid
                  id="profileTechnicianMobile"
                  disabled
                  title={vm.translate("report.block0.mobile")}
                  value={newParticipant.mobilePhone ?? ""}
                  gridTextField={{ xs: 6, md: 6 }}
                />
              </Grid>
              <TextFieldGrid
                id="profileTechnicianParticipation"
                title={vm.translate("report.block0.participation")}
                onChange={(evt: any) => onChangeParticipation(evt.target.value)}
                value={newParticipation.participation?.toString() ?? ""}
                gridTextField={{ xs: 6, md: 6 }}
              />
            </Grid>
            <Grid item xs={12} md={12} className={classes.report_saveButton}>
              <Button
                id="profileTechnicianViewSave"
                text={vm.translate("save")}
                onClick={handleSaveTechnician}
                disabled={
                  (RegexHelper.validCif.test(newParticipant?.idNumber!.toUpperCase()) ||
                    RegexHelper.CIF_REGEX.test(newParticipant?.idNumber!.toUpperCase())) &&
                    newParticipant?.idNumber!.length === 9
                    ? false
                    : true
                }
                textColor={Colors.white}
                backgroundColor={Colors.buttonSecondary}
                hoverColor={Colors.buttonSecondary}
              />
            </Grid>
          </>
        )}
      </Grid>
      <DialogMovicoders
        open={showInvite}
        onClose={() => setShowInvite(false)}
        onConfirm={() => { }}
        maxWidth={"sm"}
        dialogConfig={dialogStrings}
        draggable={false}
        closeWithX
        fullWidth
        backgroundColorTitle={Colors.primary}
      />
      <DialogMovicoders
        open={showActive}
        onClose={() => setShowActive(false)}
        onConfirm={() => { }}
        maxWidth={"sm"}
        dialogConfig={dialogStringsActiveUser}
        draggable={false}
        closeWithX
        fullWidth
        styleDialog={{ width: 500 }}
        backgroundColorTitle={Colors.primary}
      />
    </Grid>
  );
});
