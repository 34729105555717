// intended to compare objects of identical shape; ideally static.
//
// any top-level key with a primitive value which exists in `previous` but not
// in `current` returns `undefined` while vice versa yields a diff.
//
// in general, the input type determines the output type. that is if `previous`
// and `current` are objects then an object is returned. if arrays then an array
// is returned, etc.
const typeOf = (o: any) => Object.prototype.toString.call(o);
const isObject = (o: any) => o !== null && !Array.isArray(o) && typeOf(o).split(" ")[1].slice(0, -1) === "Object";

const isPrimitive = (o: any) => {
  switch (typeof o) {
    case "object": {
      return false;
    }
    case "function": {
      return false;
    }
    default: {
      return true;
    }
  }
};

export const getChanges = (previous: any, current: any) => {
  if (isPrimitive(previous) && isPrimitive(current)) {
    if (previous === current) {
      return "";
    }

    return current;
  }

  if (isObject(previous) && isObject(current)) {
    const diff: any = getChanges(Object.entries(previous), Object.entries(current));

    return diff.reduce((merged: any, [key, value]: any[]) => {
      return {
        ...merged,
        [key]: value,
      };
    }, {});
  }

  const changes: any = [];

  if (JSON.stringify(previous) === JSON.stringify(current)) {
    return changes;
  }

  for (let i = 0; i < current.length; i++) {
    const item = current[i];

    if (JSON.stringify(item) !== JSON.stringify(previous[i])) {
      changes.push(item);
    }
  }

  return changes;
};
