/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { AddressDTO, BuildingAddressDTO } from "../../../../../../clients";
import TypographyTitleWButton from "../../../../../../components/typography-title-button";
import { addressDTOMock } from "../../../../../../viewmodels/profile/data";
import ProfileViewModel from "../../../../../../viewmodels/profile/profile-view-model";
import ReportViewModel from "../../../../../../viewmodels/report/report-view-model";
import { useAddress } from "../../../../../profile/profile-components/form/form-components/useAddress";
import { ReportStyle } from "../../../../report-style";
import { DisabledAddress } from "./location-info/disabled-address";
import { EditingAddress } from "./location-info/editing-address";
import { useSnackbar } from "notistack";
import TypographyTitle from "../../../../../../components/typography-title";

export const LocationSection: React.FC<{ vm: ReportViewModel }> = observer(({ vm }) => {
  const classes = ReportStyle();
  const [isEditing, setIsEditing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { locationInfoView, address, reloadAddress } = useAddress(
    vm.parent.children.find((c) => c.navigationName === "profile") as ProfileViewModel,
    addressDTOMock(vm.manualAddress)
  );

  useEffect(() => {
    if (vm.parent.isManual === false) {
      vm.locationInfo = vm.userBook!.buildingLocationInfo!;
    }
  });

  useEffect(() => {
    if (vm.parent.isManual) {
      const a = { ...address };
      const street = address?.streetCode;
      delete a.streetCode;
      vm.locationInfo.address = { ...a, street } as BuildingAddressDTO;
    }
  }, [address]);

  const saveAddress = async (address: AddressDTO) => {
    const locationInfo = { ...vm.locationInfo, address };
    await vm.updateLocationInfo(vm.userBook?.id ?? "", locationInfo).catch((e) => {
      enqueueSnackbar("Ha ocurrido un error al guardar la dirección", { variant: "error" });
    });
    vm.userBook.buildingLocationInfo = locationInfo;
    vm.loadingBook = false;
    setIsEditing(false);
    enqueueSnackbar("Dirección guardada correctamente", { variant: "success" });
  };

  useEffect(() => {
    if (vm.parent.isManual && vm.selectedCommunityManualEntry) reloadAddress({ ...address, community: vm.selectedCommunityManualEntry });
  }, [vm.selectedCommunityManualEntry]);

  return (
    <Grid item xs={12} md={12} className={classes.report_displayGridTitle}>
      {vm.parent.isManual ? (
        <TypographyTitle id="locationTitle" marginTop title={vm.translate("report.block0.location")} />
      ) : (
        <TypographyTitleWButton
          id="locationTitle"
          marginTop
          title={vm.translate("report.block0.location")}
          buttonLabel={isEditing ? "Cancelar" : "Editar"}
          onClick={() => setIsEditing(!isEditing)}
        />
      )}
      <div style={{ height: 10, width: 1 }} />
      {vm.parent.isManual ? locationInfoView : isEditing ? <EditingAddress vm={vm} saveAddress={saveAddress} /> : <DisabledAddress vm={vm} />}
    </Grid>
  );
});
