import { FileUploader, TypographyTheme } from "@movicoders/movicoders-components";
import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { Colors, FontSizes } from "../../../../../../../constants";
import { ReportStyle } from "../../../../../report-style";
import { UsageDTO } from "../../../../../../../clients";
import Papa from "papaparse";
import { useSnackbar } from "notistack";

export interface ITextField {
  vm: any;
}

const numberRegEx = new RegExp("^[+-]?(d*.)?d+$");

export const TypographyTitleDoubleExtended = ({ vm }: ITextField) => {
  const classes = ReportStyle();
  const [file, setFile] = useState<File | undefined>();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleCloseDialog = () => {
    setFile(undefined);
    setIsDialogOpen(false);
  };

  const { enqueueSnackbar } = useSnackbar();
  const handleOpenSnackBar = (snackKey: string, variants: undefined | "default" | "error" | "success" | "warning" | "info") => {
    enqueueSnackbar(snackKey, { variant: variants, preventDuplicate: true, key: snackKey, autoHideDuration: 3000 });
  };

  const stringToNumber = (s: string): number => {
    const pattern = /^[-+]?\d+(?:[.,]\d+)?$/;
    if (pattern.test(s?.trim())) {
      const num = parseFloat(s.replace(",", "."));
      return isNaN(num) ? 0 : num;
    } else {
      return 0;
    }
  };

  const extractUsage = (usage: string[]): UsageDTO => ({
    cadastralReference: usage[0],
    description: usage[1],
    block: usage[2],
    door: usage[3],
    floor: usage[4],
    stair: usage[5],
    surface: stringToNumber(usage[6]),
  });

  const handleFileUpload = async () => {
    if (file) {
      const blob: any = new Blob([file]);
      const reader = new FileReader();
      reader.readAsBinaryString(blob);
      reader.onload = function (e) {
        if (e.target?.result && typeof e.target.result === "string") {
          let csvData: any[] = [];
          let lbreak = e.target?.result.split("\n");
          lbreak.forEach((res: any) => {
            csvData.push(res.split(";"));
          });
          // * remove header row
          csvData.shift();
          const usages = csvData.map(extractUsage);
          const filteredEmptyUsages = usages.filter((usage) => usage.cadastralReference !== "" || usage.cadastralReference?.length);
          const fixed = filteredEmptyUsages.map((usage) => {
            if (usage.surface) {
              usage.surface = +usage.surface?.toString()?.replace(/[^0-9.%]+/g, "");
              if (usage.surface === undefined) {
                usage.surface = 0;
              }
            }
            return !numberRegEx.test(usage.surface?.toString() ?? "0") ? usage : null;
          });
          vm.buildingComposition.usages = fixed;
          vm.manualUsages = fixed;
        }
      };
      setFile(undefined);
      setIsDialogOpen(false);
    }
  };

  const data = [
    ["referencia catastral", "descripción", "bloque", "puerta", "planta", "escalera", "superficie"],
    ["4637201XM7143H0002WF", "COMERCIO", "n/a", "2", "0", "1", "139"],
    ["4637201XM7143H0002WF", "COMERCIO", "1", "3", "0", "1", "317"],
    ["4637201XM7143H0002WF", "ELEMENTOS COMUNES", "A", "n/a", "n/a", "n/a", "77"],
    ["4637201XM7143H0002WG", "HOTEL", "n/a", "62", "0", "31", "1.139"],
    ["4637201XM7143H0002WG", "COMERCIO", "n/a", "3", "20", "11", "331,7"],
    ["4637201XM7143H0002WG", "ELEMENTOS COMUNES", "n/a", "n/a", "n/a", "n/a", "77"],
  ];

  const downloadTemplate = () => {
    const csvData = Papa.unparse(data, { delimiter: ";" });
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Plantilla.csv";
    a.click();
  };

  const handleOpenDialog = () => setIsDialogOpen(true);

  return (
    <React.Fragment>
      {isDialogOpen ? (
        <Dialog open={true}>
          <DialogTitle>IMPORTAR CSV</DialogTitle>
          <DialogContent>
            <Typography fontSize={11}>{vm.translate("file.size.recommendation")}</Typography>
            <FileUploader
              dropzoneText={vm.translate("report.block1.license.file.helper")}
              maxFileSize={500000}
              maxFiles={1}
              onChange={(files: File[]) => {
                if (files && files?.length)
                  !files?.[0]?.name.includes("+" || "%") ? setFile(files[0]) : handleOpenSnackBar(vm.translate("uploadNameError"), "error");
              }}
              classNameDropzone={classes.report_dropZone}
              classNameParagraphDropzone={classes.report_dropZone}
              getDropRejectMessage={() => vm.translate("file.size.error")}
              showPreviews={false}
              getFileAddedMessage={(filename: string) => `${filename} ${vm.translate("report.block1.license.file.added")}`}
              getFileRemovedMessage={(filename: string) => `${filename} ${vm.translate("report.block1.license.file.removed")}`}
            />
            <Grid item xs={12} style={{ marginTop: ".5rem" }}>
              <Button id={"close-filedialog"} onClick={handleCloseDialog} style={{ marginRight: ".5rem" }}>
                Cerrar
              </Button>
              <Button id={"close-filedialog"} onClick={handleFileUpload}>
                Guardar
              </Button>
            </Grid>
          </DialogContent>
        </Dialog>
      ) : null}
      <Grid item xs={12} md={12} style={{ marginTop: 5 }}>
        <Grid container style={{ justifyContent: "space-between", display: "flex" }}>
          <TypographyTheme id="generalDataUses" size={FontSizes.h1} color={Colors.textSecondaryHeaders} text={vm.translate("report.block0.uses")} />

          <Grid>
            <Button id="anadirplano" onClick={handleOpenDialog} variant="outlined" style={{ maxWidth: 250 }}>
              IMPORTAR CSV
            </Button>
            <Button id="anadirplano" onClick={downloadTemplate} variant="outlined" style={{ maxWidth: 250, marginLeft: ".5rem" }}>
              DESCARGAR PLANTILLA
            </Button>
          </Grid>
        </Grid>

        <hr className={classes.report_hr} />
      </Grid>
    </React.Fragment>
  );
};
