import Papa from "papaparse";
import { observer } from "mobx-react-lite";
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Icon } from "@movicoders/movicoders-components";
import UsersViewModel from "../../../viewmodels/users/users-view-model";
import { IconsList } from "@movicoders/movicoders-components";
import { Colors, TableColors, TableMargins } from "../../../constants";
import { ITableLabels } from "@movicoders/movicoders-components";
import { ColumnSearchHeader } from "@movicoders/movicoders-components";
import { Table, customColumn, deleteColumn } from "@movicoders/movicoders-components";
import { View } from "@frui.ts/views";

export const UserTable: React.FC<{ vm: UsersViewModel }> = observer(({ vm }) => {
  //const [filtered, setFiltered] = useState<any[][]>(vm.data)
  const [downloadCsv, setDownloadCsv] = useState(false);

  const translationsTable: ITableLabels = {
    body: {
      noMatch: vm.translate("table.labels.body.no.match"),
      toolTip: vm.translate("table.labels.body.tooltip"),
    },
    filter: {
      all: vm.translate("table.labels.filter.all"),
      title: vm.translate("table.labels.filter.title"),
      reset: vm.translate("table.labels.filter.reset"),
    },
    pagination: {
      next: vm.translate("table.labels.pagination.next"),
      previous: vm.translate("table.labels.pagination.previous"),
      rowsPerPage: vm.translate("table.labels.pagination.rows.per.page"),
      displayRows: vm.translate("table.labels.pagination.display.rows"),
      jumpToPage: vm.translate("table.labels.pagination.jump.to.page"),
    },
    selectedRows: {
      text: vm.translate("table.labels.selected.rows.text"),
      delete: vm.translate("table.labels.selected.rows.delete"),
      deleteAria: vm.translate("table.labels.selected.rows.delete.aria"),
    },
    toolbar: {
      search: vm.translate("search"),
      downloadCsv: vm.translate("table.labels.toolbar.download.csv"),
      print: vm.translate("table.labels.toolbar.print"),
      viewColumns: vm.translate("table.labels.toolbar.view.columns"),
      filterTable: vm.translate("table.labels.toolbar.filter.table"),
    },
    viewColumns: {
      title: vm.translate("table.labels.view.columns.title"),
      titleAria: vm.translate("table.labels.view.columns.title.aria"),
    },
  };

  // useEffect(() => {
  //const values = vm.applyFilter()
  //values && values.length >= 0 ? setFiltered(values) : setFiltered([])
  // }, [vm, vm.data])

  const handleRowsSelected = (rows: any) => {
    vm.setSelected(rows[0]);
  };

  useEffect(() => {
    vm.csvData.length > 0 &&
      vm.csvData.forEach((item) => {
        array.push({
          [`${vm.translate("user.username")}`]: item.username,
          [`${vm.translate("user.email")}`]: item.email,
          [`${vm.translate("user.active")}`]: item.active ? "Activado" : "Desactivado",
        });
      });

    if (downloadCsv && vm.csvData.length > 0) {
      downloadCSV();
      setDownloadCsv(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vm.csvData]);

  const handleCSVArray = async () => {
    vm.csvData = [];
    setDownloadCsv(true);
    vm.loadDataCSV();
  };

  var array: any[] = [];

  const downloadCSV = () => {
    Papa.DefaultDelimiter = ";";
    var csv = Papa.unparse(array, {
      quotes: false, //or array of booleans
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ";",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
      columns: [vm.translate("user.username"), vm.translate("user.email"), vm.translate("user.active")],
    });

    var csvData = new Blob(["\ufeff", csv], { type: ' type: "text/csv;charset=UTF-8"' }); //Here, I also tried charset=GBK , and it does not work either
    var csvURL = null;

    csvURL = window.URL.createObjectURL(csvData);

    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", vm.translate("section.users") + ".csv");
    tempLink.click();
  };

  const handleTextChange = (column: number, text?: string) => {
    vm.addFilter(column, text);
  };

  const customHeader = (columnMeta: any) => {
    return (
      <ColumnSearchHeader
        id={"columnSearch"}
        order={columnMeta.colPos}
        value={vm.filters[columnMeta.name]}
        styles={{
          color: Colors.primary,
          fontSize: 14,
        }}
        columnMeta={columnMeta}
        handleTextChange={handleTextChange}
      />
    );
  };

  const columns = [
    customColumn("username", vm.translate("user.username"), "textField", undefined, undefined, customHeader),
    customColumn("email", vm.translate("user.email"), "textField", undefined, undefined, customHeader),
    customColumn(
      "active",
      vm.translate("user.active"),
      "textField",
      (dataIndex: number, dataRow: number) => {
        return vm.data[dataIndex][4] ? (
          <Icon color={"green"} size={"small"} element={IconsList.Check} />
        ) : (
          <Icon size={"small"} color={"red"} element={IconsList.Clear} />
        );
      },
      undefined,
      customHeader
    ),

    deleteColumn(
      (id: string) => {
        vm.find(id);
      },
      (dataIndex: number) => vm.items[dataIndex]?.id.toString(),
      <Icon color={Colors.infoColor} element={IconsList.Clear} />
    ),
  ];

  return (
    <React.Fragment>
      {vm.activeChild && <View vm={vm.activeChild} />}
      <Table
        title={vm.translate("user.title")}
        data={vm.items}
        columns={columns}
        createButton={true}
        responsive={"standard"}
        search={false}
        selectableRows="single"
        selectableRowsOnClick={true}
        onRowSelectionChange={handleRowsSelected}
        onSelectedRow={vm.openDetailEdit}
        tableLabels={translationsTable}
        tableColors={TableColors}
        tableHeaderMargins={TableMargins}
        download={false}
        toolbarContent={
          <React.Fragment>
            <IconButton
              edge="start"
              color="inherit"
              style={{ marginLeft: 1 }}
              onClick={() => {
                handleCSVArray();
              }}
              aria-label="close"
            >
              <Icon element={IconsList.GetApp} />
            </IconButton>
          </React.Fragment>
        }
        rowsPerPage={vm.rowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100]}
        onChangeRowsPerPage={vm.handleRowsPerPage}
        bodyHeightDifference={230}
        onChangePage={vm.handleCurrentPage}
        onCreate={() => vm.openDetail(vm.prepareCreateTest())}
        marginRightPagination={vm.parent.openDrawer ? 270 : 43}
      />
    </React.Fragment>
  );
});
