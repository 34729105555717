/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccessibilityEvaluationDTO,
    AccessibilityEvaluationDTOFromJSON,
    AccessibilityEvaluationDTOToJSON,
    AgentParticipationDTO,
    AgentParticipationDTOFromJSON,
    AgentParticipationDTOToJSON,
    BookDTO,
    BookDTOFromJSON,
    BookDTOToJSON,
    BooksFilterForUser,
    BooksFilterForUserFromJSON,
    BooksFilterForUserToJSON,
    BuildingCompositionDTO,
    BuildingCompositionDTOFromJSON,
    BuildingCompositionDTOToJSON,
    BuildingConstructionDescriptionDTO,
    BuildingConstructionDescriptionDTOFromJSON,
    BuildingConstructionDescriptionDTOToJSON,
    BuildingDTO,
    BuildingDTOFromJSON,
    BuildingDTOToJSON,
    BuildingLocationInfoDTO,
    BuildingLocationInfoDTOFromJSON,
    BuildingLocationInfoDTOToJSON,
    CecaSignatureResponse,
    CecaSignatureResponseFromJSON,
    CecaSignatureResponseToJSON,
    CustomTechnicianParticipationPageDTO,
    CustomTechnicianParticipationPageDTOFromJSON,
    CustomTechnicianParticipationPageDTOToJSON,
    EnergyEfficiencyCertificateDTO,
    EnergyEfficiencyCertificateDTOFromJSON,
    EnergyEfficiencyCertificateDTOToJSON,
    Error0,
    Error0FromJSON,
    Error0ToJSON,
    ImprovementActionDTO,
    ImprovementActionDTOFromJSON,
    ImprovementActionDTOToJSON,
    InspectionDTO,
    InspectionDTOFromJSON,
    InspectionDTOToJSON,
    LegalDataDTO,
    LegalDataDTOFromJSON,
    LegalDataDTOToJSON,
    LicenseDTO,
    LicenseDTOFromJSON,
    LicenseDTOToJSON,
    MaintenancePlanDTO,
    MaintenancePlanDTOFromJSON,
    MaintenancePlanDTOToJSON,
    MediaDTO,
    MediaDTOFromJSON,
    MediaDTOToJSON,
    MovicodersBadRequestException,
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundException,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceException,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
    NewPrintingBookDTO,
    NewPrintingBookDTOFromJSON,
    NewPrintingBookDTOToJSON,
    ParticipationListItemDTO,
    ParticipationListItemDTOFromJSON,
    ParticipationListItemDTOToJSON,
    PolygonInfoDTO,
    PolygonInfoDTOFromJSON,
    PolygonInfoDTOToJSON,
    PrintingBookDTO,
    PrintingBookDTOFromJSON,
    PrintingBookDTOToJSON,
    ProceedingsDTO,
    ProceedingsDTOFromJSON,
    ProceedingsDTOToJSON,
    ShortcomingDTO,
    ShortcomingDTOFromJSON,
    ShortcomingDTOToJSON,
    TechnicianParticipationDTO,
    TechnicianParticipationDTOFromJSON,
    TechnicianParticipationDTOToJSON,
    TechnicianStatisticsDTO,
    TechnicianStatisticsDTOFromJSON,
    TechnicianStatisticsDTOToJSON,
    UrbanDataDTO,
    UrbanDataDTOFromJSON,
    UrbanDataDTOToJSON,
    UsageDTO,
    UsageDTOFromJSON,
    UsageDTOToJSON,
    UsagePageDTO,
    UsagePageDTOFromJSON,
    UsagePageDTOToJSON,
    UsagePlanDTO,
    UsagePlanDTOFromJSON,
    UsagePlanDTOToJSON,
    ViewAdminTechnicianParticipationPageDTO,
    ViewAdminTechnicianParticipationPageDTOFromJSON,
    ViewAdminTechnicianParticipationPageDTOToJSON,
} from '../models';

export interface AddAccessibilityEvaluationRequest {
    id: string;
    accessibilityEvaluationDTO?: AccessibilityEvaluationDTO;
}

export interface AddActionRegistryDocumentationRequest {
    id: string;
    mediaDTO?: MediaDTO;
}

export interface AddAgentByIdRequest {
    id: string;
    agentParticipationDTO?: AgentParticipationDTO;
}

export interface AddBestPracticeDocumentationRequest {
    id: string;
    mediaDTO?: MediaDTO;
}

export interface AddBuildingNormalizedDescriptionRequest {
    id: string;
    buildingConstructionDescriptionDTO?: BuildingConstructionDescriptionDTO;
}

export interface AddEnergyEfficiencyCertificateRequest {
    id: string;
    energyEfficiencyCertificateDTO?: EnergyEfficiencyCertificateDTO;
}

export interface AddIeeDocumentationRequest {
    id: string;
    mediaDTO?: MediaDTO;
}

export interface AddImprovementActionRequest {
    id: string;
    improvementActionDTO?: ImprovementActionDTO;
}

export interface AddInspectionRequest {
    id: string;
    inspectionDTO?: InspectionDTO;
}

export interface AddIteDocumentationRequest {
    id: string;
    mediaDTO?: MediaDTO;
}

export interface AddLicenseRequest {
    id: string;
    licenseDTO?: LicenseDTO;
}

export interface AddMaintenanceContractDocumentationRequest {
    id: string;
    mediaDTO?: MediaDTO;
}

export interface AddMaintenancePlanRequest {
    id: string;
    maintenancePlanDTO?: MaintenancePlanDTO;
}

export interface AddOtherDocumentationRequest {
    id: string;
    mediaDTO?: MediaDTO;
}

export interface AddProceedingsRequest {
    id: string;
    proceedingsDTO?: ProceedingsDTO;
}

export interface AddShortcomingToInspectionRequest {
    id: string;
    inspectionId: string;
    shortcomingDTO?: ShortcomingDTO;
}

export interface AddTechnicianRequest {
    id: string;
    technicianParticipationDTO?: TechnicianParticipationDTO;
}

export interface AddUsagePlanRequest {
    id: string;
    usagePlanDTO?: UsagePlanDTO;
}

export interface DeleteAgentByIdRequest {
    id: string;
    agentId: string;
}

export interface DeleteBookByIdRequest {
    id: string;
}

export interface DeleteLicenseByIdRequest {
    id: string;
    licenseId: string;
}

export interface DeleteMediaByIdFromBookRequest {
    id: string;
    mediaId: string;
}

export interface DeleteProceedingByIdRequest {
    id: string;
    proceedingId: string;
}

export interface DeleteTechnicianParticipationByIdRequest {
    id: string;
    credentialsId: string;
}

export interface EditAgentByIdRequest {
    bookId: string;
    agentId: string;
    agentParticipationDTO?: AgentParticipationDTO;
}

export interface GetAgentsByBookIdRequest {
    id: string;
}

export interface GetBookByIdRequest {
    id: string;
    properties: boolean;
    usages: boolean;
    polygons: boolean;
}

export interface GetBooksByTechnicianRequest {
    limit: number;
    offset: number;
}

export interface GetBooksByTechnicianByFilterRequest {
    booksFilterForUser?: BooksFilterForUser;
}

export interface GetBooksByTechnicianWithPaginationRequest {
    limit: number;
    offset: number;
}

export interface GetNewPrintingBookByIdRequest {
    id: string;
}

export interface GetPolygonsByBookIdRequest {
    id: string;
}

export interface GetPrintingBookByIdRequest {
    id: string;
}

export interface GetStatisticsFromTechnicianIdRequest {
    id: string;
}

export interface GetUsagePageByBookIdRequest {
    id: string;
    limit: number;
    offset: number;
}

export interface GetUsagesByBookIdRequest {
    id: string;
}

export interface InitBooManualRequest {
    collegeId?: string;
    buildingDTO?: BuildingDTO;
}

export interface InitBookRequest {
    collegeId?: string;
    requestBody?: Array<string>;
}

export interface InitPaymentRequest {
    collegeId?: string;
}

export interface SetBuildingCompositionRequest {
    id: string;
    buildingCompositionDTO?: BuildingCompositionDTO;
}

export interface SetLegalDataRequest {
    id: string;
    legalDataDTO?: LegalDataDTO;
}

export interface SetLocationRequest {
    id: string;
    buildingLocationInfoDTO?: BuildingLocationInfoDTO;
}

export interface SetUrbanDataRequest {
    id: string;
    urbanDataDTO?: UrbanDataDTO;
}

export interface UpdatePropertiesRequest {
    bookId: string;
    requestBody?: Array<string>;
}

export interface ValidateRequest {
    buildingDTO?: BuildingDTO;
}

/**
 * 
 */
export class BooksApi extends runtime.BaseAPI {

    /**
     * Adds accessibility evaluation file to the book.
     * Adds accessibility evaluation file to the book.
     */
    async addAccessibilityEvaluationRaw(requestParameters: AddAccessibilityEvaluationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addAccessibilityEvaluation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/accessibility-evaluation`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AccessibilityEvaluationDTOToJSON(requestParameters.accessibilityEvaluationDTO),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Adds accessibility evaluation file to the book.
     * Adds accessibility evaluation file to the book.
     */
    async addAccessibilityEvaluation(requestParameters: AddAccessibilityEvaluationRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.addAccessibilityEvaluationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add action registry documentation to the book.
     * Add action registry documentation to the book.
     */
    async addActionRegistryDocumentationRaw(requestParameters: AddActionRegistryDocumentationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addActionRegistryDocumentation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/action-registry`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MediaDTOToJSON(requestParameters.mediaDTO),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Add action registry documentation to the book.
     * Add action registry documentation to the book.
     */
    async addActionRegistryDocumentation(requestParameters: AddActionRegistryDocumentationRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.addActionRegistryDocumentationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds an agent for a given book.
     * Adds an agent for a given book.
     */
    async addAgentByIdRaw(requestParameters: AddAgentByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AgentParticipationDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addAgentById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/agents`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AgentParticipationDTOToJSON(requestParameters.agentParticipationDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgentParticipationDTOFromJSON(jsonValue));
    }

    /**
     * Adds an agent for a given book.
     * Adds an agent for a given book.
     */
    async addAgentById(requestParameters: AddAgentByIdRequest, initOverrides?: RequestInit): Promise<AgentParticipationDTO> {
        const response = await this.addAgentByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add best practice documentation to the book.
     * Add best practice documentation to the book.
     */
    async addBestPracticeDocumentationRaw(requestParameters: AddBestPracticeDocumentationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addBestPracticeDocumentation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/best-practice`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MediaDTOToJSON(requestParameters.mediaDTO),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Add best practice documentation to the book.
     * Add best practice documentation to the book.
     */
    async addBestPracticeDocumentation(requestParameters: AddBestPracticeDocumentationRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.addBestPracticeDocumentationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds building normalized description to a given book.
     * Adds building normalized description to a given book.
     */
    async addBuildingNormalizedDescriptionRaw(requestParameters: AddBuildingNormalizedDescriptionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addBuildingNormalizedDescription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/normalized-description`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BuildingConstructionDescriptionDTOToJSON(requestParameters.buildingConstructionDescriptionDTO),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Adds building normalized description to a given book.
     * Adds building normalized description to a given book.
     */
    async addBuildingNormalizedDescription(requestParameters: AddBuildingNormalizedDescriptionRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.addBuildingNormalizedDescriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds energy efficiency certificate to the book.
     * Adds energy efficiency certificate to the book.
     */
    async addEnergyEfficiencyCertificateRaw(requestParameters: AddEnergyEfficiencyCertificateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addEnergyEfficiencyCertificate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/ee-certificates`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EnergyEfficiencyCertificateDTOToJSON(requestParameters.energyEfficiencyCertificateDTO),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Adds energy efficiency certificate to the book.
     * Adds energy efficiency certificate to the book.
     */
    async addEnergyEfficiencyCertificate(requestParameters: AddEnergyEfficiencyCertificateRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.addEnergyEfficiencyCertificateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add IEE documentation to the book.
     * Add IEE documentation to the book.
     */
    async addIeeDocumentationRaw(requestParameters: AddIeeDocumentationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addIeeDocumentation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/iee-documentation`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MediaDTOToJSON(requestParameters.mediaDTO),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Add IEE documentation to the book.
     * Add IEE documentation to the book.
     */
    async addIeeDocumentation(requestParameters: AddIeeDocumentationRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.addIeeDocumentationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add Improvement Action to the book.
     * Add improvement Action to the book.
     */
    async addImprovementActionRaw(requestParameters: AddImprovementActionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addImprovementAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/improvement-actions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ImprovementActionDTOToJSON(requestParameters.improvementActionDTO),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Add Improvement Action to the book.
     * Add improvement Action to the book.
     */
    async addImprovementAction(requestParameters: AddImprovementActionRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.addImprovementActionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds inspection to the book.
     * Adds inspection to the book.
     */
    async addInspectionRaw(requestParameters: AddInspectionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addInspection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/inspections`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InspectionDTOToJSON(requestParameters.inspectionDTO),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Adds inspection to the book.
     * Adds inspection to the book.
     */
    async addInspection(requestParameters: AddInspectionRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.addInspectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add ITE documentation to the book.
     * Add ITE documentation to the book.
     */
    async addIteDocumentationRaw(requestParameters: AddIteDocumentationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addIteDocumentation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/ite-documentation`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MediaDTOToJSON(requestParameters.mediaDTO),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Add ITE documentation to the book.
     * Add ITE documentation to the book.
     */
    async addIteDocumentation(requestParameters: AddIteDocumentationRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.addIteDocumentationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a license for a given book.
     * Adds a license for a given book.
     */
    async addLicenseRaw(requestParameters: AddLicenseRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addLicense.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/licenses`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LicenseDTOToJSON(requestParameters.licenseDTO),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Adds a license for a given book.
     * Adds a license for a given book.
     */
    async addLicense(requestParameters: AddLicenseRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.addLicenseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add maintenance contract documentation to the book.
     * Add maintenance contract documentation to the book.
     */
    async addMaintenanceContractDocumentationRaw(requestParameters: AddMaintenanceContractDocumentationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addMaintenanceContractDocumentation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/maintenance-contract`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MediaDTOToJSON(requestParameters.mediaDTO),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Add maintenance contract documentation to the book.
     * Add maintenance contract documentation to the book.
     */
    async addMaintenanceContractDocumentation(requestParameters: AddMaintenanceContractDocumentationRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.addMaintenanceContractDocumentationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add a list of maintenance plan
     * Add a list of maintenance plan
     */
    async addMaintenancePlanRaw(requestParameters: AddMaintenancePlanRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addMaintenancePlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/maintenance-plan`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MaintenancePlanDTOToJSON(requestParameters.maintenancePlanDTO),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Add a list of maintenance plan
     * Add a list of maintenance plan
     */
    async addMaintenancePlan(requestParameters: AddMaintenancePlanRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.addMaintenancePlanRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add other documentation to the book.
     * Add other documentation to the book.
     */
    async addOtherDocumentationRaw(requestParameters: AddOtherDocumentationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addOtherDocumentation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/other-documentation`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MediaDTOToJSON(requestParameters.mediaDTO),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Add other documentation to the book.
     * Add other documentation to the book.
     */
    async addOtherDocumentation(requestParameters: AddOtherDocumentationRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.addOtherDocumentationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a proceedings for a given book.
     * Adds a proceedings for a given book.
     */
    async addProceedingsRaw(requestParameters: AddProceedingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addProceedings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/proceedings`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProceedingsDTOToJSON(requestParameters.proceedingsDTO),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Adds a proceedings for a given book.
     * Adds a proceedings for a given book.
     */
    async addProceedings(requestParameters: AddProceedingsRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.addProceedingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds shortcoming to inspection.
     * Adds shortcoming to inspection.
     */
    async addShortcomingToInspectionRaw(requestParameters: AddShortcomingToInspectionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InspectionDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addShortcomingToInspection.');
        }

        if (requestParameters.inspectionId === null || requestParameters.inspectionId === undefined) {
            throw new runtime.RequiredError('inspectionId','Required parameter requestParameters.inspectionId was null or undefined when calling addShortcomingToInspection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/inspections/{inspectionId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"inspectionId"}}`, encodeURIComponent(String(requestParameters.inspectionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ShortcomingDTOToJSON(requestParameters.shortcomingDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InspectionDTOFromJSON(jsonValue));
    }

    /**
     * Adds shortcoming to inspection.
     * Adds shortcoming to inspection.
     */
    async addShortcomingToInspection(requestParameters: AddShortcomingToInspectionRequest, initOverrides?: RequestInit): Promise<InspectionDTO> {
        const response = await this.addShortcomingToInspectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds an technician for a given book.
     * Adds an technician for a given book.
     */
    async addTechnicianRaw(requestParameters: AddTechnicianRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addTechnician.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/technician`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TechnicianParticipationDTOToJSON(requestParameters.technicianParticipationDTO),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Adds an technician for a given book.
     * Adds an technician for a given book.
     */
    async addTechnician(requestParameters: AddTechnicianRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.addTechnicianRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add an usage plan
     * Add an usage plan
     */
    async addUsagePlanRaw(requestParameters: AddUsagePlanRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addUsagePlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/usage-plan`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UsagePlanDTOToJSON(requestParameters.usagePlanDTO),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Add an usage plan
     * Add an usage plan
     */
    async addUsagePlan(requestParameters: AddUsagePlanRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.addUsagePlanRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete an agent for a given book.
     * Delete an agent for a given book.
     */
    async deleteAgentByIdRaw(requestParameters: DeleteAgentByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAgentById.');
        }

        if (requestParameters.agentId === null || requestParameters.agentId === undefined) {
            throw new runtime.RequiredError('agentId','Required parameter requestParameters.agentId was null or undefined when calling deleteAgentById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/deleteAgent/{agentId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"agentId"}}`, encodeURIComponent(String(requestParameters.agentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Delete an agent for a given book.
     * Delete an agent for a given book.
     */
    async deleteAgentById(requestParameters: DeleteAgentByIdRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.deleteAgentByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a book by id.
     * Deletes a book by id.
     */
    async deleteBookByIdRaw(requestParameters: DeleteBookByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteBookById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Deletes a book by id.
     * Deletes a book by id.
     */
    async deleteBookById(requestParameters: DeleteBookByIdRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.deleteBookByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a license for a given book.
     * Delete a license for a given book.
     */
    async deleteLicenseByIdRaw(requestParameters: DeleteLicenseByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteLicenseById.');
        }

        if (requestParameters.licenseId === null || requestParameters.licenseId === undefined) {
            throw new runtime.RequiredError('licenseId','Required parameter requestParameters.licenseId was null or undefined when calling deleteLicenseById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/license/{licenseId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"licenseId"}}`, encodeURIComponent(String(requestParameters.licenseId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Deletes a license for a given book.
     * Delete a license for a given book.
     */
    async deleteLicenseById(requestParameters: DeleteLicenseByIdRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.deleteLicenseByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a media file for a given book.
     * Deletes a media file from a book
     */
    async deleteMediaByIdFromBookRaw(requestParameters: DeleteMediaByIdFromBookRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteMediaByIdFromBook.');
        }

        if (requestParameters.mediaId === null || requestParameters.mediaId === undefined) {
            throw new runtime.RequiredError('mediaId','Required parameter requestParameters.mediaId was null or undefined when calling deleteMediaByIdFromBook.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/media/{mediaId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"mediaId"}}`, encodeURIComponent(String(requestParameters.mediaId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Deletes a media file for a given book.
     * Deletes a media file from a book
     */
    async deleteMediaByIdFromBook(requestParameters: DeleteMediaByIdFromBookRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.deleteMediaByIdFromBookRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a proceeding for a given book.
     * Delete a proceeding for a given book.
     */
    async deleteProceedingByIdRaw(requestParameters: DeleteProceedingByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteProceedingById.');
        }

        if (requestParameters.proceedingId === null || requestParameters.proceedingId === undefined) {
            throw new runtime.RequiredError('proceedingId','Required parameter requestParameters.proceedingId was null or undefined when calling deleteProceedingById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/proceeding/{proceedingId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"proceedingId"}}`, encodeURIComponent(String(requestParameters.proceedingId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Deletes a proceeding for a given book.
     * Delete a proceeding for a given book.
     */
    async deleteProceedingById(requestParameters: DeleteProceedingByIdRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.deleteProceedingByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a technician participation for a given book.
     * Deletes a technician participation for a given book.
     */
    async deleteTechnicianParticipationByIdRaw(requestParameters: DeleteTechnicianParticipationByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTechnicianParticipationById.');
        }

        if (requestParameters.credentialsId === null || requestParameters.credentialsId === undefined) {
            throw new runtime.RequiredError('credentialsId','Required parameter requestParameters.credentialsId was null or undefined when calling deleteTechnicianParticipationById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/technicians/{credentialsId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"credentialsId"}}`, encodeURIComponent(String(requestParameters.credentialsId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Deletes a technician participation for a given book.
     * Deletes a technician participation for a given book.
     */
    async deleteTechnicianParticipationById(requestParameters: DeleteTechnicianParticipationByIdRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.deleteTechnicianParticipationByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit an agent by id.
     * Edit an agent by id.
     */
    async editAgentByIdRaw(requestParameters: EditAgentByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AgentParticipationDTO>> {
        if (requestParameters.bookId === null || requestParameters.bookId === undefined) {
            throw new runtime.RequiredError('bookId','Required parameter requestParameters.bookId was null or undefined when calling editAgentById.');
        }

        if (requestParameters.agentId === null || requestParameters.agentId === undefined) {
            throw new runtime.RequiredError('agentId','Required parameter requestParameters.agentId was null or undefined when calling editAgentById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{bookId}/editAgents/{agentId}`.replace(`{${"bookId"}}`, encodeURIComponent(String(requestParameters.bookId))).replace(`{${"agentId"}}`, encodeURIComponent(String(requestParameters.agentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AgentParticipationDTOToJSON(requestParameters.agentParticipationDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgentParticipationDTOFromJSON(jsonValue));
    }

    /**
     * Edit an agent by id.
     * Edit an agent by id.
     */
    async editAgentById(requestParameters: EditAgentByIdRequest, initOverrides?: RequestInit): Promise<AgentParticipationDTO> {
        const response = await this.editAgentByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get agents for a given book.
     * Get agents for a given book.
     */
    async getAgentsByBookIdRaw(requestParameters: GetAgentsByBookIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AgentParticipationDTO>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAgentsByBookId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/getAgentsByBookId`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AgentParticipationDTOFromJSON));
    }

    /**
     * Get agents for a given book.
     * Get agents for a given book.
     */
    async getAgentsByBookId(requestParameters: GetAgentsByBookIdRequest, initOverrides?: RequestInit): Promise<Array<AgentParticipationDTO>> {
        const response = await this.getAgentsByBookIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get books by id.
     * Get book by id.
     */
    async getBookByIdRaw(requestParameters: GetBookByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BookDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBookById.');
        }

        if (requestParameters.properties === null || requestParameters.properties === undefined) {
            throw new runtime.RequiredError('properties','Required parameter requestParameters.properties was null or undefined when calling getBookById.');
        }

        if (requestParameters.usages === null || requestParameters.usages === undefined) {
            throw new runtime.RequiredError('usages','Required parameter requestParameters.usages was null or undefined when calling getBookById.');
        }

        if (requestParameters.polygons === null || requestParameters.polygons === undefined) {
            throw new runtime.RequiredError('polygons','Required parameter requestParameters.polygons was null or undefined when calling getBookById.');
        }

        const queryParameters: any = {};

        if (requestParameters.properties !== undefined) {
            queryParameters['properties'] = requestParameters.properties;
        }

        if (requestParameters.usages !== undefined) {
            queryParameters['usages'] = requestParameters.usages;
        }

        if (requestParameters.polygons !== undefined) {
            queryParameters['polygons'] = requestParameters.polygons;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BookDTOFromJSON(jsonValue));
    }

    /**
     * Get books by id.
     * Get book by id.
     */
    async getBookById(requestParameters: GetBookByIdRequest, initOverrides?: RequestInit): Promise<BookDTO> {
        const response = await this.getBookByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get books by technician id.
     * Get books by technician id.
     */
    async getBooksByTechnicianRaw(requestParameters: GetBooksByTechnicianRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ParticipationListItemDTO>>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getBooksByTechnician.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getBooksByTechnician.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/technician`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ParticipationListItemDTOFromJSON));
    }

    /**
     * Get books by technician id.
     * Get books by technician id.
     */
    async getBooksByTechnician(requestParameters: GetBooksByTechnicianRequest, initOverrides?: RequestInit): Promise<Array<ParticipationListItemDTO>> {
        const response = await this.getBooksByTechnicianRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get books for technicians by filter.
     * Get books for user by filter.
     */
    async getBooksByTechnicianByFilterRaw(requestParameters: GetBooksByTechnicianByFilterRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ViewAdminTechnicianParticipationPageDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/booksByFilter`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BooksFilterForUserToJSON(requestParameters.booksFilterForUser),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ViewAdminTechnicianParticipationPageDTOFromJSON(jsonValue));
    }

    /**
     * Get books for technicians by filter.
     * Get books for user by filter.
     */
    async getBooksByTechnicianByFilter(requestParameters: GetBooksByTechnicianByFilterRequest, initOverrides?: RequestInit): Promise<ViewAdminTechnicianParticipationPageDTO> {
        const response = await this.getBooksByTechnicianByFilterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get books by technician id with pagination.
     * Get books by technician id returns page.
     */
    async getBooksByTechnicianWithPaginationRaw(requestParameters: GetBooksByTechnicianWithPaginationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomTechnicianParticipationPageDTO>> {
        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getBooksByTechnicianWithPagination.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getBooksByTechnicianWithPagination.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/paginated-technicians`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomTechnicianParticipationPageDTOFromJSON(jsonValue));
    }

    /**
     * Get books by technician id with pagination.
     * Get books by technician id returns page.
     */
    async getBooksByTechnicianWithPagination(requestParameters: GetBooksByTechnicianWithPaginationRequest, initOverrides?: RequestInit): Promise<CustomTechnicianParticipationPageDTO> {
        const response = await this.getBooksByTechnicianWithPaginationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get books to print according to the new specification by id.
     * Get book to print according to the new specification by id.
     */
    async getNewPrintingBookByIdRaw(requestParameters: GetNewPrintingBookByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NewPrintingBookDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getNewPrintingBookById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/print/new/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewPrintingBookDTOFromJSON(jsonValue));
    }

    /**
     * Get books to print according to the new specification by id.
     * Get book to print according to the new specification by id.
     */
    async getNewPrintingBookById(requestParameters: GetNewPrintingBookByIdRequest, initOverrides?: RequestInit): Promise<NewPrintingBookDTO> {
        const response = await this.getNewPrintingBookByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get polygons for a given book.
     * Get polygons for a given book.
     */
    async getPolygonsByBookIdRaw(requestParameters: GetPolygonsByBookIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PolygonInfoDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPolygonsByBookId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/polygons/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PolygonInfoDTOFromJSON(jsonValue));
    }

    /**
     * Get polygons for a given book.
     * Get polygons for a given book.
     */
    async getPolygonsByBookId(requestParameters: GetPolygonsByBookIdRequest, initOverrides?: RequestInit): Promise<PolygonInfoDTO> {
        const response = await this.getPolygonsByBookIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get books to print by id.
     * Get book to print by id.
     */
    async getPrintingBookByIdRaw(requestParameters: GetPrintingBookByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PrintingBookDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPrintingBookById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/print/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PrintingBookDTOFromJSON(jsonValue));
    }

    /**
     * Get books to print by id.
     * Get book to print by id.
     */
    async getPrintingBookById(requestParameters: GetPrintingBookByIdRequest, initOverrides?: RequestInit): Promise<PrintingBookDTO> {
        const response = await this.getPrintingBookByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Statistics by technician id.
     * Get Statistics by technician id.
     */
    async getStatisticsFromTechnicianIdRaw(requestParameters: GetStatisticsFromTechnicianIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TechnicianStatisticsDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getStatisticsFromTechnicianId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/technicians/{id}/statistics`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TechnicianStatisticsDTOFromJSON(jsonValue));
    }

    /**
     * Get Statistics by technician id.
     * Get Statistics by technician id.
     */
    async getStatisticsFromTechnicianId(requestParameters: GetStatisticsFromTechnicianIdRequest, initOverrides?: RequestInit): Promise<TechnicianStatisticsDTO> {
        const response = await this.getStatisticsFromTechnicianIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Usages paginated by Book id.
     * Get Usages paginated by Book id.
     */
    async getUsagePageByBookIdRaw(requestParameters: GetUsagePageByBookIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UsagePageDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUsagePageByBookId.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling getUsagePageByBookId.');
        }

        if (requestParameters.offset === null || requestParameters.offset === undefined) {
            throw new runtime.RequiredError('offset','Required parameter requestParameters.offset was null or undefined when calling getUsagePageByBookId.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/usages/paginated/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UsagePageDTOFromJSON(jsonValue));
    }

    /**
     * Get Usages paginated by Book id.
     * Get Usages paginated by Book id.
     */
    async getUsagePageByBookId(requestParameters: GetUsagePageByBookIdRequest, initOverrides?: RequestInit): Promise<UsagePageDTO> {
        const response = await this.getUsagePageByBookIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Usages by Book id.
     * Get Usages by Book id.
     */
    async getUsagesByBookIdRaw(requestParameters: GetUsagesByBookIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<UsageDTO>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUsagesByBookId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/usages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UsageDTOFromJSON));
    }

    /**
     * Get Usages by Book id.
     * Get Usages by Book id.
     */
    async getUsagesByBookId(requestParameters: GetUsagesByBookIdRequest, initOverrides?: RequestInit): Promise<Array<UsageDTO>> {
        const response = await this.getUsagesByBookIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes a building and starts a book manual.
     * Conform a new building and init its code by manual.
     */
    async initBooManualRaw(requestParameters: InitBooManualRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BookDTO>> {
        const queryParameters: any = {};

        if (requestParameters.collegeId !== undefined) {
            queryParameters['collegeId'] = requestParameters.collegeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/booksManual`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BuildingDTOToJSON(requestParameters.buildingDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BookDTOFromJSON(jsonValue));
    }

    /**
     * Takes a building and starts a book manual.
     * Conform a new building and init its code by manual.
     */
    async initBooManual(requestParameters: InitBooManualRequest, initOverrides?: RequestInit): Promise<BookDTO> {
        const response = await this.initBooManualRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes a building and starts a book.
     * Conform a new building and init its code.
     */
    async initBookRaw(requestParameters: InitBookRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BookDTO>> {
        const queryParameters: any = {};

        if (requestParameters.collegeId !== undefined) {
            queryParameters['collegeId'] = requestParameters.collegeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BookDTOFromJSON(jsonValue));
    }

    /**
     * Takes a building and starts a book.
     * Conform a new building and init its code.
     */
    async initBook(requestParameters: InitBookRequest, initOverrides?: RequestInit): Promise<BookDTO> {
        const response = await this.initBookRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes a college id or null and starts payment process.
     * Start book payment process for college.
     */
    async initPaymentRaw(requestParameters: InitPaymentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CecaSignatureResponse>> {
        const queryParameters: any = {};

        if (requestParameters.collegeId !== undefined) {
            queryParameters['collegeId'] = requestParameters.collegeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/payment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CecaSignatureResponseFromJSON(jsonValue));
    }

    /**
     * Takes a college id or null and starts payment process.
     * Start book payment process for college.
     */
    async initPayment(requestParameters: InitPaymentRequest, initOverrides?: RequestInit): Promise<CecaSignatureResponse> {
        const response = await this.initPaymentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds building composition to a given book.
     * Adds building composition to a given book.
     */
    async setBuildingCompositionRaw(requestParameters: SetBuildingCompositionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling setBuildingComposition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/building-composition`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BuildingCompositionDTOToJSON(requestParameters.buildingCompositionDTO),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Adds building composition to a given book.
     * Adds building composition to a given book.
     */
    async setBuildingComposition(requestParameters: SetBuildingCompositionRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.setBuildingCompositionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds legal data to a given book.
     * Adds legal data to a given book.
     */
    async setLegalDataRaw(requestParameters: SetLegalDataRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling setLegalData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/legal-data`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LegalDataDTOToJSON(requestParameters.legalDataDTO),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Adds legal data to a given book.
     * Adds legal data to a given book.
     */
    async setLegalData(requestParameters: SetLegalDataRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.setLegalDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds location info to the book.
     * Adds location info to the book.
     */
    async setLocationRaw(requestParameters: SetLocationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling setLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/building-location-info`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BuildingLocationInfoDTOToJSON(requestParameters.buildingLocationInfoDTO),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Adds location info to the book.
     * Adds location info to the book.
     */
    async setLocation(requestParameters: SetLocationRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.setLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Sets the urban data of the book.
     * Sets the urban data of the book.
     */
    async setUrbanDataRaw(requestParameters: SetUrbanDataRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling setUrbanData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/{id}/urban-data`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UrbanDataDTOToJSON(requestParameters.urbanDataDTO),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Sets the urban data of the book.
     * Sets the urban data of the book.
     */
    async setUrbanData(requestParameters: SetUrbanDataRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.setUrbanDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update book properties.
     * Update book properties.
     */
    async updatePropertiesRaw(requestParameters: UpdatePropertiesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.bookId === null || requestParameters.bookId === undefined) {
            throw new runtime.RequiredError('bookId','Required parameter requestParameters.bookId was null or undefined when calling updateProperties.');
        }

        const queryParameters: any = {};

        if (requestParameters.bookId !== undefined) {
            queryParameters['bookId'] = requestParameters.bookId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/updateProperties`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Update book properties.
     * Update book properties.
     */
    async updateProperties(requestParameters: UpdatePropertiesRequest, initOverrides?: RequestInit): Promise<boolean> {
        const response = await this.updatePropertiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes a building and starts a book.
     * Conform a new building and init its code.
     */
    async validateRaw(requestParameters: ValidateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BookDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/books/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BuildingDTOToJSON(requestParameters.buildingDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BookDTOFromJSON(jsonValue));
    }

    /**
     * Takes a building and starts a book.
     * Conform a new building and init its code.
     */
    async validate(requestParameters: ValidateRequest, initOverrides?: RequestInit): Promise<BookDTO> {
        const response = await this.validateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
