/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MediaDTO,
    MediaDTOFromJSON,
    MediaDTOFromJSONTyped,
    MediaDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProceedingsDTO
 */
export interface ProceedingsDTO {
    /**
     * 
     * @type {string}
     * @memberof ProceedingsDTO
     */
    administration?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingsDTO
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingsDTO
     */
    date?: string;
    /**
     * 
     * @type {Array<MediaDTO>}
     * @memberof ProceedingsDTO
     */
    files?: Array<MediaDTO>;
    /**
     * 
     * @type {string}
     * @memberof ProceedingsDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingsDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingsDTO
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingsDTO
     */
    resolution?: string;
    /**
     * 
     * @type {string}
     * @memberof ProceedingsDTO
     */
    scope?: string;
}

export function ProceedingsDTOFromJSON(json: any): ProceedingsDTO {
    return ProceedingsDTOFromJSONTyped(json, false);
}

export function ProceedingsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProceedingsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'administration': !exists(json, 'administration') ? undefined : json['administration'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'files': !exists(json, 'files') ? undefined : ((json['files'] as Array<any>).map(MediaDTOFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'resolution': !exists(json, 'resolution') ? undefined : json['resolution'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
    };
}

export function ProceedingsDTOToJSON(value?: ProceedingsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'administration': value.administration,
        'code': value.code,
        'date': value.date,
        'files': value.files === undefined ? undefined : ((value.files as Array<any>).map(MediaDTOToJSON)),
        'id': value.id,
        'name': value.name,
        'notes': value.notes,
        'resolution': value.resolution,
        'scope': value.scope,
    };
}

