import { Button } from "@movicoders/movicoders-components";
import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import TextFieldGrid from "../../../../../../../components/text-fields/textfield-grid";
import { Colors } from "../../../../../../../constants";
import ReportViewModel from "../../../../../../../viewmodels/report/report-view-model";
import { ReportStyle } from "../../../../../report-style";
import { UsageDTO } from "../../../../../../../clients";

export const UsageForm: React.FC<{ vm: ReportViewModel; setCurrentPage?: React.Dispatch<React.SetStateAction<UsageDTO[]>>; currentPage?: UsageDTO[] }> =
  observer(({ vm, setCurrentPage, currentPage }) => {
    const classes = ReportStyle();
    const [usage, setUsage] = useState(vm.editManualUsage ? vm.manualUsage : vm.buildingComposition.usages!![vm.selectedBuildingCompositionUsage!!]);

    const { enqueueSnackbar } = useSnackbar();
    const handleOpenSnackBar = (snackKey: string, variants: undefined | "default" | "error" | "success" | "warning" | "info") => {
      enqueueSnackbar(snackKey, { variant: variants, preventDuplicate: true, key: snackKey, autoHideDuration: 3000 });
    };

    const save = () => {
      if (vm.editManualUsage) {
        vm.manualUsage = usage;
        vm.buildingComposition.usages!![vm.selectedBuildingCompositionUsage!!] = usage;
        const newUsages = [...vm.manualUsages];
        newUsages!![vm.selectedBuildingCompositionUsage!!] = usage;
        vm.manualUsages = [...newUsages];
        vm.editManualUsage = false;
        handleOpenSnackBar(vm.translate("usage.success"), "info");
        if (vm.buildingComposition.usages && setCurrentPage) setCurrentPage(vm.buildingComposition.usages);
      } else {
        vm.buildingComposition.usages!![vm.selectedBuildingCompositionUsage!!] = usage;
        const newUsages = [...vm.manualUsages];
        newUsages!![vm.selectedBuildingCompositionUsage!!] = usage;
        vm.manualUsages = [...newUsages];
        vm.selectedBuildingCompositionUsage = undefined;
        handleOpenSnackBar(vm.translate("usage.success"), "info");
        if (vm.buildingComposition.usages && setCurrentPage) setCurrentPage(vm.buildingComposition.usages);
      }
    };

    return (
      <React.Fragment>
        <Grid item xs={12} md={12} className={classes.report_displayGrid}>
          <Grid item xs={12} md={12} className={classes.report_generalDataGrid}>
            <TextFieldGrid
              id="usageFormBl"
              title={vm.translate("report.block0.usage.block")}
              onChange={(evt: any) => setUsage({ ...usage, block: evt.target.value })}
              value={usage && usage.block ? usage.block : ""}
              gridTextField={{ xs: 6, md: 6 }}
            />
            <TextFieldGrid
              id="usageFormEs"
              title={vm.translate("report.block0.usage.stair")}
              onChange={(evt: any) => setUsage({ ...usage, stair: evt.target.value })}
              value={usage && usage.stair ? usage.stair : ""}
              gridTextField={{ xs: 6, md: 6 }}
            />
          </Grid>

          <Grid item xs={12} md={12} className={classes.report_generalDataGrid}>
            <TextFieldGrid
              id="usageFormFloor"
              title={vm.translate("report.block0.usage.floor")}
              onChange={(evt: any) => setUsage({ ...usage, floor: evt.target.value })}
              value={usage && usage.floor ? usage.floor : ""}
              gridTextField={{ xs: 6, md: 6 }}
            />
            <TextFieldGrid
              id="usageFormDoor"
              title={vm.translate("report.block0.usage.door")}
              onChange={(evt: any) => setUsage({ ...usage, door: evt.target.value })}
              value={usage && usage.door ? usage.door : ""}
              gridTextField={{ xs: 6, md: 6 }}
            />
          </Grid>

          <Grid item xs={12} md={12} className={classes.report_saveButton}>
            <Button
              id="usageFormConfirm"
              text={vm.translate("save")}
              onClick={save}
              textColor={Colors.white}
              backgroundColor={Colors.buttonPrimary}
              hoverColor={Colors.buttonPrimary}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  });
