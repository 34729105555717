import { ProvinceDTO as ProvinceClient } from "../../clients";

export default class Province {

  code: string;
  id: string;
  name: string;

  constructor(
    code: string,
    id: string,
    name: string
  ) {
    this.code = code;
    this.id = id;
    this.name = name;
  }

  static fromClientProvince(province: ProvinceClient) {
    return new Province(
      province.code!,
      province.id!,
      province.name!
    );
  }

  static toClientProvince(province: Province) {
    const res: ProvinceClient = {};

    res.code = province.code;
    res.id = province.id;
    res.name = province.name;

    return res;
  }

  static fromClientProvinceList(provinces: ProvinceClient[]) {
    return provinces.map(this.fromClientProvince);
  }
}