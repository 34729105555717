import { DialogMovicoders, ConfirmDialogStrings } from "@movicoders/movicoders-components";
import { observer } from "mobx-react-lite";
import React from "react";
import { Colors } from "../../../constants";
import ProfileViewModel from "../../../viewmodels/profile/profile-view-model";
import { ProfileFormSystemAdmin } from "./profile-form-system-admin";

export const ProfileDialogSystemAdmin: React.FC<{ vm: ProfileViewModel }> = observer(({ vm }) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    vm.editUserProfileCollegeAdmin = false;
    vm.editUserProfileCollegeAdminProfile = null;
    vm.technicianProfileAdminEdit = null;
    setOpen(false);
  };

  const dialogStrings: ConfirmDialogStrings = {
    title: vm.translate("profile.user.profile_edit_admin") + vm.technicianProfileAdminEdit?.username,
    content: <ProfileFormSystemAdmin vm={vm} />,
  };

  React.useEffect(() => {
    if (vm.editUserProfileCollegeAdminProfile) {
      (async () => {
        const res = await vm.getProfileById().catch((err) => {
          vm.editUserProfileCollegeAdminProfile = null;
          vm.editUserProfileCollegeAdmin = false;
          setOpen(false);
        });
        if (res !== null) setOpen(true);
        else {
          setOpen(false);
          vm.editUserProfileCollegeAdmin = false;
        }
      })();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {open && (
        <DialogMovicoders
          id="profileDialogMovicoders"
          open={true}
          draggable
          dialogConfig={dialogStrings}
          onConfirm={() => {}}
          onClose={() => handleClose()}
          closeWithX
          maxWidth={"md"}
          backgroundColorTitle={Colors.primary}
          fullWidth
        />
      )}
    </>
  );
});
