import React from "react"
import { Grid } from "@mui/material"
import { observer } from "mobx-react-lite"
import { UrbanDataSection } from "./components/urban-data"
import { UrbanPlaneSection } from "./components/urban-plane"
import ReportViewModel from "../../../../../viewmodels/report/report-view-model"

export const ReportUrbanDataB: React.FC<{ vm: ReportViewModel }> = observer(({ vm }) => {
  return (
    <Grid container>
      <UrbanDataSection vm={vm}/>
      <UrbanPlaneSection vm={vm} />
    </Grid>
  )
})