import { Api } from "../../../infrastructure/data/api-client";
import { BaseRepository } from "../../../infrastructure/data/repositories/base-repository";
import IUsersApi from "../iusers-api";


export default class UsersProfileRepository extends BaseRepository<IUsersApi> {
  constructor() {
    super(Api.UsersProfileApi, false)
  }

  async getTechnicianByCredentialsID() {
    const client = await this.apiClient
    return await client!.getTechnicianByCredentialsID();
  }
}