/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressDTO0,
    AddressDTO0FromJSON,
    AddressDTO0FromJSONTyped,
    AddressDTO0ToJSON,
} from './';

/**
 * 
 * @export
 * @interface VoucherListItemDTO
 */
export interface VoucherListItemDTO {
    /**
     * 
     * @type {AddressDTO0}
     * @memberof VoucherListItemDTO
     */
    addressDTO?: AddressDTO0;
    /**
     * 
     * @type {string}
     * @memberof VoucherListItemDTO
     */
    amount?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VoucherListItemDTO
     */
    collegeNames?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof VoucherListItemDTO
     */
    collegeTypes?: Array<VoucherListItemDTOCollegeTypesEnum>;
    /**
     * 
     * @type {number}
     * @memberof VoucherListItemDTO
     */
    created?: number;
    /**
     * 
     * @type {string}
     * @memberof VoucherListItemDTO
     */
    credentials?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherListItemDTO
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherListItemDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherListItemDTO
     */
    fare?: VoucherListItemDTOFareEnum;
    /**
     * 
     * @type {string}
     * @memberof VoucherListItemDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherListItemDTO
     */
    idNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherListItemDTO
     */
    mobilePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherListItemDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherListItemDTO
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherListItemDTO
     */
    platform?: VoucherListItemDTOPlatformEnum;
    /**
     * 
     * @type {string}
     * @memberof VoucherListItemDTO
     */
    role?: VoucherListItemDTORoleEnum;
    /**
     * 
     * @type {string}
     * @memberof VoucherListItemDTO
     */
    status?: VoucherListItemDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof VoucherListItemDTO
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherListItemDTO
     */
    transactionId?: string;
    /**
     * 
     * @type {string}
     * @memberof VoucherListItemDTO
     */
    type?: VoucherListItemDTOTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum VoucherListItemDTOCollegeTypesEnum {
    ArchitectsCollege = 'ARCHITECTS_COLLEGE',
    RiggersAndTechnicalArchitects = 'RIGGERS_AND_TECHNICAL_ARCHITECTS'
}/**
* @export
* @enum {string}
*/
export enum VoucherListItemDTOFareEnum {
    Aragon = 'ARAGON',
    Cgate = 'CGATE',
    Standard = 'STANDARD'
}/**
* @export
* @enum {string}
*/
export enum VoucherListItemDTOPlatformEnum {
    Ceca = 'CECA'
}/**
* @export
* @enum {string}
*/
export enum VoucherListItemDTORoleEnum {
    CollegeAdministrator = 'COLLEGE_ADMINISTRATOR',
    HomeownersPresident = 'HOMEOWNERS_PRESIDENT',
    Owner = 'OWNER',
    PropertyManager = 'PROPERTY_MANAGER',
    Representative = 'REPRESENTATIVE',
    SystemAdministrator = 'SYSTEM_ADMINISTRATOR',
    Technician = 'TECHNICIAN'
}/**
* @export
* @enum {string}
*/
export enum VoucherListItemDTOStatusEnum {
    Accepted = 'ACCEPTED',
    Rejected = 'REJECTED',
    Requested = 'REQUESTED',
    Used = 'USED'
}/**
* @export
* @enum {string}
*/
export enum VoucherListItemDTOTypeEnum {
    Legal = 'LEGAL',
    Physical = 'PHYSICAL'
}

export function VoucherListItemDTOFromJSON(json: any): VoucherListItemDTO {
    return VoucherListItemDTOFromJSONTyped(json, false);
}

export function VoucherListItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): VoucherListItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addressDTO': !exists(json, 'addressDTO') ? undefined : AddressDTO0FromJSON(json['addressDTO']),
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'collegeNames': !exists(json, 'collegeNames') ? undefined : json['collegeNames'],
        'collegeTypes': !exists(json, 'collegeTypes') ? undefined : json['collegeTypes'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'credentials': !exists(json, 'credentials') ? undefined : json['credentials'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'fare': !exists(json, 'fare') ? undefined : json['fare'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'idNumber': !exists(json, 'idNumber') ? undefined : json['idNumber'],
        'mobilePhone': !exists(json, 'mobilePhone') ? undefined : json['mobilePhone'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'platform': !exists(json, 'platform') ? undefined : json['platform'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'transactionId': !exists(json, 'transactionId') ? undefined : json['transactionId'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function VoucherListItemDTOToJSON(value?: VoucherListItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addressDTO': AddressDTO0ToJSON(value.addressDTO),
        'amount': value.amount,
        'collegeNames': value.collegeNames,
        'collegeTypes': value.collegeTypes,
        'created': value.created,
        'credentials': value.credentials,
        'currency': value.currency,
        'email': value.email,
        'fare': value.fare,
        'id': value.id,
        'idNumber': value.idNumber,
        'mobilePhone': value.mobilePhone,
        'name': value.name,
        'phone': value.phone,
        'platform': value.platform,
        'role': value.role,
        'status': value.status,
        'surname': value.surname,
        'transactionId': value.transactionId,
        'type': value.type,
    };
}

