import { ActivateTechnicianRequest, AgentDTO, DeactivateTechnicianRequest, ExtendedSignupTechnician, SearchAgentByCredentialsIdRequest, SearchAgentRequest, TechnicianDeletionRequest, TechnicianDTO, TechnicianInvitationRequest, TechnicianSignUpApprovalRequest, TechnicianSignUpRejectionRequest, TechnicianSignUpRequestRequest, TechnicianUpdateRequest, UpdateAgentRequest, UserManagementApi as UserManagementApiService } from "../../../clients";
import { BaseAPI, Configuration } from '../../../clients/runtime';
import IUserManagementApi from "../iuser-management-api";

export class UserManagementApi extends BaseAPI implements IUserManagementApi {
    apiClient: UserManagementApiService;

    constructor(configuration?: Configuration) {
        super(configuration);
        this.apiClient = new UserManagementApiService(configuration);
    }

    async searchAgentByCredentialsId(requestParameters: SearchAgentByCredentialsIdRequest): Promise<AgentDTO> {
        return await this.apiClient.searchAgentByCredentialsId(requestParameters);
    }

    async updateAgent(requestParameters: UpdateAgentRequest): Promise<AgentDTO> {
        return await this.apiClient.updateAgent(requestParameters);
    }

    async searchAgent(requestParameters: SearchAgentRequest): Promise<AgentDTO> {
        const response = await this.apiClient.searchAgent(requestParameters);
        return response;
    }

    async technicianDeletion(requestParameters: TechnicianDeletionRequest): Promise<TechnicianDTO> {
        const response = await this.apiClient.technicianDeletion(requestParameters);
        return response;
    }

    async technicianSignUpApproval(requestParameters: TechnicianSignUpApprovalRequest): Promise<TechnicianDTO> {
        const response = await this.apiClient.technicianSignUpApproval(requestParameters);
        return response;
    }

    async technicianSignUpRejection(requestParameters: TechnicianSignUpRejectionRequest): Promise<TechnicianDTO> {
        const response = await this.apiClient.technicianSignUpRejection(requestParameters);
        return response;
    }

    async technicianSignUpRequest(requestParameters: TechnicianSignUpRequestRequest): Promise<TechnicianDTO> {
        const response = await this.apiClient.technicianSignUpRequest(requestParameters);
        return response
    }

    async technicianUpdate(requestParameters: TechnicianUpdateRequest): Promise<TechnicianDTO> {
        const response = await this.apiClient.technicianUpdate(requestParameters);
        return response
    }

    async technicianInvitation(requestParameters: TechnicianInvitationRequest): Promise<Boolean> {
        const response = await this.apiClient.technicianInvitation(requestParameters);
        return response
    }

    async getAllTechniciansByCollege(requestParameters: RequestInit): Promise<ExtendedSignupTechnician[]> {
        const response = await this.apiClient.getAllTechniciansByCollege(requestParameters);
        return response
    }

    async activateTechnician(requestParameters: ActivateTechnicianRequest): Promise<TechnicianDTO> {
        const response = await this.apiClient.activateTechnician(requestParameters);
        return response
    }

    async deactivateTechnician(requestParameters: DeactivateTechnicianRequest): Promise<TechnicianDTO> {
        const response = await this.apiClient.deactivateTechnician(requestParameters);
        return response
    }
}