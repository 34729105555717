import { ConductorOneChildActive, Router, ScreenBase } from "@frui.ts/screens"
import { action } from "mobx";
import RootViewModel from "../root-view-model";

@Router.registerRoute({ name: Router.Self, route: "landing" })
export default class LandingViewModel extends ConductorOneChildActive<ScreenBase> {
    constructor() {
        super();
        this.navigationName = "landing"
        this.nameValue = "section.landing"
    }

    parent = this.parent as RootViewModel;

    @action login(){
        this.parent.navigate("login",{})
    }

    @action register(){
        this.parent.navigate("register",{})
    }
}