const preffix = "REACT_APP_";
const suffix = "_URL_" + process.env.REACT_APP_ENV;

export const getEnvURL_BE = () => {
  return process.env[preffix + "BE" + suffix] || "";
}

export const getAuthURL_BE = () => {
  return process.env[preffix + "AUTH" + suffix] || "";
}

export const getCECAGatewayURL = () => {
  return process.env[preffix + "CECA_GATEWAY" + suffix] || "";
}