/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TechnicianStatisticsDTO
 */
export interface TechnicianStatisticsDTO {
    /**
     * 
     * @type {number}
     * @memberof TechnicianStatisticsDTO
     */
    inProgress?: number;
    /**
     * 
     * @type {number}
     * @memberof TechnicianStatisticsDTO
     */
    publicResidential?: number;
    /**
     * 
     * @type {number}
     * @memberof TechnicianStatisticsDTO
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof TechnicianStatisticsDTO
     */
    withFinancialAid?: number;
}

export function TechnicianStatisticsDTOFromJSON(json: any): TechnicianStatisticsDTO {
    return TechnicianStatisticsDTOFromJSONTyped(json, false);
}

export function TechnicianStatisticsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechnicianStatisticsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inProgress': !exists(json, 'inProgress') ? undefined : json['inProgress'],
        'publicResidential': !exists(json, 'publicResidential') ? undefined : json['publicResidential'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'withFinancialAid': !exists(json, 'withFinancialAid') ? undefined : json['withFinancialAid'],
    };
}

export function TechnicianStatisticsDTOToJSON(value?: TechnicianStatisticsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inProgress': value.inProgress,
        'publicResidential': value.publicResidential,
        'total': value.total,
        'withFinancialAid': value.withFinancialAid,
    };
}

