/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HealthinessImprovementDTO
 */
export interface HealthinessImprovementDTO {
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    commonWasteStorage?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    commonWasteStorageMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    commonZonesVentilation?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    commonZonesVentilationMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    dripIrrigation?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    dripIrrigationMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    garageAiring?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    garageAiringMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    houseAiring?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    houseAiringMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    poolWaterTreatment?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    poolWaterTreatmentMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    protectionMeasuresOnRiskZones?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    protectionMeasuresOnRiskZonesMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    singularElementsWaterTreatment?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    singularElementsWaterTreatmentMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    smellOnCommonWasteStorage?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    smellOnCommonWasteStorageMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    storageRoomAiring?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    storageRoomAiringMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    wasteWarehouseAiring?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    wasteWarehouseAiringMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    waterMeteringIndividualDevices?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    waterMeteringIndividualDevicesMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    waterSavingDevices?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    waterSavingDevicesMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    waterSavingDevicesOnHouses?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    waterSavingDevicesOnHousesMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    waterSupplyConditions?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    waterSupplyConditionsMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    waterTreatmentSystems?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthinessImprovementDTO
     */
    waterTreatmentSystemsMedia?: string;
}

export function HealthinessImprovementDTOFromJSON(json: any): HealthinessImprovementDTO {
    return HealthinessImprovementDTOFromJSONTyped(json, false);
}

export function HealthinessImprovementDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): HealthinessImprovementDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'commonWasteStorage': !exists(json, 'commonWasteStorage') ? undefined : json['commonWasteStorage'],
        'commonWasteStorageMedia': !exists(json, 'commonWasteStorageMedia') ? undefined : json['commonWasteStorageMedia'],
        'commonZonesVentilation': !exists(json, 'commonZonesVentilation') ? undefined : json['commonZonesVentilation'],
        'commonZonesVentilationMedia': !exists(json, 'commonZonesVentilationMedia') ? undefined : json['commonZonesVentilationMedia'],
        'dripIrrigation': !exists(json, 'dripIrrigation') ? undefined : json['dripIrrigation'],
        'dripIrrigationMedia': !exists(json, 'dripIrrigationMedia') ? undefined : json['dripIrrigationMedia'],
        'garageAiring': !exists(json, 'garageAiring') ? undefined : json['garageAiring'],
        'garageAiringMedia': !exists(json, 'garageAiringMedia') ? undefined : json['garageAiringMedia'],
        'houseAiring': !exists(json, 'houseAiring') ? undefined : json['houseAiring'],
        'houseAiringMedia': !exists(json, 'houseAiringMedia') ? undefined : json['houseAiringMedia'],
        'poolWaterTreatment': !exists(json, 'poolWaterTreatment') ? undefined : json['poolWaterTreatment'],
        'poolWaterTreatmentMedia': !exists(json, 'poolWaterTreatmentMedia') ? undefined : json['poolWaterTreatmentMedia'],
        'protectionMeasuresOnRiskZones': !exists(json, 'protectionMeasuresOnRiskZones') ? undefined : json['protectionMeasuresOnRiskZones'],
        'protectionMeasuresOnRiskZonesMedia': !exists(json, 'protectionMeasuresOnRiskZonesMedia') ? undefined : json['protectionMeasuresOnRiskZonesMedia'],
        'singularElementsWaterTreatment': !exists(json, 'singularElementsWaterTreatment') ? undefined : json['singularElementsWaterTreatment'],
        'singularElementsWaterTreatmentMedia': !exists(json, 'singularElementsWaterTreatmentMedia') ? undefined : json['singularElementsWaterTreatmentMedia'],
        'smellOnCommonWasteStorage': !exists(json, 'smellOnCommonWasteStorage') ? undefined : json['smellOnCommonWasteStorage'],
        'smellOnCommonWasteStorageMedia': !exists(json, 'smellOnCommonWasteStorageMedia') ? undefined : json['smellOnCommonWasteStorageMedia'],
        'storageRoomAiring': !exists(json, 'storageRoomAiring') ? undefined : json['storageRoomAiring'],
        'storageRoomAiringMedia': !exists(json, 'storageRoomAiringMedia') ? undefined : json['storageRoomAiringMedia'],
        'wasteWarehouseAiring': !exists(json, 'wasteWarehouseAiring') ? undefined : json['wasteWarehouseAiring'],
        'wasteWarehouseAiringMedia': !exists(json, 'wasteWarehouseAiringMedia') ? undefined : json['wasteWarehouseAiringMedia'],
        'waterMeteringIndividualDevices': !exists(json, 'waterMeteringIndividualDevices') ? undefined : json['waterMeteringIndividualDevices'],
        'waterMeteringIndividualDevicesMedia': !exists(json, 'waterMeteringIndividualDevicesMedia') ? undefined : json['waterMeteringIndividualDevicesMedia'],
        'waterSavingDevices': !exists(json, 'waterSavingDevices') ? undefined : json['waterSavingDevices'],
        'waterSavingDevicesMedia': !exists(json, 'waterSavingDevicesMedia') ? undefined : json['waterSavingDevicesMedia'],
        'waterSavingDevicesOnHouses': !exists(json, 'waterSavingDevicesOnHouses') ? undefined : json['waterSavingDevicesOnHouses'],
        'waterSavingDevicesOnHousesMedia': !exists(json, 'waterSavingDevicesOnHousesMedia') ? undefined : json['waterSavingDevicesOnHousesMedia'],
        'waterSupplyConditions': !exists(json, 'waterSupplyConditions') ? undefined : json['waterSupplyConditions'],
        'waterSupplyConditionsMedia': !exists(json, 'waterSupplyConditionsMedia') ? undefined : json['waterSupplyConditionsMedia'],
        'waterTreatmentSystems': !exists(json, 'waterTreatmentSystems') ? undefined : json['waterTreatmentSystems'],
        'waterTreatmentSystemsMedia': !exists(json, 'waterTreatmentSystemsMedia') ? undefined : json['waterTreatmentSystemsMedia'],
    };
}

export function HealthinessImprovementDTOToJSON(value?: HealthinessImprovementDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'commonWasteStorage': value.commonWasteStorage,
        'commonWasteStorageMedia': value.commonWasteStorageMedia,
        'commonZonesVentilation': value.commonZonesVentilation,
        'commonZonesVentilationMedia': value.commonZonesVentilationMedia,
        'dripIrrigation': value.dripIrrigation,
        'dripIrrigationMedia': value.dripIrrigationMedia,
        'garageAiring': value.garageAiring,
        'garageAiringMedia': value.garageAiringMedia,
        'houseAiring': value.houseAiring,
        'houseAiringMedia': value.houseAiringMedia,
        'poolWaterTreatment': value.poolWaterTreatment,
        'poolWaterTreatmentMedia': value.poolWaterTreatmentMedia,
        'protectionMeasuresOnRiskZones': value.protectionMeasuresOnRiskZones,
        'protectionMeasuresOnRiskZonesMedia': value.protectionMeasuresOnRiskZonesMedia,
        'singularElementsWaterTreatment': value.singularElementsWaterTreatment,
        'singularElementsWaterTreatmentMedia': value.singularElementsWaterTreatmentMedia,
        'smellOnCommonWasteStorage': value.smellOnCommonWasteStorage,
        'smellOnCommonWasteStorageMedia': value.smellOnCommonWasteStorageMedia,
        'storageRoomAiring': value.storageRoomAiring,
        'storageRoomAiringMedia': value.storageRoomAiringMedia,
        'wasteWarehouseAiring': value.wasteWarehouseAiring,
        'wasteWarehouseAiringMedia': value.wasteWarehouseAiringMedia,
        'waterMeteringIndividualDevices': value.waterMeteringIndividualDevices,
        'waterMeteringIndividualDevicesMedia': value.waterMeteringIndividualDevicesMedia,
        'waterSavingDevices': value.waterSavingDevices,
        'waterSavingDevicesMedia': value.waterSavingDevicesMedia,
        'waterSavingDevicesOnHouses': value.waterSavingDevicesOnHouses,
        'waterSavingDevicesOnHousesMedia': value.waterSavingDevicesOnHousesMedia,
        'waterSupplyConditions': value.waterSupplyConditions,
        'waterSupplyConditionsMedia': value.waterSupplyConditionsMedia,
        'waterTreatmentSystems': value.waterTreatmentSystems,
        'waterTreatmentSystemsMedia': value.waterTreatmentSystemsMedia,
    };
}

