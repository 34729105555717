import { TextField as TextFieldMovicoders } from "@movicoders/movicoders-components";
import { Grid, GridSize, TextField } from "@mui/material";
import { CSSProperties } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { ReportStyle } from "../../views/reports/report-style";
import { FormValidator } from "../../views/reports/reportComponents/block0/urbanData/components/FormValidator";

export interface ITextField {
  title?: string;
  gridTextField?: grid;
  width?: string;
  style?: CSSProperties;
  onChange?: Function;
  onClick?: Function;
  value?: string;
  gridClassName?: string;
  textFieldClassName?: string;
  defaultValue?: string;
  disabled?: boolean;
  variant?: "outlined" | "filled" | "standard";
  useMaterial?: boolean;
  error?: boolean;
  onError?: () => void;
  errorText?: string;
  type?: string;
  marginRight?: boolean;
  required?: boolean;
  allowNegativeNumbers?: boolean;
  inputProps?: any;
  numeric?: boolean;
  decimalNotAllowed?: boolean;
  charLimit?: number;
  onBlur?: () => boolean;
  id: string;
  form?: string;
  validator?: FormValidator;
}

type grid = { xs: GridSize; md: GridSize };
//eslint-disable-next-line

function TextFieldGrid({
  variant = "outlined",
  numeric = false,
  allowNegativeNumbers = false,
  marginRight = true,
  useMaterial = false,
  onChange = () => {},
  onClick = () => {},
  onError = () => {},
  charLimit = 4000,
  form,
  validator,
  ...props
}: ITextField) {
  const classes = ReportStyle();
  const [modifiedValue, setModifiedValue] = React.useState<string>("");
  const [error, setError] = useState(props.required && (props.value === undefined || props.value === null || props.value === "") ? true : false);
  const NUMERIC_REGEXP = allowNegativeNumbers ? new RegExp(/^-?\d*,?\d*$/) : new RegExp(/^\d*,?\d*$/);

  useEffect(() => {
    const a = props.value?.replaceAll(".", ",");
    setModifiedValue(a ?? "");
  }, [props.value]);

  const onBlur = (e: any) => {
    if (props.required === false || props.required === undefined) return;
    setError(!e.target.value || e.target.value === "");
  };

  const onFocus = (e: any) => {
    if (props.required === false || props.required === undefined) return;
    if (!e.target.value || e.target.value === "") {
      setError(true);
    }
  };

  useEffect(() => {
    if (onError && error === true) {
      onError();
    }
    validator?.setErrorForKey(form, props.id, error);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <React.Fragment>
      {!useMaterial ? (
        <Grid
          display={"flex"}
          item
          className={props.gridClassName ?? classes.report_textFieldGrid}
          xs={props.gridTextField?.xs}
          md={props.gridTextField?.md}
          onClick={() => onClick()}
        >
          {numeric ? (
            <TextField
              onBlur={onBlur}
              onFocus={onFocus}
              error={error}
              style={{ width: props.width || "100%" }}
              variant={variant}
              size={"small"}
              id={props.id}
              required={props.required || false}
              disabled={props.disabled}
              defaultValue={props.defaultValue}
              inputProps={[props.inputProps, { style: props.style, className: props.textFieldClassName ?? classes.report_textField }]}
              label={props.title || ""}
              type={props.type || "text"}
              value={modifiedValue}
              // @ts-ignore
              onChange={(evt: any) => {
                if (evt.target.value.length <= charLimit) {
                  props.decimalNotAllowed && (evt.target.value = evt.target.value.replaceAll(",", ""));
                  if (NUMERIC_REGEXP.test(evt.target.value)) {
                    const val = evt.target.value?.replaceAll(",", ".");
                    evt.target.value = val;
                    onChange(evt);
                  }
                }
              }}
              className={props.textFieldClassName ?? classes.report_textField}
            />
          ) : (
            <TextField
              onBlur={onBlur}
              onFocus={onFocus}
              error={error}
              style={{ width: props.width || "100%" }}
              variant={variant}
              size={"small"}
              id={props.id}
              required={props.required || false}
              disabled={props.disabled}
              defaultValue={props.defaultValue}
              inputProps={[props.inputProps, { style: props.style, className: props.textFieldClassName ?? classes.report_textField }]}
              label={props.title || ""}
              type={props.type || "text"}
              value={props.value}
              // @ts-ignore
              onChange={(evt: any) => {
                if (evt.target.value.length <= charLimit) {
                  onChange(evt);
                  validator?.setErrorForKey(form, props.id, false);
                }
              }}
              className={props.textFieldClassName ?? classes.report_textField}
            />
          )}

          {marginRight ? <div style={{ marginRight: 5 }} /> : <></>}
        </Grid>
      ) : (
        <Grid
          display={"flex"}
          item
          className={props.gridClassName ?? classes.report_textFieldGrid}
          xs={props.gridTextField?.xs}
          md={props.gridTextField?.md}
        >
          {numeric ? (
            <TextFieldMovicoders
              id={props.id}
              value={modifiedValue}
              variant={variant}
              size={"small"}
              disabled={props.disabled}
              label={props.title || ""}
              width={props.width || "100%"}
              error={error || false}
              errorText={props.errorText || ""}
              defaultValue={props.defaultValue}
              required={props.required || false}
              type={props.type || "text"}
              // @ts-ignore
              onChange={(evt: any) => {
                if (evt.target.value.length <= charLimit) {
                  props.decimalNotAllowed && (evt.target.value = evt.target.value.replaceAll(",", ""));
                  if (NUMERIC_REGEXP.test(evt.target.value)) {
                    let val = evt.target.value?.replaceAll(",", ".");
                    evt.target.value = val;
                    onChange(evt);
                  }
                }
              }}
              onClick={onClick}
              classTextField={props.textFieldClassName ?? classes.report_textField}
              inputProps={[props.inputProps, { style: props.style, className: props.textFieldClassName ?? classes.report_textField }]}
              onKeyDown={onClick}
            />
          ) : (
            <TextFieldMovicoders
              id={props.id}
              value={props.value}
              variant={variant}
              size={"small"}
              disabled={props.disabled}
              label={props.title || ""}
              width={props.width || "100%"}
              error={error || props.error || false}
              errorText={props.errorText || ""}
              defaultValue={props.defaultValue}
              required={props.required || false}
              type={props.type || "text"}
              // @ts-ignore
              onChange={(evt: any) => {
                if (evt.target.value.length <= charLimit) {
                  onChange(evt);
                }
              }}
              onClick={onClick}
              classTextField={props.textFieldClassName ?? classes.report_textField}
              inputProps={[props.inputProps, { style: props.style, className: props.textFieldClassName ?? classes.report_textField }]}
              onKeyDown={onClick}
            />
          )}

          {marginRight ? <div style={{ marginRight: 5 }} /> : <></>}
        </Grid>
      )}
    </React.Fragment>
  );
}

export default TextFieldGrid;
