import React from "react"
import { observer } from "mobx-react-lite"
import { UserDialog } from "./userscomponents/UsersDialog"
import { registerView, ViewComponent } from "@frui.ts/views"
import UserDetailViewModel from "../../viewmodels/users/user-detail-view-model"

const UserDetailView: ViewComponent<UserDetailViewModel> = observer(({ vm }) => {
  return (
    <React.Fragment>
      <UserDialog vm={vm} />
    </React.Fragment>
  )
})

export default registerView(UserDetailView, UserDetailViewModel)