import {
  AgentParticipationDTO,
  AgentParticipationDTORoleEnum,
  AgentParticipationDTOTypeEnum,
  BuildingCompositionDTO,
  BuildingCompositionDTOTypologyEnum,
  BuildingDTO,
  BuildingLocationInfoDTO,
  BuildingLocationInfoDTOBuildingOccupancyTypeEnum,
  MediaDTO,
  MultimediaDTO,
  ParcelDTO,
  PropertyDTO,
  UsageDTO,
} from "../clients/models";
import Building from "../data/entities/building-entity";
import Street from "../data/entities/street-entity";

export const fromMultimediaToMedia = (multimedia: MultimediaDTO): MediaDTO => {
  return { id: multimedia.id, name: multimedia.name };
};

export const fromMultimediaToMediaAll = (multimedia: MultimediaDTO[]): MediaDTO[] => {
  return multimedia.map(fromMultimediaToMedia);
};

export const fromBuildingToBuildingDTO = (building: Building, street: Street): BuildingDTO => {
  return {
    community: building.community,
    dwellings: building.dwellings,
    floorsAboveGround: building.floorsAboveGround,
    floorsUnderGround: building.floorsUnderGround,
    name: building.name,
    parcels: building.parcels,
    portalNumber: building.portalNumber,
    portalNumberLetter: building.portalNumberLetter,
    postalCode: building.postalCode,
    properties: building.properties,
    province: building.province,
    secondaryPortalLetter: building.secondaryPortalLetter,
    secondaryPortalNumber: building.secondaryPortalNumber,
    techniciansParticipation: [],
    street: street?.code ?? "",
    town: building.town,
    type: building.type,
    year: building.year,
  };
};

export const getBuildingComposition = (building?: Building): BuildingCompositionDTO => {
  return {
    builtArea: sumTotalBuildingSurface(building?.properties),
    builtOnYear: building?.year,
    complementaryFiles: [],
    dwellings: building?.dwellings.reduce((prev: number, curr: number) => curr + prev) ?? 0,
    dwellingsByFloor: 0,
    elevatorsCount: 0,
    floorsOverGround: building?.floorsAboveGround.reduce((prev: number, curr: number) => curr + prev) ?? 0,
    floorsUnderGround: building?.floorsUnderGround.reduce((prev: number, curr: number) => curr + prev) ?? 0,
    heightOverGround: 0,
    lastRehabYear: 0,
    media: [],
    otherElements: "",
    parcelArea: sumTotalParcelsSurface(building?.parcels),
    parkingCountInBuilding: 0,
    parkingPlacesInBuilding: 0,
    premisesCount: getTotalBuildingPremises(building?.properties),
    sharedPremisesCount: 0,
    stairsCount: 0,
    storageRoomsCount: 0,
    typology: BuildingCompositionDTOTypologyEnum.ClosedBlockBuilding,
    usages: getPropertiesUsages(building?.properties),
  };
};

const getPropertiesUsages = (properties?: PropertyDTO[]): UsageDTO[] => {
  let usages: UsageDTO[] = [];

  properties?.forEach((prop: PropertyDTO) => {
    usages = usages.concat(
      prop.usages?.map((usage: UsageDTO) => ({ ...usage, cadastralReference: `${prop.pc1}${prop.pc2}${prop.car}${prop.cc1}${prop.cc2}` })) ?? []
    );
  });

  return usages;
};

const sumTotalBuildingSurface = (properties?: PropertyDTO[]): number => {
  return (
    properties
      ?.map((property: PropertyDTO) => property.usages ?? [])
      ?.map((usages: UsageDTO[]) =>
        usages?.map((usage: UsageDTO) => usage.surface).reduce((prevVal: number = 0, currVal: number = 0) => prevVal + currVal, 0)
      )
      .reduce((prevVal: number = 0, currVal: number = 0) => prevVal + currVal, 0) ?? 0
  );
};

const sumTotalParcelsSurface = (parcels?: ParcelDTO[]): number => {
  const parcelSize = parcels?.map((parcel: ParcelDTO) => parcel.area).reduce((previous: number = 0, current: number = 0) => previous + current, 0) ?? 0;
  return parcelSize;
};

const getTotalBuildingPremises = (properties?: PropertyDTO[]): number => {
  return properties?.filter((property: PropertyDTO) => property.mainUsage && property.mainUsage !== "Residencial").length ?? 0;
};

export const getBuildingLocationInfo = (street: Street, building?: Building): BuildingLocationInfoDTO => {
  const groupedProperties = building?.properties?.reduce((prev: any, curr: any) => {
    const key = curr.pc1 + curr.pc2;
    if (!prev[key]) {
      prev[key] = [curr];
    } else {
      prev[key].push(curr);
    }
    return prev;
  }, {});

  const largestParcel = Object.keys(groupedProperties)
    .map((key: string) => ({ key, value: groupedProperties[key] }))
    .reduce((prev: any, curr: any) => (prev.value.length > curr.value.length ? prev : curr));
  const largestParcelFiltered = building?.parcels.find((p) => p.pc1 ?? "" + p.pc2 ?? "" === largestParcel.key);

  return {
    address: {
      block: "",
      cadastralParcelReference: `${largestParcelFiltered?.pc1}${largestParcelFiltered?.pc2}`,
      community: building?.community ?? "",
      door: "",
      floor: "",
      portalNumber: building?.portalNumber ?? "",
      portalNumberLetter: building?.portalNumberLetter ?? "",
      postalCode: building?.postalCode ?? 0,
      province: building?.province ?? "",
      secondaryPortalNumber: building?.secondaryPortalNumber ?? "",
      secondaryPortalNumberLetter: building?.secondaryPortalLetter ?? "",
      stair: "",
      street: street?.code ?? "",
      streetName: street?.name ?? "",
      streetType: street?.type ?? "",
      town: building?.town ?? "",
      latitude: largestParcelFiltered?.latitude ?? 0,
      longitude: largestParcelFiltered?.longitude ?? 0,
    },
    referenceDescription: "",
    buildingOccupancyType: BuildingLocationInfoDTOBuildingOccupancyTypeEnum.SingleFamily,
    buildingPolygon: largestParcelFiltered?.buildingPolygons ?? [],
    parcelPolygon: largestParcelFiltered?.polygons ?? [],
    sharesCommonElementsWithContiguousBuildings: "No",
  };
};

export const getDefaultParticipantAgent = (): AgentParticipationDTO => {
  return {
    block: "",
    community: "",
    door: "",
    floor: "",
    portalNumber: "",
    portalNumberLetter: "",
    postalCode: 0,
    province: "",
    secondaryPortalNumber: "",
    secondaryPortalLetter: "",
    stair: "",
    streetCode: "",
    streetName: "",
    streetType: "",
    town: "",
    email: "",
    id: "",
    idNumber: "",
    mobilePhone: "",
    participation: 0,
    phone: "",
    representer: undefined,
    representing: undefined,
    role: AgentParticipationDTORoleEnum.Owner,
    surname: "",
    type: AgentParticipationDTOTypeEnum.Physical,
    book: undefined,
    name: "",
    townName: undefined,
  };
};

export const mockParticipantAgent = (participant?: Partial<AgentParticipationDTO>): AgentParticipationDTO => {
  return { ...getDefaultParticipantAgent(), ...participant };
};

// @ts-ignore
export const groupBy = (array, funct) =>
  array
    // @ts-ignore
    .map(typeof funct === "function" ? funct : (val) => val[funct])
    // @ts-ignore
    .reduce((acc, val, i) => {
      acc[val] = (acc[val] || []).concat(array[i]);
      return acc;
    }, {});
