/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FirstBlockDTO,
    FirstBlockDTOFromJSON,
    FirstBlockDTOFromJSONTyped,
    FirstBlockDTOToJSON,
    FrontpageDTO,
    FrontpageDTOFromJSON,
    FrontpageDTOFromJSONTyped,
    FrontpageDTOToJSON,
    NewGeneralDataDTO,
    NewGeneralDataDTOFromJSON,
    NewGeneralDataDTOFromJSONTyped,
    NewGeneralDataDTOToJSON,
    SecondBlockDTO,
    SecondBlockDTOFromJSON,
    SecondBlockDTOFromJSONTyped,
    SecondBlockDTOToJSON,
    SummaryDTO,
    SummaryDTOFromJSON,
    SummaryDTOFromJSONTyped,
    SummaryDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewPrintingBookDTO
 */
export interface NewPrintingBookDTO {
    /**
     * 
     * @type {FirstBlockDTO}
     * @memberof NewPrintingBookDTO
     */
    firstBlock?: FirstBlockDTO;
    /**
     * 
     * @type {FrontpageDTO}
     * @memberof NewPrintingBookDTO
     */
    frontpage?: FrontpageDTO;
    /**
     * 
     * @type {NewGeneralDataDTO}
     * @memberof NewPrintingBookDTO
     */
    generalData?: NewGeneralDataDTO;
    /**
     * 
     * @type {SecondBlockDTO}
     * @memberof NewPrintingBookDTO
     */
    secondBlock?: SecondBlockDTO;
    /**
     * 
     * @type {SummaryDTO}
     * @memberof NewPrintingBookDTO
     */
    summary?: SummaryDTO;
}

export function NewPrintingBookDTOFromJSON(json: any): NewPrintingBookDTO {
    return NewPrintingBookDTOFromJSONTyped(json, false);
}

export function NewPrintingBookDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewPrintingBookDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstBlock': !exists(json, 'firstBlock') ? undefined : FirstBlockDTOFromJSON(json['firstBlock']),
        'frontpage': !exists(json, 'frontpage') ? undefined : FrontpageDTOFromJSON(json['frontpage']),
        'generalData': !exists(json, 'generalData') ? undefined : NewGeneralDataDTOFromJSON(json['generalData']),
        'secondBlock': !exists(json, 'secondBlock') ? undefined : SecondBlockDTOFromJSON(json['secondBlock']),
        'summary': !exists(json, 'summary') ? undefined : SummaryDTOFromJSON(json['summary']),
    };
}

export function NewPrintingBookDTOToJSON(value?: NewPrintingBookDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstBlock': FirstBlockDTOToJSON(value.firstBlock),
        'frontpage': FrontpageDTOToJSON(value.frontpage),
        'generalData': NewGeneralDataDTOToJSON(value.generalData),
        'secondBlock': SecondBlockDTOToJSON(value.secondBlock),
        'summary': SummaryDTOToJSON(value.summary),
    };
}

