import {
  AccessibilityEvaluationDTO,
  AddActionRegistryDocumentationRequest,
  AddAgentByIdRequest,
  AgentParticipationDTO,
  BookDTO,
  BuildingCompositionDTO,
  BuildingConstructionDescriptionDTO,
  BuildingDTO,
  BuildingLocationInfoDTO,
  CecaSignatureResponse,
  CustomTechnicianParticipationPageDTO,
  DeleteAgentByIdRequest,
  EditAgentByIdRequest,
  EnergyEfficiencyCertificateDTO,
  GetBookByIdRequest,
  GetBooksByTechnicianByFilterRequest,
  GetNewPrintingBookByIdRequest,
  GetPolygonsByBookIdRequest,
  GetUsagePageByBookIdRequest,
  GetUsagesByBookIdRequest,
  ImprovementActionDTO,
  InitBooManualRequest,
  InitBookRequest,
  InitPaymentRequest,
  InspectionDTO,
  LegalDataDTO,
  LicenseDTO,
  MaintenancePlanDTO,
  MediaDTO,
  NewPrintingBookDTO,
  PolygonInfoDTO,
  PrintingBookDTO,
  ProceedingsDTO,
  ShortcomingDTO,
  TechnicianParticipationDTO,
  UpdatePropertiesRequest,
  UrbanDataDTO,
  UsageDTO,
  UsagePageDTO,
  UsagePlanDTO,
  ViewAdminTechnicianParticipationPageDTO,
} from "../../../clients";
import { Api } from "../../../infrastructure/data/api-client";
import { BaseRepository } from "../../../infrastructure/data/repositories/base-repository";
import { IBookApi } from "../ibook-api";

export class BookRepository extends BaseRepository<IBookApi> {
  constructor() {
    super(Api.BookApi, false);
  }

  async deleteAgentById(requestParameters: DeleteAgentByIdRequest): Promise<boolean> {
    return (await this.apiClient).deleteAgentById(requestParameters);
  }

  async updateProperties(requestParameters: UpdatePropertiesRequest): Promise<boolean> {
    return (await this.apiClient).updateProperties(requestParameters);
  }

  async addMaintenanceContracts(c: AddActionRegistryDocumentationRequest): Promise<boolean> {
    return await (await this.apiClient).addMaintenanceContractDocumentation(c);
  }
  async addRecommendationsAndBestPractices(c: AddActionRegistryDocumentationRequest): Promise<boolean> {
    return await (await this.apiClient).addBestPracticeDocumentation(c);
  }
  async addActionRegistryDocumentation(c: AddActionRegistryDocumentationRequest): Promise<boolean> {
    return await (await this.apiClient).addActionRegistryDocumentation(c);
  }

  addAccesibilityEvaluation = async (id: string, accesibilityEvaluation: AccessibilityEvaluationDTO): Promise<boolean> => {
    return await (await this.apiClient).addAccessibilityEvaluation(id, accesibilityEvaluation);
  };

  addAgent = async (agentParticipationDto: AddAgentByIdRequest): Promise<AgentParticipationDTO> => {
    return await (await this.apiClient).addAgentById(agentParticipationDto);
  };

  editAgent = async (agentParticipationDto: EditAgentByIdRequest): Promise<AgentParticipationDTO> => {
    return await (await this.apiClient).editAgentById(agentParticipationDto);
  };

  addBuildingNormalizedDescription = async (id: string, buildingConstructionDescriptionDto: BuildingConstructionDescriptionDTO): Promise<boolean> => {
    return await (await this.apiClient).addBuildingNormalizedDescription(id, buildingConstructionDescriptionDto);
  };

  addEnergyEfficiencyCertificate = async (id: string, energyEfficiencyCertificateDto: EnergyEfficiencyCertificateDTO): Promise<boolean> => {
    return await (await this.apiClient).addEnergyEfficiencyCertificate(id, energyEfficiencyCertificateDto);
  };

  addImprovementAction = async (id: string, improvementActionDto: ImprovementActionDTO): Promise<boolean> => {
    return await (await this.apiClient).addImprovementAction(id, improvementActionDto);
  };

  addInspection = async (id: string, inspectionDTO: InspectionDTO): Promise<boolean> => {
    return await (await this.apiClient).addInspection(id, inspectionDTO);
  };

  addLicense = async (id: string, licenseDto: LicenseDTO): Promise<boolean> => {
    return await (await this.apiClient).addLicense(id, licenseDto);
  };

  addOtherDocumentation = async (id: string, bookMediaDto: MediaDTO): Promise<boolean> => {
    return await (await this.apiClient).addOtherDocumentation(id, bookMediaDto);
  };

  addProceedings = async (id: string, proceedingsDto: ProceedingsDTO): Promise<boolean> => {
    return await (await this.apiClient).addProceedings(id, proceedingsDto);
  };

  addShortcomingToInspection = async (id: string, inspectionId: string, shortcomingDTO: ShortcomingDTO): Promise<BookDTO> => {
    return await (await this.apiClient).addShortcomingToInspection(id, inspectionId, shortcomingDTO);
  };

  addTechnician = async (id: string, technician: TechnicianParticipationDTO): Promise<boolean> => {
    return await (await this.apiClient).addTechnician(id, technician);
  };

  deleteTechnician = async (id: string, credentialsId: string): Promise<boolean> => {
    return await (await this.apiClient).deleteTechnician(id, credentialsId);
  };

  getBookById = async (req: GetBookByIdRequest): Promise<BookDTO> => {
    return await (await this.apiClient).getBookById(req);
  };

  getBooksByTechnician = async (offset: number, limit: number): Promise<CustomTechnicianParticipationPageDTO> => {
    return await (await this.apiClient).getBooksByTechnician(offset, limit);
  };

  getBooksByTechnicianByFilter = async (params: GetBooksByTechnicianByFilterRequest): Promise<ViewAdminTechnicianParticipationPageDTO> => {
    return await (await this.apiClient).getBooksByTechnicianByFilter(params);
  };

  getPrintingBookById = async (id: string): Promise<PrintingBookDTO> => {
    return await (await this.apiClient).getPrintingBookById(id);
  };

  initBook = async (requestParameters: InitBookRequest): Promise<BookDTO> => {
    return await (await this.apiClient).initBook(requestParameters);
  };

  initBookManual = async (requestParameters: InitBooManualRequest): Promise<BookDTO> => {
    return await (await this.apiClient).initBooManual(requestParameters);
  };

  initPayment = async (requestParameters: InitPaymentRequest): Promise<CecaSignatureResponse> => {
    return await (await this.apiClient).initPayment(requestParameters);
  };

  setBuildingComposition = async (id: string, buildingCompositionDTO: BuildingCompositionDTO): Promise<boolean> => {
    return await (await this.apiClient).setBuildingComposition(id, buildingCompositionDTO);
  };

  setLegalData = async (id: string, legalDataDto: LegalDataDTO): Promise<boolean> => {
    return await (await this.apiClient).setLegalData(id, legalDataDto);
  };

  setLocation = async (id: string, buildingLocationInfoDto: BuildingLocationInfoDTO): Promise<boolean> => {
    return await (await this.apiClient).setLocation(id, buildingLocationInfoDto);
  };

  setUrbanData = async (id: string, urbanDataDto: UrbanDataDTO): Promise<boolean> => {
    return await (await this.apiClient).setUrbanData(id, urbanDataDto);
  };

  validate = async (buildingDto: BuildingDTO): Promise<BookDTO> => {
    return await (await this.apiClient).validate(buildingDto);
  };

  getStatisticsFromTechnicianId = async (id: string) => {
    return await (await this.apiClient).getStatisticsFromTechnicianId(id);
  };

  addIeeDocumentation = async (id: string, documentation: MediaDTO) => {
    return await (await this.apiClient).addIeeDocumentation(id, documentation);
  };

  addIteDocumentation = async (id: string, documentation: MediaDTO) => {
    return await (await this.apiClient).addIteDocumentation(id, documentation);
  };

  deleteMediaByIdFromBook = async (id: string, mediaId: string) => {
    return await (await this.apiClient).deleteMediaByIdFromBook(id, mediaId);
  };

  getNewPrintingBookById = async (requestParameters: GetNewPrintingBookByIdRequest, initOverrides?: RequestInit): Promise<NewPrintingBookDTO> => {
    return await (await this.apiClient).getNewPrintingBookById(requestParameters);
  };

  deleteBook = async (id: string) => {
    return await (await this.apiClient).deleteBook(id);
  };

  async setMaintenance(id: string, maintenancePlanDto: MaintenancePlanDTO): Promise<boolean> {
    return await (await this.apiClient).setMaintenance(id, maintenancePlanDto);
  }
  async addUsagePlan(id: string, usagePlan: UsagePlanDTO): Promise<boolean> {
    return await (await this.apiClient).addUsagePlan(id, usagePlan);
  }

  async deleteLicenseById(id: string, licenseId: string): Promise<boolean> {
    return await (await this.apiClient).deleteLicenseById(id, licenseId);
  }

  async deleteProceedingById(id: string, proceedingId: string): Promise<boolean> {
    return await (await this.apiClient).deleteProceedingById(id, proceedingId);
  }

  async getUsagesByBookId(requestParameters: GetUsagesByBookIdRequest): Promise<Array<UsageDTO>> {
    return await (await this.apiClient).getUsagesByBookId(requestParameters);
  }

  async getUsagePageByBookId(requestParameters: GetUsagePageByBookIdRequest): Promise<UsagePageDTO> {
    return await (await this.apiClient).getUsagePageByBookId(requestParameters);
  }

  async getPolygonsByBookId(requestParameters: GetPolygonsByBookIdRequest, initOverrides?: RequestInit): Promise<PolygonInfoDTO> {
    return await (await this.apiClient).getPolygonsByBookId(requestParameters);
  }


}
