/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * ExtendedSignupAgent extends Agent model to provide username and password for credentials generation purposes.
 * @export
 * @interface TechnicianInviteDTO
 */
export interface TechnicianInviteDTO {
    /**
     * 
     * @type {string}
     * @memberof TechnicianInviteDTO
     */
    buildingBookId?: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicianInviteDTO
     */
    invitedTechnicianEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicianInviteDTO
     */
    invitedTechnicianName?: string;
}

export function TechnicianInviteDTOFromJSON(json: any): TechnicianInviteDTO {
    return TechnicianInviteDTOFromJSONTyped(json, false);
}

export function TechnicianInviteDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechnicianInviteDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buildingBookId': !exists(json, 'buildingBookId') ? undefined : json['buildingBookId'],
        'invitedTechnicianEmail': !exists(json, 'invitedTechnicianEmail') ? undefined : json['invitedTechnicianEmail'],
        'invitedTechnicianName': !exists(json, 'invitedTechnicianName') ? undefined : json['invitedTechnicianName'],
    };
}

export function TechnicianInviteDTOToJSON(value?: TechnicianInviteDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buildingBookId': value.buildingBookId,
        'invitedTechnicianEmail': value.invitedTechnicianEmail,
        'invitedTechnicianName': value.invitedTechnicianName,
    };
}

