export const validEmail = new RegExp(
  //eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const validCif = new RegExp(
  //eslint-disable-next-line
  /((([X-Z])|([LM])){1}([-]?)((\d){7})([-]?)([A-Z]{1}))|((\d{8})([-]?)([A-Z]))/
);

export const DNI_REGEX = new RegExp(
  //eslint-disable-next-line
  /^(\d{8})([A-Z])$/
);

export const CIF_REGEX = new RegExp(
  //eslint-disable-next-line
  /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/
);

export const NIE_REGEX = new RegExp(
  //eslint-disable-next-line
  /^[XYZ]\d{7,8}[A-Z]$/
);
export const validPhoneNumber = new RegExp(
  //eslint-disable-next-line
  /^\+?[0-9]{0,2}\s?[0-9]+$/
);

export const maxLength = new RegExp(
  //eslint-disable-next-line
  /^.{244,255}$/
);

export const checkNumberOver1000 = new RegExp(
  //eslint-disable-next-line
  ///(?<=\d)(?=(?:\d\d\d)+(?!\d))/
  /\B(?=(\d{3})+(?!\d))/g
);
