import { Avatar, Button, TypographyTheme } from "@movicoders/movicoders-components";
import { Grid, Paper } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import movicodersLogo from "../../../assets/images/logoMovicoders.png";
import { Colors, FontSizes } from "../../../constants";
import ProfileViewModel from "../../../viewmodels/profile/profile-view-model";
import { ProfileStyle } from "../profile-styles";

export const ProfileAvatar: React.FC<{ vm: ProfileViewModel }> = observer(({ vm }) => {
  const classes = ProfileStyle();

  return (
    <Grid my={1}>
      <Paper elevation={3} className={classes.profile_paperUser}>
        <Avatar id="profileAvatar" height={"60px"} width={"60px"} src={movicodersLogo} />
        <TypographyTheme
          id="profileUserName"
          text={vm.profileUser ? vm.profileUser.name + " " + vm.profileUser.surname : ""}
          color={Colors.textPrimaryHeaders}
          bold
          size={FontSizes.h1}
        />
        <TypographyTheme
          id="profileUserCollegiated"
          text={vm.profileUser ? vm.profileUser!.collegiated!![0].college!!.name!!.toString() : ""}
          color={Colors.textPrimary}
          size={FontSizes.h4}
        />
        <hr style={{ width: "100%", border: "1px dashed " + Colors.hr }} />
        <Button
          id="profileAvatarButtonUpdate"
          text={vm.translate("profile.button.update")}
          width={"80%"}
          textColor={Colors.white}
          backgroundColor={Colors.buttonPrimary}
          hoverColor={Colors.buttonPrimary}
          variant={"outlined"}
          classButton={classes.profile_button}
          onClick={() => {
            vm.editUserProfile = true;
          }}
        />
      </Paper>
    </Grid>
  );
});
