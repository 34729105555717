/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FireSafetyImprovementDTO
 */
export interface FireSafetyImprovementDTO {
    /**
     * 
     * @type {string}
     * @memberof FireSafetyImprovementDTO
     */
    fireProtections?: string;
    /**
     * 
     * @type {string}
     * @memberof FireSafetyImprovementDTO
     */
    fireProtectionsMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof FireSafetyImprovementDTO
     */
    fireReactionWithMaterialsOnTheCommonPlaces?: string;
    /**
     * 
     * @type {string}
     * @memberof FireSafetyImprovementDTO
     */
    fireReactionWithMaterialsOnTheCommonPlacesMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof FireSafetyImprovementDTO
     */
    fireReactionWithMaterialsOnTheFacade?: string;
    /**
     * 
     * @type {string}
     * @memberof FireSafetyImprovementDTO
     */
    fireReactionWithMaterialsOnTheFacadeMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof FireSafetyImprovementDTO
     */
    partitioningOfSpecialRiskLocations?: string;
    /**
     * 
     * @type {string}
     * @memberof FireSafetyImprovementDTO
     */
    partitioningOfSpecialRiskLocationsMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof FireSafetyImprovementDTO
     */
    stairsProtection?: string;
    /**
     * 
     * @type {string}
     * @memberof FireSafetyImprovementDTO
     */
    stairsProtectionMedia?: string;
}

export function FireSafetyImprovementDTOFromJSON(json: any): FireSafetyImprovementDTO {
    return FireSafetyImprovementDTOFromJSONTyped(json, false);
}

export function FireSafetyImprovementDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FireSafetyImprovementDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fireProtections': !exists(json, 'fireProtections') ? undefined : json['fireProtections'],
        'fireProtectionsMedia': !exists(json, 'fireProtectionsMedia') ? undefined : json['fireProtectionsMedia'],
        'fireReactionWithMaterialsOnTheCommonPlaces': !exists(json, 'fireReactionWithMaterialsOnTheCommonPlaces') ? undefined : json['fireReactionWithMaterialsOnTheCommonPlaces'],
        'fireReactionWithMaterialsOnTheCommonPlacesMedia': !exists(json, 'fireReactionWithMaterialsOnTheCommonPlacesMedia') ? undefined : json['fireReactionWithMaterialsOnTheCommonPlacesMedia'],
        'fireReactionWithMaterialsOnTheFacade': !exists(json, 'fireReactionWithMaterialsOnTheFacade') ? undefined : json['fireReactionWithMaterialsOnTheFacade'],
        'fireReactionWithMaterialsOnTheFacadeMedia': !exists(json, 'fireReactionWithMaterialsOnTheFacadeMedia') ? undefined : json['fireReactionWithMaterialsOnTheFacadeMedia'],
        'partitioningOfSpecialRiskLocations': !exists(json, 'partitioningOfSpecialRiskLocations') ? undefined : json['partitioningOfSpecialRiskLocations'],
        'partitioningOfSpecialRiskLocationsMedia': !exists(json, 'partitioningOfSpecialRiskLocationsMedia') ? undefined : json['partitioningOfSpecialRiskLocationsMedia'],
        'stairsProtection': !exists(json, 'stairsProtection') ? undefined : json['stairsProtection'],
        'stairsProtectionMedia': !exists(json, 'stairsProtectionMedia') ? undefined : json['stairsProtectionMedia'],
    };
}

export function FireSafetyImprovementDTOToJSON(value?: FireSafetyImprovementDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fireProtections': value.fireProtections,
        'fireProtectionsMedia': value.fireProtectionsMedia,
        'fireReactionWithMaterialsOnTheCommonPlaces': value.fireReactionWithMaterialsOnTheCommonPlaces,
        'fireReactionWithMaterialsOnTheCommonPlacesMedia': value.fireReactionWithMaterialsOnTheCommonPlacesMedia,
        'fireReactionWithMaterialsOnTheFacade': value.fireReactionWithMaterialsOnTheFacade,
        'fireReactionWithMaterialsOnTheFacadeMedia': value.fireReactionWithMaterialsOnTheFacadeMedia,
        'partitioningOfSpecialRiskLocations': value.partitioningOfSpecialRiskLocations,
        'partitioningOfSpecialRiskLocationsMedia': value.partitioningOfSpecialRiskLocationsMedia,
        'stairsProtection': value.stairsProtection,
        'stairsProtectionMedia': value.stairsProtectionMedia,
    };
}

