/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CecaStatusResponse,
    CecaStatusResponseFromJSON,
    CecaStatusResponseToJSON,
    MovicodersBadRequestException,
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundException,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceException,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
} from '../models';

/**
 * 
 */
export class PaymentGatewayApi extends runtime.BaseAPI {

    /**
     * Online Notification for CECA Integration
     */
    async cecaNotifyRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<CecaStatusResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/ceca/notify`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CecaStatusResponseFromJSON(jsonValue));
    }

    /**
     * Online Notification for CECA Integration
     */
    async cecaNotify(initOverrides?: RequestInit): Promise<CecaStatusResponse> {
        const response = await this.cecaNotifyRaw(initOverrides);
        return await response.value();
    }

}
