
import { Card, TypographyTheme } from '@movicoders/movicoders-components'
import { Grid } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Colors, FontSizes } from '../../../../constants'
import DashboardViewModel from '../../../../viewmodels/dashboard/dashboard-view-model'
import { CardDetailStyles } from './CardDetailStyles'

export const CardDetail: React.FC<{ vm: DashboardViewModel; }> = observer(({ vm }) => {
  const classes = CardDetailStyles()

  return (
    <Grid container className={classes.cardMap_card}>
      <Card title={vm.translate("dashboard.card.detail.title")}
        id={"cardDetail"}
        content={
          <Grid container>
            <Grid xs={12} item md={12}>
              <TypographyTheme id='cardDetailAddress' color={Colors.primary} size={FontSizes.h2} text={vm.translate("dashboard.card.detail.address")} classTypography={classes.cardMap_cardTittle} />
              <TypographyTheme id="cardDetailAdd" size={16} classTypography={classes.cardMap_text} text={vm.markerSelected?.add as string || "- "} />
            </Grid>

            <Grid xs={12} item md={12}>
              <TypographyTheme id="cardDetailTypeBuilding" color={Colors.primary} size={FontSizes.h2} text={vm.translate("dashboard.card.detail.building.type")} classTypography={classes.cardMap_cardTittle} />
              <TypographyTheme id="cardDetailBuildingCategory" size={16} classTypography={classes.cardMap_text} text={vm.markerSelected?.buildingCategory as string || "- "} />
            </Grid>

            <Grid xs={12} item md={6}>
              <TypographyTheme id="cardDetailCardTitle" color={Colors.primary} size={FontSizes.h2} classTypography={classes.cardMap_cardTittle} text={vm.translate("dashboard.card.detail.building.efficiency")} />
              <TypographyTheme id="cardDetailEfficiency" size={16} classTypography={classes.cardMap_text} text={vm.markerSelected?.efficiency || "-"} />
            </Grid>
          </Grid>
        }
      />
    </Grid>
  )
})