/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Values,
    ValuesFromJSON,
    ValuesFromJSONTyped,
    ValuesToJSON,
} from './';

/**
 * 
 * @export
 * @interface BasicVoucherFilter
 */
export interface BasicVoucherFilter {
    /**
     * 
     * @type {Values}
     * @memberof BasicVoucherFilter
     */
    values?: Values;
}

export function BasicVoucherFilterFromJSON(json: any): BasicVoucherFilter {
    return BasicVoucherFilterFromJSONTyped(json, false);
}

export function BasicVoucherFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): BasicVoucherFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'values': !exists(json, 'values') ? undefined : ValuesFromJSON(json['values']),
    };
}

export function BasicVoucherFilterToJSON(value?: BasicVoucherFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'values': ValuesToJSON(value.values),
    };
}

