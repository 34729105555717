import { ConductorOneChildActive, Router, ScreenBase } from "@frui.ts/screens";
import i18n from "../../infrastructure/localization/i18n";
import { Network } from "../../infrastructure/network";
import RootViewModel from "../root-view-model";

@Router.registerRoute({ name: Router.Self, route: "reset" })
export default class ResetViewModel extends ConductorOneChildActive<ScreenBase> {
  navigationName: string = "reset";
  nameValue = "reset";
  parent = this.parent as RootViewModel;
  public showInToolbar: boolean = false;

  protected onDeactivate(close: boolean) {
    return super.onDeactivate(close);
  }

  async onActivate() {
    super.onActivate();
  }

  async resetPassword(passwd: string) {
    const token = "Bearer " + sessionStorage.getItem("token");
    await fetch(`${Network.BACKEND}/user-management/recovery/reset`, {
      method: "post",
      body: JSON.stringify({ value: passwd }),
      // @ts-ignore
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
  }

  translate(key: string): string {
    return i18n.t(key) ? i18n.t(key) : key;
  }
}
