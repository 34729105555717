export const scrollToTop = () => {
    window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth'
    });
}

export const scrollTo = (scrollCoor: number) => {
    window.scrollTo({
        left: 0,
        top: scrollCoor,
        behavior: 'smooth'
    })
}