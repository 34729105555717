import { registerView } from '@frui.ts/views'
import { observer } from "mobx-react-lite"
import React from 'react'
import RegisterViewModel from "../../viewmodels/register/register-view-model"
import { RegisterDialog } from "./components/register-dialog"

export const RegisterScreen: React.FC<{ vm: RegisterViewModel }> = observer(({ vm }) => {
  return (
    <RegisterDialog vm={vm} />
  )
})

registerView(RegisterScreen, RegisterViewModel);