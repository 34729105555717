/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GeneralDataDTO,
    GeneralDataDTOFromJSON,
    GeneralDataDTOFromJSONTyped,
    GeneralDataDTOToJSON,
    ImminentRiskDTO,
    ImminentRiskDTOFromJSON,
    ImminentRiskDTOFromJSONTyped,
    ImminentRiskDTOToJSON,
    ShortcomingDTO,
    ShortcomingDTOFromJSON,
    ShortcomingDTOFromJSONTyped,
    ShortcomingDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface InspectionDTO
 */
export interface InspectionDTO {
    /**
     * 
     * @type {string}
     * @memberof InspectionDTO
     */
    commonBuildingFacilitiesDeficienciesFinalResult?: InspectionDTOCommonBuildingFacilitiesDeficienciesFinalResultEnum;
    /**
     * 
     * @type {string}
     * @memberof InspectionDTO
     */
    commonBuildingFacilitiesDeficienciesNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof InspectionDTO
     */
    finalResult?: InspectionDTOFinalResultEnum;
    /**
     * 
     * @type {string}
     * @memberof InspectionDTO
     */
    finalResultNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof InspectionDTO
     */
    foundationDeficienciesFinalResult?: InspectionDTOFoundationDeficienciesFinalResultEnum;
    /**
     * 
     * @type {string}
     * @memberof InspectionDTO
     */
    foundationDeficienciesNotes?: string;
    /**
     * 
     * @type {GeneralDataDTO}
     * @memberof InspectionDTO
     */
    generalData?: GeneralDataDTO;
    /**
     * 
     * @type {string}
     * @memberof InspectionDTO
     */
    id?: string;
    /**
     * 
     * @type {ImminentRiskDTO}
     * @memberof InspectionDTO
     */
    imminentRisk?: ImminentRiskDTO;
    /**
     * 
     * @type {string}
     * @memberof InspectionDTO
     */
    roofsAndCoveringsDeficienciesFinalResult?: InspectionDTORoofsAndCoveringsDeficienciesFinalResultEnum;
    /**
     * 
     * @type {string}
     * @memberof InspectionDTO
     */
    roofsAndCoveringsDeficienciesNotes?: string;
    /**
     * 
     * @type {Array<ShortcomingDTO>}
     * @memberof InspectionDTO
     */
    shortcomings?: Array<ShortcomingDTO>;
    /**
     * 
     * @type {string}
     * @memberof InspectionDTO
     */
    structuralDeficienciesFinalResult?: InspectionDTOStructuralDeficienciesFinalResultEnum;
    /**
     * 
     * @type {string}
     * @memberof InspectionDTO
     */
    structuralDeficienciesNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof InspectionDTO
     */
    verticalEnclosuresDeficienciesFinalResult?: InspectionDTOVerticalEnclosuresDeficienciesFinalResultEnum;
    /**
     * 
     * @type {string}
     * @memberof InspectionDTO
     */
    verticalEnclosuresDeficienciesNotes?: string;
}

/**
* @export
* @enum {string}
*/
export enum InspectionDTOCommonBuildingFacilitiesDeficienciesFinalResultEnum {
    Approval = 'APPROVAL',
    MinorDeficienciesApproval = 'MINOR_DEFICIENCIES_APPROVAL',
    SeriousShortcomingsRejection = 'SERIOUS_SHORTCOMINGS_REJECTION',
    VerySeriousShortcomingsRejection = 'VERY_SERIOUS_SHORTCOMINGS_REJECTION'
}/**
* @export
* @enum {string}
*/
export enum InspectionDTOFinalResultEnum {
    Approval = 'APPROVAL',
    MinorDeficienciesApproval = 'MINOR_DEFICIENCIES_APPROVAL',
    SeriousShortcomingsRejection = 'SERIOUS_SHORTCOMINGS_REJECTION',
    VerySeriousShortcomingsRejection = 'VERY_SERIOUS_SHORTCOMINGS_REJECTION'
}/**
* @export
* @enum {string}
*/
export enum InspectionDTOFoundationDeficienciesFinalResultEnum {
    Approval = 'APPROVAL',
    MinorDeficienciesApproval = 'MINOR_DEFICIENCIES_APPROVAL',
    SeriousShortcomingsRejection = 'SERIOUS_SHORTCOMINGS_REJECTION',
    VerySeriousShortcomingsRejection = 'VERY_SERIOUS_SHORTCOMINGS_REJECTION'
}/**
* @export
* @enum {string}
*/
export enum InspectionDTORoofsAndCoveringsDeficienciesFinalResultEnum {
    Approval = 'APPROVAL',
    MinorDeficienciesApproval = 'MINOR_DEFICIENCIES_APPROVAL',
    SeriousShortcomingsRejection = 'SERIOUS_SHORTCOMINGS_REJECTION',
    VerySeriousShortcomingsRejection = 'VERY_SERIOUS_SHORTCOMINGS_REJECTION'
}/**
* @export
* @enum {string}
*/
export enum InspectionDTOStructuralDeficienciesFinalResultEnum {
    Approval = 'APPROVAL',
    MinorDeficienciesApproval = 'MINOR_DEFICIENCIES_APPROVAL',
    SeriousShortcomingsRejection = 'SERIOUS_SHORTCOMINGS_REJECTION',
    VerySeriousShortcomingsRejection = 'VERY_SERIOUS_SHORTCOMINGS_REJECTION'
}/**
* @export
* @enum {string}
*/
export enum InspectionDTOVerticalEnclosuresDeficienciesFinalResultEnum {
    Approval = 'APPROVAL',
    MinorDeficienciesApproval = 'MINOR_DEFICIENCIES_APPROVAL',
    SeriousShortcomingsRejection = 'SERIOUS_SHORTCOMINGS_REJECTION',
    VerySeriousShortcomingsRejection = 'VERY_SERIOUS_SHORTCOMINGS_REJECTION'
}

export function InspectionDTOFromJSON(json: any): InspectionDTO {
    return InspectionDTOFromJSONTyped(json, false);
}

export function InspectionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'commonBuildingFacilitiesDeficienciesFinalResult': !exists(json, 'commonBuildingFacilitiesDeficienciesFinalResult') ? undefined : json['commonBuildingFacilitiesDeficienciesFinalResult'],
        'commonBuildingFacilitiesDeficienciesNotes': !exists(json, 'commonBuildingFacilitiesDeficienciesNotes') ? undefined : json['commonBuildingFacilitiesDeficienciesNotes'],
        'finalResult': !exists(json, 'finalResult') ? undefined : json['finalResult'],
        'finalResultNotes': !exists(json, 'finalResultNotes') ? undefined : json['finalResultNotes'],
        'foundationDeficienciesFinalResult': !exists(json, 'foundationDeficienciesFinalResult') ? undefined : json['foundationDeficienciesFinalResult'],
        'foundationDeficienciesNotes': !exists(json, 'foundationDeficienciesNotes') ? undefined : json['foundationDeficienciesNotes'],
        'generalData': !exists(json, 'generalData') ? undefined : GeneralDataDTOFromJSON(json['generalData']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'imminentRisk': !exists(json, 'imminentRisk') ? undefined : ImminentRiskDTOFromJSON(json['imminentRisk']),
        'roofsAndCoveringsDeficienciesFinalResult': !exists(json, 'roofsAndCoveringsDeficienciesFinalResult') ? undefined : json['roofsAndCoveringsDeficienciesFinalResult'],
        'roofsAndCoveringsDeficienciesNotes': !exists(json, 'roofsAndCoveringsDeficienciesNotes') ? undefined : json['roofsAndCoveringsDeficienciesNotes'],
        'shortcomings': !exists(json, 'shortcomings') ? undefined : ((json['shortcomings'] as Array<any>).map(ShortcomingDTOFromJSON)),
        'structuralDeficienciesFinalResult': !exists(json, 'structuralDeficienciesFinalResult') ? undefined : json['structuralDeficienciesFinalResult'],
        'structuralDeficienciesNotes': !exists(json, 'structuralDeficienciesNotes') ? undefined : json['structuralDeficienciesNotes'],
        'verticalEnclosuresDeficienciesFinalResult': !exists(json, 'verticalEnclosuresDeficienciesFinalResult') ? undefined : json['verticalEnclosuresDeficienciesFinalResult'],
        'verticalEnclosuresDeficienciesNotes': !exists(json, 'verticalEnclosuresDeficienciesNotes') ? undefined : json['verticalEnclosuresDeficienciesNotes'],
    };
}

export function InspectionDTOToJSON(value?: InspectionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'commonBuildingFacilitiesDeficienciesFinalResult': value.commonBuildingFacilitiesDeficienciesFinalResult,
        'commonBuildingFacilitiesDeficienciesNotes': value.commonBuildingFacilitiesDeficienciesNotes,
        'finalResult': value.finalResult,
        'finalResultNotes': value.finalResultNotes,
        'foundationDeficienciesFinalResult': value.foundationDeficienciesFinalResult,
        'foundationDeficienciesNotes': value.foundationDeficienciesNotes,
        'generalData': GeneralDataDTOToJSON(value.generalData),
        'id': value.id,
        'imminentRisk': ImminentRiskDTOToJSON(value.imminentRisk),
        'roofsAndCoveringsDeficienciesFinalResult': value.roofsAndCoveringsDeficienciesFinalResult,
        'roofsAndCoveringsDeficienciesNotes': value.roofsAndCoveringsDeficienciesNotes,
        'shortcomings': value.shortcomings === undefined ? undefined : ((value.shortcomings as Array<any>).map(ShortcomingDTOToJSON)),
        'structuralDeficienciesFinalResult': value.structuralDeficienciesFinalResult,
        'structuralDeficienciesNotes': value.structuralDeficienciesNotes,
        'verticalEnclosuresDeficienciesFinalResult': value.verticalEnclosuresDeficienciesFinalResult,
        'verticalEnclosuresDeficienciesNotes': value.verticalEnclosuresDeficienciesNotes,
    };
}

