import { Button } from "@movicoders/movicoders-components"
import { Grid } from "@mui/material"
import { observer } from "mobx-react-lite"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import { Colors } from "../../../constants"
import Building from "../../../data/entities/building-entity"
import BuildingDetailViewModel from "../../../viewmodels/buildings/building-detail-view-model"
import { BuildingsStyle } from "../buildings-style"

export const BuildingForm: React.FC<{ vm: BuildingDetailViewModel }> = observer(({ vm }) => {
  const classes = BuildingsStyle()
  const [buildingsClone] = useState<Building[]>([])

  const { enqueueSnackbar } = useSnackbar();
  const handleOpenSnackBar = (snackKey: string, variants: undefined | "default" | "error" | "success" | "warning" | "info") => {
    enqueueSnackbar(snackKey, { variant: variants, preventDuplicate: true, key: snackKey, autoHideDuration: 3000 });
  }

  const save = async () => {
    (vm.item.name === "-1") ? vm.create().then((/*res: boolean*/) => {
      handleOpenSnackBar(vm.translate("user.error"), "error")
    }) : vm.update().then((/*res: boolean*/) => {
      handleOpenSnackBar(vm.translate("user.error"), "error")
    })
    handleOpenSnackBar(vm.translate("user.error"), "error")
    vm.requestClose();
    vm.parent.setSelected(undefined)
  }

  useEffect(() => {
    const buildings = vm.parent.items
    buildingsClone.length === 0 && buildings.forEach(val => /*buildingsClone.push(Object.assign({}, val))*/ { });
  })

  return (
    <Grid container direction={"column"}>
      {/*<TextField
        label={vm.translate("building.castastral.reference")}
        variant={"outlined"}
        disabled={vm.item.name !== "-1"}
        classTextField={classes.building_textField}
        defaultValue={vm.item.name}
        onChange={(e: any) => { vm.itemAux.castastralReference = e.target.value }}
      />
      <TextField
        label={vm.translate("building.address")}
        variant={"outlined"}
        classTextField={classes.building_textField}
        defaultValue={vm.item.address}
        onChange={(e: any) => { vm.itemAux.address = e.target.value }}
      />
      <TextField
        label={vm.translate("building.district")}
        variant={"outlined"}
        classTextField={classes.building_textField}
        defaultValue={vm.item.district}
        onChange={(e: any) => { vm.itemAux.district = e.target.value }}
      />
      <TextField
        label={vm.translate("building.total.surface")}
        variant={"outlined"}
        disabled={vm.item.id !== -1}
        classTextField={classes.building_textField}
        defaultValue={vm.item.totalSurface}
        onChange={(e: any) => { vm.itemAux.totalSurface = e.target.value }}
      />
      <TextField
        label={vm.translate("building.building.category")}
        variant={"outlined"}
        classTextField={classes.building_textField}
        defaultValue={vm.item.buildingCategory}
        onChange={(e: any) => { vm.itemAux.buildingCategory = e.target.value }}
      />
      <Grid item xs={12} md={12} className={classes.building_gridSwitch}>
        <Switch
          classSwitch={classes.building_switch}
          checked={vm.item.lift}
          onCheckedChanged={(checked: boolean) => vm.itemAux.lift = checked}
          color={Colors.primary}
          onLabel={vm.translate("building.lift")}
          offLabel={vm.translate("building.lift")}
        />
      </Grid>*/}
      <br />
      <Grid item className={classes.building_gridButton}>
        <Button
          id="buildingsButtonForm"
          text={vm.translate("save")}
          onClick={save}
          textColor={Colors.white} backgroundColor={Colors.buttonPrimary} hoverColor={Colors.buttonPrimary}
        />
      </Grid>
    </Grid>
  )
})