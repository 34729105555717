import { TechnicianStatisticsDTO } from "./../../../clients/models/TechnicianStatisticsDTO";
import { AdminParticipationListItemDTO, TechnicianListItemDTO } from "../../../clients";
import { Api } from "../../../infrastructure/data/api-client";
import { BaseRepository } from "../../../infrastructure/data/repositories/base-repository";

export default class CollegeAdminActionRepository extends BaseRepository<iCollegeAdminActionsApi> {
  constructor() {
    super(Api.CollegeAdminActionsApi, false);
  }

  async getTechniciansByCollegeAdmin() {
    return (await this.apiClient).getUserListForCollegeAdmin({});
  }

  async getStatisticsForCollegeAdmin(): Promise<TechnicianStatisticsDTO> {
    return (await this.apiClient).getStatisticsForCollegeAdmin();
  }

  async getBooksForCollegeAdmin(): Promise<AdminParticipationListItemDTO> {
    return (await this.apiClient).getBooksForCollegeAdmin();
  }
}

interface iCollegeAdminActionsApi extends IRepositoryCollegeAdminActions<TechnicianListItemDTO> {}

interface IRepositoryCollegeAdminActions<T> {
  getUserListForCollegeAdmin({}): Promise<T[]>;
  getStatisticsForCollegeAdmin(): Promise<TechnicianStatisticsDTO>;
  getBooksForCollegeAdmin(): Promise<AdminParticipationListItemDTO>;
}
