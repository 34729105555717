/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MediaPrintedDTO,
    MediaPrintedDTOFromJSON,
    MediaPrintedDTOFromJSONTyped,
    MediaPrintedDTOToJSON,
    NewAddressDTO,
    NewAddressDTOFromJSON,
    NewAddressDTOFromJSONTyped,
    NewAddressDTOToJSON,
    NewTechnicianDTO,
    NewTechnicianDTOFromJSON,
    NewTechnicianDTOFromJSONTyped,
    NewTechnicianDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface FrontpageDTO
 */
export interface FrontpageDTO {
    /**
     * 
     * @type {NewAddressDTO}
     * @memberof FrontpageDTO
     */
    address?: NewAddressDTO;
    /**
     * 
     * @type {string}
     * @memberof FrontpageDTO
     */
    buildingYear?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontpageDTO
     */
    cadastralReference?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof FrontpageDTO
     */
    image?: MediaPrintedDTO;
    /**
     * 
     * @type {Array<NewTechnicianDTO>}
     * @memberof FrontpageDTO
     */
    technicians?: Array<NewTechnicianDTO>;
}

export function FrontpageDTOFromJSON(json: any): FrontpageDTO {
    return FrontpageDTOFromJSONTyped(json, false);
}

export function FrontpageDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrontpageDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': !exists(json, 'address') ? undefined : NewAddressDTOFromJSON(json['address']),
        'buildingYear': !exists(json, 'buildingYear') ? undefined : json['buildingYear'],
        'cadastralReference': !exists(json, 'cadastralReference') ? undefined : json['cadastralReference'],
        'image': !exists(json, 'image') ? undefined : MediaPrintedDTOFromJSON(json['image']),
        'technicians': !exists(json, 'technicians') ? undefined : ((json['technicians'] as Array<any>).map(NewTechnicianDTOFromJSON)),
    };
}

export function FrontpageDTOToJSON(value?: FrontpageDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': NewAddressDTOToJSON(value.address),
        'buildingYear': value.buildingYear,
        'cadastralReference': value.cadastralReference,
        'image': MediaPrintedDTOToJSON(value.image),
        'technicians': value.technicians === undefined ? undefined : ((value.technicians as Array<any>).map(NewTechnicianDTOToJSON)),
    };
}

