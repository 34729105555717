/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StreetDTO
 */
export interface StreetDTO {
    /**
     * 
     * @type {string}
     * @memberof StreetDTO
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof StreetDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof StreetDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof StreetDTO
     */
    type?: string;
}

export function StreetDTOFromJSON(json: any): StreetDTO {
    return StreetDTOFromJSONTyped(json, false);
}

export function StreetDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreetDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function StreetDTOToJSON(value?: StreetDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'id': value.id,
        'name': value.name,
        'type': value.type,
    };
}

