import { BookMarkerDTO } from './../../../clients/models/BookMarkerDTO';
import { MapApi as MapServiceApi } from "../../../clients"
import { BaseAPI, Configuration } from '../../../clients/runtime';
import { IMapApi } from '../imap-api';

export class MapApi extends BaseAPI implements IMapApi {
    apiClient: MapServiceApi;

    constructor(configuration?: Configuration) {
        super(configuration);
        this.apiClient = new MapServiceApi(configuration);
    }


    async getMarkers(): Promise<BookMarkerDTO[]> {
      const response = await this.apiClient.getMarkersByTechnician();
      return response;
  }
}