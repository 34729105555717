import { Button, Icon, IconButton, TypographyTheme } from "@movicoders/movicoders-components";
import { IconsList } from "@movicoders/movicoders-components";
import { Checkbox, Divider, Grid, List, ListItem, Paper } from "@mui/material";
import React, { ReactFragment, useEffect, useState } from "react";
import { Colors, FontSizes } from "../../constants";
import SearchBox from "../SearchBox";
import ITransferList from "./itransfer-list";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { useTransferListStyles } from "./transfer-list-styles";

function TransferList<T>({
  initialData,
  selectedData,
  selectedDataSetter,
  rightPanelTitle = "",
  rightPanelSubtitle = "",
  leftPanelTitle = "",
  leftPanelSubtitle = "",
  searchPlaceholder = "",
  customToolbar,
  customToolbarPosition = "bottom",
  textGetter,
  textGetter2,
  listSortingFunction,
  refreshDataFunction,
  refreshTooltip
}: ITransferList<T>) {
  const [checkedItems, setCheckedItems] = useState<Array<T>>([]);
  const [leftData, setLeftData] = useState<Array<T>>([]);
  const [rightData, setRightData] = useState<Array<T>>([]);
  const [leftFilteredData, setLeftFilteredData] = useState<Array<T>>([]);
  const [rightFilteredData, setRightFilteredData] = useState<Array<T>>([]);
  const classes = useTransferListStyles();

  useEffect(() => {
    const initData = (listSortingFunction && listSortingFunction(initialData)) || initialData;
    const selectData = (listSortingFunction && listSortingFunction(selectedData ?? [])) || (selectedData ?? []);
    setLeftData([...initData]);
    setRightData([...selectData]);
    setLeftFilteredData([...initData]);
    setRightFilteredData([...selectData]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData]);

  const checkSelected = (checkedData: T[], listToCompare: T[]) => {
    return checkedData.filter((value: T) => listToCompare.indexOf(value) !== -1);
  };

  const presentItems = (itemList: T[], listToCompare: T[]) => {
    return itemList.filter((value: T) => listToCompare.indexOf(value) === -1);
  };

  const selectItem = (value: T) => {
    const itemIndex = checkedItems.indexOf(value);

    if (itemIndex === -1) {
      checkedItems.push(value);
    } else {
      checkedItems.splice(itemIndex, 1);
    }

    setCheckedItems([...checkedItems]);
  };

  const selectAll = (itemList: T[]) => {
    if (checkSelected(checkedItems, itemList).length === itemList.length) {
      setCheckedItems([...presentItems(checkedItems, itemList)]);
    } else {
      setCheckedItems([...checkedItems.concat(presentItems(itemList, checkedItems))]);
    }
  };

  const transferLeftSelected = () => {
    const newCheckedData = checkSelected(checkedItems, leftData);
    const changedLeftData = (listSortingFunction && listSortingFunction(presentItems(leftData, checkedItems))) || presentItems(leftData, checkedItems);
    const changedRightData = (listSortingFunction && listSortingFunction(rightData.concat(newCheckedData))) || rightData.concat(newCheckedData);
    setLeftData([...changedLeftData]);
    setLeftFilteredData([...changedLeftData]);
    setCheckedItems([...checkSelected(checkedItems, rightData)]);
    setRightData([...changedRightData]);
    setRightFilteredData([...changedRightData]);
    selectedDataSetter && selectedDataSetter([...changedRightData]);
  };

  const transferRightSelected = () => {
    const newCheckedData = checkSelected(checkedItems, rightData);
    const changedRightData = (listSortingFunction && listSortingFunction(presentItems(rightData, checkedItems))) || presentItems(rightData, checkedItems);
    const changedLeftData = (listSortingFunction && listSortingFunction(leftData.concat(newCheckedData))) || leftData.concat(newCheckedData);
    setRightData([...changedRightData]);
    setRightFilteredData([...changedRightData]);
    setCheckedItems([...checkSelected(checkedItems, leftData)]);
    setLeftData([...changedLeftData]);
    setLeftFilteredData([...changedLeftData]);
    selectedDataSetter && selectedDataSetter([...changedRightData]);
  };

  const checkTotalItems = (data: T[], filteredData: T[]): boolean => {
    return filteredData.length < data.length
      ? checkSelected(checkedItems, filteredData).length === filteredData.length && filteredData.length !== 0
      : checkSelected(checkedItems, data).length === data.length && data.length !== 0;
  };

  const formatSubtitle = (data: T[], filteredData: T[], subtitle: string): string => {
    return filteredData.length < data.length
      ? subtitle.concat(" ", `${checkSelected(checkedItems, filteredData).length}/${filteredData.length}`)
      : subtitle.concat(" ", `${checkSelected(checkedItems, data).length}/${data.length}`);
  };

  function renderRow(props: ListChildComponentProps) {
    const { index, style, data } = props;

    return (
      <ListItem style={{ ...style, marginBottom: "1px", display: "grid" }} key={index} button onClick={(evt) => selectItem(data[index])} disablePadding>
        <Grid container direction={"row"}>
          <Grid container item justifyContent="center" alignItems="center" xs={2}>
            <Checkbox color="primary" checked={checkedItems.indexOf(data[index]) !== -1} />
          </Grid>
          <Grid container item justifyContent={"flex-start"} alignItems={"center"} xs={10}>
            <TypographyTheme size={FontSizes.p2} textAlign="left" text={textGetter(data[index])} color={Colors.textPrimary} />
            <Divider />
            <TypographyTheme size={FontSizes.p2} textAlign="left" text={textGetter2(data[index])} color={Colors.textPrimary} />
          </Grid>
        </Grid>
      </ListItem>
    );
  }
  const buildList = (panelTitle: string, subtitle: string, data: T[], filteredData: T[], filteredSetter: (data: T[]) => void, refreshFunction?: () => void): ReactFragment => {
    return (
      <Grid>
        <Grid container justifyContent="center" className={classes.transferList_list_titleGrid}>
          <TypographyTheme size={FontSizes.h2} textAlign="center" text={panelTitle} color={Colors.textSecondaryHeaders} />
        </Grid>
        <Paper elevation={5} className={classes.transferList_paper}>
          <ListItem>
            <Grid container direction={"row"} justifyContent={"flex-end"} style={{ paddingRight: 10 }}>
              <Grid container item justifyContent="center" alignItems="center" xs={2} direction="column">
                <Grid container item justifyContent="center" alignItems="center">
                  <Checkbox
                    onClick={(evt) => selectAll(filteredData)}
                    disabled={filteredData.length < data.length ? filteredData.length === 0 : data.length === 0}
                    color="primary"
                    checked={checkTotalItems(data, filteredData)}
                  />
                </Grid>
                <Grid container item justifyContent="center" alignItems="center">
                  <TypographyTheme size={FontSizes.h4} textAlign="center" text={formatSubtitle(data, filteredData, subtitle)} color={Colors.textPrimary} />
                </Grid>
              </Grid>
              <Grid container item justifyContent="center" alignItems="center" xs={9}>
                <SearchBox
                  width="100%"
                  data={filteredData.length !== data.length ? data : filteredData}
                  filteredDataSetter={(items: T[]) => filteredSetter((listSortingFunction && listSortingFunction(items)) || items)}
                  placeholder={searchPlaceholder}
                  styleInputBase={{ width: "inherit" }}
                />
              </Grid>
              <Grid container item justifyContent="center" alignItems="center" xs={1} paddingLeft={1} >
              { refreshFunction && <IconButton
                id={"refreshParcelButton"}
                backgroundColor="transparent"
                tooltip={refreshTooltip}
                icon={<Icon color={Colors.buttonPrimary} element={IconsList.Refresh} />}
                onClick={refreshFunction}
              />}
            </Grid>
            </Grid>
          </ListItem>
          <Divider />
          <List className={classes.transferList_list}>
            <FixedSizeList itemData={filteredData} height={333} width={"100%"} itemSize={46} itemCount={filteredData.length} overscanCount={5}>
              {renderRow}
            </FixedSizeList>
          </List>
        </Paper>
      </Grid>
    );
  };

  return (
    <Grid item xs={12} className={classes.transferList_parentContainer}>
      {customToolbar && customToolbarPosition === "top" && customToolbar}
      <Grid container alignContent="center" justifyContent="center" alignItems="center">
        <Grid xs={12} md={5}>
          {buildList(leftPanelTitle, leftPanelSubtitle, leftData, leftFilteredData, setLeftFilteredData, refreshDataFunction)}
        </Grid>
        <Grid item xs={2}>
          <Grid container alignItems="center" direction="column">
            <Button
              variant="outlined"
              icon={<Icon element={IconsList.ArrowForward} className={classes.transferList_buttons_icons} />}
              iconPosition="center"
              classButton={classes.transferList_buttons}
              onClick={transferLeftSelected}
              text=""
            />
            <Button
              variant="outlined"
              icon={<Icon element={IconsList.ArrowBack} className={classes.transferList_buttons_icons} />}
              iconPosition="center"
              classButton={classes.transferList_buttons}
              onClick={transferRightSelected}
              text=""
            />
          </Grid>
        </Grid>
        <Grid xs={12} md={5}>
          {buildList(rightPanelTitle, rightPanelSubtitle, rightData, rightFilteredData, setRightFilteredData)}
        </Grid>
      </Grid>
      {customToolbar && customToolbarPosition === "bottom" && customToolbar}
    </Grid>
  );
}

export default TransferList;
