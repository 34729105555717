/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Pageable,
    PageableFromJSON,
    PageableFromJSONTyped,
    PageableToJSON,
    Sort,
    SortFromJSON,
    SortFromJSONTyped,
    SortToJSON,
    UsageDTO,
    UsageDTOFromJSON,
    UsageDTOFromJSONTyped,
    UsageDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface UsagePageDTO
 */
export interface UsagePageDTO {
    /**
     * 
     * @type {Array<UsageDTO>}
     * @memberof UsagePageDTO
     */
    content?: Array<UsageDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof UsagePageDTO
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UsagePageDTO
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UsagePageDTO
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof UsagePageDTO
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof UsagePageDTO
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof UsagePageDTO
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof UsagePageDTO
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof UsagePageDTO
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof UsagePageDTO
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof UsagePageDTO
     */
    totalReferences?: number;
}

export function UsagePageDTOFromJSON(json: any): UsagePageDTO {
    return UsagePageDTOFromJSONTyped(json, false);
}

export function UsagePageDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsagePageDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': !exists(json, 'content') ? undefined : ((json['content'] as Array<any>).map(UsageDTOFromJSON)),
        'first': !exists(json, 'first') ? undefined : json['first'],
        'last': !exists(json, 'last') ? undefined : json['last'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'numberOfElements': !exists(json, 'numberOfElements') ? undefined : json['numberOfElements'],
        'pageable': !exists(json, 'pageable') ? undefined : PageableFromJSON(json['pageable']),
        'size': !exists(json, 'size') ? undefined : json['size'],
        'sort': !exists(json, 'sort') ? undefined : SortFromJSON(json['sort']),
        'totalElements': !exists(json, 'totalElements') ? undefined : json['totalElements'],
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'totalReferences': !exists(json, 'totalReferences') ? undefined : json['totalReferences'],
    };
}

export function UsagePageDTOToJSON(value?: UsagePageDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content === undefined ? undefined : ((value.content as Array<any>).map(UsageDTOToJSON)),
        'first': value.first,
        'last': value.last,
        'number': value.number,
        'numberOfElements': value.numberOfElements,
        'pageable': PageableToJSON(value.pageable),
        'size': value.size,
        'sort': SortToJSON(value.sort),
        'totalElements': value.totalElements,
        'totalPages': value.totalPages,
        'totalReferences': value.totalReferences,
    };
}

