import { Colors, FontSizes } from '../../constants'
import { createStyles, makeStyles } from '@mui/styles'

export const ToolbarStyles = makeStyles(() => createStyles({
  toolbar_typography: {
    cursor: "pointer"
  },
  toolbar_listItemText: {
    '& .MuiTypography-body1': {
      fontSize: '14px'
    }
  },
  toolbar_list: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    '& .MuiListItem-gutters': {
      paddingLeft: "24px !important",
      paddingRight: "0px !important",
      padding: "16px !important"
    },
    '& .MuiListItem-button': {
      transition: "background-color 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important"
    }
  },
  toolbar_lisItemSelected: {
    color: Colors.primary + " !important",
    borderRight: "5px solid " + Colors.primary,
    backgroundColor: Colors.primaryOpacity + " !important",
  },
  toolbar_lisItemSelectedChildrens: {
    color: Colors.primary,
    borderRight: "5px solid " + Colors.primary,
    backgroundColor: Colors.primaryOpacity + " !important",
    paddingLeft: 20
  },
  toolbar_listItemNotSelected: {
    color: Colors.textGray,
    backgroundColor: Colors.white,
    fontSize: FontSizes.h2
  },
  toolbar_lisItemSelectedMiniDrawer: {
    backgroundColor: Colors.primaryOpacity + " !important",
    fontSize: FontSizes.h2,
    borderRight: "5px solid " + Colors.primary,
    color: Colors.primary,
    fontWeight: "inherit"
  },
  toolbar_listItemNotSelectedMiniDrawer: {
    color: Colors.textGray + "!important",
    backgroundColor: Colors.white,
    fontSize: FontSizes.h2
  },
  toolbar_listItemNotSelectedColapseMiniDrawer: {
    color: Colors.textGray,
    backgroundColor: Colors.infoColor,
    fontSize: FontSizes.h2
  },
  toolbar_listItemIcon: {
    '& .MuiSvgIcon-root': {
      width: 27,
      height: 27,
    }
  },
  toolbar_listItemIconSelected: {
    '& .MuiSvgIcon-root': {
      width: 27,
      height: 27,
      color: Colors.primary
    }
  },
  toolbar_listItemIconMiniDrawer: {
    '& .MuiSvgIcon-root': {
      width: 27,
      height: 27,
      color: Colors.primary
    }
  },
  toolbar_toolbarTitle: {
    fontSize: 25,
    fontFamily: "Arial",
    alignItems: "end",
    display: "inline-flex",
    cursor: "pointer"
  },
  toolbar_toolbarAvatar: {
    cursor: "pointer",
    marginTop: "0px !important"
  },
  toolbar_popoverTitles: {
    padding: 10,
    marginTop: 10,
    textAlign: "end"
  },
  toolbar_popover: {
    borderRadius: '0px !important',
    marginTop: 15,
    marginLeft: 15,
    boxShadow: "0 24px 54px rgb(0 0 0 / 15%), 0 4.5px 13.5px rgb(0 0 0 / 8%) !important"
  },
  toolbar_avatar: {
    color: Colors.primary
  },
  toolbar_gridContainer: {
    width: "350px !important",
    height: "200px !important"
  },
  toolbar_gridItemHeader: {
    display: "flex",
    marginTop: "8px !important",
    height: 8
  },
  toolbar_gridMargin: {
    marginLeft: "10px !important"
  },
  toolbar_gridItemStructure: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 20
  },
  toolbar_gridLogOff: {
    textAlign: "end",
    marginRight: "10px !important"
  }
}))