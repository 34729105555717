/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { observer } from "mobx-react-lite";
import { registerView, ViewComponent } from "@frui.ts/views";
import BackDropLoading from "../../../../../../components/backdrop-loading";
import ReportViewModel from "../../../../../../viewmodels/report/report-view-model";

const CadastralModal: ViewComponent<ReportViewModel> = observer(({ vm }) => {
  const [completed, setCompleted] = React.useState(0);
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    setTotal((vm.parent?.children.find((c) => c.navigationName === "report") as ReportViewModel).totalUnfinished);
    setCompleted((vm.parent?.children.find((c) => c.navigationName === "report") as ReportViewModel).completed);
  }, [(vm.parent?.children.find((c) => c.navigationName === "report") as ReportViewModel).completed]);

  return (
    <BackDropLoading
      completed={completed}
      total={total}
      estimatedTimeSubtitle={vm.translate("estimated.time")}
      estimatedTimeTitle={vm.translate("data.recovery.from.cadastre")}
      estimatedTimeUnit={vm.translate("recovered.properties")}
      showLoading={true}
    />
  );
});

export default registerView(CadastralModal, ReportViewModel);
