import { MediaApi as MediaService, MediaDTO, MultimediaDTO } from "../../../clients";
import { BaseAPI, Configuration } from "../../../clients/runtime";
import { SessionStoreFactory } from "../../../infrastructure/data/session-store-factory";
import { Network } from "../../../infrastructure/network";
import { IMultimediaApi } from "../imultimedia-api";

export class MediaApi extends BaseAPI implements IMultimediaApi {
  apiClient: MediaService;

  constructor(configuration?: Configuration) {
    super(configuration);
    this.apiClient = new MediaService(configuration);
  }

  createExternalMedia(media: MultimediaDTO): Promise<MultimediaDTO> {
    return this.apiClient.createExternalMultimedia({ multimediaDTO: media });
  }

  getAllMedia(offset: number, limit: number): Promise<MultimediaDTO[]> {
    return this.apiClient.getAllMultimedia({ offset, limit });
  }

  createMedia(file: Blob, name: string = ""): Promise<MediaDTO> {
    let filename = name;
    if (filename === "") {
      filename = (file as File).name;
    }
    filename = filename.normalize("NFKD").replace(/[\u0300-\u036f]/g, "");
    const tempFile = new File([file], filename, { type: file.type });
    const token = `Bearer ${SessionStoreFactory.getSessionStore().getToken()}`;
    const formData = new FormData();
    formData.append("body", tempFile);
    formData.append("type", tempFile.type);
    return fetch(`${Network.BACKEND}/media`, {
      method: "post",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: token,
      },
    }) as Promise<MediaDTO>;
  }

  getMediaById(id: string): Promise<MediaDTO> {
    return this.apiClient.getMultimediaByID({ id: id });
  }
  updateMultimediaById(id: string, maintainFile: boolean, file: Blob): Promise<MediaDTO> {
    return this.apiClient.updateMultimedia({ id: id, maintainFile: maintainFile, body: file });
  }
  deleteMultimediaById(id: string, mantainFile: boolean): Promise<MediaDTO> {
    return this.apiClient.deleteMultimedia({ id: id, maintainFile: mantainFile });
  }
}
