import React from "react"
import { observer } from "mobx-react-lite"
import { ViewComponent, registerView, View } from "@frui.ts/views"
import { BuildingTable } from "./buildings-components/BuildingsTable"
import BuildingViewModel from "../../viewmodels/buildings/building-view-model"

const BuildingsView: ViewComponent<BuildingViewModel> = observer(({ vm }) => {
  return (
    <React.Fragment>
      {vm.activeChild && <View vm={vm.activeChild} />}
      <BuildingTable vm={vm} />
    </React.Fragment>
  )
})

registerView(BuildingsView, BuildingViewModel)