/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VerticalStructureDTO
 */
export interface VerticalStructureDTO {
    /**
     * 
     * @type {number}
     * @memberof VerticalStructureDTO
     */
    loadingWalls?: number;
    /**
     * 
     * @type {number}
     * @memberof VerticalStructureDTO
     */
    others?: number;
    /**
     * 
     * @type {string}
     * @memberof VerticalStructureDTO
     */
    othersUnknownOthersValue?: string;
    /**
     * 
     * @type {number}
     * @memberof VerticalStructureDTO
     */
    pillars?: number;
    /**
     * 
     * @type {string}
     * @memberof VerticalStructureDTO
     */
    pillarsUnknownOthersValue?: string;
}

export function VerticalStructureDTOFromJSON(json: any): VerticalStructureDTO {
    return VerticalStructureDTOFromJSONTyped(json, false);
}

export function VerticalStructureDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerticalStructureDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'loadingWalls': !exists(json, 'loadingWalls') ? undefined : json['loadingWalls'],
        'others': !exists(json, 'others') ? undefined : json['others'],
        'othersUnknownOthersValue': !exists(json, 'othersUnknownOthersValue') ? undefined : json['othersUnknownOthersValue'],
        'pillars': !exists(json, 'pillars') ? undefined : json['pillars'],
        'pillarsUnknownOthersValue': !exists(json, 'pillarsUnknownOthersValue') ? undefined : json['pillarsUnknownOthersValue'],
    };
}

export function VerticalStructureDTOToJSON(value?: VerticalStructureDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'loadingWalls': value.loadingWalls,
        'others': value.others,
        'othersUnknownOthersValue': value.othersUnknownOthersValue,
        'pillars': value.pillars,
        'pillarsUnknownOthersValue': value.pillarsUnknownOthersValue,
    };
}

