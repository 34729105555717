import React from "react";
import { FontSizes } from "../constants";
import { Backdrop, CircularProgress, Grid } from "@mui/material";
import { TypographyTheme } from "@movicoders/movicoders-components";

interface IBackDropLoading {
  showLoading: boolean;
  total?: number;
  completed?: number;
  estimatedTimeTitle?: string;
  estimatedTimeSubtitle?: string;
  estimatedTimeUnit?: string;
}

function BackDropLoading({
  showLoading,
  completed = 0,
  total = 0,
  estimatedTimeTitle = "",
  estimatedTimeSubtitle = "",
  estimatedTimeUnit = "",
}: IBackDropLoading) {
  const [estimatedTime, setEstimatedTime] = React.useState("0");

  React.useEffect(() => {
    const totalTime = total * 2;
    const currentItem = completed * 2;
    const currentEta = totalTime - currentItem;
    const currentMinutes = Math.floor(currentEta / 60);
    const currentSeconds = currentEta % 60;

    setEstimatedTime(`${String(currentMinutes).padStart(2, "0")}:${String(currentSeconds).padStart(2, "0")}`);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completed]);

  return (
    <Backdrop style={{ zIndex: 10000011, color: "#3f51b5" }} open={showLoading}>
      {total > 0 ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          style={{ backgroundColor: "rgba(255,255,255,0.75)", padding: 10, fontSize: FontSizes.h4, width: "30%", minWidth: 300, borderRadius: 2 }}
          pt={5}
          pb={5}
        >
          <Grid item xs={12}>
            <TypographyTheme variant="h5" color="primary" text={estimatedTimeTitle} />
            {total !== completed && <div style={{ marginTop: 30 }}>{`${estimatedTimeSubtitle}: ${estimatedTime}`}</div>}
            {total !== completed && <div style={{ marginTop: 5 }}>{`${estimatedTimeUnit}: ${completed}/${total}`}</div>}
            {total === completed && <div style={{ marginTop: 5 }}>{"Terminando de configurar el libro.."}</div>}
          </Grid>
        </Grid>
      ) : (
        <CircularProgress disableShrink variant="indeterminate" color="inherit" />
      )}
    </Backdrop>
  );
}

export default BackDropLoading;
