import { DialogMovicoders } from "@movicoders/movicoders-components";
import { ConfirmDialogStrings } from "@movicoders/movicoders-components";
import { observer } from "mobx-react-lite";
import React from "react";
import { Colors } from "../../../../../../../constants";
import ReportViewModel from "../../../../../../../viewmodels/report/report-view-model";
import { UsageForm } from "./usage-form";
import { UsageDTO } from "../../../../../../../clients";

export const BuildingDialog: React.FC<{ vm: ReportViewModel; setCurrentPage: React.Dispatch<React.SetStateAction<UsageDTO[]>>; currentPage: UsageDTO[] }> =
  observer(({ vm, setCurrentPage, currentPage }) => {
    const handleClose = () => {
      vm.selectedBuildingCompositionUsage = undefined;
    };

    const dialogStrings: ConfirmDialogStrings = {
      title: vm.translate("report.block0.uses.location"),
      content: <UsageForm vm={vm} setCurrentPage={setCurrentPage} currentPage={currentPage} />,
    };

    return (
      <React.Fragment>
        <DialogMovicoders
          id="buildingDialogMovicoderesLocation"
          open={true}
          draggable
          dialogConfig={dialogStrings}
          onConfirm={() => {}}
          onClose={() => handleClose()}
          closeWithX
          maxWidth={"md"}
          backgroundColorTitle={Colors.primary}
          fullWidth
        />
      </React.Fragment>
    );
  });
