import React from 'react'
import MaintenanceViewModel from '../../viewmodels/maintenance/maintenance-view-model';
import { registerView } from '@frui.ts/views';
import { observer } from 'mobx-react-lite';

const Maintenance: React.FC<{ vm: MaintenanceViewModel }> = observer(({ vm }) => {
    return (
        <div className="container">
            <div className="container2">
                <h1 className="title">Estamos en Mantenimiento</h1>
                <p>El LEEx está actualmente en mantenimiento. Estaremos de vuelta en breve. Gracias por tu paciencia.</p>
                <p>
                    <strong>Hora estimada de regreso:</strong> 14:00 hora peninsular
                </p>
                <footer className='foot'>Para más información, contáctanos en soporte@e-leex.com</footer>
            </div>
        </div>
    )
})

export default registerView(Maintenance, MaintenanceViewModel);
