import { bound } from "@frui.ts/helpers"
import RootViewModel from "../root-view-model"
import { action, computed, observable } from "mobx"
import Building from "../../data/entities/building-entity"
import BuildingDetailViewModel from "./building-detail-view-model"
import { ConductorOneChildActive, ScreenBase } from "@frui.ts/screens"
import BuildingRepository from "../../data/repositories/impl/building-repository"

export default class BuildingViewModel extends ConductorOneChildActive<ScreenBase>{
  navigationName = "buildings"
  nameValue = "section.buildings"
  parent = this.parent as RootViewModel
  public showInToolbar: boolean = true;

  @observable items: Building[] = observable.array([
    

  ])
  @observable loading = true
  @observable selected?: Building
  @observable currentPage: number = 0
  @observable rowsPerPage: number = 25
  @observable filters: any = {}
  /** Boolean to know if the backdrop loading screen needs to be diaplayed or not */
  @observable showLoading: boolean = false
  //CSV
  @observable csvData: Building[] = []
  @computed get data() {
    return this.toData(this.items)
  }

  toData = (items: Building[]) => {
    const values = items.map((item) => item.toArray());
    return values
  }

  constructor(
    public buildingRepository: BuildingRepository,
    private detailFactory: ReturnType<typeof BuildingDetailViewModel.Factory>
  ) {
    super();
  }

  async onActivate() {
    super.onActivate()
    if (this.loading) this.loadData()
  }

  @bound async loadData() {
    this.loading = true;
    this.loading = false;
  }

  @bound async loadDataCSV() {
    this.loading = true;
    this.csvData = this.items;
    this.loading = false;
  }

  @action.bound find(id: string) {
    const index = this.items.find(item => item.name.toString() === id)
    this.selected = index!!
    return index
  }

  applyFilter = () => {
    const keys = Object.keys(this.filters)
    let values = this.data
    for (let index in keys) {
      const key = keys[index]
      const text = this.filters[key]
      if (text && text.length > 0) {
        values = values.filter(item => {
          return item[key as any]?.toString().toLowerCase().match(text.toLowerCase())
        })
      }
    }
    return values
  }

  @action.bound addFilter = (column: number, value?: string) => {
    this.filters[column] = value
  }


  @action.bound async prepareCreate() {
   /* const result = new Building(-1, "", "", "", "", "", false);
    this.setSelected(result);*/
  }

  @action.bound openNew() {
    this.prepareCreate();
    this.openDetail();
  }

  @action.bound async openDetail() {
    const detail = this.findNavigationChild(this.selected + "");
    this.tryActivateChild(await detail!!)
  }

  async findNavigationChild(navigationName: string): Promise<any> {
    if (navigationName === undefined || navigationName === this.navigationName) {
      this.parent.tryActivateChild(this);
      this.activeChild?.requestClose();
      return undefined;
    } else return undefined;
  }
  
  @action.bound addChild() {
    if (this.selected) {
      const detail = this.detailFactory!(this.selected);
      this.children.push(detail);
      return detail;
    }
    return undefined;
  }

  @action.bound async delete(id: string) { }

  @action.bound setSelected(result: Building | undefined) {
    this.selected = result;
  }

  @action.bound handleCurrentPage = (page: number) => {
    this.currentPage = page
  }

  @action.bound handleRowsPerPage = (numberOfRows: number) => {
    this.rowsPerPage = numberOfRows
  }

  translate(text: string) {
    this.parent.language = this.parent.configurationService.language
    return (this.parent as RootViewModel).configurationService.translate(text);
  }
}