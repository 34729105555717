import React from "react"
import { observer } from 'mobx-react-lite'
import { registerView, ViewComponent } from '@frui.ts/views'
import RolesViewModel from '../../viewmodels/roles-view-model'

const RolesView: ViewComponent<RolesViewModel> = observer(({ vm }) => (
	<div>
		<h1>{vm.name}</h1>
		<h1>{vm.navigationName}</h1>
	</div>
))

registerView(RolesView, RolesViewModel);