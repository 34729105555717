import { AddImprovementToImprovementActionRequest, BaseAPI, Configuration, ImprovementActionDTO, SetFireSafetyImprovementRequest, SetHealthinessImprovementRequest, SetNoisesImprovementRequest, SetOtherImprovementRequest, SetSavingEnergyImprovementRequest, SetSecurityImprovementRequest } from "../../../clients";
import { AddPhaseToImprovementActionRequest, DeleteImprovementToImprovementActionRequest, DeletePhaseFromImprovementActionRequest, ImprovementActionsApi as ImprovementsActionsServiceApi, UpdateMultipleImprovementsRequest } from '../../../clients/apis/';
import IImprovementsApi from "../iimprovements-actions-api";

export class ImprovementActions extends BaseAPI implements IImprovementsApi {
    apiClient: ImprovementsActionsServiceApi;

    constructor(configuration?: Configuration) {
        super(configuration);
        this.apiClient = new ImprovementsActionsServiceApi(configuration);
    }

    async deleteImprovementFromImprovementAction(requestParameters: DeleteImprovementToImprovementActionRequest): Promise<ImprovementActionDTO> {
        const response = await this.apiClient.deleteImprovementToImprovementAction(requestParameters);
        return response;
    }

    async addPhaseToImprovementAction(requestParameters: AddPhaseToImprovementActionRequest): Promise<ImprovementActionDTO> {
        const response = await this.apiClient.addPhaseToImprovementAction(requestParameters);
        return response;
    }

    async deletePhaseFromImprovementAction(requestParameters: DeletePhaseFromImprovementActionRequest): Promise<ImprovementActionDTO> {
        const response = await this.apiClient.deletePhaseFromImprovementAction(requestParameters);
        return response;
    }

    async updateMultipleImprovements(requestParameters: UpdateMultipleImprovementsRequest): Promise<ImprovementActionDTO> {
        const response = await this.apiClient.updateMultipleImprovements(requestParameters);
        return response;
    }

    async addImprovementToImprovementAction(requestParameters: AddImprovementToImprovementActionRequest): Promise<ImprovementActionDTO> {
        const response = await this.apiClient.addImprovementToImprovementAction(requestParameters);
        return response;
    }

    async setFireSafetyImprovement(requestParameters: SetFireSafetyImprovementRequest): Promise<ImprovementActionDTO> {
        const response = await this.apiClient.setFireSafetyImprovement(requestParameters);
        return response;
    }

    async setHealthinessImprovement(requestParameters: SetHealthinessImprovementRequest): Promise<ImprovementActionDTO> {
        const response = await this.apiClient.setHealthinessImprovement(requestParameters);
        return response;
    }

    async setNoisesImprovement(requestParameters: SetNoisesImprovementRequest): Promise<ImprovementActionDTO> {
        const response = await this.apiClient.setNoisesImprovement(requestParameters);
        return response
    }

    async setOtherImprovement(requestParameters: SetOtherImprovementRequest): Promise<ImprovementActionDTO> {
        const response = await this.apiClient.setOtherImprovement(requestParameters);
        return response
    }

    async setSavingEnergyImprovement(requestParameters: SetSavingEnergyImprovementRequest): Promise<ImprovementActionDTO> {
        const response = await this.apiClient.setSavingEnergyImprovement(requestParameters);
        return response
    }

    async setSecurityImprovement(requestParameters: SetSecurityImprovementRequest): Promise<ImprovementActionDTO> {
        const response = await this.apiClient.setSecurityImprovement(requestParameters);
        return response
    }
}