import React from "react"
import { observer } from "mobx-react-lite"
import { registerView, ViewComponent } from "@frui.ts/views"
import { BuildingDialog } from "./buildings-components/BuildingsDialog"
import BuildingDetailViewModel from "../../viewmodels/buildings/building-detail-view-model"

const BuildingDetailView: ViewComponent<BuildingDetailViewModel> = observer(({ vm }) => {
  return (
    <React.Fragment>
      <BuildingDialog vm={vm} />
    </React.Fragment>
  )
})

export default registerView(BuildingDetailView, BuildingDetailViewModel)