import { TypographyTheme } from "@movicoders/movicoders-components";
import { Button, Grid } from "@mui/material";
import React from "react";
import { Colors, FontSizes } from "../constants";
import { ReportStyle } from "../views/reports/report-style";

export interface ITextField {
  id: string;
  title: string;
  marginTop?: boolean;
  onClick?: () => void;
  buttonLabel?: string;
}

function TypographyTitleWButton(props: ITextField) {
  const classes = ReportStyle();

  return (
    <>
      <Grid display={"inline-flex"} justifyContent={"space-between"} item xs={12} md={12} style={{ marginTop: props.marginTop ? 20 : 0 }}>
        <TypographyTheme id={props.id} size={FontSizes.h1} color={Colors.textPrimaryHeaders} text={props.title} />
        <Grid item style={{ display: "flex" }} gap={1}>
          <Button size="small" variant="outlined" id="editLocation" onClick={props.onClick}>
            {props.buttonLabel}
          </Button>
        </Grid>
      </Grid>
      <hr className={classes.report_hr} />
    </>
  );
}

export default TypographyTitleWButton;
