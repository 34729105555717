import { Container, Avatar, Typography, TextField, Grid, Checkbox } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import movicodersLogo from "../../assets/images/logoMovicoders.png";
import { LoginStyles } from "./login-style";
import LoginViewModel from "../../viewmodels/login/login-view-model";
import { useSnackbar } from "notistack";
import { Network } from "../../infrastructure/network";
import { Colors } from "../../constants";
import { Button } from "@movicoders/movicoders-components";

export const LoginFields: React.FC<{ vm: LoginViewModel }> = observer(({ vm }) => {
  const classes = LoginStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [terms, setTerms] = React.useState(false);
  const handleOpenSnackBar = (
    snackKey: string,
    variants: undefined | "default" | "error" | "success" | "warning" | "info",
    hideDuration: number = 3000
  ) => {
    enqueueSnackbar(snackKey, { variant: variants, preventDuplicate: true, key: snackKey, autoHideDuration: hideDuration });
  };
  const login = async () => {
    try {
      vm.loadingLogin = true;
      const res = await vm.login({ email: vm.credentials?.userName!!, password: vm.credentials?.password!! });
      if (res !== "login.success") handleOpenSnackBar(vm.translate(res), "error");
    } catch (error) {
      vm.loadingLogin = false;
    }
    vm.loadingLogin = false;
  };

  const recoverPassword = async () => {
    try {
      vm.loadingLogin = true;
      await fetch(`${Network.BACKEND}/user-management/recovery/username`, {
        method: "post",
        body: JSON.stringify(vm.credentials!.userName!!),
        headers: {
          Accept: "application/json",
        },
      });
      handleOpenSnackBar(vm.translate("login.recover.password"), "success", 13000);
    } catch (error) {
      console.log(error);
      vm.loadingLogin = false;
      vm.forgotPassword = false;
      handleOpenSnackBar(vm.translate("login.error"), "error");
    }
    vm.loadingLogin = false;
    vm.forgotPassword = false;
  };

  const keyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      login();
    }
  };

  const getTerms = async () => {
    if (localStorage.getItem("terms-and-conditions") === "true") setTerms(true);
    else setTerms(false);
  };

  useEffect(() => {
    getTerms();
  }, []);

  return (
    <Container component="div" maxWidth="xs">
      <Avatar src={movicodersLogo} sx={{ width: "80px", height: "80px" }} variant="rounded" className={classes.login_logo} />
      <div style={{ marginTop: 1 }} />
      <div style={{ textAlign: "left" }}>
        <Typography variant="h5">{vm.forgotPassword ? vm.translate("login.label.title.recover.password") : ""}</Typography>
        <Typography variant="subtitle2">{vm.forgotPassword ? vm.translate("") : vm.translate("login.subTitle")}</Typography>
      </div>
      {vm.forgotPassword && (
        <Typography variant={"subtitle2"} textAlign={"center"} className={classes.login_subText}>
          {vm.translate("login.subtext")}
        </Typography>
      )}
      <form style={{ textAlign: "center" }}>
        <TextField
          sx={{ marginTop: 1.5 }}
          variant="outlined"
          className={classes.login_width}
          required
          label={vm.translate("login.label.user")}
          name="email"
          onKeyDown={keyDownHandler}
          autoComplete="email"
          value={vm.credentials?.userName ?? ""}
          onChange={(e: any) => {
            vm.credentials.userName = e.target.value;
          }}
        />

        {!vm.forgotPassword && (
          <TextField
            variant="outlined"
            className={classes.login_width}
            sx={{ marginTop: 1.5 }}
            onKeyDown={keyDownHandler}
            required
            name="password"
            label={vm.translate("login.label.password")}
            type="password"
            autoComplete="current-password"
            value={vm.credentials?.password}
            onChange={(e: any) => {
              vm.credentials.password = e.target.value;
            }}
          />
        )}
      </form>
      {!vm.forgotPassword ? (
        <>
          <Grid container className={classes.forgotAndRememberGrid} alignItems={"center"} direction={"row"}>
            <Grid item>
              <Checkbox
                inputProps={{ style: { color: "black" } }}
                value={terms}
                checked={terms}
                onChange={() => {
                  setTerms((n) => {
                    n = !n;
                    localStorage.setItem("terms-and-conditions", n ? "true" : "false");
                    return n;
                  });
                }}
              />
              {vm.translate("login.accept.conditions")}
            </Grid>
            <Grid item>
              <div
                onClick={() => {
                  vm.forgotPassword = true;
                }}
              >
                <Typography sx={{ fontSize: 14, cursor: "pointer" }}>{vm.translate("login.label.forgotpassword")}</Typography>
              </div>
            </Grid>
          </Grid>
        </>
      ) : (
        <div style={{ height: 21 }} />
      )}
      <Grid container justifyContent={"center"} alignItems={"center"} direction={"row"} spacing={2}>
        <Grid item>
          <Button
            variant="text"
            classButton={classes.login_width}
            text={vm.forgotPassword ? vm.translate("login.button.recover") : vm.translate("login.button")}
            style={{ width: 396, height: 40, color: "white", backgroundColor: Colors.primary }}
            onClick={vm.forgotPassword ? recoverPassword : login}
          />
        </Grid>
      </Grid>
    </Container>
  );
});
