import { CadastralInfoApi as ParcelsApi } from "../../../clients/apis/CadastralInfoApi";
import { BaseAPI, Configuration } from '../../../clients/runtime';
import { ICadastralApi } from "../icadastral-api";

export class CadastralApi extends BaseAPI implements ICadastralApi {
  apiClient: ParcelsApi
  constructor(configuration?: Configuration) {
    super(configuration);
    this.apiClient = new ParcelsApi(configuration);
  }

  findByReference(reference: string): Promise<any> {
    throw new Error("Method not implemented.");
  }
  findByAddress(item: any): Promise<any> {
    throw new Error("Method not implemented.");
  }
}