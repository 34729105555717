/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ImprovementDTO,
    ImprovementDTOFromJSON,
    ImprovementDTOFromJSONTyped,
    ImprovementDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ImprovementContainerDTO
 */
export interface ImprovementContainerDTO {
    /**
     * 
     * @type {Array<ImprovementDTO>}
     * @memberof ImprovementContainerDTO
     */
    improvementsDTO?: Array<ImprovementDTO>;
}

export function ImprovementContainerDTOFromJSON(json: any): ImprovementContainerDTO {
    return ImprovementContainerDTOFromJSONTyped(json, false);
}

export function ImprovementContainerDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImprovementContainerDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'improvementsDTO': !exists(json, 'improvementsDTO') ? undefined : ((json['improvementsDTO'] as Array<any>).map(ImprovementDTOFromJSON)),
    };
}

export function ImprovementContainerDTOToJSON(value?: ImprovementContainerDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'improvementsDTO': value.improvementsDTO === undefined ? undefined : ((value.improvementsDTO as Array<any>).map(ImprovementDTOToJSON)),
    };
}

