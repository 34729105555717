import { GetAllTechnicianRequest, GetTechnicianByCredentialsIDRequest, GetTechnicianByIDRequest, SearchTechnicianRequest } from "../../../clients/apis";
import { Api } from "../../../infrastructure/data/api-client";
import { BaseRepository } from "../../../infrastructure/data/repositories/base-repository";
import { ITechniciansApi } from "../itechnicians-api";

export class TechniciansRepository extends BaseRepository<ITechniciansApi> {

  constructor() { super(Api.TechniciansApi, false); }

  async getTechnicianByCredentialsId(getTechnicianByIDRequest: GetTechnicianByCredentialsIDRequest) {
    const client = await this.apiClient;
    return await client.getTechnicianByCredentialsID(getTechnicianByIDRequest);
  }

  async getTechnicianById(getTechnicianByIDRequest: GetTechnicianByIDRequest) {
    const response = await (await this.apiClient).getTechnicianById(getTechnicianByIDRequest);
    return response;
  }

  async searchTechnician(searchTechnicianRequest: SearchTechnicianRequest) {
    const response = await (await this.apiClient).searchTechnician(searchTechnicianRequest);
    return response;
  }

  async getAllTechnician(params: GetAllTechnicianRequest) {
    const response = await (await this.apiClient).getAllTechnician(params);
    return response;
  }

}