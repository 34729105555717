import { DialogMovicoders } from "@movicoders/movicoders-components";
import { ConfirmDialogStrings } from "@movicoders/movicoders-components";
import { observer } from "mobx-react-lite";
import React from "react";
import { Colors } from "../../../../../../constants";
import { mockParticipantAgent } from "../../../../../../utils/book-helper";
import ReportViewModel from "../../../../../../viewmodels/report/report-view-model";
import { AddUserForm } from "../../../../forms/add-user";

export const PropertyDataDialog: React.FC<{ vm: ReportViewModel }> = observer(({ vm }) => {
  const handleClose = () => {
    vm.currentAgent = mockParticipantAgent();
    vm.addUser = false;
    vm.editingParticipation = false;
    vm.role = "";
  };

  const dialogStrings: ConfirmDialogStrings = {
    title:
      vm.translate(
        vm.editingParticipation ? "report.block0.propertydata.propertyDataDialog.editAgent" : "report.block0.propertydata.propertyDataDialog.AddAgent"
      ) + vm.role.toLowerCase(),
    content: <AddUserForm vm={vm} />,
  };

  return (
    <DialogMovicoders
      id="propertyDataDialogMovicoders"
      open={true}
      draggable
      dialogConfig={dialogStrings}
      onConfirm={() => {}}
      onClose={() => handleClose()}
      closeWithX
      maxWidth={"md"}
      backgroundColorTitle={Colors.primary}
      fullWidth
    />
  );
});
