import { useSnackbar } from "notistack";
import ReportViewModel from "../../../../../../viewmodels/report/report-view-model";

export const useCheckUsages = (vm: ReportViewModel) => {
  const { enqueueSnackbar } = useSnackbar();

  const checkUsages = () => {
    if (vm.parent.isManual) return;
    if (vm.parent.editingBook && (vm.userBook.cadastralInfo?.properties?.filter((property) => property.usages?.length).length ?? 0) < 1) {
      enqueueSnackbar(vm.translate("block0.missing.usages.error"), {
        variant: "info",
        preventDuplicate: true,
        key: "error",
        autoHideDuration: 4000,
      });
    }
  };
  return { checkUsages };
};
