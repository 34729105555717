import { TypographyTheme } from "@movicoders/movicoders-components";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { CollegeDTO } from "../../../../../../../clients";
import ProfileViewModel from "../../../../../../../viewmodels/profile/profile-view-model";
import ReportViewModel from "../../../../../../../viewmodels/report/report-view-model";
import { ReportStyle } from "../../../../../report-style";
import { PaymentButton } from "./payment-button";

export const CollegeSelectionForm: React.FC<{
  vm: ReportViewModel;
}> = observer(({ vm }) => {
  const classes = ReportStyle();
  const [colleges, setColleges] = useState<CollegeDTO[] | undefined>([]);


  const checkConfiguration = async (college?: CollegeDTO) => {
    try {
      if (college) {
        const profilevm = vm.parent.children.find((c) => c.navigationName === "profile") as ProfileViewModel;
        profilevm.loadingBook = true;
        vm.selectedCollege = college;
        await vm.bookRepository
          .initPayment({ collegeId: college.id })
          .then((res) => {
            if (res !== null) vm.cecaSignatureConfiguration = res;
            else vm.cecaSignatureConfiguration = null;
          })
          .catch((err) => {
            console.log(err);
            vm.cecaSignatureConfiguration = null;
            vm.loadingBook = false;
          });
        vm.loadingBook = false;
        profilevm.loadingBook = false;
      }
      // else if (vm.selectedCollege) vm.cecaSignatureConfiguration = await vm.bookRepository.initPayment({ collegeId: vm.selectedCollege.id!! });
    } catch (e: any) {
      vm.loadingBook = false;
      console.log(e.message);
    }
  };

  useEffect(() => {
    vm.parent.technicianData?.collegiated?.map((item: any) => setColleges((oldArray: any) => [...oldArray, item!!.college]));

    vm.userData?.collegiated && vm.userData?.collegiated?.length === 1 && checkConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid
      container
      minWidth={600}
      minHeight={200}
      alignItems="start"
      style={{ placeContent: "start" }}
      justifyContent={"start"}
      className={classes.report_displayGrid}
    >
      <div style={{ height: 10, width: 1 }} />
      {vm.userData?.collegiated && vm.userData?.collegiated?.length > 1 ? (
        <Grid container display="grid" style={{ padding: "5px" }} className={classes.report_generalDataGrid}>
          <>
            <Grid item xs={12} style={{ marginBottom: "10px" }}>
              <TypographyTheme variant="body1" text={vm.translate("report.block0.college.select")} />
            </Grid>
            <Grid item xs={12} md={12}>
              <Autocomplete
                disablePortal
                id="bookGeneratorCollege"
                options={colleges || []}
                fullWidth
                clearIcon={<></>}
                getOptionLabel={(option: any) => option.name}
                renderInput={(params) => (
                  <TextField required {...params} className={classes.report_autocomplete} size="small" label={vm.translate("report.block0.college")} />
                )}
                onChange={async (event: any, value: any) => {
                  await checkConfiguration(value);
                  // vm.selectedCollege = value;
                }}
              />
            </Grid>
          </>
        </Grid>
      ) : <></>}
      {vm.selectedCollege && (
        <Grid container style={{ padding: "5px" }}>
          <PaymentButton vm={vm} />
        </Grid>
      )}
    </Grid>
  );
});
