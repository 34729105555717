/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressDTO,
    AddressDTOFromJSON,
    AddressDTOFromJSONTyped,
    AddressDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ParticipationTechnicianDTO
 */
export interface ParticipationTechnicianDTO {
    /**
     * 
     * @type {AddressDTO}
     * @memberof ParticipationTechnicianDTO
     */
    address?: AddressDTO;
    /**
     * 
     * @type {Array<string>}
     * @memberof ParticipationTechnicianDTO
     */
    colleges?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ParticipationTechnicianDTO
     */
    fare?: ParticipationTechnicianDTOFareEnum;
    /**
     * 
     * @type {string}
     * @memberof ParticipationTechnicianDTO
     */
    fullname?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipationTechnicianDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipationTechnicianDTO
     */
    idNumber?: string;
}

/**
* @export
* @enum {string}
*/
export enum ParticipationTechnicianDTOFareEnum {
    Aragon = 'ARAGON',
    Cgate = 'CGATE',
    Standard = 'STANDARD'
}

export function ParticipationTechnicianDTOFromJSON(json: any): ParticipationTechnicianDTO {
    return ParticipationTechnicianDTOFromJSONTyped(json, false);
}

export function ParticipationTechnicianDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipationTechnicianDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': !exists(json, 'address') ? undefined : AddressDTOFromJSON(json['address']),
        'colleges': !exists(json, 'colleges') ? undefined : json['colleges'],
        'fare': !exists(json, 'fare') ? undefined : json['fare'],
        'fullname': !exists(json, 'fullname') ? undefined : json['fullname'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'idNumber': !exists(json, 'idNumber') ? undefined : json['idNumber'],
    };
}

export function ParticipationTechnicianDTOToJSON(value?: ParticipationTechnicianDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': AddressDTOToJSON(value.address),
        'colleges': value.colleges,
        'fare': value.fare,
        'fullname': value.fullname,
        'id': value.id,
        'idNumber': value.idNumber,
    };
}

