import React from "react";
import { Autocomplete, Grid, TextField } from "@mui/material";
import ReportViewModel from "../../../../../../../viewmodels/report/report-view-model";
import { ReportStyle } from "../../../../../report-style";
import TextFieldGrid from "../../../../../../../components/text-fields/textfield-grid";
import { observer } from "mobx-react-lite";

export const DisabledAddress: React.FC<{ vm: ReportViewModel }> = observer(({ vm }) => {
  const classes = ReportStyle();
  return (
    <>
      <Grid item xs={12} md={12} className={classes.report_generalDataGrid}>
        <Autocomplete
          id="locationCommnunity"
          options={[]}
          disablePortal
          fullWidth
          value={`${vm.locationInfo?.address?.community}`}
          disabled
          clearIcon={<></>}
          getOptionLabel={(option: any) => option}
          renderInput={(params) => (
            <TextField {...params} className={classes.report_autocomplete} size="small" label={vm.translate("report.block0.ccaa")} />
          )}
          style={{ marginRight: 5 }}
        />

        <Autocomplete
          disablePortal
          id="locationProvinces"
          options={[]}
          fullWidth
          disabled
          value={`${vm.locationInfo?.address?.province}`}
          clearIcon={<></>}
          getOptionLabel={(option: any) => option}
          renderInput={(params) => (
            <TextField {...params} className={classes.report_autocomplete} size="small" label={vm.translate("report.block0.province")} />
          )}
          style={{ marginRight: 5 }}
        />

        <Autocomplete
          disablePortal
          id="locationTown"
          options={[]}
          fullWidth
          disabled
          value={`${vm.locationInfo?.address?.town}`}
          clearIcon={<></>}
          getOptionLabel={(option: any) => option}
          renderInput={(params) => (
            <TextField {...params} className={classes.report_autocomplete} size="small" label={vm.translate("report.block0.municipality")} />
          )}
          style={{ marginRight: 5 }}
        />
      </Grid>
      <Grid item xs={12} md={12} style={{ display: "flex" }}>
        <>
          <Autocomplete
            disablePortal
            id="locationStreets"
            options={[]}
            fullWidth
            disabled
            value={`${vm.locationInfo?.address?.streetType}, ${vm.locationInfo?.address?.streetName}`}
            clearIcon={<></>}
            getOptionLabel={(option: any) => option}
            renderInput={(params) => (
              <TextField {...params} size="small" className={classes.report_autocomplete} label={vm.translate("report.block1.address")} />
            )}
            style={{ paddingTop: 10, marginRight: 5 }}
          />
          <TextFieldGrid
            id="locationNumber"
            disabled
            title={"Nº"}
            value={vm.locationInfo?.address?.portalNumber || ""}
            gridTextField={{ xs: 3, md: 3 }}
            onChange={(event: any) => {
              if (vm.locationInfo.address) vm.locationInfo.address.portalNumber = event.target.value;
            }}
            style={{ marginRight: 5 }}
          />
          <TextFieldGrid
            id="locationPostalCode"
            title={vm.translate("report.block0.cp")}
            disabled
            value={vm.locationInfo?.address?.postalCode?.toString() || ""}
            gridTextField={{ xs: 3, md: 3 }}
            onChange={(event: any) => {
              if (vm.locationInfo.address) vm.locationInfo.address.postalCode = Number(event.target.value);
            }}
          />
        </>
      </Grid>
    </>
  );
});
