/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnergyEfficiencyImprovementDataDTO
 */
export interface EnergyEfficiencyImprovementDataDTO {
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    acsEnergyDemandReduction?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    acsEnergyDemandReductionPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    acsFinalEnergyConsumption?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    acsInitialEnergyConsumption?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    annualFinancialSavingsAfterImplementation?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    coolingEnergyDemandReduction?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    coolingEnergyDemandReductionPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    coolingFinalEnergyConsumption?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    coolingInitialEnergyConsumption?: number;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    energyEfficiencyMeasureType?: EnergyEfficiencyImprovementDataDTOEnergyEfficiencyMeasureTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    energyRatingInFinalEmissions?: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    energyRatingInFinalNonRenewablePrimaryEnergyConsumption?: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    energyRatingInInitialEmissions?: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    energyRatingInInitialNonRenewablePrimaryEnergyConsumption?: string;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    finalCarbonDioxideEmissions?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    finalGlobalEnergyDemand?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    finalNonRenewablePrimaryEnergyConsumption?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    globalEnergyDemandReduction?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    globalEnergyDemandReductionPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    heatingEnergyDemandReduction?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    heatingEnergyDemandReductionPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    heatingFinalEnergyConsumption?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    heatingInitialEnergyConsumption?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    initialCarbonDioxideEmissions?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    initialGlobalEnergyDemand?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    initialNonRenewablePrimaryEnergyConsumption?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    initialNonRenewablePrimaryEnergyConsumptionReduction?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    initialNonRenewablePrimaryEnergyConsumptionReductionPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    investment?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    lightningEnergyDemandReduction?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    lightningEnergyDemandReductionPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    lightningFinalEnergyConsumption?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    lightningInitialEnergyConsumption?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyImprovementDataDTO
     */
    maximumFinancialAid?: number;
}

/**
* @export
* @enum {string}
*/
export enum EnergyEfficiencyImprovementDataDTOEnergyEfficiencyMeasureTypeEnum {
    Others = 'OTHERS',
    _30To44 = '_30_TO_44',
    _45To59 = '_45_TO_59',
    _60OrMore = '_60_OR_MORE'
}

export function EnergyEfficiencyImprovementDataDTOFromJSON(json: any): EnergyEfficiencyImprovementDataDTO {
    return EnergyEfficiencyImprovementDataDTOFromJSONTyped(json, false);
}

export function EnergyEfficiencyImprovementDataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnergyEfficiencyImprovementDataDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'acsEnergyDemandReduction': !exists(json, 'acsEnergyDemandReduction') ? undefined : json['acsEnergyDemandReduction'],
        'acsEnergyDemandReductionPercent': !exists(json, 'acsEnergyDemandReductionPercent') ? undefined : json['acsEnergyDemandReductionPercent'],
        'acsFinalEnergyConsumption': !exists(json, 'acsFinalEnergyConsumption') ? undefined : json['acsFinalEnergyConsumption'],
        'acsInitialEnergyConsumption': !exists(json, 'acsInitialEnergyConsumption') ? undefined : json['acsInitialEnergyConsumption'],
        'annualFinancialSavingsAfterImplementation': !exists(json, 'annualFinancialSavingsAfterImplementation') ? undefined : json['annualFinancialSavingsAfterImplementation'],
        'coolingEnergyDemandReduction': !exists(json, 'coolingEnergyDemandReduction') ? undefined : json['coolingEnergyDemandReduction'],
        'coolingEnergyDemandReductionPercent': !exists(json, 'coolingEnergyDemandReductionPercent') ? undefined : json['coolingEnergyDemandReductionPercent'],
        'coolingFinalEnergyConsumption': !exists(json, 'coolingFinalEnergyConsumption') ? undefined : json['coolingFinalEnergyConsumption'],
        'coolingInitialEnergyConsumption': !exists(json, 'coolingInitialEnergyConsumption') ? undefined : json['coolingInitialEnergyConsumption'],
        'energyEfficiencyMeasureType': !exists(json, 'energyEfficiencyMeasureType') ? undefined : json['energyEfficiencyMeasureType'],
        'energyRatingInFinalEmissions': !exists(json, 'energyRatingInFinalEmissions') ? undefined : json['energyRatingInFinalEmissions'],
        'energyRatingInFinalNonRenewablePrimaryEnergyConsumption': !exists(json, 'energyRatingInFinalNonRenewablePrimaryEnergyConsumption') ? undefined : json['energyRatingInFinalNonRenewablePrimaryEnergyConsumption'],
        'energyRatingInInitialEmissions': !exists(json, 'energyRatingInInitialEmissions') ? undefined : json['energyRatingInInitialEmissions'],
        'energyRatingInInitialNonRenewablePrimaryEnergyConsumption': !exists(json, 'energyRatingInInitialNonRenewablePrimaryEnergyConsumption') ? undefined : json['energyRatingInInitialNonRenewablePrimaryEnergyConsumption'],
        'finalCarbonDioxideEmissions': !exists(json, 'finalCarbonDioxideEmissions') ? undefined : json['finalCarbonDioxideEmissions'],
        'finalGlobalEnergyDemand': !exists(json, 'finalGlobalEnergyDemand') ? undefined : json['finalGlobalEnergyDemand'],
        'finalNonRenewablePrimaryEnergyConsumption': !exists(json, 'finalNonRenewablePrimaryEnergyConsumption') ? undefined : json['finalNonRenewablePrimaryEnergyConsumption'],
        'globalEnergyDemandReduction': !exists(json, 'globalEnergyDemandReduction') ? undefined : json['globalEnergyDemandReduction'],
        'globalEnergyDemandReductionPercent': !exists(json, 'globalEnergyDemandReductionPercent') ? undefined : json['globalEnergyDemandReductionPercent'],
        'heatingEnergyDemandReduction': !exists(json, 'heatingEnergyDemandReduction') ? undefined : json['heatingEnergyDemandReduction'],
        'heatingEnergyDemandReductionPercent': !exists(json, 'heatingEnergyDemandReductionPercent') ? undefined : json['heatingEnergyDemandReductionPercent'],
        'heatingFinalEnergyConsumption': !exists(json, 'heatingFinalEnergyConsumption') ? undefined : json['heatingFinalEnergyConsumption'],
        'heatingInitialEnergyConsumption': !exists(json, 'heatingInitialEnergyConsumption') ? undefined : json['heatingInitialEnergyConsumption'],
        'initialCarbonDioxideEmissions': !exists(json, 'initialCarbonDioxideEmissions') ? undefined : json['initialCarbonDioxideEmissions'],
        'initialGlobalEnergyDemand': !exists(json, 'initialGlobalEnergyDemand') ? undefined : json['initialGlobalEnergyDemand'],
        'initialNonRenewablePrimaryEnergyConsumption': !exists(json, 'initialNonRenewablePrimaryEnergyConsumption') ? undefined : json['initialNonRenewablePrimaryEnergyConsumption'],
        'initialNonRenewablePrimaryEnergyConsumptionReduction': !exists(json, 'initialNonRenewablePrimaryEnergyConsumptionReduction') ? undefined : json['initialNonRenewablePrimaryEnergyConsumptionReduction'],
        'initialNonRenewablePrimaryEnergyConsumptionReductionPercent': !exists(json, 'initialNonRenewablePrimaryEnergyConsumptionReductionPercent') ? undefined : json['initialNonRenewablePrimaryEnergyConsumptionReductionPercent'],
        'investment': !exists(json, 'investment') ? undefined : json['investment'],
        'lightningEnergyDemandReduction': !exists(json, 'lightningEnergyDemandReduction') ? undefined : json['lightningEnergyDemandReduction'],
        'lightningEnergyDemandReductionPercent': !exists(json, 'lightningEnergyDemandReductionPercent') ? undefined : json['lightningEnergyDemandReductionPercent'],
        'lightningFinalEnergyConsumption': !exists(json, 'lightningFinalEnergyConsumption') ? undefined : json['lightningFinalEnergyConsumption'],
        'lightningInitialEnergyConsumption': !exists(json, 'lightningInitialEnergyConsumption') ? undefined : json['lightningInitialEnergyConsumption'],
        'maximumFinancialAid': !exists(json, 'maximumFinancialAid') ? undefined : json['maximumFinancialAid'],
    };
}

export function EnergyEfficiencyImprovementDataDTOToJSON(value?: EnergyEfficiencyImprovementDataDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'acsEnergyDemandReduction': value.acsEnergyDemandReduction,
        'acsEnergyDemandReductionPercent': value.acsEnergyDemandReductionPercent,
        'acsFinalEnergyConsumption': value.acsFinalEnergyConsumption,
        'acsInitialEnergyConsumption': value.acsInitialEnergyConsumption,
        'annualFinancialSavingsAfterImplementation': value.annualFinancialSavingsAfterImplementation,
        'coolingEnergyDemandReduction': value.coolingEnergyDemandReduction,
        'coolingEnergyDemandReductionPercent': value.coolingEnergyDemandReductionPercent,
        'coolingFinalEnergyConsumption': value.coolingFinalEnergyConsumption,
        'coolingInitialEnergyConsumption': value.coolingInitialEnergyConsumption,
        'energyEfficiencyMeasureType': value.energyEfficiencyMeasureType,
        'energyRatingInFinalEmissions': value.energyRatingInFinalEmissions,
        'energyRatingInFinalNonRenewablePrimaryEnergyConsumption': value.energyRatingInFinalNonRenewablePrimaryEnergyConsumption,
        'energyRatingInInitialEmissions': value.energyRatingInInitialEmissions,
        'energyRatingInInitialNonRenewablePrimaryEnergyConsumption': value.energyRatingInInitialNonRenewablePrimaryEnergyConsumption,
        'finalCarbonDioxideEmissions': value.finalCarbonDioxideEmissions,
        'finalGlobalEnergyDemand': value.finalGlobalEnergyDemand,
        'finalNonRenewablePrimaryEnergyConsumption': value.finalNonRenewablePrimaryEnergyConsumption,
        'globalEnergyDemandReduction': value.globalEnergyDemandReduction,
        'globalEnergyDemandReductionPercent': value.globalEnergyDemandReductionPercent,
        'heatingEnergyDemandReduction': value.heatingEnergyDemandReduction,
        'heatingEnergyDemandReductionPercent': value.heatingEnergyDemandReductionPercent,
        'heatingFinalEnergyConsumption': value.heatingFinalEnergyConsumption,
        'heatingInitialEnergyConsumption': value.heatingInitialEnergyConsumption,
        'initialCarbonDioxideEmissions': value.initialCarbonDioxideEmissions,
        'initialGlobalEnergyDemand': value.initialGlobalEnergyDemand,
        'initialNonRenewablePrimaryEnergyConsumption': value.initialNonRenewablePrimaryEnergyConsumption,
        'initialNonRenewablePrimaryEnergyConsumptionReduction': value.initialNonRenewablePrimaryEnergyConsumptionReduction,
        'initialNonRenewablePrimaryEnergyConsumptionReductionPercent': value.initialNonRenewablePrimaryEnergyConsumptionReductionPercent,
        'investment': value.investment,
        'lightningEnergyDemandReduction': value.lightningEnergyDemandReduction,
        'lightningEnergyDemandReductionPercent': value.lightningEnergyDemandReductionPercent,
        'lightningFinalEnergyConsumption': value.lightningFinalEnergyConsumption,
        'lightningInitialEnergyConsumption': value.lightningInitialEnergyConsumption,
        'maximumFinancialAid': value.maximumFinancialAid,
    };
}

