import { DialogMovicoders } from "@movicoders/movicoders-components"
import { ConfirmDialogStrings } from "@movicoders/movicoders-components"
import { observer } from "mobx-react-lite"
import React from "react"
import { Colors } from "../../../constants"
import ProfileViewModel from "../../../viewmodels/profile/profile-view-model"
import { ProfileForm } from "./form/profile-form"

export const ProfileDialog: React.FC<{ vm: ProfileViewModel }> = observer(({ vm }) => {
  const handleClose = () => {
    vm.editUserProfile = false
  }

  const dialogStrings: ConfirmDialogStrings = {
    title: vm.translate("profile.user.profile"),
    content: <ProfileForm vm={vm} />
  }

  return (
    <DialogMovicoders
      id="profileDialogMovicoders"
      open={true}
      draggable
      dialogConfig={dialogStrings}
      onConfirm={() => { }}
      onClose={() => handleClose()}
      closeWithX
      maxWidth={"md"}
      backgroundColorTitle={Colors.primary}
      fullWidth
    />
    
  )
})