/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewAddressDTO
 */
export interface NewAddressDTO {
    /**
     * 
     * @type {string}
     * @memberof NewAddressDTO
     */
    block?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAddressDTO
     */
    floor?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAddressDTO
     */
    floorNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAddressDTO
     */
    portalNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAddressDTO
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAddressDTO
     */
    province?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAddressDTO
     */
    stair?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAddressDTO
     */
    streetName?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAddressDTO
     */
    streetType?: string;
    /**
     * 
     * @type {string}
     * @memberof NewAddressDTO
     */
    town?: string;
}

export function NewAddressDTOFromJSON(json: any): NewAddressDTO {
    return NewAddressDTOFromJSONTyped(json, false);
}

export function NewAddressDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewAddressDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'block': !exists(json, 'block') ? undefined : json['block'],
        'floor': !exists(json, 'floor') ? undefined : json['floor'],
        'floorNumber': !exists(json, 'floorNumber') ? undefined : json['floorNumber'],
        'portalNumber': !exists(json, 'portalNumber') ? undefined : json['portalNumber'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'province': !exists(json, 'province') ? undefined : json['province'],
        'stair': !exists(json, 'stair') ? undefined : json['stair'],
        'streetName': !exists(json, 'streetName') ? undefined : json['streetName'],
        'streetType': !exists(json, 'streetType') ? undefined : json['streetType'],
        'town': !exists(json, 'town') ? undefined : json['town'],
    };
}

export function NewAddressDTOToJSON(value?: NewAddressDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'block': value.block,
        'floor': value.floor,
        'floorNumber': value.floorNumber,
        'portalNumber': value.portalNumber,
        'postalCode': value.postalCode,
        'province': value.province,
        'stair': value.stair,
        'streetName': value.streetName,
        'streetType': value.streetType,
        'town': value.town,
    };
}

