/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccessibilityEvaluationTechnicianDTO
 */
export interface AccessibilityEvaluationTechnicianDTO {
    /**
     * 
     * @type {string}
     * @memberof AccessibilityEvaluationTechnicianDTO
     */
    idNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessibilityEvaluationTechnicianDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessibilityEvaluationTechnicianDTO
     */
    title?: string;
}

export function AccessibilityEvaluationTechnicianDTOFromJSON(json: any): AccessibilityEvaluationTechnicianDTO {
    return AccessibilityEvaluationTechnicianDTOFromJSONTyped(json, false);
}

export function AccessibilityEvaluationTechnicianDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessibilityEvaluationTechnicianDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idNumber': !exists(json, 'idNumber') ? undefined : json['idNumber'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function AccessibilityEvaluationTechnicianDTOToJSON(value?: AccessibilityEvaluationTechnicianDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idNumber': value.idNumber,
        'name': value.name,
        'title': value.title,
    };
}

