import { AddImprovementToImprovementActionRequest, AddPhaseToImprovementActionRequest, DeleteImprovementToImprovementActionRequest, DeletePhaseFromImprovementActionRequest, ImprovementActionDTO, SetFireSafetyImprovementRequest, SetHealthinessImprovementRequest, SetNoisesImprovementRequest, SetOtherImprovementRequest, SetSavingEnergyImprovementRequest, SetSecurityImprovementRequest, UpdateMultipleImprovementsRequest } from "../../../clients";
import { Api } from "../../../infrastructure/data/api-client";
import { BaseRepository } from "../../../infrastructure/data/repositories/base-repository";
import IImprovementsApi from "../iimprovements-actions-api";

export default class ImprovementRepository extends BaseRepository<IImprovementsApi> {

  constructor() { super(Api.ImprovementActionsApi, false); }


  async deleteImprovementFromImprovementAction(requestParameters: DeleteImprovementToImprovementActionRequest) {
    const client = await this.apiClient;
    return await client.deleteImprovementFromImprovementAction(requestParameters);
  }

  async addPhaseToImprovementAction(requestParameters: AddPhaseToImprovementActionRequest): Promise<ImprovementActionDTO> {
    const response = await (await this.apiClient).addPhaseToImprovementAction(requestParameters);
    return response;
  }

  async deletePhaseFromImprovementAction(requestParameters: DeletePhaseFromImprovementActionRequest): Promise<ImprovementActionDTO> {
    const response = await (await this.apiClient).deletePhaseFromImprovementAction(requestParameters);
    return response;
  }

  async updateMultipleImprovements(requestParameters: UpdateMultipleImprovementsRequest) {
    const client = await this.apiClient;
    return await client.updateMultipleImprovements(requestParameters);
  }

  async addImprovementToImprovementAction(requestParameters: AddImprovementToImprovementActionRequest) {
    const client = await this.apiClient;
    return await client.addImprovementToImprovementAction(requestParameters);
  }

  async setFireSafetyImprovement(requestParameters: SetFireSafetyImprovementRequest) {
    const client = await this.apiClient;
    return await client.setFireSafetyImprovement(requestParameters);
  }
  async setHealthinessImprovement(requestParameters: SetHealthinessImprovementRequest) {
    const client = await this.apiClient;
    return await client.setHealthinessImprovement(requestParameters);
  }
  async setNoisesImprovement(requestParameters: SetNoisesImprovementRequest) {
    const client = await this.apiClient;
    return await client.setNoisesImprovement(requestParameters);
  }
  async setOtherImprovement(requestParameters: SetOtherImprovementRequest) {
    const client = await this.apiClient;
    return await client.setOtherImprovement(requestParameters);
  }
  async setSavingEnergyImprovement(requestParameters: SetSavingEnergyImprovementRequest) {
    const client = await this.apiClient;
    return await client.setSavingEnergyImprovement(requestParameters);
  }
  async setSecurityImprovement(requestParameters: SetSecurityImprovementRequest) {
    const client = await this.apiClient;
    return await client.setSecurityImprovement(requestParameters);
  }
}