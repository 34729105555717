import ICollegeApi from "../icollege-api"
import { Api } from "../../../infrastructure/data/api-client"
import { BaseRepository } from "../../../infrastructure/data/repositories/base-repository"
import { GetAllCollegeRequest } from "../../../clients";


export default class CollegeRepository extends BaseRepository<ICollegeApi> {

  constructor() { super(Api.CollegeApi, false); }

  async getAllCollege(requestParameters: GetAllCollegeRequest) {
    const client = await this.apiClient;
    return await client.getAllCollege(requestParameters);
  }

  async getCollegeById(id: string) {
    const client = await this.apiClient;
    return await client.getCollegeById(id);
  }

  async getCollegeByName(name: string) {
    const client = await this.apiClient;
    return await client.getCollegeByName(name);
  }
}