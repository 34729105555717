import AddIcon from "@mui/icons-material/Add";
import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { MultimediaDTO } from "../../../../../../clients/models";
import { Colors } from "../../../../../../constants";
import ReportViewModel from "../../../../../../viewmodels/report/report-view-model";
import { CustomReactDropzone } from "../../../block0/buildingIdentification/components/CustomReactDropzone";

export const UploadFileDialog: React.FC<{
  id: string;
  vm: ReportViewModel;
  buttonText: string;
  isUrbanPlan?: boolean;
  isPortrait?: boolean;
  mRight?: string;
  collect: (m: MultimediaDTO | undefined) => void;
}> = ({ id, vm, buttonText, isUrbanPlan, isPortrait, mRight, collect }) => {
  const [file, setFile] = useState<File | undefined>();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleCloseDialog = () => {
    setFile(undefined);
    setIsDialogOpen(false);
  };

  const handleFileUpload = async () => {
    if (file) {
      if (isPortrait === true) {
        collect(await vm.createMediaWithoutApiPortrait(file, isPortrait));
      } else {
        collect(await vm.createMediaWithoutApiManual(file, isUrbanPlan));
      }
    }
    setFile(undefined);
    setIsDialogOpen(false);
  };

  const handleOpenDialog = () => setIsDialogOpen(true);
  const retrievefile = async (file: File[]) => {
    try {
      setFile(file?.[0]);
    } catch (e) {}
  };
  return (
    <>
      {isDialogOpen ? (
        <Dialog open={true}>
          <DialogTitle id={id}>{buttonText}</DialogTitle>
          <DialogContent>
            <Typography fontSize={11}>{vm.translate("file.size.recommendation")}</Typography>
            <CustomReactDropzone
              retrieveFiles={retrievefile}
              oneFile
              infoTitle={vm.translate("report.block1.license.file.helper")}
              infoMessage={vm.translate("report.block1.license.file.helper.info")}
              vm={vm}
            />
            <Grid item xs={12} style={{ marginTop: ".5rem" }}>
              <Button id={"close-filedialog"} onClick={handleCloseDialog} style={{ marginRight: ".5rem" }}>
                Cerrar
              </Button>
              <Button id={"close-filedialog"} onClick={handleFileUpload}>
                Guardar
              </Button>
            </Grid>
          </DialogContent>
        </Dialog>
      ) : null}
      <Button
        id="anadirplano"
        variant="outlined"
        style={{ maxWidth: 250, marginRight: mRight ? mRight : 0 }}
        startIcon={<AddIcon />}
        onClick={handleOpenDialog}
      >
        {buttonText}
      </Button>
    </>
  );
};

export const UploadCSV: React.FC<{ vm: ReportViewModel; buttonText: string; mRight?: string; collect: (m: MultimediaDTO | undefined) => void }> = ({
  vm,
  buttonText,
  mRight,
  collect,
}) => {
  const [file, setFile] = useState<File | undefined>();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleCloseDialog = () => {
    setFile(undefined);
    setIsDialogOpen(false);
  };

  const handleFileUpload = async () => {
    if (file) {
      const f = { ...file };
      setFile(undefined);
      collect(await vm.createMediaWithoutApi(f));
    }
  };

  const handleOpenDialog = () => setIsDialogOpen(true);
  const retrievefile = async (file: File[]) => {
    try {
      setFile(file?.[0]);
    } catch (e) {}
  };
  return (
    <>
      {isDialogOpen ? (
        <Dialog open={true}>
          <DialogTitle>{buttonText}</DialogTitle>
          <DialogContent>
            <Typography fontSize={11}>{vm.translate("file.size.recommendation")}</Typography>
            <CustomReactDropzone
              retrieveFiles={retrievefile}
              oneFile
              infoTitle={vm.translate("report.block1.license.file.helper")}
              infoMessage={vm.translate("report.block1.license.file.helper.info")}
              vm={vm}
            />
            <Grid item xs={12} style={{ marginTop: ".5rem" }}>
              <Button id={"close-filedialog"} onClick={handleCloseDialog} style={{ marginRight: ".5rem", background: Colors.primary, color: Colors.white }}>
                Cerrar
              </Button>
              <Button id={"close-filedialog"} onClick={handleFileUpload} style={{ background: Colors.primary, color: Colors.white }}>
                Guardar
              </Button>
            </Grid>
          </DialogContent>
        </Dialog>
      ) : null}
      <Button
        id={buttonText + "csv"}
        variant="outlined"
        style={{ maxWidth: 250, marginRight: mRight ? mRight : 0, background: Colors.primary, color: Colors.white }}
        startIcon={<AddIcon />}
        onClick={handleOpenDialog}
      >
        {buttonText}
      </Button>
    </>
  );
};
