/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@movicoders/movicoders-components";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import {
  AgentParticipationDTO,
  AgentParticipationDTORoleEnum,
  AgentParticipationDTOTypeEnum,
  CommunityDTO,
  ProvinceDTO,
  StreetDTO,
  TownDTO,
} from "../../../clients";
import TextFieldGrid from "../../../components/text-fields/textfield-grid";
import TypographyTitle from "../../../components/typography-title";
import { Colors } from "../../../constants";
import { UUIDGenerator, filterOptions } from "../../../utils/arrayHelper";
import { mockParticipantAgent } from "../../../utils/book-helper";
import * as RegexHelper from "../../../utils/regexHelper";
import ReportViewModel from "../../../viewmodels/report/report-view-model";
import { ReportStyle } from "../report-style";

export const AddUserForm: React.FC<{ vm: ReportViewModel }> = observer(({ vm }) => {
  const classes = ReportStyle();

  const [newParticipant, setNewParticipant] = useState<AgentParticipationDTO>({});
  const [emailError, setEmailError] = useState(false);
  const [idNumberError, setIdNumberError] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isCif, setIsCif] = useState(false);
  const [editing, setEditing] = useState(false);
  const [hideAutocomplete, setHideAutocomplete] = useState(newParticipant?.town === "0");

  const { enqueueSnackbar } = useSnackbar();
  const handleOpenSnackBar = (snackKey: string, variants: undefined | "default" | "error" | "success" | "warning" | "info") => {
    enqueueSnackbar(snackKey, { variant: variants, preventDuplicate: true, key: snackKey, autoHideDuration: 3000 });
  };

  const checkParticipation = (agent: AgentParticipationDTO, agents: AgentParticipationDTO[]) => {
    if (agent.role !== AgentParticipationDTORoleEnum.Owner) return true;
    const totalAgentParticipation = agents
      .filter((a) => a.role === AgentParticipationDTORoleEnum.Owner)
      .filter((a) => a.id !== agent.id)
      .map((agent: AgentParticipationDTO) => agent.participation ?? 0);
    const totalParticipation = totalAgentParticipation.length ? totalAgentParticipation.reduce((prev: number = 0, curr: number = 0) => prev + curr) : 0;

    let canParticipate = false;

    if (agent.participation || agent.participation === 0)
      canParticipate = agent.participation >= 0 && Number(agent.participation) + totalParticipation <= 100;

    if (Number(newParticipant.participation) + totalParticipation > 100) {
      handleOpenSnackBar(vm.translate("report.block0.participation.error"), "error");
    }
    return canParticipate;
  };

  const saveAgent = async (agent: AgentParticipationDTO) => {
    const a = { ...agent, participation: Number(agent.participation ?? 0), id: UUIDGenerator() };
    await vm
      .addAgentParticipation(vm.userBook.id ?? "", a)
      .then((agent: AgentParticipationDTO) => {
        vm.userBook.agents = [agent, ...(vm.userBook.agents ?? [])];
        setNewParticipant({});
        vm.addUser = false;
        vm.editingParticipation = false;
        handleOpenSnackBar(vm.translate("report.block0.propertyData.save.success"), "success");
      })
      .catch((e) => handleOpenSnackBar(vm.translate("report.block0.propertyData.save.error"), "error"));
  };

  const saveAgentToBook = async (agent: AgentParticipationDTO) => {
    vm.loadingBook = true;
    const agents = vm.userBook.agents ?? [];
    let surnameError = agent.type === AgentParticipationDTOTypeEnum.Physical && (agent.surname === "" || agent.surname === undefined);
    const canParticipate = checkParticipation(agent, agents);
    if (!surnameError) {
      if (canParticipate) {
        if (vm.validatorVM.validate("adduserForm")) {
          vm.loadingBook = true;
          await saveAgent(agent);
        } else {
          handleOpenSnackBar(vm.translate("report.block0.propertyData.save.empty.error"), "error");
        }
      }
    } else handleOpenSnackBar(vm.translate("report.block0.propertyData.surnameField.error"), "error");
    vm.loadingBook = false;
  };

  const updateAgentB = async (agent: AgentParticipationDTO) => {
    await vm.bookRepository
      .editAgent({ bookId: vm.userBook.id ?? "", agentId: agent.id ?? "", agentParticipationDTO: agent })
      .then((agent: AgentParticipationDTO) => {
        vm.userBook.agents = [agent, ...(vm.userBook.agents?.filter((a) => a.id !== agent.id) ?? [])];
        setNewParticipant({});
        vm.currentAgent = mockParticipantAgent();
        setEditing(false);
        setIsEditing(false);
        vm.addUser = false;
        vm.editingParticipation = false;
        handleOpenSnackBar(vm.translate("report.block0.propertyData.save.success"), "success");
      })
      .catch((e: any) => {
        setNewParticipant({});
        setEditing(false);
        vm.editingParticipation = false;
        setIsEditing(false);
        handleOpenSnackBar(vm.translate("report.block0.propertyData.save.error"), "error");
      });
  };

  const updateAgent = async (agent: AgentParticipationDTO) => {
    vm.loadingBook = true;
    const agents = vm.userBook.agents ?? [];

    let surnameError = agent.type === AgentParticipationDTOTypeEnum.Physical && (agent.surname === "" || agent.surname === undefined);
    const canParticipate = checkParticipation(agent, agents);

    if (!surnameError) {
      if (canParticipate) {
        if (!(agent?.town && agent?.community && agent?.province && agent?.streetCode)) {
          handleOpenSnackBar(vm.translate("report.block0.propertyData.save.error"), "error");
          vm.loadingBook = false;
          return;
        }
        vm.loadingBook = true;
        await updateAgentB(agent);
      }
    } else handleOpenSnackBar(vm.translate("report.block0.propertyData.surnameField.error"), "error");
    setNewParticipant({});
    vm.currentAgent = mockParticipantAgent();
    setEditing(false);
    vm.editingParticipation = false;
    vm.addUser = false;
    setIsEditing(false);
    vm.loadingBook = false;
    vm.role = "";
  };

  const checkNumericInputs = () => {
    if (newParticipant.phone?.match(RegexHelper.validPhoneNumber)?.length !== 1) {
      newParticipant.phone = "";
    }
    return newParticipant?.mobilePhone?.match(RegexHelper.validPhoneNumber)?.length === 1;
  };

  const handleNifInput = (value: string) => {
    setIsCif(RegexHelper.CIF_REGEX.test(value));
    setNewParticipant({ ...newParticipant, idNumber: value });
  };

  const handleSaveAgent = async () => {
    let participant = { ...newParticipant };
    //Calcular tipo (Persona física / jurídica)
    if (participant.role !== AgentParticipationDTORoleEnum.Owner && participant.role !== AgentParticipationDTORoleEnum.HomeownersPresident)
      participant.participation = 100;

    const type = RegexHelper.CIF_REGEX.test(participant.idNumber ?? "");

    if (type) {
      //type = CIF
      participant.surname = "-";
      participant.type = AgentParticipationDTOTypeEnum.Legal;
    } else {
      //type = DNI/NIF
      participant.type = AgentParticipationDTOTypeEnum.Physical;
    }

    if (!isEditing) {
      const validCif = RegexHelper.validCif.test(participant.idNumber!.toUpperCase() ?? "");
      const email = RegexHelper.validEmail.test(participant.email ?? "");
      const validIdNumber =
        RegexHelper.CIF_REGEX.test(participant.idNumber!.toUpperCase() ?? "") ||
        RegexHelper.NIE_REGEX.test(participant.idNumber!.toUpperCase() ?? "") ||
        RegexHelper.DNI_REGEX.test(participant.idNumber!.toUpperCase() ?? "");

      if (checkNumericInputs()) {
        setEmailError(!email);
        setIdNumberError(!((validCif || validIdNumber) && participant.idNumber!.length === 9));

        if (email === true && (validCif || validIdNumber) === true) {
          if (!(participant?.town && participant?.community && participant?.province && participant?.streetCode)) {
            handleOpenSnackBar(vm.translate("Debes rellenar la dirección"), "error");
          } else {
            saveAgentToBook(participant);
          }
        } else {
          handleOpenSnackBar(vm.translate("report.block0.dni.and.email.not.valid"), "error");
        }
      } else {
        handleOpenSnackBar(vm.translate("report.block0.propertyData.save.empty.error"), "error");
      }
    } else {
      saveAgentToBook(participant);
    }
  };

  const handleUpdateAgent = async () => {
    updateAgent(newParticipant);
  };

  const [provinces, setProvinces] = useState<ProvinceDTO[] | null>([]);
  const [towns, setTowns] = useState<TownDTO[] | null>([]);
  const [communities, setCommunites] = useState<CommunityDTO[] | null>([]);
  const [streets, setStreets] = useState<StreetDTO[] | null>([]);

  const [updateView, setUpdateView] = useState<boolean>(true);

  const getCommunities = async () => {
    const c = await vm.parent.communityRepository.getAllCommunity().catch((e) => []);
    setCommunites(c);
    return c;
  };

  const getProvinces = async (communityCode: string) => {
    const result = await vm.parent.communityRepository.getProvinceByCommunityCode(communityCode).catch((e) => []);
    setProvinces(result);
    return result;
  };

  const getTowns = async (provinceCode: string) => {
    const result = await vm.parent.provincesRepository.getAllTownByProvinceCode(provinceCode).catch((e) => []);
    setTowns(result);
    return result;
  };

  const getStreets = async (townId: string) => {
    const result = await vm.parent.townRepository.getStreetsByTownId(townId).catch((e) => []);
    setStreets(result.sort((a, b) => a.name.localeCompare(b.name)));
    return result;
  };

  useEffect(() => {
    if (newParticipant?.town) {
      const town = towns?.find((t) => t.name === newParticipant?.town);
      if (town?.id) getStreets(town.id);
    }
  }, [newParticipant?.town]);

  useEffect(() => {
    if (newParticipant?.province) {
      const province = provinces?.find((p) => p.name === newParticipant?.province);
      if (province?.code) getTowns(province.code);
    }
  }, [newParticipant?.province]);

  useEffect(() => {
    if (newParticipant?.community) {
      const ccaas = communities?.length ? communities : vm.parent.communities ?? [];
      const community = ccaas?.find((c) => c.name === newParticipant?.community);
      if (community?.code) getProvinces(community.code);
    }
  }, [newParticipant?.community]);

  const getActualFullAddress = async () => {
    const c = await getCommunities();
    if (newParticipant?.community && newParticipant?.province && newParticipant?.town && newParticipant?.streetName) {
      const community = c.find((c) => c.name === newParticipant.community);
      if (community?.code) {
        const p = await getProvinces(community.code);
        const province = p.find((p) => p.name === newParticipant.province);
        if (province?.code) {
          const t = await getTowns(province.code);
          const town = t.find((t) => t.name === newParticipant.town);
          if (town?.id) {
            await getStreets(town.id);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (vm.currentAgent.id !== "") {
      setEditing(true);
      setNewParticipant(vm.currentAgent);
    } else {
      setEditing(false);
      setNewParticipant(mockParticipantAgent());
    }
    getActualFullAddress();
    return () => {
      setProvinces(null);
      setTowns(null);
      setCommunites(null);
      setStreets(null);
    };
  }, []);

  const checkDisabled = (isDisabled: boolean): boolean => {
    return isEditing || isDisabled;
  };

  return (
    <Grid container>
      <Grid item xs={12} md={12} style={{ display: "flex" }}>
        <TextFieldGrid
          id="addUserNif"
          disabled={checkDisabled(false)}
          form="adduserForm"
          required
          validator={vm.validatorVM}
          value={newParticipant.idNumber ?? ""}
          title={vm.translate("report.block0.nif")}
          gridTextField={{ xs: 4, md: 4 }}
          onChange={(evt: any) => handleNifInput(evt.target.value.toUpperCase())}
          error={idNumberError}
          errorText={vm.translate("report.block0.agents.form.idNumberError")}
        />
        <TextFieldGrid
          id="addUserName"
          disabled={checkDisabled(false)}
          form="adduserForm"
          validator={vm.validatorVM}
          required
          value={newParticipant.name ?? ""}
          title={vm.translate("report.block0.name")}
          style={{ marginRight: 5 }}
          gridTextField={{ xs: 4, md: 4 }}
          onChange={(evt: any) => setNewParticipant({ ...newParticipant, name: evt.target.value })}
        />
        <TextFieldGrid
          id="addUserSurname"
          disabled={checkDisabled(false) || isCif}
          value={newParticipant.surname ?? ""}
          required
          title={vm.translate("report.block0.surname")}
          style={{ marginRight: 5 }}
          gridTextField={{ xs: 4, md: 4 }}
          onChange={(evt: any) => setNewParticipant({ ...newParticipant, surname: evt.target.value })}
        />
      </Grid>

      <Grid item xs={12} md={12} className={`${classes.report_generalDataGrid} ${classes.report_textFieldGrid}`}>
        <Grid item xs={4} md={4} style={{ marginRight: 5 }}>
          <Autocomplete
            disablePortal
            id="addUserCcaa"
            disabled={checkDisabled(false)}
            value={{ name: newParticipant.community ?? "" }}
            options={vm.parent.communities || []}
            fullWidth
            clearIcon={<></>}
            getOptionLabel={(option: CommunityDTO) => option.name ?? ""}
            renderInput={(params) => (
              <TextField {...params} className={classes.report_autocomplete} size="small" label={vm.translate("report.block0.ccaa")} />
            )}
            onChange={(event: any, value: CommunityDTO | null) => {
              if (value?.name !== newParticipant.community) setNewParticipant({ ...newParticipant, community: value?.name ?? "" });
            }}
          />
        </Grid>
        <Grid item xs={4} md={4} style={{ marginRight: 5 }}>
          <Autocomplete
            disablePortal
            id="addUserProvince"
            disabled={checkDisabled(false) ? isEditing : newParticipant?.community ? false : true}
            value={{ name: newParticipant.province ?? "" }}
            options={provinces || []}
            fullWidth
            clearIcon={<></>}
            getOptionLabel={(option: ProvinceDTO) => option.name ?? ""}
            renderInput={(params) => (
              <TextField {...params} className={classes.report_autocomplete} size="small" label={vm.translate("report.block0.province")} />
            )}
            onChange={(event: any, value: ProvinceDTO | null) => {
              if (value?.code !== newParticipant.province) setNewParticipant({ ...newParticipant, province: value?.name ?? "" });
            }}
          />
        </Grid>
        <Grid item xs={4} md={4} style={{ marginRight: 5 }}>
          {hideAutocomplete ? (
            <TextFieldGrid
              id={"towncode0"}
              title={vm.translate("launch.town")}
              form="adduserForm"
              validator={vm.validatorVM}
              gridTextField={{ xs: 12, md: 12 }}
              gridClassName={classes.report_textField}
              onChange={(evt: any) => {
                if (evt.target.value !== newParticipant.townName) setNewParticipant({ ...newParticipant, townName: evt.target.value });
              }}
              value={newParticipant.townName ?? ""}
            />
          ) : (
            <Autocomplete
              disablePortal
              id="addUserTown"
              disabled={checkDisabled(false) ? isEditing : newParticipant?.province ? false : true}
              value={{ name: newParticipant.town ?? "" }}
              options={towns || []}
              fullWidth
              clearIcon={<></>}
              getOptionLabel={(option: TownDTO) => option.name ?? ""}
              renderInput={(params) => (
                <TextField {...params} className={classes.report_autocomplete} size="small" label={vm.translate("report.block1.town")} />
              )}
              onChange={(event: any, value: TownDTO | null) => {
                if (value && newParticipant) setNewParticipant({ ...newParticipant, town: value?.name ?? "", townName: value?.name });
              }}
            />
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} style={{ display: "flex" }} className={classes.report_generalDataGrid}>
        <Grid item xs={6} style={{ marginTop: 10, marginRight: 5 }}>
          {hideAutocomplete ? (
            <TextFieldGrid
              id={"streetcode0"}
              form="adduserForm"
              required
              validator={vm.validatorVM}
              gridTextField={{ xs: 12, md: 12 }}
              title={vm.translate("report.block1.address")}
              onChange={(evt: any) => {
                setNewParticipant({ ...newParticipant, streetName: evt.target.value });
              }}
              value={newParticipant?.streetName}
            />
          ) : (
            <Autocomplete
              disablePortal
              id="addUserStreet"
              value={{ name: newParticipant.streetName ?? "" }}
              disabled={checkDisabled(false) ? isEditing : newParticipant?.town ? false : true}
              options={streets || []}
              fullWidth
              filterOptions={filterOptions}
              clearIcon={<></>}
              getOptionLabel={(option: StreetDTO) => `${option.type ?? ""} ${option.name ?? ""}`.trim()}
              renderInput={(params) => (
                <TextField {...params} required className={classes.report_autocomplete} size="small" label={vm.translate("report.block0.column.street")} />
              )}
              onChange={(event: any, value: StreetDTO | null) => {
                if (value && newParticipant) setNewParticipant({ ...newParticipant, streetName: value?.name ?? "", streetCode: value?.name });
              }}
            />
          )}
        </Grid>
        <TextFieldGrid
          id="addUserPortalNumber"
          disabled={checkDisabled(false)}
          value={newParticipant?.portalNumber ?? ""}
          title={"Nº"}
          style={{ marginRight: 5 }}
          form="adduserForm"
          required
          validator={vm.validatorVM}
          gridTextField={{ xs: 3, md: 3 }}
          onChange={(evt: any) => setNewParticipant({ ...newParticipant, portalNumber: evt.target.value })}
        />
        <TextFieldGrid
          id="addUserPostalCode"
          disabled={checkDisabled(false)}
          form="adduserForm"
          validator={vm.validatorVM}
          required
          value={newParticipant?.postalCode?.toString() ?? ""}
          title={vm.translate("report.block0.cp")}
          gridTextField={{ xs: 3, md: 3 }}
          onChange={(evt: any) => setNewParticipant({ ...newParticipant, postalCode: +evt.target.value })}
        />
      </Grid>
      <Grid item xs={12} md={12} style={{ marginTop: 5 }}>
        <TypographyTitle id="addUserContact" title={vm.translate("report.block0.contact")} />
      </Grid>
      <Grid item xs={12} md={12} style={{ display: "grid" }}>
        <TextFieldGrid
          id={"addUserEmail"}
          form="adduserForm"
          validator={vm.validatorVM}
          disabled={checkDisabled(false)}
          required
          value={newParticipant.email ?? ""}
          title={vm.translate("report.block0.email")}
          style={{ marginRight: 5 }}
          gridTextField={{ xs: 6, md: 6 }}
          onChange={(evt: any) => setNewParticipant({ ...newParticipant, email: evt.target.value })}
          error={emailError}
          errorText={emailError ? vm.translate("report.block0.agents.form.emailError") : vm.translate("report.block0.agents.user.exists")}
        />
        <Grid item xs={12} md={12} style={{ display: "flex" }}>
          <TextFieldGrid
            id="addUserMobile"
            disabled={checkDisabled(false)}
            required
            form="adduserForm"
            validator={vm.validatorVM}
            value={newParticipant.mobilePhone ?? ""}
            title={vm.translate("report.block0.mobile")}
            style={{ marginRight: 5 }}
            gridTextField={{ xs: 6, md: 6 }}
            onChange={(evt: any) => setNewParticipant({ ...newParticipant, mobilePhone: evt.target.value.replace(/[a-z,A-Z]/g, "") })}
          />
          <TextFieldGrid
            id="addUserPhone"
            disabled={checkDisabled(false)}
            value={newParticipant.phone ?? ""}
            title={vm.translate("report.block0.phone")}
            style={{ marginRight: 5 }}
            gridTextField={{ xs: 6, md: 6 }}
            onChange={(evt: any) => setNewParticipant({ ...newParticipant, phone: evt.target.value.replace(/[a-z,A-Z]/g, "") })}
          />
        </Grid>
        <Grid item xs={12} md={12} style={{ display: "flex" }}>
          <Grid xs={12} md={6} mr={1} style={{ height: "40px" }}>
            <Autocomplete
              disablePortal
              id="addUserOwner"
              size={"small"}
              value={newParticipant.role ?? ""}
              getOptionLabel={(option: string) => {
                return vm.translate("report.block0.user." + (option.toUpperCase() ?? AgentParticipationDTORoleEnum.PropertyManager));
              }}
              options={Object.values(AgentParticipationDTORoleEnum).filter((a) => a !== AgentParticipationDTORoleEnum.Technician)}
              onChange={(event, value) => {
                setNewParticipant({ ...newParticipant, role: value as AgentParticipationDTORoleEnum });
              }}
              renderInput={(params) => (
                <TextField {...params} className={classes.report_autocomplete} required size="small" label={vm.translate("report.block0.role")} />
              )}
              style={{ width: "100%", marginTop: 10 }}
            />
          </Grid>
          {newParticipant.role === AgentParticipationDTORoleEnum.Owner || newParticipant.role === AgentParticipationDTORoleEnum.HomeownersPresident ? (
            <TextFieldGrid
              id="agentparticipation"
              disabled={checkDisabled(false)}
              value={newParticipant.participation?.toString() ?? ""}
              title={vm.translate("report.block0.participation")}
              style={{ marginRight: 5 }}
              gridTextField={{ xs: 12, md: 6 }}
              onChange={(evt: any) => {
                setNewParticipant({ ...newParticipant, participation: evt.target.value.replace(/[a-z,A-Z]/g, "") });
              }}
            />
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "flex-end", padding: 10 }}>
        <Button
          id="addUserSave"
          text={vm.translate("save")}
          onClick={editing ? handleUpdateAgent : handleSaveAgent}
          textColor={Colors.white}
          backgroundColor={Colors.buttonSecondary}
          hoverColor={Colors.buttonSecondary}
        />
      </Grid>
    </Grid>
  );
});
