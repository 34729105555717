export class FormValidator {
  errorsMap!: Map<string, Map<string, boolean>>;

  constructor() {
    this.errorsMap = new Map<string, Map<string, boolean>>();
  }

  setErrorForKey(form?: string, key?: string, value?: boolean): void {
    if (form && key && value !== undefined) {
      let formMap = this.errorsMap.get(form);
      if (!formMap) {
        formMap = new Map<string, boolean>();
        this.errorsMap.set(form, formMap);
      }
      formMap.set(key, value);
    }
  }

  hasErrorForKey(form?: string, key?: string): boolean {
    if (!form || !key) return false;
    return this.errorsMap.get(form)?.get(key) ?? false;
  }

  validate(form: string) {
    let isValid = true
    this.errorsMap.get(form)?.forEach((value, key) => {
        if(value === true) {
            isValid = false
        }
    })
    return isValid
  }
}
