/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessibilityEvaluationTechnicianDTO,
    AccessibilityEvaluationTechnicianDTOFromJSON,
    AccessibilityEvaluationTechnicianDTOFromJSONTyped,
    AccessibilityEvaluationTechnicianDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface FunctionalConditionsDTO
 */
export interface FunctionalConditionsDTO {
    /**
     * 
     * @type {boolean}
     * @memberof FunctionalConditionsDTO
     */
    accessWithLiftButNotAccordingTheStandardBetweenBuildingFloors?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FunctionalConditionsDTO
     */
    accessWithLiftButNotAccordingTheStandardBetweenBuildingFloorsNotes?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FunctionalConditionsDTO
     */
    accessibilityBetweenBuildingFloorsBetweenCommonZonesInSameFloor?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FunctionalConditionsDTO
     */
    accessibilityBetweenBuildingFloorsBetweenLivingHomesInSameFloor?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FunctionalConditionsDTO
     */
    accessibilityBetweenBuildingFloorsBetweenThem?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FunctionalConditionsDTO
     */
    accessibilityBetweenBuildingFloorsNotes?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FunctionalConditionsDTO
     */
    accessibilityBetweenBuildingFloorsWheelChairAccessibility?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FunctionalConditionsDTO
     */
    accessibilityBetweenBuildingFloorsWheelChairAccessibilityNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof FunctionalConditionsDTO
     */
    accessibilityBetweenFloorsNotes?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FunctionalConditionsDTO
     */
    accessibilityWithLiftBetweenBuildingFloorsWheelChairAccessibility?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FunctionalConditionsDTO
     */
    areaOfApplication?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FunctionalConditionsDTO
     */
    areaOfApplicationNotes?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FunctionalConditionsDTO
     */
    buildingExternalAccessWithCommonZones?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FunctionalConditionsDTO
     */
    buildingExternalAccessWithPublicRoad?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FunctionalConditionsDTO
     */
    externalAccessibilityNotes?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FunctionalConditionsDTO
     */
    hasSpaceToInstallLiftOrRampBetweenBuildingFloors?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FunctionalConditionsDTO
     */
    moreThanTwelveLivingPlacesInFloorsWithoutAccessibleMainEntrance?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FunctionalConditionsDTO
     */
    moreThanTwelveLivingPlacesInFloorsWithoutAccessibleMainEntranceValue?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FunctionalConditionsDTO
     */
    moreThanTwoFloorsBetweenAccessibleMainEntranceToLivingPlaceOrCommonZone?: boolean;
    /**
     * 
     * @type {number}
     * @memberof FunctionalConditionsDTO
     */
    moreThanTwoFloorsBetweenAccessibleMainEntranceToLivingPlaceOrCommonZoneValue?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FunctionalConditionsDTO
     */
    singleFamilyHomeExternalAccessWithCommonZones?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FunctionalConditionsDTO
     */
    singleFamilyHomeExternalAccessWithPublicRoad?: boolean;
    /**
     * 
     * @type {AccessibilityEvaluationTechnicianDTO}
     * @memberof FunctionalConditionsDTO
     */
    technician?: AccessibilityEvaluationTechnicianDTO;
    /**
     * 
     * @type {boolean}
     * @memberof FunctionalConditionsDTO
     */
    wheelChairAccessibleWithLiftOrRampAssociatedElements?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FunctionalConditionsDTO
     */
    wheelChairAccessibleWithLiftOrRampBuildingEntrance?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FunctionalConditionsDTO
     */
    wheelChairAccessibleWithLiftOrRampCommonElements?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FunctionalConditionsDTO
     */
    wheelChairAccessibleWithLiftOrRampNotes?: string;
}

export function FunctionalConditionsDTOFromJSON(json: any): FunctionalConditionsDTO {
    return FunctionalConditionsDTOFromJSONTyped(json, false);
}

export function FunctionalConditionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FunctionalConditionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessWithLiftButNotAccordingTheStandardBetweenBuildingFloors': !exists(json, 'accessWithLiftButNotAccordingTheStandardBetweenBuildingFloors') ? undefined : json['accessWithLiftButNotAccordingTheStandardBetweenBuildingFloors'],
        'accessWithLiftButNotAccordingTheStandardBetweenBuildingFloorsNotes': !exists(json, 'accessWithLiftButNotAccordingTheStandardBetweenBuildingFloorsNotes') ? undefined : json['accessWithLiftButNotAccordingTheStandardBetweenBuildingFloorsNotes'],
        'accessibilityBetweenBuildingFloorsBetweenCommonZonesInSameFloor': !exists(json, 'accessibilityBetweenBuildingFloorsBetweenCommonZonesInSameFloor') ? undefined : json['accessibilityBetweenBuildingFloorsBetweenCommonZonesInSameFloor'],
        'accessibilityBetweenBuildingFloorsBetweenLivingHomesInSameFloor': !exists(json, 'accessibilityBetweenBuildingFloorsBetweenLivingHomesInSameFloor') ? undefined : json['accessibilityBetweenBuildingFloorsBetweenLivingHomesInSameFloor'],
        'accessibilityBetweenBuildingFloorsBetweenThem': !exists(json, 'accessibilityBetweenBuildingFloorsBetweenThem') ? undefined : json['accessibilityBetweenBuildingFloorsBetweenThem'],
        'accessibilityBetweenBuildingFloorsNotes': !exists(json, 'accessibilityBetweenBuildingFloorsNotes') ? undefined : json['accessibilityBetweenBuildingFloorsNotes'],
        'accessibilityBetweenBuildingFloorsWheelChairAccessibility': !exists(json, 'accessibilityBetweenBuildingFloorsWheelChairAccessibility') ? undefined : json['accessibilityBetweenBuildingFloorsWheelChairAccessibility'],
        'accessibilityBetweenBuildingFloorsWheelChairAccessibilityNotes': !exists(json, 'accessibilityBetweenBuildingFloorsWheelChairAccessibilityNotes') ? undefined : json['accessibilityBetweenBuildingFloorsWheelChairAccessibilityNotes'],
        'accessibilityBetweenFloorsNotes': !exists(json, 'accessibilityBetweenFloorsNotes') ? undefined : json['accessibilityBetweenFloorsNotes'],
        'accessibilityWithLiftBetweenBuildingFloorsWheelChairAccessibility': !exists(json, 'accessibilityWithLiftBetweenBuildingFloorsWheelChairAccessibility') ? undefined : json['accessibilityWithLiftBetweenBuildingFloorsWheelChairAccessibility'],
        'areaOfApplication': !exists(json, 'areaOfApplication') ? undefined : json['areaOfApplication'],
        'areaOfApplicationNotes': !exists(json, 'areaOfApplicationNotes') ? undefined : json['areaOfApplicationNotes'],
        'buildingExternalAccessWithCommonZones': !exists(json, 'buildingExternalAccessWithCommonZones') ? undefined : json['buildingExternalAccessWithCommonZones'],
        'buildingExternalAccessWithPublicRoad': !exists(json, 'buildingExternalAccessWithPublicRoad') ? undefined : json['buildingExternalAccessWithPublicRoad'],
        'externalAccessibilityNotes': !exists(json, 'externalAccessibilityNotes') ? undefined : json['externalAccessibilityNotes'],
        'hasSpaceToInstallLiftOrRampBetweenBuildingFloors': !exists(json, 'hasSpaceToInstallLiftOrRampBetweenBuildingFloors') ? undefined : json['hasSpaceToInstallLiftOrRampBetweenBuildingFloors'],
        'moreThanTwelveLivingPlacesInFloorsWithoutAccessibleMainEntrance': !exists(json, 'moreThanTwelveLivingPlacesInFloorsWithoutAccessibleMainEntrance') ? undefined : json['moreThanTwelveLivingPlacesInFloorsWithoutAccessibleMainEntrance'],
        'moreThanTwelveLivingPlacesInFloorsWithoutAccessibleMainEntranceValue': !exists(json, 'moreThanTwelveLivingPlacesInFloorsWithoutAccessibleMainEntranceValue') ? undefined : json['moreThanTwelveLivingPlacesInFloorsWithoutAccessibleMainEntranceValue'],
        'moreThanTwoFloorsBetweenAccessibleMainEntranceToLivingPlaceOrCommonZone': !exists(json, 'moreThanTwoFloorsBetweenAccessibleMainEntranceToLivingPlaceOrCommonZone') ? undefined : json['moreThanTwoFloorsBetweenAccessibleMainEntranceToLivingPlaceOrCommonZone'],
        'moreThanTwoFloorsBetweenAccessibleMainEntranceToLivingPlaceOrCommonZoneValue': !exists(json, 'moreThanTwoFloorsBetweenAccessibleMainEntranceToLivingPlaceOrCommonZoneValue') ? undefined : json['moreThanTwoFloorsBetweenAccessibleMainEntranceToLivingPlaceOrCommonZoneValue'],
        'singleFamilyHomeExternalAccessWithCommonZones': !exists(json, 'singleFamilyHomeExternalAccessWithCommonZones') ? undefined : json['singleFamilyHomeExternalAccessWithCommonZones'],
        'singleFamilyHomeExternalAccessWithPublicRoad': !exists(json, 'singleFamilyHomeExternalAccessWithPublicRoad') ? undefined : json['singleFamilyHomeExternalAccessWithPublicRoad'],
        'technician': !exists(json, 'technician') ? undefined : AccessibilityEvaluationTechnicianDTOFromJSON(json['technician']),
        'wheelChairAccessibleWithLiftOrRampAssociatedElements': !exists(json, 'wheelChairAccessibleWithLiftOrRampAssociatedElements') ? undefined : json['wheelChairAccessibleWithLiftOrRampAssociatedElements'],
        'wheelChairAccessibleWithLiftOrRampBuildingEntrance': !exists(json, 'wheelChairAccessibleWithLiftOrRampBuildingEntrance') ? undefined : json['wheelChairAccessibleWithLiftOrRampBuildingEntrance'],
        'wheelChairAccessibleWithLiftOrRampCommonElements': !exists(json, 'wheelChairAccessibleWithLiftOrRampCommonElements') ? undefined : json['wheelChairAccessibleWithLiftOrRampCommonElements'],
        'wheelChairAccessibleWithLiftOrRampNotes': !exists(json, 'wheelChairAccessibleWithLiftOrRampNotes') ? undefined : json['wheelChairAccessibleWithLiftOrRampNotes'],
    };
}

export function FunctionalConditionsDTOToJSON(value?: FunctionalConditionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessWithLiftButNotAccordingTheStandardBetweenBuildingFloors': value.accessWithLiftButNotAccordingTheStandardBetweenBuildingFloors,
        'accessWithLiftButNotAccordingTheStandardBetweenBuildingFloorsNotes': value.accessWithLiftButNotAccordingTheStandardBetweenBuildingFloorsNotes,
        'accessibilityBetweenBuildingFloorsBetweenCommonZonesInSameFloor': value.accessibilityBetweenBuildingFloorsBetweenCommonZonesInSameFloor,
        'accessibilityBetweenBuildingFloorsBetweenLivingHomesInSameFloor': value.accessibilityBetweenBuildingFloorsBetweenLivingHomesInSameFloor,
        'accessibilityBetweenBuildingFloorsBetweenThem': value.accessibilityBetweenBuildingFloorsBetweenThem,
        'accessibilityBetweenBuildingFloorsNotes': value.accessibilityBetweenBuildingFloorsNotes,
        'accessibilityBetweenBuildingFloorsWheelChairAccessibility': value.accessibilityBetweenBuildingFloorsWheelChairAccessibility,
        'accessibilityBetweenBuildingFloorsWheelChairAccessibilityNotes': value.accessibilityBetweenBuildingFloorsWheelChairAccessibilityNotes,
        'accessibilityBetweenFloorsNotes': value.accessibilityBetweenFloorsNotes,
        'accessibilityWithLiftBetweenBuildingFloorsWheelChairAccessibility': value.accessibilityWithLiftBetweenBuildingFloorsWheelChairAccessibility,
        'areaOfApplication': value.areaOfApplication,
        'areaOfApplicationNotes': value.areaOfApplicationNotes,
        'buildingExternalAccessWithCommonZones': value.buildingExternalAccessWithCommonZones,
        'buildingExternalAccessWithPublicRoad': value.buildingExternalAccessWithPublicRoad,
        'externalAccessibilityNotes': value.externalAccessibilityNotes,
        'hasSpaceToInstallLiftOrRampBetweenBuildingFloors': value.hasSpaceToInstallLiftOrRampBetweenBuildingFloors,
        'moreThanTwelveLivingPlacesInFloorsWithoutAccessibleMainEntrance': value.moreThanTwelveLivingPlacesInFloorsWithoutAccessibleMainEntrance,
        'moreThanTwelveLivingPlacesInFloorsWithoutAccessibleMainEntranceValue': value.moreThanTwelveLivingPlacesInFloorsWithoutAccessibleMainEntranceValue,
        'moreThanTwoFloorsBetweenAccessibleMainEntranceToLivingPlaceOrCommonZone': value.moreThanTwoFloorsBetweenAccessibleMainEntranceToLivingPlaceOrCommonZone,
        'moreThanTwoFloorsBetweenAccessibleMainEntranceToLivingPlaceOrCommonZoneValue': value.moreThanTwoFloorsBetweenAccessibleMainEntranceToLivingPlaceOrCommonZoneValue,
        'singleFamilyHomeExternalAccessWithCommonZones': value.singleFamilyHomeExternalAccessWithCommonZones,
        'singleFamilyHomeExternalAccessWithPublicRoad': value.singleFamilyHomeExternalAccessWithPublicRoad,
        'technician': AccessibilityEvaluationTechnicianDTOToJSON(value.technician),
        'wheelChairAccessibleWithLiftOrRampAssociatedElements': value.wheelChairAccessibleWithLiftOrRampAssociatedElements,
        'wheelChairAccessibleWithLiftOrRampBuildingEntrance': value.wheelChairAccessibleWithLiftOrRampBuildingEntrance,
        'wheelChairAccessibleWithLiftOrRampCommonElements': value.wheelChairAccessibleWithLiftOrRampCommonElements,
        'wheelChairAccessibleWithLiftOrRampNotes': value.wheelChairAccessibleWithLiftOrRampNotes,
    };
}

