import { ProvincesApi as ProvincesServiceApi } from "../../../clients";
import Province from "../../entities/province-entity";
import Town from "../../entities/town-entity";
import IProvincesApi from "../iprovinces-api";
import { BaseAPI, Configuration } from "../../../clients/runtime";

export class ProvincesApi extends BaseAPI implements IProvincesApi {
  apiClient: ProvincesServiceApi;

  constructor(configuration?: Configuration) {
    super(configuration);
    this.apiClient = new ProvincesServiceApi(configuration);
  }
  async getProvinces(): Promise<Province[]> {
    const response = await this.apiClient.getProvinces();
    return Province.fromClientProvinceList(response);
  }

  async getProvinceByCode(code: string): Promise<Province> {
    const response = await this.apiClient.getProvinceByCode({ code: code });
    return Province.fromClientProvince(response);
  }

  async getTownsForProvinceCode(code: string): Promise<Town[]> {
    const response = await this.apiClient.getTownsForProvinceCode({ code: code });
    return Town.fromClientTownList(response);
  }

  async getProvinceByName(name: string): Promise<Province[]> {
    const response = await this.apiClient.getProvinceByName({ name: name });
    return Province.fromClientProvinceList(response);
  }
}
