/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Box, CircularProgress, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AddressDTO, CommunityDTO, ProvinceDTO, StreetDTO, TownDTO } from "../../../../../clients/models";
import TextFieldGrid from "../../../../../components/text-fields/textfield-grid";
import { filterOptions } from "../../../../../utils/arrayHelper";
import ProfileViewModel from "../../../../../viewmodels/profile/profile-view-model";
import { ReportStyle } from "../../../../reports/report-style";
import { ProfileStyle } from "../../../profile-styles";
import { addressDTOMock } from "../../../../../viewmodels/profile/data";

export const useAddress = (vm: ProfileViewModel, _address: AddressDTO) => {
  const classes = ProfileStyle();
  const reportClasses = ReportStyle();
  const [hideAutocomplete, setHideAutocomplete] = useState(vm.profileUser?.address?.town === "0");

  const [address, setAddress] = useState<AddressDTO | null>(addressDTOMock(_address));
  const [provinces, setProvinces] = useState<ProvinceDTO[] | null>([]);
  const [towns, setTowns] = useState<TownDTO[] | null>([]);
  const [streets, setStreets] = useState<StreetDTO[] | null>([]);

  const [updateView, setUpdateView] = useState<boolean>(true);

  const getProvinces = async (communityCode: string) => {
    const result = await vm.parent.communityRepository.getProvinceByCommunityCode(communityCode).catch((e) => []);
    setProvinces(result);
    return result;
  };

  const getTowns = async (provinceCode: string) => {
    const result = await vm.parent.provincesRepository.getAllTownByProvinceCode(provinceCode).catch((e) => []);
    setTowns(result);
    return result;
  };

  const getStreets = async (townId: string) => {
    const result = await vm.parent.townRepository.getStreetsByTownId(townId).catch((e) => []);
    setStreets(result.sort((a, b) => a.name.localeCompare(b.name)));
    return result;
  };

  useEffect(() => {
    const getCommunities = async () => {
      const result = (await vm.parent.communityRepository.getAllCommunity()) ?? [];
      vm.parent.communities = result;
    };
    getCommunities();
  }, []);

  useEffect(() => {
    if (address?.town) {
      const town = towns?.find((t) => t.name === address?.town);
      if (town?.id) getStreets(town.id);
    }
  }, [address?.town]);

  useEffect(() => {
    if (address?.province) {
      const province = provinces?.find((p) => p.name === address?.province);
      if (province?.code) getTowns(province.code);
    }
  }, [address?.province]);

  useEffect(() => {
    if (address?.community) {
      const ccaas = vm.parent.communities ?? [];
      const community = ccaas?.find((c) => c.name === address?.community);
      if (community?.code) getProvinces(community.code);
    }
  }, [address?.community]);

  const getActualFullAddress = async () => {
    if (address?.community && address?.province && address?.town && address?.streetName) {
      const community = vm.parent.communities?.find((c) => c.name === address.community);
      if (community?.code) {
        const p = await getProvinces(community.code);
        const province = p.find((p) => p.name === address.province);
        if (province?.code) {
          const t = await getTowns(province.code);
          const town = t.find((t) => t.name === address.town);
          if (town?.id) {
            await getStreets(town.id);
          }
        }
      }
    }
  };

  useEffect(() => {
    getActualFullAddress();
    return () => {
      setProvinces(null);
      setTowns(null);
      setStreets(null);
    };
  }, []);

  const reloadAddress = (ladress: AddressDTO) => {
    setAddress({ ...address, community: ladress.community });
  };

  const view = (
    <React.Fragment>
      {provinces && towns && vm.parent.communities ? (
        <>
          <Grid item xs={12} md={12} style={{ marginTop: 10 }} className={classes.profile_displayGrid}>
            <Grid item xs={12} md={12} className={classes.profile_generalDataGrid}>
              <Autocomplete
                disablePortal
                id="registerFormCcaa"
                options={vm.parent.communities || []}
                fullWidth
                value={{ name: address?.community }}
                clearIcon={<></>}
                getOptionLabel={(option: any) => option.name}
                renderInput={(params: any) => (
                  <TextField {...params} className={reportClasses.report_textField} required size="small" label={vm.translate("report.block0.ccaa")} />
                )}
                onChange={(event: any, value: any) => {
                  if (address && value) {
                    setAddress({ ...address, community: value.name });
                  }
                }}
              />
              <div style={{ marginRight: 5 }} />
              <Autocomplete
                disablePortal
                id="registerFormPronvinces"
                options={provinces || []}
                fullWidth
                value={{ name: address?.province }}
                disabled={address?.community?.length !== 0 ? false : true}
                clearIcon={<></>}
                getOptionLabel={(option: any) => option.name}
                renderInput={(params: any) => (
                  <TextField {...params} className={reportClasses.report_textField} required size="small" label={vm.translate("report.block0.province")} />
                )}
                onChange={(event: any, value: any) => {
                  if (address && value) {
                    setAddress({ ...address, province: value.name });
                  }
                }}
              />
              <div style={{ marginRight: 5 }} />
            </Grid>
            <Grid item xs={12} md={12} container className={classes.profile_generalDataGrid} style={{ marginTop: 10 }}>
              {address?.town && hideAutocomplete ? (
                <TextFieldGrid
                  id={"towncode0"}
                  title={vm.translate("launch.town")}
                  gridTextField={{ xs: 6, md: 6 }}
                  onChange={(evt: any) => {
                    setAddress({ ...address, townName: evt.target.value });
                  }}
                  value={address?.townName}
                />
              ) : (
                <Grid xs={6}>
                  <Autocomplete
                    id="registerFormTown"
                    options={towns || []}
                    fullWidth
                    value={{ name: address?.town }}
                    disabled={address?.province?.length !== 0 ? false : true}
                    clearIcon={<></>}
                    getOptionLabel={(option: any) => option.name}
                    renderInput={(params: any) => (
                      <TextField {...params} className={reportClasses.report_textField} required size="small" label={vm.translate("launch.town")} />
                    )}
                    onChange={(event: any, value: any) => {
                      if (address && value) {
                        setAddress({ ...address, town: value.name, townName: value.name });
                      }
                    }}
                  />
                </Grid>
              )}
              {hideAutocomplete ? (
                <TextFieldGrid
                  id={"streetcode0"}
                  gridTextField={{ xs: 6, md: 6 }}
                  title={vm.translate("report.block1.address")}
                  onChange={(evt: any) => {
                    setAddress({ ...address, streetName: evt.target.value });
                  }}
                  value={address?.streetName}
                />
              ) : (
                <Grid xs={6}>
                  <Autocomplete
                    disablePortal
                    id="registerFormStreets"
                    options={streets || []}
                    value={{ name: `${address?.streetType}, ${address?.streetName}` }}
                    getOptionLabel={(option: any) => option.name}
                    fullWidth
                    filterOptions={filterOptions}
                    disabled={address?.town?.length !== 0 ? false : true}
                    clearIcon={<></>}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        className={reportClasses.report_textField}
                        required
                        size="small"
                        label={vm.translate("report.block1.address")}
                      />
                    )}
                    onChange={(event: any, value: any) => {
                      if (value) {
                        setAddress({
                          ...address,
                          streetName: value.name,
                          streetCode: value.name,
                          streetType: value.type,
                        });
                      }
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} className={classes.profile_displayGrid}>
            <Grid item xs={12} md={12} className={classes.profile_generalDataGrid}>
              <TextFieldGrid
                id="profilePortalNumber"
                title={vm.translate("launch.portal.number")}
                required
                value={address?.portalNumber}
                onChange={(value: any) => {
                  setAddress({ ...address, portalNumber: value.target.value });
                }}
                gridTextField={{ xs: 6, md: 6 }}
              />
              <TextFieldGrid
                id="profileFloor"
                title={vm.translate("launch.floor")}
                required
                value={address?.floor}
                onChange={(value: any) => {
                  setAddress({ ...address, floor: value.target.value });
                }}
                gridTextField={{ xs: 6, md: 6 }}
              />
              <TextFieldGrid
                id="profileBlock"
                title={vm.translate("launch.block")}
                value={address?.block}
                onChange={(value: any) => {
                  setAddress({ ...address, block: value.target.value });
                }}
                gridTextField={{ xs: 6, md: 6 }}
              />
              <TextFieldGrid
                id="profileStair"
                title={vm.translate("launch.stair")}
                value={address?.stair}
                onChange={(value: any) => {
                  setAddress({ ...address, stair: value.target.value });
                }}
                gridTextField={{ xs: 6, md: 6 }}
              />
              <TextFieldGrid
                id="profileDoor"
                title={vm.translate("launch.door")}
                value={address?.door}
                onChange={(value: any) => {
                  setAddress({ ...address, door: value.target.value });
                }}
                gridTextField={{ xs: 6, md: 6 }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} className={classes.profile_displayGrid}>
            <TextFieldGrid
              id="profilePostalCode"
              title={vm.translate("launch.postal.code")}
              required
              value={address?.postalCode !== 0 ? address?.postalCode?.toString() : ""}
              onChange={(value: any) => {
                setAddress({ ...address, postalCode: value.target.value });
              }}
              gridTextField={{ xs: 6, md: 6 }}
            />
          </Grid>
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
          <CircularProgress />
        </Box>
      )}
    </React.Fragment>
  );

  const locationInfoView = (
    <React.Fragment>
      <Grid item xs={12} md={12} className={reportClasses.report_generalDataGrid}>
        <Grid item xs={4} md={4}>
          {updateView ? (
            <Autocomplete
              id="registerFormCcaa"
              options={vm.parent.communities || []}
              fullWidth
              value={{ name: address?.community }}
              clearIcon={<></>}
              getOptionLabel={(option: any) => option.name}
              renderInput={(params) => (
                <TextField {...params} className={reportClasses.report_autocomplete} required size="small" label={vm.translate("report.block0.ccaa")} />
              )}
              onChange={(event: any, value: any) => {
                if (value) setAddress({ ...address, community: value.name });
              }}
            />
          ) : (
            <></>
          )}
        </Grid>

        <div style={{ marginRight: 5 }} />
        <Grid item xs={4} md={4}>
          <Autocomplete
            disablePortal
            id="registerFormPronvinces"
            options={provinces || []}
            value={{ name: address?.province }}
            fullWidth
            disabled={address?.community?.length !== 0 ? false : true}
            clearIcon={<></>}
            getOptionLabel={(option: any) => option.name}
            renderInput={(params) => (
              <TextField {...params} className={reportClasses.report_autocomplete} required size="small" label={vm.translate("report.block0.province")} />
            )}
            onChange={(event: any, value: any) => {
              setAddress({ ...address, province: value.name });
            }}
          />
        </Grid>

        <div style={{ marginRight: 5 }} />
        {
          <Grid item xs={4} md={4}>
            {hideAutocomplete ? (
              <TextFieldGrid
                id={"towncode0"}
                title={vm.translate("launch.town")}
                gridTextField={{ xs: 12, md: 12 }}
                gridClassName={reportClasses.report_textField}
                onChange={(evt: any) => {
                  setAddress({ ...address, townName: evt.target.value });
                }}
                value={address?.townName}
              />
            ) : (
              <Autocomplete
                disablePortal
                id="registerFormTown"
                options={towns || []}
                fullWidth
                disabled={address?.province?.length !== 0 ? false : true}
                clearIcon={<></>}
                getOptionLabel={(option: any) => option.name}
                renderInput={(params) => (
                  <TextField {...params} className={reportClasses.report_autocomplete} required size="small" label={vm.translate("launch.town")} />
                )}
                onChange={(event: any, value: any) => {
                  setAddress({ ...address, town: value.name, townName: value.name });
                }}
              />
            )}
          </Grid>
        }
      </Grid>
      <Grid item xs={12} md={12} style={{ marginTop: "10px" }} className={reportClasses.report_generalDataGrid}>
        <Grid item xs={12} md={12} display="inline-flex" alignItems="center">
          <Grid item xs={6} md={6}>
            {hideAutocomplete ? (
              <TextFieldGrid
                id={"streetcode0"}
                gridTextField={{ xs: 12, md: 12 }}
                title={vm.translate("report.block1.address")}
                onChange={(evt: any) => {
                  setAddress({ ...address, streetName: evt.target.value });
                }}
                value={address?.streetName}
              />
            ) : (
              <Grid item xs={12} md={12}>
                <Autocomplete
                  disablePortal
                  id="registerFormStreets"
                  options={streets || []}
                  fullWidth
                  filterOptions={filterOptions}
                  disabled={address?.town?.length !== 0 ? false : true}
                  clearIcon={<></>}
                  getOptionLabel={(option: any) => {
                    return option.type + ", " + option.name;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={reportClasses.report_autocomplete}
                      required
                      size="small"
                      label={vm.translate("report.block1.address")}
                    />
                  )}
                  onChange={(event: any, value: any) => {
                    setAddress({
                      ...address,
                      streetName: value.name,
                      streetCode: value.name,
                      streetType: value.type,
                    });
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={3} md={3} style={{ marginLeft: ".5rem", marginTop: "-10px" }}>
            <TextFieldGrid
              id="registerFormPortalNumber"
              title={vm.translate("launch.portal.number")}
              required
              value={address?.portalNumber}
              textFieldClassName={reportClasses.locationinfo_tf_inline}
              onChange={(value: any) => {
                setAddress({ ...address, portalNumber: value.target.value });
              }}
              gridTextField={{ xs: 12, md: 12 }}
            />
          </Grid>
          <Grid item xs={3} md={3} style={{ marginTop: "-10px" }}>
            <TextFieldGrid
              id="registerFormPostalCode"
              title={vm.translate("launch.postal.code")}
              required
              textFieldClassName={reportClasses.locationinfo_tf_inline}
              value={address?.postalCode !== 0 ? address?.postalCode?.toString() : ""}
              onChange={(value: any) => {
                setAddress({ ...address, postalCode: value.target.value });
              }}
              gridTextField={{ xs: 12, md: 12 }}
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  const registerAddressView = (
    <React.Fragment>
      <Grid item xs={12} md={12} className={reportClasses.report_generalDataGrid}>
        <Grid item xs={4} md={4}>
          <Autocomplete
            id="registerFormCcaa"
            options={vm.parent.communities || []}
            fullWidth
            clearIcon={<></>}
            getOptionLabel={(option: any) => option.name}
            renderInput={(params) => (
              <TextField {...params} className={reportClasses.report_autocomplete} required size="small" label={vm.translate("report.block0.ccaa")} />
            )}
            onChange={(event: any, value: any) => {
              setAddress({ ...address, community: value.name });
            }}
          />
        </Grid>

        <div style={{ marginRight: 5 }} />
        <Grid item xs={4} md={4}>
          <Autocomplete
            disablePortal
            id="registerFormPronvinces"
            options={provinces || []}
            fullWidth
            disabled={address?.community?.length !== 0 ? false : true}
            clearIcon={<></>}
            getOptionLabel={(option: any) => option.name}
            renderInput={(params) => (
              <TextField {...params} className={reportClasses.report_autocomplete} required size="small" label={vm.translate("report.block0.province")} />
            )}
            onChange={(event: any, value: any) => {
              setAddress({ ...address, province: value.name });
            }}
          />
        </Grid>

        <div style={{ marginRight: 5 }} />
        {
          <Grid item xs={4} md={4}>
            {hideAutocomplete ? (
              <TextFieldGrid
                id={"towncode0"}
                title={vm.translate("launch.town")}
                gridTextField={{ xs: 12, md: 12 }}
                gridClassName={reportClasses.report_textField}
                onChange={(evt: any) => {
                  setAddress({ ...address, townName: evt.target.value });
                }}
                value={address?.townName}
              />
            ) : (
              <Autocomplete
                disablePortal
                id="registerFormTown"
                options={towns || []}
                fullWidth
                disabled={address?.province?.length !== 0 ? false : true}
                clearIcon={<></>}
                getOptionLabel={(option: any) => option.name}
                renderInput={(params) => (
                  <TextField {...params} className={reportClasses.report_autocomplete} required size="small" label={vm.translate("launch.town")} />
                )}
                onChange={(event: any, value: any) => {
                  setAddress({ ...address, town: value.name, townName: value.name });
                }}
              />
            )}
          </Grid>
        }
      </Grid>
      <div style={{ marginTop: 10 }} />
      <Grid item xs={12} md={12} className={reportClasses.report_generalDataGrid}>
        <Grid item xs={12} md={12}>
          {hideAutocomplete ? (
            <TextFieldGrid
              id={"streetcode0"}
              gridTextField={{ xs: 12, md: 12 }}
              title={vm.translate("report.block1.address")}
              onChange={(evt: any) => {
                setAddress({ ...address, streetName: evt.target.value });
              }}
              value={address?.streetName}
            />
          ) : (
            <Autocomplete
              disablePortal
              id="registerFormStreets"
              options={streets || []}
              fullWidth
              filterOptions={filterOptions}
              disabled={address?.town?.length !== 0 ? false : true}
              clearIcon={<></>}
              getOptionLabel={(option: any) => {
                return option.type + ", " + option.name;
              }}
              renderInput={(params) => (
                <TextField {...params} className={reportClasses.report_autocomplete} required size="small" label={vm.translate("report.block1.address")} />
              )}
              onChange={(event: any, value: any) => {
                setAddress({
                  ...address,
                  streetName: value.name,
                  streetCode: value.code,
                  streetType: value.type,
                });
              }}
            />
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} className={reportClasses.report_generalDataGrid}>
        <TextFieldGrid
          id="registerFormFloor"
          title={vm.translate("launch.floor")}
          required
          value={address?.floor}
          onChange={(value: any) => {
            setAddress({ ...address, floor: value.target.value });
          }}
          gridTextField={{ xs: 3, md: 3 }}
        />
        <TextFieldGrid
          id="registerFormDoor"
          title={vm.translate("launch.door")}
          value={address?.door}
          onChange={(value: any) => {
            setAddress({ ...address, door: value.target.value });
          }}
          gridTextField={{ xs: 3, md: 3 }}
        />
        <TextFieldGrid
          id="registerFormBlock"
          title={vm.translate("launch.block")}
          value={address?.block}
          onChange={(value: any) => {
            setAddress({ ...address, block: value.target.value });
          }}
          gridTextField={{ xs: 3, md: 3 }}
        />
        <TextFieldGrid
          id="registerFormStair"
          title={vm.translate("launch.stair")}
          value={address?.stair}
          onChange={(value: any) => {
            setAddress({ ...address, stair: value.target.value });
          }}
          gridTextField={{ xs: 3, md: 3 }}
        />
      </Grid>
      <Grid item xs={12} md={12} className={reportClasses.report_generalDataGrid}>
        <TextFieldGrid
          id="registerFormPortalNumber"
          title={vm.translate("launch.portal.number")}
          required
          value={address?.portalNumber}
          onChange={(value: any) => {
            setAddress({ ...address, portalNumber: value.target.value });
          }}
          gridTextField={{ xs: 3, md: 3 }}
        />
        <TextFieldGrid
          id="registerFormPortalLetter"
          title={vm.translate("launch.portal.letter")}
          value={address?.portalNumberLetter}
          onChange={(value: any) => {
            setAddress({ ...address, portalNumberLetter: value.target.value });
          }}
          gridTextField={{ xs: 3, md: 3 }}
        />
        <TextFieldGrid
          id="registerFormSecondaryPortalNumber"
          title={vm.translate("launch.secondary.portal.number")}
          value={address?.secondaryPortalNumber}
          onChange={(value: any) => {
            setAddress({ ...address, secondaryPortalNumber: value.target.value });
          }}
          gridTextField={{ xs: 3, md: 3 }}
        />
        <TextFieldGrid
          id="registerFormSecondaryPortalLetter"
          title={vm.translate("launch.secondary.portal.letter")}
          value={address?.secondaryPortalNumberLetter}
          onChange={(value: any) => {
            setAddress({ ...address, secondaryPortalNumberLetter: value.target.value });
          }}
          gridTextField={{ xs: 3, md: 3 }}
        />
      </Grid>
      <Grid item xs={12} md={12} className={reportClasses.report_generalDataGrid}>
        <TextFieldGrid
          id="registerFormPostalCode"
          title={vm.translate("launch.postal.code")}
          required
          value={address?.postalCode !== 0 ? address?.postalCode?.toString() : ""}
          onChange={(value: any) => {
            setAddress({ ...address, postalCode: value.target.value });
          }}
          gridTextField={{ xs: 6, md: 6 }}
        />
      </Grid>
    </React.Fragment>
  );

  return { view, address, registerAddressView, reloadAddress, locationInfoView };
};
