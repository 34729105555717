import { createStyles, makeStyles } from "@mui/styles";
import { Colors, FontSizes } from "../../constants";

export const ReportStyle = makeStyles(() =>
  createStyles({
    report_textLabel: {
      "& .MuiInputLabel-root": {
        display: "contents",
        fontSize: 12,
      },
    },
    pd40: {
      padding: " 0px 50px 100px 50px",
      marginTop: "10px",
    },
    report_conservationState: {
      marginBottom: 10,
    },
    profile_inlineIcons: {
      "& .MuiImageListItemBar-actionIcon": {
        display: "inline-flex",
      },
    },
    report_radiogroup: {
      marginLeft: "-7px",
      //width: "800px !important",
      fontSize: FontSizes.h3,
      color: Colors.textSecondaryHeaders + " !important",
      "& .MuiFormControlLabel-root": {
        //display:"flex !important"
      },
      "& .MuiTypography-body1": {
        fontSize: FontSizes.h2,
        color: Colors.textPrimary + " !important",
      },
      "& .MuiRadio-root": {
        padding: "5px",
        marginTop: "-6px",
      },
    },
    report_dateTextField: {
      paddingLeft: 10,
      "& .MuiOutlinedInput-notchedOutline": {
        marginRight: "5px !important",
      },
      "& .MuiIconButton-root": {
        marginRight: "5px !important",
        pointerEvents: "all",
        zIndex: 500,
      },
    },
    report_readOnly: {
      "& .MuiIconButton-root": {
        pointerEvents: "all",
        zIndex: 500,
      },
    },
    report_rightDateTextField: {
      paddingLeft: 10,
      "& .MuiOutlinedInput-notchedOutline": {
        marginRight: "5px !important",
      },
      "& .MuiIconButton-root": {
        marginRight: "5px !important",
        pointerEvents: "all",
        zIndex: 500,
      },
    },
    report_autocomplete: {
      "& .MuiOutlinedInput-root": {
        padding: "8px 7px 7px !important",
      },
    },
    report_dropZone: {
      color: Colors.textGray,
      "& .MuiDropzoneArea-icon": { color: Colors.textGray },
    },
    report_numericInput: {
      width: "100%",
      marginRight: 5,
    },

    report_numericInputGeneralData: {
      width: "100%",
      marginRight: 5,
      marginTop: 10,
      "& .MuiTextField-root": {
        width: "100% !important",
      },
      // padding: "18.5px 0px 14px",
      "& .MuiFormControl-root": {
        width: "100% !important",
      },
      "& .MuiInputBase-input": {
        padding: "10px 14px 10px !important",
        fontSize: FontSizes.p + " !important",
      },
    },
    report_numericInputGeneralData_2: {
      width: "100%",
      marginRight: 2.5,
      marginTop: 2.5,
      "& .MuiTextField-root": {
        width: "100% !important",
      },
      // padding: "18.5px 0px 14px",
      "& .MuiFormControl-root": {
        width: "100% !important",
      },
      "& .MuiInputBase-input": {
        padding: "10px 14px 10px !important",
        fontSize: FontSizes.p + " !important",
      },
    },
    locationinfo_tf_inline: {
      width: "100%",
      "& .MuiTextField-root": {
        width: "100% !important",
      },
      // padding: "18.5px 0px 14px",
      "& .MuiFormControl-root": {
        width: "100% !important",
      },
      "& .MuiInputBase-input": {
        height: "25px !important",
        margin: "0px !important",
        fontSize: FontSizes.p + " !important",
      },
    },
    report_dropZoneWidth: {
      width: "100%",
    },
    report_dropZoneWidthBlock0: {
      width: "99%",
      marginLeft: 5,
    },
    report_addDocument: {
      width: "300px !important",
      marginTop: "1rem !important",
    },
    report_JustifyaddButton: {
      display: "flex",
      justifyContent: "center",
    },
    report_addTechicianmini: {
      marginRight: "1rem !important",
    },
    report_tab: {
      fontWeight: "bold",
      color: Colors.primary,
      borderColor: Colors.primary,
      "& .Mui-selected": {
        color: Colors.primary,
      },
      "& .MuiTabs-indicator": {
        backgroundColor: Colors.primary,
      },
    },
    report_block1Tab: {
      fontWeight: "bold",
      color: Colors.primary,
      borderColor: Colors.primary,
      "& .Mui-selected": {
        color: Colors.primary,
      },
      "& .MuiTabs-indicator": {
        backgroundColor: Colors.primary,
      },
      "& .MuiTabPanel-root": {
        paddingTop: "10px !important",
      },
    },
    report_generalDataGrid: {
      display: "flex",
      justifyContent: "flex-start",
    },
    report_grid: {
      justifyContent: "flex-start",
      alignItems: "center",
      display: "flex",
    },
    report_fab: {
      position: "fixed",
      bottom: "20px",
      right: "50px",
      borderRadius: "50%",
    },
    report_textWrap: {
      textDecoration: "word-wrap",
    },
    scroll_fab: {
      position: "fixed",
      bottom: "90px",
      right: "50px",
      borderRadius: "50%",
    },
    report_gridInput: {
      justifyContent: "flex-end",
      alignItems: "baseline",
      display: "flex",
    },
    report_typography: {
      marginLeft: "20px !important",
    },
    report_textField: {
      // padding: "18.5px 0px 14px",
      "& .MuiTextField-root": {
        width: "100% !important",
      },
      // padding: "18.5px 0px 14px",
      "& .MuiFormControl-root": {
        width: "100% !important",
      },
      "& .MuiInputBase-input": {
        padding: "10px 14px 10px !important",
        fontSize: FontSizes.p + " !important",
      },
    },
    report_textField_ccaa: {
      // padding: "18.5px 0px 14px",
      "& .MuiTextField-root": {
        width: "100% !important",
      },
      // padding: "18.5px 0px 14px",
      "& .MuiFormControl-root": {
        width: "100% !important",
      },
      "& .MuiInputBase-input": {
        padding: "10px 14px 10px !important",
        fontSize: FontSizes.p + " !important",
        height: "7px !important",
      },
    },
    report_textFieldArea: {
      // padding: "18.5px 0px 14px",
      "& .MuiInputBase-input": {
        fontSize: FontSizes.p + " !important",
        padding: "0px !important",
      },
    },
    report_checkbox: {
      "& .MuiTypography-root": {
        color: Colors.textPrimary,
        fontSize: FontSizes.p + " !important",
      },
    },

    report_generalCheckbox: {
      borderRightColor: "red",
    },
    report_gridCheckbox: {
      justifyContent: "flex-start",
      alignItems: "center",
      display: "flex",
    },
    report_groupCheckbox: {
      marginLeft: 40,
      display: "flex",
      flexDirection: "column",
    },
    report_titleTypography: {
      marginLeft: 10,
    },
    report_textFieldGrid: {
      marginTop: "10px !important",
    },
    report_displayGrid: {
      display: "grid",
    },
    report_saveButton: {
      display: "flex",
      justifyContent: "flex-end",
      padding: 10,
    },
    report_textFieldGridAlt: {
      paddingRight: 10,
    },
    report_displayGridTitle: {
      marginLeft: 10,
      marginTop: 10,
      display: "grid",
    },
    report_hr: {
      width: "100%",
      border: "1px solid " + Colors.textPrimaryHeaders,
    },
    report_map: {
      zIndex: 0,
      position: "relative",
      width: "100%",
      height: "400px",
      outline: "none",
    },
    report_radioGroup: {
      marginLeft: 10,
      marginTop: 10,
    },
    report_addUsers: {
      display: "flex",
      alignItems: "center",
      marginLeft: "1rem",
    },
    report_addUserGrid: {
      marginLeft: 10,
      marginTop: 10,
      display: "flex",
      flexDirection: "column",
    },
    report_generalEnergyGrid: {
      display: "flex",
      justifyContent: "flex-start",
      marginTop: 10,
    },
    report_addButton: {
      fontSize: ".8rem",
      width: "100px",
      height: "100px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    report_addButtonMini: {
      "& .MuiSvgIcon-root": {
        width: "1em",
        height: "1em",
      },
      width: "0 !important",
      fontSize: "2px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    report_addButtonNoMini: {
      width: "40rem",
      height: "2.5rem",
      fontSize: "12px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    report_treview: {
      "& .MuiTreeItem-iconContainer": {
        width: "60px",
        padding: "5px",
        paddingLeft: 0,
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      },
      "& .MuiTreeItem-label": {
        fontSize: "1.0rem",
        color: Colors.textPrimary,
      },
      "& .MuiCollapse-wrapperInner": {
        padding: 5,
      },
    },
    report_treItem: {
      "& .MuiTreeItem-iconContainer": {
        padding: 0,
        width: 60,
      },
      "& .MuiTreeItem-label": {
        fontSize: "1rem",
        color: Colors.primary,
      },
      "& .MuiCollapse-wrapperInner": {
        display: "flex",
        padding: 0,
      },
    },
    report_normalDescriptionTextField: {
      "& .MuiInputBase-input": {
        // padding: "4px 14px 4px !important"
        //fontSize: 13
      },
      "& label": {
        //position: "absolute",
        //top: -11
      },
    },
    report_textFieldComponent: {
      marginLeft: 30,
    },
    report_checkboxDataGrid: {
      display: "grid",
      marginTop: 5,
      marginLeft: -33,
    },
    improvement_textField: {
      width: "100% !important",
      heigth: "27px",
    },
  })
);
