export function arraysEqual(_arr1: any[], _arr2: any[], sortingAlgorithm: (a: any, b: any) => 0 | 1 | -1) {
	if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length) return false;

	var arr1 = [ ..._arr1 ];
	arr1.sort(sortingAlgorithm);
	var arr2 = [ ..._arr2 ];
	arr2.sort(sortingAlgorithm);

	for (var i = 0; i < arr1.length; i++) {
		if (arr1[i] !== arr2[i]) return false;
	}
	return true;
}

export function reducedFilter<T>(data: T[], keys: Array<keyof T>, fn: any) {
	return data.filter(fn).map((el: T) =>
		keys.reduce((newItem: any, key: keyof T) => {
			newItem[key] = el[key];
			return newItem;
		}, {})
	);
}

export const filterOptions = (options: any[], state: { inputValue: string }) => {
    const searchWords = state.inputValue.split(' ')
  
    options = options.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.place === value.place && t.type === value.type && t.name === value.name
      ))
    )

    return options.filter((option: any) => {
      return searchWords.every((word: string) => {
        return option.type.replace(",", "").toLowerCase().includes(word.toLowerCase()) + option.name.replace(",", "").toLowerCase().includes(word.toLowerCase())
      })
    }
    )
  }

export const UUIDGenerator = () =>
	// @ts-ignore
	([ 1e7 ] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
		(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
	);
