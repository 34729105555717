/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressDTO,
    AddressDTOFromJSON,
    AddressDTOFromJSONTyped,
    AddressDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface AgentDTO
 */
export interface AgentDTO {
    /**
     * 
     * @type {boolean}
     * @memberof AgentDTO
     */
    active?: boolean;
    /**
     * 
     * @type {AddressDTO}
     * @memberof AgentDTO
     */
    address?: AddressDTO;
    /**
     * 
     * @type {string}
     * @memberof AgentDTO
     */
    credentials?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgentDTO
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgentDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentDTO
     */
    idNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentDTO
     */
    mobilePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentDTO
     */
    phone?: string;
    /**
     * 
     * @type {AgentDTO}
     * @memberof AgentDTO
     */
    representer?: AgentDTO;
    /**
     * 
     * @type {AgentDTO}
     * @memberof AgentDTO
     */
    representing?: AgentDTO;
    /**
     * 
     * @type {string}
     * @memberof AgentDTO
     */
    role?: AgentDTORoleEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentDTO
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentDTO
     */
    tenant?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentDTO
     */
    type?: AgentDTOTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum AgentDTORoleEnum {
    CollegeAdministrator = 'COLLEGE_ADMINISTRATOR',
    HomeownersPresident = 'HOMEOWNERS_PRESIDENT',
    Owner = 'OWNER',
    PropertyManager = 'PROPERTY_MANAGER',
    Representative = 'REPRESENTATIVE',
    SystemAdministrator = 'SYSTEM_ADMINISTRATOR',
    Technician = 'TECHNICIAN'
}/**
* @export
* @enum {string}
*/
export enum AgentDTOTypeEnum {
    Legal = 'LEGAL',
    Physical = 'PHYSICAL'
}

export function AgentDTOFromJSON(json: any): AgentDTO {
    return AgentDTOFromJSONTyped(json, false);
}

export function AgentDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgentDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'address': !exists(json, 'address') ? undefined : AddressDTOFromJSON(json['address']),
        'credentials': !exists(json, 'credentials') ? undefined : json['credentials'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'idNumber': !exists(json, 'idNumber') ? undefined : json['idNumber'],
        'mobilePhone': !exists(json, 'mobilePhone') ? undefined : json['mobilePhone'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'representer': !exists(json, 'representer') ? undefined : AgentDTOFromJSON(json['representer']),
        'representing': !exists(json, 'representing') ? undefined : AgentDTOFromJSON(json['representing']),
        'role': !exists(json, 'role') ? undefined : json['role'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'tenant': !exists(json, 'tenant') ? undefined : json['tenant'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function AgentDTOToJSON(value?: AgentDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'address': AddressDTOToJSON(value.address),
        'credentials': value.credentials,
        'deleted': value.deleted,
        'email': value.email,
        'id': value.id,
        'idNumber': value.idNumber,
        'mobilePhone': value.mobilePhone,
        'name': value.name,
        'phone': value.phone,
        'representer': AgentDTOToJSON(value.representer),
        'representing': AgentDTOToJSON(value.representing),
        'role': value.role,
        'surname': value.surname,
        'tenant': value.tenant,
        'type': value.type,
    };
}

