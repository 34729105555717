import { Button, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import {
  BuildingCompositionDTO,
  BuildingLocationInfoDTO,
  BuildingLocationInfoDTOBuildingOccupancyTypeEnum,
  BuildingPolygonDTOCoordinatesSystemEnum,
  ParcelDTO,
  PolygonDTOCoordinatesSystemEnum,
  PropertyDTO,
  UsageDTO,
} from "../../../../../../clients";
import RadioBoxGroup from "../../../../../../components/radio-group/radio-group";
import { Colors } from "../../../../../../constants";
import { UUIDGenerator } from "../../../../../../utils/arrayHelper";
import ReportViewModel from "../../../../../../viewmodels/report/report-view-model";
import { ReportStyle } from "../../../../report-style";
import { useSnackbar } from "notistack";
import { groupBy } from "../../../../../../utils/book-helper";

export enum communities_enum {
  PAIS_VASCO = "16",
  NAVARRA = "15",
}

export enum provinces_enum {
  GIPUZKOA = "20",
  BIZKAIA = "48",
  NAVARRA = "31",
}

export const RadioGroupSectin: React.FC<{ vm: ReportViewModel }> = observer(({ vm }) => {
  const classes = ReportStyle();

  const { enqueueSnackbar } = useSnackbar();
  const handleOpenSnackBar = (snackKey: string, variants: undefined | "default" | "error" | "success" | "warning" | "info") => {
    enqueueSnackbar(snackKey, { variant: variants, preventDuplicate: true, key: snackKey, autoHideDuration: 6000 });
  };

  const separateCadastralReference = (cadastralReference: string) => {
    switch (vm.manualAddress.community) {
      case communities_enum.PAIS_VASCO:
        switch (vm.manualAddress.province) {
          case provinces_enum.GIPUZKOA:
            return splitReference(cadastralReference, 4, 3, 10, 0, 0);
          case provinces_enum.BIZKAIA:
            return splitReference(cadastralReference, 6, 6, 4, 0, 0);
          default:
            return splitReference(cadastralReference, 7, 7, 4, 1, 1);
        }
      case communities_enum.NAVARRA:
        switch (vm.manualAddress.province) {
          case provinces_enum.NAVARRA:
            if (cadastralReference.length === 20) return splitReference(cadastralReference, 7, 7, 4, 1, 1);
            else return splitReference(cadastralReference, 4, 3, 3, 0, 0);
          default:
            return splitReference(cadastralReference, 7, 7, 4, 1, 1);
        }
      default:
        return splitReference(cadastralReference, 7, 7, 4, 1, 1);
    }
  };

  const splitReference = (reference: string, pc1: number, pc2: number, car: number, cc1: number, cc2: number) => {
    return {
      pc1: reference.substring(0, pc1),
      pc2: reference.substring(pc1, pc1 + pc2) ?? "",
      car: reference.substring(pc1 + pc2, pc1 + pc2 + car),
      cc1: reference.substring(pc1 + pc2 + car, pc1 + pc2 + car + cc1) ?? "",
      cc2: reference.substring(pc1 + pc2 + car + cc1, pc1 + pc2 + car + cc1 + cc2) ?? "",
    };
  };

  const onBuildingTypeChange = (value: string) => {
    if (value === BuildingLocationInfoDTOBuildingOccupancyTypeEnum.SingleFamily) {
      vm.userBook.buildingLocationInfo && vm.setBuildingOccupancyType(BuildingLocationInfoDTOBuildingOccupancyTypeEnum.SingleFamily);
      vm.locationInfo.buildingOccupancyType = BuildingLocationInfoDTOBuildingOccupancyTypeEnum.SingleFamily;
    } else if (value === BuildingLocationInfoDTOBuildingOccupancyTypeEnum.MultyFamily) {
      vm.userBook.buildingLocationInfo && vm.setBuildingOccupancyType(BuildingLocationInfoDTOBuildingOccupancyTypeEnum.MultyFamily);
      vm.locationInfo.buildingOccupancyType = BuildingLocationInfoDTOBuildingOccupancyTypeEnum.MultyFamily;
    } else {
      vm.userBook.buildingLocationInfo && vm.setBuildingOccupancyType(BuildingLocationInfoDTOBuildingOccupancyTypeEnum.Others);
      vm.locationInfo.buildingOccupancyType = BuildingLocationInfoDTOBuildingOccupancyTypeEnum.Others;
    }
  };

  const onAdjoiningBuildingsChange = (value: string) => {
    if (value === vm.translate("yes")) {
      vm.userBook.buildingLocationInfo && vm.setSharesCommonElementsWithContiguousBuildings(vm.translate("yes"));
      vm.locationInfo.sharesCommonElementsWithContiguousBuildings = vm.translate("yes");
    } else {
      vm.userBook.buildingLocationInfo && vm.setSharesCommonElementsWithContiguousBuildings(vm.translate("no"));
      vm.locationInfo.sharesCommonElementsWithContiguousBuildings = vm.translate("no");
    }
  };

  const validate = () => {
    if (vm.manualCadastralReference.length < 2) {
      handleOpenSnackBar(vm.translate("La referencia catastral de la parcela es obligatoria"), "error");
      const element = document.getElementById("cadastralReferenceParcel");
      element?.scrollIntoView({ behavior: "smooth", block: "center" });
      element?.focus();
      return true;
    }
    if (
      (vm.locationInfo as BuildingLocationInfoDTO)?.address?.portalNumber?.length === 0 ||
      (vm.locationInfo as BuildingLocationInfoDTO)?.address?.province?.length === 0 ||
      (vm.locationInfo as BuildingLocationInfoDTO)?.address?.community?.length === 0 ||
      (vm.locationInfo as BuildingLocationInfoDTO)?.address?.postalCode?.toString().length === 0 ||
      (vm.locationInfo as BuildingLocationInfoDTO)?.address?.street?.length === 0 ||
      (vm.locationInfo as BuildingLocationInfoDTO)?.address?.streetName?.length === 0 ||
      (vm.locationInfo as BuildingLocationInfoDTO)?.address?.streetType?.length === 0 ||
      (vm.locationInfo as BuildingLocationInfoDTO)?.address?.town?.length === 0
    ) {
      const element = document.getElementById("locationTitle");
      element?.scrollIntoView({ behavior: "smooth", block: "center" });
      handleOpenSnackBar(vm.translate("La localización de la parcela es obligatoria"), "error");
      return true;
    }
    if (vm.buildingComposition?.usages?.length === 0) {
      const element = document.getElementById("addgeneralDataUsage");
      element?.scrollIntoView({ behavior: "smooth", block: "center" });
      handleOpenSnackBar(vm.translate("Los Usos y superficies pormenorizados son obligatorios"), "error");
      return true;
    }
    if (vm.buildingComposition?.media?.length === 0) {
      const element = document.getElementById("juploadFrontPage");
      element?.scrollIntoView({ behavior: "smooth", block: "center" });
      handleOpenSnackBar(vm.translate("Añadir una portada es obligatorio"), "error");
      return true;
    }
    return false;
  };

  const createBook = () => {
    if (validate()) return;
    const groupedPropertiesWithUsages = groupBy(vm.buildingComposition.usages, "cadastralReference");

    const properties = Object.keys(groupedPropertiesWithUsages).map((prop): PropertyDTO => {
      const { pc1, pc2, car, cc1, cc2 } = separateCadastralReference(prop);
      return {
        pc1,
        cadastralTown: "",
        pc2,
        car,
        cc1,
        cc2,
        usages: groupedPropertiesWithUsages[prop],
        antiquity: (vm.buildingComposition as BuildingCompositionDTO)?.builtOnYear,
        builtSurface: groupedPropertiesWithUsages[prop]
          .map((u: UsageDTO) => parseFloat(u.surface?.toString()?.replace(",", ".") ?? "0"))
          .reduce((total: number, num: number) => total + num, 0),
        block: groupedPropertiesWithUsages[prop].find((usage: UsageDTO) => usage.block !== "n/a")?.block ?? "n/a",
        door: groupedPropertiesWithUsages[prop].find((usage: UsageDTO) => usage.door !== "n/a")?.door ?? "n/a",
        floor: groupedPropertiesWithUsages[prop].find((usage: UsageDTO) => usage.floor !== "n/a")?.floor ?? "n/a",
        community: (vm.locationInfo as BuildingLocationInfoDTO).address?.community,
        mainUsage: "",
        complete: true,
        participationCoefficient: 0.0,
        portalNumber: (vm.locationInfo as BuildingLocationInfoDTO).address?.portalNumber,
        province: (vm.locationInfo as BuildingLocationInfoDTO).address?.province,
        postalCode: (vm.locationInfo as BuildingLocationInfoDTO).address?.postalCode,
        streetCode: (vm.locationInfo as BuildingLocationInfoDTO).address?.streetName,
        streetName: (vm.locationInfo as BuildingLocationInfoDTO).address?.streetName,
        streetType: (vm.locationInfo as BuildingLocationInfoDTO).address?.streetType,
        town: (vm.locationInfo as BuildingLocationInfoDTO).address?.town,
      };
    });

    const { pc1, pc2 } = separateCadastralReference(vm.manualCadastralReference ?? "");
    if (vm.locationInfo?.address) vm.locationInfo.address.cadastralParcelReference = pc1 + pc2;

    const parcels: ParcelDTO[] = [
      {
        street: "",
        id: UUIDGenerator(),
        area: vm.buildingComposition.builtArea,
        community: vm.manualBuildingDTO.community,
        latitude: vm.locationInfo.address?.latitude,
        longitude: vm.locationInfo.address?.longitude,
        dwellings: vm.manualBuildingDTO.dwellings?.length ?? 0,
        town: vm.locationInfo.address?.town,
        province: vm.locationInfo.address?.province,
        portalNumber: vm.locationInfo.address?.portalNumber,
        streetType: vm.locationInfo.address?.streetType,
        cadastralTown: vm.locationInfo.address?.town,
        postalCode: vm.locationInfo.address?.postalCode,
        year: vm.buildingComposition.builtOnYear,
        floorsAboveGround: vm.buildingComposition.floorsOverGround,
        floorsUnderGround: vm.buildingComposition.floorsUnderGround,
        properties: [],
        buildingPolygons: [
          {
            content: [
              {
                lat: vm.locationInfo.address?.latitude ?? 0,
                lng: vm.locationInfo.address?.longitude ?? 0,
              },
            ],
            coordinatesSystem: BuildingPolygonDTOCoordinatesSystemEnum.Epsg4258,
          },
        ],
        media: [
          {
            id: "117b89fc-0bdc-43f4-bc24-0116c1e96514",
            name: "0801901XM7100B",
          },
        ],
        polygons: [
          {
            content: [
              {
                lat: vm.locationInfo.address?.latitude ?? 0,
                lng: vm.locationInfo.address?.longitude ?? 0,
              },
            ],
            coordinatesSystem: PolygonDTOCoordinatesSystemEnum.Epsg4258,
          },
        ],
        pc1,
        pc2,
        portalNumberLetter: vm.locationInfo.address?.portalNumberLetter ?? "-",
        secondaryPortalLetter: "",
        secondaryPortalNumber: "",
        streetName: vm.locationInfo.address?.streetName,
      },
    ];

    vm.manualBuildingDTO = {
      community: vm.locationInfo.address?.community,
      dwellings: [vm.buildingComposition.dwellings ?? 0],
      floorsAboveGround: [vm.buildingComposition.floorsOverGround ?? 0],
      floorsUnderGround: [vm.buildingComposition.floorsUnderGround ?? 0],
      name: vm.locationInfo.address?.streetName,
      properties,
      parcels,
      portalNumber: vm.locationInfo.address?.portalNumber,
      portalNumberLetter: vm.locationInfo.address?.portalNumberLetter,
      postalCode: vm.locationInfo.address?.postalCode,
      province: vm.locationInfo.address?.province,
      street: vm.locationInfo.address?.street,
      secondaryPortalLetter: "",
      secondaryPortalNumber: "",
      transactionId: undefined,
      town: vm.locationInfo.address?.town,
      type: vm.locationInfo.address?.streetType,
      year: vm.buildingComposition.builtOnYear,
      techniciansParticipation: [],
    };

    vm.getBuildingManual();
  }

  return (
    <React.Fragment>
      <Grid item xs={12} md={12} className={classes.report_radioGroup}>
        <RadioBoxGroup
          id="radioGroupUserBuilding"
          title={vm.translate("report.block0.user.building")}
          selection={vm.userBook?.buildingLocationInfo?.buildingOccupancyType || BuildingLocationInfoDTOBuildingOccupancyTypeEnum.SingleFamily}
          filaArray={[
            { label: vm.translate("report.block0.single.building"), value: BuildingLocationInfoDTOBuildingOccupancyTypeEnum.SingleFamily },
            { label: vm.translate("report.block0.portal"), value: BuildingLocationInfoDTOBuildingOccupancyTypeEnum.MultyFamily },
            { label: vm.translate("report.block0.others"), value: BuildingLocationInfoDTOBuildingOccupancyTypeEnum.Others },
          ]}
          onSelectionChange={onBuildingTypeChange}
        />
      </Grid>

      <Grid item xs={12} md={12} className={classes.report_radioGroup}>
        <RadioBoxGroup
          id="radioGroupAdjoiningBuildings"
          title={vm.translate("report.block0.adjoining.buildings")}
          selection={vm.userBook?.buildingLocationInfo?.sharesCommonElementsWithContiguousBuildings || vm.translate("no")}
          filaArray={[
            { label: vm.translate("no"), value: vm.translate("no") },
            { label: vm.translate("yes"), value: vm.translate("yes") },
          ]}
          onSelectionChange={onAdjoiningBuildingsChange}
        />
      </Grid>
      {vm.parent.isManual ? (
        <Grid>
          <Button
            onClick={createBook}
            style={{ background: Colors.primary, color: Colors.white }}
          >
            Crear libro
          </Button>
        </Grid>
      ) : null
      }
    </React.Fragment >
  );
});
