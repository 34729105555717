/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProfileDTO
 */
export interface ProfileDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDTO
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    credentials?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfileDTO
     */
    role?: ProfileDTORoleEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ProfileDTO
     */
    validated?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum ProfileDTORoleEnum {
    CollegeAdministrator = 'COLLEGE_ADMINISTRATOR',
    HomeownersPresident = 'HOMEOWNERS_PRESIDENT',
    Owner = 'OWNER',
    PropertyManager = 'PROPERTY_MANAGER',
    Representative = 'REPRESENTATIVE',
    SystemAdministrator = 'SYSTEM_ADMINISTRATOR',
    Technician = 'TECHNICIAN'
}

export function ProfileDTOFromJSON(json: any): ProfileDTO {
    return ProfileDTOFromJSONTyped(json, false);
}

export function ProfileDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'credentials': !exists(json, 'credentials') ? undefined : json['credentials'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'validated': !exists(json, 'validated') ? undefined : json['validated'],
    };
}

export function ProfileDTOToJSON(value?: ProfileDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'credentials': value.credentials,
        'email': value.email,
        'id': value.id,
        'role': value.role,
        'validated': value.validated,
    };
}

