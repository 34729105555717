/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccEvaluationsPrintedDTO,
    AccEvaluationsPrintedDTOFromJSON,
    AccEvaluationsPrintedDTOFromJSONTyped,
    AccEvaluationsPrintedDTOToJSON,
    BuildingCompositionDTO,
    BuildingCompositionDTOFromJSON,
    BuildingCompositionDTOFromJSONTyped,
    BuildingCompositionDTOToJSON,
    BuildingConstructionDescriptionDTO,
    BuildingConstructionDescriptionDTOFromJSON,
    BuildingConstructionDescriptionDTOFromJSONTyped,
    BuildingConstructionDescriptionDTOToJSON,
    BuildingIdentificationPrintedDTO,
    BuildingIdentificationPrintedDTOFromJSON,
    BuildingIdentificationPrintedDTOFromJSONTyped,
    BuildingIdentificationPrintedDTOToJSON,
    BuildingUrbanDataPrintedDTO,
    BuildingUrbanDataPrintedDTOFromJSON,
    BuildingUrbanDataPrintedDTOFromJSONTyped,
    BuildingUrbanDataPrintedDTOToJSON,
    EnergyEfficiencyCertificatePrintedDTO,
    EnergyEfficiencyCertificatePrintedDTOFromJSON,
    EnergyEfficiencyCertificatePrintedDTOFromJSONTyped,
    EnergyEfficiencyCertificatePrintedDTOToJSON,
    ExtendedMediaPrintedDTO,
    ExtendedMediaPrintedDTOFromJSON,
    ExtendedMediaPrintedDTOFromJSONTyped,
    ExtendedMediaPrintedDTOToJSON,
    ImprovementActionPrintedDTO,
    ImprovementActionPrintedDTOFromJSON,
    ImprovementActionPrintedDTOFromJSONTyped,
    ImprovementActionPrintedDTOToJSON,
    InspectionPrintedDTO,
    InspectionPrintedDTOFromJSON,
    InspectionPrintedDTOFromJSONTyped,
    InspectionPrintedDTOToJSON,
    LicenseDTO,
    LicenseDTOFromJSON,
    LicenseDTOFromJSONTyped,
    LicenseDTOToJSON,
    MaintenancePlanActionDTO,
    MaintenancePlanActionDTOFromJSON,
    MaintenancePlanActionDTOFromJSONTyped,
    MaintenancePlanActionDTOToJSON,
    MediaPrintedDTO,
    MediaPrintedDTOFromJSON,
    MediaPrintedDTOFromJSONTyped,
    MediaPrintedDTOToJSON,
    ProceedingsDTO,
    ProceedingsDTOFromJSON,
    ProceedingsDTOFromJSONTyped,
    ProceedingsDTOToJSON,
    UsagePlanDTO,
    UsagePlanDTOFromJSON,
    UsagePlanDTOFromJSONTyped,
    UsagePlanDTOToJSON,
    UserDataPrintDTO,
    UserDataPrintDTOFromJSON,
    UserDataPrintDTOFromJSONTyped,
    UserDataPrintDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface GeneralBuildingDataPrintedDTO
 */
export interface GeneralBuildingDataPrintedDTO {
    /**
     * 
     * @type {Array<AccEvaluationsPrintedDTO>}
     * @memberof GeneralBuildingDataPrintedDTO
     */
    accesibilityEvaluations?: Array<AccEvaluationsPrintedDTO>;
    /**
     * 
     * @type {Array<ExtendedMediaPrintedDTO>}
     * @memberof GeneralBuildingDataPrintedDTO
     */
    actionRegistries?: Array<ExtendedMediaPrintedDTO>;
    /**
     * 
     * @type {Array<UserDataPrintDTO>}
     * @memberof GeneralBuildingDataPrintedDTO
     */
    agentsData?: Array<UserDataPrintDTO>;
    /**
     * 
     * @type {Array<ExtendedMediaPrintedDTO>}
     * @memberof GeneralBuildingDataPrintedDTO
     */
    bestPractices?: Array<ExtendedMediaPrintedDTO>;
    /**
     * 
     * @type {Array<EnergyEfficiencyCertificatePrintedDTO>}
     * @memberof GeneralBuildingDataPrintedDTO
     */
    energyEfficiencyCertificates?: Array<EnergyEfficiencyCertificatePrintedDTO>;
    /**
     * 
     * @type {EnergyEfficiencyCertificatePrintedDTO}
     * @memberof GeneralBuildingDataPrintedDTO
     */
    finalEnergyEfficiencyCertificate?: EnergyEfficiencyCertificatePrintedDTO;
    /**
     * 
     * @type {BuildingCompositionDTO}
     * @memberof GeneralBuildingDataPrintedDTO
     */
    generalData?: BuildingCompositionDTO;
    /**
     * 
     * @type {BuildingIdentificationPrintedDTO}
     * @memberof GeneralBuildingDataPrintedDTO
     */
    identification?: BuildingIdentificationPrintedDTO;
    /**
     * 
     * @type {Array<MediaPrintedDTO>}
     * @memberof GeneralBuildingDataPrintedDTO
     */
    ieeDocuments?: Array<MediaPrintedDTO>;
    /**
     * 
     * @type {Array<ImprovementActionPrintedDTO>}
     * @memberof GeneralBuildingDataPrintedDTO
     */
    improvements?: Array<ImprovementActionPrintedDTO>;
    /**
     * 
     * @type {EnergyEfficiencyCertificatePrintedDTO}
     * @memberof GeneralBuildingDataPrintedDTO
     */
    initialEnergyEfficiencyCertificate?: EnergyEfficiencyCertificatePrintedDTO;
    /**
     * 
     * @type {Array<InspectionPrintedDTO>}
     * @memberof GeneralBuildingDataPrintedDTO
     */
    inspections?: Array<InspectionPrintedDTO>;
    /**
     * 
     * @type {Array<MediaPrintedDTO>}
     * @memberof GeneralBuildingDataPrintedDTO
     */
    iteDocuments?: Array<MediaPrintedDTO>;
    /**
     * 
     * @type {Array<LicenseDTO>}
     * @memberof GeneralBuildingDataPrintedDTO
     */
    licenses?: Array<LicenseDTO>;
    /**
     * 
     * @type {Array<ExtendedMediaPrintedDTO>}
     * @memberof GeneralBuildingDataPrintedDTO
     */
    maintenanceContracts?: Array<ExtendedMediaPrintedDTO>;
    /**
     * 
     * @type {Array<MaintenancePlanActionDTO>}
     * @memberof GeneralBuildingDataPrintedDTO
     */
    maintenancePlanActions?: Array<MaintenancePlanActionDTO>;
    /**
     * 
     * @type {Array<MediaPrintedDTO>}
     * @memberof GeneralBuildingDataPrintedDTO
     */
    multimedia?: Array<MediaPrintedDTO>;
    /**
     * 
     * @type {BuildingConstructionDescriptionDTO}
     * @memberof GeneralBuildingDataPrintedDTO
     */
    normalizedDescription?: BuildingConstructionDescriptionDTO;
    /**
     * 
     * @type {Array<MediaPrintedDTO>}
     * @memberof GeneralBuildingDataPrintedDTO
     */
    otherDocuments?: Array<MediaPrintedDTO>;
    /**
     * 
     * @type {Array<ProceedingsDTO>}
     * @memberof GeneralBuildingDataPrintedDTO
     */
    proceedings?: Array<ProceedingsDTO>;
    /**
     * 
     * @type {Array<UserDataPrintDTO>}
     * @memberof GeneralBuildingDataPrintedDTO
     */
    techniciansData?: Array<UserDataPrintDTO>;
    /**
     * 
     * @type {BuildingUrbanDataPrintedDTO}
     * @memberof GeneralBuildingDataPrintedDTO
     */
    urbanData?: BuildingUrbanDataPrintedDTO;
    /**
     * 
     * @type {Array<UsagePlanDTO>}
     * @memberof GeneralBuildingDataPrintedDTO
     */
    usagePlans?: Array<UsagePlanDTO>;
}

export function GeneralBuildingDataPrintedDTOFromJSON(json: any): GeneralBuildingDataPrintedDTO {
    return GeneralBuildingDataPrintedDTOFromJSONTyped(json, false);
}

export function GeneralBuildingDataPrintedDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneralBuildingDataPrintedDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accesibilityEvaluations': !exists(json, 'accesibilityEvaluations') ? undefined : ((json['accesibilityEvaluations'] as Array<any>).map(AccEvaluationsPrintedDTOFromJSON)),
        'actionRegistries': !exists(json, 'actionRegistries') ? undefined : ((json['actionRegistries'] as Array<any>).map(ExtendedMediaPrintedDTOFromJSON)),
        'agentsData': !exists(json, 'agentsData') ? undefined : ((json['agentsData'] as Array<any>).map(UserDataPrintDTOFromJSON)),
        'bestPractices': !exists(json, 'bestPractices') ? undefined : ((json['bestPractices'] as Array<any>).map(ExtendedMediaPrintedDTOFromJSON)),
        'energyEfficiencyCertificates': !exists(json, 'energyEfficiencyCertificates') ? undefined : ((json['energyEfficiencyCertificates'] as Array<any>).map(EnergyEfficiencyCertificatePrintedDTOFromJSON)),
        'finalEnergyEfficiencyCertificate': !exists(json, 'finalEnergyEfficiencyCertificate') ? undefined : EnergyEfficiencyCertificatePrintedDTOFromJSON(json['finalEnergyEfficiencyCertificate']),
        'generalData': !exists(json, 'generalData') ? undefined : BuildingCompositionDTOFromJSON(json['generalData']),
        'identification': !exists(json, 'identification') ? undefined : BuildingIdentificationPrintedDTOFromJSON(json['identification']),
        'ieeDocuments': !exists(json, 'ieeDocuments') ? undefined : ((json['ieeDocuments'] as Array<any>).map(MediaPrintedDTOFromJSON)),
        'improvements': !exists(json, 'improvements') ? undefined : ((json['improvements'] as Array<any>).map(ImprovementActionPrintedDTOFromJSON)),
        'initialEnergyEfficiencyCertificate': !exists(json, 'initialEnergyEfficiencyCertificate') ? undefined : EnergyEfficiencyCertificatePrintedDTOFromJSON(json['initialEnergyEfficiencyCertificate']),
        'inspections': !exists(json, 'inspections') ? undefined : ((json['inspections'] as Array<any>).map(InspectionPrintedDTOFromJSON)),
        'iteDocuments': !exists(json, 'iteDocuments') ? undefined : ((json['iteDocuments'] as Array<any>).map(MediaPrintedDTOFromJSON)),
        'licenses': !exists(json, 'licenses') ? undefined : ((json['licenses'] as Array<any>).map(LicenseDTOFromJSON)),
        'maintenanceContracts': !exists(json, 'maintenanceContracts') ? undefined : ((json['maintenanceContracts'] as Array<any>).map(ExtendedMediaPrintedDTOFromJSON)),
        'maintenancePlanActions': !exists(json, 'maintenancePlanActions') ? undefined : ((json['maintenancePlanActions'] as Array<any>).map(MaintenancePlanActionDTOFromJSON)),
        'multimedia': !exists(json, 'multimedia') ? undefined : ((json['multimedia'] as Array<any>).map(MediaPrintedDTOFromJSON)),
        'normalizedDescription': !exists(json, 'normalizedDescription') ? undefined : BuildingConstructionDescriptionDTOFromJSON(json['normalizedDescription']),
        'otherDocuments': !exists(json, 'otherDocuments') ? undefined : ((json['otherDocuments'] as Array<any>).map(MediaPrintedDTOFromJSON)),
        'proceedings': !exists(json, 'proceedings') ? undefined : ((json['proceedings'] as Array<any>).map(ProceedingsDTOFromJSON)),
        'techniciansData': !exists(json, 'techniciansData') ? undefined : ((json['techniciansData'] as Array<any>).map(UserDataPrintDTOFromJSON)),
        'urbanData': !exists(json, 'urbanData') ? undefined : BuildingUrbanDataPrintedDTOFromJSON(json['urbanData']),
        'usagePlans': !exists(json, 'usagePlans') ? undefined : ((json['usagePlans'] as Array<any>).map(UsagePlanDTOFromJSON)),
    };
}

export function GeneralBuildingDataPrintedDTOToJSON(value?: GeneralBuildingDataPrintedDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accesibilityEvaluations': value.accesibilityEvaluations === undefined ? undefined : ((value.accesibilityEvaluations as Array<any>).map(AccEvaluationsPrintedDTOToJSON)),
        'actionRegistries': value.actionRegistries === undefined ? undefined : ((value.actionRegistries as Array<any>).map(ExtendedMediaPrintedDTOToJSON)),
        'agentsData': value.agentsData === undefined ? undefined : ((value.agentsData as Array<any>).map(UserDataPrintDTOToJSON)),
        'bestPractices': value.bestPractices === undefined ? undefined : ((value.bestPractices as Array<any>).map(ExtendedMediaPrintedDTOToJSON)),
        'energyEfficiencyCertificates': value.energyEfficiencyCertificates === undefined ? undefined : ((value.energyEfficiencyCertificates as Array<any>).map(EnergyEfficiencyCertificatePrintedDTOToJSON)),
        'finalEnergyEfficiencyCertificate': EnergyEfficiencyCertificatePrintedDTOToJSON(value.finalEnergyEfficiencyCertificate),
        'generalData': BuildingCompositionDTOToJSON(value.generalData),
        'identification': BuildingIdentificationPrintedDTOToJSON(value.identification),
        'ieeDocuments': value.ieeDocuments === undefined ? undefined : ((value.ieeDocuments as Array<any>).map(MediaPrintedDTOToJSON)),
        'improvements': value.improvements === undefined ? undefined : ((value.improvements as Array<any>).map(ImprovementActionPrintedDTOToJSON)),
        'initialEnergyEfficiencyCertificate': EnergyEfficiencyCertificatePrintedDTOToJSON(value.initialEnergyEfficiencyCertificate),
        'inspections': value.inspections === undefined ? undefined : ((value.inspections as Array<any>).map(InspectionPrintedDTOToJSON)),
        'iteDocuments': value.iteDocuments === undefined ? undefined : ((value.iteDocuments as Array<any>).map(MediaPrintedDTOToJSON)),
        'licenses': value.licenses === undefined ? undefined : ((value.licenses as Array<any>).map(LicenseDTOToJSON)),
        'maintenanceContracts': value.maintenanceContracts === undefined ? undefined : ((value.maintenanceContracts as Array<any>).map(ExtendedMediaPrintedDTOToJSON)),
        'maintenancePlanActions': value.maintenancePlanActions === undefined ? undefined : ((value.maintenancePlanActions as Array<any>).map(MaintenancePlanActionDTOToJSON)),
        'multimedia': value.multimedia === undefined ? undefined : ((value.multimedia as Array<any>).map(MediaPrintedDTOToJSON)),
        'normalizedDescription': BuildingConstructionDescriptionDTOToJSON(value.normalizedDescription),
        'otherDocuments': value.otherDocuments === undefined ? undefined : ((value.otherDocuments as Array<any>).map(MediaPrintedDTOToJSON)),
        'proceedings': value.proceedings === undefined ? undefined : ((value.proceedings as Array<any>).map(ProceedingsDTOToJSON)),
        'techniciansData': value.techniciansData === undefined ? undefined : ((value.techniciansData as Array<any>).map(UserDataPrintDTOToJSON)),
        'urbanData': BuildingUrbanDataPrintedDTOToJSON(value.urbanData),
        'usagePlans': value.usagePlans === undefined ? undefined : ((value.usagePlans as Array<any>).map(UsagePlanDTOToJSON)),
    };
}

