import User from "../models/current-user";

export default class LocalStorageService {
  getToken(): string | undefined | null {
    return localStorage.getItem("token");
  }

  setToken(token: string) {
    localStorage.setItem("token", token);
  }

  deleteToken() {
    localStorage.removeItem("token");
  }

  deleteUser() {
    localStorage.removeItem("user");
  }

  getUser(): User | undefined {
    const user =
      localStorage && localStorage.getItem("user") !== null
        ? JSON.parse(localStorage.getItem("user")!!)
        : undefined;
    return user ?? undefined;
  }

  setUser(currentUser: User) {
    localStorage.setItem("user", JSON.stringify(currentUser));
  }

  isLoggedIn(): boolean {
    const token = localStorage.getItem("token");
    return !!(token && token.length > 0);
  }

  getCurrentPath(): string | undefined | null {
    if (!localStorage.getItem("currentPath"))
      localStorage.setItem("currentPath", "/");
    return localStorage.getItem("currentPath")!!;
  }

  setCurrentPath(path: string) {
    localStorage.setItem("currentPath", path);
  }
};
