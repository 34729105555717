/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RoofAndEnclosuresDTO
 */
export interface RoofAndEnclosuresDTO {
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    carpentryAndGlassPercentOfVerticalEnclosure?: number;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    carpentryAndGlassSurface?: number;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    inclinedCover?: number;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    inclinedCoverGuttersAvailable?: number;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    inclinedCoverPercentOfHorizontalEnclosure?: number;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    inclinedCoverSurface?: number;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    inclinedCoverThermalInsulationAvailable?: number;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    mainCarpentry?: number;
    /**
     * 
     * @type {string}
     * @memberof RoofAndEnclosuresDTO
     */
    mainCarpentryUnknownOthersValue?: string;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    mainFacadeAirChamberAvailable?: number;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    mainFacadeAttachedElements?: number;
    /**
     * 
     * @type {string}
     * @memberof RoofAndEnclosuresDTO
     */
    mainFacadeAttachedElementsUnknownOthersValue?: string;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    mainFacadeLinedFinish?: number;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    mainFacadeLinedFinishOfVerticalEnclosure?: number;
    /**
     * 
     * @type {string}
     * @memberof RoofAndEnclosuresDTO
     */
    mainFacadeLinedFinishUnknownOthersValue?: string;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    mainFacadeProtectiveElements?: number;
    /**
     * 
     * @type {string}
     * @memberof RoofAndEnclosuresDTO
     */
    mainFacadeProtectiveElementsUnknownOthersValue?: string;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    mainFacadeShownFinish?: number;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    mainFacadeShownFinishOfVerticalEnclosure?: number;
    /**
     * 
     * @type {string}
     * @memberof RoofAndEnclosuresDTO
     */
    mainFacadeShownFinishUnknownOthersValue?: string;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    mainFacadeSurface?: number;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    mainFacadeSurfacePercentOfVerticalEnclosure?: number;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    mainFacadeThermalInsulationAvailable?: number;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    mainGlass?: number;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    mainGlassLayers?: number;
    /**
     * 
     * @type {string}
     * @memberof RoofAndEnclosuresDTO
     */
    mainGlassUnknownOthersValue?: string;
    /**
     * 
     * @type {string}
     * @memberof RoofAndEnclosuresDTO
     */
    notes?: string;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    otherFacadesAirChamberAvailable?: number;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    otherFacadesAttachedElements?: number;
    /**
     * 
     * @type {string}
     * @memberof RoofAndEnclosuresDTO
     */
    otherFacadesAttachedElementsUnknownOthersValue?: string;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    otherFacadesLinedFinish?: number;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    otherFacadesLinedFinishOfVerticalEnclosure?: number;
    /**
     * 
     * @type {string}
     * @memberof RoofAndEnclosuresDTO
     */
    otherFacadesLinedFinishUnknownOthersValue?: string;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    otherFacadesPercentOfVerticalEnclosure?: number;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    otherFacadesProtectiveElements?: number;
    /**
     * 
     * @type {string}
     * @memberof RoofAndEnclosuresDTO
     */
    otherFacadesProtectiveElementsUnknownOthersValue?: string;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    otherFacadesShownFinish?: number;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    otherFacadesShownFinishOfVerticalEnclosure?: number;
    /**
     * 
     * @type {string}
     * @memberof RoofAndEnclosuresDTO
     */
    otherFacadesShownFinishUnknownOthersValue?: string;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    otherFacadesThermalInsulationAvailable?: number;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    partyWallsFacadeSurface?: number;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    passableRoofTop?: number;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    patiosFacadeSurface?: number;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    plainCoverPercentOfHorizontalEnclosure?: number;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    plainCoverSurface?: number;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    roofTopThermalInsulationAvailable?: number;
    /**
     * 
     * @type {number}
     * @memberof RoofAndEnclosuresDTO
     */
    waterproofCoatingAvailable?: number;
}

export function RoofAndEnclosuresDTOFromJSON(json: any): RoofAndEnclosuresDTO {
    return RoofAndEnclosuresDTOFromJSONTyped(json, false);
}

export function RoofAndEnclosuresDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoofAndEnclosuresDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'carpentryAndGlassPercentOfVerticalEnclosure': !exists(json, 'carpentryAndGlassPercentOfVerticalEnclosure') ? undefined : json['carpentryAndGlassPercentOfVerticalEnclosure'],
        'carpentryAndGlassSurface': !exists(json, 'carpentryAndGlassSurface') ? undefined : json['carpentryAndGlassSurface'],
        'inclinedCover': !exists(json, 'inclinedCover') ? undefined : json['inclinedCover'],
        'inclinedCoverGuttersAvailable': !exists(json, 'inclinedCoverGuttersAvailable') ? undefined : json['inclinedCoverGuttersAvailable'],
        'inclinedCoverPercentOfHorizontalEnclosure': !exists(json, 'inclinedCoverPercentOfHorizontalEnclosure') ? undefined : json['inclinedCoverPercentOfHorizontalEnclosure'],
        'inclinedCoverSurface': !exists(json, 'inclinedCoverSurface') ? undefined : json['inclinedCoverSurface'],
        'inclinedCoverThermalInsulationAvailable': !exists(json, 'inclinedCoverThermalInsulationAvailable') ? undefined : json['inclinedCoverThermalInsulationAvailable'],
        'mainCarpentry': !exists(json, 'mainCarpentry') ? undefined : json['mainCarpentry'],
        'mainCarpentryUnknownOthersValue': !exists(json, 'mainCarpentryUnknownOthersValue') ? undefined : json['mainCarpentryUnknownOthersValue'],
        'mainFacadeAirChamberAvailable': !exists(json, 'mainFacadeAirChamberAvailable') ? undefined : json['mainFacadeAirChamberAvailable'],
        'mainFacadeAttachedElements': !exists(json, 'mainFacadeAttachedElements') ? undefined : json['mainFacadeAttachedElements'],
        'mainFacadeAttachedElementsUnknownOthersValue': !exists(json, 'mainFacadeAttachedElementsUnknownOthersValue') ? undefined : json['mainFacadeAttachedElementsUnknownOthersValue'],
        'mainFacadeLinedFinish': !exists(json, 'mainFacadeLinedFinish') ? undefined : json['mainFacadeLinedFinish'],
        'mainFacadeLinedFinishOfVerticalEnclosure': !exists(json, 'mainFacadeLinedFinishOfVerticalEnclosure') ? undefined : json['mainFacadeLinedFinishOfVerticalEnclosure'],
        'mainFacadeLinedFinishUnknownOthersValue': !exists(json, 'mainFacadeLinedFinishUnknownOthersValue') ? undefined : json['mainFacadeLinedFinishUnknownOthersValue'],
        'mainFacadeProtectiveElements': !exists(json, 'mainFacadeProtectiveElements') ? undefined : json['mainFacadeProtectiveElements'],
        'mainFacadeProtectiveElementsUnknownOthersValue': !exists(json, 'mainFacadeProtectiveElementsUnknownOthersValue') ? undefined : json['mainFacadeProtectiveElementsUnknownOthersValue'],
        'mainFacadeShownFinish': !exists(json, 'mainFacadeShownFinish') ? undefined : json['mainFacadeShownFinish'],
        'mainFacadeShownFinishOfVerticalEnclosure': !exists(json, 'mainFacadeShownFinishOfVerticalEnclosure') ? undefined : json['mainFacadeShownFinishOfVerticalEnclosure'],
        'mainFacadeShownFinishUnknownOthersValue': !exists(json, 'mainFacadeShownFinishUnknownOthersValue') ? undefined : json['mainFacadeShownFinishUnknownOthersValue'],
        'mainFacadeSurface': !exists(json, 'mainFacadeSurface') ? undefined : json['mainFacadeSurface'],
        'mainFacadeSurfacePercentOfVerticalEnclosure': !exists(json, 'mainFacadeSurfacePercentOfVerticalEnclosure') ? undefined : json['mainFacadeSurfacePercentOfVerticalEnclosure'],
        'mainFacadeThermalInsulationAvailable': !exists(json, 'mainFacadeThermalInsulationAvailable') ? undefined : json['mainFacadeThermalInsulationAvailable'],
        'mainGlass': !exists(json, 'mainGlass') ? undefined : json['mainGlass'],
        'mainGlassLayers': !exists(json, 'mainGlassLayers') ? undefined : json['mainGlassLayers'],
        'mainGlassUnknownOthersValue': !exists(json, 'mainGlassUnknownOthersValue') ? undefined : json['mainGlassUnknownOthersValue'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'otherFacadesAirChamberAvailable': !exists(json, 'otherFacadesAirChamberAvailable') ? undefined : json['otherFacadesAirChamberAvailable'],
        'otherFacadesAttachedElements': !exists(json, 'otherFacadesAttachedElements') ? undefined : json['otherFacadesAttachedElements'],
        'otherFacadesAttachedElementsUnknownOthersValue': !exists(json, 'otherFacadesAttachedElementsUnknownOthersValue') ? undefined : json['otherFacadesAttachedElementsUnknownOthersValue'],
        'otherFacadesLinedFinish': !exists(json, 'otherFacadesLinedFinish') ? undefined : json['otherFacadesLinedFinish'],
        'otherFacadesLinedFinishOfVerticalEnclosure': !exists(json, 'otherFacadesLinedFinishOfVerticalEnclosure') ? undefined : json['otherFacadesLinedFinishOfVerticalEnclosure'],
        'otherFacadesLinedFinishUnknownOthersValue': !exists(json, 'otherFacadesLinedFinishUnknownOthersValue') ? undefined : json['otherFacadesLinedFinishUnknownOthersValue'],
        'otherFacadesPercentOfVerticalEnclosure': !exists(json, 'otherFacadesPercentOfVerticalEnclosure') ? undefined : json['otherFacadesPercentOfVerticalEnclosure'],
        'otherFacadesProtectiveElements': !exists(json, 'otherFacadesProtectiveElements') ? undefined : json['otherFacadesProtectiveElements'],
        'otherFacadesProtectiveElementsUnknownOthersValue': !exists(json, 'otherFacadesProtectiveElementsUnknownOthersValue') ? undefined : json['otherFacadesProtectiveElementsUnknownOthersValue'],
        'otherFacadesShownFinish': !exists(json, 'otherFacadesShownFinish') ? undefined : json['otherFacadesShownFinish'],
        'otherFacadesShownFinishOfVerticalEnclosure': !exists(json, 'otherFacadesShownFinishOfVerticalEnclosure') ? undefined : json['otherFacadesShownFinishOfVerticalEnclosure'],
        'otherFacadesShownFinishUnknownOthersValue': !exists(json, 'otherFacadesShownFinishUnknownOthersValue') ? undefined : json['otherFacadesShownFinishUnknownOthersValue'],
        'otherFacadesThermalInsulationAvailable': !exists(json, 'otherFacadesThermalInsulationAvailable') ? undefined : json['otherFacadesThermalInsulationAvailable'],
        'partyWallsFacadeSurface': !exists(json, 'partyWallsFacadeSurface') ? undefined : json['partyWallsFacadeSurface'],
        'passableRoofTop': !exists(json, 'passableRoofTop') ? undefined : json['passableRoofTop'],
        'patiosFacadeSurface': !exists(json, 'patiosFacadeSurface') ? undefined : json['patiosFacadeSurface'],
        'plainCoverPercentOfHorizontalEnclosure': !exists(json, 'plainCoverPercentOfHorizontalEnclosure') ? undefined : json['plainCoverPercentOfHorizontalEnclosure'],
        'plainCoverSurface': !exists(json, 'plainCoverSurface') ? undefined : json['plainCoverSurface'],
        'roofTopThermalInsulationAvailable': !exists(json, 'roofTopThermalInsulationAvailable') ? undefined : json['roofTopThermalInsulationAvailable'],
        'waterproofCoatingAvailable': !exists(json, 'waterproofCoatingAvailable') ? undefined : json['waterproofCoatingAvailable'],
    };
}

export function RoofAndEnclosuresDTOToJSON(value?: RoofAndEnclosuresDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'carpentryAndGlassPercentOfVerticalEnclosure': value.carpentryAndGlassPercentOfVerticalEnclosure,
        'carpentryAndGlassSurface': value.carpentryAndGlassSurface,
        'inclinedCover': value.inclinedCover,
        'inclinedCoverGuttersAvailable': value.inclinedCoverGuttersAvailable,
        'inclinedCoverPercentOfHorizontalEnclosure': value.inclinedCoverPercentOfHorizontalEnclosure,
        'inclinedCoverSurface': value.inclinedCoverSurface,
        'inclinedCoverThermalInsulationAvailable': value.inclinedCoverThermalInsulationAvailable,
        'mainCarpentry': value.mainCarpentry,
        'mainCarpentryUnknownOthersValue': value.mainCarpentryUnknownOthersValue,
        'mainFacadeAirChamberAvailable': value.mainFacadeAirChamberAvailable,
        'mainFacadeAttachedElements': value.mainFacadeAttachedElements,
        'mainFacadeAttachedElementsUnknownOthersValue': value.mainFacadeAttachedElementsUnknownOthersValue,
        'mainFacadeLinedFinish': value.mainFacadeLinedFinish,
        'mainFacadeLinedFinishOfVerticalEnclosure': value.mainFacadeLinedFinishOfVerticalEnclosure,
        'mainFacadeLinedFinishUnknownOthersValue': value.mainFacadeLinedFinishUnknownOthersValue,
        'mainFacadeProtectiveElements': value.mainFacadeProtectiveElements,
        'mainFacadeProtectiveElementsUnknownOthersValue': value.mainFacadeProtectiveElementsUnknownOthersValue,
        'mainFacadeShownFinish': value.mainFacadeShownFinish,
        'mainFacadeShownFinishOfVerticalEnclosure': value.mainFacadeShownFinishOfVerticalEnclosure,
        'mainFacadeShownFinishUnknownOthersValue': value.mainFacadeShownFinishUnknownOthersValue,
        'mainFacadeSurface': value.mainFacadeSurface,
        'mainFacadeSurfacePercentOfVerticalEnclosure': value.mainFacadeSurfacePercentOfVerticalEnclosure,
        'mainFacadeThermalInsulationAvailable': value.mainFacadeThermalInsulationAvailable,
        'mainGlass': value.mainGlass,
        'mainGlassLayers': value.mainGlassLayers,
        'mainGlassUnknownOthersValue': value.mainGlassUnknownOthersValue,
        'notes': value.notes,
        'otherFacadesAirChamberAvailable': value.otherFacadesAirChamberAvailable,
        'otherFacadesAttachedElements': value.otherFacadesAttachedElements,
        'otherFacadesAttachedElementsUnknownOthersValue': value.otherFacadesAttachedElementsUnknownOthersValue,
        'otherFacadesLinedFinish': value.otherFacadesLinedFinish,
        'otherFacadesLinedFinishOfVerticalEnclosure': value.otherFacadesLinedFinishOfVerticalEnclosure,
        'otherFacadesLinedFinishUnknownOthersValue': value.otherFacadesLinedFinishUnknownOthersValue,
        'otherFacadesPercentOfVerticalEnclosure': value.otherFacadesPercentOfVerticalEnclosure,
        'otherFacadesProtectiveElements': value.otherFacadesProtectiveElements,
        'otherFacadesProtectiveElementsUnknownOthersValue': value.otherFacadesProtectiveElementsUnknownOthersValue,
        'otherFacadesShownFinish': value.otherFacadesShownFinish,
        'otherFacadesShownFinishOfVerticalEnclosure': value.otherFacadesShownFinishOfVerticalEnclosure,
        'otherFacadesShownFinishUnknownOthersValue': value.otherFacadesShownFinishUnknownOthersValue,
        'otherFacadesThermalInsulationAvailable': value.otherFacadesThermalInsulationAvailable,
        'partyWallsFacadeSurface': value.partyWallsFacadeSurface,
        'passableRoofTop': value.passableRoofTop,
        'patiosFacadeSurface': value.patiosFacadeSurface,
        'plainCoverPercentOfHorizontalEnclosure': value.plainCoverPercentOfHorizontalEnclosure,
        'plainCoverSurface': value.plainCoverSurface,
        'roofTopThermalInsulationAvailable': value.roofTopThermalInsulationAvailable,
        'waterproofCoatingAvailable': value.waterproofCoatingAvailable,
    };
}

