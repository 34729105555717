import {
  AdminActionsApi,
  AdminParticipationListItemDTO,
  BookDTO,
  CreateFreeVoucherForCredentialsIdRequest,
  CustomAdminParticipationListItemDTOPageDTO,
  DeleteUserForAdminRequest,
  EditUserForAdminRequest,
  ExtendedSignupTechnician,
  GetAllVouchersFilteredRequest,
  GetAllVouchersRequest,
  GetBookByPropertyCodeRequest,
  GetBooksByFilterRequest,
  GetBooksByTechniciansForAdminPaginatedRequest,
  GetBooksByTechniciansForAdminRequest,
  GetStatisticsByTechnicianForAdminRequest,
  GetUserByIdForAdminRequest,
  PaymentDTO,
  StatisticsDTO,
  TechnicianDTO,
  TechnicianListItemDTO,
  VoucherListItemDTO,
  VouchersPageDTO,
} from "../../../clients";
import { Api } from "../../../infrastructure/data/api-client";
import { BaseRepository } from "../../../infrastructure/data/repositories/base-repository";

export default class AdminActionsRepository extends BaseRepository<AdminActionsApi> {
  constructor() {
    super(Api.AdminActionsApi, false);
  }

  async deleteUserForAdmin(requestParameters: DeleteUserForAdminRequest): Promise<TechnicianDTO> {
    return (await this.apiClient).deleteUserForAdmin(requestParameters);
  }

  async editUserForAdmin(requestParameters: EditUserForAdminRequest): Promise<TechnicianDTO> {
    return (await this.apiClient).editUserForAdmin(requestParameters);
  }
  async getBooksByTechniciansForAdminPaginated(
    requestParameters: GetBooksByTechniciansForAdminPaginatedRequest
  ): Promise<CustomAdminParticipationListItemDTOPageDTO> {
    return (await this.apiClient).getBooksByTechniciansForAdminPaginated(requestParameters);
  }
  async getStatisticsByTechnicianForAdmin(requestParameters: GetStatisticsByTechnicianForAdminRequest): Promise<StatisticsDTO> {
    return (await this.apiClient).getStatisticsByTechnicianForAdmin(requestParameters);
  }
  async getUserByIdForAdmin(requestParameters: GetUserByIdForAdminRequest): Promise<ExtendedSignupTechnician> {
    return (await this.apiClient).getUserByIdForAdmin(requestParameters);
  }
  async getUserListForAdmin(): Promise<TechnicianListItemDTO[]> {
    return (await this.apiClient).getUserListForAdmin();
  }

  async getBooksByTechniciansForAdmin(requestParameters: GetBooksByTechniciansForAdminRequest): Promise<AdminParticipationListItemDTO[]> {
    return (await this.apiClient).getBooksByTechniciansForAdmin(requestParameters);
  }

  async getBookByPropertyCode(requestParameters: GetBookByPropertyCodeRequest): Promise<BookDTO> {
    return (await this.apiClient).getBookByPropertyCode(requestParameters);
  }

  async getBooksByFilter(requestParameters: GetBooksByFilterRequest): Promise<AdminParticipationListItemDTO[]> {
    return (await this.apiClient).getBooksByFilter(requestParameters);
  }

  async createFreeVoucherByID(requestParameters: CreateFreeVoucherForCredentialsIdRequest, initOverrides?: RequestInit): Promise<PaymentDTO> {
    return (await this.apiClient).createFreeVoucherForCredentialsId(requestParameters, initOverrides);
  }

  async getAllVouchersFiltered(requestParameters: GetAllVouchersFilteredRequest, initOverrides?: RequestInit): Promise<VouchersPageDTO> {
    return (await this.apiClient).getAllVouchersFiltered(requestParameters, initOverrides);
  }

  async getAllVouchers(requestParameters: GetAllVouchersRequest, initOverrides?: RequestInit): Promise<Array<VoucherListItemDTO>> {
    return (await this.apiClient).getAllVouchers(requestParameters, initOverrides);
  }
}
