/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BuildingAddressDTO,
    BuildingAddressDTOFromJSON,
    BuildingAddressDTOFromJSONTyped,
    BuildingAddressDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ParticipationListItemDTO
 */
export interface ParticipationListItemDTO {
    /**
     * 
     * @type {BuildingAddressDTO}
     * @memberof ParticipationListItemDTO
     */
    address?: BuildingAddressDTO;
    /**
     * 
     * @type {string}
     * @memberof ParticipationListItemDTO
     */
    college?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipationListItemDTO
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ParticipationListItemDTO
     */
    participation?: number;
    /**
     * 
     * @type {string}
     * @memberof ParticipationListItemDTO
     */
    status?: ParticipationListItemDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ParticipationListItemDTO
     */
    technician?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipationListItemDTO
     */
    updated?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipationListItemDTO
     */
    usage?: string;
}

/**
* @export
* @enum {string}
*/
export enum ParticipationListItemDTOStatusEnum {
    Approved = 'APPROVED',
    Finished = 'FINISHED',
    InProgress = 'IN_PROGRESS',
    MaintenanceRequested = 'MAINTENANCE_REQUESTED',
    OnMaintenance = 'ON_MAINTENANCE',
    Open = 'OPEN',
    Rejected = 'REJECTED',
    ValidationRequested = 'VALIDATION_REQUESTED',
    WithIncidents = 'WITH_INCIDENTS'
}

export function ParticipationListItemDTOFromJSON(json: any): ParticipationListItemDTO {
    return ParticipationListItemDTOFromJSONTyped(json, false);
}

export function ParticipationListItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipationListItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': !exists(json, 'address') ? undefined : BuildingAddressDTOFromJSON(json['address']),
        'college': !exists(json, 'college') ? undefined : json['college'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'participation': !exists(json, 'participation') ? undefined : json['participation'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'technician': !exists(json, 'technician') ? undefined : json['technician'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
        'usage': !exists(json, 'usage') ? undefined : json['usage'],
    };
}

export function ParticipationListItemDTOToJSON(value?: ParticipationListItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': BuildingAddressDTOToJSON(value.address),
        'college': value.college,
        'id': value.id,
        'participation': value.participation,
        'status': value.status,
        'technician': value.technician,
        'updated': value.updated,
        'usage': value.usage,
    };
}

