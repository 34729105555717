import { Button } from "@movicoders/movicoders-components";
import { Divider, Grid, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import es from "date-fns/locale/es";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import ProfileViewModel from "../../../viewmodels/profile/profile-view-model";
// @ts-ignore
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { observer } from "mobx-react-lite";

export const ProfileSearchForm: React.FC<{ vm: ProfileViewModel }> = observer(({ vm }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [refresh, setRefresh] = useState<boolean>(false);

  const searchBooks = () => {
    let isDisabled = true;
    if (vm.searchOptions?.postalCode?.toString()?.length && vm.searchOptions.postalCode.toString().length > 3) isDisabled = false;
    if (vm.searchOptions?.code?.length && vm.searchOptions?.code?.length > 3) isDisabled = false;
    if (!vm.searchOptions?.code?.length) isDisabled = false;
    if (!vm.searchOptions?.postalCode?.toString()?.length) isDisabled = false;
    if (vm.searchOptions.initialDate && vm.searchOptions.finalDate) isDisabled = false;
    if (vm.searchOptions.postalCode === 0) vm.searchOptions.postalCode = undefined;
    if (isDisabled) handleOpenSnackBar("Debe rellenar al menos 4 caracteres en cualquiera de los campos de búsqueda", "info");
    if (!isDisabled) vm.searchProfileBooks(vm.searchOptions);
  };

  const handleEndDateChange = (d: Date | null) => {
    vm.searchOptions.finalDate = d === null ? undefined : d;
  };

  const handleStartDateChange = (d: Date | null) => {
    vm.searchOptions.initialDate = d === null ? undefined : d;
    vm.searchOptions.finalDate = vm.searchOptions?.initialDate === undefined ? new Date() : vm.searchOptions?.finalDate;
  };

  const handleOpenSnackBar = (snackKey: string, variants: undefined | "default" | "error" | "success" | "warning" | "info") => {
    enqueueSnackbar(snackKey, { variant: variants, preventDuplicate: true, key: snackKey, autoHideDuration: 3000 });
  };

  const handleChangeTFields = (e: React.ChangeEvent<HTMLInputElement>) => {
    vm.searchOptions = { ...vm.searchOptions, [e.target.name]: e.target.value };
  };

  const resetProfileBooks = async () => {
    vm.searchOptions = { code: "", postalCode: undefined, initialDate: undefined, finalDate: undefined };
    refreshTF();
    await vm.searchProfileBooks({});
  };

  const refreshTF = async () => {
    setRefresh((state) => !state);
    await vm.sleep(1);
    setRefresh((state) => !state);
  };

  return (
    <Grid item xs={12} marginTop=".5rem" display="flex" flexDirection="column" style={{ placeItems: "flex-start" }}>
      <Grid item xs={12} display="flex" marginBottom={"1rem"}>
        {!refresh ? (
          <>
            <TextField
              id="referenciaCatastralTextField"
              label="Referencia catastral"
              name="code"
              value={vm.searchOptions?.code}
              style={{ marginRight: ".6rem" }}
              InputLabelProps={{ style: { top: -6 } }}
              inputProps={{ style: { height: "8px", width: "140px", fontSize: "13px", fontWeight: 500 } }}
              onChange={handleChangeTFields}
            />

            <TextField
              id="postalCodeTextField"
              label="Código postal"
              name="postalCode"
              value={vm.searchOptions?.postalCode}
              InputLabelProps={{ style: { top: -6 } }}
              onChange={handleChangeTFields}
              InputProps={{
                inputComponent: NumericFormatCustom as any,
                style: { height: "40px", minWidth: "130px", fontSize: "13px", fontWeight: 500, marginRight: ".6rem" },
              }}
            />
          </>
        ) : (
          <div style={{ width: "24rem" }}></div>
        )}
        <LocalizationProvider adapterLocale={es} dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Fecha de creación desde"
            value={vm.searchOptions?.initialDate ?? null}
            onChange={handleStartDateChange}
            slotProps={{
              field: { clearable: true },
              textField: { size: "small", sx: { marginRight: ".6rem" } },
            }}
            maxDate={vm.searchOptions?.finalDate?.getDate() === new Date().getDate() ? undefined : vm.searchOptions?.finalDate}
          />
          <DatePicker
            label="Hasta"
            value={vm.searchOptions?.finalDate ?? null}
            minDate={vm.searchOptions?.initialDate?.getDate() === new Date().getDate() ? undefined : vm.searchOptions?.initialDate}
            onChange={handleEndDateChange}
            slotProps={{
              field: { clearable: true },
              textField: { size: "small", sx: { marginRight: ".6rem", width: "9.5rem" } },
            }}
          />
        </LocalizationProvider>
        <Divider orientation="vertical" flexItem style={{ marginRight: ".4rem" }} />
        <Button text={"Buscar"} style={{ height: "40px", width: "90px", marginRight: "10px" }} onClick={searchBooks} />
        <Button text={"Limpiar"} style={{ height: "40px", width: "90px" }} onClick={resetProfileBooks} />
      </Grid>
    </Grid>
  );
});

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values: any) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      valueIsNumericString
    />
  );
});
