import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, Box, Container, Divider, Grid, Paper, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { BookDTO } from "../../clients";
import ProfileViewModel from "../../viewmodels/profile/profile-view-model";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: Readonly<DialogTitleProps>) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export interface NoticeDialogProps {
  content: React.ReactNode;
  title: string;
  handleClose: () => void;
  open: boolean;
  save: () => void;
  acceptText: string;
}

export default function NoticeDialog({ content, title, handleClose, open, acceptText, save }: Readonly<NoticeDialogProps>) {
  return (
    <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {title}
      </BootstrapDialogTitle>
      <DialogContent dividers>{content}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={save}>
          {acceptText}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}

export interface SearchBookByPropertyProps {
  vm: ProfileViewModel;
}

export function SearchBookByProperty({ vm }: Readonly<SearchBookByPropertyProps>) {
  const [property, setProperty] = React.useState("");
  const [province, setProvince] = React.useState("");
  const [result, setResult] = React.useState<{ book: BookDTO | null; status: "found" | "not found" }>();
  const [loading, setLoading] = React.useState(false);
  const getBookByPropertyCode = async (code: string) => {
    if (!code) return;
    setLoading(true);
    const book = await vm.getBookByProperty(property, province);
    setLoading(false);
    if (book === null) {
      setResult({ book: null, status: "not found" });
      return;
    }
    setResult({ book: book, status: "found" });
  };

  const renderResult = () => {
    switch (result?.status) {
      case "found":
        return (
          vm.translate("searchbookbyproperty.text") +
          result.book?.code +
          " " +
          vm.translate("searchbookbyproperty.belongsTo") +
          " " +
          result.book?.technicians?.map((t) => t.name)?.join(", ") +
          "."
        );
      case "not found":
        return vm.translate("searchbookbyproperty.notfound");
      default:
        return null;
    }
  };

  return (
    <Paper sx={{ width: "724px", justifySelf: "center", alignSelf: "center", marginTop: "50px" }}>
      <Container>
        <Box mt={2}>
          <Typography variant="body2" fontSize={15} marginBottom={2} fontWeight={"bold"} component="div">
            {vm.translate("searchbookbyproperty.subtitle")}
          </Typography>
          <Grid container justifyContent={"space-between"}>
            <Grid item container mb={1} gap={2} xs={12} display="flex">
              <Autocomplete
                options={provinces}
                id="province-input-search"
                getOptionLabel={(option) => option.name}
                onChange={(e, value) => setProvince(value?.name ?? "")}
                placeholder="Provincia"
                style={{ width: "330px" }}
                aria-describedby="province-input-search"
                renderInput={(params) => (
                  <TextField {...params} id="property-input-search" label="Provincia" style={{ width: "330px" }} aria-describedby="property-input-search" />
                )}
              />
              <TextField
                id="property-input-search"
                onChange={(e) => setProperty(e.target.value)}
                label="Propiedad"
                value={property}
                style={{ width: "330px" }}
                aria-describedby="property-input-search"
              />
            </Grid>
            <Grid item pb={2}>
              <Button
                variant="contained"
                disabled={loading}
                sx={{ width: "150px", marginTop: "20px" }}
                color="primary"
                onClick={() => getBookByPropertyCode(property)}
              >
                {loading ? vm.translate("searchbookbyproperty.searching") : vm.translate("searchbookbyproperty.search")}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <DialogContent dividers sx={{ overflow: "hidden" }}>
          <Typography variant="subtitle2" component="div" fontWeight={"bold"}>
            {vm.translate("searchbookbyproperty.results")}
          </Typography>
          <Divider style={{ marginBottom: "10px" }} />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography>{renderResult()}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Container>
    </Paper>
  );
}

const provinces = [
  {
    code: "15",
    id: "81c26a59-5b2a-413e-a146-1590e14eada6",
    name: "A CORUÑA",
  },
  {
    code: "03",
    id: "9bf0dbdc-46fd-4d92-a2c6-054500fcb59e",
    name: "ALACANT",
  },
  {
    code: "02",
    id: "3f17c0ba-a203-4507-8fb8-19057a402022",
    name: "ALBACETE",
  },
  {
    code: "04",
    id: "055b6cf3-a964-4e53-bfe3-9d02def0e2af",
    name: "ALMERIA",
  },
  {
    code: "01",
    id: "710e6e2c-1622-45e8-96fc-d97e33d533ed",
    name: "ARABA/ÁLAVA",
  },
  {
    code: "33",
    id: "f761ae54-e729-47fa-a4d9-ccfcaa34a98c",
    name: "ASTURIAS",
  },
  {
    code: "05",
    id: "0cf5f3ed-9793-4856-898f-a3c40fad8ebd",
    name: "AVILA",
  },
  {
    code: "06",
    id: "52d3200a-1abf-414f-a86c-552e98846f79",
    name: "BADAJOZ",
  },
  {
    code: "08",
    id: "4d120820-8f99-4cf5-b591-6baffc67ee16",
    name: "BARCELONA",
  },
  {
    code: "48",
    id: "7fcf1dd6-6cfc-4863-bb20-ce0cb31d9d17",
    name: "BIZKAIA",
  },
  {
    code: "09",
    id: "cb8be450-ffee-462d-a864-87b2706d5434",
    name: "BURGOS",
  },
  {
    code: "10",
    id: "d7147f57-3a40-49da-a250-d96f238bb707",
    name: "CACERES",
  },
  {
    code: "11",
    id: "8aeae24f-6ead-4283-804e-beff246d7ddb",
    name: "CADIZ",
  },
  {
    code: "39",
    id: "04a15751-9663-45af-982a-e7d256af3fbb",
    name: "CANTABRIA",
  },
  {
    code: "12",
    id: "d9c731b6-2fc8-4d46-a9eb-add05a60f70e",
    name: "CASTELLO",
  },
  {
    code: "51",
    id: "3a33a523-ee28-48e2-842b-4aa6a353ffab",
    name: "CEUTA",
  },
  {
    code: "13",
    id: "e87f2f74-2e5e-4147-b326-71535900c31f",
    name: "CIUDAD REAL",
  },
  {
    code: "14",
    id: "84688023-192d-4516-99ab-7fbdb1b393df",
    name: "CORDOBA",
  },
  {
    code: "16",
    id: "2a0b7b1d-d4d7-43e6-a9fd-1e6a438db12b",
    name: "CUENCA",
  },
  {
    code: "20",
    id: "cf723a75-f738-4fda-8c65-65f3dbf92c6b",
    name: "GIPUZKOA",
  },
  {
    code: "17",
    id: "e7609f5b-dc86-4ed7-be2f-5a5a7435d635",
    name: "GIRONA",
  },
  {
    code: "18",
    id: "ba8d05c7-69e5-441f-b4a0-2c7e2e74a421",
    name: "GRANADA",
  },
  {
    code: "19",
    id: "b0104fd7-8ef5-48a2-8355-cfae36055491",
    name: "GUADALAJARA",
  },
  {
    code: "21",
    id: "8984666c-8fcb-407c-af4d-72a27d2959fc",
    name: "HUELVA",
  },
  {
    code: "22",
    id: "bc5f9839-8b60-41f0-8579-4f1b80838c25",
    name: "HUESCA",
  },
  {
    code: "07",
    id: "15a33410-0541-435f-b47f-725cd21719f0",
    name: "ILLES BALEARS",
  },
  {
    code: "23",
    id: "afba9222-ef2d-43be-9841-fe1bba14c5bc",
    name: "JAEN",
  },
  {
    code: "26",
    id: "acdb716a-b2f7-443f-a771-e45099f3f6b8",
    name: "LA RIOJA",
  },
  {
    code: "35",
    id: "1ef34e48-ee4e-4f0b-bb4c-eebd70a9cdc0",
    name: "LAS PALMAS",
  },
  {
    code: "24",
    id: "a7c4e5f7-8f29-4786-8605-e5c0a7a15ef0",
    name: "LEON",
  },
  {
    code: "25",
    id: "2a3d2fa7-c490-41de-b57c-904eed3ffd91",
    name: "LLEIDA",
  },
  {
    code: "27",
    id: "7d7e56b6-0525-4f52-ab7a-4c16eda1cee6",
    name: "LUGO",
  },
  {
    code: "28",
    id: "901f1b6e-4e5a-457a-9832-925ecb9d415c",
    name: "MADRID",
  },
  {
    code: "29",
    id: "e940290b-9e09-4631-a14a-33ba3326b761",
    name: "MALAGA",
  },
  {
    code: "52",
    id: "54f403ae-fa9f-4b8c-a32d-d405aa542f7e",
    name: "MELILLA",
  },
  {
    code: "30",
    id: "d9e5439f-7362-4aad-89c1-641a5e12e1ba",
    name: "MURCIA",
  },
  {
    code: "31",
    id: "8622fb6a-aaa2-4bc7-a39e-1969895005ad",
    name: "NAVARRA",
  },
  {
    code: "32",
    id: "b95c4f2b-97bb-4562-afc2-6758df2593c3",
    name: "OURENSE",
  },
  {
    code: "34",
    id: "54bdeca6-6f67-4b8f-8478-b1f4dd43c2bb",
    name: "PALENCIA",
  },
  {
    code: "36",
    id: "169c17f7-3be5-461e-9108-6d0943aa387a",
    name: "PONTEVEDRA",
  },
  {
    code: "37",
    id: "81c296d0-e5f9-408b-95c6-f8d46b566e87",
    name: "SALAMANCA",
  },
  {
    code: "38",
    id: "94fcb3dd-f446-4a14-bb04-bfd084baa17e",
    name: "S.C. TENERIFE",
  },
  {
    code: "40",
    id: "82d9663b-6c92-4946-aebd-543949ce62c0",
    name: "SEGOVIA",
  },
  {
    code: "41",
    id: "5d522f7b-76f0-4316-836c-5c00bb63d1ff",
    name: "SEVILLA",
  },
  {
    code: "42",
    id: "d4f23a07-db49-482d-ac96-39109011a92c",
    name: "SORIA",
  },
  {
    code: "43",
    id: "d779a001-0efb-4aac-9bb1-ebce3948ebf5",
    name: "TARRAGONA",
  },
  {
    code: "44",
    id: "eed0bffa-2db6-466a-8008-054b16be15e8",
    name: "TERUEL",
  },
  {
    code: "45",
    id: "99a650f6-73e9-4255-bdc9-c94ef2198d02",
    name: "TOLEDO",
  },
  {
    code: "46",
    id: "2f2a8053-9c7a-4bcc-b04c-febdfcfb4ee0",
    name: "VALENCIA",
  },
  {
    code: "47",
    id: "83d69814-796b-4021-b1e3-fa7d3ff38fbe",
    name: "VALLADOLID",
  },
  {
    code: "49",
    id: "2e47db97-e109-4410-92bb-6c5370f5306c",
    name: "ZAMORA",
  },
  {
    code: "50",
    id: "519b96d6-490c-41fa-b03a-b9d412122b2c",
    name: "ZARAGOZA",
  },
];
