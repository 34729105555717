/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnergyEfficiencyImprovementDTO
 */
export interface EnergyEfficiencyImprovementDTO {
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementDTO
     */
    ageOfInstallation?: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementDTO
     */
    ageOfInstallationMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementDTO
     */
    automaticSystems?: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementDTO
     */
    automaticSystemsMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementDTO
     */
    circulatingPumps?: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementDTO
     */
    circulatingPumpsMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementDTO
     */
    consumptionAccounting?: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementDTO
     */
    consumptionAccountingMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementDTO
     */
    energyCertification?: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementDTO
     */
    energyCertificationMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementDTO
     */
    energyPerformanceAnalysisOnRealConditions?: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementDTO
     */
    energyPerformanceAnalysisOnRealConditionsMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementDTO
     */
    energySavingLightingOnCommonZones?: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementDTO
     */
    energySavingLightingOnCommonZonesMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementDTO
     */
    highEfficiencyElevator?: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementDTO
     */
    highEfficiencyElevatorMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementDTO
     */
    installationPowerGenerationSystems?: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementDTO
     */
    installationPowerGenerationSystemsMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementDTO
     */
    lightingControlSystems?: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementDTO
     */
    lightingControlSystemsMedia?: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementDTO
     */
    pipeIsolation?: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyImprovementDTO
     */
    pipeIsolationMedia?: string;
}

export function EnergyEfficiencyImprovementDTOFromJSON(json: any): EnergyEfficiencyImprovementDTO {
    return EnergyEfficiencyImprovementDTOFromJSONTyped(json, false);
}

export function EnergyEfficiencyImprovementDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnergyEfficiencyImprovementDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ageOfInstallation': !exists(json, 'ageOfInstallation') ? undefined : json['ageOfInstallation'],
        'ageOfInstallationMedia': !exists(json, 'ageOfInstallationMedia') ? undefined : json['ageOfInstallationMedia'],
        'automaticSystems': !exists(json, 'automaticSystems') ? undefined : json['automaticSystems'],
        'automaticSystemsMedia': !exists(json, 'automaticSystemsMedia') ? undefined : json['automaticSystemsMedia'],
        'circulatingPumps': !exists(json, 'circulatingPumps') ? undefined : json['circulatingPumps'],
        'circulatingPumpsMedia': !exists(json, 'circulatingPumpsMedia') ? undefined : json['circulatingPumpsMedia'],
        'consumptionAccounting': !exists(json, 'consumptionAccounting') ? undefined : json['consumptionAccounting'],
        'consumptionAccountingMedia': !exists(json, 'consumptionAccountingMedia') ? undefined : json['consumptionAccountingMedia'],
        'energyCertification': !exists(json, 'energyCertification') ? undefined : json['energyCertification'],
        'energyCertificationMedia': !exists(json, 'energyCertificationMedia') ? undefined : json['energyCertificationMedia'],
        'energyPerformanceAnalysisOnRealConditions': !exists(json, 'energyPerformanceAnalysisOnRealConditions') ? undefined : json['energyPerformanceAnalysisOnRealConditions'],
        'energyPerformanceAnalysisOnRealConditionsMedia': !exists(json, 'energyPerformanceAnalysisOnRealConditionsMedia') ? undefined : json['energyPerformanceAnalysisOnRealConditionsMedia'],
        'energySavingLightingOnCommonZones': !exists(json, 'energySavingLightingOnCommonZones') ? undefined : json['energySavingLightingOnCommonZones'],
        'energySavingLightingOnCommonZonesMedia': !exists(json, 'energySavingLightingOnCommonZonesMedia') ? undefined : json['energySavingLightingOnCommonZonesMedia'],
        'highEfficiencyElevator': !exists(json, 'highEfficiencyElevator') ? undefined : json['highEfficiencyElevator'],
        'highEfficiencyElevatorMedia': !exists(json, 'highEfficiencyElevatorMedia') ? undefined : json['highEfficiencyElevatorMedia'],
        'installationPowerGenerationSystems': !exists(json, 'installationPowerGenerationSystems') ? undefined : json['installationPowerGenerationSystems'],
        'installationPowerGenerationSystemsMedia': !exists(json, 'installationPowerGenerationSystemsMedia') ? undefined : json['installationPowerGenerationSystemsMedia'],
        'lightingControlSystems': !exists(json, 'lightingControlSystems') ? undefined : json['lightingControlSystems'],
        'lightingControlSystemsMedia': !exists(json, 'lightingControlSystemsMedia') ? undefined : json['lightingControlSystemsMedia'],
        'pipeIsolation': !exists(json, 'pipeIsolation') ? undefined : json['pipeIsolation'],
        'pipeIsolationMedia': !exists(json, 'pipeIsolationMedia') ? undefined : json['pipeIsolationMedia'],
    };
}

export function EnergyEfficiencyImprovementDTOToJSON(value?: EnergyEfficiencyImprovementDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ageOfInstallation': value.ageOfInstallation,
        'ageOfInstallationMedia': value.ageOfInstallationMedia,
        'automaticSystems': value.automaticSystems,
        'automaticSystemsMedia': value.automaticSystemsMedia,
        'circulatingPumps': value.circulatingPumps,
        'circulatingPumpsMedia': value.circulatingPumpsMedia,
        'consumptionAccounting': value.consumptionAccounting,
        'consumptionAccountingMedia': value.consumptionAccountingMedia,
        'energyCertification': value.energyCertification,
        'energyCertificationMedia': value.energyCertificationMedia,
        'energyPerformanceAnalysisOnRealConditions': value.energyPerformanceAnalysisOnRealConditions,
        'energyPerformanceAnalysisOnRealConditionsMedia': value.energyPerformanceAnalysisOnRealConditionsMedia,
        'energySavingLightingOnCommonZones': value.energySavingLightingOnCommonZones,
        'energySavingLightingOnCommonZonesMedia': value.energySavingLightingOnCommonZonesMedia,
        'highEfficiencyElevator': value.highEfficiencyElevator,
        'highEfficiencyElevatorMedia': value.highEfficiencyElevatorMedia,
        'installationPowerGenerationSystems': value.installationPowerGenerationSystems,
        'installationPowerGenerationSystemsMedia': value.installationPowerGenerationSystemsMedia,
        'lightingControlSystems': value.lightingControlSystems,
        'lightingControlSystemsMedia': value.lightingControlSystemsMedia,
        'pipeIsolation': value.pipeIsolation,
        'pipeIsolationMedia': value.pipeIsolationMedia,
    };
}

