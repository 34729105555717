import { BaseAPI, CadastralInfoApi as PropertyServiceApi, Configuration } from "../../../clients";
import Properties from '../../entities/properties-entity';
import { IPropertyApi } from '../iproperties-api';

export class PropertiesApi extends BaseAPI implements IPropertyApi {
  apiClient: PropertyServiceApi

  constructor(configuration?: Configuration) {
    super(configuration);
    this.apiClient = new PropertyServiceApi(configuration);
  }

  async getPropertyByReference(reference: string): Promise<Properties> {
    const response = await this.apiClient.getPropertyById({ id: reference });
    return Properties.fromClientProperty(response);
  }

  async getPropertyByProvinceAndTownAndCadastralReference(province: string, town: string, reference: string): Promise<Properties> {
    const response = await this.apiClient.getPropertyByProvinceAndTownAndCadastralReference({
      province: province,
      town: town,
      reference: reference
    });
    return Properties.fromClientProperty(response);
  }
}