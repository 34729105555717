import { observable, action } from "mobx";
import { BusyWatcher, ScreenBase } from "@frui.ts/screens";
import { FilteredListViewModel } from "@frui.ts/datascreens";
import { debounce } from "lodash";
import type { IPagingFilter } from "@frui.ts/data";
import i18n from "../infrastructure/localization/i18n";

export default abstract class FilteredViewModelBase<TEntity, TFilter extends {}, TDetail extends ScreenBase> extends FilteredListViewModel<
  TEntity,
  TFilter,
  TDetail
> {
  get filterCount() {
    return "";
  }
  onCellClick(rowData: any, options?: any): void {
    this.openDetail(rowData);
  }
  readonly busyWatcher = new BusyWatcher();
  entityName: string = "";
  searchable = true;
  selectable = true;
  hasCellSupport = false;
  hasVirtualScroll = false;
  child!: TDetail;

  @observable.shallow selectedItems?: TEntity[] = undefined;

  @action.bound selectItems(items: TEntity[] | undefined) {
    this.selectedItems = items;
  }

  deleteSelectedItems() {}
  deleteDetail(item: TEntity): void | Promise<void> {}

  searchDebounced = debounce(this.search, 500);
  protected abstract search(query: string): void;

  @action.bound setLazyData(items: TEntity[], requestedPaging: IPagingFilter) {
    const { limit, offset } = requestedPaging;
    const hasMoreData = items.length >= limit - 1;
    const totalItems = hasMoreData ? offset + items.length + 10 : offset + items.length;

    this.items = items;
    this.currentPaging = { totalItems, offset, limit };
  }

  @action.bound loadLazyData({ first, rows }: { first: number; rows: number }) {
    this.pagingFilter.offset = first;
    this.pagingFilter.limit = rows;
    return this.loadData();
  }

  abstract openDetail(item: TEntity): void | Promise<void>;

  protected async onInitialize() {
    return this.applyFilterAndLoad();
  }

  protected onDeactivate(close: boolean) {
    if (close) {
    }

    return super.onDeactivate(close);
  }

  @action.bound
  ta(attributeName?: string) {
    return i18n.t(attributeName ?? "");
  }

  protected findNavigationChild(navigationName: string): Promise<TDetail | undefined> {
    return Promise.resolve(this.child);
  }
}
