import { MultimediaDTO } from "./../../clients/models/MultimediaDTO";
import {
  AccessibilityEvaluationDTO,
  AccessibilityEvaluationDTOFinalResultEnum,
  AddressDTO,
  BookDTO,
  BuildingCompositionDTO,
  BuildingCompositionDTOTypologyEnum,
  BuildingConstructionDescriptionDTO,
  BuildingDTO,
  BuildingLocationInfoDTO,
  BuildingLocationInfoDTOBuildingOccupancyTypeEnum,
  BuildingPolygonDTOCoordinatesSystemEnum,
  EnergyEfficiencyCertificateDTO,
  EnergyEfficiencyCertificateDTOClimaticZoneEnum,
  EnergyEfficiencyCertificateDTOTypeEnum,
  EnergyEfficiencyImprovementDTO,
  FireSafetyImprovementDTO,
  HealthinessImprovementDTO,
  ImprovementActionDTO,
  ImprovementDTO,
  ImprovementDTOPhaseEnum,
  InspectionDTO,
  InspectionDTOFinalResultEnum,
  LegalDataDTOPropertyLegalRegimeEnum,
  LicenseDTO,
  MediaDTO,
  NoiseImprovementDTO,
  OtherImprovementDTO,
  PhaseDTO,
  PhaseDTOEstimatedStartEnum,
  PhaseDTOPhaseOrderEnum,
  PolygonDTOCoordinatesSystemEnum,
  ProceedingsDTO,
  SecurityImprovementDTO,
  ShortcomingDTO,
  ShortcomingDTOPriorityEnum,
  ShortcomingDTOStatusEnum,
  TechnicianDTO,
  UrbanDataDTO,
  UsageDTO,
} from "../../clients";
import { UUIDGenerator } from "../../utils/arrayHelper";

const getDefaults = (): BookDTO => ({
  accessibilityEvaluations: [
    {
      accessibleElementsDTO: {
        accessibleMechanismsAccessible: false,
        accessibleMechanismsAccessibleNotes: "",
        parkingLotWheelChairAccessible: false,
        parkingLotWheelChairAccessibleNotes: "",
        poolsWheelChairAccessible: false,
        poolsWheelChairAccessibleNotes: "",
        sanitaryFacilitiesAccessibleNotes: "",
        sanitaryFacilitiesShowerAccessible: false,
        sanitaryFacilitiesToiletAccessible: false,
      },
      finalResult: AccessibilityEvaluationDTOFinalResultEnum.Approval,
      functionalConditionsDTO: {
        accessibilityBetweenBuildingFloorsBetweenCommonZonesInSameFloor: false,
        accessibilityBetweenBuildingFloorsBetweenLivingHomesInSameFloor: false,
        accessibilityBetweenBuildingFloorsBetweenThem: false,
        accessibilityBetweenBuildingFloorsNotes: "",
        accessibilityBetweenBuildingFloorsWheelChairAccessibility: false,
        accessibilityBetweenBuildingFloorsWheelChairAccessibilityNotes: "",
        accessibilityWithLiftBetweenBuildingFloorsWheelChairAccessibility: false,
        accessWithLiftButNotAccordingTheStandardBetweenBuildingFloors: false,
        accessWithLiftButNotAccordingTheStandardBetweenBuildingFloorsNotes: "",
        hasSpaceToInstallLiftOrRampBetweenBuildingFloors: false,
        accessibilityBetweenFloorsNotes: "",
        areaOfApplication: false,
        areaOfApplicationNotes: "",
        buildingExternalAccessWithCommonZones: false,
        buildingExternalAccessWithPublicRoad: false,
        externalAccessibilityNotes: "",
        moreThanTwelveLivingPlacesInFloorsWithoutAccessibleMainEntrance: false,
        moreThanTwelveLivingPlacesInFloorsWithoutAccessibleMainEntranceValue: 0,
        moreThanTwoFloorsBetweenAccessibleMainEntranceToLivingPlaceOrCommonZone: false,
        moreThanTwoFloorsBetweenAccessibleMainEntranceToLivingPlaceOrCommonZoneValue: 0,
        singleFamilyHomeExternalAccessWithCommonZones: false,
        singleFamilyHomeExternalAccessWithPublicRoad: false,
        technician: {
          idNumber: "",
          name: "",
          title: "",
        },
        wheelChairAccessibleWithLiftOrRampAssociatedElements: false,
        wheelChairAccessibleWithLiftOrRampBuildingEntrance: false,
        wheelChairAccessibleWithLiftOrRampCommonElements: false,
        wheelChairAccessibleWithLiftOrRampNotes: "",
      },
      informationAndSignsDTO: {
        accessibleEntrancesSignpostedWithSIA: false,
        accessibleItinerariesSignpostedWithSIA: false,
        informationAndSignsNotes: "",
        liftsSignpostedWithSIA: false,
        parkingPlacesSignpostedWithSIA: false,
      },
      media: [],
    },
  ],
  agents: [],
  buildingComposition: {
    builtArea: 0,
    builtOnYear: 0,
    complementaryFiles: [],
    dwellings: 0,
    dwellingsByFloor: 0,
    elevatorsCount: 0,
    floorsOverGround: 0,
    floorsUnderGround: 0,
    heightOverGround: 0,
    lastRehabYear: 0,
    media: [],
    otherElements: "",
    parcelArea: 0,
    parkingCountInBuilding: 0,
    parkingPlacesInBuilding: 0,
    premisesCount: 0,
    sharedPremisesCount: 0,
    stairsCount: 0,
    storageRoomsCount: 0,
    typology: BuildingCompositionDTOTypologyEnum.ClosedBlockBuilding,
    usages: [],
  },
  buildingConstructionDescription: {
    facilities: {
      centralHeating: 0,
      centralHeatingUnknownOthersValue: "",
      communicationsFacilities: 0,
      cooling: 0,
      coolingSystemsShownInFacade: 0,
      directGasForDomesticFacilities: 0,
      directGasForDomesticFacilitiesCounters: 0,
      directGasForDomesticFacilitiesFuel: 0,
      drainPipes: 0,
      drainage: 0,
      electricSupply: 0,
      fireProtection: 0,
      centralHeatingFuel: 0,
      centralHeatingFuelUnknownOthersValue: "",
      centralHeatingIssuers: 0,
      centralHeatingSupplyPower: 0,
      collectiveCooling: 0,
      collectiveCoolingIssuers: 0,
      collectiveCoolingSupplyPower: 0,
      communicationsFacilitiesUnknownOthersValue: "",
      electricSupplyMeter: 0,
      fuelTanksAvailable: 0,
      hotWaterSupply: 0,
      hotWaterSupplyFuel: 0,
      hotWaterSupplyFuelUnknownOthersValue: "",
      hotWaterSupplyPower: 0,
      lightningProtectionUnknownOthersValue: "",
      realEstateACSHeaterSystemsFuelUnknownOthersValue: "",
      waterSupplyMeter: 0,
      waterSupplyType: 0,
      lightningProtection: 0,
      notes: "",
      otherDrainPipesValue: "",
      otherElectricSupplyValue: "",
      otherSewerPipeValue: "",
      otherVentilationValue: "",
      parkingHasVentilation: 0,
      realEstateACSBottledHeaterSystems: 0,
      realEstateACSBottledHeaterSystemsFuel: 0,
      realEstateACSElectricHeaterSystems: 0,
      realEstateACSHeaterSystems: 0,
      realEstateACSHeaterSystemsFuel: 0,
      realEstateDieselOilHeaterSystems: 0,
      realEstateDirectHeaterSystems: 0,
      realEstateDirectHeaterSystemsFuel: 0,
      realEstateElectricHeaterSystems: 0,
      realEstateElectricHeaterSystemsValue: 0,
      realEstateIndividualACSProductionSystems: 0,
      realEstateIndividualCoolingSystems: 0,
      realEstateIndividualHeatingProductionSystems: 0,
      realEstateOtherACSHeaterSystems: 0,
      realEstateOtherACSHeaterSystemsValue: "",
      realEstateOtherHeaterSystems: 0,
      realEstateOtherHeaterSystemsUnknownOthersValue: "",
      realEstateWetRoomsWithoutVentilation: 0,
      sewerPipe: 0,
      solarPanelsAvailable: 0,
      ventilation: 0,
      waterSupply: 0,
    },
    foundation: {
      deepFounding: 0,
      deepFoundingUnknownOthersValue: "",
      notes: "",
      restraintSystems: 0,
      restraintSystemsUnknownOthersValue: "",
      surfaceFounding: 0,
      surfaceFoundingUnknownOthersValue: "",
    },
    roofsAndEnclosures: {
      carpentryAndGlassPercentOfVerticalEnclosure: 0,
      carpentryAndGlassSurface: 0,
      inclinedCover: 0,
      inclinedCoverPercentOfHorizontalEnclosure: 0,
      inclinedCoverSurface: 0,
      inclinedCoverThermalInsulationAvailable: 0,
      mainCarpentry: 0,
      mainFacadeAirChamberAvailable: 0,
      mainFacadeLinedFinish: 0,
      mainFacadeLinedFinishOfVerticalEnclosure: 0,
      mainFacadeLinedFinishUnknownOthersValue: "",
      mainFacadeShownFinish: 0,
      mainFacadeShownFinishOfVerticalEnclosure: 0,
      mainFacadeShownFinishUnknownOthersValue: "",
      mainFacadeSurface: 0,
      mainFacadeSurfacePercentOfVerticalEnclosure: 0,
      mainFacadeThermalInsulationAvailable: 0,
      mainGlass: 0,
      mainGlassLayers: 0,
      notes: "",
      otherFacadesAirChamberAvailable: 0,
      otherFacadesLinedFinish: 0,
      otherFacadesLinedFinishOfVerticalEnclosure: 0,
      otherFacadesLinedFinishUnknownOthersValue: "",
      otherFacadesPercentOfVerticalEnclosure: 0,
      otherFacadesShownFinish: 0,
      otherFacadesShownFinishOfVerticalEnclosure: 0,
      otherFacadesShownFinishUnknownOthersValue: "",
      otherFacadesThermalInsulationAvailable: 0,
      partyWallsFacadeSurface: 0,
      passableRoofTop: 0,
      patiosFacadeSurface: 0,
      plainCoverPercentOfHorizontalEnclosure: 0,
      plainCoverSurface: 0,
      roofTopThermalInsulationAvailable: 0,
      waterproofCoatingAvailable: 0,
      inclinedCoverGuttersAvailable: 0,
      mainCarpentryUnknownOthersValue: "",
      mainFacadeAttachedElements: 0,
      mainFacadeAttachedElementsUnknownOthersValue: "",
      mainFacadeProtectiveElements: 0,
      mainFacadeProtectiveElementsUnknownOthersValue: "",
      mainGlassUnknownOthersValue: "",
      otherFacadesAttachedElements: 0,
      otherFacadesAttachedElementsUnknownOthersValue: "",
      otherFacadesProtectiveElements: 0,
      otherFacadesProtectiveElementsUnknownOthersValue: "",
    },
    structure: {
      groundHorizontalStructure: {
        framing: 0,
        others: 0,
        sanitaryFraming: 0,
        unknownOrOthersValue: "",
      },
      horizontalStructure: {
        beams: 0,
        framing: 0,
        framingBetweenBeams: 0,
        others: 0,
        othersUnknownOthersValue: "",
      },
      notes: "",
      roofStructure: {
        horizontalFraming: 0,
        board: 0,
        inclinedFraming: 0,
        inclinedFramingUnknownOthersValue: "",
        others: 0,
        othersUnknownOthersValue: "",
        trussAndPorticos: 0,
      },
      verticalStructure: {
        loadingWalls: 0,
        othersUnknownOthersValue: "",
        pillars: 0,
        pillarsUnknownOthersValue: "",
        others: 0,
      },
      innerElements: {
        falseCeilings: 0,
        falseCeilingsSurface: 0,
        notes: "",
        partition: 0,
        partitionUnknownOthersValue: "",
        partitionWalls: 0,
        partitionWallsUnknownOthersValue: "",
        pavements: 0,
        pavementsSurface: 0,
        pavementsUnknownOthersValue: "",
        surface: 0,
      },
    },
  },
  buildingLocationInfo: {
    address: {
      block: "",
      community: "",
      door: "",
      floor: "",
      portalNumber: "",
      portalNumberLetter: "",
      postalCode: 0,
      province: "",
      secondaryPortalNumber: "",
      secondaryPortalNumberLetter: "",
      stair: "",
      street: "",
      streetName: "",
      streetType: "",
      town: "",
      cadastralParcelReference: "",
      latitude: 0,
      longitude: 0,
    },
    buildingOccupancyType: BuildingLocationInfoDTOBuildingOccupancyTypeEnum.MultyFamily,
    buildingPolygon: [
      {
        content: [
          {
            lat: 0,
            lng: 0,
          },
        ],
        coordinatesSystem: BuildingPolygonDTOCoordinatesSystemEnum.Epsg4258,
      },
    ],
    parcelPolygon: [
      {
        content: [
          {
            lat: 0,
            lng: 0,
          },
        ],
        coordinatesSystem: PolygonDTOCoordinatesSystemEnum.Epsg4258,
      },
    ],
    sharesCommonElementsWithContiguousBuildings: "No",
  },
  cadastralInfo: {
    community: "",
    street: "",
    dwellings: [0],
    floorsAboveGround: [0],
    floorsUnderGround: [0],
    name: "",
    parcels: [
      {
        area: 0,
        buildingPolygons: [
          {
            content: [
              {
                lat: 0,
                lng: 0,
              },
            ],
            coordinatesSystem: BuildingPolygonDTOCoordinatesSystemEnum.Epsg4258,
          },
        ],
        community: "",
        dwellings: 0,
        floorsAboveGround: 0,
        floorsUnderGround: 0,
        id: "-1",
        latitude: 0,
        longitude: 0,
        media: [],
        polygons: [],
        pc1: "",
        pc2: "",
        portalNumber: "",
        portalNumberLetter: "",
        postalCode: 0,
        province: "",
        secondaryPortalLetter: "",
        secondaryPortalNumber: "",
        town: "",
        year: 0,
      },
    ],
    portalNumber: "",
    portalNumberLetter: "",
    postalCode: 0,
    properties: [
      {
        antiquity: 0,
        block: "",
        builtSurface: 0,
        car: "",
        cc1: "",
        cc2: "",
        door: "",
        floor: "",
        mainUsage: "",
        participationCoefficient: 0,
        pc1: "",
        pc2: "",
        portalNumber: "",
        portalNumberLetter: "",
        postalCode: 0,
        province: "",
        secondaryPortalLetter: "",
        secondaryPortalNumber: "",
        stair: "",
        town: "",
        usages: [],
      },
    ],
    province: "",
    secondaryPortalLetter: "",
    secondaryPortalNumber: "",
    techniciansParticipation: [],
    town: "",
    type: "",
    year: 0,
  },
  code: "",
  energyEfficiencyCertificateDTO: [
    {
      certificateFiles: [],
      climaticZone: EnergyEfficiencyCertificateDTOClimaticZoneEnum.A1,
      co2Emissions: {
        amount: 0,
        qualifier: undefined,
        unitOfMeasure: "",
      },
      coolingDemand: {
        amount: 0,
        qualifier: undefined,
        unitOfMeasure: "",
      },
      dailyACSDemand: 0,
      energyReportValues: [[0]],
      heatingDemand: {
        amount: 0,
        qualifier: undefined,
        unitOfMeasure: "",
      },
      primaryEnergyNoRenewableConsumptionDTO: {
        amount: 0,
        qualifier: undefined,
        unitOfMeasure: "",
      },
      usage: "Vivienda",
    },
  ],
  id: "-1",
  ieeDocuments: [],
  improvements: [
    /* {
      enEfficiencyImprovement: {
        ageOfInstallation: '',
        automaticSystems: '',
        circulatingPumps: '',
        consumptionAccounting: '',
        energyCertification: '',
        energyPerformanceAnalysisOnRealConditions: '',
        energySavingLightingOnCommonZones: '',
        highEfficiencyElevator: '',
        lightingControlSystems: '',
        pipeIsolation: ''
      },
      fireSafetyImprovement: {
        fireProtections: '',
        fireReactionWithMaterialsOnTheCommonPlaces: '',
        fireReactionWithMaterialsOnTheFacade: '',
        partitioningOfSpecialRiskLocations: '',
        stairsProtection: ''
      },
      healthinessImprovement: {
        commonWasteStorage: '',
        commonZonesVentilation: '',
        dripIrrigation: '',
        poolWaterTreatment: '',
        protectionMeasuresOnRiskZones: '',
        singularElementsWaterTreatment: '',
        smellOnCommonWasteStorage: '',
        waterMeteringIndividualDevices: '',
        waterSavingDevices: '',
        waterSavingDevicesOnHouses: '',
        waterSupplyConditions: '',
        waterTreatmentSystems: ''
      },
      id: UUIDGenerator(),
      improvements: [
        {
          dificulty: ImprovementDTODificultyEnum.None,
          estimatedCost: ImprovementDTOEstimatedCostEnum.Until500,
          name: '',
          priority: ImprovementDTOPriorityEnum.None,
          saving: 0,
          type: ImprovementDTOTypeEnum.EnergyAgeOfInstallation
        }
      ],
      noiseImprovement: {
        noiseOnTheDay: '',
        noisesFromFacilities: '',
        noisesFromOtherBuildings: '',
        noisesFromOtherVehicles: '',
        otherNoises: '',
        otherNoisesFromOutside: ''
      },
      otherImprovement: {
        ageOfInstallations: '',
        asbestosPresence: '',
        darkZonesOnFacade: '',
        digitizationAndMonitoring: '',
        electricCondition: '',
        facadeDeficiencies: '',
        greenRoof: '',
        parkSituation: '',
        rainWaterSaving: '',
        solarProtectionZones: '',
        telecomConditions: '',
        zoneOfElectricCharge: ''
      },
      securityImprovement: {
        accessibilityElements: '',
        accessibilityElementsInformation: '',
        accessibleEntrancePool: '',
        doorsAgainstRiskOfImpact: '',
        fallProtectionBarriers: '',
        flooringAgainstRiskOfFalling: '',
        flooringAgainstRiskOfFallingDueToIsolatedSteps: '',
        functionalConditionsDTO: '',
        glazingAgainstRiskInItsCleaning: '',
        glazingAgainstRiskOfImpact: '',
        lightningStrikeProtection: '',
        parkingAgainstRiskOfRunOver: '',
        poolsAgainstRiskOfDrowing: '',
        warningSigns: ''
      }
    } */
  ],
  inspections: [],
  iteDocuments: [],
  legalData: {
    otherPropertyLegalRegimeValue: "",
    propertyLegalRegime: LegalDataDTOPropertyLegalRegimeEnum.CommunityOfOwners,
  },
  licenses: getDefaultLicenses(),
  otherDocuments: [],
  proceedings: [
    {
      administration: "",
      code: "",
      date: new Date().toISOString().replace("Z", "+00:00"),
      files: [
        {
          id: "",
          name: "",
        },
      ],
      name: "",
      notes: "",
      resolution: "",
      scope: "",
    },
  ],
  technicians: [],
  urbanData: getUrbanDataDefaults(),
});

export const getBookMock = (book?: Partial<BookDTO>): BookDTO => ({
  ...getDefaults(),
  ...book,
});

const getInspectionDefaults = (): InspectionDTO => ({
  finalResult: InspectionDTOFinalResultEnum.Approval,
  finalResultNotes: "",
  foundationDeficienciesFinalResult: undefined,
  foundationDeficienciesNotes: "",
  structuralDeficienciesFinalResult: undefined,
  structuralDeficienciesNotes: "",
  verticalEnclosuresDeficienciesFinalResult: undefined,
  verticalEnclosuresDeficienciesNotes: "",
  roofsAndCoveringsDeficienciesFinalResult: undefined,
  roofsAndCoveringsDeficienciesNotes: "",
  commonBuildingFacilitiesDeficienciesFinalResult: undefined,
  commonBuildingFacilitiesDeficienciesNotes: "",
  generalData: {
    date: new Date().toISOString().replace("Z", "+00:00"),
    immediateSecurityMeasuresTakenDuringVisit: "",
    impediments: "",
    inspectedHouseHolds: 0,
    inspectedPremisedOrOtherUsages: 0,
    notes: "",
    testsMade: "",
    meansUsedDuringInspection: "",
  },
  id: "",
  imminentRisk: {
    deadline: new Date().toISOString().replace("Z", "+00:00"),
    description: "",
    recommendations: "",
  },
  shortcomings: [],
});

export const getInspectionMock = (inspection?: Partial<InspectionDTO>): InspectionDTO => ({
  ...getInspectionDefaults(),
  ...inspection,
});

export type ShortComingDTO = ShortcomingDTO & {
  temporalFiles?: File[];
};

const getShortcomingDefaults = (): ShortComingDTO => ({
  id: "",
  constructionEndDate: new Date().toISOString().replace("Z", "+00:00"),
  constructionStartDate: new Date().toISOString().replace("Z", "+00:00"),
  files: [],
  location: "",
  name: "",
  notes: "",
  description: "",
  priority: ShortcomingDTOPriorityEnum.Low,
  section: "",
  status: ShortcomingDTOStatusEnum.Unfavorable,
  subsection: "",
  testsMade: "",
});

export const getShortcomingMock = (shortComing?: Partial<ShortComingDTO>): ShortComingDTO => ({
  ...getShortcomingDefaults(),
  ...shortComing,
});

const getMultimediaDefaults = (): MultimediaDTO => ({
  id: "",
  description: "",
  extension: "",
  length: 0,
  name: "",
  mediaType: "",
  url: "",
});

export const getMultimediaMock = (multimedia?: Partial<MultimediaDTO>): MultimediaDTO => ({
  ...getMultimediaDefaults(),
  ...multimedia,
});

const getAccesibilityEvaluationsDefaults = (): AccessibilityEvaluationDTO => ({
  excludeDataToFill: false,
  accessibleElementsDTO: {
    accessibleMechanismsAccessible: false,
    accessibleMechanismsAccessibleNotes: "",
    parkingLotWheelChairAccessible: false,
    parkingLotWheelChairAccessibleNotes: "",
    poolsWheelChairAccessible: false,
    poolsWheelChairAccessibleNotes: "",
    sanitaryFacilitiesAccessibleNotes: "",
    sanitaryFacilitiesShowerAccessible: false,
    sanitaryFacilitiesToiletAccessible: false,
  },
  finalResult: AccessibilityEvaluationDTOFinalResultEnum.Approval,
  functionalConditionsDTO: {
    accessibilityBetweenBuildingFloorsBetweenCommonZonesInSameFloor: false,
    accessibilityBetweenBuildingFloorsBetweenLivingHomesInSameFloor: false,
    accessibilityBetweenBuildingFloorsBetweenThem: false,
    accessibilityBetweenBuildingFloorsNotes: "",
    accessibilityBetweenBuildingFloorsWheelChairAccessibility: false,
    accessibilityWithLiftBetweenBuildingFloorsWheelChairAccessibility: false,
    accessibilityBetweenBuildingFloorsWheelChairAccessibilityNotes: "",
    accessWithLiftButNotAccordingTheStandardBetweenBuildingFloors: false,
    accessWithLiftButNotAccordingTheStandardBetweenBuildingFloorsNotes: "",
    hasSpaceToInstallLiftOrRampBetweenBuildingFloors: false,
    accessibilityBetweenFloorsNotes: "",
    areaOfApplication: false,
    areaOfApplicationNotes: "",
    buildingExternalAccessWithCommonZones: false,
    buildingExternalAccessWithPublicRoad: false,
    externalAccessibilityNotes: "",
    moreThanTwelveLivingPlacesInFloorsWithoutAccessibleMainEntrance: false,
    moreThanTwelveLivingPlacesInFloorsWithoutAccessibleMainEntranceValue: 0,
    moreThanTwoFloorsBetweenAccessibleMainEntranceToLivingPlaceOrCommonZone: false,
    moreThanTwoFloorsBetweenAccessibleMainEntranceToLivingPlaceOrCommonZoneValue: 0,
    singleFamilyHomeExternalAccessWithCommonZones: false,
    singleFamilyHomeExternalAccessWithPublicRoad: false,
    technician: {
      idNumber: "",
      name: "",
      title: "",
    },
    wheelChairAccessibleWithLiftOrRampAssociatedElements: false,
    wheelChairAccessibleWithLiftOrRampBuildingEntrance: false,
    wheelChairAccessibleWithLiftOrRampCommonElements: false,
    wheelChairAccessibleWithLiftOrRampNotes: "",
  },
  informationAndSignsDTO: {
    accessibleEntrancesSignpostedWithSIA: false,
    accessibleItinerariesSignpostedWithSIA: false,
    informationAndSignsNotes: "",
    liftsSignpostedWithSIA: false,
    parkingPlacesSignpostedWithSIA: false,
  },
  media: [],
});

export const getAccesibilityEvaluationsMock = (accesibility?: Partial<AccessibilityEvaluationDTO>): AccessibilityEvaluationDTO => ({
  ...getAccesibilityEvaluationsDefaults(),
  ...accesibility,
});

const getMediaDefaults = (): MediaDTO => ({
  id: "",
  name: "",
});

export const getMediaMock = (media?: Partial<MediaDTO>): MediaDTO => ({
  ...getMediaDefaults(),
  ...media,
});

const getEnergyEfficiencyCertificateDefaults = (): EnergyEfficiencyCertificateDTO => ({
  certificateFiles: [],
  climaticZone: EnergyEfficiencyCertificateDTOClimaticZoneEnum.A1,
  co2Emissions: {
    amount: 0,
    qualifier: undefined,
    unitOfMeasure: "",
  },
  coolingDemand: {
    amount: 0,
    qualifier: undefined,
    unitOfMeasure: "",
  },
  dailyACSDemand: 0,
  energyReportValues: [[0]],
  heatingDemand: {
    amount: 0,
    qualifier: undefined,
    unitOfMeasure: "",
  },
  primaryEnergyNoRenewableConsumptionDTO: {
    amount: 0,
    qualifier: undefined,
    unitOfMeasure: "",
  },
  gasNaturalEnergyReport: {
    cooling: 0,
    global: 0,
    acs: 0,
    heating: 0,
    lighting: 0,
  },
  lightingEnergyReport: {
    cooling: 0,
    global: 0,
    acs: 0,
    heating: 0,
    lighting: 0,
  },
  biomassEnergyReport: {
    cooling: 0,
    global: 0,
    acs: 0,
    heating: 0,
    lighting: 0,
  },
  glpEnergyReport: {
    cooling: 0,
    global: 0,
    acs: 0,
    heating: 0,
    lighting: 0,
  },
  carbonEnergyReport: {
    cooling: 0,
    global: 0,
    acs: 0,
    heating: 0,
    lighting: 0,
  },
  bioFuelEnergyReport: {
    cooling: 0,
    global: 0,
    acs: 0,
    heating: 0,
    lighting: 0,
  },
  pelletBiomassEnergyReport: {
    cooling: 0,
    global: 0,
    acs: 0,
    heating: 0,
    lighting: 0,
  },
  dieselFuelEnergyReport: {
    cooling: 0,
    global: 0,
    acs: 0,
    heating: 0,
    lighting: 0,
  },
  co2EmissionsEnergyReport: {
    cooling: 0,
    global: 0,
    acs: 0,
    heating: 0,
    lighting: 0,
  },
  finalEnergyReport: {
    cooling: 0,
    global: 0,
    acs: 0,
    heating: 0,
    lighting: 0,
  },
  type: EnergyEfficiencyCertificateDTOTypeEnum.Initial,
  date: Date.now(),
  usage: "Vivienda",
  energyEfficientCertificateMedia: "",
});

export const getEnergyEfficiencyCertificateMock = (
  energyEfficiencyCertificate?: Partial<EnergyEfficiencyCertificateDTO>
): EnergyEfficiencyCertificateDTO => ({
  ...getEnergyEfficiencyCertificateDefaults(),
  ...energyEfficiencyCertificate,
});

const getLicenseDefaults = (): LicenseDTO => ({
  code: "",
  date: new Date().toISOString().replace("Z", "+00:00"),
  files: [],
  name: "",
  notes: "",
  scope: "",
  technicians: "",
});

export const getLicenseMock = (license?: Partial<LicenseDTO>): LicenseDTO => ({
  ...getLicenseDefaults(),
  ...license,
});

const getProceedingDefaults = (): ProceedingsDTO => ({
  date: new Date().toISOString().replace("Z", "+00:00"),
  files: [],
  name: "",
  notes: "",
  scope: "",
  administration: "",
  resolution: "",
  code: "",
});

export const getProceedingMock = (proceeding?: Partial<ProceedingsDTO>): ProceedingsDTO => ({
  ...getProceedingDefaults(),
  ...proceeding,
});

export const getDefaultLicenses = (): LicenseDTO[] => [
  {
    code: "ite",
    date: new Date().toISOString().replace("Z", "+00:00"),
    files: [],
    name: "",
    notes: "",
    scope: "",
    technicians: "",
  },
  {
    code: "iee",
    date: new Date().toISOString().replace("Z", "+00:00"),
    files: [],
    name: "",
    notes: "",
    scope: "",
    technicians: "",
  },
];

const getUrbanDataDefaults = (): UrbanDataDTO => ({
  classification: "",
  currentPlanning: "",
  notes: "",
  ordinance: "",
  protectedElements: "",
  urbanPlan: [],
});

export const getUrbanDataMock = (urbanData?: Partial<UrbanDataDTO>): UrbanDataDTO => ({
  ...getUrbanDataDefaults(),
  ...urbanData,
});

export const initialData: BookDTO = {
  accessibilityEvaluations: [
    {
      accessibleElementsDTO: {
        accessibleMechanismsAccessible: false,
        accessibleMechanismsAccessibleNotes: "",
        parkingLotWheelChairAccessible: false,
        parkingLotWheelChairAccessibleNotes: "",
        poolsWheelChairAccessible: false,
        poolsWheelChairAccessibleNotes: "",
        sanitaryFacilitiesAccessibleNotes: "",
        sanitaryFacilitiesShowerAccessible: false,
        sanitaryFacilitiesToiletAccessible: false,
      },
      finalResult: AccessibilityEvaluationDTOFinalResultEnum.Approval,
      functionalConditionsDTO: {
        accessibilityBetweenBuildingFloorsBetweenCommonZonesInSameFloor: false,
        accessibilityBetweenBuildingFloorsBetweenLivingHomesInSameFloor: false,
        accessibilityBetweenBuildingFloorsBetweenThem: false,
        accessibilityBetweenBuildingFloorsNotes: "",
        accessibilityBetweenBuildingFloorsWheelChairAccessibility: false,
        accessibilityBetweenBuildingFloorsWheelChairAccessibilityNotes: "",
        accessWithLiftButNotAccordingTheStandardBetweenBuildingFloorsNotes: "",
        accessibilityBetweenFloorsNotes: "",
        areaOfApplication: false,
        areaOfApplicationNotes: "",
        buildingExternalAccessWithCommonZones: false,
        buildingExternalAccessWithPublicRoad: false,
        externalAccessibilityNotes: "",
        moreThanTwelveLivingPlacesInFloorsWithoutAccessibleMainEntrance: false,
        moreThanTwelveLivingPlacesInFloorsWithoutAccessibleMainEntranceValue: 0,
        moreThanTwoFloorsBetweenAccessibleMainEntranceToLivingPlaceOrCommonZone: false,
        moreThanTwoFloorsBetweenAccessibleMainEntranceToLivingPlaceOrCommonZoneValue: 0,
        singleFamilyHomeExternalAccessWithCommonZones: false,
        singleFamilyHomeExternalAccessWithPublicRoad: false,
        technician: {
          idNumber: "",
          name: "",
          title: "",
        },
        wheelChairAccessibleWithLiftOrRampAssociatedElements: false,
        wheelChairAccessibleWithLiftOrRampBuildingEntrance: false,
        wheelChairAccessibleWithLiftOrRampCommonElements: false,
        wheelChairAccessibleWithLiftOrRampNotes: "",
      },
      informationAndSignsDTO: {
        accessibleEntrancesSignpostedWithSIA: false,
        accessibleItinerariesSignpostedWithSIA: false,
        informationAndSignsNotes: "",
        liftsSignpostedWithSIA: false,
        parkingPlacesSignpostedWithSIA: false,
      },
      media: [],
    },
  ],
  agents: [],
  buildingComposition: {
    builtArea: 0,
    builtOnYear: 0,
    complementaryFiles: [],
    dwellings: 0,
    dwellingsByFloor: 0,
    elevatorsCount: 0,
    floorsOverGround: 0,
    floorsUnderGround: 0,
    heightOverGround: 0,
    lastRehabYear: 0,
    media: [],
    otherElements: "",
    parcelArea: 0,
    parkingCountInBuilding: 0,
    parkingPlacesInBuilding: 0,
    premisesCount: 0,
    sharedPremisesCount: 0,
    stairsCount: 0,
    storageRoomsCount: 0,
    typology: BuildingCompositionDTOTypologyEnum.ClosedBlockBuilding,
    usages: [],
  },
  buildingConstructionDescription: {
    facilities: {
      centralHeating: 0,
      centralHeatingUnknownOthersValue: "",
      communicationsFacilities: 0,
      cooling: 0,
      coolingSystemsShownInFacade: 0,
      directGasForDomesticFacilities: 0,
      directGasForDomesticFacilitiesCounters: 0,
      directGasForDomesticFacilitiesFuel: 0,
      drainPipes: 0,
      drainage: 0,
      electricSupply: 0,
      fireProtection: 0,
      centralHeatingFuel: 0,
      centralHeatingFuelUnknownOthersValue: "",
      centralHeatingIssuers: 0,
      centralHeatingSupplyPower: 0,
      collectiveCooling: 0,
      collectiveCoolingIssuers: 0,
      collectiveCoolingSupplyPower: 0,
      communicationsFacilitiesUnknownOthersValue: "",
      electricSupplyMeter: 0,
      fuelTanksAvailable: 0,
      hotWaterSupply: 0,
      hotWaterSupplyFuel: 0,
      hotWaterSupplyFuelUnknownOthersValue: "",
      hotWaterSupplyPower: 0,
      lightningProtectionUnknownOthersValue: "",
      realEstateACSHeaterSystemsFuelUnknownOthersValue: "",
      waterSupplyMeter: 0,
      waterSupplyType: 0,
      lightningProtection: 0,
      notes: "",
      otherDrainPipesValue: "",
      otherElectricSupplyValue: "",
      otherSewerPipeValue: "",
      otherVentilationValue: "",
      parkingHasVentilation: 0,
      realEstateACSBottledHeaterSystems: 0,
      realEstateACSBottledHeaterSystemsFuel: 0,
      realEstateACSElectricHeaterSystems: 0,
      realEstateACSHeaterSystems: 0,
      realEstateACSHeaterSystemsFuel: 0,
      realEstateDieselOilHeaterSystems: 0,
      realEstateDirectHeaterSystems: 0,
      realEstateDirectHeaterSystemsFuel: 0,
      realEstateElectricHeaterSystems: 0,
      realEstateElectricHeaterSystemsValue: 0,
      realEstateIndividualACSProductionSystems: 0,
      realEstateIndividualCoolingSystems: 0,
      realEstateIndividualHeatingProductionSystems: 0,
      realEstateOtherACSHeaterSystems: 0,
      realEstateOtherACSHeaterSystemsValue: "",
      realEstateOtherHeaterSystems: 0,
      realEstateOtherHeaterSystemsUnknownOthersValue: "",
      realEstateWetRoomsWithoutVentilation: 0,
      sewerPipe: 0,
      solarPanelsAvailable: 0,
      ventilation: 0,
      waterSupply: 0,
    },
    foundation: {
      deepFounding: 0,
      deepFoundingUnknownOthersValue: "",
      notes: "",
      restraintSystems: 0,
      restraintSystemsUnknownOthersValue: "",
      surfaceFounding: 0,
      surfaceFoundingUnknownOthersValue: "",
    },
    roofsAndEnclosures: {
      carpentryAndGlassPercentOfVerticalEnclosure: 0,
      carpentryAndGlassSurface: 0,
      inclinedCover: 0,
      inclinedCoverPercentOfHorizontalEnclosure: 0,
      inclinedCoverSurface: 0,
      inclinedCoverThermalInsulationAvailable: 0,
      mainCarpentry: 0,
      mainFacadeAirChamberAvailable: 0,
      mainFacadeLinedFinish: 0,
      mainFacadeLinedFinishOfVerticalEnclosure: 0,
      otherFacadesProtectiveElements: 0,
      mainFacadeLinedFinishUnknownOthersValue: "",
      mainFacadeShownFinish: 0,
      mainFacadeShownFinishOfVerticalEnclosure: 0,
      mainFacadeProtectiveElementsUnknownOthersValue: "",
      mainFacadeSurface: 0,
      mainFacadeSurfacePercentOfVerticalEnclosure: 0,
      mainFacadeThermalInsulationAvailable: 0,
      mainGlass: 0,
      mainGlassLayers: 0,
      notes: "",
      otherFacadesAirChamberAvailable: 0,
      otherFacadesLinedFinish: 0,
      otherFacadesLinedFinishOfVerticalEnclosure: 0,
      otherFacadesLinedFinishUnknownOthersValue: "",
      otherFacadesPercentOfVerticalEnclosure: 0,
      otherFacadesShownFinish: 0,
      otherFacadesShownFinishOfVerticalEnclosure: 0,
      otherFacadesShownFinishUnknownOthersValue: "",
      otherFacadesThermalInsulationAvailable: 0,
      partyWallsFacadeSurface: 0,
      passableRoofTop: 0,
      patiosFacadeSurface: 0,
      plainCoverPercentOfHorizontalEnclosure: 0,
      plainCoverSurface: 0,
      roofTopThermalInsulationAvailable: 0,
      waterproofCoatingAvailable: 0,
      inclinedCoverGuttersAvailable: 0,
      mainCarpentryUnknownOthersValue: "",
      mainFacadeAttachedElements: 0,
      mainFacadeAttachedElementsUnknownOthersValue: "",
      mainFacadeProtectiveElements: 0,
      mainFacadeShownFinishUnknownOthersValue: "",
      mainGlassUnknownOthersValue: "",
      otherFacadesAttachedElements: 0,
      otherFacadesAttachedElementsUnknownOthersValue: "",
      otherFacadesProtectiveElementsUnknownOthersValue: "",
    },
    structure: {
      groundHorizontalStructure: {
        framing: 0,
        others: 0,
        sanitaryFraming: 0,
        unknownOrOthersValue: "",
      },
      horizontalStructure: {
        beams: 0,
        framing: 0,
        framingBetweenBeams: 0,
        othersUnknownOthersValue: "",
        others: 0,
      },
      notes: "",
      roofStructure: {
        horizontalFraming: 0,
        board: 0,
        inclinedFraming: 0,
        inclinedFramingUnknownOthersValue: "",
        others: 0,
        othersUnknownOthersValue: "",
        trussAndPorticos: 0,
      },
      verticalStructure: {
        loadingWalls: 0,
        pillars: 0,
        others: 0,
        othersUnknownOthersValue: "",
        pillarsUnknownOthersValue: "",
      },
      innerElements: {
        falseCeilings: 0,
        falseCeilingsSurface: 0,
        notes: "",
        partition: 0,
        partitionUnknownOthersValue: "",
        partitionWalls: 0,
        partitionWallsUnknownOthersValue: "",
        pavements: 0,
        pavementsSurface: 0,
        pavementsUnknownOthersValue: "",
        surface: 0,
      },
    },
  },
  buildingLocationInfo: {
    address: {
      block: "",
      community: "",
      door: "",
      floor: "",
      cadastralParcelReference: "",
      latitude: 0,
      longitude: 0,
      portalNumber: "",
      portalNumberLetter: "",
      postalCode: 0,
      province: "",
      secondaryPortalNumber: "",
      secondaryPortalNumberLetter: "",
      stair: "",
      street: "",
      streetName: "",
      streetType: "",
      town: "",
    },
    buildingOccupancyType: BuildingLocationInfoDTOBuildingOccupancyTypeEnum.MultyFamily,
    buildingPolygon: [
      {
        content: [
          {
            lat: 0,
            lng: 0,
          },
        ],
        coordinatesSystem: BuildingPolygonDTOCoordinatesSystemEnum.Epsg4258,
      },
    ],
    parcelPolygon: [
      {
        content: [
          {
            lat: 0,
            lng: 0,
          },
        ],
        coordinatesSystem: PolygonDTOCoordinatesSystemEnum.Epsg4258,
      },
    ],
    sharesCommonElementsWithContiguousBuildings: "No",
  },
  cadastralInfo: {
    community: "",
    dwellings: [0],
    floorsAboveGround: [0],
    floorsUnderGround: [0],
    name: "",
    parcels: [
      {
        area: 0,
        buildingPolygons: [
          {
            content: [
              {
                lat: 0,
                lng: 0,
              },
            ],
            coordinatesSystem: BuildingPolygonDTOCoordinatesSystemEnum.Epsg4258,
          },
        ],
        community: "",
        dwellings: 0,
        floorsAboveGround: 0,
        floorsUnderGround: 0,
        id: UUIDGenerator(),
        latitude: 0,
        longitude: 0,
        media: [],
        pc1: "",
        pc2: "",
        polygons: [
          {
            content: [
              {
                lat: 0,
                lng: 0,
              },
            ],
            coordinatesSystem: PolygonDTOCoordinatesSystemEnum.Epsg4258,
          },
        ],
        portalNumber: "",
        portalNumberLetter: "",
        postalCode: 0,
        province: "",
        secondaryPortalLetter: "",
        secondaryPortalNumber: "",
        town: "",
        year: 0,
      },
    ],
    portalNumber: "",
    portalNumberLetter: "",
    postalCode: 0,
    properties: [
      {
        antiquity: 0,
        block: "",
        builtSurface: 0,
        car: "",
        cc1: "",
        cc2: "",
        door: "",
        floor: "",
        mainUsage: "",
        participationCoefficient: 0,
        pc1: "",
        pc2: "",
        portalNumber: "",
        portalNumberLetter: "",
        postalCode: 0,
        province: "",
        secondaryPortalLetter: "",
        secondaryPortalNumber: "",
        stair: "",
        town: "",
        usages: [],
      },
    ],
    province: "",
    secondaryPortalLetter: "",
    secondaryPortalNumber: "",
    techniciansParticipation: [],
    town: "",
    type: "",
    year: 0,
  },
  code: UUIDGenerator(),
  energyEfficiencyCertificateDTO: [
    {
      certificateFiles: [],
      climaticZone: EnergyEfficiencyCertificateDTOClimaticZoneEnum.A1,
      co2Emissions: {
        amount: 0,
        qualifier: undefined,
        unitOfMeasure: "",
      },
      coolingDemand: {
        amount: 0,
        qualifier: undefined,
        unitOfMeasure: "",
      },
      dailyACSDemand: 0,
      energyReportValues: [[0]],
      heatingDemand: {
        amount: 0,
        qualifier: undefined,
        unitOfMeasure: "",
      },
      primaryEnergyNoRenewableConsumptionDTO: {
        amount: 0,
        qualifier: undefined,
        unitOfMeasure: "",
      },
      usage: "Vivienda",
    },
  ],
  id: "",
  ieeDocuments: [],
  improvements: [],
  inspections: [],
  iteDocuments: [],
  legalData: {
    otherPropertyLegalRegimeValue: "",
    propertyLegalRegime: LegalDataDTOPropertyLegalRegimeEnum.CommunityOfOwners,
  },
  licenses: [],
  otherDocuments: [],
  proceedings: [],
  technicians: [],
  urbanData: getUrbanDataDefaults(),
};

const getSecurityImprovementDefaults = (): SecurityImprovementDTO => ({
  accessibilityElements: "",
  accessibilityElementsInformation: "",
  accessibleEntrancePool: "",
  doorsAgainstRiskOfImpact: "",
  fallProtectionBarriers: "",
  flooringAgainstRiskOfFalling: "",
  flooringAgainstRiskOfFallingDueToIsolatedSteps: "",
  functionalConditions: "",
  functionalConditionsMedia: "",
  glazingAgainstRiskInItsCleaning: "",
  glazingAgainstRiskOfImpact: "",
  lightningStrikeProtection: "",
  othersConditions: "",
  othersInProvisionOfElements: "",
  parkingAgainstRiskOfRunOver: "",
  poolsAgainstRiskOfDrowning: "",
  stairsAgainstFallingRisk: "",
  warningSigns: "",
  warningSignsMedia: "",
});

export const getSecurityImprovementMock = (securityImprovement?: Partial<SecurityImprovementDTO>): SecurityImprovementDTO => ({
  ...getSecurityImprovementDefaults(),
  ...securityImprovement,
});

const getFireSafetyImprovementDTODefaults = (): FireSafetyImprovementDTO => ({
  fireProtections: "",
  fireReactionWithMaterialsOnTheCommonPlaces: "",
  fireReactionWithMaterialsOnTheFacade: "",
  partitioningOfSpecialRiskLocations: "",
  stairsProtection: "",
});

export const getFireSafetyImprovementDTOMock = (fireSafetyImprovementDTO?: Partial<FireSafetyImprovementDTO>): FireSafetyImprovementDTO => ({
  ...getFireSafetyImprovementDTODefaults(),
  ...fireSafetyImprovementDTO,
});

const getEnergeticEfficiencyImprovementDTODefaults = (): EnergyEfficiencyImprovementDTO => ({
  ageOfInstallation: "",
  automaticSystems: "",
  circulatingPumps: "",
  installationPowerGenerationSystems: "",
  consumptionAccounting: "",
  energyCertification: "",
  energyPerformanceAnalysisOnRealConditions: "",
  energySavingLightingOnCommonZones: "",
  highEfficiencyElevator: "",
  lightingControlSystems: "",
  pipeIsolation: "",
});

export const getEnergeticEfficiencyImprovementDTOMock = (
  energeticEfficiencyImprovementDTO?: Partial<EnergyEfficiencyImprovementDTO>
): EnergyEfficiencyImprovementDTO => ({
  ...getEnergeticEfficiencyImprovementDTODefaults(),
  ...energeticEfficiencyImprovementDTO,
});

const getNoiseImprovementDTODefaults = (): NoiseImprovementDTO => ({
  noiseOnTheDay: "",
  noiseOnTheDayMedia: undefined,
  noisesFromFacilities: "",
  noisesFromFacilitiesMedia: undefined,
  noisesFromOtherBuildings: "",
  noisesFromOtherBuildingsMedia: undefined,
  noisesFromOtherVehicles: "",
  noisesFromOtherVehiclesMedia: undefined,
  otherNoises: "",
  otherNoisesFromOutside: "",
  otherNoisesFromOutsideMedia: undefined,
  otherNoisesMedia: undefined,
});

export const getNoiseImprovementDTOMock = (noiseImprovementDTO?: Partial<NoiseImprovementDTO>): NoiseImprovementDTO => ({
  ...getNoiseImprovementDTODefaults(),
  ...noiseImprovementDTO,
});

const getHealthinessImprovementDTODefaults = (): HealthinessImprovementDTO => ({
  commonWasteStorage: "",
  commonZonesVentilation: "",
  dripIrrigation: "",
  poolWaterTreatment: "",
  protectionMeasuresOnRiskZones: "",
  singularElementsWaterTreatment: "",
  smellOnCommonWasteStorage: "",
  waterMeteringIndividualDevices: "",
  waterSavingDevices: "",
  waterSavingDevicesOnHouses: "",
  waterSupplyConditions: "",
  waterTreatmentSystems: "",
});

export const getHealthinessImprovementDTOMock = (healthinessImprovementDTO?: Partial<HealthinessImprovementDTO>): HealthinessImprovementDTO => ({
  ...getHealthinessImprovementDTODefaults(),
  ...healthinessImprovementDTO,
});

const getOtherImprovementDTODefaults = (): OtherImprovementDTO => ({
  ageOfInstallations: "",
  asbestosPresence: "",
  consumptionAccounting: "",
  darkZonesOnFacade: "",
  digitizationAndMonitoring: "",
  electricCondition: "",
  facadeDeficiencies: "",
  greenRoof: "",
  parkSituation: "",
  permeableExteriorSurfaces: "",
  rainWaterSaving: "",
  solarProtectionZones: "",
  telecomConditions: "",
  zoneOfElectricCharge: "",
});

export const getOtherImprovementDTOMock = (otherImprovementDTO?: Partial<OtherImprovementDTO>): OtherImprovementDTO => ({
  ...getOtherImprovementDTODefaults(),
  ...otherImprovementDTO,
});

const getImprovementDTODefaults = (): ImprovementDTOId => ({
  name: "",
  saving: 0,
  type: undefined,
  phase: ImprovementDTOPhaseEnum.NoScheduled,
  estimatedCost: undefined,
  files: [],
  difficulty: undefined,
  priority: undefined,
  recommendedStartDateOfConstructions: new Date().valueOf(),
});

export const getImprovementDTOMock = (improvementDTO?: Partial<ImprovementDTO>): ImprovementDTO => ({
  ...getImprovementDTODefaults(),
  ...improvementDTO,
});

const getPhaseDefaults = (): PhaseDTO => ({
  id: UUIDGenerator(),
  phaseOrder: PhaseDTOPhaseOrderEnum.NoScheduled,
  nonRenewablePrimaryEnergyConsumptionReductionPercentage: 0,
  energyDemandReductionPercentage: 0,
  durationInMonths: 0,
  estimatedStart: PhaseDTOEstimatedStartEnum.ZeroToSixMonths,
  startDate: undefined,
});

export const getPhaseMock = (phaseDTO?: Partial<PhaseDTO>): PhaseDTO => ({
  ...getPhaseDefaults(),
  ...phaseDTO,
});

export const getBuildingCompositionDefaults = (buildingComposition?: Partial<BuildingCompositionDTO>): BuildingCompositionDTO => ({
  ...{
    builtArea: 0,
    builtOnYear: 0,
    complementaryFiles: [],
    dwellings: 0,
    dwellingsByFloor: 0,
    elevatorsCount: 0,
    floorsOverGround: 0,
    floorsUnderGround: 0,
    heightOverGround: 0,
    lastRehabYear: 0,
    media: [],
    otherElements: "",
    parcelArea: 0,
    parkingCountInBuilding: 0,
    parkingPlacesInBuilding: 0,
    premisesCount: 0,
    sharedPremisesCount: 0,
    stairsCount: 0,
    storageRoomsCount: 0,
    typology: BuildingCompositionDTOTypologyEnum.ClosedBlockBuilding,
    usages: [],
  },
  ...buildingComposition,
});

export const getBuildingConstructionDescriptionDefaults = (
  buildingDescription?: Partial<BuildingConstructionDescriptionDTO>
): BuildingConstructionDescriptionDTO => ({
  ...{
    facilities: {
      centralHeating: 0,
      centralHeatingUnknownOthersValue: "",
      communicationsFacilities: 0,
      cooling: 0,
      coolingSystemsShownInFacade: 0,
      directGasForDomesticFacilities: 0,
      directGasForDomesticFacilitiesCounters: 0,
      directGasForDomesticFacilitiesFuel: 0,
      drainPipes: 0,
      drainage: 0,
      electricSupply: 0,
      facilitiesLifting: 0,
      facilitiesLiftingUnknownOthersValue: "",
      fireProtection: 0,
      gasRadonProtection: 0,
      gasRadonProtectionUnknownOthersValue: "",
      centralHeatingFuel: 0,
      centralHeatingFuelUnknownOthersValue: "",
      centralHeatingIssuers: 0,
      centralHeatingSupplyPower: 0,
      collectiveCooling: 0,
      collectiveCoolingIssuers: 0,
      collectiveCoolingSupplyPower: 0,
      communicationsFacilitiesUnknownOthersValue: "",
      electricSupplyMeter: 0,
      fuelTanksAvailable: 0,
      hotWaterSupply: 0,
      hotWaterSupplyFuel: 0,
      hotWaterSupplyFuelUnknownOthersValue: "",
      hotWaterSupplyPower: 0,
      lightningProtectionUnknownOthersValue: "",
      realEstateACSHeaterSystemsFuelUnknownOthersValue: "",
      waterSupplyMeter: 0,
      waterSupplyType: 0,
      lightningProtection: 0,
      notes: "",
      otherDrainPipesValue: "",
      otherElectricSupplyValue: "",
      otherSewerPipeValue: "",
      otherVentilationValue: "",
      parkingHasVentilation: 0,
      realEstateACSBottledHeaterSystems: 0,
      realEstateACSBottledHeaterSystemsFuel: 0,
      realEstateACSElectricHeaterSystems: 0,
      realEstateACSHeaterSystems: 0,
      realEstateACSHeaterSystemsFuel: 0,
      realEstateDieselOilHeaterSystems: 0,
      realEstateDirectHeaterSystems: 0,
      realEstateDirectHeaterSystemsFuel: 0,
      realEstateElectricHeaterSystems: 0,
      realEstateElectricHeaterSystemsValue: 0,
      realEstateIndividualACSProductionSystems: 0,
      realEstateIndividualCoolingSystems: 0,
      realEstateIndividualHeatingProductionSystems: 0,
      realEstateOtherACSHeaterSystems: 0,
      realEstateOtherACSHeaterSystemsValue: "",
      realEstateOtherHeaterSystems: 0,
      realEstateOtherHeaterSystemsUnknownOthersValue: "",
      realEstateWetRoomsWithoutVentilation: 0,
      sewerPipe: 0,
      solarPanelsAvailable: 0,
      ventilation: 0,
      waterSupply: 0,
    },
    foundation: {
      deepFounding: 0,
      deepFoundingUnknownOthersValue: "",
      notes: "",
      restraintSystems: 0,
      restraintSystemsUnknownOthersValue: "",
      surfaceFounding: 0,
      surfaceFoundingUnknownOthersValue: "",
    },
    roofsAndEnclosures: {
      carpentryAndGlassPercentOfVerticalEnclosure: 0,
      carpentryAndGlassSurface: 0,
      inclinedCover: 0,
      inclinedCoverPercentOfHorizontalEnclosure: 0,
      inclinedCoverSurface: 0,
      inclinedCoverThermalInsulationAvailable: 0,
      mainCarpentry: 0,
      mainFacadeAirChamberAvailable: 0,
      mainFacadeLinedFinish: 0,
      mainFacadeLinedFinishOfVerticalEnclosure: 0,
      mainFacadeLinedFinishUnknownOthersValue: "",
      mainFacadeShownFinish: 0,
      mainFacadeShownFinishOfVerticalEnclosure: 0,
      mainFacadeShownFinishUnknownOthersValue: "",
      mainFacadeSurface: 0,
      mainFacadeSurfacePercentOfVerticalEnclosure: 0,
      mainFacadeThermalInsulationAvailable: 0,
      mainGlass: 0,
      mainGlassLayers: 0,
      notes: "",
      otherFacadesAirChamberAvailable: 0,
      otherFacadesLinedFinish: 0,
      otherFacadesLinedFinishOfVerticalEnclosure: 0,
      otherFacadesLinedFinishUnknownOthersValue: "",
      otherFacadesPercentOfVerticalEnclosure: 0,
      otherFacadesShownFinish: 0,
      otherFacadesShownFinishOfVerticalEnclosure: 0,
      otherFacadesShownFinishUnknownOthersValue: "",
      otherFacadesThermalInsulationAvailable: 0,
      partyWallsFacadeSurface: 0,
      passableRoofTop: 0,
      patiosFacadeSurface: 0,
      plainCoverPercentOfHorizontalEnclosure: 0,
      plainCoverSurface: 0,
      roofTopThermalInsulationAvailable: 0,
      waterproofCoatingAvailable: 0,
      inclinedCoverGuttersAvailable: 0,
      mainCarpentryUnknownOthersValue: "",
      mainFacadeAttachedElements: 0,
      mainFacadeAttachedElementsUnknownOthersValue: "",
      mainFacadeProtectiveElements: 0,
      mainFacadeProtectiveElementsUnknownOthersValue: "",
      mainGlassUnknownOthersValue: "",
      otherFacadesAttachedElements: 0,
      otherFacadesAttachedElementsUnknownOthersValue: "",
      otherFacadesProtectiveElements: 0,
      otherFacadesProtectiveElementsUnknownOthersValue: "",
    },
    structure: {
      groundHorizontalStructure: {
        framing: 0,
        others: 0,
        sanitaryFraming: 0,
        unknownOrOthersValue: "",
      },
      horizontalStructure: {
        beams: 0,
        framing: 0,
        framingBetweenBeams: 0,
        others: 0,
        othersUnknownOthersValue: "",
      },
      notes: "",
      roofStructure: {
        horizontalFraming: 0,
        inclinedFraming: 0,
        board: 0,
        inclinedFramingUnknownOthersValue: "",
        others: 0,
        othersUnknownOthersValue: "",
        trussAndPorticos: 0,
      },
      verticalStructure: {
        loadingWalls: 0,
        pillars: 0,
        others: 0,
        othersUnknownOthersValue: "",
        pillarsUnknownOthersValue: "",
      },
      innerElements: {
        falseCeilings: 0,
        falseCeilingsSurface: 0,
        notes: "",
        partition: 0,
        partitionUnknownOthersValue: "",
        partitionWalls: 0,
        partitionWallsUnknownOthersValue: "",
        pavements: 0,
        pavementsSurface: 0,
        pavementsUnknownOthersValue: "",
        surface: 0,
      },
    },
  },
  ...buildingDescription,
});

export const getBuildingLocationInfoDefaults = (buildingLocation?: Partial<BuildingLocationInfoDTO>): BuildingLocationInfoDTO => ({
  ...{
    address: {
      block: "",
      community: "",
      door: "",
      floor: "",
      portalNumber: "",
      portalNumberLetter: "",
      postalCode: 0,
      province: "",
      secondaryPortalNumber: "",
      secondaryPortalNumberLetter: "",
      stair: "",
      street: "",
      streetName: "",
      streetType: "",
      town: "",
    },
    buildingOccupancyType: BuildingLocationInfoDTOBuildingOccupancyTypeEnum.MultyFamily,
    buildingPolygon: [
      {
        content: [
          {
            lat: 0,
            lng: 0,
          },
        ],
        coordinatesSystem: BuildingPolygonDTOCoordinatesSystemEnum.Epsg4258,
      },
    ],
    parcelPolygon: [
      {
        content: [
          {
            lat: 0,
            lng: 0,
          },
        ],
        coordinatesSystem: PolygonDTOCoordinatesSystemEnum.Epsg4258,
      },
    ],
    sharesCommonElementsWithContiguousBuildings: "No",
  },
  ...buildingLocation,
});

export type ImprovementDTOId = ImprovementDTO & {
  id?: string;
};

export const getBuildingCompositionMock = (): BuildingCompositionDTO => {
  return {
    builtArea: 0,
    builtOnYear: 0,
    complementaryFiles: [],
    dwellings: 0,
    dwellingsByFloor: 0,
    elevatorsCount: 0,
    floorsOverGround: 0,
    floorsUnderGround: 0,
    heightOverGround: 0,
    lastRehabYear: 0,
    media: [],
    otherElements: "",
    parcelArea: 0,
    parkingCountInBuilding: 0,
    parkingPlacesInBuilding: 0,
    premisesCount: 0,
    sharedPremisesCount: 0,
    stairsCount: 0,
    storageRoomsCount: 0,
    typology: BuildingCompositionDTOTypologyEnum.ClosedBlockBuilding,
    usages: [],
  };
};

export const getBuildingLocationInfoMock = (): BuildingLocationInfoDTO => {
  return {
    address: undefined,
    buildingOccupancyType: undefined,
    buildingPolygon: undefined,
    parcelPolygon: undefined,
    sharesCommonElementsWithContiguousBuildings: undefined,
    referenceDescription: "",
  };
};

export const getImprovementActionDTODefaults = (improvementActionDTO?: Partial<ImprovementActionDTO>): ImprovementActionDTO => ({
  phases: [],
  summary: "",
  timestamp: new Date().valueOf(),
  improvementActionMedias: [],
  enEfficiencyImprovement: {
    ageOfInstallation: "",
    ageOfInstallationMedia: undefined,
    automaticSystems: "",
    automaticSystemsMedia: undefined,
    installationPowerGenerationSystems: "",
    installationPowerGenerationSystemsMedia: undefined,
    circulatingPumps: "",
    circulatingPumpsMedia: undefined,
    consumptionAccounting: "",
    consumptionAccountingMedia: undefined,
    energyCertification: "",
    energyCertificationMedia: undefined,
    energyPerformanceAnalysisOnRealConditions: "",
    energyPerformanceAnalysisOnRealConditionsMedia: undefined,
    energySavingLightingOnCommonZones: "",
    energySavingLightingOnCommonZonesMedia: undefined,
    highEfficiencyElevator: "",
    highEfficiencyElevatorMedia: undefined,
    lightingControlSystems: "",
    lightingControlSystemsMedia: undefined,
    pipeIsolation: "",
    pipeIsolationMedia: undefined,
  },
  fireSafetyImprovement: {
    fireProtections: "",
    fireProtectionsMedia: undefined,
    fireReactionWithMaterialsOnTheCommonPlaces: "",
    fireReactionWithMaterialsOnTheCommonPlacesMedia: undefined,
    fireReactionWithMaterialsOnTheFacade: "",
    fireReactionWithMaterialsOnTheFacadeMedia: undefined,
    partitioningOfSpecialRiskLocations: "",
    partitioningOfSpecialRiskLocationsMedia: undefined,
    stairsProtection: "",
    stairsProtectionMedia: undefined,
  },
  healthinessImprovement: {
    commonWasteStorage: "",
    commonWasteStorageMedia: undefined,
    commonZonesVentilation: "",
    commonZonesVentilationMedia: undefined,
    dripIrrigation: "",
    dripIrrigationMedia: undefined,
    garageAiring: "",
    garageAiringMedia: undefined,
    houseAiring: "",
    houseAiringMedia: undefined,
    poolWaterTreatment: "",
    poolWaterTreatmentMedia: undefined,
    protectionMeasuresOnRiskZones: "",
    protectionMeasuresOnRiskZonesMedia: undefined,
    singularElementsWaterTreatment: "",
    singularElementsWaterTreatmentMedia: undefined,
    smellOnCommonWasteStorage: "",
    smellOnCommonWasteStorageMedia: undefined,
    storageRoomAiring: "",
    storageRoomAiringMedia: undefined,
    wasteWarehouseAiring: "",
    wasteWarehouseAiringMedia: undefined,
    waterMeteringIndividualDevices: "",
    waterMeteringIndividualDevicesMedia: undefined,
    waterSavingDevices: "",
    waterSavingDevicesMedia: undefined,
    waterSavingDevicesOnHouses: "",
    waterSavingDevicesOnHousesMedia: undefined,
    waterSupplyConditions: "",
    waterSupplyConditionsMedia: undefined,
    waterTreatmentSystems: "",
    waterTreatmentSystemsMedia: undefined,
  },
  id: UUIDGenerator(),
  improvements: [],
  noiseImprovement: {
    noiseOnTheDay: "",
    noiseOnTheDayMedia: undefined,
    noisesFromFacilities: "",
    noisesFromFacilitiesMedia: undefined,
    noisesFromOtherBuildings: "",
    noisesFromOtherBuildingsMedia: undefined,
    noisesFromOtherVehicles: "",
    noisesFromOtherVehiclesMedia: undefined,
    otherNoises: "",
    otherNoisesFromOutside: "",
    otherNoisesFromOutsideMedia: undefined,
    otherNoisesMedia: undefined,
  },
  otherImprovement: {
    ageOfInstallations: "",
    ageOfInstallationsMedia: undefined,
    asbestosPresence: "",
    asbestosPresenceMedia: undefined,
    consumptionAccounting: undefined,
    consumptionAccountingMedia: undefined,
    darkZonesOnFacade: "",
    darkZonesOnFacadeMedia: undefined,
    digitizationAndMonitoring: "",
    digitizationAndMonitoringMedia: undefined,
    electricCondition: "",
    electricConditionMedia: undefined,
    facadeDeficiencies: "",
    facadeDeficienciesMedia: undefined,
    greenRoof: "",
    greenRoofMedia: undefined,
    parkSituation: "",
    parkSituationMedia: undefined,
    permeableExteriorSurfaces: undefined,
    permeableExteriorSurfacesMedia: undefined,
    rainWaterSaving: "",
    rainWaterSavingMedia: undefined,
    solarProtectionZones: "",
    solarProtectionZonesMedia: undefined,
    telecomConditions: "",
    telecomConditionsMedia: undefined,
    zoneOfElectricCharge: "",
    zoneOfElectricChargeMedia: undefined,
  },
  securityImprovement: {
    accessibilityElements: "",
    accessibilityElementsInformation: "",
    accessibilityElementsInformationMedia: undefined,
    accessibilityElementsMedia: undefined,
    accessibleEntrancePool: "",
    accessibleEntrancePoolMedia: undefined,
    doorsAgainstRiskOfImpact: "",
    doorsAgainstRiskOfImpactMedia: undefined,
    fallProtectionBarriers: "",
    fallProtectionBarriersMedia: undefined,
    flooringAgainstRiskOfFalling: "",
    flooringAgainstRiskOfFallingDueToIsolatedSteps: "",
    flooringAgainstRiskOfFallingDueToIsolatedStepsMedia: undefined,
    flooringAgainstRiskOfFallingMedia: undefined,
    functionalConditions: "",
    functionalConditionsMedia: undefined,
    glazingAgainstRiskInItsCleaning: "",
    glazingAgainstRiskInItsCleaningMedia: undefined,
    glazingAgainstRiskOfImpact: "",
    glazingAgainstRiskOfImpactMedia: undefined,
    lightningStrikeProtection: "",
    lightningStrikeProtectionMedia: undefined,
    othersConditions: "",
    othersConditionsMedia: undefined,
    othersInProvisionOfElements: "",
    othersInProvisionOfElementsMedia: undefined,
    parkingAgainstRiskOfRunOver: "",
    parkingAgainstRiskOfRunOverMedia: undefined,
    poolsAgainstRiskOfDrowning: "",
    poolsAgainstRiskOfDrowningMedia: undefined,
    stairsAgainstFallingRisk: "",
    stairsAgainstFallingRiskMedia: undefined,
    warningSigns: "",
    warningSignsMedia: undefined,
  },
});

export const getImprovementActionDTOMock = (improvementActionDTO?: Partial<ImprovementActionDTO>): ImprovementActionDTO => ({
  ...getImprovementActionDTODefaults(),
  ...improvementActionDTO,
});

export type TechnicianDTOParticipation = TechnicianDTO & {
  participation: number;
};

const addressDefaults = (): AddressDTO => ({
  block: "",
  community: "",
  door: "",
  floor: "",
  portalNumber: "",
  secondaryPortalNumber: "",
  province: "",
  secondaryPortalNumberLetter: "",
  stair: "",
  streetCode: "",
  streetType: "",
  streetName: "",
  portalNumberLetter: "",
  postalCode: 0,
  town: "",
  townName: "",
});

export const addressDTOMock = (address?: Partial<AddressDTO>): AddressDTO => ({
  ...addressDefaults(),
  ...address,
});

const usageDefaults = (): UsageDTO => ({
  block: "",
  door: "",
  floor: "",
  stair: "",
  cadastralReference: "",
  description: "",
  surface: 0,
});

export const usageMock = (usage?: Partial<UsageDTO>): UsageDTO => ({
  ...usageDefaults(),
  ...usage,
});

const buildingDefaults = (): BuildingDTO => ({
  community: "",
  province: "",
  town: "",
  street: "",
  type: "",
  portalNumber: "",
  postalCode: 0,
  properties: [],
  parcels: [],
  dwellings: [],
  floorsAboveGround: [],
  floorsUnderGround: [],
  portalNumberLetter: "",
  secondaryPortalLetter: "",
  secondaryPortalNumber: "",
  techniciansParticipation: [],
  year: 1900,
  transactionId: undefined,
  name: "",
});

export const buildingMock = (building?: Partial<BuildingDTO>): BuildingDTO => ({
  ...buildingDefaults(),
  ...building,
});
