import { Api } from "../../../infrastructure/data/api-client"
import { BaseRepository } from "../../../infrastructure/data/repositories/base-repository"
import { CreateCollegeAdministratorRequest, GetAllCollegeAdministratorRequest, UpdateCollegeAdministratorRequest } from "../../../clients";
import ICollegeAdministratorApi from "../icollege-administrator-api";


export default class CollegeAdministratorRepository extends BaseRepository<ICollegeAdministratorApi> {

  constructor() { super(Api.CollegeAdministratorApi, false); }

  async createCollegeAdministrator(requestParameters: CreateCollegeAdministratorRequest) {
    const client = await this.apiClient;
    return await client.createCollegeAdministrator(requestParameters);
  }

  async deleteCollegeAdministrator(id: string) {
    const client = await this.apiClient;
    return await client.deleteCollegeAdministrator(id);
  }

  async getAllCollegeAdministrator(requestParameters: GetAllCollegeAdministratorRequest) {
    const client = await this.apiClient;
    return await client.getAllCollegeAdministrator(requestParameters);
  }

  async getCollegeAdministratorByCollege(college: string) {
    const client = await this.apiClient;
    return await client.getCollegeAdministratorByCollege(college);
  }

  async updateCollegeAdministrator(requestParameters: UpdateCollegeAdministratorRequest) {
    const client = await this.apiClient;
    return await client.updateCollegeAdministrator(requestParameters);
  }

  async getCollegeAdministratorByID(id: string) {
    const client = await this.apiClient;
    return await client.getCollegeAdministratorByID(id);
  }
}