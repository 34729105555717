import { ICadastralApi } from '../icadastral-api'
import { Api } from '../../../infrastructure/data/api-client'
import { BaseRepository } from '../../../infrastructure/data/repositories/base-repository'

export default class CadastralRepository extends BaseRepository<ICadastralApi> {

  constructor() {
    super(Api.CadastralApi, false)
  }

  async findCadastralEntityByReference(reference: string) {
    return await (await this.apiClient).findByReference(reference)
  }

  async findCadastralEntityByAddress(item: any) { }
}