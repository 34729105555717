import { Button as MovicodersButton } from "@movicoders/movicoders-components";
import { Grid } from "@mui/material";
import { SimpleMapScreenshoter } from "leaflet-simple-map-screenshoter";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { MultimediaDTO } from "../../../../../../clients";
import TextFieldGrid from "../../../../../../components/text-fields/textfield-grid";
import TypographyTitle from "../../../../../../components/typography-title";
import { Colors } from "../../../../../../constants";
import ReportViewModel from "../../../../../../viewmodels/report/report-view-model";
import { ReportStyle } from "../../../../report-style";
import { ReactiveMap } from "./reactive-map";
import { UploadFileDialog } from "./upload-file-dialog";

export const MapSection: React.FC<{ vm: ReportViewModel }> = observer(({ vm }) => {
  const classes = ReportStyle();
  const [map, setMap] = useState(undefined);

  const save = async () => {
    const blob = (await new SimpleMapScreenshoter().addTo(map!!).takeScreen("blob")) as unknown as Blob;
    await vm.saveMapBuilding(blob, "map_screenshot");
  };

  const collectSitePlan = (m?: MultimediaDTO) => {
    if (m) vm.findMapScreenshotManual(m);
  };

  const mappolygons = (): [number, number] => {
    if (vm.userBook.buildingLocationInfo?.buildingPolygon?.[0]?.content?.length) {
      return [vm.userBook.buildingLocationInfo?.buildingPolygon?.[0]?.content?.[0].lat ?? 0, vm.userBook.buildingLocationInfo?.buildingPolygon?.[0]?.content?.[0].lng ?? 0]
    } else if (vm.userBook.buildingLocationInfo?.parcelPolygon?.[0]?.content?.length) {
      return [vm.userBook.buildingLocationInfo?.parcelPolygon?.[0]?.content?.[0].lat ?? 0, vm.userBook.buildingLocationInfo?.parcelPolygon?.[0]?.content?.[0].lng ?? 0]
    }
    else {
      return [vm.userBook.buildingLocationInfo?.address?.latitude ?? 0, vm.userBook.buildingLocationInfo?.address?.longitude ?? 0]
    }
  }
  const mapview = () => {
    return vm.parent.isManual ? (
      <>
        <UploadFileDialog id="juploadMapScreenShot" vm={vm} buttonText="Añadir plano" collect={collectSitePlan} isUrbanPlan />
        <TextFieldGrid
          id={"latitud"}
          title="Latitud"
          numeric
          allowNegativeNumbers
          gridTextField={{ xs: 12, md: 4 }}
          value={vm.coordinates.latitude?.toString()}
          onChange={(e: any) => {
            if (vm.parent.isManual) vm.coordinates.latitude = e.target.value;
          }}
        />
        <TextFieldGrid
          id={"longitud"}
          numeric
          allowNegativeNumbers
          title="Longitud"
          gridTextField={{ xs: 12, md: 4 }}
          value={vm.coordinates.longitude?.toString()}
          onChange={(e: any) => {
            if (vm.parent.isManual) vm.coordinates.longitude = e.target.value;
          }}
        />
      </>
    ) : (
      <>
        <MapContainer
          id="mapSectionContainer"
          dragging={false}
          zoom={20}
          className={classes.report_map}
          scrollWheelZoom={false}
          center={mappolygons()}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <ReactiveMap vm={vm} setMap={setMap} mappolygons={mappolygons} />
        </MapContainer>

        <MovicodersButton
          id="saveLocationMap"
          text={vm.translate("report.block0.save.location.map")}
          onClick={save}
          textColor={Colors.white}
          backgroundColor={Colors.buttonPrimary}
          hoverColor={Colors.buttonPrimary}
        />
      </>
    );
  };

  return (
    <React.Fragment>
      {vm.userBook.buildingLocationInfo?.buildingPolygon?.length || vm.userBook.buildingLocationInfo?.parcelPolygon?.length ||
        (vm.userBook.buildingLocationInfo?.address?.latitude !== 0 || vm.userBook.buildingLocationInfo?.address?.longitude !== 0) ? (
        <Grid item xs={12} md={12} className={classes.report_displayGridTitle}>
          <TypographyTitle id="mapSectionTitle" marginTop title={vm.translate("report.block0.map")} />
          <div style={{ width: 1, height: 10 }} />
          {mapview()}
        </Grid>
      ) : null}
    </React.Fragment>
  );
});
