/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GatewayConfigurationDTO
 */
export interface GatewayConfigurationDTO {
    /**
     * 
     * @type {string}
     * @memberof GatewayConfigurationDTO
     */
    acquirerBIN?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayConfigurationDTO
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayConfigurationDTO
     */
    cypher?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayConfigurationDTO
     */
    exemptionSCA?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayConfigurationDTO
     */
    exponent?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayConfigurationDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayConfigurationDTO
     */
    merchantId?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayConfigurationDTO
     */
    platform?: GatewayConfigurationDTOPlatformEnum;
    /**
     * 
     * @type {string}
     * @memberof GatewayConfigurationDTO
     */
    secret?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayConfigurationDTO
     */
    terminalId?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayConfigurationDTO
     */
    urlNOK?: string;
    /**
     * 
     * @type {string}
     * @memberof GatewayConfigurationDTO
     */
    urlOK?: string;
}

/**
* @export
* @enum {string}
*/
export enum GatewayConfigurationDTOPlatformEnum {
    Ceca = 'CECA'
}

export function GatewayConfigurationDTOFromJSON(json: any): GatewayConfigurationDTO {
    return GatewayConfigurationDTOFromJSONTyped(json, false);
}

export function GatewayConfigurationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): GatewayConfigurationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'acquirerBIN': !exists(json, 'acquirerBIN') ? undefined : json['acquirerBIN'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'cypher': !exists(json, 'cypher') ? undefined : json['cypher'],
        'exemptionSCA': !exists(json, 'exemptionSCA') ? undefined : json['exemptionSCA'],
        'exponent': !exists(json, 'exponent') ? undefined : json['exponent'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'merchantId': !exists(json, 'merchantId') ? undefined : json['merchantId'],
        'platform': !exists(json, 'platform') ? undefined : json['platform'],
        'secret': !exists(json, 'secret') ? undefined : json['secret'],
        'terminalId': !exists(json, 'terminalId') ? undefined : json['terminalId'],
        'urlNOK': !exists(json, 'urlNOK') ? undefined : json['urlNOK'],
        'urlOK': !exists(json, 'urlOK') ? undefined : json['urlOK'],
    };
}

export function GatewayConfigurationDTOToJSON(value?: GatewayConfigurationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'acquirerBIN': value.acquirerBIN,
        'currency': value.currency,
        'cypher': value.cypher,
        'exemptionSCA': value.exemptionSCA,
        'exponent': value.exponent,
        'id': value.id,
        'merchantId': value.merchantId,
        'platform': value.platform,
        'secret': value.secret,
        'terminalId': value.terminalId,
        'urlNOK': value.urlNOK,
        'urlOK': value.urlOK,
    };
}

