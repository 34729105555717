import { DialogMovicoders } from "@movicoders/movicoders-components"
import { ConfirmDialogStrings } from "@movicoders/movicoders-components"
import { observer } from "mobx-react-lite"
import React from "react"
import { Colors } from "../../../constants"
import UserDetailViewModel from "../../../viewmodels/users/user-detail-view-model"
import { UserForm } from "./UseForm"

export const UserDialog: React.FC<{ vm: UserDetailViewModel }> = observer(({ vm }) => {
  const handleClose = () => {
    vm.requestClose()
    vm.parent.setSelected(undefined)
  }

  const dialogStrings: ConfirmDialogStrings = {
    title: vm.item.id === -1 ? vm.translate("user.detail.createUser") : vm.translate("user.detail.editUser"),
    content: <UserForm vm={vm} />
  }

  return (
    <DialogMovicoders
      id="userDialogMovicoders"
      open={true}
      draggable
      dialogConfig={dialogStrings}
      onConfirm={() => { }}
      onClose={() => handleClose()}
      closeWithX
      maxWidth={"sm"}
      backgroundColorTitle={Colors.primary}
      fullWidth
    />
  )
})