import JSZip from "jszip";
import { MediaPrintedDTO, MultimediaDTO, NewPrintingBookDTO } from "../../../clients/models";
import { mountUrl } from "../../../utils/imageHelper";
import ProfileViewModel from "../../../viewmodels/profile/profile-view-model";
import ReportViewModel from "../../../viewmodels/report/report-view-model";

interface useDownloadZipsProps {
  vm: ProfileViewModel;
  printingBook: NewPrintingBookDTO;
}

export const useDownloadZips = ({ vm, printingBook: book }: useDownloadZipsProps) => {
  const transformIntoMultimedia = async (mediaIds: string[]): Promise<MultimediaDTO[]> => {
    if (!mediaIds) return [];
    const reportvm = vm.parent.children.find((c: any) => c.navigationName === "report") as ReportViewModel;
    return await Promise.all(mediaIds?.map((id) => id && reportvm.getMultimediaById(id)));
  };

  const transformIntoFile = async (multimedias: MultimediaDTO[]): Promise<File[]> => {
    const files = [];
    for (const multimedia of multimedias) {
      try {
        const result = await fetch(mountUrl(multimedia?.url ?? ""))
          .then((res) => res.blob())
          .catch((e) => new File([], "noname.txt"))
          .then((blob) => {
            return new File([blob], multimedia?.name! + "." + multimedia?.extension);
          });
        files.push(result);
      } catch (error) {
        console.log(error);
      }
    }
    return files;
  };

  const securityMedias =
    book.secondBlock?.improvementActions && book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1]
      ? [
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].securityImprovement?.accessibilityElementsInformationMedia
            ?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].securityImprovement?.accessibilityElementsMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].securityImprovement?.accessibleEntrancePoolMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].securityImprovement?.doorsAgainstRiskOfImpactMedia?.id ??
            "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].securityImprovement?.fallProtectionBarriersMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].securityImprovement?.doorsAgainstRiskOfImpactMedia?.id ??
            "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].securityImprovement?.stairsAgainstFallingRiskMedia?.id ??
            "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].securityImprovement?.lightningStrikeProtectionMedia?.id ??
            "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].securityImprovement?.glazingAgainstRiskOfImpactMedia?.id ??
            "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].securityImprovement?.poolsAgainstRiskOfDrowningMedia?.id ??
            "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].securityImprovement?.othersInProvisionOfElementsMedia?.id ??
            "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].securityImprovement?.parkingAgainstRiskOfRunOverMedia?.id ??
            "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].securityImprovement?.flooringAgainstRiskOfFallingMedia
            ?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].securityImprovement?.glazingAgainstRiskInItsCleaningMedia
            ?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].securityImprovement?.accessibilityElementsInformationMedia
            ?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].securityImprovement
            ?.flooringAgainstRiskOfFallingDueToIsolatedStepsMedia?.id ?? "",
        ].filter((value) => {
          if (value.trim() !== "" && value.trim() !== undefined) return value;
        })
      : [];

  const fireSecurityMedias =
    book.secondBlock?.improvementActions && book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1]
      ? [
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].fireSafetyImprovement?.fireProtectionsMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].fireSafetyImprovement?.stairsProtectionMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].fireSafetyImprovement
            ?.partitioningOfSpecialRiskLocationsMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].fireSafetyImprovement
            ?.partitioningOfSpecialRiskLocationsMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].fireSafetyImprovement
            ?.fireReactionWithMaterialsOnTheFacadeMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].fireSafetyImprovement
            ?.fireReactionWithMaterialsOnTheCommonPlacesMedia?.id ?? "",
        ].filter((value) => {
          if (value.trim() !== "" && value.trim() !== undefined) return value;
        })
      : [];

  const healthynessMedias =
    book.secondBlock?.improvementActions && book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1]
      ? [
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].healthinessImprovement?.houseAiringMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].healthinessImprovement?.garageAiringMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].healthinessImprovement?.dripIrrigationMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].healthinessImprovement?.storageRoomAiringMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].healthinessImprovement?.commonWasteStorageMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].healthinessImprovement?.poolWaterTreatmentMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].healthinessImprovement?.waterSavingDevicesMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].healthinessImprovement?.wasteWarehouseAiringMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].healthinessImprovement?.waterSupplyConditionsMedia?.id ??
            "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].healthinessImprovement?.waterTreatmentSystemsMedia?.id ??
            "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].healthinessImprovement?.commonZonesVentilationMedia?.id ??
            "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].healthinessImprovement?.smellOnCommonWasteStorageMedia
            ?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].healthinessImprovement?.waterSavingDevicesOnHousesMedia
            ?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].healthinessImprovement?.protectionMeasuresOnRiskZonesMedia
            ?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].healthinessImprovement?.singularElementsWaterTreatmentMedia
            ?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].healthinessImprovement?.waterMeteringIndividualDevicesMedia
            ?.id ?? "",
        ].filter((value) => {
          if (value.trim() !== "" && value.trim() !== undefined) return value;
        })
      : [];

  const enEfficiencyMedias =
    book.secondBlock?.improvementActions && book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1]
      ? [
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].enEfficiencyImprovement?.ageOfInstallationMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].enEfficiencyImprovement?.automaticSystemsMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].enEfficiencyImprovement?.circulatingPumpsMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].enEfficiencyImprovement?.consumptionAccountingMedia?.id ??
            "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].enEfficiencyImprovement?.energyCertificationMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].enEfficiencyImprovement
            ?.energyPerformanceAnalysisOnRealConditionsMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].enEfficiencyImprovement
            ?.energySavingLightingOnCommonZonesMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].enEfficiencyImprovement?.highEfficiencyElevatorMedia?.id ??
            "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].enEfficiencyImprovement
            ?.installationPowerGenerationSystemsMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].enEfficiencyImprovement?.lightingControlSystemsMedia?.id ??
            "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].enEfficiencyImprovement?.pipeIsolationMedia?.id ?? "",
        ].filter((value) => {
          if (value.trim() !== "" && value.trim() !== undefined) return value;
        })
      : [];

  const noiseProtectionMedias =
    book.secondBlock?.improvementActions && book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1]
      ? [
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].noiseImprovement?.otherNoisesMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].noiseImprovement?.noiseOnTheDayMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].noiseImprovement?.noisesFromFacilitiesMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].noiseImprovement?.otherNoisesFromOutsideMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].noiseImprovement?.noisesFromOtherVehiclesMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].noiseImprovement?.noisesFromOtherBuildingsMedia?.id ?? "",
        ].filter((value) => {
          if (value.trim() !== "" && value.trim() !== undefined) return value;
        })
      : [];

  const otherMedias =
    book.secondBlock?.improvementActions && book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1]
      ? [
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].otherImprovement?.greenRoofMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].otherImprovement?.parkSituationMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].otherImprovement?.rainWaterSavingMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].otherImprovement?.asbestosPresenceMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].otherImprovement?.darkZonesOnFacadeMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].otherImprovement?.electricConditionMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].otherImprovement?.telecomConditionsMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].otherImprovement?.ageOfInstallationsMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].otherImprovement?.facadeDeficienciesMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].otherImprovement?.electricConditionMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].otherImprovement?.zoneOfElectricChargeMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].otherImprovement?.consumptionAccountingMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].otherImprovement?.digitizationAndMonitoringMedia?.id ?? "",
          book.secondBlock?.improvementActions[book.secondBlock?.improvementActions.length - 1].otherImprovement?.permeableExteriorSurfacesMedia?.id ?? "",
        ].filter((value) => {
          if (value.trim() !== "" && value.trim() !== undefined) return value;
        })
      : [];

  const downloadProceedingsAndLicenses = async (zip: JSZip) => {
    const proceedingsMedias = (book.firstBlock?.buildingInspection?.documentation?.buildingDescription?.proceedings
      ?.map((p: any) => p.files?.map((f: any) => f.id))
      .flat() ?? []) as string[];
    const licencesMedias = (book.firstBlock?.buildingInspection?.documentation?.buildingDescription?.licenses
      ?.map((p: any) => p.files?.map((f: any) => f.id))
      .flat() ?? []) as string[];
    const medias = [...proceedingsMedias, ...licencesMedias];
    if (medias.length === 0) return;
    await downloadZipLocal(zip, medias, vm.translate("profile.document.pdf.general.data.administrative.doc"));
  };

  const downloadAccesibilityAttachments = async (zip: JSZip) => {
    if (book.firstBlock?.buildingInspection?.accessibilityEvaluations?.length === 0) return;
    if (
      book.firstBlock?.buildingInspection?.accessibilityEvaluations![book.firstBlock?.buildingInspection?.accessibilityEvaluations!.length - 1]!.media
        ?.length === 0
    )
      return;
    const medias = (book.firstBlock?.buildingInspection?.accessibilityEvaluations?.[
      book.firstBlock?.buildingInspection?.accessibilityEvaluations?.length - 1
    ]?.media
      ?.map((m: any) => m.media)
      .flat() ?? []) as string[];
    if (medias.length === 0) return;
    await downloadZipLocal(zip, medias, vm.translate("profile.document.pdf.general.data.accesibility.doc"));
  };

  const downloadBase64 = (base64: string, name: string) => {
    const anchorElement = document.createElement("a");
    anchorElement.href = "data:application/zip;base64," + base64;
    anchorElement.download = `${name + "-"}.zip`;
    anchorElement.click();
    anchorElement.remove();
  };

  const addFilesToFolder = (zip: JSZip, files: File[], name: string) => {
    zip.folder(name);
    files.forEach((file) => zip.file(`${name}/${file.name}`, file));
  };

  const downloadZipLocal = async (zip: JSZip, medias: string[], name: string) => {
    const multimedias = await transformIntoMultimedia(medias);
    const uniqueMedias = Array.from(new Set(multimedias?.map((a) => a?.url)))
      .map((url) => {
        return multimedias?.find((a) => a?.url === url);
      })
      .filter((a) => a !== undefined);
    const files = await transformIntoFile((uniqueMedias as MultimediaDTO[]) ?? []);
    if (files.length > 0) addFilesToFolder(zip, files, name);
  };

  const downloadITEIEEAndOtherAttachments = async (zip: JSZip) => {
    const iteMedias = (book.summary?.iteDocuments?.map((p: any) => p?.id).flat() ?? []) as string[];
    const ieeMedias = (book.summary?.ieeDocuments?.map((p: any) => p?.id).flat() ?? []) as string[];
    const otherMedias = (book.summary?.otherDocuments?.map((p: any) => p?.id).flat() ?? []) as string[];
    const generalbuildingMedias = (book.firstBlock?.buildingInspection?.documentation?.generalData?.images?.map((p: any) => p?.id).flat() ??
      []) as string[];
    const urbanDataMedias = (book.generalData?.images?.map((p: any) => p?.id).flat() ?? []) as string[];
    const medias = Array.from(new Set([...iteMedias, ...ieeMedias, ...otherMedias, ...generalbuildingMedias, ...urbanDataMedias]));
    if (medias.length === 0) return;
    await downloadZipLocal(zip, medias, vm.translate("profile.document.pdf.general.data.ite.doc"));
  };

  const downloadImprovementsAttachments = async (zip: JSZip) => {
    const iteMedias = (book.summary?.improvements?.map((p) => p?.id).flat() ?? [])
      .concat(securityMedias)
      .concat(fireSecurityMedias)
      .concat(enEfficiencyMedias)
      .concat(healthynessMedias)
      .concat(noiseProtectionMedias)
      .concat(otherMedias) as string[];
    if (iteMedias.length === 0) return;
    await downloadZipLocal(zip, iteMedias, vm.translate("profile.document.pdf.general.data.improvements.summary.files.doc"));
  };

  const downloadLastInspectionShortcomingsAttachments = async (zip: JSZip) => {
    if (book.summary?.inspectionShortcomingsAttachments?.length === 0) return;
    const inspections = [...(book.summary?.inspectionShortcomingsAttachments ?? [])];
    if (inspections.length > 0) {
      const lastInspection = inspections;
      if (lastInspection?.length === 0) return;
      const shortcomingsMultimedias = (lastInspection?.map((m: MediaPrintedDTO) => m.id) ?? []) as string[];
      const medias = [...shortcomingsMultimedias];
      if (medias.length === 0) return;
      await downloadZipLocal(zip, medias, vm.translate("profile.document.pdf.general.data.last.inspection.doc"));
    }
  };

  const downloadMaintenanceContracts = async (zip: JSZip) => {
    if (book.firstBlock?.useAndMaintenance?.contracts === undefined) return;
    if (book.firstBlock?.useAndMaintenance?.contracts.length === 0) return;
    return await downloadZipExtendedMediaPrinted(
      book.firstBlock?.useAndMaintenance?.contracts,
      vm.translate("profile.document.pdf.general.data.maintenance.contracts"),
      zip
    );
  };

  const downloadActionRegistries = async (zip: JSZip) => {
    if (book.firstBlock?.useAndMaintenance?.actionRegistries === undefined) return;
    if (book.firstBlock?.useAndMaintenance?.actionRegistries?.length === 0) return;
    return await downloadZipExtendedMediaPrinted(
      book.firstBlock?.useAndMaintenance?.actionRegistries,
      vm.translate("profile.document.pdf.general.data.buiding.performance.record"),
      zip
    );
  };

  const downloadBestPractices = async (zip: JSZip) => {
    if (book.firstBlock?.useAndMaintenance?.bestPractices === undefined) return;
    if (book.firstBlock?.useAndMaintenance?.bestPractices?.length === 0) return;
    await downloadZipExtendedMediaPrinted(
      book.firstBlock?.useAndMaintenance?.bestPractices,
      vm.translate("profile.document.pdf.general.data.use.recommendations"),
      zip
    );
  };

  const downloadZipExtendedMediaPrinted = async (medias: MediaPrintedDTO[], name: string, zip: JSZip) => {
    const files = await transformIntoFile(medias);
    if (files.length > 0) addFilesToFolder(zip, files, name);
  };

  const createZips = async () => {
    const zip = new JSZip();
    await downloadProceedingsAndLicenses(zip);
    await downloadITEIEEAndOtherAttachments(zip);
    await downloadLastInspectionShortcomingsAttachments(zip);
    await downloadMaintenanceContracts(zip);
    await downloadActionRegistries(zip);
    await downloadImprovementsAttachments(zip);
    await downloadBestPractices(zip);
    await downloadAccesibilityAttachments(zip);
    const zipName =
      `Documentación-${book?.generalData?.buildingIdentification?.address?.streetName} - Nº ${book?.generalData?.buildingIdentification?.address?.portalNumber} ${book?.generalData?.buildingIdentification?.address?.postalCode} ${book?.generalData?.buildingIdentification?.address?.town}`
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    zip
      .generateAsync({ type: "base64" })
      .then(function (content) {
        downloadBase64(content, zipName);
      })
      .catch((error) => {});
  };

  return { createZips };
};
