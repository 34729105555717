import { GetParcelForCadastralReferenceRequest } from "../../../clients";
import { CadastralInfoApi as ParcelServiceApi } from '../../../clients/apis/CadastralInfoApi';
import { BaseAPI, Configuration } from '../../../clients/runtime';
import Parcel from '../../entities/parcel-entity';
import { IParcelApi } from '../iparcel-api';

export class ParcelsApi extends BaseAPI implements IParcelApi{
  apiClient: ParcelServiceApi

  constructor(configuration?: Configuration) {
    super(configuration);
    this.apiClient = new ParcelServiceApi(configuration);
  }

  async getParcelByCadastralReference(reference: string, refresh: boolean = false): Promise<Parcel> {
    const response = refresh 
        ? await this.apiClient.refreshParcelForCadastralReference(
            { reference: reference } as GetParcelForCadastralReferenceRequest,
            undefined
        )
        : await this.apiClient.getParcelForCadastralReference(
            { reference: reference } as GetParcelForCadastralReferenceRequest,
            undefined
        );
    return Parcel.fromClientParcel(response)
  }
}