import { StreetDTO as StreetClient } from "../../clients";

export default class Street {

    code: string;
    id: string;
    name: string;
    type: string;

    constructor(
        code: string,
        id: string,
        name: string,
        type: string
    ) {
        this.code = code;
        this.id = id;
        this.name = name;
        this.type = type;
    }

    static fromClientStreet(street: StreetClient) {
        return new Street(
            street.code ?? "",
            street.id!,
            street.name ?? "",
            street.type ?? ""
        );
    }

    static toClientStreet(street: Street) {
        const res: StreetClient = {};

        res.code = street.code;
        res.id = street.id;
        res.name = street.name;
        res.type = street.type;

        return res;
    }

    static fromClientStreetList(streets: StreetClient[]) {
        return streets.map(this.fromClientStreet);
    }
}