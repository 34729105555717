import React from "react"
import { observer } from "mobx-react-lite"
import ProfileViewModel from "../../../viewmodels/profile/profile-view-model"
import { ProfileUserStructureSystemAdmin } from "./profile-form-structure-system-admin"

export const ProfileFormSystemAdmin: React.FC<{ vm: ProfileViewModel }> = observer(({ vm }) => {
  return (
    <React.Fragment>
      <ProfileUserStructureSystemAdmin vm={vm} />
      { /*<ProfilePassword vm={vm} />*/}
    </React.Fragment>
  )
})