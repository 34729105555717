/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnergyEfficiencyImprovementPrintedDTO,
    EnergyEfficiencyImprovementPrintedDTOFromJSON,
    EnergyEfficiencyImprovementPrintedDTOFromJSONTyped,
    EnergyEfficiencyImprovementPrintedDTOToJSON,
    FireSafetyImprovementPrintedDTO,
    FireSafetyImprovementPrintedDTOFromJSON,
    FireSafetyImprovementPrintedDTOFromJSONTyped,
    FireSafetyImprovementPrintedDTOToJSON,
    HealthinessImprovementPrintedDTO,
    HealthinessImprovementPrintedDTOFromJSON,
    HealthinessImprovementPrintedDTOFromJSONTyped,
    HealthinessImprovementPrintedDTOToJSON,
    ImprovementDTO,
    ImprovementDTOFromJSON,
    ImprovementDTOFromJSONTyped,
    ImprovementDTOToJSON,
    MediaPrintedDTO,
    MediaPrintedDTOFromJSON,
    MediaPrintedDTOFromJSONTyped,
    MediaPrintedDTOToJSON,
    NoiseImprovementPrintedDTO,
    NoiseImprovementPrintedDTOFromJSON,
    NoiseImprovementPrintedDTOFromJSONTyped,
    NoiseImprovementPrintedDTOToJSON,
    OtherImprovementPrintedDTO,
    OtherImprovementPrintedDTOFromJSON,
    OtherImprovementPrintedDTOFromJSONTyped,
    OtherImprovementPrintedDTOToJSON,
    PhaseDTO,
    PhaseDTOFromJSON,
    PhaseDTOFromJSONTyped,
    PhaseDTOToJSON,
    SecurityImprovementPrintedDTO,
    SecurityImprovementPrintedDTOFromJSON,
    SecurityImprovementPrintedDTOFromJSONTyped,
    SecurityImprovementPrintedDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ImprovementActionPrintedDTO
 */
export interface ImprovementActionPrintedDTO {
    /**
     * 
     * @type {EnergyEfficiencyImprovementPrintedDTO}
     * @memberof ImprovementActionPrintedDTO
     */
    enEfficiencyImprovement?: EnergyEfficiencyImprovementPrintedDTO;
    /**
     * 
     * @type {FireSafetyImprovementPrintedDTO}
     * @memberof ImprovementActionPrintedDTO
     */
    fireSafetyImprovement?: FireSafetyImprovementPrintedDTO;
    /**
     * 
     * @type {HealthinessImprovementPrintedDTO}
     * @memberof ImprovementActionPrintedDTO
     */
    healthinessImprovement?: HealthinessImprovementPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof ImprovementActionPrintedDTO
     */
    id?: string;
    /**
     * 
     * @type {Array<MediaPrintedDTO>}
     * @memberof ImprovementActionPrintedDTO
     */
    improvementActionMedias?: Array<MediaPrintedDTO>;
    /**
     * 
     * @type {Array<ImprovementDTO>}
     * @memberof ImprovementActionPrintedDTO
     */
    improvements?: Array<ImprovementDTO>;
    /**
     * 
     * @type {NoiseImprovementPrintedDTO}
     * @memberof ImprovementActionPrintedDTO
     */
    noiseImprovement?: NoiseImprovementPrintedDTO;
    /**
     * 
     * @type {OtherImprovementPrintedDTO}
     * @memberof ImprovementActionPrintedDTO
     */
    otherImprovement?: OtherImprovementPrintedDTO;
    /**
     * 
     * @type {Array<PhaseDTO>}
     * @memberof ImprovementActionPrintedDTO
     */
    phases?: Array<PhaseDTO>;
    /**
     * 
     * @type {SecurityImprovementPrintedDTO}
     * @memberof ImprovementActionPrintedDTO
     */
    securityImprovement?: SecurityImprovementPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof ImprovementActionPrintedDTO
     */
    summary?: string;
    /**
     * 
     * @type {number}
     * @memberof ImprovementActionPrintedDTO
     */
    timestamp?: number;
}

export function ImprovementActionPrintedDTOFromJSON(json: any): ImprovementActionPrintedDTO {
    return ImprovementActionPrintedDTOFromJSONTyped(json, false);
}

export function ImprovementActionPrintedDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImprovementActionPrintedDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enEfficiencyImprovement': !exists(json, 'enEfficiencyImprovement') ? undefined : EnergyEfficiencyImprovementPrintedDTOFromJSON(json['enEfficiencyImprovement']),
        'fireSafetyImprovement': !exists(json, 'fireSafetyImprovement') ? undefined : FireSafetyImprovementPrintedDTOFromJSON(json['fireSafetyImprovement']),
        'healthinessImprovement': !exists(json, 'healthinessImprovement') ? undefined : HealthinessImprovementPrintedDTOFromJSON(json['healthinessImprovement']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'improvementActionMedias': !exists(json, 'improvementActionMedias') ? undefined : ((json['improvementActionMedias'] as Array<any>).map(MediaPrintedDTOFromJSON)),
        'improvements': !exists(json, 'improvements') ? undefined : ((json['improvements'] as Array<any>).map(ImprovementDTOFromJSON)),
        'noiseImprovement': !exists(json, 'noiseImprovement') ? undefined : NoiseImprovementPrintedDTOFromJSON(json['noiseImprovement']),
        'otherImprovement': !exists(json, 'otherImprovement') ? undefined : OtherImprovementPrintedDTOFromJSON(json['otherImprovement']),
        'phases': !exists(json, 'phases') ? undefined : ((json['phases'] as Array<any>).map(PhaseDTOFromJSON)),
        'securityImprovement': !exists(json, 'securityImprovement') ? undefined : SecurityImprovementPrintedDTOFromJSON(json['securityImprovement']),
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'timestamp': !exists(json, 'timestamp') ? undefined : json['timestamp'],
    };
}

export function ImprovementActionPrintedDTOToJSON(value?: ImprovementActionPrintedDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enEfficiencyImprovement': EnergyEfficiencyImprovementPrintedDTOToJSON(value.enEfficiencyImprovement),
        'fireSafetyImprovement': FireSafetyImprovementPrintedDTOToJSON(value.fireSafetyImprovement),
        'healthinessImprovement': HealthinessImprovementPrintedDTOToJSON(value.healthinessImprovement),
        'id': value.id,
        'improvementActionMedias': value.improvementActionMedias === undefined ? undefined : ((value.improvementActionMedias as Array<any>).map(MediaPrintedDTOToJSON)),
        'improvements': value.improvements === undefined ? undefined : ((value.improvements as Array<any>).map(ImprovementDTOToJSON)),
        'noiseImprovement': NoiseImprovementPrintedDTOToJSON(value.noiseImprovement),
        'otherImprovement': OtherImprovementPrintedDTOToJSON(value.otherImprovement),
        'phases': value.phases === undefined ? undefined : ((value.phases as Array<any>).map(PhaseDTOToJSON)),
        'securityImprovement': SecurityImprovementPrintedDTOToJSON(value.securityImprovement),
        'summary': value.summary,
        'timestamp': value.timestamp,
    };
}

