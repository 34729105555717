/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InspectionItemPrintedDTO,
    InspectionItemPrintedDTOFromJSON,
    InspectionItemPrintedDTOFromJSONTyped,
    InspectionItemPrintedDTOToJSON,
    MediaPrintedDTO,
    MediaPrintedDTOFromJSON,
    MediaPrintedDTOFromJSONTyped,
    MediaPrintedDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface InspectionPrintedDTO
 */
export interface InspectionPrintedDTO {
    /**
     * 
     * @type {string}
     * @memberof InspectionPrintedDTO
     */
    date?: string;
    /**
     * 
     * @type {InspectionItemPrintedDTO}
     * @memberof InspectionPrintedDTO
     */
    inspection?: InspectionItemPrintedDTO;
    /**
     * 
     * @type {Array<MediaPrintedDTO>}
     * @memberof InspectionPrintedDTO
     */
    multimedias?: Array<MediaPrintedDTO>;
}

export function InspectionPrintedDTOFromJSON(json: any): InspectionPrintedDTO {
    return InspectionPrintedDTOFromJSONTyped(json, false);
}

export function InspectionPrintedDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectionPrintedDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : json['date'],
        'inspection': !exists(json, 'inspection') ? undefined : InspectionItemPrintedDTOFromJSON(json['inspection']),
        'multimedias': !exists(json, 'multimedias') ? undefined : ((json['multimedias'] as Array<any>).map(MediaPrintedDTOFromJSON)),
    };
}

export function InspectionPrintedDTOToJSON(value?: InspectionPrintedDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'inspection': InspectionItemPrintedDTOToJSON(value.inspection),
        'multimedias': value.multimedias === undefined ? undefined : ((value.multimedias as Array<any>).map(MediaPrintedDTOToJSON)),
    };
}

