import { ActivateTechnicianRequest, DeactivateTechnicianRequest, SearchAgentByCredentialsIdRequest, SearchAgentRequest, TechnicianDeletionRequest, TechnicianInvitationRequest, TechnicianSignUpApprovalRequest, TechnicianSignUpRejectionRequest, TechnicianSignUpRequestRequest, TechnicianUpdateRequest, UpdateAgentRequest } from "../../../clients";
import { Api } from "../../../infrastructure/data/api-client";
import { BaseRepository } from "../../../infrastructure/data/repositories/base-repository";
import IUserManagementApi from "../iuser-management-api";

export default class UserManagementRepository extends BaseRepository<IUserManagementApi> {

  constructor() { super(Api.UserManagementApi, false); }

  async searchAgentByCredentialsId(requestParameters: SearchAgentByCredentialsIdRequest) {
    const client = await this.apiClient;
    return await client.searchAgentByCredentialsId(requestParameters);
  }

  async updateAgent(requestParameters: UpdateAgentRequest) {
    const client = await this.apiClient;
    return await client.updateAgent(requestParameters);
  }

  async technicianDeletion(requestParameters: TechnicianDeletionRequest) {
    const client = await this.apiClient;
    return await client.technicianDeletion(requestParameters);
  }

  async technicianSignUpApproval(requestParameters: TechnicianSignUpApprovalRequest) {
    const client = await this.apiClient;
    return await client.technicianSignUpApproval(requestParameters);
  }

  async technicianSignUpRejection(requestParameters: TechnicianSignUpRejectionRequest) {
    const client = await this.apiClient;
    return await client.technicianSignUpRejection(requestParameters);
  }

  async technicianSignUpRequest(requestParameters: TechnicianSignUpRequestRequest) {
    const client = await this.apiClient;
    return await client.technicianSignUpRequest(requestParameters);
  }

  async technicianUpdate(requestParameters: TechnicianUpdateRequest) {
    const client = await this.apiClient;
    return await client.technicianUpdate(requestParameters);
  }

  async searchAgent(requestParameters: SearchAgentRequest) {
    const client = await this.apiClient;
    return await client.searchAgent(requestParameters);
  }

  async technicianInvitation(requestParameters: TechnicianInvitationRequest) {
    const client = await this.apiClient;
    return await client.technicianInvitation(requestParameters);
  }

  async getAllTechniciansByCollege(requestParameters: RequestInit) {
    const client = await this.apiClient;
    return await client.getAllTechniciansByCollege(requestParameters);
  }

  async activateTechnician(requestParameters: ActivateTechnicianRequest) {
    const client = await this.apiClient
    return await client.activateTechnician(requestParameters);
  }

  async deactivateTechnician(requestParameters: DeactivateTechnicianRequest) {
    const client = await this.apiClient
    return await client.deactivateTechnician(requestParameters)
  }


}