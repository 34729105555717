/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CollegeDTO,
    CollegeDTOFromJSON,
    CollegeDTOFromJSONTyped,
    CollegeDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface CollegeAdministratorDTO
 */
export interface CollegeAdministratorDTO {
    /**
     * 
     * @type {boolean}
     * @memberof CollegeAdministratorDTO
     */
    active?: boolean;
    /**
     * 
     * @type {CollegeDTO}
     * @memberof CollegeAdministratorDTO
     */
    college?: CollegeDTO;
    /**
     * 
     * @type {string}
     * @memberof CollegeAdministratorDTO
     */
    credentials?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CollegeAdministratorDTO
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CollegeAdministratorDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeAdministratorDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CollegeAdministratorDTO
     */
    tenant?: string;
}

export function CollegeAdministratorDTOFromJSON(json: any): CollegeAdministratorDTO {
    return CollegeAdministratorDTOFromJSONTyped(json, false);
}

export function CollegeAdministratorDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollegeAdministratorDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'active': !exists(json, 'active') ? undefined : json['active'],
        'college': !exists(json, 'college') ? undefined : CollegeDTOFromJSON(json['college']),
        'credentials': !exists(json, 'credentials') ? undefined : json['credentials'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'tenant': !exists(json, 'tenant') ? undefined : json['tenant'],
    };
}

export function CollegeAdministratorDTOToJSON(value?: CollegeAdministratorDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'active': value.active,
        'college': CollegeDTOToJSON(value.college),
        'credentials': value.credentials,
        'deleted': value.deleted,
        'email': value.email,
        'id': value.id,
        'tenant': value.tenant,
    };
}

