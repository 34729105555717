/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AgentParticipationDTO
 */
export interface AgentParticipationDTO {
    /**
     * 
     * @type {string}
     * @memberof AgentParticipationDTO
     */
    block?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentParticipationDTO
     */
    book?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentParticipationDTO
     */
    community?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentParticipationDTO
     */
    door?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentParticipationDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentParticipationDTO
     */
    floor?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentParticipationDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentParticipationDTO
     */
    idNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentParticipationDTO
     */
    mobilePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentParticipationDTO
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof AgentParticipationDTO
     */
    participation?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentParticipationDTO
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentParticipationDTO
     */
    portalNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentParticipationDTO
     */
    portalNumberLetter?: string;
    /**
     * 
     * @type {number}
     * @memberof AgentParticipationDTO
     */
    postalCode?: number;
    /**
     * 
     * @type {string}
     * @memberof AgentParticipationDTO
     */
    province?: string;
    /**
     * 
     * @type {AgentParticipationDTO}
     * @memberof AgentParticipationDTO
     */
    representer?: AgentParticipationDTO;
    /**
     * 
     * @type {AgentParticipationDTO}
     * @memberof AgentParticipationDTO
     */
    representing?: AgentParticipationDTO;
    /**
     * 
     * @type {string}
     * @memberof AgentParticipationDTO
     */
    role?: AgentParticipationDTORoleEnum;
    /**
     * 
     * @type {string}
     * @memberof AgentParticipationDTO
     */
    secondaryPortalLetter?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentParticipationDTO
     */
    secondaryPortalNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentParticipationDTO
     */
    stair?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentParticipationDTO
     */
    streetCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentParticipationDTO
     */
    streetName?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentParticipationDTO
     */
    streetType?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentParticipationDTO
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentParticipationDTO
     */
    town?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentParticipationDTO
     */
    townName?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentParticipationDTO
     */
    type?: AgentParticipationDTOTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum AgentParticipationDTORoleEnum {
    HomeownersPresident = 'HOMEOWNERS_PRESIDENT',
    Owner = 'OWNER',
    PropertyManager = 'PROPERTY_MANAGER',
    Representative = 'REPRESENTATIVE',
    Technician = 'TECHNICIAN'
}/**
* @export
* @enum {string}
*/
export enum AgentParticipationDTOTypeEnum {
    Legal = 'LEGAL',
    Physical = 'PHYSICAL'
}

export function AgentParticipationDTOFromJSON(json: any): AgentParticipationDTO {
    return AgentParticipationDTOFromJSONTyped(json, false);
}

export function AgentParticipationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgentParticipationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'block': !exists(json, 'block') ? undefined : json['block'],
        'book': !exists(json, 'book') ? undefined : json['book'],
        'community': !exists(json, 'community') ? undefined : json['community'],
        'door': !exists(json, 'door') ? undefined : json['door'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'floor': !exists(json, 'floor') ? undefined : json['floor'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'idNumber': !exists(json, 'idNumber') ? undefined : json['idNumber'],
        'mobilePhone': !exists(json, 'mobilePhone') ? undefined : json['mobilePhone'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'participation': !exists(json, 'participation') ? undefined : json['participation'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'portalNumber': !exists(json, 'portalNumber') ? undefined : json['portalNumber'],
        'portalNumberLetter': !exists(json, 'portalNumberLetter') ? undefined : json['portalNumberLetter'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'province': !exists(json, 'province') ? undefined : json['province'],
        'representer': !exists(json, 'representer') ? undefined : AgentParticipationDTOFromJSON(json['representer']),
        'representing': !exists(json, 'representing') ? undefined : AgentParticipationDTOFromJSON(json['representing']),
        'role': !exists(json, 'role') ? undefined : json['role'],
        'secondaryPortalLetter': !exists(json, 'secondaryPortalLetter') ? undefined : json['secondaryPortalLetter'],
        'secondaryPortalNumber': !exists(json, 'secondaryPortalNumber') ? undefined : json['secondaryPortalNumber'],
        'stair': !exists(json, 'stair') ? undefined : json['stair'],
        'streetCode': !exists(json, 'streetCode') ? undefined : json['streetCode'],
        'streetName': !exists(json, 'streetName') ? undefined : json['streetName'],
        'streetType': !exists(json, 'streetType') ? undefined : json['streetType'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'town': !exists(json, 'town') ? undefined : json['town'],
        'townName': !exists(json, 'townName') ? undefined : json['townName'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function AgentParticipationDTOToJSON(value?: AgentParticipationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'block': value.block,
        'book': value.book,
        'community': value.community,
        'door': value.door,
        'email': value.email,
        'floor': value.floor,
        'id': value.id,
        'idNumber': value.idNumber,
        'mobilePhone': value.mobilePhone,
        'name': value.name,
        'participation': value.participation,
        'phone': value.phone,
        'portalNumber': value.portalNumber,
        'portalNumberLetter': value.portalNumberLetter,
        'postalCode': value.postalCode,
        'province': value.province,
        'representer': AgentParticipationDTOToJSON(value.representer),
        'representing': AgentParticipationDTOToJSON(value.representing),
        'role': value.role,
        'secondaryPortalLetter': value.secondaryPortalLetter,
        'secondaryPortalNumber': value.secondaryPortalNumber,
        'stair': value.stair,
        'streetCode': value.streetCode,
        'streetName': value.streetName,
        'streetType': value.streetType,
        'surname': value.surname,
        'town': value.town,
        'townName': value.townName,
        'type': value.type,
    };
}

