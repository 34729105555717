import { MultimediaDTO } from "../../../clients";
import { Api } from "../../../infrastructure/data/api-client";
import { BaseRepository } from "../../../infrastructure/data/repositories/base-repository";
import { IMultimediaApi } from "../imultimedia-api";

export class MultimediaRepository extends BaseRepository<IMultimediaApi> {

  constructor() {
    super(Api.MediaApi, false)
  }

  createExternalmedia = async (media: MultimediaDTO) => {
    return await (await this.apiClient).createExternalMedia(media);
  }

  getAllMedia = async (offset: number, limit: number) => {
    return await (await this.apiClient).getAllMedia(offset, limit);
  }

  createMedia = async (mediaFile: Blob, name: string = "") => {
    return await (await this.apiClient).createMedia(mediaFile, name);
  }

  getMediaById = async (id: string) => {
    return await (await this.apiClient).getMediaById(id);
  }

  updateMediaById = async (id: string, maintainFile: boolean, file: Blob) => {
    return await (await this.apiClient).updateMultimediaById(id, maintainFile, file);
  }

  deleteMediaById = async (id: string, mantainFile: boolean) => {
    return await (await this.apiClient).deleteMultimediaById(id, mantainFile);
  }
}