import { DialogMovicoders } from "@movicoders/movicoders-components";
import { ConfirmDialogStrings } from "@movicoders/movicoders-components";
import { observer } from "mobx-react-lite";
import React from "react";
import ReportViewModel from "../../../../../../../viewmodels/report/report-view-model";
import { Typography } from "@mui/material";
import { Colors } from "../../../../../../../constants";

export const UsagesEditWarningDialog: React.FC<{
  vm: ReportViewModel;
  fillUsagesPaged: () => void;
}> = observer(({ vm }) => {
  const dialogStrings: ConfirmDialogStrings = {
    title: vm.translate("usages.edit.warning.title"),
    content: <Typography>{vm.translate("usages.edit.warning.message")}</Typography>,
    confirmText: vm.translate("usages.edit.warning.continue"),
    cancelText: vm.translate("profile.user.cancel"),
  };

  return (
    <DialogMovicoders
      id="profileDialogMovicoders"
      open={true}
      buttonProps={{}}
      draggable
      dialogConfig={dialogStrings}
      onConfirm={async () => {
        vm.isWarningEditUsagesOpen = false;
        await vm
          .getUsagesByBookId({ id: vm.userBook.id ?? "" })
          .then((res) => {
            vm.buildingComposition.usages = res;
          })
          .catch((e) => console.log(e));
        vm.isOpenEditUsages = true;
      }}
      onClose={() => (vm.isWarningEditUsagesOpen = false)}
      closeWithX
      maxWidth={"md"}
      backgroundColorTitle={Colors.primary}
      fullWidth
    />
  );
});
