/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GroundHorizontalStructureDTO,
    GroundHorizontalStructureDTOFromJSON,
    GroundHorizontalStructureDTOFromJSONTyped,
    GroundHorizontalStructureDTOToJSON,
    HorizontalStructureDTO,
    HorizontalStructureDTOFromJSON,
    HorizontalStructureDTOFromJSONTyped,
    HorizontalStructureDTOToJSON,
    InnerElementsDTO,
    InnerElementsDTOFromJSON,
    InnerElementsDTOFromJSONTyped,
    InnerElementsDTOToJSON,
    RoofStructureDTO,
    RoofStructureDTOFromJSON,
    RoofStructureDTOFromJSONTyped,
    RoofStructureDTOToJSON,
    VerticalStructureDTO,
    VerticalStructureDTOFromJSON,
    VerticalStructureDTOFromJSONTyped,
    VerticalStructureDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface StructureDTO
 */
export interface StructureDTO {
    /**
     * 
     * @type {GroundHorizontalStructureDTO}
     * @memberof StructureDTO
     */
    groundHorizontalStructure?: GroundHorizontalStructureDTO;
    /**
     * 
     * @type {HorizontalStructureDTO}
     * @memberof StructureDTO
     */
    horizontalStructure?: HorizontalStructureDTO;
    /**
     * 
     * @type {InnerElementsDTO}
     * @memberof StructureDTO
     */
    innerElements?: InnerElementsDTO;
    /**
     * 
     * @type {string}
     * @memberof StructureDTO
     */
    notes?: string;
    /**
     * 
     * @type {RoofStructureDTO}
     * @memberof StructureDTO
     */
    roofStructure?: RoofStructureDTO;
    /**
     * 
     * @type {VerticalStructureDTO}
     * @memberof StructureDTO
     */
    verticalStructure?: VerticalStructureDTO;
}

export function StructureDTOFromJSON(json: any): StructureDTO {
    return StructureDTOFromJSONTyped(json, false);
}

export function StructureDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): StructureDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groundHorizontalStructure': !exists(json, 'groundHorizontalStructure') ? undefined : GroundHorizontalStructureDTOFromJSON(json['groundHorizontalStructure']),
        'horizontalStructure': !exists(json, 'horizontalStructure') ? undefined : HorizontalStructureDTOFromJSON(json['horizontalStructure']),
        'innerElements': !exists(json, 'innerElements') ? undefined : InnerElementsDTOFromJSON(json['innerElements']),
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'roofStructure': !exists(json, 'roofStructure') ? undefined : RoofStructureDTOFromJSON(json['roofStructure']),
        'verticalStructure': !exists(json, 'verticalStructure') ? undefined : VerticalStructureDTOFromJSON(json['verticalStructure']),
    };
}

export function StructureDTOToJSON(value?: StructureDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'groundHorizontalStructure': GroundHorizontalStructureDTOToJSON(value.groundHorizontalStructure),
        'horizontalStructure': HorizontalStructureDTOToJSON(value.horizontalStructure),
        'innerElements': InnerElementsDTOToJSON(value.innerElements),
        'notes': value.notes,
        'roofStructure': RoofStructureDTOToJSON(value.roofStructure),
        'verticalStructure': VerticalStructureDTOToJSON(value.verticalStructure),
    };
}

