/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TechnicianParticipationDTO
 */
export interface TechnicianParticipationDTO {
    /**
     * 
     * @type {string}
     * @memberof TechnicianParticipationDTO
     */
    college?: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicianParticipationDTO
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof TechnicianParticipationDTO
     */
    participation?: number;
}

export function TechnicianParticipationDTOFromJSON(json: any): TechnicianParticipationDTO {
    return TechnicianParticipationDTOFromJSONTyped(json, false);
}

export function TechnicianParticipationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TechnicianParticipationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'college': !exists(json, 'college') ? undefined : json['college'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'participation': !exists(json, 'participation') ? undefined : json['participation'],
    };
}

export function TechnicianParticipationDTOToJSON(value?: TechnicianParticipationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'college': value.college,
        'id': value.id,
        'participation': value.participation,
    };
}

