/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BuildingFacilitiesDTO,
    BuildingFacilitiesDTOFromJSON,
    BuildingFacilitiesDTOFromJSONTyped,
    BuildingFacilitiesDTOToJSON,
    FoundationDTO,
    FoundationDTOFromJSON,
    FoundationDTOFromJSONTyped,
    FoundationDTOToJSON,
    RoofAndEnclosuresDTO,
    RoofAndEnclosuresDTOFromJSON,
    RoofAndEnclosuresDTOFromJSONTyped,
    RoofAndEnclosuresDTOToJSON,
    StructureDTO,
    StructureDTOFromJSON,
    StructureDTOFromJSONTyped,
    StructureDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewConstructiveDescDTO
 */
export interface NewConstructiveDescDTO {
    /**
     * 
     * @type {BuildingFacilitiesDTO}
     * @memberof NewConstructiveDescDTO
     */
    facilities?: BuildingFacilitiesDTO;
    /**
     * 
     * @type {FoundationDTO}
     * @memberof NewConstructiveDescDTO
     */
    foundation?: FoundationDTO;
    /**
     * 
     * @type {RoofAndEnclosuresDTO}
     * @memberof NewConstructiveDescDTO
     */
    roofsAndEnclosures?: RoofAndEnclosuresDTO;
    /**
     * 
     * @type {StructureDTO}
     * @memberof NewConstructiveDescDTO
     */
    structure?: StructureDTO;
}

export function NewConstructiveDescDTOFromJSON(json: any): NewConstructiveDescDTO {
    return NewConstructiveDescDTOFromJSONTyped(json, false);
}

export function NewConstructiveDescDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewConstructiveDescDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'facilities': !exists(json, 'facilities') ? undefined : BuildingFacilitiesDTOFromJSON(json['facilities']),
        'foundation': !exists(json, 'foundation') ? undefined : FoundationDTOFromJSON(json['foundation']),
        'roofsAndEnclosures': !exists(json, 'roofsAndEnclosures') ? undefined : RoofAndEnclosuresDTOFromJSON(json['roofsAndEnclosures']),
        'structure': !exists(json, 'structure') ? undefined : StructureDTOFromJSON(json['structure']),
    };
}

export function NewConstructiveDescDTOToJSON(value?: NewConstructiveDescDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'facilities': BuildingFacilitiesDTOToJSON(value.facilities),
        'foundation': FoundationDTOToJSON(value.foundation),
        'roofsAndEnclosures': RoofAndEnclosuresDTOToJSON(value.roofsAndEnclosures),
        'structure': StructureDTOToJSON(value.structure),
    };
}

