import { SessionStoreFactory } from '../infrastructure/data/session-store-factory';
import CurrentUser from '../models/current-user';
import LocalStorageService from './local-storage-service';
export default class UserContextService {
	private _apiToken?: string | null;
	private _currentUser?: CurrentUser | null;
	private _isLoggedIn: boolean = false;

	constructor(private localStorageService: LocalStorageService) {
		this.getTokenFromStorage();
		this.getUserFromStorage();
		this._isLoggedIn = !!(this._apiToken && this._currentUser);
	}

	get isLoggedIn() {
		return this._isLoggedIn;
	}

	get user() {
		return {email: ""};
	}

	setUser(user: CurrentUser, apiToken: string) {
		this._currentUser = user;
		this._apiToken = apiToken;
		this._isLoggedIn = true;
		SessionStoreFactory.getSessionStore().setToken(apiToken);
		SessionStoreFactory.getSessionStore().setUser(user);
	}

	deleteUser() {
		this._currentUser = null;
		this._apiToken = null;
		this._isLoggedIn = false;
		SessionStoreFactory.getSessionStore().deleteToken();
		SessionStoreFactory.getSessionStore().deleteUser();
	}

	getTokenFromStorage() {
		this._apiToken = SessionStoreFactory.getSessionStore().getToken();
	}

	getUserFromStorage() {
		this._currentUser = {email:"afafds"};
	}

	setToken(token: string | null) {
		this._apiToken = "token";
	}

	get token() {
		return this._apiToken;
	}

	logout() {
		this.deleteUser();
	}
}
