import { useStylesBasicTable } from "@movicoders/movicoders-components/dist/tablebasic/TableBasic.styles";
import theme from "@movicoders/movicoders-components/dist/theme";
import { CssBaseline, LabelDisplayedRowsArgs, Paper, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import ProfileViewModel from "../../../viewmodels/profile/profile-view-model";
import { ProfileSearchForm } from "../profile-components/profile-filters";

interface BasicTableProps<T> {
  data: T[];
  columns: GridColDef[];
  title?: string;
  headerColor?: string;
  showSearchInput?: boolean;
  selectedRowSetter?: (row: T) => void;
  selectedRowsSetter?: (rows: T[]) => void;
  selectedModel?: string[];
  handleSelectionModel?: (model: string[]) => void;
  handlePageChange?: (page: number) => void;
  autoPageSize?: boolean;
  disableCheckboxes?: boolean;
  rowCount: number;
  customToolbar?: React.ReactNode;
  locale?: string;
  customSX?: React.CSSProperties;
  setPageSize: (pageSize: number) => void;
  pageSize: number;
  page: number;
  setPage: (page: number) => void;
  vm: ProfileViewModel;
}

export const BasicTable = <T,>({ ...params }: BasicTableProps<T>) => {
  const classes = useStylesBasicTable(theme);
  const [filterData, setFilterData] = useState<T[]>([]);

  const handleSelectedRows = (event: GridRowParams) => {
    if (params.selectedRowSetter) params.selectedRowSetter(event.row as T);
  };

  const noColumns: GridColDef[] = [];

  useEffect(() => {
    setFilterData(params.data);
  }, [params.data]);

  return (
    <div className={classes.basicTable_root}>
      <CssBaseline />
      <Paper className={classes.basicTable_content}>
        <Typography fontSize={25} color={params.headerColor || "#0F407A"}>
          {params.title}
        </Typography>
        <ProfileSearchForm vm={params.vm} />
        <div style={{ width: "100%" }}>
          <DataGrid
            rowCount={params.rowCount}
            autoHeight
            checkboxSelection={!params.disableCheckboxes}
            page={params.page}
            onPageChange={(page: any) => {
              params.handlePageChange && params.handlePageChange(page);
            }}
            selectionModel={params.selectedModel ?? []}
            onRowClick={(event) => handleSelectedRows(event)}
            sx={{ border: "none", ...params.customSX }}
            rows={filterData}
            autoPageSize={params.autoPageSize ? params.autoPageSize : false}
            paginationMode={params.vm?.profileTableServerSideFilter ? "server" : "client"}
            columns={params.columns ?? noColumns}
            pageSize={params.pageSize}
            rowsPerPageOptions={[5, 10, 20, 50]}
            onPageSizeChange={params.setPageSize}
            density={"standard"}
            disableColumnFilter={false}
            loading={!params.data}
            localeText={{
              MuiTablePagination: {
                translate: "yes",
                labelDisplayedRows: (paginationInfo: LabelDisplayedRowsArgs) => (
                  <>
                    {paginationInfo.from}-{paginationInfo.to} of {paginationInfo.count !== -1 ? paginationInfo.count : `more than ${paginationInfo.to}`}
                  </>
                ),
                labelRowsPerPage: params.locale === "es" ? "Filas por página: " : "Rows per page: ",
              },
              actionsCellMore: params.locale === "es" ? "mas" : "more",
              columnHeaderFiltersLabel: params.locale === "es" ? "Filtrar" : "Filter",
              columnHeaderFiltersTooltipActive: (count) => count,
              columnHeaderSortIconLabel: params.locale === "es" ? "Ordenar" : "Sort",
              columnMenuFilter: params.locale === "es" ? "Filtrar" : "Filter",
              columnMenuHideColumn: params.locale === "es" ? "Esconder columna" : "Hide columns",
              columnMenuLabel: params.locale === "es" ? "Opciones" : "Options",
              columnMenuShowColumns: params.locale === "es" ? "Mostrar columnas" : "Show columns",
              columnMenuSortAsc: params.locale === "es" ? "Ordernar asc" : "Short asc",
              columnMenuSortDesc: params.locale === "es" ? "Ordenar desc" : "Short desc",
              columnMenuUnsort: params.locale === "es" ? "Desordenar" : "Unshort",
              columnsPanelHideAllButton: params.locale === "es" ? "Esconder todos los botones" : "Hide all buton",
              columnsPanelShowAllButton: params.locale === "es" ? "Mostrar todos los botones" : "Show all buttons",
              columnsPanelTextFieldLabel: params.locale === "es" ? "Buscar columna" : "Search column name",
              columnsPanelTextFieldPlaceholder: params.locale === "es" ? "Nombre" : "Name",
              filterOperatorAfter: params.locale === "es" ? "Después" : "After",
              filterOperatorBefore: params.locale === "es" ? "Antes" : "Before",
              filterOperatorContains: params.locale === "es" ? "Contiene" : "Contains",
              filterOperatorEndsWith: params.locale === "es" ? "Termina con" : "Ends with",
              filterOperatorEquals: params.locale === "es" ? "Similar" : "Equals",
              filterOperatorIs: params.locale === "es" ? "es" : "Is",
              filterOperatorIsEmpty: params.locale === "es" ? "Esta vacio" : "Empty",
              filterOperatorIsNotEmpty: params.locale === "es" ? "No vacio" : "No empty",
              filterOperatorNot: params.locale === "es" ? "No" : "Not",
              filterOperatorStartsWith: params.locale === "es" ? "Compienza con" : "Start witch",
              filterPanelAddFilter: params.locale === "es" ? "Añadir filtro" : "Add filter",
              filterPanelColumns: params.locale === "es" ? "Columna" : "Columns",
              filterPanelDeleteIconLabel: params.locale === "es" ? "Eliminar" : "Delete",
              filterPanelInputLabel: params.locale === "es" ? "Búsqueda" : "Search",
              filterPanelInputPlaceholder: params.locale === "es" ? "Búesqueda" : "Search",
              filterPanelOperatorAnd: params.locale === "es" ? "&&" : "&&",
              filterPanelOperatorOr: params.locale === "es" ? "||" : "||",
              filterPanelOperators: params.locale === "es" ? "Tipo de filtro" : "Filter type",
              footerRowSelected: (count) => {
                return params.locale === "es" ? count + "fila seleccionada" : count + "row selecteddd";
              },
              footerTotalRows: params.locale === "es" ? "Filas totales" : "Total rows",
              noResultsOverlayLabel: params.locale === "es" ? "Sin resultados" : "No results",
              toolbarExport: params.locale === "es" ? "Exportar" : "Export",
              toolbarExportCSV: params.locale === "es" ? "Exportar CSV" : "Export CSV",
              toolbarExportLabel: params.locale === "es" ? "Exportar" : "Export",
              toolbarExportPrint: params.locale === "es" ? "Imprimir" : "Print",
            }}
          />
        </div>
      </Paper>
    </div>
  );
};
