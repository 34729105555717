import { Button, DialogMovicoders, Icon, Popover, TableBasic, TypographyTheme } from "@movicoders/movicoders-components"
import { ConfirmDialogStrings } from "@movicoders/movicoders-components"
import { IconsList } from "@movicoders/movicoders-components"
import { Grid } from "@mui/material"
import { observer } from "mobx-react-lite"
import { useSnackbar } from "notistack"
import React, { useState } from "react"
import { CollegeDTO, FullTechnicianParticipationDTO } from "../../../../../../clients"
import TextFieldGrid from "../../../../../../components/text-fields/textfield-grid"
import { Colors } from "../../../../../../constants"
import ReportViewModel from "../../../../../../viewmodels/report/report-view-model"
import { ReportStyle } from "../../../../report-style"
import { TTechnicianRedactors } from "../technician-redactors"

export const AddButtonsSection: React.FC<{ vm: ReportViewModel, props: TTechnicianRedactors }> = observer(({ vm, props }) => {
  const classes = ReportStyle()
  const [editDialog, setEditDialog] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)


  const { enqueueSnackbar } = useSnackbar();
  const handleOpenSnackBar = (snackKey: string, variants: undefined | "default" | "error" | "success" | "warning" | "info") => {
    enqueueSnackbar(snackKey, { variant: variants, preventDuplicate: true, key: snackKey, autoHideDuration: 3000 });
  }

  const EditDialogContent = () => {
    const [participation, setParticipation] = useState(vm.selectedTechnicianParticipation?.participation ?? 0)
    const [colleges, setColleges] = useState<CollegeDTO[]>([])
    React.useEffect(() => {
      vm.selectedTechnicianParticipation!.participation! = participation
    }, [participation])

    React.useEffect(() => {
      vm.parent.collegeRepository.getAllCollege({}).then((response) => {
        setColleges(response)
      });
    }, [])

    return (
      <React.Fragment>
        <Grid container xs={12} flexDirection="column">
          <Grid item container flexDirection={"row"} paddingTop={1}>
            <TextFieldGrid gridTextField={{ xs: 12, md: 12 }} onChange={() => { }} title={vm.translate("report.block0.technicanredactors.dialog.name")} disabled value={vm.selectedTechnicianParticipation?.name} id={"editTechnicianName"} />
          </Grid>
          {colleges?.find((element: any) => element.id === vm.selectedTechnicianParticipation?.college) ? <Grid item container alignItems={"center"} flexDirection={"row"} paddingTop={1}>
            <TextFieldGrid gridTextField={{ xs: 12, md: 12 }} onChange={() => { }} title={vm.translate("report.block0.technicanredactors.dialog.school")} disabled value={colleges?.find((element: any) => element.id === vm.selectedTechnicianParticipation?.college)?.name} width={"100%"} id={"editTechnicianCollege"} />
          </Grid> : null}
          <Grid item container alignItems={"center"} flexDirection={"row"} paddingTop={1}>
            <TextFieldGrid gridTextField={{ xs: 12, md: 12 }} required
              numeric
              onChange={(event: any) => setParticipation(event.target.value)}
              title={vm.translate("report.block0.technicanredactors.dialog.participation")}
              value={participation.toString()}
              id={"editTechnicianParticipation"} />
          </Grid>
          <Grid item container alignItems={"center"} flexDirection={"row"} paddingTop={1}>
            <Button
              onClick={async () => {
                if (await vm.editTechnicianParticipation()) {
                  setEditDialog(false);
                  handleOpenSnackBar(vm.translate("report.block0.technician.edit.success"), "success")
                }
                else {
                  handleOpenSnackBar(vm.translate("report.block0.technician.edit.error"), "error")
                }
              }}
              backgroundColor={Colors.buttonPrimary}
              text={vm.translate("report.block0.technicanredactors.dialog.save")}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  const DeleteDialogContent = () => {
    return (
      <React.Fragment>
        <Grid container xs={12} flexDirection="column">
          <Grid item container flexDirection={"row"} paddingTop={1}>
            <TextFieldGrid gridTextField={{ xs: 12, md: 12 }} onChange={() => { }} title={vm.translate("report.block0.technicanredactors.dialog.name")} disabled value={vm.selectedTechnicianParticipation?.name} id={"deleteTechnicianName"} />
          </Grid>
          <Grid item container alignItems={"center"} flexDirection={"row"} paddingTop={1}>
            <TextFieldGrid gridTextField={{ xs: 12, md: 12 }} onChange={() => { }} title={vm.translate("report.block0.technicanredactors.dialog.school")} disabled value={vm.parent.colleges?.find((element: any) => element.id === vm.selectedTechnicianParticipation?.college).name} width={"100%"} id={"deleteTechnicianCollege"} />
          </Grid>
          <Grid item container alignItems={"center"} flexDirection={"row"} paddingTop={1}>
            <TextFieldGrid gridTextField={{ xs: 12, md: 12 }} disabled title={vm.translate("report.block0.technicanredactors.dialog.participation")} defaultValue={vm.selectedTechnicianParticipation?.participation?.toString()} id={"deleteTechnicianParticipation"} />
          </Grid>
          <Grid item container alignItems={"center"} flexDirection={"row"} paddingTop={1}>
            <Button
              onClick={async () => {
                if (await vm.deleteTechnicianParticipation()) {
                  setDeleteDialog(false);
                  handleOpenSnackBar(vm.translate("report.block0.technician.delete.success"), "success")
                }
                else {
                  handleOpenSnackBar(vm.translate("report.block0.technician.delete.error"), "error")
                }
              }}
              backgroundColor={Colors.error}
              text={vm.translate("report.block0.technicanredactors.dialog.delete")}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  const editDialogStrings: ConfirmDialogStrings = {
    title: vm.translate("report.block0.technicianParticipation.edit"),
    content: <EditDialogContent />
  }

  const deleteDialogStrings: ConfirmDialogStrings = {
    title: vm.translate("report.block0.technicianParticipation.delete"),
    content: <DeleteDialogContent />
  }


  const optionPopover = (params: any) => {
    return <Popover
      content={[
        { id: 'editTechnicianParticipation', label: vm.translate("report.block0.technicianParticipation.edit"), action: () => { vm.selectedTechnicianParticipation = params.row; setEditDialog(true) } },
        { id: 'deleteTechnicianParticipation', label: vm.translate("report.block0.technicianParticipation.delete"), action: () => { vm.selectedTechnicianParticipation = params.row; setDeleteDialog(true) } },
      ]}
      iconButton={{
        id: params.row.id,
        icon: <Icon element={IconsList.MoreHoriz} />, backgroundColor: "transparent", color: 'black',
      }}
    />
  }



  return (
    <React.Fragment>
      <Grid item xs={12} md={12} className={classes.report_addUserGrid}>
        <Grid item xs={5} md={5} className={classes.report_addUsers}>
          <Grid item xs={12} md={12} style={{ display: 'inline-flex', alignItems: 'center' }} className={classes.report_displayGrid}>
            <TypographyTheme
              id="techniciansRedactorsAddUsersButton"
              classTypography={classes.report_addTechicianmini}
              color={Colors.textPrimary}
              text={vm.translate("report.block0.technician.addTechnicianButton")}
            />
            <Button
              id="technicianRedactorAddButton"
              onClick={() => { vm.profileTechnician = true }}
              backgroundColor={Colors.buttonPrimary} icon={<Icon element={IconsList.PersonAdd} />}
              iconPosition="start" classButton={classes.report_addButtonMini}
              text={''} />
          </Grid>
        </Grid>
        <div style={{ height: 10 }} />
        {vm.userBook.technicians!!.length &&
          <TableBasic
            rowsPerPageOptions={[5]}
            disableCheckboxes
            data={/* vm.bookTechnicians */vm.userBook.technicians!!}
            headerColor={Colors.primary}
            columns={[
              {
                field: 'name',
                headerAlign: 'left',
                headerName: vm.translate("report.block0.technicanredactors.dialog.name"),
                filterable: false,
                sortable: false,
                flex: 0.3
              },
              {
                field: 'e',
                headerAlign: 'left',
                headerName: vm.translate("report.block0.technicanredactors.dialog.school"),
                renderCell: (params) => <div>{vm.parent.colleges?.map((element: CollegeDTO) => (element?.id === params.row?.college) && <div>{element?.name}</div>)}</div>,
                filterable: false,
                sortable: false,
                flex: 0.5
              },
              {
                field: 'participation',
                headerAlign: 'left',
                headerName: vm.translate("report.block0.technicanredactors.dialog.participation"),
                renderCell: (params) => <div>{String((params.row as FullTechnicianParticipationDTO).participation).replaceAll(".", ",")}</div>,
                filterable: false,
                sortable: false,
                flex: 0.2
              },
              {
                headerAlign: 'left',
                align: 'left',
                field: '',
                hideSortIcons: true,
                headerName: '',
                flex: .01,
                disableColumnMenu: true,
                disableReorder: true,
                sortable: false,
                renderCell: optionPopover,
              }

            ]}
            locale={vm.configurationService.language}
          />}
        <DialogMovicoders
          open={editDialog}
          draggable
          dialogConfig={editDialogStrings}
          onConfirm={() => { }}
          onClose={() => setEditDialog(false)}
          closeWithX
          maxWidth={"sm"}
          backgroundColorTitle={Colors.primary}
          fullWidth
        />
        <DialogMovicoders
          open={deleteDialog}
          draggable
          dialogConfig={deleteDialogStrings}
          onConfirm={() => { }}
          onClose={() => setDeleteDialog(false)}
          closeWithX
          maxWidth={"sm"}
          backgroundColorTitle={Colors.primary}
          fullWidth
        />
      </Grid>
    </React.Fragment>
  )
})