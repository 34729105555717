import { Colors } from '../../constants'
import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'

export const DashboardStyle = makeStyles(() => createStyles({
  dashboard_additionalData: {
    '& .leaflet-container': {
      width: "100%",
      height: "600px !important"
    },
    zIndex: 0,
    position: "fixed",
    padding: 0,
    margin: 0,
    top: "49px",
    left: 0,
    width: "100%",
    height: "94%"
  },
  dashboard_popupButton: {
    cursor: 'pointer'
  },
  dashboard_iconButton: {
    backgroundColor: Colors.primary + " !important",
    zIndex: 1
  },
  dashboard_iconButtonOpen: {
    backgroundColor: "transparent"
  },
  dashboard_popover: {
    '& .MuiPopover-paper': {
      marginRight: "35px",
      padding: "13px",
      opacity: "90% !important",
      width: 300
    },

  },
  dashboard_button: {
    marginTop: 10,
    marginLeft: 112
  },
  dashboard_iconButtonDiv: {
    textAlign: "right",
    marginRight: "36px",
    marginTop: "36px"
  },
  dashboard_filterText: {
    fontSize: 16,
    fontFamily: "Helvetica",
    marginBottom: 12,
    color: Colors.textPrimary,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  dashboard_backdrop: {
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
    color: Colors.primary,
    alignItems: "center",
    justifyContent: "center"
  }
}))