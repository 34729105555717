import { TownsApi as TownServiceApi } from "../../../clients";
import { BaseAPI, Configuration } from "../../../clients/runtime";
import Street from "../../entities/street-entity";
import Town from "../../entities/town-entity";
import ITownApi from "../itowns-api";

export class TownApi extends BaseAPI implements ITownApi {
  apiClient: TownServiceApi;

  constructor(configuration?: Configuration) {
    super(configuration);
    this.apiClient = new TownServiceApi(configuration);
  }

  async getTowns(): Promise<Town[]> {
    const response = await this.apiClient.getTowns();
    return Town.fromClientTownList(response);
  }

  async getTownById(id: string): Promise<Town> {
    const response = await this.apiClient.getTownById({ id: id });
    return Town.fromClientTown(response);
  }

  async getStreetsByTownId(id: string): Promise<Street[]> {
    const response = await this.apiClient.getStreetsByTownId({ id: id });
    return Street.fromClientStreetList(response);
  }

  async getStreetsByTownIdAndName(id: string, name: string): Promise<Street[]> {
    const response = await this.apiClient.getStreetsByTownIdAndName({ id: id, name: name });
    return Street.fromClientStreetList(response);
  }
}
