import { FileUploader, Icon, IconButton, IconsList } from "@movicoders/movicoders-components";
import { Grid, ImageListItemBar, Typography, useMediaQuery } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { MultimediaDTO } from "../../../../../../clients";
import TypographyTitle from "../../../../../../components/typography-title";
import { Colors } from "../../../../../../constants";
import ReportViewModel from "../../../../../../viewmodels/report/report-view-model";
import { ReportStyle } from "../../../../report-style";
import { DeleteDialog } from "./DeleteDialog";
import { UploadFileDialog } from "./upload-file-dialog";
import { UploadImagesDialog } from "./upload-images-dialog";
import * as BookHelper from "../../../../../../utils/book-helper";
import { useSnackbar } from "notistack";

export const GraphicDocumentationSection: React.FC<{ vm: ReportViewModel }> = observer(({ vm }) => {
  const [fileToDelete, setFileToDelete] = useState<null | string | undefined>(null);
  const [images, setImages] = useState<MultimediaDTO[]>([]);

  const min = useMediaQuery("(min-width:742px)");
  const classes = ReportStyle();

  const { enqueueSnackbar } = useSnackbar();
  const handleOpenSnackBar = (snackKey: string, variants: undefined | "default" | "error" | "success" | "warning" | "info") => {
    enqueueSnackbar(snackKey, { variant: variants, preventDuplicate: true, key: snackKey, autoHideDuration: 3000 });
  };

  const onChangeFiles = async (files?: File[]) => {
    if (files?.length) {
      files.filter((file: File) => file !== undefined && file.name.includes("+" || "%")).length === 0
        ? (vm.graphicDocs = files)
        : handleOpenSnackBar("uploadNameError", "error");
      files = [];
    }
  };

  useEffect(() => {
    if (vm.parent.isManual === false)
      (async () => {
        setImages(
          await Promise.all(
            (vm.userBook.buildingComposition?.media
              ?.filter((med) => med?.id)
              ?.map(
                async (media) =>
                  await vm
                    .getMultimediaById(media.id!)
                    .then((res) => res)
                    .catch((err) => false)
              )
              .filter(Boolean) as MultimediaDTO[]) ?? []
          )
        );
      })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vm.userBook.buildingComposition?.media]);

  const deleteFile = async () => {
    try {
      if (!fileToDelete) return;
      if (vm.parent.isManual) {
        vm.removeImageFromBuildingComposition(fileToDelete);
        setImages(images.filter((item) => item.id !== fileToDelete));
        setFileToDelete(null);
        return;
      }
      const res = await vm.deleteFile(fileToDelete);
      if (res) {
        vm.removeImageFromBuildingComposition(fileToDelete);
        setImages(images.filter((item) => item.id !== fileToDelete));
      }
      setFileToDelete(null);
    } catch (err) {
      console.log(err);
      setFileToDelete(null);
    }
  };

  useEffect(() => {
    const docs = vm.graphicDocs.filter((docs) => {
      return images.includes(docs.name);
    });
    vm.graphicDocs = docs;
  }, [images]);

  const collectFrontPage = (m?: MultimediaDTO) => {
    if (m) vm.buildingComposition.media = [BookHelper.fromMultimediaToMedia(m), ...(vm.buildingComposition.media ?? [])];
  };

  const collectImagesPage = (m: MultimediaDTO[] | undefined) => {
    if (m) vm.buildingComposition.media = [...(vm.buildingComposition.media ?? []), ...BookHelper.fromMultimediaToMediaAll(m)];
  };

  useEffect(() => {
    if (vm.parent.isManual)
      (async () => {
        setImages(await Promise.all(vm.buildingComposition?.media?.map(async (media) => await vm.getMultimediaById(media.id!)) ?? []));
      })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vm.buildingComposition?.media]);

  return (
    <Grid item xs={12} md={12} className={classes.report_displayGridTitle}>
      <TypographyTitle id="graphicDocumentationTitle" marginTop title={vm.translate("report.block0.graphic.documentation")} />
      <div style={{ width: 1, height: 10 }} />
      {vm.parent.isManual ? (
        <>
          <Grid display="inline-flex">
            <UploadFileDialog id="juploadFrontPage" vm={vm} buttonText="Añadir portada" mRight=".5rem" collect={collectFrontPage} isPortrait={true} />
            <UploadImagesDialog vm={vm} buttonText="Añadir Imágenes" mRight=".5rem" collect={collectImagesPage} />
          </Grid>
          {images?.length !== 0 && (
            <ImageList sx={{ width: "100%" }} cols={min ? 4 : 2} rowHeight={200}>
              {images.map((item: any, index: number) => (
                <ImageListItem style={{ height: "200px !important" }} key={item?.id}>
                  <img
                    style={{ height: "200px !important" }}
                    src={`${item!!.url}`.replace("http://", "https://")}
                    srcSet={`${item?.url}?w=200&h=200&fit=cover&auto=format&dpr=2 2x`}
                    alt={item?.name}
                    loading="lazy"
                  />
                  <ImageListItemBar
                    title={item?.name}
                    className={classes.profile_inlineIcons}
                    actionIcon={
                      <>
                        <IconButton
                          id={"graphicDocumentationIconButton" + index}
                          backgroundColor="transparent"
                          style={{ display: "inherit" }}
                          onClick={() => vm.downloadFile(item!.url ?? "")}
                          icon={<Icon color={Colors.white} element={IconsList.CloudDownload} />}
                        />
                        <IconButton
                          id={"graphicDocumentationIconButton" + index}
                          style={{ display: "inherit" }}
                          backgroundColor="transparent"
                          onClick={() => {
                            setFileToDelete(item!.id);
                          }}
                          icon={<Icon color={Colors.white} element={IconsList.DeleteForever} />}
                        />
                      </>
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>
          )}
          {fileToDelete && <DeleteDialog onClose={() => setFileToDelete(null)} onDelete={deleteFile} vm={vm} />}
        </>
      ) : (
        <>
          {vm.showFileuploader ? (
            <>
              <UploadFileDialog id="juploadFrontPage" vm={vm} buttonText="Añadir portada" mRight=".5rem" collect={collectFrontPage} isPortrait={true} />
              <FileUploader
                maxFiles={14}
                onChange={onChangeFiles}
                acceptedFiles={["image/*"]}
                classNameDropzone={classes.report_dropZone}
                getDropRejectMessage={() => vm.translate("file.size.error")}
                classNameParagraphDropzone={classes.report_dropZone}
                maxFileSize={500000}
                dropzoneText={vm.translate("report.block0.dropzone")}
                showPreviews={false}
              />
              <Typography fontSize={11}>{vm.translate("file.size.recommendation")}</Typography>
            </>
          ) : (
            <div style={{ width: "100%", height: "250px" }}></div>
          )}
          {images.length !== 0 && (
            <ImageList sx={{ width: "100%" }} cols={min ? 4 : 2} rowHeight={200}>
              {images.map((item: any, index: number) => (
                <ImageListItem style={{ height: "200px !important" }} key={item?.id}>
                  <img
                    style={{ height: "200px !important" }}
                    src={`${item!!.url}`.replace("http://", "https://")}
                    srcSet={`${item?.url}?w=200&h=200&fit=cover&auto=format&dpr=2 2x`}
                    alt={item?.name}
                    loading="lazy"
                  />
                  <ImageListItemBar
                    title={item?.name}
                    className={classes.profile_inlineIcons}
                    actionIcon={
                      <>
                        <IconButton
                          id={"graphicDocumentationIconButton" + index}
                          backgroundColor="transparent"
                          style={{ display: "inherit" }}
                          onClick={() => vm.downloadFile(item!.url ?? "")}
                          icon={<Icon color={Colors.white} element={IconsList.CloudDownload} />}
                        />
                        <IconButton
                          id={"graphicDocumentationIconButton" + index}
                          style={{ display: "inherit" }}
                          backgroundColor="transparent"
                          onClick={() => {
                            setFileToDelete(item!.id);
                          }}
                          icon={<Icon color={Colors.white} element={IconsList.DeleteForever} />}
                        />
                      </>
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>
          )}
          {fileToDelete && <DeleteDialog onClose={() => setFileToDelete(null)} onDelete={deleteFile} vm={vm} />}
        </>
      )}
    </Grid>
  );
});
