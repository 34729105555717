import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import ReportViewModel from "../../../../../viewmodels/report/report-view-model";
import { AddButtonsSection } from "./components/add-users-buttons";
import { PropertyDataDialog } from "./components/property-data-dialog";

export const ReportPropertyDataC: React.FC<{ vm: ReportViewModel }> = observer(({ vm }) => {
  return (
    <Grid container>
      {vm.addUser && <PropertyDataDialog vm={vm} />}
      <AddButtonsSection vm={vm} />
    </Grid>
  );
});
