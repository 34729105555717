import { ListViewModel } from "@frui.ts/datascreens";
import { bound } from "@frui.ts/helpers";
import { Router } from "@frui.ts/screens";
import { action, computed, observable } from "mobx";
import User from "../../data/entities/user-entity";
import UsersRepository from "../../data/repositories/impl/user-repository";
import RootViewModel from "../root-view-model";
import UserDetailViewModel from "./user-detail-view-model";

@Router.registerRoute({ name: Router.Self, route: "users" })
export default class UsersViewModel extends ListViewModel<User, UserDetailViewModel> {
  navigationName = "users";
  nameValue = "section.users";
  parent = this.parent as RootViewModel;
  public showInToolbar: boolean = false;

  @observable items: User[] = observable.array([
    {
      id: 1,
      email: "jesus@gmail.com",
      password: "1234",
      username: "jesus12",
      active: false,
      toArray: () => {
        return [];
      },
    },
    {
      id: 2,
      email: "marcos@gmail.com",
      password: "1234",
      username: "marcos5",
      active: false,
      toArray: () => {
        return [];
      },
    },
    {
      id: 3,
      email: "cristian@gmail.com",
      password: "1234",
      username: "kkkhristian3",
      active: false,
      toArray: () => {
        return [];
      },
    },
    {
      id: 4,
      email: "juanjo@gmail.com",
      password: "1234",
      username: "juanjo99",
      active: false,
      toArray: () => {
        return [];
      },
    },
  ]);
  @observable loading = true;
  @observable selected?: User;
  @observable currentPage: number = 0;
  @observable rowsPerPage: number = 25;
  @observable filters: any = {};
  /** Boolean to know if the backdrop loading screen needs to be diaplayed or not */
  @observable showLoading: boolean = false;
  //CSV
  @observable csvData: User[] = [];
  @computed get data() {
    return this.toData(this.items);
  }

  toData = (items: User[]) => {
    const values = items.map((item) => item.toArray());
    return values;
  };

  constructor(public usersRepository: UsersRepository, private detailFactory: ReturnType<typeof UserDetailViewModel.Factory>) {
    super();
  }

  async onActivate() {
    super.onActivate();
    if (this.loading) this.loadData();
  }

  @bound async loadData() {
    this.loading = true;
    this.loading = false;
  }

  @bound async loadDataCSV() {
    this.loading = true;
    this.csvData = this.items;
    this.loading = false;
  }

  @action.bound find(id: string) {
    const index = this.items.find((item) => item.id.toString() === id);
    this.selected = index!!;
    return index;
  }

  applyFilter = () => {
    const keys = Object.keys(this.filters);
    let values = this.data;
    for (let index in keys) {
      const key = keys[index];
      const text = this.filters[key];
      if (text && text.length > 0) {
        values = values.filter((item) => {
          return item[key as any]?.toString().toLowerCase().match(text.toLowerCase());
        });
      }
    }
    return values;
  };

  @action.bound addFilter = (column: number, value?: string) => {
    this.filters[column] = value;
  };

  protected search(query: string): void {
    throw new Error("Method not implemented.");
  }

  @action.bound prepareCreateTest(): User {
    const user = new User(-1, "", "", "", true);
    return user;
  }

  @action.bound async prepareCreate() {
    const result = new User(-1, "", "", "", true);
    this.setSelected(result);
  }

  @action.bound openDetail(item: User): void | Promise<void> {
    this.prepareCreate();
    const detail = this.detailFactory(this.usersRepository, item);
    this.tryActivateChild(detail);
  }

  @action.bound
  openDetailEdit() {
    const detail = this.detailFactory(this.usersRepository, this.selected!!);
    this.tryActivateChild(detail);
  }

  async findNavigationChild(navigationName: string): Promise<any> {
    if (navigationName === undefined || navigationName === this.navigationName) {
      this.parent.tryActivateChild(this);
      this.activeChild?.requestClose();
      return undefined;
    }
    const item = this.items?.find((item) => item.id.toString() === navigationName);
    return this.detailFactory(this.usersRepository, item!!);
  }

  @action.bound async delete(id: string) {
    let created = false;
    const index = this.items.findIndex((item) => item.id.toString() === id);
    return this.usersRepository.delete(this.items[index]).then((res) => {
      created = true;
      res.active = false;
      this.items.splice(this.items.indexOf(this.items[index]), 1, res);
      return created;
    });
  }

  @action.bound setSelected(result: User | undefined) {
    this.selected = result;
  }

  @action.bound handleCurrentPage = (page: number) => {
    this.currentPage = page;
  };

  @action.bound handleRowsPerPage = (numberOfRows: number) => {
    this.rowsPerPage = numberOfRows;
  };

  translate(text: string) {
    this.parent.language = this.parent.configurationService.language;
    return (this.parent as RootViewModel).configurationService.translate(text);
  }
}
