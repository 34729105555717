/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CommunityDTO,
    CommunityDTOFromJSON,
    CommunityDTOToJSON,
    MovicodersBadRequestException,
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundException,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceException,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
    ProvinceDTO,
    ProvinceDTOFromJSON,
    ProvinceDTOToJSON,
} from '../models';

export interface GetProvinceByCommunityCodeRequest {
    code: string;
}

/**
 * 
 */
export class CommunitiesApi extends runtime.BaseAPI {

    /**
     * Returns all communities
     * Get communities list
     */
    async getCommunitiesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<CommunityDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/communities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CommunityDTOFromJSON));
    }

    /**
     * Returns all communities
     * Get communities list
     */
    async getCommunities(initOverrides?: RequestInit): Promise<Array<CommunityDTO>> {
        const response = await this.getCommunitiesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns all provinces of a community
     * Get provinces list by community code
     */
    async getProvinceByCommunityCodeRaw(requestParameters: GetProvinceByCommunityCodeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ProvinceDTO>>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling getProvinceByCommunityCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/communities/{code}/provinces`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProvinceDTOFromJSON));
    }

    /**
     * Returns all provinces of a community
     * Get provinces list by community code
     */
    async getProvinceByCommunityCode(requestParameters: GetProvinceByCommunityCodeRequest, initOverrides?: RequestInit): Promise<Array<ProvinceDTO>> {
        const response = await this.getProvinceByCommunityCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
