//import { User as ClientUser } from "../../client/models/User"

export default class User {
  id: number
  username: string
  password: string
  email: string
  active: boolean

  constructor(
    id: number,
    username: string,
    password: string,
    email: string,
    active: boolean,
  ) {
    this.id = id;
    this.username = username;
    this.password = password;
    this.email = email;
    this.active = active;
  }

  toArray = () => {
    const element: any[] = [];
    element[0] = this.username
    element[1] = this.email
    element[4] = this.active
    element[5] = this.id
    element[6] = this
    return element;
  };

  static fromClientUser(user: any /*ClientUser*/) {}
  static toClientUser(user: User) {}
}