import React from "react"
import { observer } from "mobx-react-lite"
import { UserTable } from "./userscomponents/UsersTable"
import { ViewComponent, registerView } from "@frui.ts/views"
import UsersViewModel from "../../viewmodels/users/users-view-model"

const UsersView: ViewComponent<UsersViewModel> = observer(({ vm }) => {
  return (
    <React.Fragment >
      {/* {vm.activeChild && <View vm={vm.activeChild} />} */}
      <UserTable vm={vm} />
    </React.Fragment>
  )
})

registerView(UsersView, UsersViewModel)