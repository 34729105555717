import { registerView, ViewComponent } from "@frui.ts/views";
import { Fab, Icon, IconsList, TypographyTheme } from "@movicoders/movicoders-components";
import { TabContext, TabPanel } from "@mui/lab";
import { Grid, Tab, Tabs, Tooltip } from "@mui/material";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { lazy, Suspense, useEffect, useState } from "react";
import { EnergyEfficiencyCertificateDTOTypeEnum } from "../../clients";
import { Colors } from "../../constants";
import { scrollToTop } from "../../utils/uiHelper";
import {
  getAccesibilityEvaluationsMock,
  getBookMock,
  getBuildingCompositionDefaults,
  getBuildingConstructionDescriptionDefaults,
  getBuildingLocationInfoDefaults,
  getEnergyEfficiencyCertificateMock,
  getUrbanDataMock,
} from "../../viewmodels/profile/data";
import ReportViewModel from "../../viewmodels/report/report-view-model";
import { ReportStyle } from "./report-style";
import "./report.css";
import ReportBlock0 from "./reportComponents/globalviews/ReportBlock0";
import BackDropLoading from "../../components/backdrop-loading";
const Block1Tabs = lazy(() => import("./reportComponents/globalviews/block1/Block1Tabs"));
const Block2Tabs = lazy(() => import("./reportComponents/globalviews/block2/Block2Tabs"));

export type TSavingFunction = {
  fn: Function;
};

const ReportView: ViewComponent<ReportViewModel> = observer(({ vm }) => {
  const classes = ReportStyle();
  const [savingFunction, setSavingFunction] = useState<TSavingFunction>({ fn: () => { } });
  const [scroll_fab, setScroll_fab] = useState(false);
  const [bookId, setBookId] = useState<any>();

  useEffect(() => {
    vm.parent.selectedBook?.id && setBookId(Object.assign({}, { id: vm.parent.selectedBook?.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vm.parent.selectedBook?.id]);

  useEffect(() => {
    bookId?.id && vm.parent.selectedBook?.id !== bookId?.id && window.location.reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vm.parent.selectedBook?.id]);

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  useEffect(() => {
    if (vm.parent.editingBook === true && vm.parent.settingBookFromVM === false) {
      const buildingComposition = getBuildingCompositionDefaults(vm.parent.selectedBook?.buildingComposition);
      const buildingConstructionDescription = getBuildingConstructionDescriptionDefaults(vm.parent.selectedBook?.buildingConstructionDescription);
      const buildingLocationInfo = getBuildingLocationInfoDefaults(vm.parent.selectedBook?.buildingLocationInfo);
      vm.userBook = getBookMock({ ...vm.parent.selectedBook!, buildingComposition, buildingConstructionDescription, buildingLocationInfo });
      vm.urbanData = getUrbanDataMock(vm.parent.selectedBook!.urbanData!);
      if (vm.parent.selectedBook?.energyEfficiencyCertificateDTO?.length) {
        const intialCertificate = vm.parent.selectedBook.energyEfficiencyCertificateDTO.filter(
          (a) => a.type === EnergyEfficiencyCertificateDTOTypeEnum.Initial
        );
        const finalCertificate = vm.parent.selectedBook.energyEfficiencyCertificateDTO.filter(
          (a) => a.type === EnergyEfficiencyCertificateDTOTypeEnum.Final
        );
        vm.energeticEfficiencyCertificates = getEnergyEfficiencyCertificateMock(intialCertificate[intialCertificate.length - 1]!!);
        vm.finalEnergeticEfficiencyCertificates = getEnergyEfficiencyCertificateMock(finalCertificate[finalCertificate.length - 1]!!);
      } else {
        vm.energeticEfficiencyCertificates = getEnergyEfficiencyCertificateMock();
        vm.finalEnergeticEfficiencyCertificates = getEnergyEfficiencyCertificateMock();
      }
      vm.buildingComposition = buildingComposition;
      const usageRes = vm.parent.selectedBook?.usagePlans?.find((i) => i.year === new Date().getFullYear());
      if (usageRes) vm.useFunctionalDtoArray = usageRes;
      vm.improvementActions = vm.parent.selectedBook!.improvements!!;
      if (vm.parent.selectedBook && vm.parent.selectedBook!!.accessibilityEvaluations && vm.parent.selectedBook!!.accessibilityEvaluations.length > 0)
        vm.accessibilityEvaluations = getAccesibilityEvaluationsMock(
          vm.parent.selectedBook!!.accessibilityEvaluations!![vm.parent.selectedBook!!.accessibilityEvaluations!.length - 1]!!
        );
      else vm.accessibilityEvaluations = getAccesibilityEvaluationsMock();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSticky = (e: any) => {
    const headerTabs = document.querySelector(".header-tabs");
    const scrollTop = window.scrollY;
    if (scrollTop > 300) {
      setScroll_fab(true);
      headerTabs!!.classList.add("is-sticky");
    } else {
      setScroll_fab(false);
      headerTabs!!.classList.remove("is-sticky");
    }
  };

  return (
    <Grid container className={"bg"}>
      {vm.userBook && vm.userBook.id && !vm.hideInspectionSave && (
        <Tooltip placement="top-end" title={vm.translate("report.view.save")}>
          <Fab
            icon={<Icon element={IconsList.Save} color={Colors.white} />}
            variant={"circular"}
            color={Colors.buttonPrimary}
            classNameFab={classes.report_fab}
            onClick={savingFunction.fn}
            onKeyDown={() => { }}
          />
        </Tooltip>
      )}
      {scroll_fab ? (
        <Fab
          icon={<Icon element={IconsList.ArrowUpward} color={Colors.white} />}
          variant={"circular"}
          color={Colors.buttonPrimary}
          classNameFab={classes.scroll_fab}
          onClick={scrollToTop}
          onKeyDown={() => { }}
        />
      ) : (
        <></>
      )}
      <Grid xs={2} />
      <Grid container flexDirection="column" xs={8} md={8}>
        <div style={{ height: 15 }} />
        <TabContext value={vm.activeTab}>
          <Grid container flexDirection={"column"}>
            <Grid item paddingTop={4} alignItems={"center"} display={"flex"}>
              <TypographyTheme
                id="tabActive"
                classTypography={classes.report_titleTypography}
                text={vm.translate("report." + vm.activeTab + ".title")}
                bold
                textAlign="left"
              />
            </Grid>
            <Grid item className="header-tabs">
              <Tabs
                scrollButtons={false}
                className={classes.report_tab}
                value={vm.activeTab}
                variant="fullWidth"
                onChange={(event, value) => {
                  const insp = JSON.stringify(toJS(vm.userBook.inspections?.find(a => a.id === vm.inspection?.id)));
                  const insp2 = JSON.stringify(toJS(vm.inspection));
                  let changes = false;
                  if (vm.inspection && (insp !== insp2)) changes = true
                  if (vm.editingNormalizedDescription && (JSON.stringify(vm.userBook.buildingConstructionDescription) !== JSON.stringify(vm.editingNormalizedDescription))) changes = true
                  if (changes) return
                  vm.previousTab = value;
                  vm.changeTab(value);
                }}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {vm.tabs.map((value: string, index: number) => {
                  return (
                    <Tab
                      id={value + index}
                      disabled={(value === "block1" || value === "block2") && (vm.userBook && vm.userBook.id) === ""}
                      value={value}
                      label={vm.translate("report." + value)}
                      style={{ fontWeight: "bold" }}
                    />
                  );
                })}
              </Tabs>
            </Grid>
          </Grid>
          <Suspense fallback={<BackDropLoading showLoading={true} />}>
            <TabPanel id="tabPanelBlock0" value={"block0"} style={{ background: "white" }}>
              <ReportBlock0 vm={vm} savingFunction={setSavingFunction} />
            </TabPanel>
            {vm.userBook && vm.userBook.id !== "" ? (
              <TabPanel id="tabPanelBlock1" value={"block1"} style={{ background: "white" }}>
                <Block1Tabs vm={vm} savingFunction={setSavingFunction} />
              </TabPanel>
            ) : (
              <></>
            )}
            {vm.userBook && vm.userBook.id !== "" ? (
              <TabPanel id="tabPanelBlock2" value={"block2"} style={{ background: "white" }}>
                <Block2Tabs vm={vm} savingFunction={setSavingFunction} />
              </TabPanel>
            ) : (
              <></>
            )}
          </Suspense>
        </TabContext>
      </Grid>
      <Grid xs={2} />
      <div style={{ height: 50, background: "#F3F6FB", width: 1 }} />
    </Grid>
  );
});

registerView(ReportView, ReportViewModel);
