import { Api } from "../../../infrastructure/data/api-client";
import { BaseRepository } from "../../../infrastructure/data/repositories/base-repository";
import { IPropertyApi } from "../iproperties-api";

export default class PropertiesRepository extends BaseRepository<IPropertyApi> {

    constructor() { super(Api.PropertiesApi, false); }

    async getPropertyByReference(reference: string) {
        const client = await this.apiClient
        return await client.getPropertyByReference(reference);
    }

    async getPropertyByProvinceAndTownAndCadastralReference(
        province: string,
        town: string,
        reference: string
    ) {
        const client = await this.apiClient;
        return await client.getPropertyByProvinceAndTownAndCadastralReference(
            province,
            town,
            reference
        )
    }
}