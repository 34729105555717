import { DialogMovicoders } from "@movicoders/movicoders-components"
import { ConfirmDialogStrings } from "@movicoders/movicoders-components"
import { observer } from "mobx-react-lite"
import React from "react"
import { Colors } from "../../../constants"
import RegisterViewModel from "../../../viewmodels/register/register-view-model"
import { RegisterForm } from "./register-form"

export const RegisterDialog: React.FC<{ vm: RegisterViewModel }> = observer(({ vm }) => {
  const handleClose = () => { vm.parent.navigate("login",{}) }

  const dialogStrings: ConfirmDialogStrings = {
    title: vm.translate("launch.screen.registry.header"),
    content: <RegisterForm vm={vm} />
  }

  return (
    <DialogMovicoders
      id="registerDialogMovicoders"
      open={true}
      draggable
      dialogConfig={dialogStrings}
      onConfirm={() => { }}
      onClose={() => handleClose()}
      closeWithX
      maxWidth={"md"}
      backgroundColorTitle={Colors.primary}
      fullWidth
    />
  )
})