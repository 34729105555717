import { createTheme } from '@mui/material'
import { Colors } from './constants'

const palette = {
  primary: {
    main: Colors.primary,
  },
  info: {
    main: Colors.yellow
  }
}

export default createTheme({ palette })