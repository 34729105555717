import {
  EnergyEfficiencyImprovementDataDTOEnergyEfficiencyMeasureTypeEnum,
  ImprovementDTO,
  ImprovementDTODifficultyEnum,
  ImprovementDTOEstimatedCostEnum,
  ImprovementDTOPhaseEnum,
  ImprovementDTOPriorityEnum,
  ImprovementDTOStatusEnum,
  ImprovementDTOTypeEnum,
} from "../../../../../../clients";
import { CompleteEnergyEfficiencyImprovementDataDTO } from "./EnergyModels";

export const convertEstimatedCostToNumber = (costEnum: ImprovementDTOEstimatedCostEnum): number => {
  switch (costEnum) {
    case ImprovementDTOEstimatedCostEnum.MoreThan1000000:
      return 1000001;
    case ImprovementDTOEstimatedCostEnum._7500001000000:
      return 1000000;
    case ImprovementDTOEstimatedCostEnum._5011000:
      return 1000;
    case ImprovementDTOEstimatedCostEnum._100110000:
      return 10000;
    case ImprovementDTOEstimatedCostEnum._1000125000:
      return 25000;
    case ImprovementDTOEstimatedCostEnum._2500150000:
      return 50000;
    case ImprovementDTOEstimatedCostEnum._50001100000:
      return 100000;
    case ImprovementDTOEstimatedCostEnum._150001200000:
      return 200000;
    case ImprovementDTOEstimatedCostEnum._201000250000:
      return 250000;
    case ImprovementDTOEstimatedCostEnum._250001300000:
      return 300000;
    case ImprovementDTOEstimatedCostEnum._100001150000:
      return 150000;
    case ImprovementDTOEstimatedCostEnum._300000500000:
      return 500000;
    case ImprovementDTOEstimatedCostEnum._500001750000:
      return 750000;
    case ImprovementDTOEstimatedCostEnum.Until500:
      return 500;
  }
};

export const convertEstimatedCostToEnum = (cost: number): ImprovementDTOEstimatedCostEnum => {
  switch (cost) {
    case 1000001:
      return ImprovementDTOEstimatedCostEnum.MoreThan1000000;
    case 1000000:
      return ImprovementDTOEstimatedCostEnum._7500001000000;
    case 1000:
      return ImprovementDTOEstimatedCostEnum._5011000;
    case 10000:
      return ImprovementDTOEstimatedCostEnum._100110000;
    case 25000:
      return ImprovementDTOEstimatedCostEnum._1000125000;
    case 50000:
      return ImprovementDTOEstimatedCostEnum._2500150000;
    case 100000:
      return ImprovementDTOEstimatedCostEnum._50001100000;
    case 150000:
      return ImprovementDTOEstimatedCostEnum._100001150000;
    case 200000:
      return ImprovementDTOEstimatedCostEnum._150001200000;
    case 250000:
      return ImprovementDTOEstimatedCostEnum._201000250000;
    case 300000:
      return ImprovementDTOEstimatedCostEnum._250001300000;
    case 500000:
      return ImprovementDTOEstimatedCostEnum._300000500000;
    case 750000:
      return ImprovementDTOEstimatedCostEnum._500001750000;
    case 500:
      return ImprovementDTOEstimatedCostEnum.Until500;
    default:
      return ImprovementDTOEstimatedCostEnum.Until500;
  }
};

export const fromCompleteEnergyEfficiencyImprovementDataDTOToEnergyEfficiencyImprovementDataDTO = (
  complete: CompleteEnergyEfficiencyImprovementDataDTO
): ImprovementDTO => {
  return {
    id: complete.id ?? undefined,
    measureDescription: complete.description ?? "",
    name: complete.name,
    saving: complete.annualFinancialSavingsAfterImplementation,
    conceptOfImprovementAccordingToGuide: complete.conceptOfImprovementAccordingToGuide,
    estimatedCost: convertEstimatedCostToEnum(complete.estimatedCost ?? 500),
    difficulty: complete.difficulty ?? ImprovementDTODifficultyEnum.Low,
    type: complete.improvementType ?? ImprovementDTOTypeEnum.EnergyEnergySavingLightingOnCommonZones,
    priority: complete.priority ?? ImprovementDTOPriorityEnum.Low,
    referenceStandard: complete.referenceStandard ?? "",
    useRationalizationIndicationAndBuildingManagement: complete.useRationalizationIndicationAndBuildingManagement ?? "",
    scopeOfConstructions: complete.scopeOfConstructions ?? "",
    userBenefits: complete.userBenefits ?? "",
    constructionDurationInMonths: complete.constructionDurationInMonths ?? "",
    benefitDescription: complete.userBenefits ?? "",
    files: complete.files ?? [],
    initialNonRenewablePrimaryEnergyConsumptionReduction: complete.initialNonRenewablePrimaryEnergyConsumptionReductionBackingField,
    initialNonRenewablePrimaryEnergyConsumptionReductionPercent: complete.initialNonRenewablePrimaryEnergyConsumptionReductionPercentBackingField,
    maximumEconomicAidToGet: complete.maximumFinancialAid?.toString() ?? "0",
    order: -1,
    phase: ImprovementDTOPhaseEnum.NoScheduled,
    recommendedStartDateOfConstructions: complete.recommendedStartDateOfConstructions ?? 0,
    status: ImprovementDTOStatusEnum.Proposed,
    timestampOfExecution: new Date().valueOf(),
    energyEfficiencyMeasureData: {
      coolingEnergyDemandReduction: complete.coolingEnergyConsumptionReductionBackingField,
      coolingEnergyDemandReductionPercent: complete.coolingEnergyConsumptionReductionPercentBackingField,
      globalEnergyDemandReduction: complete.initialGlobalEnergyDemandReductionBackingField,
      globalEnergyDemandReductionPercent: complete.initialGlobalEnergyDemandReductionPercentBackingField,
      investment: complete.investmentBackingField,
      heatingEnergyDemandReduction: complete.heatingEnergyConsumptionBackingField,
      heatingEnergyDemandReductionPercent: complete.heatingEnergyConsumptionPercentBackingField,
      initialNonRenewablePrimaryEnergyConsumptionReduction: complete.initialNonRenewablePrimaryEnergyConsumptionReductionBackingField,
      lightningEnergyDemandReduction: complete.lightningEnergyConsumptionReductionBackingField,
      initialNonRenewablePrimaryEnergyConsumptionReductionPercent: complete.initialNonRenewablePrimaryEnergyConsumptionReductionPercentBackingField,
      lightningEnergyDemandReductionPercent: complete.lightningEnergyConsumptionReductionPercentBackingField,
      acsEnergyDemandReduction: complete.acsEnergyConsumptionReductionBackingField,
      acsEnergyDemandReductionPercent: complete.acsEnergyConsumptionReductionPercentBackingField,
      energyEfficiencyMeasureType: complete.energyEfficiencyMeasureType ?? EnergyEfficiencyImprovementDataDTOEnergyEfficiencyMeasureTypeEnum.Others,
      initialNonRenewablePrimaryEnergyConsumption: complete.initialNonRenewablePrimaryEnergyConsumption,
      energyRatingInFinalEmissions: complete.energyRatingInFinalEmissionsQualifier ?? "G",
      energyRatingInInitialEmissions: complete.energyRatingInInitialEmissionsQualifier ?? "G",
      initialCarbonDioxideEmissions: complete.initialCarbonDioxideEmissions,
      finalCarbonDioxideEmissions: complete.finalCarbonDioxideEmissions,
      energyRatingInInitialNonRenewablePrimaryEnergyConsumption: complete.energyRatingInInitialNonRenewablePrimaryEnergyConsumptionQualifier ?? "G",
      energyRatingInFinalNonRenewablePrimaryEnergyConsumption: complete.energyRatingInFinalNonRenewablePrimaryEnergyConsumptionQualifier ?? "G",
      coolingInitialEnergyConsumption: complete.coolingInitialEnergyConsumption,
      coolingFinalEnergyConsumption: complete.coolingFinalEnergyConsumption,
      heatingInitialEnergyConsumption: complete.heatingInitialEnergyConsumption,
      heatingFinalEnergyConsumption: complete.heatingFinalEnergyConsumption,
      acsInitialEnergyConsumption: complete.acsInitialEnergyConsumption,
      initialGlobalEnergyDemand: complete.initialGlobalEnergyDemandXML,
      finalGlobalEnergyDemand: complete.finalGlobalEnergyDemandXML,
      lightningInitialEnergyConsumption: complete.lightningInitialEnergyConsumption,
      lightningFinalEnergyConsumption: complete.lightningFinalEnergyConsumption,
      acsFinalEnergyConsumption: complete.acsFinalEnergyConsumption,
      finalNonRenewablePrimaryEnergyConsumption: complete.finalNonRenewablePrimaryEnergyConsumption,
      maximumFinancialAid: complete.maximumFinancialAid,
      annualFinancialSavingsAfterImplementation: complete.annualFinancialSavingsAfterImplementation,
    },
  };
};

export const reductionHelper = (initial?: number, final?: number) => {
  let start = initial ? initial : 0;
  let end = final ? final : 0;
  return +(+(start - end) * 100).toFixed(0) / 100;
};

export const percentHelper = (numerator?: number, denominator?: number): number => {
  let divisor = denominator;
  let divisible = numerator;
  if (denominator === undefined || denominator === null || denominator === 0) {
    divisor = 1;
  }
  if (numerator === undefined || numerator === null) {
    divisible = 0;
  }
  return +(+((divisible!! * 10000) / divisor!!).toFixed(0) / 100);
};

export const isBackingValueValid = (value: any): boolean => {
  return value !== undefined && value !== null && value !== "";
};
