import { createStyles, makeStyles } from '@mui/styles'

export const UserStyle = makeStyles(() => createStyles({
  user_textField: {
    marginBottom: 10,
    width: "100%"
  },
  user_gridButton: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%"
  },
  user_gridSwitch: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%"
  },
  user_switch: {
    '& .MuiIconButton-label': {
      marginTop: -10
    }
  }
}))