import { DialogMovicoders } from "@movicoders/movicoders-components"
import { ConfirmDialogStrings } from "@movicoders/movicoders-components"
import { observer } from "mobx-react-lite"
import React from "react"
import { Colors } from "../../../constants"
import BuildingDetailViewModel from "../../../viewmodels/buildings/building-detail-view-model"
import { BuildingForm } from "./BuildingsForm"

export const BuildingDialog: React.FC<{ vm: BuildingDetailViewModel }> = observer(({ vm }) => {
  const handleClose = () => {
    vm.requestClose()
    vm.parent.setSelected(undefined)
  }

  const dialogStrings: ConfirmDialogStrings = {
    title: vm.item.name === "-1" ? vm.translate("building.detail.create") : vm.translate("building.detail.edit"),
    content: <BuildingForm vm={vm} />
  }

  return (
    <DialogMovicoders
      id="buildingsDialogMovicoders"
      open={true}
      draggable
      dialogConfig={dialogStrings}
      onConfirm={() => { }}
      onClose={() => handleClose()}
      closeWithX
      maxWidth={"sm"}
      backgroundColorTitle={Colors.primary}
      fullWidth
    />
  )
})