import { Icon, IconButton, TypographyTheme } from "@movicoders/movicoders-components";
import { IconsList } from "@movicoders/movicoders-components";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import usePreciseTimer from "../../utils/timer-hook";

interface IErrorDialog {
  title: string;
  confirmText: string;
  handleClose: Function;
  handleError: Function;
  handleCancel: Function;
  open: boolean;
}

/** function to made the paper draggable */
/*
function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}*/

function ErrorDialog(props: IErrorDialog) {
  const [timer, setTimer] = useState(5);
  const [timerOn, setTimerOn] = useState(true);

  const taskManager = () => {
    if (timer === 0) setTimerOn(false);
    else setTimer(timer - 1);
  };

  usePreciseTimer(taskManager, 1000, timerOn);

  useEffect(() => {
    if (props.open) {
      setTimer(5);
      setTimerOn(true);
    }
  }, [props.open]);

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={() => props.handleClose()}
        //PaperComponent={PaperComponent}
        scroll={"paper"}
        aria-labelledby="draggable-dialog-title"
        disableEscapeKeyDown
      >
        <DialogTitle style={{ display: "flex", justifyContent: "space-between", backgroundColor: "#3f51b5", color: "white" }} id="draggable-dialog-title">
          {props.title}

          <IconButton onClick={() => props.handleCancel()} icon={<Icon element={IconsList.Close} />} />
        </DialogTitle>

        <DialogContent dividers={true}>
          {timerOn ? (
            <TypographyTheme text={`Su acceso a la aplicación ha caducado, espere ${timer} segundos para renovar la sesión`} />
          ) : (
            <TypographyTheme text={`Su acceso a la aplicación ha caducado, pulse el botón para renovar sesión`} />
          )}
        </DialogContent>

        <DialogActions>
          <Button disabled={timerOn} onClick={() => props.handleError()} color="primary">
            {props.confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default ErrorDialog;
