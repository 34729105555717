import { ExtendedSignupTechnician, GetAllTechnicianRequest, GetTechnicianByCredentialsIDRequest, GetTechnicianByIDRequest, SearchTechnicianRequest, TechnicianDTO, TechniciansApi as TechniciansService } from "../../../clients";
import { BaseAPI, Configuration } from "../../../clients/runtime";
import { ITechniciansApi } from "../itechnicians-api";

export class TechniciansApi extends BaseAPI implements ITechniciansApi {
  apiClient: TechniciansService;

  constructor(configuration?: Configuration) {
    super(configuration);
    this.apiClient = new TechniciansService(configuration);
  }

  async getTechnicianById(getTechnicianByIDRequest: GetTechnicianByIDRequest): Promise<TechnicianDTO> {
    const response = await this.apiClient.getTechnicianByID(getTechnicianByIDRequest);
    return response;
  }

  async searchTechnician(searchTechnicianRequest: SearchTechnicianRequest): Promise<TechnicianDTO> {
    const response = await this.apiClient.searchTechnician(searchTechnicianRequest);
    return response;
  }

  async getAllTechnician(params: GetAllTechnicianRequest): Promise<TechnicianDTO[]> {
    const response = await this.apiClient.getAllTechnician(params);
    return response;
  }

  async getTechnicianByCredentialsID(getTechnicianByIDRequest: GetTechnicianByCredentialsIDRequest): Promise<ExtendedSignupTechnician> {
    const response = await this.apiClient.getTechnicianByCredentialsID(getTechnicianByIDRequest);
    return response;
  }

}