/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BioFuelEnergyReportDTO,
    BioFuelEnergyReportDTOFromJSON,
    BioFuelEnergyReportDTOFromJSONTyped,
    BioFuelEnergyReportDTOToJSON,
    BiomassEnergyReportDTO,
    BiomassEnergyReportDTOFromJSON,
    BiomassEnergyReportDTOFromJSONTyped,
    BiomassEnergyReportDTOToJSON,
    CO2EmissionsDTO,
    CO2EmissionsDTOFromJSON,
    CO2EmissionsDTOFromJSONTyped,
    CO2EmissionsDTOToJSON,
    CO2EmissionsEnergyReportDTO,
    CO2EmissionsEnergyReportDTOFromJSON,
    CO2EmissionsEnergyReportDTOFromJSONTyped,
    CO2EmissionsEnergyReportDTOToJSON,
    CarbonEnergyReportDTO,
    CarbonEnergyReportDTOFromJSON,
    CarbonEnergyReportDTOFromJSONTyped,
    CarbonEnergyReportDTOToJSON,
    CoolingDemandDTO,
    CoolingDemandDTOFromJSON,
    CoolingDemandDTOFromJSONTyped,
    CoolingDemandDTOToJSON,
    DieselFuelEnergyReportDTO,
    DieselFuelEnergyReportDTOFromJSON,
    DieselFuelEnergyReportDTOFromJSONTyped,
    DieselFuelEnergyReportDTOToJSON,
    FinalEnergyReportDTO,
    FinalEnergyReportDTOFromJSON,
    FinalEnergyReportDTOFromJSONTyped,
    FinalEnergyReportDTOToJSON,
    GasNaturalEnergyReportDTO,
    GasNaturalEnergyReportDTOFromJSON,
    GasNaturalEnergyReportDTOFromJSONTyped,
    GasNaturalEnergyReportDTOToJSON,
    GlpEnergyReportDTO,
    GlpEnergyReportDTOFromJSON,
    GlpEnergyReportDTOFromJSONTyped,
    GlpEnergyReportDTOToJSON,
    HeatingDemandDTO,
    HeatingDemandDTOFromJSON,
    HeatingDemandDTOFromJSONTyped,
    HeatingDemandDTOToJSON,
    LightingEnergyReportDTO,
    LightingEnergyReportDTOFromJSON,
    LightingEnergyReportDTOFromJSONTyped,
    LightingEnergyReportDTOToJSON,
    MediaDTO,
    MediaDTOFromJSON,
    MediaDTOFromJSONTyped,
    MediaDTOToJSON,
    PelletBiomassEnergyReportDTO,
    PelletBiomassEnergyReportDTOFromJSON,
    PelletBiomassEnergyReportDTOFromJSONTyped,
    PelletBiomassEnergyReportDTOToJSON,
    PrimaryEnergyNoRenewableConsumptionDTO,
    PrimaryEnergyNoRenewableConsumptionDTOFromJSON,
    PrimaryEnergyNoRenewableConsumptionDTOFromJSONTyped,
    PrimaryEnergyNoRenewableConsumptionDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface EnergyEfficiencyCertificateDTO
 */
export interface EnergyEfficiencyCertificateDTO {
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    acsDemand?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    acsnoRenewableConsumption?: number;
    /**
     * 
     * @type {BioFuelEnergyReportDTO}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    bioFuelEnergyReport?: BioFuelEnergyReportDTO;
    /**
     * 
     * @type {BiomassEnergyReportDTO}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    biomassEnergyReport?: BiomassEnergyReportDTO;
    /**
     * 
     * @type {CarbonEnergyReportDTO}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    carbonEnergyReport?: CarbonEnergyReportDTO;
    /**
     * 
     * @type {Array<MediaDTO>}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    certificateFiles?: Array<MediaDTO>;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    climaticZone?: EnergyEfficiencyCertificateDTOClimaticZoneEnum;
    /**
     * 
     * @type {CO2EmissionsDTO}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    co2Emissions?: CO2EmissionsDTO;
    /**
     * 
     * @type {CO2EmissionsEnergyReportDTO}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    co2EmissionsEnergyReport?: CO2EmissionsEnergyReportDTO;
    /**
     * 
     * @type {CoolingDemandDTO}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    coolingDemand?: CoolingDemandDTO;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    coolingNoRenewableConsumption?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    created?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    dailyACSDemand?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    date?: number;
    /**
     * 
     * @type {DieselFuelEnergyReportDTO}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    dieselFuelEnergyReport?: DieselFuelEnergyReportDTO;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    energyEfficientCertificateMedia?: string;
    /**
     * 
     * @type {Array<Array<number>>}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    energyReportValues?: Array<Array<number>>;
    /**
     * 
     * @type {FinalEnergyReportDTO}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    finalEnergyReport?: FinalEnergyReportDTO;
    /**
     * 
     * @type {GasNaturalEnergyReportDTO}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    gasNaturalEnergyReport?: GasNaturalEnergyReportDTO;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    globalDemand?: number;
    /**
     * 
     * @type {GlpEnergyReportDTO}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    glpEnergyReport?: GlpEnergyReportDTO;
    /**
     * 
     * @type {HeatingDemandDTO}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    heatingDemand?: HeatingDemandDTO;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    heatingNoRenewableConsumption?: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    lightingDemand?: number;
    /**
     * 
     * @type {LightingEnergyReportDTO}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    lightingEnergyReport?: LightingEnergyReportDTO;
    /**
     * 
     * @type {number}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    lightingNoRenewableConsumption?: number;
    /**
     * 
     * @type {PelletBiomassEnergyReportDTO}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    pelletBiomassEnergyReport?: PelletBiomassEnergyReportDTO;
    /**
     * 
     * @type {PrimaryEnergyNoRenewableConsumptionDTO}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    primaryEnergyNoRenewableConsumptionDTO?: PrimaryEnergyNoRenewableConsumptionDTO;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    type?: EnergyEfficiencyCertificateDTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EnergyEfficiencyCertificateDTO
     */
    usage?: string;
}

/**
* @export
* @enum {string}
*/
export enum EnergyEfficiencyCertificateDTOClimaticZoneEnum {
    A1 = 'A1',
    A2 = 'A2',
    A3 = 'A3',
    A4 = 'A4',
    Alpha3 = 'ALPHA3',
    B1 = 'B1',
    B2 = 'B2',
    B3 = 'B3',
    B4 = 'B4',
    C1 = 'C1',
    C2 = 'C2',
    C3 = 'C3',
    C4 = 'C4',
    D1 = 'D1',
    D2 = 'D2',
    D3 = 'D3',
    D4 = 'D4',
    E1 = 'E1',
    E2 = 'E2',
    E3 = 'E3',
    E4 = 'E4'
}/**
* @export
* @enum {string}
*/
export enum EnergyEfficiencyCertificateDTOTypeEnum {
    Final = 'FINAL',
    Initial = 'INITIAL'
}

export function EnergyEfficiencyCertificateDTOFromJSON(json: any): EnergyEfficiencyCertificateDTO {
    return EnergyEfficiencyCertificateDTOFromJSONTyped(json, false);
}

export function EnergyEfficiencyCertificateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnergyEfficiencyCertificateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'acsDemand': !exists(json, 'acsDemand') ? undefined : json['acsDemand'],
        'acsnoRenewableConsumption': !exists(json, 'acsnoRenewableConsumption') ? undefined : json['acsnoRenewableConsumption'],
        'bioFuelEnergyReport': !exists(json, 'bioFuelEnergyReport') ? undefined : BioFuelEnergyReportDTOFromJSON(json['bioFuelEnergyReport']),
        'biomassEnergyReport': !exists(json, 'biomassEnergyReport') ? undefined : BiomassEnergyReportDTOFromJSON(json['biomassEnergyReport']),
        'carbonEnergyReport': !exists(json, 'carbonEnergyReport') ? undefined : CarbonEnergyReportDTOFromJSON(json['carbonEnergyReport']),
        'certificateFiles': !exists(json, 'certificateFiles') ? undefined : ((json['certificateFiles'] as Array<any>).map(MediaDTOFromJSON)),
        'climaticZone': !exists(json, 'climaticZone') ? undefined : json['climaticZone'],
        'co2Emissions': !exists(json, 'co2Emissions') ? undefined : CO2EmissionsDTOFromJSON(json['co2Emissions']),
        'co2EmissionsEnergyReport': !exists(json, 'co2EmissionsEnergyReport') ? undefined : CO2EmissionsEnergyReportDTOFromJSON(json['co2EmissionsEnergyReport']),
        'coolingDemand': !exists(json, 'coolingDemand') ? undefined : CoolingDemandDTOFromJSON(json['coolingDemand']),
        'coolingNoRenewableConsumption': !exists(json, 'coolingNoRenewableConsumption') ? undefined : json['coolingNoRenewableConsumption'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'dailyACSDemand': !exists(json, 'dailyACSDemand') ? undefined : json['dailyACSDemand'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'dieselFuelEnergyReport': !exists(json, 'dieselFuelEnergyReport') ? undefined : DieselFuelEnergyReportDTOFromJSON(json['dieselFuelEnergyReport']),
        'energyEfficientCertificateMedia': !exists(json, 'energyEfficientCertificateMedia') ? undefined : json['energyEfficientCertificateMedia'],
        'energyReportValues': !exists(json, 'energyReportValues') ? undefined : json['energyReportValues'],
        'finalEnergyReport': !exists(json, 'finalEnergyReport') ? undefined : FinalEnergyReportDTOFromJSON(json['finalEnergyReport']),
        'gasNaturalEnergyReport': !exists(json, 'gasNaturalEnergyReport') ? undefined : GasNaturalEnergyReportDTOFromJSON(json['gasNaturalEnergyReport']),
        'globalDemand': !exists(json, 'globalDemand') ? undefined : json['globalDemand'],
        'glpEnergyReport': !exists(json, 'glpEnergyReport') ? undefined : GlpEnergyReportDTOFromJSON(json['glpEnergyReport']),
        'heatingDemand': !exists(json, 'heatingDemand') ? undefined : HeatingDemandDTOFromJSON(json['heatingDemand']),
        'heatingNoRenewableConsumption': !exists(json, 'heatingNoRenewableConsumption') ? undefined : json['heatingNoRenewableConsumption'],
        'lightingDemand': !exists(json, 'lightingDemand') ? undefined : json['lightingDemand'],
        'lightingEnergyReport': !exists(json, 'lightingEnergyReport') ? undefined : LightingEnergyReportDTOFromJSON(json['lightingEnergyReport']),
        'lightingNoRenewableConsumption': !exists(json, 'lightingNoRenewableConsumption') ? undefined : json['lightingNoRenewableConsumption'],
        'pelletBiomassEnergyReport': !exists(json, 'pelletBiomassEnergyReport') ? undefined : PelletBiomassEnergyReportDTOFromJSON(json['pelletBiomassEnergyReport']),
        'primaryEnergyNoRenewableConsumptionDTO': !exists(json, 'primaryEnergyNoRenewableConsumptionDTO') ? undefined : PrimaryEnergyNoRenewableConsumptionDTOFromJSON(json['primaryEnergyNoRenewableConsumptionDTO']),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'usage': !exists(json, 'usage') ? undefined : json['usage'],
    };
}

export function EnergyEfficiencyCertificateDTOToJSON(value?: EnergyEfficiencyCertificateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'acsDemand': value.acsDemand,
        'acsnoRenewableConsumption': value.acsnoRenewableConsumption,
        'bioFuelEnergyReport': BioFuelEnergyReportDTOToJSON(value.bioFuelEnergyReport),
        'biomassEnergyReport': BiomassEnergyReportDTOToJSON(value.biomassEnergyReport),
        'carbonEnergyReport': CarbonEnergyReportDTOToJSON(value.carbonEnergyReport),
        'certificateFiles': value.certificateFiles === undefined ? undefined : ((value.certificateFiles as Array<any>).map(MediaDTOToJSON)),
        'climaticZone': value.climaticZone,
        'co2Emissions': CO2EmissionsDTOToJSON(value.co2Emissions),
        'co2EmissionsEnergyReport': CO2EmissionsEnergyReportDTOToJSON(value.co2EmissionsEnergyReport),
        'coolingDemand': CoolingDemandDTOToJSON(value.coolingDemand),
        'coolingNoRenewableConsumption': value.coolingNoRenewableConsumption,
        'created': value.created,
        'dailyACSDemand': value.dailyACSDemand,
        'date': value.date,
        'dieselFuelEnergyReport': DieselFuelEnergyReportDTOToJSON(value.dieselFuelEnergyReport),
        'energyEfficientCertificateMedia': value.energyEfficientCertificateMedia,
        'energyReportValues': value.energyReportValues,
        'finalEnergyReport': FinalEnergyReportDTOToJSON(value.finalEnergyReport),
        'gasNaturalEnergyReport': GasNaturalEnergyReportDTOToJSON(value.gasNaturalEnergyReport),
        'globalDemand': value.globalDemand,
        'glpEnergyReport': GlpEnergyReportDTOToJSON(value.glpEnergyReport),
        'heatingDemand': HeatingDemandDTOToJSON(value.heatingDemand),
        'heatingNoRenewableConsumption': value.heatingNoRenewableConsumption,
        'lightingDemand': value.lightingDemand,
        'lightingEnergyReport': LightingEnergyReportDTOToJSON(value.lightingEnergyReport),
        'lightingNoRenewableConsumption': value.lightingNoRenewableConsumption,
        'pelletBiomassEnergyReport': PelletBiomassEnergyReportDTOToJSON(value.pelletBiomassEnergyReport),
        'primaryEnergyNoRenewableConsumptionDTO': PrimaryEnergyNoRenewableConsumptionDTOToJSON(value.primaryEnergyNoRenewableConsumptionDTO),
        'type': value.type,
        'usage': value.usage,
    };
}

