/* eslint-disable react-hooks/exhaustive-deps */
import { CustomStepper, Icon, IconsList } from "@movicoders/movicoders-components";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from "@mui/material";
import MuiAlert, { AlertColor } from "@mui/material/Alert";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Colors, FontSizes } from "../../../../constants";
import * as BookHelper from "../../../../utils/book-helper";
import ReportViewModel from "../../../../viewmodels/report/report-view-model";
import { TSavingFunction } from "../../report-view";
import { ReportBuildingIdentification } from "../block0/buildingIdentification/building-identification";
import { ReportPropertyDataC } from "../block0/propertydata/property-data";
import { TechnicianRedactors } from "../block0/technicianredactors/technician-redactors";
import { ReportUrbanDataB } from "../block0/urbanData/urban-data";
import DomainIcon from "@mui/icons-material/Domain";
import GroupsIcon from "@mui/icons-material/Groups";
import { getChanges } from "../../../../utils/object-has-changed-helper";
import BackDropLoading from "../../../../components/backdrop-loading";
import { useSnackbar } from "notistack";

const ReportBlock0: React.FC<{ vm: ReportViewModel; savingFunction: (fn: TSavingFunction) => void }> = observer(({ vm, savingFunction }) => {
  const [step, setStep] = useState("0");
  const [content, setContent] = useState<JSX.Element>(<></>);
  const [saving, setSaving] = useState(false);
  const [savingFunctionMessage, setSavingFunctionMessage] = useState<string>("");
  const [savingFunctionSeverity, setSavingFunctionSeverity] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();
  const [show, setShow] = useState(false);

  const [previousStep, setPreviousStep] = useState<any>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [section, setSection] = useState<"BUILDING" | "URBAN_DATA">("BUILDING");

  //BLOCK A
  const [locationInfo, setLocationInfo] = useState(Object.assign({}, vm.userBook.buildingLocationInfo?.address));
  const [referenceDescription, setReferenceDescription] = useState(Object.assign({}, vm.userBook.buildingLocationInfo));

  let USAGES: any = [];
  const [generalData, setGeneralData] = useState<any>([]);

  const [buildMedia, setBuildMedia] = useState([...vm.graphicDocs!]);
  const [parcelArea, setParcelArea] = useState(vm.buildingComposition.parcelArea);
  const [builtArea, setBuiltArea] = useState(vm.buildingComposition.builtArea);
  const [heightOverGround, setHeightOverGround] = useState(vm.buildingComposition.heightOverGround);
  const [floorsOverGround, setFloorsOverGround] = useState(vm.buildingComposition.floorsOverGround);
  const [floorsUnderGround, setFloorsUnderGround] = useState(vm.buildingComposition.floorsUnderGround);
  const [elevatorsCount, setElevatorsCount] = useState(vm.buildingComposition.elevatorsCount);
  const [stairsCount, setStairsCount] = useState(vm.buildingComposition.stairsCount);
  const [builtOnYear, setBuiltOnYear] = useState(vm.buildingComposition.builtOnYear);
  const [lastRehabYear, setLastRehabYear] = useState(vm.buildingComposition.lastRehabYear);
  const [dwellings, setDwellings] = useState(vm.buildingComposition.dwellings);
  const [dwellingsByFloor, setDwellingsByFloor] = useState(vm.buildingComposition.dwellingsByFloor);
  const [parkingCountInBuilding, setParkingCountInBuilding] = useState(vm.buildingComposition.parkingCountInBuilding);
  const [parkingPlacesInBuilding, setParkingPlacesInBuilding] = useState(vm.buildingComposition.parkingPlacesInBuilding);
  const [premisesCount, setPremisesCount] = useState(vm.buildingComposition.premisesCount);
  const [otherElements, setOtherElements] = useState(vm.buildingComposition.otherElements);
  const [storageRoomsCount, setStorageRoomsCount] = useState(vm.buildingComposition.storageRoomsCount);
  const [usageUpdates, setUsageUpdates] = useState(vm.buildingComposition.usages);

  //BLOCK B
  const [urban, setUrban] = useState(Object.assign({}, vm.urbanData));
  const [urbanMedia, setUrbanMedia] = useState(Object.assign({}, vm.urbanMedia));

  useEffect(() => {
    if (locationInfo?.community === "") {
      setLocationInfo(Object.assign({}, vm.userBook.buildingLocationInfo?.address));
      setReferenceDescription(Object.assign({}, vm.userBook.buildingLocationInfo));
      setBuildMedia([...vm.graphicDocs!]);
    }
  }, [vm.userBook.buildingLocationInfo]);

  useEffect(() => {
    parcelArea === 0 && setParcelArea(vm.buildingComposition.parcelArea);
    builtArea === 0 && setBuiltArea(vm.buildingComposition.builtArea);
    heightOverGround === 0 && setHeightOverGround(vm.buildingComposition.heightOverGround);
    floorsOverGround === 0 && setFloorsOverGround(vm.buildingComposition.floorsOverGround);
    floorsUnderGround === 0 && setFloorsUnderGround(vm.buildingComposition.floorsUnderGround);
    elevatorsCount === 0 && setElevatorsCount(vm.buildingComposition.elevatorsCount);
    stairsCount === 0 && setStairsCount(vm.buildingComposition.stairsCount);
    builtOnYear === 0 && setBuiltOnYear(vm.buildingComposition.builtOnYear);
    lastRehabYear === 0 && setLastRehabYear(vm.buildingComposition.lastRehabYear);
    dwellings === 0 && setDwellings(vm.buildingComposition.dwellings);
    dwellingsByFloor === 0 && setDwellingsByFloor(vm.buildingComposition.dwellingsByFloor);
    parkingCountInBuilding === 0 && setParkingCountInBuilding(vm.buildingComposition.parkingCountInBuilding);
    parkingPlacesInBuilding === 0 && setParkingPlacesInBuilding(vm.buildingComposition.parkingPlacesInBuilding);
    premisesCount === 0 && setPremisesCount(vm.buildingComposition.premisesCount);
    otherElements === "" && setOtherElements(vm.buildingComposition.otherElements);
    storageRoomsCount === 0 && setStorageRoomsCount(vm.buildingComposition.storageRoomsCount);
    usageUpdates?.length === 0 && setUsageUpdates(vm.buildingComposition.usages);
  }, [vm.buildingComposition.parcelArea]);

  useEffect(() => {
    if (generalData.length === 0 && vm.userBook.buildingComposition?.usages) {
      setGeneralData(Object.assign([], [...vm.userBook.buildingComposition!.usages!]));
    }
  }, [vm.userBook.buildingComposition?.usages]);

  useEffect(() => {
    USAGES.length === 0 && (USAGES = generalData);
  }, [generalData]);

  const Alert = (severity: string, message: string) => {
    return (
      <MuiAlert elevation={6} variant="filled" onClose={() => setSaving(false)} severity={severity as AlertColor} sx={{ width: "100%" }}>
        {message}
      </MuiAlert>
    );
  };

  const cleanNumericInputs = () => {
    vm.numericInputBuildingCompositonValues.builtArea = 0;
    vm.numericInputBuildingCompositonValues.builtOnYear = 0;
    vm.numericInputBuildingCompositonValues.dwellings = 0;
    vm.numericInputBuildingCompositonValues.dwellingsByFloor = 0;
    vm.numericInputBuildingCompositonValues.elevatorsCount = 0;
    vm.numericInputBuildingCompositonValues.floorsOverGround = 0;
    vm.numericInputBuildingCompositonValues.floorsUnderGround = 0;
    vm.numericInputBuildingCompositonValues.heightOverGround = 0;
    vm.numericInputBuildingCompositonValues.lastRehabYear = 0;
    vm.numericInputBuildingCompositonValues.parcelArea = 0;
    vm.numericInputBuildingCompositonValues.parkingCountInBuilding = 0;
    vm.numericInputBuildingCompositonValues.parkingPlacesInBuilding = 0;
    vm.numericInputBuildingCompositonValues.premisesCount = 0;
    vm.numericInputBuildingCompositonValues.stairsCount = 0;
    vm.numericInputBuildingCompositonValues.storageRoomsCount = 0;
  };

  useEffect(() => {
    if (step === "0") {
      vm.isOpenEditUsages = false;
      vm.parcel = undefined;
      setParcelArea(vm.buildingComposition.parcelArea);
      setBuiltArea(vm.buildingComposition.builtArea);
      setHeightOverGround(vm.buildingComposition.heightOverGround);
      setFloorsOverGround(vm.buildingComposition.floorsOverGround);
      setFloorsUnderGround(vm.buildingComposition.floorsUnderGround);
      setElevatorsCount(vm.buildingComposition.elevatorsCount);
      setStairsCount(vm.buildingComposition.stairsCount);
      setBuiltOnYear(vm.buildingComposition.builtOnYear);
      setLastRehabYear(vm.buildingComposition.lastRehabYear);
      setDwellings(vm.buildingComposition.dwellings);
      setDwellingsByFloor(vm.buildingComposition.dwellingsByFloor);
      setParkingCountInBuilding(vm.buildingComposition.parkingCountInBuilding);
      setParkingPlacesInBuilding(vm.buildingComposition.parkingPlacesInBuilding);
      setPremisesCount(vm.buildingComposition.premisesCount);
      setOtherElements(vm.buildingComposition.otherElements);
      setStorageRoomsCount(vm.buildingComposition.storageRoomsCount);
      setUsageUpdates(vm.buildingComposition.usages);

      setLocationInfo(Object.assign({}, vm.userBook.buildingLocationInfo?.address));
      setReferenceDescription(Object.assign({}, vm.userBook.buildingLocationInfo));
      setGeneralData(Object.assign([], [...vm.userBook.buildingComposition!.usages!]));
      setBuildMedia([...vm.graphicDocs!]);
      setContent(
        <div>
          <ReportBuildingIdentification vm={vm} />
        </div>
      );
      savingFunction({ fn: handleSaveBuildingIdentification });
      vm.hideInspectionSave = false;
    } else if (step === "1") {
      setUrban(Object.assign({}, vm.urbanData));
      setUrbanMedia(Object.assign({}, vm.urbanMedia));
      setContent(
        <div>
          <ReportUrbanDataB vm={vm} />
        </div>
      );
      savingFunction({ fn: handleSaveUrbanData });
      vm.hideInspectionSave = false;
    } else if (step === "2") {
      setContent(
        <div>
          <ReportPropertyDataC vm={vm} />
        </div>
      );
      savingFunction({ fn: () => {} });
      vm.hideInspectionSave = false;
    } else if (step === "3") {
      setContent(<TechnicianRedactors vm={vm} />);
      savingFunction({ fn: () => {} });
      vm.hideInspectionSave = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const uploadFile = async (file: Blob) => await vm.createMediaWithoutApi(file);

  const handleSaveBuildingIdentification = async () => {
    try {
      vm.loadingBook = true;
      const updatedFiles = await Promise.all(vm.graphicDocs.map(uploadFile));
      vm.buildingComposition.media = vm.buildingComposition.media?.concat(BookHelper.fromMultimediaToMediaAll(updatedFiles));
      await vm.updateBuildingComposition(vm.userBook.id ?? "", vm.buildingComposition).then((res) => {
        vm.userBook.buildingComposition = vm.buildingComposition;
      });
      await vm.updateLocationInfo(vm.userBook.id ?? "", vm.locationInfo).then((res) => {
        vm.userBook.buildingLocationInfo = vm.locationInfo;
      });
      await vm.refreshFileUploader();
      vm.buildingComposition = vm.userBook.buildingComposition!;
      setSaving(true);
      setSavingFunctionMessage(vm.translate("report.block0.buildingIdentification.save.success"));
      setSavingFunctionSeverity("success");

      cleanNumericInputs();
      setParcelArea(vm.buildingComposition.parcelArea);
      setBuiltArea(vm.buildingComposition.builtArea);
      setHeightOverGround(vm.buildingComposition.heightOverGround);
      setFloorsOverGround(vm.buildingComposition.floorsOverGround);
      setFloorsUnderGround(vm.buildingComposition.floorsUnderGround);
      setElevatorsCount(vm.buildingComposition.elevatorsCount);
      setStairsCount(vm.buildingComposition.stairsCount);
      setBuiltOnYear(vm.buildingComposition.builtOnYear);
      setLastRehabYear(vm.buildingComposition.lastRehabYear);
      setDwellings(vm.buildingComposition.dwellings);
      setDwellingsByFloor(vm.buildingComposition.dwellingsByFloor);
      setParkingCountInBuilding(vm.buildingComposition.parkingCountInBuilding);
      setParkingPlacesInBuilding(vm.buildingComposition.parkingPlacesInBuilding);
      setPremisesCount(vm.buildingComposition.premisesCount);
      setOtherElements(vm.buildingComposition.otherElements);
      setStorageRoomsCount(vm.buildingComposition.storageRoomsCount);
      setUsageUpdates(vm.buildingComposition.usages);

      setLocationInfo(Object.assign({}, vm.userBook.buildingLocationInfo?.address));
      setReferenceDescription(Object.assign({}, vm.userBook.buildingLocationInfo));
      setGeneralData(Object.assign([], [...vm.userBook.buildingComposition!.usages!]));
      setBuildMedia([...vm.graphicDocs!]);

      setDialogOpen(false);
      vm.isBlockSaved = true;
    } catch (e) {
      vm.loadingBook = false;
      setSaving(true);
      setSavingFunctionMessage(vm.translate("report.block0.buildingIdentification.save.error"));
      setSavingFunctionSeverity("error");
    }
    vm.loadingBook = false;
  };

  const handleSaveUrbanData = async () => {
    try {
      if (vm.validatorVM.validate("urbanData")) {
        vm.loadingBook = true;
        const updatedFiles = await Promise.all(vm.urbanMedia.map(uploadFile));
        vm.urbanData!.urbanPlan = vm.urbanData.urbanPlan?.concat(BookHelper.fromMultimediaToMediaAll(updatedFiles));
        await vm.updateBuildingUrbanData(vm.userBook.id ?? "", vm.urbanData).then((res) => {
          vm.userBook.urbanData = vm.urbanData;
        });
        await vm.refreshFileUploader();
        vm.urbanData = vm.userBook.urbanData!;
        setSaving(true);
        setSavingFunctionMessage(vm.translate("report.block0.urbanData.save.success"));
        setSavingFunctionSeverity("success");
        setUrban(Object.assign({}, vm.urbanData));
        setUrbanMedia(Object.assign({}, []));
        vm.urbanMedia = [];
        setDialogOpen(false);
        vm.isBlockSaved = true;
      } else {
        vm.loadingBook = false;
        setSaving(true);
        setSavingFunctionMessage(vm.translate("report.block0.urbanData.save.error"));
        setSavingFunctionSeverity("error");
      }
    } catch (e) {
      vm.loadingBook = false;
      setSaving(true);
      setSavingFunctionMessage(vm.translate("report.block0.urbanData.save.error"));
      setSavingFunctionSeverity("error");
    }
    vm.loadingBook = false;
  };

  const handleBuildingValidation = () => {
    const fixedReferenceDescription = { ...referenceDescription, buildingPolygon: vm.userBook.buildingLocationInfo?.buildingPolygon };

    if (
      JSON.stringify(getChanges(locationInfo, vm.userBook.buildingLocationInfo?.address)) !== JSON.stringify({}) ||
      JSON.stringify(getChanges(fixedReferenceDescription, vm.userBook.buildingLocationInfo)) !== JSON.stringify({}) ||
      JSON.stringify(generalData) !== JSON.stringify(vm.userBook.buildingComposition!.usages!) ||
      JSON.stringify(buildMedia) !== JSON.stringify([...vm.graphicDocs!]) ||
      JSON.stringify(parcelArea) !== JSON.stringify(vm.buildingComposition.parcelArea) ||
      JSON.stringify(builtArea) !== JSON.stringify(vm.buildingComposition.builtArea) ||
      JSON.stringify(heightOverGround) !== JSON.stringify(vm.buildingComposition.heightOverGround) ||
      JSON.stringify(floorsOverGround) !== JSON.stringify(vm.buildingComposition.floorsOverGround) ||
      JSON.stringify(floorsUnderGround) !== JSON.stringify(vm.buildingComposition.floorsUnderGround) ||
      JSON.stringify(elevatorsCount) !== JSON.stringify(vm.buildingComposition.elevatorsCount) ||
      JSON.stringify(stairsCount) !== JSON.stringify(vm.buildingComposition.stairsCount) ||
      JSON.stringify(builtOnYear) !== JSON.stringify(vm.buildingComposition.builtOnYear) ||
      JSON.stringify(lastRehabYear) !== JSON.stringify(vm.buildingComposition.lastRehabYear) ||
      JSON.stringify(dwellings) !== JSON.stringify(vm.buildingComposition.dwellings) ||
      JSON.stringify(dwellingsByFloor) !== JSON.stringify(vm.buildingComposition.dwellingsByFloor) ||
      JSON.stringify(parkingCountInBuilding) !== JSON.stringify(vm.buildingComposition.parkingCountInBuilding) ||
      JSON.stringify(parkingPlacesInBuilding) !== JSON.stringify(vm.buildingComposition.parkingPlacesInBuilding) ||
      JSON.stringify(premisesCount) !== JSON.stringify(vm.buildingComposition.premisesCount) ||
      JSON.stringify(otherElements) !== JSON.stringify(vm.buildingComposition.otherElements) ||
      JSON.stringify(storageRoomsCount) !== JSON.stringify(vm.buildingComposition.storageRoomsCount)
    ) {
      setSection("BUILDING");
      vm.isBlockSaved = false;
      setDialogOpen(true);
    } else {
      vm.isBlockSaved = true;
    }
  };

  const handleUrbanDataValidation = () => {
    if (
      JSON.stringify(getChanges(urban, vm.urbanData)) !== JSON.stringify({}) ||
      JSON.stringify(getChanges(urbanMedia, vm.urbanMedia)) !== JSON.stringify([])
    ) {
      setSection("URBAN_DATA");
      vm.isBlockSaved = false;
      setDialogOpen(true);
    } else {
      vm.isBlockSaved = true;
    }
  };

  useEffect(() => {
    if (previousStep === "0") {
      handleBuildingValidation();
    }
    if (previousStep === "1") {
      handleUrbanDataValidation();
    }
  }, [previousStep]);

  return (
    <React.Fragment>
      <div style={{ width: "100%" }}>
        {(vm.userBook && (vm.userBook.id ?? "")) !== "" && (
          <CustomStepper
            wordsWidth="120px"
            id="block0Stepper"
            flexDirection="column"
            typographyLabelProps={{ text: "", size: FontSizes.p }}
            data={{
              steps: [
                { titleStep: vm.translate("report.block0.identicate.building"), complete: false },
                { titleStep: vm.translate("report.block0.uban.data"), complete: false },
                { titleStep: vm.translate("report.block0.owner"), complete: false },
                { titleStep: vm.translate("report.block0.technician"), complete: false },
              ],
              iconsSteps: {
                //@ts-ignore
                0: (
                  <div onClick={() => (vm.evaluation = false)}>
                    <DomainIcon />
                  </div>
                ),
                //@ts-ignore
                1: <Icon element={IconsList.Settings} />,
                //@ts-ignore
                2: <GroupsIcon />,
                //@ts-ignore
                3: <Icon element={IconsList.Group} />,
              },
            }}
            backgroundColorActive={Colors.primary}
            backgroundColorComplete={Colors.buttonSecondary}
            orientation="horizontal"
            activeStep={0}
            backgroundColor={Colors.textPrimary}
            onClickIconButton={(activeStep: string) => {
              setPreviousStep(step);
              setStep(activeStep);
            }}
          />
        )}
      </div>
      {content}
      <BackDropLoading showLoading={show} />
      <Dialog open={dialogOpen} scroll={"paper"} aria-labelledby="draggable-dialog-title" disableEscapeKeyDown>
        <DialogTitle style={{ display: "flex", justifyContent: "space-between", backgroundColor: "#3f51b5", color: "white" }} id="draggable-dialog-title">
          {vm.translate("block.no.save")}
        </DialogTitle>

        <DialogContent dividers={true}>{vm.translate("block.no.save.message")}</DialogContent>

        <DialogActions>
          <Button
            onClick={async () => {
              vm.userBook.buildingComposition!.usages = generalData;
              vm.graphicDocs = [];
              vm.userBook.buildingLocationInfo = referenceDescription;
              vm.userBook.buildingLocationInfo!.address = locationInfo;
              vm.buildingComposition.parcelArea = parcelArea;
              vm.buildingComposition.builtArea = builtArea;
              vm.buildingComposition.heightOverGround = heightOverGround;
              vm.buildingComposition.floorsOverGround = floorsOverGround;
              vm.buildingComposition.floorsUnderGround = floorsUnderGround;
              vm.buildingComposition.elevatorsCount = elevatorsCount;
              vm.buildingComposition.stairsCount = stairsCount;
              vm.buildingComposition.builtOnYear = builtOnYear;
              vm.buildingComposition.lastRehabYear = lastRehabYear;
              vm.buildingComposition.dwellings = dwellings;
              vm.buildingComposition.dwellingsByFloor = dwellingsByFloor;
              vm.buildingComposition.parkingCountInBuilding = parkingCountInBuilding;
              vm.buildingComposition.parkingPlacesInBuilding = parkingPlacesInBuilding;
              vm.buildingComposition.premisesCount = premisesCount;
              vm.buildingComposition.otherElements = otherElements;
              vm.buildingComposition.storageRoomsCount = storageRoomsCount;
              vm.buildingComposition.usages = usageUpdates;

              vm.urbanData = urban;
              vm.urbanMedia = [];

              vm.previousTab = "block0";
              vm.isBlockSaved = false;
              setDialogOpen(false);
            }}
            color="primary"
          >
            {vm.translate("block.no.save.discard")}
          </Button>
          <Button
            onClick={async () => {
              setDialogOpen(false);
              setShow(true);

              if (section === "BUILDING") {
                handleSaveBuildingIdentification();
              } else if (section === "URBAN_DATA") {
                handleSaveUrbanData();
              }
              vm.isBlockSaved = true;

              setShow(false);
            }}
            color="primary"
          >
            {vm.translate("block.no.save.save")}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={saving} autoHideDuration={3000} onClose={() => setSaving(false)}>
        {Alert(savingFunctionSeverity, savingFunctionMessage)}
      </Snackbar>
    </React.Fragment>
  );
});

export default ReportBlock0;
