import { CommunityDTO as CommunityClient } from "../../clients";

export default class Community {

  code: string;
  id: string;
  name: string;

  constructor(
    code: string,
    id: string,
    name: string
  ) {
    this.code = code;
    this.id = id;
    this.name = name;
  }

  static fromClientCommunity(community: CommunityClient) {
    return new Community(
      community.code!,
      community.id!,
      community.name!
    );
  }

  static toClientCommunity(community: Community) {
    const res: CommunityClient = {};

    res.code = community.code;
    res.id = community.id;
    res.name = community.name;

    return res;
  }

  static fromClientCommunityList(community: CommunityClient[]) {
    return community.map(this.fromClientCommunity);
  }
}