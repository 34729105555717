import { BaseAPI, Configuration } from "../../../clients";
import { IncompletePropertiesDTOInManager } from "../../../clients/models";
import Building from "../../entities/building-entity";
import { IBuildingApi } from "../ibuildings-api";
import {
  CadastralInfoApi as BuildingServiceApi,
  GetBuildingInfoByRealEstateIdsRequest,
  GetIncompletePropertiesIdsFromRequest,
} from "./../../../clients/apis/CadastralInfoApi";
export class BuildingsApi extends BaseAPI implements IBuildingApi {
  apiClient: BuildingServiceApi;

  constructor(configuration?: Configuration) {
    super(configuration);
    this.apiClient = new BuildingServiceApi(configuration);
  }

  async getIncompletePropertiesIdsFrom(ids: string[]): Promise<IncompletePropertiesDTOInManager> {
    const response = await this.apiClient.getIncompletePropertiesIdsFrom({ requestBody: ids } as GetIncompletePropertiesIdsFromRequest, undefined);
    return response;
  }

  async getBuildingInfoByRealEstateIds(ids: Array<string>): Promise<Building> {
    const response = await this.apiClient.getBuildingInfoByRealEstateIds({ requestBody: ids } as GetBuildingInfoByRealEstateIdsRequest, undefined);
    return Building.fromClientBuilding(response);
  }
}
