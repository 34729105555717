import Community from "../../entities/community-entity";
import ICommunityApi from "../icommunity-api";
import { CommunitiesApi as CommunityServiceApi, ProvinceDTO } from "../../../clients";
import { BaseAPI, Configuration } from "../../../clients/runtime";

export class CommunityApi extends BaseAPI implements ICommunityApi {
  apiClient: CommunityServiceApi;

  constructor(configuration?: Configuration) {
    super(configuration);
    this.apiClient = new CommunityServiceApi(configuration);
  }

  async getProvincesByCommunityCode(code: string): Promise<ProvinceDTO[]> {
    return await this.apiClient.getProvinceByCommunityCode({ code });
  }

  async getCommunityList(): Promise<Community[]> {
    const response = await this.apiClient.getCommunities();
    return Community.fromClientCommunityList(response);
  }
}
