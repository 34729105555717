import { bound } from "@frui.ts/helpers";
import { ConductorOneChildActive, NavigationPath, Router, ScreenBase } from "@frui.ts/screens";
import { action, observable } from "mobx";
import type { BookDTO, CommunityDTO, ExtendedSignupTechnician, ProvinceDTO, TownDTO } from "../clients";
import CollegeRepository from "../data/repositories/impl/college-repository";
import CommunityRepository from "../data/repositories/impl/community-repository";
import ProvincesRepository from "../data/repositories/impl/provinces-repository";
import { TechniciansRepository } from "../data/repositories/impl/technicians-repository";
import TownRepository from "../data/repositories/impl/town-repository";
import { SessionStoreFactory } from "../infrastructure/data/session-store-factory";
import CurrentUser from "../models/current-user";
import ConfigurationService from "../services/configuration-service";
import UserContextService from "../services/user-context-service";
import { TechnicianDTO } from "./../clients/models/TechnicianDTO";
import DashboardViewModel from "./dashboard/dashboard-view-model";
import LandingViewModel from "./landing/landing-view-model";
import LoginViewModel from "./login/login-view-model";
import ProfileViewModel from "./profile/profile-view-model";
import RegisterViewModel from "./register/register-view-model";
import ReportViewModel from "./report/report-view-model";
import ResetViewModel from "./reset/reset-view-model";
import RolesViewModel from "./roles-view-model";
import UsersViewModel from "./users/users-view-model";
import MaintenanceViewModel from "./maintenance/maintenance-view-model";

@Router.registerRoute({
  name: Router.Self,
  route: "",
  children: [
    DashboardViewModel,
    RolesViewModel,
    ReportViewModel,
    LoginViewModel,
    UsersViewModel,
    MaintenanceViewModel,
    ProfileViewModel,
    RegisterViewModel,
    ResetViewModel,
    LandingViewModel,
  ],
})
export default class RootViewModel extends ConductorOneChildActive<ScreenBase> {
  @observable navigationPath: string = "";
  isLoggedIn = false;
  currentUser: CurrentUser | null | undefined;
  @observable language: string = "";
  @observable confirmToken: string = "";
  @observable confirmLogin: string = "";
  @observable isStillCreating: boolean = false;
  @observable errorLogin: boolean = false;
  @observable openDrawer: boolean = false;
  @observable provinces?: ProvinceDTO[] = undefined;
  @observable towns?: TownDTO[] = undefined;
  @observable colleges?: any = undefined;
  @observable communities?: CommunityDTO[] = undefined;
  @observable selectedBook?: BookDTO;
  @observable downloadBook?: BookDTO;
  @observable editingBook: boolean = false;
  @observable technicianData?: ExtendedSignupTechnician;
  @observable settingBookFromVM: boolean = false;
  @observable cgateRegisterUser: TechnicianDTO | undefined;
  @observable isManual: boolean = false;

  constructor(
    dashboardViewModel: DashboardViewModel,
    rolesViewModel: RolesViewModel,
    reportViewModel: ReportViewModel,
    usersViewModel: UsersViewModel,
    maintenanceViewModel: MaintenanceViewModel,
    profileViewModel: ProfileViewModel,
    resetViewModel: ResetViewModel,
    loginViewModel: LoginViewModel,
    registerViewModel: RegisterViewModel,
    landingViewModel: LandingViewModel,
    public communityRepository: CommunityRepository,
    public provincesRepository: ProvincesRepository,
    public collegeRepository: CollegeRepository,
    private userContext: UserContextService,
    public configurationService: ConfigurationService,
    public townRepository: TownRepository,
    public technicianRepository: TechniciansRepository
  ) {
    super();
    this.navigationName = "";
    this.children.push(
      dashboardViewModel,
      rolesViewModel,
      reportViewModel,
      usersViewModel,
      profileViewModel,
      loginViewModel,
      registerViewModel,
      landingViewModel,
      resetViewModel,
      maintenanceViewModel
    );
  }

  @action
  setNavigationPath = (path: string) => {
    this.navigationPath = path;
  };

  @action logOff = () => {
    this.userContext.logout();
    SessionStoreFactory.getSessionStore().setToken("");
    (this.children.find((f) => f.navigationName === "report") as ProfileViewModel).profileAdmin = undefined;
    this.navigate("login", {});
  };

  @action
  setConfirmToken = (value: string) => {
    this.confirmToken = value;
  };

  onChildNavigated(child: ScreenBase | undefined) {
    super.onChildNavigated(child);
  }

  goToMaintenance = () => {
    this.userContext.logout();
    SessionStoreFactory.getSessionStore().setToken("");
    (this.children.find((f) => f.navigationName === "report") as ProfileViewModel).profileAdmin = undefined;
    this.navigate("maintenance", {});
  }

  async onActivate() {
    super.onActivate();
  }

  @action.bound
  async getTechnicianData() {
    try {
      const user = (this.children.find((c) => c.navigationName === "profile") as ProfileViewModel)?.profileUser
      if (user) this.technicianData = user;
      else this.technicianData = await this.technicianRepository.getTechnicianByCredentialsId({});
    } catch (e: any) {
      if (e.status === 401) {
        this.errorLogin = true;
        (this.children.find((c) => c.navigationName === "report") as ReportViewModel).loadingBook = false;
      }
    }
  }

  @action
  setOpenDrawerVM = (value: boolean) => {
    this.openDrawer = value;
  };

  @action.bound
  setConfirmLogin = (value: string) => {
    this.confirmLogin = value;
  };

  @action
  setErrorLogin(value: boolean) {
    this.errorLogin = value;
  }

  translate(text: string) {
    return this.configurationService.translate(text);
  }

  @bound
  navigate(path: string, params: any) {
    this.isLoggedIn = this.userContext.isLoggedIn;
    if (path === undefined && !this.isLoggedIn) return super.navigate("landing", {});
    else if (path === undefined && this.isLoggedIn) return super.navigate("profile", {});
    if (!this.isLoggedIn) {
      if (path === "login") {
        return super.navigate("login", {});
      }
      if (path === "register") {
        return super.navigate("register", {});
      }
      if (path === "landing") {
        return super.navigate("landing", {});
      }
      if (path === "maintenance") return super.navigate("maintenance", {});
      if (path.split("/")[0] === "reset") {
        const token = path.split("/")[1].split("token=")[1] ?? "";
        sessionStorage.setItem("token", token);
        return super.navigate("reset", { token: "test" });
      }
      return super.navigate("landing", {});
    } else {
      if (path === undefined) {
        return super.navigate("landing", {});
      } else if (path.split("/")[0] === "reset") {
        const token = path.split("/")[1].split("token=")[1] ?? "";
        sessionStorage.setItem("token", token);
        return super.navigate("reset", {});
      } else {
        return super.navigate(path, params);
      }
    }
  }

  getChildNavigationPath(child: any, params: any): NavigationPath {
    const path = this.getNavigationPath();
    if (child) {
      return {
        path: child.navigationName,
        params: params || this.navigationParams,
        isClosed: false,
      };
    } else {
      return path;
    }
  }

  @action
  getReportViewModel = (): ReportViewModel => {
    return this.children.find((c) => c.navigationName === "report") as ReportViewModel;
  };

  findNavigationChild(navigationName: string) {
    const child = this.children.find((item) => item.navigationName === navigationName) ?? this.children.find((item) => item.navigationName === "landing");
    return Promise.resolve(child);
  }
}
