import L from "leaflet";
import { observer } from "mobx-react-lite";
import React from "react";
import { GeoJSON, Marker, useMap } from "react-leaflet";
import * as GeoJsonHelper from "../../../../../../utils/geojsonHelper";
import ReportViewModel from "../../../../../../viewmodels/report/report-view-model";
import { BuildingPolygonDTO, ParcelDTO, PolygonDTO } from "../../../../../../clients/models";

export const ReactiveMap: React.FC<{ vm: ReportViewModel; setMap: any, mappolygons: () => [number, number] }> = observer(({ vm, setMap, mappolygons }) => {
  const map = useMap();
  setMap(map);
  return (
    <div id="mapid">
      <Marker
        icon={L.divIcon({
          className: "my-custom-pin",
          popupAnchor: [7, -5],
        })}
        position={mappolygons()}
      />
      {vm.building?.parcels?.map((p: ParcelDTO) => {
        return p.polygons && <GeoJSON key={p.id} data={GeoJsonHelper.geoJsonFromCoordinates((p.polygons[0] as PolygonDTO) ?? {}).geometry as any} />;
      })}
      <GeoJSON data={GeoJsonHelper.geoJsonFromCoordinates((vm.userBook.buildingLocationInfo?.parcelPolygon?.[0] ?? {})).geometry as any} />
      {vm.userBook?.buildingLocationInfo?.buildingPolygon?.map((p: BuildingPolygonDTO, index: number) => {
        return (
          p && (
            <GeoJSON
              key={index}
              data={
                GeoJsonHelper.geoJsonFromCoordinates(({ content: p.content, coordinatesSystem: p.coordinatesSystem } as PolygonDTO) ?? {}).geometry as any
              }
            />
          )
        );
      })}
    </div>
  );
});
