import { makeStyles } from "@mui/styles";

export const useTransferListStyles = makeStyles({
  transferList_parentContainer: {
    margin: "15px 0px 15px 0px",
  },
  transferList_paper: {
    borderRadius: 15,
  },
  transferList_buttons: {
    margin: 5,
  },
  transferList_buttons_icons: {
    color: "#0d2b52",
  },
  transferList_list_titleGrid: {
    marginBottom: 5,
  },
  transferList_list: {
    height: 350,
    overflow: "auto",
    overflowY: "hidden",
  },
  transferList_checkbox: {
    marginRight: 10,
    marginBottom: 18,
  },
});
