/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CO2EmissionsDTO
 */
export interface CO2EmissionsDTO {
    /**
     * 
     * @type {number}
     * @memberof CO2EmissionsDTO
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof CO2EmissionsDTO
     */
    qualifier?: string;
    /**
     * 
     * @type {string}
     * @memberof CO2EmissionsDTO
     */
    unitOfMeasure?: string;
}

export function CO2EmissionsDTOFromJSON(json: any): CO2EmissionsDTO {
    return CO2EmissionsDTOFromJSONTyped(json, false);
}

export function CO2EmissionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CO2EmissionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'qualifier': !exists(json, 'qualifier') ? undefined : json['qualifier'],
        'unitOfMeasure': !exists(json, 'unitOfMeasure') ? undefined : json['unitOfMeasure'],
    };
}

export function CO2EmissionsDTOToJSON(value?: CO2EmissionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'qualifier': value.qualifier,
        'unitOfMeasure': value.unitOfMeasure,
    };
}

