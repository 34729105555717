/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnergyEfficiencyCertificatePrintedDTO,
    EnergyEfficiencyCertificatePrintedDTOFromJSON,
    EnergyEfficiencyCertificatePrintedDTOFromJSONTyped,
    EnergyEfficiencyCertificatePrintedDTOToJSON,
    NewBuildingInspectionDTO,
    NewBuildingInspectionDTOFromJSON,
    NewBuildingInspectionDTOFromJSONTyped,
    NewBuildingInspectionDTOToJSON,
    NewUseAndMaintenanceDTO,
    NewUseAndMaintenanceDTOFromJSON,
    NewUseAndMaintenanceDTOFromJSONTyped,
    NewUseAndMaintenanceDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface FirstBlockDTO
 */
export interface FirstBlockDTO {
    /**
     * 
     * @type {NewBuildingInspectionDTO}
     * @memberof FirstBlockDTO
     */
    buildingInspection?: NewBuildingInspectionDTO;
    /**
     * 
     * @type {Array<EnergyEfficiencyCertificatePrintedDTO>}
     * @memberof FirstBlockDTO
     */
    energyEfficiencyCertificates?: Array<EnergyEfficiencyCertificatePrintedDTO>;
    /**
     * 
     * @type {EnergyEfficiencyCertificatePrintedDTO}
     * @memberof FirstBlockDTO
     */
    finalEnergyEfficiencyCertificate?: EnergyEfficiencyCertificatePrintedDTO;
    /**
     * 
     * @type {EnergyEfficiencyCertificatePrintedDTO}
     * @memberof FirstBlockDTO
     */
    initialEnergyEfficiencyCertificate?: EnergyEfficiencyCertificatePrintedDTO;
    /**
     * 
     * @type {NewUseAndMaintenanceDTO}
     * @memberof FirstBlockDTO
     */
    useAndMaintenance?: NewUseAndMaintenanceDTO;
}

export function FirstBlockDTOFromJSON(json: any): FirstBlockDTO {
    return FirstBlockDTOFromJSONTyped(json, false);
}

export function FirstBlockDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FirstBlockDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buildingInspection': !exists(json, 'buildingInspection') ? undefined : NewBuildingInspectionDTOFromJSON(json['buildingInspection']),
        'energyEfficiencyCertificates': !exists(json, 'energyEfficiencyCertificates') ? undefined : ((json['energyEfficiencyCertificates'] as Array<any>).map(EnergyEfficiencyCertificatePrintedDTOFromJSON)),
        'finalEnergyEfficiencyCertificate': !exists(json, 'finalEnergyEfficiencyCertificate') ? undefined : EnergyEfficiencyCertificatePrintedDTOFromJSON(json['finalEnergyEfficiencyCertificate']),
        'initialEnergyEfficiencyCertificate': !exists(json, 'initialEnergyEfficiencyCertificate') ? undefined : EnergyEfficiencyCertificatePrintedDTOFromJSON(json['initialEnergyEfficiencyCertificate']),
        'useAndMaintenance': !exists(json, 'useAndMaintenance') ? undefined : NewUseAndMaintenanceDTOFromJSON(json['useAndMaintenance']),
    };
}

export function FirstBlockDTOToJSON(value?: FirstBlockDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buildingInspection': NewBuildingInspectionDTOToJSON(value.buildingInspection),
        'energyEfficiencyCertificates': value.energyEfficiencyCertificates === undefined ? undefined : ((value.energyEfficiencyCertificates as Array<any>).map(EnergyEfficiencyCertificatePrintedDTOToJSON)),
        'finalEnergyEfficiencyCertificate': EnergyEfficiencyCertificatePrintedDTOToJSON(value.finalEnergyEfficiencyCertificate),
        'initialEnergyEfficiencyCertificate': EnergyEfficiencyCertificatePrintedDTOToJSON(value.initialEnergyEfficiencyCertificate),
        'useAndMaintenance': NewUseAndMaintenanceDTOToJSON(value.useAndMaintenance),
    };
}

