import { BuildingDTO, ParcelDTO, PropertyDTO } from "../../clients/models";

export default class Building {
  community: string;
  dwellings: number[];
  floorsAboveGround: number[];
  floorsUnderGround: number[];
  name: string;
  parcels: ParcelDTO[];
  portalNumber: string;
  portalNumberLetter: string;
  postalCode: number;
  properties: PropertyDTO[];
  province: string;
  secondaryPortalLetter: string;
  secondaryPortalNumber: string;
  street: string;
  town: string;
  type: string;
  year: number;

  constructor(
    community: string,
    dwellings: number[],
    floorsAboveGround: number[],
    floorsUnderGround: number[],
    name: string,
    parcels: ParcelDTO[],
    portalNumber: string,
    portalNumberLetter: string,
    postalCode: number,
    properties: PropertyDTO[],
    province: string,
    secondaryPortalLetter: string,
    secondaryPortalNumber: string,
    street: string,
    town: string,
    type: string,
    year: number
  ) {
    this.community = community;
    this.dwellings = dwellings;
    this.floorsAboveGround = floorsAboveGround;
    this.floorsUnderGround = floorsUnderGround;
    this.name = name;
    this.parcels = parcels;
    this.portalNumber = portalNumber;
    this.portalNumberLetter = portalNumberLetter;
    this.postalCode = postalCode;
    this.properties = properties;
    this.province = province;
    this.secondaryPortalLetter = secondaryPortalLetter;
    this.secondaryPortalNumber = secondaryPortalNumber;
    this.street = street;
    this.town = town;
    this.type = type;
    this.year = year;
  }

  toArray = () => {
    const element: any[] = [];
    element[0] = this.community;
    element[1] = this.dwellings;
    element[2] = this.floorsAboveGround;
    element[3] = this.floorsUnderGround;
    element[4] = this.name;
    element[5] = this.parcels;
    element[6] = this.portalNumber;
    element[7] = this.portalNumberLetter;
    element[8] = this.postalCode;
    element[9] = this.properties;
    element[10] = this.province;
    element[11] = this.secondaryPortalLetter;
    element[12] = this.secondaryPortalNumber;
    element[13] = this.street;
    element[14] = this.town;
    element[15] = this.type;
    element[16] = this.year;
    return element;
  };

  static fromClientBuilding(building: BuildingDTO) {
    const result = new Building(
      building.community!!,
      building.dwellings!!,
      building.floorsAboveGround!!,
      building.floorsUnderGround!!,
      building.name!!,
      building.parcels!!,
      building.portalNumber!!,
      building.portalNumberLetter!!,
      building.postalCode!!,
      building.properties!!,
      building.province!!,
      building.secondaryPortalLetter!!,
      building.secondaryPortalNumber!!,
      building.street!!,
      building.town!!,
      building.type!!,
      building.year!!
    );
    return result;
  }

  static toClientBuilding(building: Building) {
    let result: BuildingDTO = {};
    result.community = building.community;
    result.dwellings = building.dwellings;
    result.floorsAboveGround = building.floorsAboveGround;
    result.floorsUnderGround = building.floorsUnderGround;
    result.name = building.name;
    result.parcels = building.parcels;
    result.portalNumber = building.portalNumber;
    result.portalNumberLetter = building.portalNumberLetter;
    result.postalCode = building.postalCode;
    result.properties = building.properties;
    result.province = building.province;
    result.secondaryPortalLetter = building.secondaryPortalLetter;
    result.secondaryPortalNumber = building.secondaryPortalNumber;
    result.street = building.street;
    result.portalNumberLetter = building.town;
    result.type = building.type;
    result.year = building.year;

    return result;
  }
}
