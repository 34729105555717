/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Coordinates,
    CoordinatesFromJSON,
    CoordinatesFromJSONTyped,
    CoordinatesToJSON,
    ParcelDTO,
    ParcelDTOFromJSON,
    ParcelDTOFromJSONTyped,
    ParcelDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface BuildingPolygonDTO
 */
export interface BuildingPolygonDTO {
    /**
     * 
     * @type {Array<Coordinates>}
     * @memberof BuildingPolygonDTO
     */
    content?: Array<Coordinates>;
    /**
     * 
     * @type {string}
     * @memberof BuildingPolygonDTO
     */
    coordinatesSystem?: BuildingPolygonDTOCoordinatesSystemEnum;
    /**
     * 
     * @type {ParcelDTO}
     * @memberof BuildingPolygonDTO
     */
    parcel?: ParcelDTO;
}

/**
* @export
* @enum {string}
*/
export enum BuildingPolygonDTOCoordinatesSystemEnum {
    Epsg23029 = 'EPSG23029',
    Epsg23030 = 'EPSG23030',
    Epsg23031 = 'EPSG23031',
    Epsg25829 = 'EPSG25829',
    Epsg25830 = 'EPSG25830',
    Epsg25831 = 'EPSG25831',
    Epsg32627 = 'EPSG32627',
    Epsg32628 = 'EPSG32628',
    Epsg32629 = 'EPSG32629',
    Epsg32630 = 'EPSG32630',
    Epsg32631 = 'EPSG32631',
    Epsg4230 = 'EPSG4230',
    Epsg4258 = 'EPSG4258',
    Epsg4326 = 'EPSG4326'
}

export function BuildingPolygonDTOFromJSON(json: any): BuildingPolygonDTO {
    return BuildingPolygonDTOFromJSONTyped(json, false);
}

export function BuildingPolygonDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuildingPolygonDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'content': !exists(json, 'content') ? undefined : ((json['content'] as Array<any>).map(CoordinatesFromJSON)),
        'coordinatesSystem': !exists(json, 'coordinatesSystem') ? undefined : json['coordinatesSystem'],
        'parcel': !exists(json, 'parcel') ? undefined : ParcelDTOFromJSON(json['parcel']),
    };
}

export function BuildingPolygonDTOToJSON(value?: BuildingPolygonDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content': value.content === undefined ? undefined : ((value.content as Array<any>).map(CoordinatesToJSON)),
        'coordinatesSystem': value.coordinatesSystem,
        'parcel': ParcelDTOToJSON(value.parcel),
    };
}

