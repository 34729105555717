/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewCadastralLocationDTO
 */
export interface NewCadastralLocationDTO {
    /**
     * 
     * @type {string}
     * @memberof NewCadastralLocationDTO
     */
    block?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCadastralLocationDTO
     */
    door?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCadastralLocationDTO
     */
    floor?: string;
    /**
     * 
     * @type {string}
     * @memberof NewCadastralLocationDTO
     */
    stair?: string;
}

export function NewCadastralLocationDTOFromJSON(json: any): NewCadastralLocationDTO {
    return NewCadastralLocationDTOFromJSONTyped(json, false);
}

export function NewCadastralLocationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewCadastralLocationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'block': !exists(json, 'block') ? undefined : json['block'],
        'door': !exists(json, 'door') ? undefined : json['door'],
        'floor': !exists(json, 'floor') ? undefined : json['floor'],
        'stair': !exists(json, 'stair') ? undefined : json['stair'],
    };
}

export function NewCadastralLocationDTOToJSON(value?: NewCadastralLocationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'block': value.block,
        'door': value.door,
        'floor': value.floor,
        'stair': value.stair,
    };
}

