import { Icon, IconButton } from "@movicoders/movicoders-components";
import { IconsList } from "@movicoders/movicoders-components";
import { ISearchBox } from "@movicoders/movicoders-components";
import InputBase from "@mui/material/InputBase/InputBase";
import React, { useEffect, useState } from "react";
import { Colors } from "../constants";

export default function SearchBox<T>(params: ISearchBox<T>) {
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);

  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    params.onChange && params.onChange(e.target.value);
    setDebouncedSearchText(e.target.value);
  };

  const handleClear = () => {
    setDebouncedSearchText("");
  };

  const requestSearch = (searchValue: string) => {
    // const searchRegex = new RegExp(escapeRegExp(searchValue.toLowerCase()), 'i');
    const filteredRows = params.data?.filter((row: T) => {
      // @ts-ignore
      const text = row["type"] + ": " + row["name"] + "ptal: " + row["portalNumber"] + "piso: " + row["floor"] + "pta: " + row["door"] + "ref: " + row["pc1"] + row["pc2"] + row["car"] + row["cc1"] + row["cc2"];
      return text && text.toLowerCase().includes(searchValue?.toLowerCase());
    });
    params.filteredDataSetter(filteredRows);
  };

  useEffect(() => {
    const timer = setTimeout(() => setSearchText(debouncedSearchText), params.typingTimer ? params.typingTimer : 250);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText]);

  useEffect(() => {
    requestSearch(searchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <div style={{ border: `1px solid ${Colors.primary}`, borderRadius: 5, width: params.width ? params.width : "250px", ...params.stylePaper }}>
      <InputBase
        id={params.id}
        style={{
          ...params.styleInputBase,
          flex: 1,
          borderRadius: 5,
          background: params.backgroundColor ? params.backgroundColor : Colors.white,
          width: params.width ? params.width : "240px",
        }}
        placeholder={params.placeholder}
        value={debouncedSearchText}
        onChange={handleChangeText}
        startAdornment={
          <div style={{ margin: 5, marginTop: 10 }}>
            <Icon color={params.iconColor ? params.iconColor : Colors.primary} element={IconsList.Search} />
          </div>
        }
        endAdornment={
          <div style={{ visibility: debouncedSearchText !== "" ? "visible" : "hidden" }}>
            <IconButton id={params.idIconButton} backgroundColor="transparent" icon={<Icon color={params.iconColor ? params.iconColor : Colors.primary} element={IconsList.Clear} />} onClick={handleClear} />
          </div>
        }
      />
    </div>
  );
}
