import { RadioGroup } from "@movicoders/movicoders-components"
import { displayPosition, optionObject } from "@movicoders/movicoders-components"
import React from "react"
import { Colors } from "../../constants"
import { RadioGroupStyles } from "./radio-group-styles"

export interface IRadioGroup {
  id: string
  filaArray: optionObject[]
  title?: string
  subTitle?: string
  orientation?: displayPosition
  onSelectionChange?: (value: any) => void
  selection?: any
  marginLeft?: string | number
  iconRadioSize?: "small" | "medium"
  className?: string
  classNameFormLabel?: string
  withoutMarginleft?: boolean
}

function RadioBoxGroup(props: IRadioGroup) {
  const classes = RadioGroupStyles()
  return (
    <div style={props.marginLeft ? { marginLeft: props.marginLeft } : { marginLeft: 10 }}>
      <RadioGroup
        id={props.id}
        classFormLabel={props.classNameFormLabel ?? classes.radioGroup_label}
        title={props.title}
        classDescription={props.className ?? props.withoutMarginleft ? classes.radioGroup_label_withoutMarginLeft : classes.radioGroup_label}
        display={props.orientation !== undefined ? props.orientation : displayPosition.vertical}
        description={props.subTitle || ""}
        radioColor={Colors.primary}
        onSelectionChange={props.onSelectionChange ?? (() => { })}
        options={props.filaArray}
        iconRadioSize={props.iconRadioSize || "small"}
        selection={props.selection}
        classFormControl={props.className ?? classes.radioGroup_label}
      />
    </div>
  )
}

export default RadioBoxGroup