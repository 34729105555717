/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Box, Button, CircularProgress, Grid, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { AddressDTO, CommunityDTO, ProvinceDTO, StreetDTO, TownDTO } from "../../../../../../../clients";
import TextFieldGrid from "../../../../../../../components/text-fields/textfield-grid";
import { filterOptions } from "../../../../../../../utils/arrayHelper";
import ReportViewModel from "../../../../../../../viewmodels/report/report-view-model";
import { scrollTo } from "../../../../../../../utils/uiHelper";
import { ReportStyle } from "../../../../../report-style";

export const EditingAddress: React.FC<{ vm: ReportViewModel; saveAddress: (address: AddressDTO) => void }> = observer(({ vm, saveAddress }) => {
  const classes = ReportStyle();
  const [address, setAddress] = useState<AddressDTO | null>({ ...vm.locationInfo?.address });
  const [provinces, setProvinces] = useState<ProvinceDTO[] | null>([]);
  const [towns, setTowns] = useState<TownDTO[] | null>([]);
  const [communities, setCommunites] = useState<CommunityDTO[] | null>([]);
  const [streets, setStreets] = useState<StreetDTO[] | null>([]);

  const getCommunities = async () => {
    const c = await vm.parent.communityRepository.getAllCommunity().catch((e) => []);
    setCommunites(c);
    return c;
  };

  const getProvinces = async (communityCode: string) => {
    const result = await vm.parent.communityRepository.getProvinceByCommunityCode(communityCode).catch((e) => []);
    setProvinces(result);
    return result;
  };

  const getTowns = async (provinceCode: string) => {
    const result = await vm.parent.provincesRepository.getAllTownByProvinceCode(provinceCode).catch((e) => []);
    setTowns(result);
    return result;
  };

  const getStreets = async (townId: string) => {
    const result = await vm.parent.townRepository.getStreetsByTownId(townId).catch((e) => []);
    setStreets(result.sort((a, b) => a.name.localeCompare(b.name)));
    return result;
  };

  useEffect(() => {
    if (address?.town) {
      const town = towns?.find((t) => t.name === address?.town);
      if (town?.id) getStreets(town.id);
    }
  }, [address?.town]);

  useEffect(() => {
    if (address?.province) {
      const province = provinces?.find((p) => p.name === address?.province);
      if (province?.code) getTowns(province.code);
    }
  }, [address?.province]);

  useEffect(() => {
    if (address?.community) {
      const community = communities?.find((c) => c.name === address?.community);
      if (community?.code) getProvinces(community.code);
    }
  }, [address?.community]);

  useEffect(() => {
    getActualFullAddress();
    return () => {
      setProvinces(null);
      setTowns(null);
      setCommunites(null);
      setStreets(null);
    };
  }, []);

  const getActualFullAddress = async () => {
    const c = await getCommunities();
    if (address?.community && address?.province && address?.town && address?.streetName) {
      const community = c.find((c) => c.name === address.community);
      if (community) {
        const p = await getProvinces(community.code);
        const province = p.find((p) => p.name === address.province);
        if (province?.code) {
          const t = await getTowns(province.code);
          const town = t.find((t) => t.name === address.town);
          if (town?.id) {
            await getStreets(town.id);
          }
        }
      }
    }
  };

  return (
    <>
      {!provinces && !towns && !communities ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container display={"flex"}>
          <Grid item xs={12} md={12} className={classes.report_generalDataGrid}>
            <Autocomplete
              id="locationCommnunity"
              options={communities || []}
              disablePortal
              fullWidth
              onFocus={(e: any) => {
                scrollTo(320);
              }}
              loading={!communities}
              value={{ name: address?.community }}
              clearIcon={<></>}
              getOptionLabel={(option: CommunityDTO) => option.name ?? ""}
              renderInput={(params) => (
                <TextField {...params} className={classes.report_autocomplete} size="small" label={vm.translate("report.block0.ccaa")} />
              )}
              onChange={(event: any, value: any) => {
                if (address && value) {
                  setAddress({ ...address, community: value.name });
                }
              }}
              style={{ marginRight: 5 }}
            />

            <Autocomplete
              disablePortal
              id="locationProvinces"
              options={provinces || []}
              fullWidth
              value={{ name: address?.province }}
              clearIcon={<></>}
              onFocus={(e: any) => {
                scrollTo(320);
              }}
              getOptionLabel={(option: ProvinceDTO) => option.name ?? ""}
              renderInput={(params) => (
                <TextField {...params} className={classes.report_autocomplete} size="small" label={vm.translate("report.block0.province")} />
              )}
              onChange={(event: any, value: any) => {
                if (address && value) {
                  setAddress({ ...address, province: value.name });
                }
              }}
              style={{ marginRight: 5 }}
            />

            <Autocomplete
              disablePortal
              onFocus={(e: any) => {
                scrollTo(320);
              }}
              id="locationTown"
              options={towns || []}
              fullWidth
              value={{ name: address?.town }}
              clearIcon={<></>}
              getOptionLabel={(option: any) => option.name}
              renderInput={(params) => (
                <TextField {...params} className={classes.report_autocomplete} size="small" label={vm.translate("report.block0.municipality")} />
              )}
              onChange={(event: any, value: any) => {
                if (address && value) {
                  setAddress({ ...address, town: value.name });
                }
              }}
              style={{ marginRight: 5 }}
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ display: "flex" }}>
            <React.Fragment>
              <Autocomplete
                disablePortal
                onFocus={(e: any) => {
                  scrollTo(320);
                }}
                id="locationStreets"
                options={streets || []}
                fullWidth
                filterOptions={filterOptions}
                value={{ name: `${address?.streetType}, ${address?.streetName}` }}
                clearIcon={<></>}
                getOptionLabel={(option: any) => option.name}
                renderInput={(params) => (
                  <TextField {...params} size="small" className={classes.report_autocomplete} label={vm.translate("report.block1.address")} />
                )}
                onChange={(event: any, value: any) => {
                  if (value && address) {
                    setAddress({ ...address, streetName: value.name, streetType: value.type, streetCode: value.code });
                  }
                }}
                style={{ paddingTop: 10, marginRight: 5 }}
              />
              <TextFieldGrid
                id="locationNumber"
                title={"Nº"}
                value={address?.portalNumber || ""}
                gridTextField={{ xs: 3, md: 3 }}
                onChange={(event: any) => {
                  if (address) {
                    setAddress({ ...address, portalNumber: event.target.value });
                  }
                }}
                style={{ marginRight: 5 }}
              />
              <TextFieldGrid
                id="locationPostalCode"
                title={vm.translate("report.block0.cp")}
                value={address?.postalCode?.toString() || ""}
                gridTextField={{ xs: 3, md: 3 }}
                onChange={(event: any) => {
                  if (address) {
                    setAddress({ ...address, postalCode: event.target.value });
                  }
                }}
              />
            </React.Fragment>
          </Grid>
          <Button style={{ marginTop: "1em" }} variant="contained" size="small" onClick={() => address && saveAddress(address)}>
            Guardar dirección
          </Button>
        </Grid>
      )}
    </>
  );
});
