import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import TextAreaGrid from "../../../../../../components/text-area";
import TextFieldGrid from "../../../../../../components/text-fields/textfield-grid";
import TypographyTitle from "../../../../../../components/typography-title";
import ReportViewModel from "../../../../../../viewmodels/report/report-view-model";
import { ReportStyle } from "../../../../report-style";



export const UrbanDataSection: React.FC<{ vm: ReportViewModel }> = observer(({ vm }) => {
  const classes = ReportStyle();

  return (
    <React.Fragment>
      <Grid item xs={12} md={12} className={classes.report_displayGridTitle}>
        <TypographyTitle id="urbanDataTitle" title={vm.translate("report.block0.uban.data")} />

        <TextFieldGrid
          id="urbanDataApproachInForce"
          title={vm.translate("report.block0.approach.in.force")}
          onChange={(evt: any) => {
            const value = evt.target.value
            vm.urbanData.currentPlanning = value
          }}
          value={vm.urbanData.currentPlanning}
          gridTextField={{ xs: 6, md: 6 }}
          charLimit={50}
        />
        <TextFieldGrid
          id="urbanDataApproachClassification"
          title={vm.translate("report.block0.classification")}
          onChange={(evt: any) => {
            const value = evt.target.value
            vm.urbanData.classification = value
          }
          }
          value={vm.urbanData.classification}
          gridTextField={{ xs: 6, md: 6 }}
          charLimit={100}
        />
        <TextFieldGrid
          id="urbanDataApproachOrdinance"
          title={vm.translate("report.block0.ordinance")}
          onChange={(evt: any) => {
            const value = evt.target.value
            vm.urbanData.ordinance = value
          }}
          value={vm.urbanData.ordinance}
          gridTextField={{ xs: 6, md: 6 }}
          charLimit={100}
        />
        <Grid item xs={12} md={12}>
          <TextAreaGrid
            id="urbanDataObservations"
            rows={1}
            label={vm.translate("report.block0.observations")}
            onChange={(evt: any) => (vm.urbanData.notes = evt.target.value)}
            value={vm.urbanData.notes}
            style={{ marginRight: 5 }}
            gridTextField={{ xs: 12, md: 12 }}
            charLimit={500}
          />
          <TextAreaGrid
            id="urbanDataProtectedElements"
            rows={1}
            label={vm.translate("report.block0.protected.elements")}
            onChange={(evt: any) => (vm.urbanData.protectedElements = evt.target.value)}
            value={vm.urbanData.protectedElements}
            style={{ marginRight: 5 }}
            gridTextField={{ xs: 12, md: 12 }}
            charLimit={500}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
});
