import { ISessionStore } from "../session-store-interface";
import User from "../../../models/current-user";

export const sessionStorageSessionStore = (): ISessionStore => {
  const sessionStore: ISessionStore = {
    getToken() {
      return localStorage.getItem('token');
    },

    setToken(token: string) {
      sessionStorage.setItem("token", token);
    },

    deleteToken() {
      sessionStorage.removeItem("token");
    },

    getUser() {
      return sessionStorage && sessionStorage.getItem('user') !== null ? JSON.parse(sessionStorage.getItem('user')!!) : undefined;
    },

    setUser(curentUser: User) {
      sessionStorage.setItem("user", JSON.stringify(curentUser));
    },

    deleteUser() {
      sessionStorage.removeItem("user");
    },

    isLoggedIn: async () => {
      const token = sessionStorage.getItem('token');
      return !!(token && token.length > 0);
    },

    getCurrentPath: async () => {
      if (!sessionStorage.getItem('currentPath'))
        sessionStorage.setItem('currentPath', "/");
      return sessionStorage.getItem('currentPath')!!;
    },

    setCurrentPath(path: string) {
      sessionStorage.setItem('currentPath', path);
    },
  }
  return sessionStore;
}