import { BaseAPI, Configuration, ConfigurationParameters } from "../../clients/runtime";
import { NotImplementedException } from "../exceptions/not-implemented-exception";
import { Network } from "../network";
import { SessionStoreFactory } from "./session-store-factory";

export enum Api {
  BookApi,
  MapApi,
  ConfigApi,
  CadastralApi,
  UsersApi,
  BuildingApi,
  ParcelsApi,
  PropertiesApi,
  TechniciansApi,
  CommunityApi,
  ProvincesApi,
  TownApi,
  CollegeApi,
  ImprovementActionsApi,
  MailApi,
  UserManagementApi,
  CollegeAdministratorApi,
  MediaApi,
  CollegeAdminActionsApi,
  AdminActionsApi,
  UsersProfileApi
}

export default class ApiClient {
  public static register(key: Api, api: BaseAPI, mock: boolean = false) {
    mock ? ApiClient.mockApis.set(key, api) : ApiClient.apis.set(key, api);
  }

  private static apis = new Map<Api, BaseAPI>();
  private static mockApis = new Map<Api, BaseAPI>();

  private static clientConfig(token?: string | undefined | null): Configuration {
    const params: ConfigurationParameters = {};
    params.basePath = Network.BACKEND;

    if (token) params.apiKey = "Bearer " + token;
    params.headers = {
      "Accept": "application/json",
      "Content-Type": "application/json",
    };
    return new Configuration(params);
  }

  public static async clientFor<T>(type: Api, mock: boolean = false) {
    const api = mock ? ApiClient.mockApis.get(type) : ApiClient.apis.get(type);
    const constuctor = api?.constructor as any;
    const token = await SessionStoreFactory.getSessionStore().getToken()
    /*? await SessionStoreFactory.getSessionStore().getToken()
    : undefined;*/
    if (constuctor) {
      return new constuctor(this.clientConfig(token)) as T;
    } else throw new NotImplementedException();
  }
}