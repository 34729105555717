import {  CollegeAdministratorsApi as CollegeAdministratorService, CreateCollegeAdministratorRequest, CollegeAdministratorDTO, GetAllCollegeAdministratorRequest, UpdateCollegeAdministratorRequest } from "../../../clients"
import ICollegeAdministratorApi from "../icollege-administrator-api";
import { BaseAPI, Configuration } from '../../../clients/runtime';

export class CollegeAdministratorApi extends BaseAPI implements ICollegeAdministratorApi {
    apiClient: CollegeAdministratorService;

    constructor(configuration?: Configuration) {
        super(configuration);
        this.apiClient = new CollegeAdministratorService(configuration);
    }


    async createCollegeAdministrator(requestParameters: CreateCollegeAdministratorRequest): Promise<CollegeAdministratorDTO> {
        const response = await this.apiClient.createCollegeAdministrator(requestParameters);
        return response;
    }

    

    async deleteCollegeAdministrator(id: string): Promise<CollegeAdministratorDTO> {
        const response = await this.apiClient.deleteCollegeAdministrator({ id: id });
        return response;
    }

    async getAllCollegeAdministrator(requestParameters: GetAllCollegeAdministratorRequest): Promise<CollegeAdministratorDTO[]> {
        const response = await this.apiClient.getAllCollegeAdministrator(requestParameters);
        return response;
    }

    async getCollegeAdministratorByCollege(college: string): Promise<CollegeAdministratorDTO> {
        const response = await this.apiClient.getCollegeAdministratorByCollege({ college: college });
        return response
    }
    async getCollegeAdministratorByID(id: string): Promise<CollegeAdministratorDTO> {
        const response = await this.apiClient.getCollegeAdministratorByID({ id: id });
        return response
    }

    async updateCollegeAdministrator(requestParameters: UpdateCollegeAdministratorRequest): Promise<CollegeAdministratorDTO> {
        const response = await this.apiClient.updateCollegeAdministrator(requestParameters);
        return response
    }
}