import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import detector from "i18next-browser-languagedetector"

const en = require('../../assets/locales/en.json') //Import json file from assets
const es = require('../../assets/locales/es.json')

const resources = {
  en: { translation: en }, //Register any other language
  es: { translation: es },
};

i18n
  .use(detector)
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    backend: {
      loadPath: "../../assets/locales/{{lng}}.json"
    },
    lng: "es",
    fallbackLng: "es", // use en if detected lng is not available
    keySeparator: false, //'.',
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      //wait: true
      useSuspense: false
    }
  })

export default i18n