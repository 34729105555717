import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import ReportViewModel from "../../../../../viewmodels/report/report-view-model";
import { GeneralBuildingData } from "./components/general-building-data/general-building-data";
import { GraphicDocumentationSection } from "./components/graphic-documentation";
import { MapSection } from "./components/map-section";
import { RadioGroupSectin } from "./components/radio-groups";
import { ReferenceCadastralGenerator } from "./components/reference-cadastral-generator/reference-cadastral-generator";
import { LocationSection } from "./components/location-section";

export const ReportBuildingIdentification: React.FC<{ vm: ReportViewModel }> = observer(({ vm }) => {
  return (
    <Grid container>
      <ReferenceCadastralGenerator vm={vm} />
      {(vm.parent.isManual || vm.userBook?.id !== "") && (
        <React.Fragment>
          <LocationSection vm={vm} />
          <GeneralBuildingData vm={vm} />
          <GraphicDocumentationSection vm={vm} />
          <MapSection vm={vm} />
          <RadioGroupSectin vm={vm} />
        </React.Fragment>
      )}
    </Grid>
  );
});
