import { bound } from '@frui.ts/helpers';
import { watchBusy } from '@frui.ts/screens';
import { interfaces } from 'inversify';
import { action } from 'mobx';
import { BookDTO } from '../../clients';
import { BookRepository } from '../../data/repositories/impl/book-repository';
import DetailViewModelBase from '../detail-view-model-base';
import ReportViewModel from './report-view-model';

// @Router.registerRoute({ name: Router.Self, route: ':id' })
export default class ReportDetailViewModel extends DetailViewModelBase<BookDTO> {
	parent: ReportViewModel = this.parent;
	title?: string;
	isEditing: boolean = false;

	constructor(private repository: BookRepository, public item: BookDTO) {
		super();
		this.loadAvailableActions();
		this.navigationName = item.id === '-1' ? 'new' : item.id!.toString();
		
		this.item = item;
	}

	@bound
	ta(attributeName: string) {
		return 'no translator';
	}

	@action.bound
	createService() {
		this.parent.items.push(this.item!);
	}

	resetNewService() {}

	updateService() {
		// @ts-ignore
		this.parent.items[this.item] = this.item;
		let found = this.parent.items.filter((i) => i.id === this.item.id)[0];
		if (found) {
			found = { ...this.item };
			this.parent.items.push(found);
		}
	}

	setCurrentItemValue<T>(key: keyof BookDTO, value: T) {
		if (this.item && key)
			// @ts-ignore
			this.item[key] = value;
	}

	async loadAvailableActions() {
		// this.availableActions = await this.repository.getAvailableActions();
	}

	async loadDetail() {
		return this.item;
	}

	@bound
	@watchBusy
	async save() {
		await this.requestClose();
	}

	static Factory({ container }: interfaces.Context) {
		return (repository: BookRepository, item: BookDTO) => new ReportDetailViewModel(repository, item);
	}
}
