/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RoofStructureDTO
 */
export interface RoofStructureDTO {
    /**
     * 
     * @type {number}
     * @memberof RoofStructureDTO
     */
    board?: number;
    /**
     * 
     * @type {number}
     * @memberof RoofStructureDTO
     */
    horizontalFraming?: number;
    /**
     * 
     * @type {number}
     * @memberof RoofStructureDTO
     */
    inclinedFraming?: number;
    /**
     * 
     * @type {string}
     * @memberof RoofStructureDTO
     */
    inclinedFramingUnknownOthersValue?: string;
    /**
     * 
     * @type {number}
     * @memberof RoofStructureDTO
     */
    others?: number;
    /**
     * 
     * @type {string}
     * @memberof RoofStructureDTO
     */
    othersUnknownOthersValue?: string;
    /**
     * 
     * @type {number}
     * @memberof RoofStructureDTO
     */
    trussAndPorticos?: number;
}

export function RoofStructureDTOFromJSON(json: any): RoofStructureDTO {
    return RoofStructureDTOFromJSONTyped(json, false);
}

export function RoofStructureDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoofStructureDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'board': !exists(json, 'board') ? undefined : json['board'],
        'horizontalFraming': !exists(json, 'horizontalFraming') ? undefined : json['horizontalFraming'],
        'inclinedFraming': !exists(json, 'inclinedFraming') ? undefined : json['inclinedFraming'],
        'inclinedFramingUnknownOthersValue': !exists(json, 'inclinedFramingUnknownOthersValue') ? undefined : json['inclinedFramingUnknownOthersValue'],
        'others': !exists(json, 'others') ? undefined : json['others'],
        'othersUnknownOthersValue': !exists(json, 'othersUnknownOthersValue') ? undefined : json['othersUnknownOthersValue'],
        'trussAndPorticos': !exists(json, 'trussAndPorticos') ? undefined : json['trussAndPorticos'],
    };
}

export function RoofStructureDTOToJSON(value?: RoofStructureDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'board': value.board,
        'horizontalFraming': value.horizontalFraming,
        'inclinedFraming': value.inclinedFraming,
        'inclinedFramingUnknownOthersValue': value.inclinedFramingUnknownOthersValue,
        'others': value.others,
        'othersUnknownOthersValue': value.othersUnknownOthersValue,
        'trussAndPorticos': value.trussAndPorticos,
    };
}

