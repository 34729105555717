import { Api } from "../../../infrastructure/data/api-client";
import { BaseRepository } from "../../../infrastructure/data/repositories/base-repository";
import IProvincesApi from "../iprovinces-api";

export default class ProvincesRepository extends BaseRepository<IProvincesApi> {
  constructor() {
    super(Api.ProvincesApi, false);
  }

  async getProvinces() {
    const client = await this.apiClient;
    return await client.getProvinces();
  }

  async getProvinceByCode(code: string) {
    const client = await this.apiClient;
    return await client.getProvinceByCode(code);
  }

  async getAllTownByProvinceCode(code: string) {
    const client = await this.apiClient;
    return await client.getTownsForProvinceCode(code);
  }

  async getProvinceByName(name: string) {
    const client = await this.apiClient;
    return await client.getProvinceByName(name);
  }
}
