/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FilterDTO
 */
export interface FilterDTO {
    /**
     * 
     * @type {string}
     * @memberof FilterDTO
     */
    colleges?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterDTO
     */
    idNumbers?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterDTO
     */
    names?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterDTO
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterDTO
     */
    surnames?: string;
}

export function FilterDTOFromJSON(json: any): FilterDTO {
    return FilterDTOFromJSONTyped(json, false);
}

export function FilterDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'colleges': !exists(json, 'colleges') ? undefined : json['colleges'],
        'idNumbers': !exists(json, 'idNumbers') ? undefined : json['idNumbers'],
        'names': !exists(json, 'names') ? undefined : json['names'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'surnames': !exists(json, 'surnames') ? undefined : json['surnames'],
    };
}

export function FilterDTOToJSON(value?: FilterDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'colleges': value.colleges,
        'idNumbers': value.idNumbers,
        'names': value.names,
        'postalCode': value.postalCode,
        'surnames': value.surnames,
    };
}

