/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MediaDTO,
    MediaDTOFromJSON,
    MediaDTOFromJSONTyped,
    MediaDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface LicenseDTO
 */
export interface LicenseDTO {
    /**
     * 
     * @type {string}
     * @memberof LicenseDTO
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseDTO
     */
    date?: string;
    /**
     * 
     * @type {Array<MediaDTO>}
     * @memberof LicenseDTO
     */
    files?: Array<MediaDTO>;
    /**
     * 
     * @type {string}
     * @memberof LicenseDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseDTO
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseDTO
     */
    scope?: string;
    /**
     * 
     * @type {string}
     * @memberof LicenseDTO
     */
    technicians?: string;
}

export function LicenseDTOFromJSON(json: any): LicenseDTO {
    return LicenseDTOFromJSONTyped(json, false);
}

export function LicenseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LicenseDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'files': !exists(json, 'files') ? undefined : ((json['files'] as Array<any>).map(MediaDTOFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
        'technicians': !exists(json, 'technicians') ? undefined : json['technicians'],
    };
}

export function LicenseDTOToJSON(value?: LicenseDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'date': value.date,
        'files': value.files === undefined ? undefined : ((value.files as Array<any>).map(MediaDTOToJSON)),
        'id': value.id,
        'name': value.name,
        'notes': value.notes,
        'scope': value.scope,
        'technicians': value.technicians,
    };
}

