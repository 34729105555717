/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UsagePlanActionDTO
 */
export interface UsagePlanActionDTO {
    /**
     * 
     * @type {string}
     * @memberof UsagePlanActionDTO
     */
    checked?: UsagePlanActionDTOCheckedEnum;
    /**
     * 
     * @type {string}
     * @memberof UsagePlanActionDTO
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UsagePlanActionDTO
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof UsagePlanActionDTO
     */
    item?: number;
    /**
     * 
     * @type {string}
     * @memberof UsagePlanActionDTO
     */
    mandatory?: UsagePlanActionDTOMandatoryEnum;
    /**
     * 
     * @type {string}
     * @memberof UsagePlanActionDTO
     */
    roleInCharge?: UsagePlanActionDTORoleInChargeEnum;
}

/**
* @export
* @enum {string}
*/
export enum UsagePlanActionDTOCheckedEnum {
    Checked = 'CHECKED',
    NotChecked = 'NOT_CHECKED'
}/**
* @export
* @enum {string}
*/
export enum UsagePlanActionDTOMandatoryEnum {
    Mandatory = 'MANDATORY',
    Recommended = 'RECOMMENDED'
}/**
* @export
* @enum {string}
*/
export enum UsagePlanActionDTORoleInChargeEnum {
    GeneralTechnician = 'GENERAL_TECHNICIAN',
    Inspector = 'INSPECTOR',
    InspectorTechnician = 'INSPECTOR_TECHNICIAN',
    MaintenanceTechnician = 'MAINTENANCE_TECHNICIAN',
    Specialist = 'SPECIALIST',
    User = 'USER'
}

export function UsagePlanActionDTOFromJSON(json: any): UsagePlanActionDTO {
    return UsagePlanActionDTOFromJSONTyped(json, false);
}

export function UsagePlanActionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsagePlanActionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'checked': !exists(json, 'checked') ? undefined : json['checked'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'item': !exists(json, 'item') ? undefined : json['item'],
        'mandatory': !exists(json, 'mandatory') ? undefined : json['mandatory'],
        'roleInCharge': !exists(json, 'roleInCharge') ? undefined : json['roleInCharge'],
    };
}

export function UsagePlanActionDTOToJSON(value?: UsagePlanActionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'checked': value.checked,
        'description': value.description,
        'id': value.id,
        'item': value.item,
        'mandatory': value.mandatory,
        'roleInCharge': value.roleInCharge,
    };
}

