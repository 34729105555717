/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MovicodersBadRequestException,
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundException,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceException,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
    ProvinceDTO,
    ProvinceDTOFromJSON,
    ProvinceDTOToJSON,
    TownDTO,
    TownDTOFromJSON,
    TownDTOToJSON,
} from '../models';

export interface GetProvinceByCodeRequest {
    code: string;
}

export interface GetProvinceByNameRequest {
    name: string;
}

export interface GetTownsForProvinceCodeRequest {
    code: string;
}

/**
 * 
 */
export class ProvincesApi extends runtime.BaseAPI {

    /**
     * Return the full province
     * Get province by code
     */
    async getProvinceByCodeRaw(requestParameters: GetProvinceByCodeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProvinceDTO>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling getProvinceByCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/provinces/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProvinceDTOFromJSON(jsonValue));
    }

    /**
     * Return the full province
     * Get province by code
     */
    async getProvinceByCode(requestParameters: GetProvinceByCodeRequest, initOverrides?: RequestInit): Promise<ProvinceDTO> {
        const response = await this.getProvinceByCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return the full province
     * Get province by name
     */
    async getProvinceByNameRaw(requestParameters: GetProvinceByNameRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ProvinceDTO>>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getProvinceByName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/provinces/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProvinceDTOFromJSON));
    }

    /**
     * Return the full province
     * Get province by name
     */
    async getProvinceByName(requestParameters: GetProvinceByNameRequest, initOverrides?: RequestInit): Promise<Array<ProvinceDTO>> {
        const response = await this.getProvinceByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all provinces
     * Get provinces list
     */
    async getProvincesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ProvinceDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/provinces`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProvinceDTOFromJSON));
    }

    /**
     * Returns all provinces
     * Get provinces list
     */
    async getProvinces(initOverrides?: RequestInit): Promise<Array<ProvinceDTO>> {
        const response = await this.getProvincesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns all towns of a given province by code
     * Get towns list for province
     */
    async getTownsForProvinceCodeRaw(requestParameters: GetTownsForProvinceCodeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<TownDTO>>> {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling getTownsForProvinceCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/provinces/{code}/towns`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TownDTOFromJSON));
    }

    /**
     * Returns all towns of a given province by code
     * Get towns list for province
     */
    async getTownsForProvinceCode(requestParameters: GetTownsForProvinceCodeRequest, initOverrides?: RequestInit): Promise<Array<TownDTO>> {
        const response = await this.getTownsForProvinceCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
