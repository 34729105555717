
import { makeStyles } from '@mui/styles'
import { Colors } from '../../../../constants'

export const CardDetailStyles = makeStyles(() => ({
  cardMap_card: {
    '& .MuiCard-root': {
      maxWidth: '100% !important',
      maxHeight: '600px'
    },
    '& .MuiCardHeader-title': {
      fontSize: "20px !important",
      color: Colors.primary,
      fontWeight: "bold"
    },
    '& .MuiCardContent-root': {
      paddingTop: 0,
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 16,
    },
    maxWidth: "26%",
    zIndex: 1,
    width: "auto",
    position: "absolute",
    justifyContent: "center",
    textAlign: "left",
    alignItems: "center",
    marginLeft: "12px",
    opacity: "80%",
    fontSize: "12px !important",
  },
  cardMap_text: {
    fontSize: "13px !important",
  },
  cardMap_cardTittle: {
    marginBottom: "-10px",
    lineHeight: "80%"
  },
  cardMap_tittle: {
    fontWeight: "bold",
    padding: "19"
  }
}))