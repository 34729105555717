import { BookMarkerDTO } from './../../clients/models/BookMarkerDTO';
import { action, observable } from "mobx"
import RootViewModel from "../root-view-model"
import { ArquitectsMarker } from "../../services/dashboard-service"
import { BusyWatcher, ConductorOneChildActive, Router, ScreenBase, watchBusy } from "@frui.ts/screens"
import MapRepository from "../../data/repositories/impl/map-repository"

@Router.registerRoute({ name: Router.Self, route: "dashboard" })
export default class DashboardViewModel extends ConductorOneChildActive<ScreenBase>{
  navigationName = "dashboard"
  nameValue = "section.map"
  parent = this.parent as RootViewModel
  public showInToolbar: boolean = true;
  busyWatcher = new BusyWatcher()
  @observable loading = false;
  @observable loadingMap = true;
  @observable navigationPath: string = '';
  @observable yearComboboxData: string[] = ["5", "10", "20", "30", "40"]
  @observable markerSelected?: any
  @observable filteredItems: ArquitectsMarker[] = observable.array([])
  @observable markers: ArquitectsMarker[] = observable.array([]);
  @observable itemLayers: Map<string, any>
  @observable latitude: number = 41.6563
  @observable longitude: number = -0.876566
  layers: Map<string, any[]>
  layersNeighborhood: Map<string, any[]>
  @observable districtFiles: string[] = observable.array([]);
  @observable townHallLogo?: any
  @observable energyEfficiencyFilter: string = ''
  @observable allMarkers: BookMarkerDTO[] = observable.array([]);
  @observable filteredMarkers:any[] = []
  constructor(
    public mapService: MapRepository,
  ) {
    super();
    this.itemLayers = new Map()
    this.layers = new Map()
    this.layersNeighborhood = new Map()
    this.setFilterdMarkers(this.allMarkers)
    this.filteredMarkers = [...this.allMarkers] as any
    window.addEventListener('navigated', (e: any) => {
			this.setNavigationPath(e.detail.path);
		})

  }

  @action
	setNavigationPath = (path: string) => {
		this.navigationPath = path;
	};


  @action.bound addLayer(id: string, polygon: any) {
    if (polygon)
      this.itemLayers.set(id, polygon)
  }

  @action.bound async loadMarkers() { 
    const result = await this.mapService.getMarkers()
    this.allMarkers = result
  }

  @action.bound
  @watchBusy
  async loadData() { }

  @action.bound
  @watchBusy
  async loadDistricts() { }

  @action.bound async handleFileContent(fileId?: string, fileType?: string) {
    let localMap
    if (fileId) {
      localMap = localStorage.getItem(fileId)
      if (localMap) {
        localMap = JSON.parse(localMap)
      } 
    }
  }

  @action.bound async loadCoordinates() {
   /* try {
      const enviromentCity = await this.configService.loadEnviromentCity()
      this.latitude = enviromentCity.latitude!!
      this.longitude = enviromentCity.longitude!!
    } catch (e: any) {
      if (e?.status === 400) {
        this.loadingMap = false
      }
    }*/
  }

  @action.bound async setMarkerSelected(item: any | undefined) {
    this.markerSelected = item
  }

  @action.bound setEnergyEfficiencyFilter(ef: string){
    this.energyEfficiencyFilter = ef
    if(ef === undefined || ef === null){
      this.setFilterdMarkers(this.allMarkers)
      return
    } else {
      this.filteredMarkers = this.allMarkers.filter((m: any) => m.efficiency === ef)
    }
  }

  @action.bound setFilterdMarkers(markers: any[]){
    this.filteredMarkers = [...markers];
  }

  @action.bound setFilteredMarkers(filterResult: ArquitectsMarker[]) {
    this.filteredItems.splice(0, this.filteredItems.length)
    this.itemLayers.clear()
    filterResult.forEach(item => {
      this.filteredItems.push(item)
      this.addLayer(item.id, item.polygon)
    })
  }

  async findNavigationChild(navigationName: string): Promise<any> {
    if (navigationName === undefined || navigationName === this.navigationName) {
      this.parent.tryActivateChild(this);
      this.activeChild?.requestClose();
      return undefined;
    } else return undefined
  }
 
  translate(text: string) {
    this.parent.language = this.parent.configurationService.language
    return (this.parent as RootViewModel).configurationService.translate(text);
  }
}