/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FoundationDTO
 */
export interface FoundationDTO {
    /**
     * 
     * @type {number}
     * @memberof FoundationDTO
     */
    deepFounding?: number;
    /**
     * 
     * @type {string}
     * @memberof FoundationDTO
     */
    deepFoundingUnknownOthersValue?: string;
    /**
     * 
     * @type {string}
     * @memberof FoundationDTO
     */
    notes?: string;
    /**
     * 
     * @type {number}
     * @memberof FoundationDTO
     */
    restraintSystems?: number;
    /**
     * 
     * @type {string}
     * @memberof FoundationDTO
     */
    restraintSystemsUnknownOthersValue?: string;
    /**
     * 
     * @type {number}
     * @memberof FoundationDTO
     */
    surfaceFounding?: number;
    /**
     * 
     * @type {string}
     * @memberof FoundationDTO
     */
    surfaceFoundingUnknownOthersValue?: string;
}

export function FoundationDTOFromJSON(json: any): FoundationDTO {
    return FoundationDTOFromJSONTyped(json, false);
}

export function FoundationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FoundationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deepFounding': !exists(json, 'deepFounding') ? undefined : json['deepFounding'],
        'deepFoundingUnknownOthersValue': !exists(json, 'deepFoundingUnknownOthersValue') ? undefined : json['deepFoundingUnknownOthersValue'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'restraintSystems': !exists(json, 'restraintSystems') ? undefined : json['restraintSystems'],
        'restraintSystemsUnknownOthersValue': !exists(json, 'restraintSystemsUnknownOthersValue') ? undefined : json['restraintSystemsUnknownOthersValue'],
        'surfaceFounding': !exists(json, 'surfaceFounding') ? undefined : json['surfaceFounding'],
        'surfaceFoundingUnknownOthersValue': !exists(json, 'surfaceFoundingUnknownOthersValue') ? undefined : json['surfaceFoundingUnknownOthersValue'],
    };
}

export function FoundationDTOToJSON(value?: FoundationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deepFounding': value.deepFounding,
        'deepFoundingUnknownOthersValue': value.deepFoundingUnknownOthersValue,
        'notes': value.notes,
        'restraintSystems': value.restraintSystems,
        'restraintSystemsUnknownOthersValue': value.restraintSystemsUnknownOthersValue,
        'surfaceFounding': value.surfaceFounding,
        'surfaceFoundingUnknownOthersValue': value.surfaceFoundingUnknownOthersValue,
    };
}

