import { registerView, ViewComponent } from "@frui.ts/views";
import { Grid, Paper, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Button, TypographyTheme } from "@movicoders/movicoders-components";
import ResetViewModel from "../../viewmodels/reset/reset-view-model";
import { useResetStyles } from "./reset-style";
import { Colors, FontSizes } from "../../constants";
import BackDropLoading from "../../components/backdrop-loading";

const ResetView: ViewComponent<ResetViewModel> = observer(({ vm }) => {
  const classes = useResetStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const handleOpenSnackBar = (
    snackKey: string,
    variants: undefined | "default" | "error" | "success" | "warning" | "info",
    hideDuration: number = 3000
  ) => {
    enqueueSnackbar(snackKey, { variant: variants, preventDuplicate: true, key: snackKey, autoHideDuration: hideDuration });
  };

  const resetPassword = async () => {
    if (password.length > 0 && password === passwordConfirm) {
      try {
        setLoading(true);
        await vm.resetPassword(password);
        sessionStorage.clear();
        handleOpenSnackBar(vm.translate("reset.password.confirmation"), "success", 13000);
      } catch (error) {
        console.log(error);
        handleOpenSnackBar(vm.translate("reset.password.confirmation.error"), "error");
        setLoading(false);
      }
      setLoading(false);
      vm.parent.navigate("login", {});
    } else {
      handleOpenSnackBar(vm.translate("reset.password.coincidence.error"), "info");
    }
  };

  return (
    <React.Fragment>
      <BackDropLoading showLoading={loading} />
      <Grid container className={classes.login_container}>
        <Grid item xs={12} md={12} className={classes.login_grid}>
          <Paper id="paperLoginView" className={classes.login_paper}>
            <Grid container style={{ width: "600px", height: "250px", paddingLeft: "40px", paddingRight: "40px", marginTop: "-20px" }}>
              <Grid item xs={12} style={{ textAlign: "start", height: "50px" }}>
                <TypographyTheme
                  text={vm.translate("reset.password")}
                  id="recutitle"
                  backgroundColor="none"
                  bold
                  size={FontSizes.h1}
                  color={Colors.primary}
                />
              </Grid>
              <Grid item xs={12} style={{ textAlign: "start", height: "50px" }}>
                <TypographyTheme
                  text={vm.translate("reset.new.password")}
                  id="recudescr"
                  backgroundColor="none"
                  bold
                  size={FontSizes.h4}
                  color={Colors.primary}
                />{" "}
              </Grid>
              <Grid item xs={12} style={{ display: "inline-flex", justifyContent: "space-between", height: "50px" }}>
                <TextField type="password" placeholder={vm.translate("launch.password")} onChange={(e) => setPassword(e.target.value)}></TextField>
                <TextField
                  type="password"
                  placeholder={vm.translate("user.repeatPassword")}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                ></TextField>
              </Grid>
              <Grid item style={{ marginTop: "32px", display: "inline-flex", justifyContent: "flex-start" }} xs={12}>
                <Button onClick={resetPassword} text="Cambiar contraseña" id="changepassbtn" />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

registerView(ResetView, ResetViewModel);
