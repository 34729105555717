/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Field
 */
export interface Field {
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    key?: FieldKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    order?: FieldOrderEnum;
    /**
     * 
     * @type {number}
     * @memberof Field
     */
    position?: number;
}

/**
* @export
* @enum {string}
*/
export enum FieldKeyEnum {
    Status = 'STATUS'
}/**
* @export
* @enum {string}
*/
export enum FieldOrderEnum {
    Asc = 'ASC',
    Desc = 'DESC'
}

export function FieldFromJSON(json: any): Field {
    return FieldFromJSONTyped(json, false);
}

export function FieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): Field {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : json['key'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'position': !exists(json, 'position') ? undefined : json['position'],
    };
}

export function FieldToJSON(value?: Field | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'order': value.order,
        'position': value.position,
    };
}

