import { bound } from "@frui.ts/helpers";
import { ConductorOneChildActive, Router, ScreenBase } from "@frui.ts/screens";
import { action, computed, observable } from "mobx";
import type {
  AdminParticipationListItemDTO,
  BasicVoucherFilter,
  BookDTO,
  BookFilterDTO,
  BooksFilterForUser,
  CollegeAdministratorDTO,
  ExtendedSignupTechnician,
  ProfileDTO,
  StreetDTO,
  TechnicianDTO,
  TechnicianListItemDTO,
  TechnicianStatisticsDTO,
  TownDTO,
  ViewAdminTechnicianParticipationPageDTO,
  VoucherListItemDTO,
} from "../../clients";
import { ProfileDTORoleEnum, ValuesStatusEnum } from "../../clients";
import AdminActionsRepository from "../../data/repositories/impl/admin-actions-repository";
import { BookRepository } from "../../data/repositories/impl/book-repository";
import CollegeAdminActionRepository from "../../data/repositories/impl/college-admin-repository";
import CollegeAdministratorRepository from "../../data/repositories/impl/college-administrator-repository";
import UserManagementRepository from "../../data/repositories/impl/user-management-repository";
import UsersRepository from "../../data/repositories/impl/users-profile-repository";
import RootViewModel from "../root-view-model";
import { CustomTechnicianParticipationPageDTO } from "./../../clients/models/CustomTechnicianParticipationPageDTO";
import { TechniciansRepository } from "./../../data/repositories/impl/technicians-repository";
import { SessionStoreFactory } from "./../../infrastructure/data/session-store-factory";
import { initialData } from "./data";
import * as RegexHelper from "../../utils/regexHelper";
import ReportViewModel from "../report/report-view-model";

@Router.registerRoute({ name: Router.Self, route: "profile" })
export default class ProfileViewModel extends ConductorOneChildActive<ScreenBase> {
  navigationName = "profile";
  nameValue = "section.dashboard";
  parent: RootViewModel = this.parent;
  selectedBuilding: BookDTO | undefined = initialData;
  public showInToolbar: boolean = true;
  @observable technicianParticipation: any[] = observable.array([]);
  @observable paginationObject: ViewAdminTechnicianParticipationPageDTO | CustomTechnicianParticipationPageDTO | undefined = undefined;
  @observable technicianBooks: BookDTO[] = observable.array([]);
  // @ts-ignore
  @observable profileUser?: ExtendedSignupTechnician;
  @observable profileAdmin?: ProfileDTO;
  @observable collegeAdministrator?: CollegeAdministratorDTO;
  @observable editUserProfile: boolean = false;
  @observable statistics?: TechnicianStatisticsDTO = { inProgress: 0, publicResidential: 0, total: 0, withFinancialAid: 0 };
  @observable loading = true;
  @observable rowsPerPage: number = 25;
  @observable currentPage: number = 0;
  @observable userAdminSelected?: TechnicianDTO;
  @observable bookCadastralParcel: string = "";
  @observable profileTableServerSideFilter: boolean = true;

  @observable searchOptions: BooksFilterForUser = {
    postalCode: undefined,
    initialDate: undefined,
    finalDate: undefined,
    code: undefined,
  };

  @observable items: TechnicianListItemDTO[] = observable.array([]);
  @observable adminBooks: AdminParticipationListItemDTO[] = observable.array([]);
  @observable college_items: TechnicianListItemDTO[] = observable.array([]);
  @observable vouchersDataDefault: VoucherListItemDTO[] = observable.array([]);

  @observable filtersBooks: any = {};
  @observable filtersVouchers: any = {};
  @observable selected?: any;
  @observable filters: any = {};
  //CSV
  @observable csvData: any[] = [];
  @observable loadingBook: boolean = false;
  @observable technicianListItems = observable.array([]);
  @observable editUserProfileCollegeAdmin: boolean = false;
  @observable editUserProfileCollegeAdminProfile: TechnicianListItemDTO | null = null;
  @observable technicianProfileAdminEdit: ExtendedSignupTechnician | null = null;
  @observable collegeAdminBooks: AdminParticipationListItemDTO[] | null = null;
  @observable collegeAdminUsers: TechnicianListItemDTO[] | null = null;
  @observable totalItems: number | undefined = 0;

  @computed get data() {
    return this.toData(this.items);
  }

  @computed get booksData() {
    return this.toBooksData(this.adminBooks);
  }

  @computed get voucherData() {
    return this.toVouchersData(this.vouchersDataDefault);
  }

  toArray = (item: TechnicianListItemDTO) => {
    let element: any[] = [];

    element[0] = item.name;
    element[1] = item.surname;
    element[2] = item.role;
    element[3] = item.idNumber;
    element[4] =
      item.address?.streetType +
      " " +
      item.address?.streetName +
      " " +
      item.address?.portalNumber +
      " " +
      item.address?.postalCode +
      " " +
      item.address?.townName +
      " " +
      item.address?.province;
    element[5] = item.active;
    element[6] = item.valid;
    element[7] = item.collegeName;
    element[8] = item.fare;
    element[10] = item;

    return element;
  };

  toBooksArray = (item: AdminParticipationListItemDTO) => {
    let element: any[] = [];
    element[0] = item.book?.code;
    element[1] =
      item.book?.addressDTO?.streetType +
      " " +
      item.book?.addressDTO?.streetName +
      " " +
      item.book?.addressDTO?.portalNumber +
      " " +
      item.book?.addressDTO?.postalCode +
      " " +
      item.book?.addressDTO?.town +
      " " +
      item.book?.addressDTO?.province ?? "";
    element[2] = item.technician?.fullname ?? "";
    element[3] = item.technician?.idNumber ?? "";
    element[4] =
      item.technician?.address?.streetType +
      " " +
      item.technician?.address?.streetName +
      " " +
      item.technician?.address?.portalNumber +
      " " +
      item.technician?.address?.postalCode +
      " " +
      item.technician?.address?.town +
      " " +
      item.technician?.address?.province;
    element[5] = item.book?.created ? item.book?.created : " - ";
    element[6] = item.book?.validated ?? "No validado";
    element[8] = `${item.amount && Number(item.amount) > 1 ? item.amount?.toString().replaceAll(RegexHelper.checkNumberOver1000, ".") + "€" : " - "} `;
    element[7] = item.technician?.fare ?? " - ";
    element[9] = item;

    return element;
  };

  toVoucherArray = (item: VoucherListItemDTO) => {
    let element: any[] = [];
    const colleges: string[] = [];

    item.collegeTypes?.forEach((element: any, index: any) => {
      if (item.collegeNames && item.collegeNames?.length > index) colleges.push(this.translate(element) + ", " + item.collegeNames![index]);
    });

    element[0] = item.name + " " + item.surname;
    element[1] = item.idNumber;
    element[2] = item.email;
    element[3] =
      item.addressDTO?.streetType +
      " " +
      item.addressDTO?.streetName +
      " " +
      item.addressDTO?.portalNumber +
      " " +
      item.addressDTO?.postalCode +
      " " +
      item.addressDTO?.town +
      " " +
      item.addressDTO?.province;
    element[4] = item.collegeTypes?.map((e) => e);
    element[5] = item.collegeNames?.map((e) => e);
    element[6] = item.created ? item.created : " - ";
    element[7] = item.transactionId;
    element[8] = `${item.amount && Number(item.amount) > 1
      ? (Number(item.amount) / 100)?.toString().padEnd(5, "0")?.replaceAll(RegexHelper.checkNumberOver1000, ".") +
      " " +
      (item.currency === "978" ? "€" : "")
      : " - "
      } `;
    /** cantidad */
    element[9] = item.status;
    /** estado del libro */
    element[10] = item.collegeTypes;
    element[11] = item.created;

    return element;
  };

  toData = (items: TechnicianListItemDTO[]) => {
    const values = items.map((item) => this.toArray(item));
    return values;
  };

  toBooksData = (items: AdminParticipationListItemDTO[]) => {
    const values = this.adminBooks.map((item) => this.toBooksArray(item));
    return values;
  };

  toVouchersData = (items: VoucherListItemDTO[]) => {
    const values = this.vouchersDataDefault.map((item) => this.toVoucherArray(item));
    return values;
  };

  constructor(
    public profileAdminCollegeRepository: UsersRepository,
    public bookRepository: BookRepository,
    public techniciansRepositoy: TechniciansRepository,
    public userManagementRepository: UserManagementRepository,
    public collegeAdminActionRepository: CollegeAdminActionRepository,
    public collegeAdminRepository: CollegeAdministratorRepository,
    public adminActionsRepository: AdminActionsRepository
  ) {
    super();
  }

  applyFilter = () => {
    const keys = Object.keys(this.filters);
    let values = this.data;
    for (let index in keys) {
      const key = keys[index];
      const text = this.filters[key];
      if (text && text.length > 0) {
        values = values.filter((item) => {
          return item[key as any]?.toString().toLowerCase().match(text.toLowerCase());
        });
      }
    }
    return values;
  };

  applyFilterBooks = () => {
    const keys = Object.keys(this.filtersBooks);
    let values = this.booksData;
    for (let index in keys) {
      const key = keys[index];
      const text = this.filtersBooks[key];
      if (text && text.length > 0) {
        values = values.filter((item) => {
          return item[key as any]?.toString().toLowerCase().match(text.toLowerCase());
        });
      }
    }
    return values;
  };

  applyFilterVouchers = () => {
    const keys = Object.keys(this.filtersVouchers);
    let values = this.voucherData;
    for (let index in keys) {
      const key = keys[index];
      const text = this.filtersVouchers[key];
      if (text && text.length > 0) {
        values = values.filter((item) => {
          return item[key as any]?.toString().toLowerCase().match(text.toLowerCase());
        });
      }
    }
    return values;
  };

  translate(text: string) {
    this.parent.language = this.parent.configurationService.language;
    return (this.parent as RootViewModel).configurationService.translate(text);
  }

  async onActivate() {
    super.onActivate();
    (this.parent.children.find((f) => f.navigationName === "report") as any).loadingBook = true;
    try {
      const token = SessionStoreFactory.getSessionStore().getToken()?.split(".")[1] ?? "";
      const sub = JSON.parse(atob(token)).sub as string;
      if (sub !== "admin") {
        this.loadProfile();
        this.profileAdmin = undefined;
      }
      else await this.loadProfileAdmin();
      this.parent.selectedBook = undefined;
      if (sub === "admin") await this.getAllTechniciansByCollege();
    } catch (e: any) {
      if (e.status === 401 && this.parent.errorLogin === false) {
        this.parent.errorLogin = true;
      }
    } finally {
      (this.parent.children.find((f) => f.navigationName === "report") as any).loadingBook = false;
    }
  }

  @action.bound
  async editBook(id: string) {
    this.parent.selectedBook = undefined;
    this.loadingBook = true;
    if (id) {
      try {
        const result = await this.bookRepository.getBookById({ id, polygons: false, usages: false, properties: false });

        this.parent.selectedBook = result;
        this.loadingBook = false;
      } catch (e) {
        this.loadingBook = false;
      } finally {
        this.loadingBook = false;
      }
    }
  }

  @action.bound
  async editBookNewPdf(id: string) {
    this.parent.selectedBook = undefined;
    if (id) {
      try {
        this.parent.selectedBook = await this.bookRepository.getBookById({ id, polygons: true, usages: true, properties: true });
      } catch (e) { }
    }
  }

  @action.bound
  async editBookPdf(id: string) {
    if (id) {
      const result = await this.bookRepository.getBookById({ id, polygons: true, properties: true, usages: true });
      this.parent.selectedBook = result;
    }
  }

  @action.bound redirectToReport() {
    this.parent.navigate("report", {});
  }

  async loadProfile() {
    try {
      const result = await this.techniciansRepositoy.getTechnicianByCredentialsId({});
      this.profileUser = result;
      this.parent.technicianData = result;
      if (!!this.parent.technicianData === true) {
        (this.parent.children.find((r: any) => r.navigationName === "report") as ReportViewModel).checkPayment();
      }
      // TODO: Use statistics when it is implemented
      // this.bookRepository
      //   .getStatisticsFromTechnicianId(userId)
      //   .then((res) => (this.statistics = res))
      //   .catch((e) => { });
    } catch (e) {
      console.log(e);
    }
  }

  async loadProfileAdmin() {
    const result = await this.profileAdminCollegeRepository.getTechnicianByCredentialsID();
    if (result?.role === "COLLEGE_ADMINISTRATOR") {
      const result2 = await this.collegeAdminRepository.getCollegeAdministratorByID(result.id ?? "").catch((err) => {
        console.log(err);
        return undefined;
      });
      this.collegeAdministrator = result2;
      await this.getAllTechniciansByCollegeCollegeAdminApi();
    }
    this.profileAdmin = result;
  }

  async desactiveUser(user: TechnicianListItemDTO) {
    const result = await this.userManagementRepository.deactivateTechnician({ id: user.technicianId!! });
    this.userAdminSelected = { ...user, active: result.active, valid: result.valid } as TechnicianDTO;
    this.items.splice(this.items.indexOf(user as TechnicianListItemDTO), 1, this.userAdminSelected as TechnicianListItemDTO);
  }

  async activeUser(user: TechnicianListItemDTO) {
    const result = await this.userManagementRepository.activateTechnician({ id: user.technicianId!! });
    this.userAdminSelected = { ...user, active: result.active, valid: result.valid } as TechnicianDTO;
    this.items.splice(this.items.indexOf(user as TechnicianListItemDTO), 1, this.userAdminSelected as TechnicianListItemDTO);
  }

  async validateUser(user: TechnicianListItemDTO) {
    const result = await new UserManagementRepository().technicianSignUpApproval({ id: user.credentialsId!! });
    this.userAdminSelected = { ...user, active: result.active, valid: result.valid } as TechnicianDTO;
    this.items.splice(this.items.indexOf(user as TechnicianListItemDTO), 1, this.userAdminSelected as TechnicianListItemDTO);
  }

  @action.bound
  async getAllTechniciansByCollege() {
    if (this.profileAdmin?.role === ProfileDTORoleEnum.SystemAdministrator) {
      (this.parent.children.find((f) => f.navigationName === "report") as any).loadingBook = true;
      this.adminBooks = [];
      await this.adminActionsRepository
        .getUserListForAdmin()
        .then((res) => (this.items = res))
        .catch((err) => (this.items = []));
    }
  }

  @action.bound
  async searchBooks(req: BookFilterDTO) {
    try {
      (this.parent.children.find((f) => f.navigationName === "report") as any).loadingBook = true;
      await this.adminActionsRepository
        .getBooksByFilter({ bookFilterDTO: req })
        .then((res) => (this.adminBooks = res.map((i) => ({ ...i, id: i.book?.id }))))
        .catch((err) => (this.adminBooks = []));
    } catch (e) {
    } finally {
      (this.parent.children.find((f) => f.navigationName === "report") as any).loadingBook = false;
    }
  }

  @action.bound
  async searchProfileBooks(req: BooksFilterForUser, statistics?: boolean) {
    try {
      await this.bookRepository
        .getBooksByTechnicianByFilter({ booksFilterForUser: req })
        .then((res) => {
          this.profileTableServerSideFilter = false;
          this.technicianParticipation = res?.content ?? [];
          this.paginationObject = res;
          if (statistics) this.statistics = { inProgress: res?.totalElements ?? 0, publicResidential: 0, total: res.totalElements, withFinancialAid: 0 }
        })
        .catch((err) => (this.technicianParticipation = []));
    } catch (e) {
    }
  }

  @action.bound
  async loadVouchers() {
    this.loadingBook = true;
    if (this.profileAdmin?.role === ProfileDTORoleEnum.SystemAdministrator) {
      const vouchers: VoucherListItemDTO[] | undefined = await this.adminActionsRepository
        .getAllVouchers({
          basicVoucherFilter: {
            values: {
              status: [ValuesStatusEnum.Accepted, ValuesStatusEnum.Rejected, ValuesStatusEnum.Used],
            },
          },
        })
        .catch((err) => { this.loadingBook = false; return [] });
      this.vouchersDataDefault = vouchers ?? [];
      this.totalItems = vouchers?.length ?? 0;
    }
    this.loadingBook = false;
  }

  async getBookByProperty(property: string, province: string) {
    this.loadingBook = true;
    const result = await this.adminActionsRepository.getBookByPropertyCode({ propertyReference: property, province: province }).catch((err) => null);
    this.loadingBook = false;
    return result;
  }

  @action.bound
  async getAllTechniciansByCollegeCollegeAdminApi() {
    (this.parent.children.find((a) => a.navigationName === "report") as any).loadingBook = true;
    const collegeadminbooks = await this.collegeAdminActionRepository
      .getBooksForCollegeAdmin()
      .then((res) => (res as AdminParticipationListItemDTO[]) ?? ([] as AdminParticipationListItemDTO[]))
      .catch((err) => [] as AdminParticipationListItemDTO[]);

    const collegeadminbooksBookProvinces = Array.from(new Set(collegeadminbooks.map((user) => user?.book?.addressDTO?.province)));
    const collegeadminbooksTechnicianProvinces = Array.from(new Set(collegeadminbooks.map((user) => user?.technician?.address?.province)));
    const collegeAdminBooksProvinces = Array.from(new Set([...collegeadminbooksBookProvinces, ...collegeadminbooksTechnicianProvinces]));

    this.statistics = await this.collegeAdminActionRepository.getStatisticsForCollegeAdmin().catch((err) => {
      console.log("errr", err);
      return {};
    });
    const result = await this.collegeAdminActionRepository.getTechniciansByCollegeAdmin();
    const usersProvinces = Array.from(new Set(result.map((user) => user?.address?.province)));
    const userAndBooksProvinces = Array.from([...collegeAdminBooksProvinces, ...usersProvinces]);

    let usersProvinceAndTowns: AddressItem[] = [];

    for await (const province of userAndBooksProvinces) {
      const towns = await this.parent.provincesRepository.getAllTownByProvinceCode(province?.padStart(2, "0") ?? "").catch((err) => {
        console.log(err);
        return [];
      });
      const citiesMatchingUser = towns.filter(
        (t) => result.filter((u) => u.address?.province === province).filter((u) => u.address?.town === t.code).length
      );
      const citiesMatchingBooksBook = towns.filter(
        (t) => collegeadminbooks.filter((u) => u.book?.addressDTO?.province === province).filter((u) => u.book?.addressDTO?.town === t.code).length
      );
      const citiesMatchingBooksTechnician = towns.filter(
        (t) => collegeadminbooks.filter((u) => u.technician?.address?.province === province).filter((u) => u.technician?.address?.town === t.code).length
      );
      const citiesMatchingUsersTechniciansAndBooks = Array.from(
        new Set([...citiesMatchingUser, ...citiesMatchingBooksBook, ...citiesMatchingBooksTechnician])
      );

      const townWithCities: TownAndStreets[] = [];
      for await (const town of citiesMatchingUsersTechniciansAndBooks) {
        const streets = await this.parent.townRepository.getStreetsByTownId(town?.id ?? "").catch((err) => {
          console.log(err);
          return [];
        });
        const t: TownAndStreets = { town, streets };
        townWithCities.push(t);
        await this.sleep(500);
      }
      if (province) {
        const addressItem: AddressItem = { province, townAndStreets: townWithCities };
        usersProvinceAndTowns.push(addressItem);
      }
      await this.sleep(500);
      this.loadingBook = false;
    }

    this.collegeAdminUsers = result.map((listitem) => {
      const province = listitem.address?.province;
      const streetType = listitem.address?.streetType;
      let streetName = listitem.address?.streetName ?? "";
      const streetCode = listitem.address?.streetCode;
      const postalCode = listitem.address?.postalCode;
      const townCode = listitem.address?.town;
      const portalNumber = listitem.address?.portalNumber;
      const townName = listitem.address?.townName;

      let address = ``;
      if (streetCode === "0") {
        address = `${streetType ?? " - "} ${streetName ?? " - "} ${portalNumber ?? " - "} ${postalCode ?? " - "} ${townName ?? " - "} `;
      } else {
        const { tname, sname } = this.getStreetName(province ?? "", townCode ?? "", streetCode ?? "", streetType ?? "", usersProvinceAndTowns);
        address = `${streetType ?? " - "} ${sname ?? " - "} ${portalNumber ?? " - "} ${postalCode ?? " - "} ${tname ?? " - "} `;
      }

      return {
        id: listitem.technicianId,
        name: listitem.name, // 1
        surname: listitem.surname, // 2
        role: listitem.role, // 3
        idNumber: listitem.idNumber, // 4
        active: listitem.active, // 5
        valid: listitem.valid, // 6
        collegeName: listitem.collegeName, // 7
        address: listitem.address, // 8
        fare: listitem.fare, // 9
        fakeAddress: address, // 8
      };
    }) as any;

    this.collegeAdminBooks = collegeadminbooks.map((listitem) => {
      let technicianAddress = ``;
      let bookAddress = ``;
      if (listitem.technician?.address?.streetCode === "0") {
        technicianAddress = `${listitem.technician?.address?.streetType ?? " - "} ${listitem.technician?.address?.streetName ?? " - "} ${listitem.technician?.address?.portalNumber ?? " - "
          } ${listitem.technician?.address?.postalCode ?? " - "} ${listitem.technician?.address?.townName ?? " - "} `;
      } else {
        const { tname, sname } = this.getStreetName(
          listitem.technician?.address?.province ?? "",
          listitem.technician?.address?.town ?? "",
          listitem.technician?.address?.streetCode ?? "",
          listitem.technician?.address?.streetType ?? "",
          usersProvinceAndTowns
        );

        const { tname: btname, sname: bsname } = this.getStreetName(
          listitem.book?.addressDTO?.province ?? "",
          listitem.book?.addressDTO?.town ?? "",
          listitem.book?.addressDTO?.street ?? "",
          listitem.book?.addressDTO?.streetType ?? "",
          usersProvinceAndTowns
        );

        technicianAddress = `${listitem.technician?.address?.streetType ?? " - "} ${sname ?? " - "} ${listitem.technician?.address?.portalNumber ?? " - "
          } ${listitem.technician?.address?.postalCode ?? " - "} ${tname ?? " - "} `;
        bookAddress = `${listitem.book?.addressDTO?.streetType ?? " - "} ${bsname ?? " - "} ${listitem.book?.addressDTO?.portalNumber ?? " - "} ${listitem.book?.addressDTO?.postalCode ?? " - "
          } ${btname ?? " - "} `;
      }

      return {
        id: listitem.book?.code,
        bookCode: listitem.book?.code,
        fullName: listitem.technician?.fullname, // 1
        idNumber: listitem.technician?.idNumber, // 4
        creationDate: new Date(listitem.book?.created ?? 0).toLocaleString("es-ES", {
          localeMatcher: "lookup",
          month: "long",
          day: "numeric",
          year: "numeric",
        }), // 4
        collegeName: listitem.technician?.colleges![0] ?? "", // 7
        address: listitem.technician?.address, // 8
        validated: listitem.book?.validated ? "Validado" : "No validado", // 6
        amount: `${listitem.amount && listitem.amount > 1 ? (listitem.amount / 100).toString() + "€" : " - "} `,
        fare: listitem.technician?.fare, // 9
        fakeTechnicianAddress: technicianAddress, // 8
        fakeBookAddress: bookAddress, // 8
      };
    }) as any;
    (this.parent.children.find((a) => a.navigationName === "report") as any).loadingBook = true;
  }

  async getProfileById(): Promise<ExtendedSignupTechnician | null> {
    const res = await this.adminActionsRepository
      .getUserByIdForAdmin({ id: this.editUserProfileCollegeAdminProfile?.technicianId ?? "" })
      .catch((err) => null);

    this.technicianProfileAdminEdit = res;

    return res;
  }

  sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  getStreetName = (province: string, townCode: string, streetCode: string, streetType: string, provinceTownsStreets: AddressItem[]) => {
    let address: any = {};

    const pts = provinceTownsStreets.find((ai) => ai.province === province);

    if (pts) {
      if (pts.townAndStreets.find((t) => t.town.code === townCode && t.streets.find((s) => s.type === streetType && s.code === streetCode))) {
        const tas = pts.townAndStreets.find((t) => t.town.code === townCode);
        if (tas) {
          address.tname = tas.town?.name ?? "";
          address.sname = tas.streets.find((street) => street.type === streetType && streetCode === street.code)?.name ?? "";
        }
      }
    }

    return address;
  };

  @action.bound
  async deleteUser(id: string) {
    await this.adminActionsRepository.deleteUserForAdmin({ id });
  }

  @action.bound
  async editProfileUser(techinician: TechnicianDTO) {
    this.loadingBook = true;
    const result = await this.userManagementRepository.technicianUpdate({ technicianDTO: techinician });
    this.profileUser = result as ExtendedSignupTechnician;
    this.parent.technicianData = result as ExtendedSignupTechnician;
    this.editUserProfile = false;
    this.loadingBook = false;
  }

  @action.bound
  async editProfileUserAsAdmin(techinician: TechnicianDTO) {
    this.loadingBook = true;
    const result = await this.adminActionsRepository.editUserForAdmin({
      technicianDTO: techinician,
      id: techinician.id ?? "",
    });
    this.profileUser = result as ExtendedSignupTechnician;
    this.parent.technicianData = result as ExtendedSignupTechnician;
    this.editUserProfile = false;
    this.loadingBook = false;
  }

  @action.bound
  async deactivateTechnicianProfileUser() {
    try {
      await this.userManagementRepository.deactivateTechnician({ id: this.profileUser!!.id!! });
      this.parent.logOff();
    } catch {
      throw new Error("Error");
    }
    //
  }

  @action.bound find(id: string) {
    const index = this.items.find((item) => item?.technicianId?.toString() === id);
    this.selected = index!!;
    return index;
  }

  @bound async loadDataCSV() {
    this.loading = true;
    this.csvData = this.items;
    this.loading = false;
  }

  @action.bound addFilter = (column: number, value?: string) => {
    this.filters[column] = value;
  };

  @action.bound addFilterBooks = (column: number, value?: string) => {
    this.filtersBooks[column] = value;
  };

  @action.bound addFilterVouchers = (column: number, value?: string) => {
    this.filtersVouchers[column] = value;
  };

  @action.bound setSelected(result: any | undefined) {
    this.selected = result;
  }

  @action.bound handleRowsPerPage = async (numberOfRows: number) => {
    this.rowsPerPage = numberOfRows;
    // await this.loadVouchers()
  };

  @action.bound handleCurrentPage = (page: number) => {
    this.currentPage = page;
  };

  async findNavigationChild(navigationName: string): Promise<any> {
    if (navigationName === undefined || navigationName === this.navigationName) {
      this.parent.tryActivateChild(this);
      this.activeChild?.requestClose();
      return undefined;
    } else {
      return undefined;
    }
  }

  async createFreeVoucherByID(credentialsId: string) {
    if (!credentialsId) return;
    try {
      this.loadingBook = true;
      const result = await this.adminActionsRepository.createFreeVoucherByID({ credentialsId: credentialsId });
      this.loadingBook = false;
      return result;
    } catch (err) {
      console.error(err);
    }
  }

  async getAllVouchers(basicFilter: BasicVoucherFilter) {
    try {
      this.loadingBook = true;
      const result = await this.adminActionsRepository.getAllVouchers({ basicVoucherFilter: basicFilter });
      this.loadingBook = false;
      return result;
    } catch (err: any) {
      console.error(err);
      throw new Error(err);
    }
  }
}

interface AddressItem {
  province: string;
  townAndStreets: TownAndStreets[];
}

interface TownAndStreets {
  town: TownDTO;
  streets: StreetDTO[];
}
