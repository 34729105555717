import { createStyles, makeStyles } from '@mui/styles'

export const MapStyle = makeStyles((theme) => createStyles({
  map_additionalData: {
    '& .leaflet-container': {
      width: "100%",
      height: "100% !important"
    },
    zIndex: 0,
    position: "fixed",
    padding: 0,
    margin: 0,
    top: "49px",
    left: 0,
    width: "100%",
    height: "94%"
  }
}))