import { Button, DialogMovicoders, TypographyTheme } from "@movicoders/movicoders-components";
import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Colors } from "../../../constants";
import { getBookMock, getBuildingCompositionDefaults, getBuildingLocationInfoMock } from "../../../viewmodels/profile/data";
import ProfileViewModel from "../../../viewmodels/profile/profile-view-model";
import ReportViewModel from "../../../viewmodels/report/report-view-model";
import { CollegeSelectionForm } from "../../reports/reportComponents/block0/buildingIdentification/components/reference-cadastral-generator/book-select-college-form";
import { ProfileStyle } from "../profile-styles";

export const ProfileCounts: React.FC<{ vm: ProfileViewModel }> = observer(({ vm }) => {
  const classes = ProfileStyle();
  const [validated, setValidated] = useState(false);
  const [needPaymentButton, setNeedPaymentButton] = useState(false);

  React.useEffect(() => {
    if ((vm.parent.children.find((c: any) => c.navigationName === "report") as ReportViewModel).paymentButton) setNeedPaymentButton(true);
    else setNeedPaymentButton(false);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(vm.parent.children.find((c: any) => c.navigationName === "report") as ReportViewModel).paymentButton]);

  const handleCreateBook = async () => {
    try {
      const localVm = vm.parent.children.find((c: any) => c.navigationName === "report") as ReportViewModel;
      const onlyOneCollege = localVm?.verifyCollegiated();
      (vm.parent.children.find((p) => p.navigationName === "report") as ReportViewModel).buildingComposition = getBuildingCompositionDefaults();
      (vm.parent.children.find((p) => p.navigationName === "report") as ReportViewModel).locationInfo = getBuildingLocationInfoMock();
      (vm.parent.children.find((p) => p.navigationName === "report") as ReportViewModel).userBook = getBookMock();
      vm.parent.selectedBook = undefined;
      (vm.parent.children.find((p) => p.navigationName === "report") as ReportViewModel).manualUsages = [];
      if (onlyOneCollege && localVm) {
        vm.loadingBook = true;
        localVm.userData = vm.parent.technicianData as any;
        localVm.selectedCollege = vm.parent.technicianData!.collegiated![0].college;

        vm.loadingBook = false;
        localVm.openPaymentForm = true;
      } else {
        localVm.userData = vm.parent.technicianData as any;
        localVm.openPaymentForm = true;
      }
    } catch (e: any) {
      vm.loadingBook = false;
      console.log(e.message);
    }
  };

  useEffect(() => {
    setValidated(vm.profileUser?.valid || false);
  }, [vm.profileUser]);

  return (
    <Grid container style={{ padding: 30 }}>
      <Grid item md={12} xs={12} style={{ display: "flex" }}>
        <Grid item md={2} xs={2} style={{ height: 150, display: "flex", marginRight: 5 }}>
          <Button
            disabled={!validated}
            id="profileCountsCreateBook"
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: needPaymentButton ? Colors.primary : "#82D173",
              color: needPaymentButton ? "white" : Colors.primary,
              fontWeight: "700",
            }}
            onClick={handleCreateBook}
            text={vm.translate(needPaymentButton ? "profile.paybook" : "profile.createBook")}
          />
        </Grid>

        <Grid item md={3} xs={3} className={classes.profile_grid}>
          <TypographyTheme id="profileLee" text={vm.translate("profile.lee")} color={Colors.textPrimary} />
          <TypographyTheme text={vm.statistics?.total?.toString() || ""} bold variant="h4" />
        </Grid>

        <Grid item md={3} xs={3} className={classes.profile_grid}>
          <TypographyTheme id="profileResidential" text={vm.translate("profile.residential")} color={Colors.textPrimary} />
          <TypographyTheme id="profileResidentialStatistics" text={vm.statistics?.publicResidential?.toString() || ""} bold variant="h4" />
        </Grid>

        <Grid item md={3} xs={3} className={classes.profile_grid}>
          <TypographyTheme id="profileProgress" text={vm.translate("profile.progress")} color={Colors.textPrimary} />
          <TypographyTheme id="profileProgressStatistics" text={vm.statistics?.inProgress?.toString() || ""} bold variant="h4" />
        </Grid>

        <Grid item md={3} xs={3} className={classes.profile_gridClose}>
          <TypographyTheme id="profileHelps" text={vm.translate("profile.helps")} color={Colors.textPrimary} />
          <TypographyTheme id="profileHelpsWithFinalcialAid" text={vm.statistics?.withFinancialAid?.toString() || ""} bold variant="h4" />
        </Grid>
      </Grid>

      {(vm.parent?.children?.find((c) => c.navigationName === "report") as ReportViewModel)?.openPaymentForm && (
        <DialogMovicoders
          open={true}
          dialogConfig={{
            content: <CollegeSelectionForm vm={vm.parent?.children?.find((c) => c.navigationName === "report") as ReportViewModel} />,
            title: vm.translate("profile.counts.preparing.book"),
            cancelText: "",
            confirmText: "",
          }}
          onClose={() => { }}
          maxWidth={"md"}
          onConfirm={() => { }}
        //styleDialog={{ maxWidth: 450 }}
        />
      )}
    </Grid>
  );
});

