/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MovicodersBadRequestException,
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundException,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceException,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
    StreetDTO,
    StreetDTOFromJSON,
    StreetDTOToJSON,
    TownDTO,
    TownDTOFromJSON,
    TownDTOToJSON,
} from '../models';

export interface GetStreetsByTownIdRequest {
    id: string;
}

export interface GetStreetsByTownIdAndNameRequest {
    id: string;
    name: string;
}

export interface GetTownByIdRequest {
    id: string;
}

/**
 * 
 */
export class TownsApi extends runtime.BaseAPI {

    /**
     * Returns the streets of a town
     * Get streets by town id
     */
    async getStreetsByTownIdRaw(requestParameters: GetStreetsByTownIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<StreetDTO>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getStreetsByTownId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/towns/{id}/streets`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StreetDTOFromJSON));
    }

    /**
     * Returns the streets of a town
     * Get streets by town id
     */
    async getStreetsByTownId(requestParameters: GetStreetsByTownIdRequest, initOverrides?: RequestInit): Promise<Array<StreetDTO>> {
        const response = await this.getStreetsByTownIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns the streets of a town by a similar street name
     * Get streets by town id and given name
     */
    async getStreetsByTownIdAndNameRaw(requestParameters: GetStreetsByTownIdAndNameRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<StreetDTO>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getStreetsByTownIdAndName.');
        }

        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getStreetsByTownIdAndName.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/towns/{id}/streets/search`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StreetDTOFromJSON));
    }

    /**
     * Returns the streets of a town by a similar street name
     * Get streets by town id and given name
     */
    async getStreetsByTownIdAndName(requestParameters: GetStreetsByTownIdAndNameRequest, initOverrides?: RequestInit): Promise<Array<StreetDTO>> {
        const response = await this.getStreetsByTownIdAndNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a town by id or null
     * Get town by id
     */
    async getTownByIdRaw(requestParameters: GetTownByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TownDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTownById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/towns/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TownDTOFromJSON(jsonValue));
    }

    /**
     * Returns a town by id or null
     * Get town by id
     */
    async getTownById(requestParameters: GetTownByIdRequest, initOverrides?: RequestInit): Promise<TownDTO> {
        const response = await this.getTownByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all towns
     * Get towns list
     */
    async getTownsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<TownDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/towns`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TownDTOFromJSON));
    }

    /**
     * Returns all towns
     * Get towns list
     */
    async getTowns(initOverrides?: RequestInit): Promise<Array<TownDTO>> {
        const response = await this.getTownsRaw(initOverrides);
        return await response.value();
    }

}
