/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdminParticipationListItemDTO,
    AdminParticipationListItemDTOFromJSON,
    AdminParticipationListItemDTOToJSON,
    MovicodersBadRequestException,
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundException,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceException,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
    TechnicianListItemDTO,
    TechnicianListItemDTOFromJSON,
    TechnicianListItemDTOToJSON,
    TechnicianStatisticsDTO,
    TechnicianStatisticsDTOFromJSON,
    TechnicianStatisticsDTOToJSON,
} from '../models';

/**
 * 
 */
export class CollegeAdminActionsApi extends runtime.BaseAPI {

    /**
     * Get books for a college.
     * Get books for a college.
     */
    async getBooksForCollegeAdminRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AdminParticipationListItemDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/college-admin/books`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdminParticipationListItemDTOFromJSON));
    }

    /**
     * Get books for a college.
     * Get books for a college.
     */
    async getBooksForCollegeAdmin(initOverrides?: RequestInit): Promise<Array<AdminParticipationListItemDTO>> {
        const response = await this.getBooksForCollegeAdminRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get statistics for college admin
     * Get statistics for college admin
     */
    async getStatisticsForCollegeAdminRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<TechnicianStatisticsDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/college-admin/books/statistics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TechnicianStatisticsDTOFromJSON(jsonValue));
    }

    /**
     * Get statistics for college admin
     * Get statistics for college admin
     */
    async getStatisticsForCollegeAdmin(initOverrides?: RequestInit): Promise<TechnicianStatisticsDTO> {
        const response = await this.getStatisticsForCollegeAdminRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get users list for college admin
     * Get users list for college admin
     */
    async getUserListForCollegeAdminRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<TechnicianListItemDTO>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/college-admin/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TechnicianListItemDTOFromJSON));
    }

    /**
     * Get users list for college admin
     * Get users list for college admin
     */
    async getUserListForCollegeAdmin(initOverrides?: RequestInit): Promise<Array<TechnicianListItemDTO>> {
        const response = await this.getUserListForCollegeAdminRaw(initOverrides);
        return await response.value();
    }

}
