/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccessibleElementsDTO
 */
export interface AccessibleElementsDTO {
    /**
     * 
     * @type {boolean}
     * @memberof AccessibleElementsDTO
     */
    accessibleMechanismsAccessible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccessibleElementsDTO
     */
    accessibleMechanismsAccessibleNotes?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccessibleElementsDTO
     */
    parkingLotWheelChairAccessible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccessibleElementsDTO
     */
    parkingLotWheelChairAccessibleNotes?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccessibleElementsDTO
     */
    poolsWheelChairAccessible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccessibleElementsDTO
     */
    poolsWheelChairAccessibleNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessibleElementsDTO
     */
    sanitaryFacilitiesAccessibleNotes?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccessibleElementsDTO
     */
    sanitaryFacilitiesShowerAccessible?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccessibleElementsDTO
     */
    sanitaryFacilitiesToiletAccessible?: boolean;
}

export function AccessibleElementsDTOFromJSON(json: any): AccessibleElementsDTO {
    return AccessibleElementsDTOFromJSONTyped(json, false);
}

export function AccessibleElementsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessibleElementsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessibleMechanismsAccessible': !exists(json, 'accessibleMechanismsAccessible') ? undefined : json['accessibleMechanismsAccessible'],
        'accessibleMechanismsAccessibleNotes': !exists(json, 'accessibleMechanismsAccessibleNotes') ? undefined : json['accessibleMechanismsAccessibleNotes'],
        'parkingLotWheelChairAccessible': !exists(json, 'parkingLotWheelChairAccessible') ? undefined : json['parkingLotWheelChairAccessible'],
        'parkingLotWheelChairAccessibleNotes': !exists(json, 'parkingLotWheelChairAccessibleNotes') ? undefined : json['parkingLotWheelChairAccessibleNotes'],
        'poolsWheelChairAccessible': !exists(json, 'poolsWheelChairAccessible') ? undefined : json['poolsWheelChairAccessible'],
        'poolsWheelChairAccessibleNotes': !exists(json, 'poolsWheelChairAccessibleNotes') ? undefined : json['poolsWheelChairAccessibleNotes'],
        'sanitaryFacilitiesAccessibleNotes': !exists(json, 'sanitaryFacilitiesAccessibleNotes') ? undefined : json['sanitaryFacilitiesAccessibleNotes'],
        'sanitaryFacilitiesShowerAccessible': !exists(json, 'sanitaryFacilitiesShowerAccessible') ? undefined : json['sanitaryFacilitiesShowerAccessible'],
        'sanitaryFacilitiesToiletAccessible': !exists(json, 'sanitaryFacilitiesToiletAccessible') ? undefined : json['sanitaryFacilitiesToiletAccessible'],
    };
}

export function AccessibleElementsDTOToJSON(value?: AccessibleElementsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessibleMechanismsAccessible': value.accessibleMechanismsAccessible,
        'accessibleMechanismsAccessibleNotes': value.accessibleMechanismsAccessibleNotes,
        'parkingLotWheelChairAccessible': value.parkingLotWheelChairAccessible,
        'parkingLotWheelChairAccessibleNotes': value.parkingLotWheelChairAccessibleNotes,
        'poolsWheelChairAccessible': value.poolsWheelChairAccessible,
        'poolsWheelChairAccessibleNotes': value.poolsWheelChairAccessibleNotes,
        'sanitaryFacilitiesAccessibleNotes': value.sanitaryFacilitiesAccessibleNotes,
        'sanitaryFacilitiesShowerAccessible': value.sanitaryFacilitiesShowerAccessible,
        'sanitaryFacilitiesToiletAccessible': value.sanitaryFacilitiesToiletAccessible,
    };
}

