import Map from "../data/entities/map-entity"
import MapRepository from "../data/repositories/impl/map-repository"

export default class MapService {
  maps: Map[]

  constructor(public mapRepository: MapRepository) {
    this.maps = []
  }

  findById = async (id: string) => {  }
}