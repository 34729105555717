import { Button, DialogMovicoders } from "@movicoders/movicoders-components";
import { ConfirmDialogStrings } from "@movicoders/movicoders-components";
import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import TextFieldGrid from "../../../../../components/text-fields/textfield-grid";
import { Colors } from "../../../../../constants";
import { addressDTOMock } from "../../../../../viewmodels/profile/data";
import ProfileViewModel from "../../../../../viewmodels/profile/profile-view-model";
import { ProfileStyle } from "../../../profile-styles";
import { useAddress } from "./useAddress";
import * as RegexHelper from "../../../../../utils/regexHelper";

export const ProfileUserStructure: React.FC<{ vm: ProfileViewModel }> = observer(({ vm }) => {
  const classes = ProfileStyle();
  const [technician, setTechnician] = useState(vm.profileUser);
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);

  const { view: addresshookview, address } = useAddress(vm, addressDTOMock(vm.profileUser?.address));

  const { enqueueSnackbar } = useSnackbar();
  const handleOpenSnackBar = (snackKey: string, variants: undefined | "default" | "error" | "success" | "warning" | "info") => {
    enqueueSnackbar(snackKey, { variant: variants, preventDuplicate: true, key: snackKey, autoHideDuration: 3000 });
  };

  const save = async () => {
    vm.loadingBook = true;
    const technician_ = { ...technician, address };
    vm.editProfileUser(technician_!! as any)
      .then(() => {
        handleOpenSnackBar(vm.translate("profile.success"), "success");
        vm.loadingBook = false;
      })
      .catch(() => {
        handleOpenSnackBar(vm.translate("profile.error"), "error");
        vm.loadingBook = false;
      });
    vm.loadingBook = false;
  };

  const handleClose = () => {
    setDeleteConfirmDialog(false);
  };

  const handleDeleteProfile = () => {
    vm.deactivateTechnicianProfileUser()
      .then(() => {
        vm.editUserProfile = false;
        setDeleteConfirmDialog(false);
        handleOpenSnackBar(vm.translate("profile.success"), "success");
      })
      .catch(() => {
        handleOpenSnackBar(vm.translate("profile.error"), "error");
      });
  };

  const dialogStrings: ConfirmDialogStrings = {
    confirmText: vm.translate("profile.user.deactivate"),
    cancelText: vm.translate("profile.user.cancel"),
    title: vm.translate("profile.user.deregistration"),
    content: <div>{vm.translate("profile.user.deactivate.confirmation")}</div>,
  };

  return (
    /*eslint no-script-url: "off"*/
    <form
      action="javascript:void(0);"
      onSubmit={() => {
        save();
      }}
    >
      <React.Fragment>
        <Grid item xs={12} md={12} className={classes.profile_displayGrid} justifyContent={"center"} alignItems={"center"}>
          <Grid item xs={12} md={12} className={classes.profile_generalDataGrid}>
            <TextFieldGrid
              id="profileName"
              title={vm.translate("profile.user.usernamename")}
              value={technician?.username}
              disabled
              onChange={(e: any) => {}}
              style={{ marginRight: 5 }}
              gridTextField={{ xs: 12, md: 4 }}
            />
          </Grid>
          <Grid item xs={12} md={12} className={classes.profile_generalDataGrid}>
            <TextFieldGrid
              id="profileName"
              title={vm.translate("profile.name")}
              required
              value={technician?.name}
              onChange={(e: any) => {
                setTechnician({ ...technician, name: e.target.value });
              }}
              style={{ marginRight: 5 }}
              gridTextField={{ xs: 12, md: 4 }}
            />
            <TextFieldGrid
              id="profileSurname"
              title={vm.translate("profile.surname")}
              required
              value={technician?.surname}
              onChange={(e: any) => {
                setTechnician({ ...technician, surname: e.target.value });
              }}
              gridTextField={{ xs: 12, md: 4 }}
            />
            <TextFieldGrid
              id="profilePhone"
              title={vm.translate("profile.phone")}
              value={technician?.phone}
              onChange={(e: any) => {
                setTechnician({ ...technician, phone: e.target.value });
              }}
              gridTextField={{ xs: 12, md: 4 }}
            />
            <TextFieldGrid
              id="profilePhone2"
              title={vm.translate("profile.phone2")}
              required
              value={technician?.mobilePhone}
              onChange={(e: any) => {
                setTechnician({ ...technician, mobilePhone: e.target.value });
              }}
              gridTextField={{ xs: 12, md: 4 }}
            />
          </Grid>

          <Grid item xs={12} md={12} className={classes.profile_displayGrid}>
            <Grid item xs={12} md={12} className={classes.profile_generalDataGrid}>
              <TextFieldGrid
                id="profileDni"
                title={vm.translate("report.block0.nif")}
                value={technician?.idNumber}
                error={
                  !(RegexHelper.validCif.test(technician!.idNumber!.toUpperCase()) || RegexHelper.CIF_REGEX.test(technician!.idNumber!.toUpperCase())) ||
                  technician?.idNumber!.length !== 9
                }
                onChange={(e: any) => {
                  setTechnician({ ...technician, idNumber: e.target.value });
                }}
                style={{ marginRight: 5 }}
                gridTextField={{ xs: 6, md: 6 }}
              />
              <TextFieldGrid
                id="profileEmail"
                title={vm.translate("profile.email")}
                value={technician?.email}
                onChange={(e: any) => {
                  setTechnician({ ...technician, email: e.target.value });
                }}
                style={{ marginRight: 5 }}
                gridTextField={{ xs: 6, md: 6 }}
              />
              <TextFieldGrid
                id="profileWork"
                title={vm.translate("profile.work")}
                disabled
                value={vm.translate("technician.participation.profesion." + technician?.collegiated![0]?.college?.type ?? "")}
                onChange={(e: any) => {}}
                gridTextField={{ xs: 6, md: 6 }}
              />
              <TextFieldGrid
                id="profileWork"
                title={vm.translate("profile.fare")}
                disabled
                value={vm.translate("technician.fare." + technician?.fare ?? "")}
                onChange={(e: any) => {}}
                gridTextField={{ xs: 6, md: 6 }}
              />
            </Grid>
          </Grid>
          {/* Address */}
          {addresshookview}

          <Grid item xs={12} md={12} className={classes.profile_displayGrid}>
            <TextFieldGrid
              id="colllege"
              title={vm.translate(technician?.collegiated![0]?.college?.type ?? "")}
              disabled
              value={technician?.collegiated![0]?.college?.name ?? ""}
              onChange={(value: any) => {}}
              gridTextField={{ xs: 6, md: 6 }}
            />
          </Grid>
        </Grid>

        <hr style={{ width: "100%", border: "1px solid " + Colors.hr }} />
        <Grid container item xs={12} className={classes.profile_buttonSave} justifyContent="space-between">
          <Button
            id="profileUserStructureSaveButton"
            type="submit"
            text={vm.translate("profile.button.save")}
            textColor={Colors.white}
            backgroundColor={Colors.buttonSecondary}
            hoverColor={Colors.buttonSecondary}
            onClick={() => {}}
            disabled={
              (RegexHelper.validCif.test(technician!.idNumber!.toUpperCase()) || RegexHelper.CIF_REGEX.test(technician!.idNumber!.toUpperCase())) &&
              technician?.idNumber!.length === 9
                ? false
                : true
            }
          />
          <Button
            text={vm.translate("profile.button.delete")}
            textColor={Colors.white}
            backgroundColor={Colors.error}
            hoverColor={Colors.error}
            onClick={() => {
              setDeleteConfirmDialog(true);
            }}
          />
        </Grid>
        <DialogMovicoders
          open={deleteConfirmDialog}
          draggable={false}
          dialogConfig={dialogStrings}
          onConfirm={() => {
            handleDeleteProfile();
          }}
          onClose={() => handleClose()}
          closeWithX
          maxWidth={"sm"}
          backgroundColorTitle={Colors.primary}
          fullWidth
        />
      </React.Fragment>
    </form>
  );
});
