import { ConductorOneChildActive, Router, ScreenBase } from "@frui.ts/screens";

@Router.registerRoute({ name: Router.Self, route: "roles" })
export default class RolesViewModel extends ConductorOneChildActive<ScreenBase> {
  constructor() {
    super();
    this.navigationName = "roles";
    this.nameValue = "section.roles";
  }
}
