import { ConductorOneChildActive, Router, ScreenBase } from "@frui.ts/screens";
import { action, observable } from "mobx";
import TownRepository from "../../data/repositories/impl/town-repository";
import UserManagementRepository from "../../data/repositories/impl/user-management-repository";
import LoginRepository from "../../infrastructure/data/repositories/login-repository";
import { SessionStoreFactory } from "../../infrastructure/data/session-store-factory";
import { ISessionStore } from "../../infrastructure/data/session-store-interface";
import i18n from "../../infrastructure/localization/i18n";
import { Network } from "../../infrastructure/network";
import LoginModel from "../../models/login-model";
import type LoginRequest from "../../models/login-request";
import LoginResponse from "../../models/login-response";
import UserContextService from "../../services/user-context-service";
import RootViewModel from "../root-view-model";

@Router.registerRoute({ name: Router.Self, route: "login" })
export default class LoginViewModel extends ConductorOneChildActive<ScreenBase> {
  @observable credentials = new LoginModel();
  nameValue = "login";
  navigationName = "login";
  @observable persistLogin = false;
  @observable forgotPassword?: boolean;

  loginSessionStorage: ISessionStore = SessionStoreFactory.getSessionStore(); // TEMPORARY
  parent: RootViewModel = this.parent;
  @observable loadingLogin: boolean = false;

  constructor(
    private loginRepository: LoginRepository,
    public userContext: UserContextService,
    private userManagementRepository: UserManagementRepository,
    public townRepository: TownRepository
  ) {
    super();
  }

  protected onDeactivate(close: boolean) {
    return super.onDeactivate(close);
  }

  async onActivate() {
    super.onActivate();
    this.forgotPassword = false;
  }

  @action.bound
  async login(payload: LoginRequest): Promise<string> {
    return await fetch(`${Network.AUTH}/login`, {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ password: payload.password, username: payload.email }),
    }).then(async (response: Response) => {
      if (response.status === 200) {
        let res: LoginResponse = await response.json();
        this.userContext.setUser(res.user, res.token);
        this.loginSessionStorage.setToken(res.token ?? "");
        this.parent.navigate("profile", {});
        return "login.success";
      } else if (response.status === 410) {
        this.loadingLogin = false;
        return "login.not_validated";
      } else {
        this.loadingLogin = false;
        return "login.error";
      }
    });
  }

  translate(key: string): string {
    return i18n.t(key) ? i18n.t(key) : key;
  }
}
