import md5 from "blueimp-md5";
export const getUrlVars = () => {
  var vars = {};
  // @ts-ignore
  window.location.href.replace(/[/?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    // @ts-ignore
    if (m.match("/sso_token")) vars[key.split("/")[1]] = value;
    // @ts-ignore
    else vars[key] = value;
  });
  return vars;
};

export const getUrlParam = (parameter: any, defaultvalue: any, parameters: any) => {
  var urlparameter = defaultvalue;
  if (window.location.href.indexOf(parameter) > -1) {
    // @ts-ignore
    urlparameter = parameters[parameter];
  }
  if (urlparameter !== undefined) {
    return urlparameter;
  } else {
    return defaultvalue;
  }
};

export const calcMD5 = (token: string, timestamp: number) => {
  const hash = md5(`sso_token=${token}&sso_timestamp=${timestamp}&secret=${process.env.REACT_APP_CGATESECRET}`);
  return hash;
};

export const checkMD5 = (token: string, timestamp: number, hash2: string) => {
  return calcMD5(token, timestamp) !== hash2;
};

export const checkIfTimestampIsLessThan3HoursOld = (timestamp: number) => {
  const currentTimestamp = new Date().getTime();
  const difference = currentTimestamp - timestamp;
  const differenceInHours = difference / 1000 / 60 / 60;
  return differenceInHours < 3;
};
