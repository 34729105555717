import { TownDTO as ClientTown } from "../../clients";

export default class Town {
    code: string;
    id: string;
    name: string;

    constructor(
        code: string,
        id: string,
        name: string
    ) {
        this.code = code;
        this.id = id;
        this.name = name;
    }

    static fromClientTown(town: ClientTown) {
        return new Town(
            town.code!,
            town.id!,
            town.name!
        );
    }

    static toClientTown(town: Town) {
        const clTown: ClientTown = {};

        clTown.code = town.code;
        clTown.id = town.id;
        clTown.name = town.name;

        return clTown;
    }

    static fromClientTownList(town: ClientTown[]) {
        return town.map(this.fromClientTown);
    }
}