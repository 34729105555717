/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MaintenancePlanActionDTO
 */
export interface MaintenancePlanActionDTO {
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanActionDTO
     */
    age?: MaintenancePlanActionDTOAgeEnum;
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanActionDTO
     */
    checked?: MaintenancePlanActionDTOCheckedEnum;
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanActionDTO
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanActionDTO
     */
    frequency?: MaintenancePlanActionDTOFrequencyEnum;
    /**
     * 
     * @type {number}
     * @memberof MaintenancePlanActionDTO
     */
    item?: number;
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanActionDTO
     */
    mandatory?: MaintenancePlanActionDTOMandatoryEnum;
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanActionDTO
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanActionDTO
     */
    roleInCharge?: MaintenancePlanActionDTORoleInChargeEnum;
    /**
     * 
     * @type {string}
     * @memberof MaintenancePlanActionDTO
     */
    type?: MaintenancePlanActionDTOTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum MaintenancePlanActionDTOAgeEnum {
    Before1980 = 'BEFORE_1980',
    NoSpecified = 'NO_SPECIFIED'
}/**
* @export
* @enum {string}
*/
export enum MaintenancePlanActionDTOCheckedEnum {
    Checked = 'CHECKED',
    NotChecked = 'NOT_CHECKED'
}/**
* @export
* @enum {string}
*/
export enum MaintenancePlanActionDTOFrequencyEnum {
    Annually = 'ANNUALLY',
    Biennial = 'BIENNIAL',
    Decennial = 'DECENNIAL',
    EighteenYears = 'EIGHTEEN_YEARS',
    EightYears = 'EIGHT_YEARS',
    ElevenYears = 'ELEVEN_YEARS',
    FifteenYears = 'FIFTEEN_YEARS',
    FiveYears = 'FIVE_YEARS',
    FourteenYears = 'FOURTEEN_YEARS',
    FourYears = 'FOUR_YEARS',
    Monthly = 'MONTHLY',
    NineteenYears = 'NINETEEN_YEARS',
    NineYears = 'NINE_YEARS',
    SeventeenYears = 'SEVENTEEN_YEARS',
    SevenYears = 'SEVEN_YEARS',
    SixteenYears = 'SIXTEEN_YEARS',
    SixMonths = 'SIX_MONTHS',
    SixYears = 'SIX_YEARS',
    ThirteenYears = 'THIRTEEN_YEARS',
    ThreeMonths = 'THREE_MONTHS',
    Triennial = 'TRIENNIAL',
    TwelveYears = 'TWELVE_YEARS',
    TwentyfourYears = 'TWENTYFOUR_YEARS',
    TwentyoneYears = 'TWENTYONE_YEARS',
    TwentythreeYears = 'TWENTYTHREE_YEARS',
    TwentytwoYears = 'TWENTYTWO_YEARS',
    TwentyFiveYears = 'TWENTY_FIVE_YEARS',
    TwentyYears = 'TWENTY_YEARS',
    Weekly = 'WEEKLY',
    WhenOccurs = 'WHEN_OCCURS'
}/**
* @export
* @enum {string}
*/
export enum MaintenancePlanActionDTOMandatoryEnum {
    Mandatory = 'MANDATORY',
    Recommended = 'RECOMMENDED'
}/**
* @export
* @enum {string}
*/
export enum MaintenancePlanActionDTORoleInChargeEnum {
    GeneralTechnician = 'GENERAL_TECHNICIAN',
    Inspector = 'INSPECTOR',
    InspectorTechnician = 'INSPECTOR_TECHNICIAN',
    MaintenanceTechnician = 'MAINTENANCE_TECHNICIAN',
    Specialist = 'SPECIALIST',
    User = 'USER'
}/**
* @export
* @enum {string}
*/
export enum MaintenancePlanActionDTOTypeEnum {
    Conservation = 'CONSERVATION',
    CorrectiveMaintenance = 'CORRECTIVE_MAINTENANCE',
    Inspection = 'INSPECTION',
    Maintenance = 'MAINTENANCE',
    NormativePreventiveMaintenance = 'NORMATIVE_PREVENTIVE_MAINTENANCE',
    PeriodicInspectionOfEnergyEfficiency = 'PERIODIC_INSPECTION_OF_ENERGY_EFFICIENCY',
    PreventiveMaintenance = 'PREVENTIVE_MAINTENANCE',
    RegularEvaluationOfPerformance = 'REGULAR_EVALUATION_OF_PERFORMANCE',
    RoutineInspection = 'ROUTINE_INSPECTION',
    SpecializedInspection = 'SPECIALIZED_INSPECTION'
}

export function MaintenancePlanActionDTOFromJSON(json: any): MaintenancePlanActionDTO {
    return MaintenancePlanActionDTOFromJSONTyped(json, false);
}

export function MaintenancePlanActionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaintenancePlanActionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'age': !exists(json, 'age') ? undefined : json['age'],
        'checked': !exists(json, 'checked') ? undefined : json['checked'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'frequency': !exists(json, 'frequency') ? undefined : json['frequency'],
        'item': !exists(json, 'item') ? undefined : json['item'],
        'mandatory': !exists(json, 'mandatory') ? undefined : json['mandatory'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'roleInCharge': !exists(json, 'roleInCharge') ? undefined : json['roleInCharge'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function MaintenancePlanActionDTOToJSON(value?: MaintenancePlanActionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'age': value.age,
        'checked': value.checked,
        'description': value.description,
        'frequency': value.frequency,
        'item': value.item,
        'mandatory': value.mandatory,
        'notes': value.notes,
        'roleInCharge': value.roleInCharge,
        'type': value.type,
    };
}

