import { PropertyDTO, UsageDTO } from "../../clients";

export default class Property {
  antiquity: number;
  id: string;
  complete: boolean;
  autonomousCommunity: string;
  block: string;
  builtSurface: number;
  car: string;
  cc1: string;
  cc2: string;
  door: string;
  floor: string;
  mainUsage: string;
  name: string;
  participationCoefficient: number;
  pc1: string;
  pc2: string;
  portalNumber: string;
  portalNumberLetter: string;
  postalCode: number;
  province: string;
  secondaryPortalLetter: string;
  secondaryPortalNumber: string;
  stair: string;
  town: string;
  type: string;
  usages: UsageDTO[];

  constructor(
    id: string,
    complete: boolean,
    antiquity: number,
    autonomousCommunity: string,
    block: string,
    builtSurface: number,
    car: string,
    cc1: string,
    cc2: string,
    door: string,
    floor: string,
    mainUsage: string,
    name: string,
    participationCoefficient: number,
    pc1: string,
    pc2: string,
    portalNumber: string,
    portalNumberLetter: string,
    postalCode: number,
    province: string,
    secondaryPortalLetter: string,
    secondaryPortalNumber: string,
    stair: string,
    town: string,
    type: string,
    usages: UsageDTO[]
  ) {
    this.id = id;
    this.complete = complete;
    this.antiquity = antiquity;
    this.autonomousCommunity = autonomousCommunity;
    this.block = block;
    this.builtSurface = builtSurface;
    this.car = car;
    this.cc1 = cc1;
    this.cc2 = cc2;
    this.door = door;
    this.floor = floor;
    this.mainUsage = mainUsage;
    this.name = name;
    this.participationCoefficient = participationCoefficient;
    this.pc1 = pc1;
    this.pc2 = pc2;
    this.portalNumber = portalNumber;
    this.portalNumberLetter = portalNumberLetter;
    this.postalCode = postalCode;
    this.province = province;
    this.secondaryPortalLetter = secondaryPortalLetter;
    this.secondaryPortalNumber = secondaryPortalNumber;
    this.stair = stair;
    this.town = town;
    this.type = type;
    this.usages = usages;
  }

  static fromClientProperty(property: PropertyDTO) {
    const result = new Property(
      property.id!!,
      property.complete!!,
      property.antiquity!!,
      property.community!!,
      property.block!!,
      property.builtSurface!!,
      property.car!!,
      property.cc1!!,
      property.cc2!!,
      property.door!!,
      property.floor!!,
      property.mainUsage!!,
      property.streetName!!,
      property.participationCoefficient!!,
      property.pc1!!,
      property.pc2!!,
      property.portalNumber!!,
      property.portalNumberLetter!!,
      property.postalCode!!,
      property.province!!,
      property.secondaryPortalLetter!!,
      property.secondaryPortalNumber!!,
      property.stair!!,
      property.town!!,
      property.streetType!!,
      property.usages!!
    );
    return result;
  }

  static toClientProperty(property: Property) {
    let result: PropertyDTO = {};
    result.id = property.id;
    result.complete = property.complete;
    result.antiquity = property.antiquity;
    result.community = property.autonomousCommunity;
    result.block = property.block;
    result.builtSurface = property.builtSurface;
    result.car = property.car;
    result.cc1 = property.cc1;
    result.cc2 = property.cc2;
    result.door = property.door;
    result.floor = property.floor;
    result.mainUsage = property.mainUsage;
    result.streetName = property.name;
    result.participationCoefficient = property.participationCoefficient;
    result.pc1 = property.pc1;
    result.pc2 = property.pc2;
    result.portalNumber = property.portalNumber;
    result.portalNumberLetter = property.portalNumberLetter;
    result.postalCode = property.postalCode;
    result.province = property.province;
    result.secondaryPortalLetter = property.secondaryPortalLetter;
    result.secondaryPortalNumber = property.secondaryPortalNumber;
    result.stair = property.stair;
    result.town = property.town;
    result.streetType = property.type;
    result.usages = property.usages;
    return result;
  }
}
