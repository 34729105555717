/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MediaPrintedDTO,
    MediaPrintedDTOFromJSON,
    MediaPrintedDTOFromJSONTyped,
    MediaPrintedDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OtherImprovementPrintedDTO
 */
export interface OtherImprovementPrintedDTO {
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementPrintedDTO
     */
    ageOfInstallations?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof OtherImprovementPrintedDTO
     */
    ageOfInstallationsMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementPrintedDTO
     */
    asbestosPresence?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof OtherImprovementPrintedDTO
     */
    asbestosPresenceMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementPrintedDTO
     */
    consumptionAccounting?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof OtherImprovementPrintedDTO
     */
    consumptionAccountingMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementPrintedDTO
     */
    darkZonesOnFacade?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof OtherImprovementPrintedDTO
     */
    darkZonesOnFacadeMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementPrintedDTO
     */
    digitizationAndMonitoring?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof OtherImprovementPrintedDTO
     */
    digitizationAndMonitoringMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementPrintedDTO
     */
    electricCondition?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof OtherImprovementPrintedDTO
     */
    electricConditionMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementPrintedDTO
     */
    facadeDeficiencies?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof OtherImprovementPrintedDTO
     */
    facadeDeficienciesMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementPrintedDTO
     */
    greenRoof?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof OtherImprovementPrintedDTO
     */
    greenRoofMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementPrintedDTO
     */
    parkSituation?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof OtherImprovementPrintedDTO
     */
    parkSituationMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementPrintedDTO
     */
    permeableExteriorSurfaces?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof OtherImprovementPrintedDTO
     */
    permeableExteriorSurfacesMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementPrintedDTO
     */
    rainWaterSaving?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof OtherImprovementPrintedDTO
     */
    rainWaterSavingMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementPrintedDTO
     */
    solarProtectionZones?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof OtherImprovementPrintedDTO
     */
    solarProtectionZonesMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementPrintedDTO
     */
    telecomConditions?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof OtherImprovementPrintedDTO
     */
    telecomConditionsMedia?: MediaPrintedDTO;
    /**
     * 
     * @type {string}
     * @memberof OtherImprovementPrintedDTO
     */
    zoneOfElectricCharge?: string;
    /**
     * 
     * @type {MediaPrintedDTO}
     * @memberof OtherImprovementPrintedDTO
     */
    zoneOfElectricChargeMedia?: MediaPrintedDTO;
}

export function OtherImprovementPrintedDTOFromJSON(json: any): OtherImprovementPrintedDTO {
    return OtherImprovementPrintedDTOFromJSONTyped(json, false);
}

export function OtherImprovementPrintedDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OtherImprovementPrintedDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ageOfInstallations': !exists(json, 'ageOfInstallations') ? undefined : json['ageOfInstallations'],
        'ageOfInstallationsMedia': !exists(json, 'ageOfInstallationsMedia') ? undefined : MediaPrintedDTOFromJSON(json['ageOfInstallationsMedia']),
        'asbestosPresence': !exists(json, 'asbestosPresence') ? undefined : json['asbestosPresence'],
        'asbestosPresenceMedia': !exists(json, 'asbestosPresenceMedia') ? undefined : MediaPrintedDTOFromJSON(json['asbestosPresenceMedia']),
        'consumptionAccounting': !exists(json, 'consumptionAccounting') ? undefined : json['consumptionAccounting'],
        'consumptionAccountingMedia': !exists(json, 'consumptionAccountingMedia') ? undefined : MediaPrintedDTOFromJSON(json['consumptionAccountingMedia']),
        'darkZonesOnFacade': !exists(json, 'darkZonesOnFacade') ? undefined : json['darkZonesOnFacade'],
        'darkZonesOnFacadeMedia': !exists(json, 'darkZonesOnFacadeMedia') ? undefined : MediaPrintedDTOFromJSON(json['darkZonesOnFacadeMedia']),
        'digitizationAndMonitoring': !exists(json, 'digitizationAndMonitoring') ? undefined : json['digitizationAndMonitoring'],
        'digitizationAndMonitoringMedia': !exists(json, 'digitizationAndMonitoringMedia') ? undefined : MediaPrintedDTOFromJSON(json['digitizationAndMonitoringMedia']),
        'electricCondition': !exists(json, 'electricCondition') ? undefined : json['electricCondition'],
        'electricConditionMedia': !exists(json, 'electricConditionMedia') ? undefined : MediaPrintedDTOFromJSON(json['electricConditionMedia']),
        'facadeDeficiencies': !exists(json, 'facadeDeficiencies') ? undefined : json['facadeDeficiencies'],
        'facadeDeficienciesMedia': !exists(json, 'facadeDeficienciesMedia') ? undefined : MediaPrintedDTOFromJSON(json['facadeDeficienciesMedia']),
        'greenRoof': !exists(json, 'greenRoof') ? undefined : json['greenRoof'],
        'greenRoofMedia': !exists(json, 'greenRoofMedia') ? undefined : MediaPrintedDTOFromJSON(json['greenRoofMedia']),
        'parkSituation': !exists(json, 'parkSituation') ? undefined : json['parkSituation'],
        'parkSituationMedia': !exists(json, 'parkSituationMedia') ? undefined : MediaPrintedDTOFromJSON(json['parkSituationMedia']),
        'permeableExteriorSurfaces': !exists(json, 'permeableExteriorSurfaces') ? undefined : json['permeableExteriorSurfaces'],
        'permeableExteriorSurfacesMedia': !exists(json, 'permeableExteriorSurfacesMedia') ? undefined : MediaPrintedDTOFromJSON(json['permeableExteriorSurfacesMedia']),
        'rainWaterSaving': !exists(json, 'rainWaterSaving') ? undefined : json['rainWaterSaving'],
        'rainWaterSavingMedia': !exists(json, 'rainWaterSavingMedia') ? undefined : MediaPrintedDTOFromJSON(json['rainWaterSavingMedia']),
        'solarProtectionZones': !exists(json, 'solarProtectionZones') ? undefined : json['solarProtectionZones'],
        'solarProtectionZonesMedia': !exists(json, 'solarProtectionZonesMedia') ? undefined : MediaPrintedDTOFromJSON(json['solarProtectionZonesMedia']),
        'telecomConditions': !exists(json, 'telecomConditions') ? undefined : json['telecomConditions'],
        'telecomConditionsMedia': !exists(json, 'telecomConditionsMedia') ? undefined : MediaPrintedDTOFromJSON(json['telecomConditionsMedia']),
        'zoneOfElectricCharge': !exists(json, 'zoneOfElectricCharge') ? undefined : json['zoneOfElectricCharge'],
        'zoneOfElectricChargeMedia': !exists(json, 'zoneOfElectricChargeMedia') ? undefined : MediaPrintedDTOFromJSON(json['zoneOfElectricChargeMedia']),
    };
}

export function OtherImprovementPrintedDTOToJSON(value?: OtherImprovementPrintedDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ageOfInstallations': value.ageOfInstallations,
        'ageOfInstallationsMedia': MediaPrintedDTOToJSON(value.ageOfInstallationsMedia),
        'asbestosPresence': value.asbestosPresence,
        'asbestosPresenceMedia': MediaPrintedDTOToJSON(value.asbestosPresenceMedia),
        'consumptionAccounting': value.consumptionAccounting,
        'consumptionAccountingMedia': MediaPrintedDTOToJSON(value.consumptionAccountingMedia),
        'darkZonesOnFacade': value.darkZonesOnFacade,
        'darkZonesOnFacadeMedia': MediaPrintedDTOToJSON(value.darkZonesOnFacadeMedia),
        'digitizationAndMonitoring': value.digitizationAndMonitoring,
        'digitizationAndMonitoringMedia': MediaPrintedDTOToJSON(value.digitizationAndMonitoringMedia),
        'electricCondition': value.electricCondition,
        'electricConditionMedia': MediaPrintedDTOToJSON(value.electricConditionMedia),
        'facadeDeficiencies': value.facadeDeficiencies,
        'facadeDeficienciesMedia': MediaPrintedDTOToJSON(value.facadeDeficienciesMedia),
        'greenRoof': value.greenRoof,
        'greenRoofMedia': MediaPrintedDTOToJSON(value.greenRoofMedia),
        'parkSituation': value.parkSituation,
        'parkSituationMedia': MediaPrintedDTOToJSON(value.parkSituationMedia),
        'permeableExteriorSurfaces': value.permeableExteriorSurfaces,
        'permeableExteriorSurfacesMedia': MediaPrintedDTOToJSON(value.permeableExteriorSurfacesMedia),
        'rainWaterSaving': value.rainWaterSaving,
        'rainWaterSavingMedia': MediaPrintedDTOToJSON(value.rainWaterSavingMedia),
        'solarProtectionZones': value.solarProtectionZones,
        'solarProtectionZonesMedia': MediaPrintedDTOToJSON(value.solarProtectionZonesMedia),
        'telecomConditions': value.telecomConditions,
        'telecomConditionsMedia': MediaPrintedDTOToJSON(value.telecomConditionsMedia),
        'zoneOfElectricCharge': value.zoneOfElectricCharge,
        'zoneOfElectricChargeMedia': MediaPrintedDTOToJSON(value.zoneOfElectricChargeMedia),
    };
}

