/* eslint-disable react-hooks/exhaustive-deps */
import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { ExtendedSignupTechnicianFareEnum, ProfileDTO } from "../clients";
import BackDropLoading from "../components/backdrop-loading";
import ErrorDialog from "../components/error-dialog/error-dialog";
import LeeToolbar from "../components/toolbar/lee-toolbar";
import { SessionStoreFactory } from "../infrastructure/data/session-store-factory";
import ProfileViewModel from "../viewmodels/profile/profile-view-model";
import RegisterViewModel from "../viewmodels/register/register-view-model";
import ReportViewModel from "../viewmodels/report/report-view-model";
import RootViewModel from "../viewmodels/root-view-model";
import { getUrlVars, getUrlParam, checkIfTimestampIsLessThan3HoursOld, checkMD5 } from "./root-uri-utilities";

const RootView: ViewComponent<RootViewModel> = observer(({ vm }) => {
  const [profile, setProfile] = useState<ProfileDTO | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();
  const handleOpenSnackBar = (snackKey: string, variants: undefined | "default" | "error" | "success" | "warning" | "info", duration: number = 3000) => {
    enqueueSnackbar(snackKey, { variant: variants, preventDuplicate: true, key: snackKey, autoHideDuration: duration });
  };

  const [completed, setCompleted] = React.useState(0);
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    setTotal((vm.children.find((c) => c.navigationName === "report") as ReportViewModel).totalUnfinished);
    setCompleted((vm.children.find((c) => c.navigationName === "report") as ReportViewModel).completed);
  }, [(vm.children.find((c) => c.navigationName === "report") as ReportViewModel).completed]);

  React.useEffect(() => {
    const loadProfile = async () => {
      try {
        vm.technicianData?.id && (vm.children.find((c) => c.navigationName === "profile") as ProfileViewModel)?.searchProfileBooks({}, true);
      } catch (e) {
        handleOpenSnackBar(vm.translate("root.error.getbooks"), "error", 4000);
        (vm.children.find((f) => f.navigationName === "report") as ReportViewModel).loadingBook = false;
      }
    }
    loadProfile()
  }, [vm.technicianData?.id]);

  useEffect(() => {
    if (window.location.pathname.startsWith("/sso_token")) {
      const parameters = getUrlVars();
      const dni = getUrlParam("sso_token", "", parameters);
      const email = getUrlParam("sso_email", "", parameters);
      const timestamp = getUrlParam("sso_timestamp", "", parameters);
      const hash = getUrlParam("sso_hash", "", parameters);
      // const gender = getUrlParam("sso_sex", "");
      const nme = getUrlParam("sso_name", "", parameters);
      const surname = getUrlParam("sso_surname", "", parameters);
      const hashFix = hash.split("#")[0];
      if (!checkIfTimestampIsLessThan3HoursOld(timestamp)) {
        handleOpenSnackBar(vm.translate("root.cgate.expirated.token"), "error", 15000);
        return;
      }

      if (checkMD5(dni, timestamp, hashFix + "")) {
        handleOpenSnackBar(vm.translate("root.cgate.wrong.md5"), "error", 15000);
        return;
      }

      handleOpenSnackBar(vm.translate("root.cgate.wrong.md5.success"), "success", 3000);

      const registervm = vm.children.find((c: any) => c.navigationName === "register") as RegisterViewModel;

      registervm.item.idNumber = dni;
      registervm.item.email = email;
      registervm.item.name = nme;
      registervm.item.surname = surname;
      registervm.item.fare = ExtendedSignupTechnicianFareEnum.Cgate;
      vm.navigate("register", {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (vm.children[4] as ProfileViewModel).profileAdmin && setProfile((vm.children[4] as ProfileViewModel).profileAdmin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(vm.children[4] as ProfileViewModel).profileAdmin]);

  window.addEventListener("unhandledrejection", async (event) => {
    if (event.reason.status === 401 && vm.errorLogin === false) {
      vm.errorLogin = true;
    }
  });

  return (
    <React.Fragment>
      <BackDropLoading
        completed={completed}
        total={total}
        estimatedTimeSubtitle={vm.translate("estimated.time")}
        estimatedTimeTitle={vm.translate("data.recovery.from.cadastre")}
        estimatedTimeUnit={vm.translate("recovered.properties")}
        showLoading={(vm.children.find((c) => c.navigationName === "report")! as any).loadingBook!}
      />

      <ErrorDialog
        title={vm.translate("refresh.token.error")}
        confirmText={vm.translate("root.access.renew")}
        handleError={() => {
          vm.logOff();
          vm.errorLogin = false;
        }}
        handleClose={() => {
          vm.logOff();
          vm.errorLogin = false;
        }}
        handleCancel={() => {
          vm.logOff();
          vm.errorLogin = false;
        }}
        open={vm.errorLogin}
      />
      <div>
        <LeeToolbar
          rootvm={vm}
          popOverProps={{
            currentUser: SessionStoreFactory.getSessionStore().getToken() && profile?.role === "TECHNICIAN" ? (vm.technicianData as any) || {} : {},
            currentRegisterUser: (vm.children.find((c: any) => c.navigationName === "register") as RegisterViewModel).item,
            currentAdmin: SessionStoreFactory.getSessionStore().getToken() && profile?.role !== "TECHNICIAN" ? profile : undefined,
          }}
        >
          <View vm={vm.activeChild} />
        </LeeToolbar>
      </div>
    </React.Fragment>
  );
});

export default registerView(RootView, RootViewModel);
