/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UsageDTO
 */
export interface UsageDTO {
    /**
     * 
     * @type {string}
     * @memberof UsageDTO
     */
    block?: string;
    /**
     * 
     * @type {string}
     * @memberof UsageDTO
     */
    cadastralReference?: string;
    /**
     * 
     * @type {string}
     * @memberof UsageDTO
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UsageDTO
     */
    door?: string;
    /**
     * 
     * @type {string}
     * @memberof UsageDTO
     */
    floor?: string;
    /**
     * 
     * @type {string}
     * @memberof UsageDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UsageDTO
     */
    stair?: string;
    /**
     * 
     * @type {number}
     * @memberof UsageDTO
     */
    surface?: number;
}

export function UsageDTOFromJSON(json: any): UsageDTO {
    return UsageDTOFromJSONTyped(json, false);
}

export function UsageDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsageDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'block': !exists(json, 'block') ? undefined : json['block'],
        'cadastralReference': !exists(json, 'cadastralReference') ? undefined : json['cadastralReference'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'door': !exists(json, 'door') ? undefined : json['door'],
        'floor': !exists(json, 'floor') ? undefined : json['floor'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'stair': !exists(json, 'stair') ? undefined : json['stair'],
        'surface': !exists(json, 'surface') ? undefined : json['surface'],
    };
}

export function UsageDTOToJSON(value?: UsageDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'block': value.block,
        'cadastralReference': value.cadastralReference,
        'description': value.description,
        'door': value.door,
        'floor': value.floor,
        'id': value.id,
        'stair': value.stair,
        'surface': value.surface,
    };
}

