import { registerView } from '@frui.ts/views'
import { Button, TypographyTheme } from "@movicoders/movicoders-components"
import { Grid, Paper } from "@mui/material"
import i18n from 'i18next'
import { observer } from "mobx-react-lite"
import React from 'react'
import mainLogo from '../../assets/images/logo.png'
import { Colors } from "../../constants"
import LandingViewModel from "../../viewmodels/landing/landing-view-model"
import { LoginStyles } from "../login/login-style"

export const LandingScreen: React.FC<{ vm: LandingViewModel }> = observer(({ vm }) => {
  const classes = LoginStyles()

  return (
    <Grid container className={classes.launch_container}>
      <Grid item xs={12} md={12} className={classes.login_grid}>
        <Paper id="paperLandingView" elevation={12} style={{ backgroundColor: Colors.primary, width: "40%", height: "40%", justifyContent: "center", alignItems: "center" }}>
          <Grid container className={classes.login_grid} style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center", paddingTop: 20, paddingBottom: 20 }}>
            <Grid justifyContent={"center"} alignItems={"center"} container item xs={12} style={{ marginBottom: 10 }}>
              <img src={mainLogo} alt={"mainLogo"} height={150} width={140} />
            </Grid>
            <Grid justifyContent={"center"} alignItems={"center"} container item xs={12} style={{ marginBottom: 10 }}>
              <TypographyTheme bold text={"LEEx"} variant="h1" size={30} textAlign='start' color={Colors.white} />
            </Grid>
            <Grid justifyContent={"space-evenly"} alignItems={"center"} container item xs={12} style={{ marginBottom: 10 }}>
              <Button text={i18n.t("launchScreen.loginButton")} classButton={classes.launch_loginButton} onClick={() => vm.login()} />
              <Button text={i18n.t("launchScreen.registryButton")} classButton={classes.launch_registryButton} onClick={() => vm.register()} />
            </Grid>
          </Grid>
        </Paper >
      </Grid >
    </Grid >
  )
})

registerView(LandingScreen, LandingViewModel);