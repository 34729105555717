import { Api } from "../api-client"
import ApiClient from '../api-client'

export class BaseRepository<T> {
  isMocked: boolean
  api: Api

  constructor(api: Api, mocked: boolean) {
    this.isMocked = mocked
    this.api = api
  }

  get apiClient() {
    return ApiClient.clientFor<T>(this.api, this.isMocked)
  }
}