import { Coordinates, PolygonDTO, PolygonDTOCoordinatesSystemEnum } from "../clients"
import proj4 from "proj4";



proj4.defs("EPSG:4258", "+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs");
proj4.defs("EPSG:25830", "+proj=utm +zone=30 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");

/*export const geoJsonFromClientCoordinates = (coords: ClientCoordinates[]) => {
    return geoJsonFromCoordinates(coords.map(item => fromClient(item)))
}*/

export type TGeoJsonCropped = {
  type: string
  geometry: {
    type: string,
    coordinates: (number | undefined)[][][][]
  },
  properties: Map<string, any> | {}
}

export const geoJsonFromCoordinates = (coords: PolygonDTO, properties?: Map<string, any>): TGeoJsonCropped => {
  let coordinatesSystem = ""

  let coordsArray = coords.content?.map((item: Coordinates) => [item.lng, item.lat])
  if (coords) {
    coordinatesSystem = coords.coordinatesSystem ? coords.coordinatesSystem : PolygonDTOCoordinatesSystemEnum.Epsg4258
  }
  if (coordinatesSystem === PolygonDTOCoordinatesSystemEnum.Epsg25830) {
    coordsArray = coords.content?.map((coord: Coordinates) => {
      const converted = proj4("EPSG:25830", 'EPSG:4258', [coord.lng ?? 0, coord.lat ?? 0])
      return [converted[0], converted[1]]
    })
  }
  const props = properties ? properties : {
    "stroke": "#555555",
    "stroke-width": 2,
    "stroke-opacity": 1,
    "fill": "#555555",
    "fill-opacity": 0.5
  }
  const geoJson = {
    "type": "Feature",
    "geometry": {
      "type": "MultiPolygon",
      "coordinates": [
        [
          [
            ...coordsArray ?? []
          ]
        ]
      ]
    },
    "properties": props

  }
  return geoJson
}