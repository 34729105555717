import axios from "axios";
import LoginRequest from "../../../models/login-request";
import LoginResponse from "../../../models/login-response";

export default class LoginRepository {
  async loginAsync(payload: LoginRequest): Promise<any> {
    const swUrl = `./mocks/login.json`;

    return await axios.get<LoginResponse>(swUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        timeout: 10000,
      });
  }
}
