import { registerView, ViewComponent } from "@frui.ts/views";
import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { Suspense } from "react";
import { ExtendedSignupTechnicianRoleEnum, ProfileDTORoleEnum } from "../../clients/models";
import ProfileViewModel from "../../viewmodels/profile/profile-view-model";
import { ProfileDialogCollegeAdmin } from "./aragon-dashboard/profile-edit";
import { ProfileDialog } from "./profile-components/pofile-dialog";
import { ProfileAvatar } from "./profile-components/profile-avatar";
import { ProfileCounts } from "./profile-components/profile-counts";
import { ProfileTable } from "./profile-components/profile-table";
import { ProfileDialogSystemAdmin } from "./system-admin/profile-edit-admin";

const CollegeAdministratorBooks = React.lazy(() => import("./aragon-dashboard/college-administrator-books"));
const CollegeAdministrator = React.lazy(() => import("./aragon-dashboard/college-administrator"));
const ProfileCountsCollegeAdmin = React.lazy(() => import("./profile-components/ProfileCountsCollegeAdmin"));
const ProfileAvatar2 = React.lazy(() => import("./profile-components/ProfileAvatar2"));
const SystemAdministrator = React.lazy(() => import("./system-admin/system-administrator"));

const ProfileView: ViewComponent<ProfileViewModel> = observer(({ vm }) => {
  window.addEventListener("unhandledrejection", async (event) => {
    if (event.reason.status === 401 && vm.parent.errorLogin === false) {
      vm.parent.errorLogin = true;
    }
  });

  const dashboard = (role?: ProfileDTORoleEnum | ExtendedSignupTechnicianRoleEnum) => {
    switch (role) {
      case ProfileDTORoleEnum.CollegeAdministrator:
        return (
          <React.Fragment>
            <ProfileCountsCollegeAdmin vm={vm} />
            <Grid container display="inline-flex" style={{ padding: 30 }}>
              <ProfileAvatar2 vm={vm} />
              <CollegeAdministrator vm={vm} />
              <div style={{ width: "100%", marginBottom: "20px" }} />
              <CollegeAdministratorBooks vm={vm} />
            </Grid>
            {<ProfileDialogCollegeAdmin vm={vm} />}
          </React.Fragment>
        );
      case ProfileDTORoleEnum.SystemAdministrator:
        return (
          <React.Fragment>
            <Grid item xs={12}>
              <SystemAdministrator vm={vm} />
            </Grid>
            {vm.editUserProfileCollegeAdmin && vm.editUserProfileCollegeAdminProfile && <ProfileDialogSystemAdmin vm={vm} />}
          </React.Fragment>
        );
      case ProfileDTORoleEnum.Technician:
        return (
          <React.Fragment>
            <ProfileCounts vm={vm} />
            <Grid container style={{ padding: 30 }}>
              <ProfileAvatar vm={vm} />
              <ProfileTable vm={vm} />
            </Grid>
            {vm.editUserProfile && <ProfileDialog vm={vm} />}
          </React.Fragment>
        );
      default:
        return <></>;
    }
  };

  return <React.Fragment><Suspense fallback={<div></div>}>{dashboard(vm.profileAdmin?.role ?? vm.profileUser?.role ?? ProfileDTORoleEnum.Technician)}</Suspense></React.Fragment>;
});

registerView(ProfileView, ProfileViewModel);
