import { observer } from "mobx-react-lite";
import ReportViewModel from "../../../../../../viewmodels/report/report-view-model";
import { useDropzone } from "react-dropzone";
import { Box, Button, Card, CardContent, CardMedia, Grid, Paper, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { AttachFile, DeleteForever } from "@mui/icons-material";

const fileSizeValidator = (file: File) => {
  if (file.type.includes("image") && file.size > 500000) {
    return { code: "image-too-large", message: "file.size.error500kb" };
  } else if (file.size > 5000000) {
    return { code: "file-too-large", message: "file.size.error5mb" };
  } else if (file.name.includes("+" || "%")) {
    return { code: "file-name-error", message: "uploadNameError" };
  }
  return null;
};

interface CustomReactDropzoneProps {
  vm: ReportViewModel;
  infoTitle: string;
  infoMessage: string;
  retrieveFiles: (files: File[]) => void;
  oneFile?: boolean;
  maxFiles?: number;
}

export const CustomReactDropzone: React.FC<CustomReactDropzoneProps> = observer(
  ({ vm, infoMessage, infoTitle, retrieveFiles, oneFile = false, maxFiles = 15 }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [files, setFiles] = useState<File[]>([]);

    const onDrop = useCallback(
      (acceptedFiles, rejectedFiles) => {
        // @ts-ignore
        rejectedFiles.forEach(({ file, errors }) => {
          const { code, message } = errors[0];
          switch (code) {
            case "file-too-large":
              handleOpenSnackBar(`${file.name} -- ${vm.translate(message)}`, "error");
              break;
            case "image-too-large":
              handleOpenSnackBar(`${file.name} -- ${vm.translate(message)}`, "error");
              break;
            case "file-name-error":
              handleOpenSnackBar(vm.translate(message), "error");
              break;
          }
        });

        if (oneFile) {
          retrieveFiles([acceptedFiles[0]]);
          setFiles([acceptedFiles[0]]);
        } else {
          retrieveFiles([...files, ...acceptedFiles]);
          setFiles([...files, ...acceptedFiles]);
        }
      },
      [files]
    );

    const { getRootProps, getInputProps } = useDropzone({ validator: fileSizeValidator, maxFiles, onDrop });

    const removeFile = (file: any) => () => {
      setFiles(files.filter((f) => file.name !== f.name));
    };

    const removeAll = () => {
      setFiles([]);
    };

    const renderFileCard = (file: File, index: number) => (
      <Grid item sx={{ height: "100", margin: "5px" }} key={`${file.name} ${index}`}>
        <Card sx={{ width: "160px" }}>
          {file.type.includes("image") ? (
            <CardMedia
              sx={{ padding: "5px", objectFit: "contain" }}
              component="img"
              height="100"
              image={URL.createObjectURL(file)}
              alt="failed to load image"
            />
          ) : (
            <div style={{ height: "100px", width: "100%", padding: "5px" }}>
              <AttachFile sx={{ height: "100%", width: "100%" }} />
            </div>
          )}
          <CardContent>
            <Typography variant="body1" style={{ fontSize: 11, textOverflow: "hidden" }}>
              {file.name}
            </Typography>
            <Grid xs={12} container marginTop={1} alignContent={"center"} alignItems={"center"}>
              <Grid xs={10} alignContent={"center"} alignItems={"center"}>
                <Typography style={{ fontSize: 11 }}>{Number(file.size / 1024).toFixed(2)} Kb</Typography>
              </Grid>
              <Grid xs={2}>
                <DeleteForever sx={{ cursor: "pointer" }} onClick={removeFile(file)} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );

    const filelist = oneFile ? files[0] && renderFileCard(files[0], 0) : files.map((file, index) => renderFileCard(file, index));

    const handleOpenSnackBar = (snackKey: string, variants: undefined | "default" | "error" | "success" | "warning" | "info") => {
      enqueueSnackbar(snackKey, { variant: variants, preventDuplicate: true, key: snackKey, autoHideDuration: 6500 });
    };

    useEffect(() => {
      return () => {
        setFiles([]);
      };
    }, []);

    return (
      <Paper sx={{ marginBottom: "2px" }}>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            border: "2px dashed #000",
            padding: "10px",
            borderRadius: "10px",
            margin: "20px",
            width: "93%",
            height: "180px",
            textAlign: "center",
          }}
        >
          <Grid
            {...getRootProps({
              style: {
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                textAlign: "center",
              },
            })}
          >
            <input {...getInputProps()} />
            <p>{infoTitle}</p>
            <em>({infoMessage})</em>
          </Grid>
        </Box>
        <Grid container>{filelist}</Grid>
        {!oneFile && files?.length ? (
          <Button style={{ marginTop: "2px" }} onClick={removeAll}>
            Quitar todos
          </Button>
        ) : null}
      </Paper>
    );
  }
);
