import React, { useState } from "react"
import { Grid } from "@mui/material"
import { observer } from "mobx-react-lite"
import { AddButtonsSection } from "./components/add-users-buttons"
import ReportViewModel from "../../../../../viewmodels/report/report-view-model"
import { TechnicianRedactorsDialog } from "./components/techinician-redactors-dialog"
import { TechnicianDTO } from "../../../../../clients"


export type TTechnicianRedactors = {
  technicians?: TTechnicianParticipation[]
  setTechnicians?: (value: TTechnicianParticipation[]) => void
}

export type TTechnicianParticipation = {
  technician?: TechnicianDTO
  participation?: number
}

export const TechnicianRedactors: React.FC<{ vm: ReportViewModel }> = observer(({ vm }) => {

  const [technicians, setTechnicians] = useState<TTechnicianParticipation[]>([]);

  return (
    <Grid container>
      {vm.profileTechnician && <TechnicianRedactorsDialog vm={vm} props={{ technicians: technicians, setTechnicians: setTechnicians }} />}

      <React.Fragment>
        <AddButtonsSection vm={vm} props={{ technicians: technicians, setTechnicians: setTechnicians }} />
      </React.Fragment>
    </Grid>
  )
})