import { TypographyTheme } from "@movicoders/movicoders-components";
import { Grid } from "@mui/material";
import React from "react";
import { Colors, FontSizes } from "../constants";
import { ReportStyle } from "../views/reports/report-style";

export interface ITextField {
  id: string;
  title: string;
  marginTop?: boolean;
}

function TypographyTitle(props: ITextField) {
  const classes = ReportStyle();

  return (
    <React.Fragment>
      <Grid item xs={12} md={12} style={{ marginTop: props.marginTop ? 20 : 0 }}>
        <TypographyTheme id={props.id} size={FontSizes.h1} color={Colors.textPrimaryHeaders} text={props.title} />
        <hr className={classes.report_hr} />
      </Grid>
    </React.Fragment>
  );
}

export default TypographyTitle;
