/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdminTechnicianParticipationDTO,
    AdminTechnicianParticipationDTOFromJSON,
    AdminTechnicianParticipationDTOFromJSONTyped,
    AdminTechnicianParticipationDTOToJSON,
    ParticipationTechnicianDTO,
    ParticipationTechnicianDTOFromJSON,
    ParticipationTechnicianDTOFromJSONTyped,
    ParticipationTechnicianDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface AdminParticipationListItemDTO
 */
export interface AdminParticipationListItemDTO {
    /**
     * 
     * @type {number}
     * @memberof AdminParticipationListItemDTO
     */
    amount?: number;
    /**
     * 
     * @type {AdminTechnicianParticipationDTO}
     * @memberof AdminParticipationListItemDTO
     */
    book?: AdminTechnicianParticipationDTO;
    /**
     * 
     * @type {string}
     * @memberof AdminParticipationListItemDTO
     */
    currency?: string;
    /**
     * 
     * @type {ParticipationTechnicianDTO}
     * @memberof AdminParticipationListItemDTO
     */
    technician?: ParticipationTechnicianDTO;
    /**
     * 
     * @type {string}
     * @memberof AdminParticipationListItemDTO
     */
    type?: AdminParticipationListItemDTOTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum AdminParticipationListItemDTOTypeEnum {
    ArchitectsCollege = 'ARCHITECTS_COLLEGE',
    RiggersAndTechnicalArchitects = 'RIGGERS_AND_TECHNICAL_ARCHITECTS'
}

export function AdminParticipationListItemDTOFromJSON(json: any): AdminParticipationListItemDTO {
    return AdminParticipationListItemDTOFromJSONTyped(json, false);
}

export function AdminParticipationListItemDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminParticipationListItemDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'book': !exists(json, 'book') ? undefined : AdminTechnicianParticipationDTOFromJSON(json['book']),
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'technician': !exists(json, 'technician') ? undefined : ParticipationTechnicianDTOFromJSON(json['technician']),
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function AdminParticipationListItemDTOToJSON(value?: AdminParticipationListItemDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'book': AdminTechnicianParticipationDTOToJSON(value.book),
        'currency': value.currency,
        'technician': ParticipationTechnicianDTOToJSON(value.technician),
        'type': value.type,
    };
}

