/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccessibilityEvaluationMediaDTO
 */
export interface AccessibilityEvaluationMediaDTO {
    /**
     * 
     * @type {string}
     * @memberof AccessibilityEvaluationMediaDTO
     */
    media?: string;
}

export function AccessibilityEvaluationMediaDTOFromJSON(json: any): AccessibilityEvaluationMediaDTO {
    return AccessibilityEvaluationMediaDTOFromJSONTyped(json, false);
}

export function AccessibilityEvaluationMediaDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessibilityEvaluationMediaDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'media': !exists(json, 'media') ? undefined : json['media'],
    };
}

export function AccessibilityEvaluationMediaDTOToJSON(value?: AccessibilityEvaluationMediaDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'media': value.media,
    };
}

