import { Button, Switch, TextField } from "@movicoders/movicoders-components";
import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Colors } from "../../../constants";
import User from "../../../data/entities/user-entity";
import { validEmail } from "../../../utils/regexHelper";
import UserDetailViewModel from "../../../viewmodels/users/user-detail-view-model";
import { UserStyle } from "../UserStyle";

export const UserForm: React.FC<{ vm: UserDetailViewModel }> = observer(({ vm }) => {
  const classes = UserStyle();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userNameError, setUserNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [usersClone] = useState<User[]>([]);

  const { enqueueSnackbar } = useSnackbar();
  const handleOpenSnackBar = (snackKey: string, variants: undefined | "default" | "error" | "success" | "warning" | "info") => {
    enqueueSnackbar(snackKey, { variant: variants, preventDuplicate: true, key: snackKey, autoHideDuration: 3000 });
  };

  const handleUserNameError = () => {
    const usersCloneFilterName = usersClone!!.filter((item: User) => item.username!!.toLocaleLowerCase() === vm.itemAux.username!!.toLocaleLowerCase())[0];
    if (
      (usersCloneFilterName?.username?.toLocaleLowerCase() === vm.itemAux.username!!.toLocaleLowerCase() && usersCloneFilterName.id !== vm.itemAux.id) ||
      vm.itemAux.username === ""
    ) {
      setUserNameError(true);
    } else {
      setUserNameError(false);
    }
  };

  const handleEmailError = () => {
    if (vm.itemAux.email !== "" && !validEmail.test(vm.itemAux.email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const handlePasswordError = () => {
    if (vm.itemAux.password !== confirmPassword) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };

  const save = async () => {
    !userNameError && !emailError && !passwordError
      ? vm.item.id === -1
        ? vm.create().then((/*res: boolean*/) => {})
        : vm.update().then((/*res: boolean*/) => {})
      : handleOpenSnackBar(vm.translate("user.error"), "error");
    vm.requestClose();
    vm.parent.setSelected(undefined);
  };

  useEffect(() => {
    const users = vm.parent.items;
    usersClone.length === 0 && users.forEach((val) => usersClone.push(Object.assign({}, val)));
  });

  useEffect(() => {
    handlePasswordError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmPassword]);

  return (
    <Grid container direction={"column"}>
      <TextField
        id="userFormName"
        label={vm.translate("user.name")}
        error={userNameError}
        errorText={vm.translate("user.name.error")}
        variant={"outlined"}
        disabled={vm.item.id !== -1}
        classTextField={classes.user_textField}
        value={vm.item.username}
        onChange={(e: any) => {
          vm.itemAux.username = e.target.value;
          handleUserNameError();
        }}
      />

      <TextField
        id="userFormEmail"
        variant={"outlined"}
        error={emailError}
        errorText={vm.translate("user.email.error")}
        label={vm.translate("user.email")}
        classTextField={classes.user_textField}
        value={vm.item.email}
        disabled={vm.item.id !== -1}
        onChange={(e: any) => {
          vm.itemAux.email = e.target.value;
          handleEmailError();
        }}
      />

      {vm.item.id === -1 && (
        <React.Fragment>
          <TextField
            id="useFormPassword"
            label={vm.translate("user.password")}
            variant={"outlined"}
            classTextField={classes.user_textField}
            type={"password"}
            value={vm.item.password}
            onChange={(e: any) => {
              vm.itemAux.password = e.target.value;
              handlePasswordError();
            }}
          />
          <TextField
            id="useFormRepeatPassword"
            label={vm.translate("user.repeatPassword")}
            error={passwordError}
            errorText={vm.translate("user.password.error")}
            variant={"outlined"}
            type={"password"}
            classTextField={classes.user_textField}
            value={confirmPassword}
            onChange={(e: any) => {
              setConfirmPassword(e.target.value);
            }}
          />
        </React.Fragment>
      )}
      <Grid item xs={12} md={12} className={classes.user_gridSwitch}>
        <Switch
          classSwitch={classes.user_switch}
          checked={vm.item.active}
          onCheckedChanged={(checked: boolean) => (vm.itemAux.active = checked)}
          color={Colors.primary}
          onLabel={vm.translate("user.active")}
          offLabel={vm.translate("user.active")}
        />
      </Grid>
      <br />
      <Grid item xs={12} md={12} className={classes.user_gridButton}>
        <Button
          id="userFormConfirmButton"
          text={vm.translate("save")}
          onClick={save}
          textColor={Colors.white}
          backgroundColor={Colors.buttonPrimary}
          hoverColor={Colors.buttonPrimary}
        />
      </Grid>
    </Grid>
  );
});
