import { createStyles, makeStyles } from "@mui/styles";
import { Colors } from "../../constants";

export const LoginStyles = makeStyles(() =>
  createStyles({
    login_width: {
      width: "390px !important",
    },
    customheaderbig: {
      width: "500px !important",
    },
    login_typographyGrid: {
      textAlign: "left",
      marginTop: 20,
    },
    login_logo: {
      position: "absolute",
      marginTop: "-85px !important",
    },
    login_paper: {
      paddingTop: "37px",
      paddingBottom: "20px",
    },
    landing_paper: {
      width: "70vh",
      padding: "37px",
    },
    login_grid: {
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      display: "flex",
    },
    login_container: {
      position: "fixed",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      background: Colors.white,
    },
    launch_container: {
      position: "fixed",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      //backgroundColor: '#45a3a8',
      //backgroundColor: Colors.primary
      backgroundColor: "white", //"#F0F8FF"
    },
    launch_loginButton: {
      backgroundColor: "#ebfbff",
      color: "black",
      minWidth: 115,
    },
    launch_registryButton: {
      //backgroundColor: '#1a5c82',
      backgroundColor: Colors.buttonSecondary,
      minWidth: 115,
      color: Colors.white,
    },
    login_subText: {
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      marginTop: 5,
      //@ts-ignore
      textAlign: "left !important",
    },
    login_gridForm2: {
      height: 150,
      justifyContent: "center",
      flexDirection: "column",
      display: "flex",
      marginRight: 5,
      alignItems: "center",
      backgroundColor: Colors.white,
      border: "1px solid " + Colors.hr,
      borderRightColor: Colors.hr,
      boxShadow: "1px 1px 6px 1px rgb(0 0 0 / 21%);",
    },

    login_displayGrid: {
      display: "grid",
      marginTop: 5,
    },

    login_generalDataGrid: {
      display: "flex",
      justifyContent: "flex-start",
    },

    login_gridClose: {
      height: 150,
      justifyContent: "center",
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
      backgroundColor: Colors.white,
      boxShadow: "1px 1px 6px 1px rgb(0 0 0 / 21%);",
    },
    login_paperUser: {
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      height: 280,
      width: 300,
      display: "flex",
      backgroundColor: Colors.white,
      marginRight: 40,
      // boxShadow: "1px 1px 6px 1px rgb(0 0 0 / 31%);"
    },
    login_paperProfileUser: {
      backgroundColor: "white",
      //boxShadow: "1px 1px 6px 1px rgb(0 0 0 / 31%);"
    },

    login_button: {
      marginTop: 16,
    },
    login_header: {
      padding: 15,
      paddingBottom: 0,
    },
    login_gridForm: {
      display: "flex",
      textAlign: "center",
    },
    login_gridItemRight: {
      textAlign: "end",
      marginRight: 20,
    },
    login_gridItemLeft: {
      textAlign: "start",
      //marginLeft: 20
    },
    login_information: {
      display: "flex",
      flexDirection: "column",
      textAlign: "justify",
    },
    login_buttonSave: {
      padding: "0px 0px 10px 0px",
    },
    forgotAndRememberGrid: {
      justifyContent: "space-between",
      alignItems: "center",
      alignContent: "center",
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    paper: {
      marginTop: 5,
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
  })
);
