import { IMapApi } from "../imap-api"
import { Api } from "../../../infrastructure/data/api-client"
import { BaseRepository } from "../../../infrastructure/data/repositories/base-repository"

export default class MapRepository extends BaseRepository<IMapApi>{
  constructor() {
    super(Api.MapApi, false)
  }

  getMarkers = async () => {
    const client = await this.apiClient
    return await client.getMarkers()
  }
}