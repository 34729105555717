import { ConductorOneChildActive, Router, ScreenBase } from "@frui.ts/screens"
import RootViewModel from "../root-view-model";

@Router.registerRoute({ name: Router.Self, route: "maintenance" })
export default class MaintenanceViewModel extends ConductorOneChildActive<ScreenBase> {
    constructor() {
        super();
        this.navigationName = "maintenance"
        this.nameValue = "section.maintenance"
    }

    async onActivate() {
        super.onActivate();
    }

    parent: RootViewModel = this.parent;

}