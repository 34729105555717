import {Button} from "@movicoders/movicoders-components";
import {TypographyTheme} from "@movicoders/movicoders-components";
import { Grid, Paper } from "@mui/material";
import React from "react";
import { Colors } from "../../../../../../../constants";
import { Network } from "../../../../../../../infrastructure/network";
import { getBookMock } from "../../../../../../../viewmodels/profile/data";
import ReportViewModel from "../../../../../../../viewmodels/report/report-view-model";

export const PaymentButton: React.FC<{ vm: ReportViewModel }> = ({ vm }) => {
  const [needPayment, setNeedPayment] = React.useState(false);

  React.useEffect(() => {
    if (vm.cecaSignatureConfiguration !== null) {
      setNeedPayment(true);
    }
  }, [vm.cecaSignatureConfiguration]);

  const freeBook = async () => {
    vm.activeTab = "block0";
    vm.openPaymentForm = false;
    vm.parent.editingBook = false;
    vm.parent.tryActivateChild(vm);
    vm.parent.navigate("report", {});
    vm.parent.selectedBook = getBookMock();
  };

  const paydBook = () => {
    const submit = document.getElementById("cecaForm") as HTMLFormElement;
    submit.submit();
  };

  return (
    <Grid container>
      <form id="cecaForm" action={Network.CECA_GATEWAY} method="POST" encType="application/x-www-form-urlencoded">
        <input name="MerchantID" type="hidden" value={vm.cecaSignatureConfiguration?.merchantID} />
        <input name="AcquirerBIN" type="hidden" value={vm.cecaSignatureConfiguration?.acquirerBIN} />
        <input name="TerminalID" type="hidden" value={vm.cecaSignatureConfiguration?.terminalID} />
        <input name="URL_OK" type="hidden" value={vm.cecaSignatureConfiguration?.urlOK} />
        <input name="URL_NOK" type="hidden" value={vm.cecaSignatureConfiguration?.urlNOK} />
        <input name="Firma" type="hidden" value={vm.cecaSignatureConfiguration?.signature} />
        <input name="Cifrado" type="hidden" value={vm.cecaSignatureConfiguration?.cifrado} />
        <input name="Num_operacion" type="hidden" value={vm.cecaSignatureConfiguration?.numOperacion} />
        <input name="Importe" type="hidden" value={vm.cecaSignatureConfiguration?.importe} />
        <input name="TipoMoneda" type="hidden" value={vm.cecaSignatureConfiguration?.tipoMoneda} />
        <input name="Exponente" type="hidden" value={vm.cecaSignatureConfiguration?.exponente} />
        <input name="Pago_soportado" type="hidden" value="SSL" />
        <input type="hidden" name="Descripcion" value={vm.cecaSignatureConfiguration?.descripcion} />
        <input name="Idioma" type="hidden" value="1" />
      </form>

      {needPayment ? (
        <Grid container display="grid" justifyContent="start" alignItems="start" style={{ marginBottom: "20px", maxWidth: 450 }}>
          <br />
          <TypographyTheme variant="h5" color={Colors.primary} text={vm.translate("payment.button.buying.book")} />
          <div style={{ height: 10, width: 1 }} />
          <TypographyTheme variant="body1" text= {vm.translate("payment.button.buying.book.generate.reference")} />

          <Paper elevation={3} style={{ display: "grid", paddingTop: "5px" }}>
            <div style={{ padding: "10px", display: "grid" }}>
              <Grid display="inline-flex">
                <TypographyTheme variant="h6" text={vm.translate("payment.button.book.create.access")}/>
              </Grid>
              <Grid display="inline-flex">
                <TypographyTheme variant="h6" text={`${vm.translate("report.block0.buildingIdentification.paymentButton.amount")} ${String(Number(vm.cecaSignatureConfiguration!.importe! ?? 0) / 100 ?? "0")}€ ${vm.translate("vat.included")}`} />
              </Grid>
            </div>
          </Paper>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <TypographyTheme variant="body1" color={Colors.primary} text={vm.translate("payment.dialog.book.ready")} />
        </Grid>
      )}
      {!needPayment && <div style={{ height: 140 }} />}
      <Button
        variant="contained"
        style={{ height: "2.5rem" }}
        onClick={() => {
          vm.openPaymentForm = false;
        }}
        text={vm.translate("cancel")}
      />
      {needPayment ? (
        <Button style={{ marginLeft: "10px", height: "2.5rem" }} variant="contained" onClick={paydBook} text={vm.translate("payment.button.book.buy")} />
      ) : (
        <Button style={{ marginLeft: "10px", height: "2.5rem" }} variant="contained" onClick={freeBook} text={vm.translate("profile.createBook")} />
      )}
    </Grid>
  );
};
export const CECA_CONSTANTS = {
  CECA_PAGO_SOPORTADO: "SSL",
  CECA_TIPO_MONEDA: "978",
  CECA_CIFRADO: "SHA2",
  CECA_EXPONENTE: "2",
  CECA_URL_ACTION_URL_PROD: "https://pgw.ceca.es/tpvweb/tpv/compra.action ", //parametrized from .env
  CECA_URL_ACTION_URL_DEV: "https://tpv.ceca.es/tpvweb/tpv/compra.action ", //parametrized from .env
};
