/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InnerElementsDTO
 */
export interface InnerElementsDTO {
    /**
     * 
     * @type {number}
     * @memberof InnerElementsDTO
     */
    falseCeilings?: number;
    /**
     * 
     * @type {number}
     * @memberof InnerElementsDTO
     */
    falseCeilingsSurface?: number;
    /**
     * 
     * @type {string}
     * @memberof InnerElementsDTO
     */
    notes?: string;
    /**
     * 
     * @type {number}
     * @memberof InnerElementsDTO
     */
    partition?: number;
    /**
     * 
     * @type {string}
     * @memberof InnerElementsDTO
     */
    partitionUnknownOthersValue?: string;
    /**
     * 
     * @type {number}
     * @memberof InnerElementsDTO
     */
    partitionWalls?: number;
    /**
     * 
     * @type {string}
     * @memberof InnerElementsDTO
     */
    partitionWallsUnknownOthersValue?: string;
    /**
     * 
     * @type {number}
     * @memberof InnerElementsDTO
     */
    pavements?: number;
    /**
     * 
     * @type {number}
     * @memberof InnerElementsDTO
     */
    pavementsSurface?: number;
    /**
     * 
     * @type {string}
     * @memberof InnerElementsDTO
     */
    pavementsUnknownOthersValue?: string;
    /**
     * 
     * @type {number}
     * @memberof InnerElementsDTO
     */
    surface?: number;
}

export function InnerElementsDTOFromJSON(json: any): InnerElementsDTO {
    return InnerElementsDTOFromJSONTyped(json, false);
}

export function InnerElementsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InnerElementsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'falseCeilings': !exists(json, 'falseCeilings') ? undefined : json['falseCeilings'],
        'falseCeilingsSurface': !exists(json, 'falseCeilingsSurface') ? undefined : json['falseCeilingsSurface'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'partition': !exists(json, 'partition') ? undefined : json['partition'],
        'partitionUnknownOthersValue': !exists(json, 'partitionUnknownOthersValue') ? undefined : json['partitionUnknownOthersValue'],
        'partitionWalls': !exists(json, 'partitionWalls') ? undefined : json['partitionWalls'],
        'partitionWallsUnknownOthersValue': !exists(json, 'partitionWallsUnknownOthersValue') ? undefined : json['partitionWallsUnknownOthersValue'],
        'pavements': !exists(json, 'pavements') ? undefined : json['pavements'],
        'pavementsSurface': !exists(json, 'pavementsSurface') ? undefined : json['pavementsSurface'],
        'pavementsUnknownOthersValue': !exists(json, 'pavementsUnknownOthersValue') ? undefined : json['pavementsUnknownOthersValue'],
        'surface': !exists(json, 'surface') ? undefined : json['surface'],
    };
}

export function InnerElementsDTOToJSON(value?: InnerElementsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'falseCeilings': value.falseCeilings,
        'falseCeilingsSurface': value.falseCeilingsSurface,
        'notes': value.notes,
        'partition': value.partition,
        'partitionUnknownOthersValue': value.partitionUnknownOthersValue,
        'partitionWalls': value.partitionWalls,
        'partitionWallsUnknownOthersValue': value.partitionWallsUnknownOthersValue,
        'pavements': value.pavements,
        'pavementsSurface': value.pavementsSurface,
        'pavementsUnknownOthersValue': value.pavementsUnknownOthersValue,
        'surface': value.surface,
    };
}

