import { Button, CssBaseline, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from "react";
import { Colors } from "../../../../../../constants";
import ReportViewModel from "../../../../../../viewmodels/report/report-view-model";

interface DeleteDialogProps {
  onClose: () => void;
  onDelete: () => void;
  vm: ReportViewModel;
}

export const DeleteDialog: React.FC<DeleteDialogProps> = ({ vm, onClose, onDelete }: DeleteDialogProps) => {
  return (
    <div>
      <CssBaseline/>
      <Dialog open={true} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle style={{ background: Colors.buttonPrimary, color: Colors.white }} id="alert-dialog-title">
          {vm.translate("report.block0.buildingIdentification.deleteDialog.deletingFile")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{vm.translate("delete.dialog.confirm")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} style={{ background: Colors.primary, color: Colors.white }}>
            {vm.translate("delete.dialog.cancel")}
          </Button>
          <Button onClick={onDelete} style={{ background: Colors.primary, color: Colors.white }}>
            {vm.translate("delete.dialog.delete")}
          </Button>
          {/* <Button onClick={onDelete} style={{ background: Colors.primary, color: Colors.white }}>
            {vm.translate("delete.dialog.delete")}
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
};

interface UnbindDialogProps {
  onClose: () => void;
  onDelete: () => void;
  vm: ReportViewModel;
}

export const UnbindAgent: React.FC<UnbindDialogProps> = ({ vm, onClose, onDelete }: UnbindDialogProps) => {
  return (
    <div>
      <Dialog open={true} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle style={{ background: Colors.buttonPrimary, color: Colors.white }} id="alert-dialog-title">
          {vm.translate("report.agents.unbind_agent")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{vm.translate("report.agents.unbind_agent.confirm")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} style={{ background: Colors.primary, color: Colors.white }}>
            {vm.translate("delete.dialog.cancel")}
          </Button>
          <Button onClick={onDelete} style={{ background: Colors.primary, color: Colors.white }} autoFocus>
            {vm.translate("report.agents.unbind_agent.unbind")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
