import { interfaces } from "inversify"
import { action, observable } from "mobx"
import { Router, ScreenBase } from "@frui.ts/screens"
import BuildingViewModel from "./building-view-model"
import i18n from "../../infrastructure/localization/i18n"
import Building from "../../data/entities/building-entity"
import BuildingRepository from "../../data/repositories/impl/building-repository"

@Router.registerRoute({ name: Router.Self, route: "buildings" })
export default class BuildingDetailViewModel extends ScreenBase {
  @observable itemAux: Building

  constructor(public item: Building, public buildingRepository: BuildingRepository, public parent: BuildingViewModel) {
    super();
    this.item = item;
    this.itemAux = Object.assign({}, this.item)
    this.navigationName = item.name.toString() === "-1" ? "new" : item.name.toString();
  }

  async onActivate() {
    super.onActivate()
    if (this.parent.loading) this.parent.loadData()
  }

  @action.bound async create() { }

  @action.bound async update() { }

  @action.bound async delete() {
    /*const response = await this.buildingRepository.delete(this.item);
    if (response) {
      this.parent.items.splice(this.parent.items.indexOf(this.item), 1);
    }*/
  }

  @action.bound setItem = (item: Building) => { }

  translate(text: string) {
    return i18n.t(text);
  }

  static Factory({ container }: interfaces.Context) {
    return (item: Building) =>
      new BuildingDetailViewModel(item, container.get(BuildingRepository), container.get(BuildingViewModel));
  }
}