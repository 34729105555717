import { BuildingPolygonDTO, CadastralBasicPropertyDTO, MediaDTO, ParcelDTO, PolygonDTO } from "../../clients/models";

export default class Parcel {
  area: number;
  buildingPolygons: BuildingPolygonDTO[];
  community: string;
  dwellings: number;
  floorsAboveGround: number;
  floorsUnderGround: number;
  id: string;
  latitude: number;
  longitude: number;
  media: MediaDTO[];
  name: string;
  parcelPolygons: PolygonDTO[];
  pc1: string;
  pc2: string;
  portalNumber: string;
  portalNumberLetter: string;
  postalCode: number;
  properties: CadastralBasicPropertyDTO[];
  province: string;
  secondaryPortalLetter: string;
  secondaryPortalNumber: string;
  town: string;
  type: string;
  year: number;

  constructor(
    area: number,
    buildingPolygons: BuildingPolygonDTO[],
    community: string,
    dwellings: number,
    floorsAboveGround: number,
    floorsUnderGround: number,
    id: string,
    latitude: number,
    longitude: number,
    media: MediaDTO[],
    name: string,
    parcelPolygons: PolygonDTO[],
    pc1: string,
    pc2: string,
    portalNumber: string,
    portalNumberLetter: string,
    postalCode: number,
    properties: CadastralBasicPropertyDTO[],
    province: string,
    secondaryPortalLetter: string,
    secondaryPortalNumber: string,
    town: string,
    type: string,
    year: number
  ) {
    this.area = area;
    this.buildingPolygons = buildingPolygons;
    this.community = community;
    this.dwellings = dwellings;
    this.floorsAboveGround = floorsAboveGround;
    this.floorsUnderGround = floorsUnderGround;
    this.id = id;
    this.latitude = latitude;
    this.longitude = longitude;
    this.media = media;
    this.name = name;
    this.parcelPolygons = parcelPolygons;
    this.pc1 = pc1;
    this.pc2 = pc2;
    this.portalNumber = portalNumber;
    this.portalNumberLetter = portalNumberLetter;
    this.id = id;
    this.postalCode = postalCode;
    this.properties = properties;
    this.province = province;
    this.secondaryPortalLetter = secondaryPortalLetter;
    this.secondaryPortalNumber = secondaryPortalNumber;
    this.town = town;
    this.type = type;
    this.year = year;
  }

  static fromClientParcel(parcel: ParcelDTO) {
    const result = new Parcel(
      parcel.area!!,
      parcel.buildingPolygons!!,
      parcel.community!!,
      parcel.dwellings!!,
      parcel.floorsAboveGround!!,
      parcel.floorsUnderGround!!,
      parcel.id!!,
      parcel.latitude!!,
      parcel.longitude!!,
      parcel.media!!,
      parcel.streetName!!,
      parcel.polygons!!,
      parcel.pc1!!,
      parcel.pc2!!,
      parcel.portalNumber!!,
      parcel.portalNumberLetter!!,
      parcel.postalCode!!,
      parcel.properties!!,
      parcel.province!!,
      parcel.secondaryPortalLetter!!,
      parcel.secondaryPortalNumber!!,
      parcel.town!!,
      parcel.streetType!!,
      parcel.year!!
    );
    return result;
  }

  static toClientParcel(parcel: Parcel) {
    let result: ParcelDTO = {};
    result.area = parcel.area;
    result.buildingPolygons = parcel.buildingPolygons;
    result.community = parcel.community;
    result.dwellings = parcel.dwellings;
    result.floorsAboveGround = parcel.floorsAboveGround;
    result.floorsUnderGround = parcel.floorsUnderGround;
    result.id = parcel.id;
    result.latitude = parcel.latitude;
    result.longitude = parcel.longitude;
    result.media = parcel.media;
    result.streetName = parcel.name;
    result.polygons = parcel.parcelPolygons;
    result.pc1 = parcel.pc1;
    result.pc2 = parcel.pc2;
    result.portalNumber = parcel.portalNumber;
    result.portalNumberLetter = parcel.portalNumberLetter;
    result.postalCode = parcel.postalCode;
    result.properties = parcel.properties;
    result.province = parcel.province;
    result.secondaryPortalLetter = parcel.secondaryPortalLetter;
    result.secondaryPortalNumber = parcel.secondaryPortalNumber;
    result.town = parcel.town;
    result.streetType = parcel.type;
    result.year = parcel.year;

    return result;
  }
}
