import { createStyles, makeStyles } from '@mui/styles'

export const BuildingsStyle = makeStyles(() => createStyles({
  building_textField: {
    marginBottom: 10,
    width: "100%"
  },
  building_gridButton: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%"
  },
  building_gridSwitch: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%"
  },
  building_switch: {
    '& .MuiIconButton-label': {
      marginTop: -10
    }
  }
}))