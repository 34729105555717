/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BuildingDTO,
    BuildingDTOFromJSON,
    BuildingDTOToJSON,
    IncompletePropertiesDTOInManager,
    IncompletePropertiesDTOInManagerFromJSON,
    IncompletePropertiesDTOInManagerToJSON,
    MovicodersBadRequestException,
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundException,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceException,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
    ParcelDTO,
    ParcelDTOFromJSON,
    ParcelDTOToJSON,
    PropertyDTO,
    PropertyDTOFromJSON,
    PropertyDTOToJSON,
} from '../models';

export interface GetBuildingInfoByRealEstateIdsRequest {
    requestBody?: Array<string>;
}

export interface GetIncompletePropertiesIdsFromRequest {
    requestBody?: Array<string>;
}

export interface GetParcelByAddressRequest {
    province: string;
    town: string;
    street: string;
    number: string;
    block?: string;
    stair?: string;
    floor?: string;
    door?: string;
}

export interface GetParcelForCadastralReferenceRequest {
    reference: string;
}

export interface GetPropertyByIdRequest {
    id: string;
}

export interface GetPropertyByProvinceAndTownAndCadastralReferenceRequest {
    province: string;
    town: string;
    reference: string;
}

export interface RefreshParcelForCadastralReferenceRequest {
    reference: string;
}

/**
 * 
 */
export class CadastralInfoApi extends runtime.BaseAPI {

    /**
     * Takes a list of uuids and returns a building with the full property information.
     * Conform a new building.
     */
    async getBuildingInfoByRealEstateIdsRaw(requestParameters: GetBuildingInfoByRealEstateIdsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BuildingDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/buildings`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BuildingDTOFromJSON(jsonValue));
    }

    /**
     * Takes a list of uuids and returns a building with the full property information.
     * Conform a new building.
     */
    async getBuildingInfoByRealEstateIds(requestParameters: GetBuildingInfoByRealEstateIdsRequest, initOverrides?: RequestInit): Promise<BuildingDTO> {
        const response = await this.getBuildingInfoByRealEstateIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes a list of uuids and returns the incomplete ones.
     * Verify incomplete properties for a new building.
     */
    async getIncompletePropertiesIdsFromRaw(requestParameters: GetIncompletePropertiesIdsFromRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IncompletePropertiesDTOInManager>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/buildings/verify`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncompletePropertiesDTOInManagerFromJSON(jsonValue));
    }

    /**
     * Takes a list of uuids and returns the incomplete ones.
     * Verify incomplete properties for a new building.
     */
    async getIncompletePropertiesIdsFrom(requestParameters: GetIncompletePropertiesIdsFromRequest, initOverrides?: RequestInit): Promise<IncompletePropertiesDTOInManager> {
        const response = await this.getIncompletePropertiesIdsFromRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get parcel address
     * Get parcel by address
     */
    async getParcelByAddressRaw(requestParameters: GetParcelByAddressRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ParcelDTO>> {
        if (requestParameters.province === null || requestParameters.province === undefined) {
            throw new runtime.RequiredError('province','Required parameter requestParameters.province was null or undefined when calling getParcelByAddress.');
        }

        if (requestParameters.town === null || requestParameters.town === undefined) {
            throw new runtime.RequiredError('town','Required parameter requestParameters.town was null or undefined when calling getParcelByAddress.');
        }

        if (requestParameters.street === null || requestParameters.street === undefined) {
            throw new runtime.RequiredError('street','Required parameter requestParameters.street was null or undefined when calling getParcelByAddress.');
        }

        if (requestParameters.number === null || requestParameters.number === undefined) {
            throw new runtime.RequiredError('number','Required parameter requestParameters.number was null or undefined when calling getParcelByAddress.');
        }

        const queryParameters: any = {};

        if (requestParameters.province !== undefined) {
            queryParameters['province'] = requestParameters.province;
        }

        if (requestParameters.town !== undefined) {
            queryParameters['town'] = requestParameters.town;
        }

        if (requestParameters.street !== undefined) {
            queryParameters['street'] = requestParameters.street;
        }

        if (requestParameters.number !== undefined) {
            queryParameters['number'] = requestParameters.number;
        }

        if (requestParameters.block !== undefined) {
            queryParameters['block'] = requestParameters.block;
        }

        if (requestParameters.stair !== undefined) {
            queryParameters['stair'] = requestParameters.stair;
        }

        if (requestParameters.floor !== undefined) {
            queryParameters['floor'] = requestParameters.floor;
        }

        if (requestParameters.door !== undefined) {
            queryParameters['door'] = requestParameters.door;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/parcels/address`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParcelDTOFromJSON(jsonValue));
    }

    /**
     * Get parcel address
     * Get parcel by address
     */
    async getParcelByAddress(requestParameters: GetParcelByAddressRequest, initOverrides?: RequestInit): Promise<ParcelDTO> {
        const response = await this.getParcelByAddressRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a parcel info by cadastral reference
     * Get parcel by reference
     */
    async getParcelForCadastralReferenceRaw(requestParameters: GetParcelForCadastralReferenceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ParcelDTO>> {
        if (requestParameters.reference === null || requestParameters.reference === undefined) {
            throw new runtime.RequiredError('reference','Required parameter requestParameters.reference was null or undefined when calling getParcelForCadastralReference.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/parcels/{reference}`.replace(`{${"reference"}}`, encodeURIComponent(String(requestParameters.reference))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParcelDTOFromJSON(jsonValue));
    }

    /**
     * Returns a parcel info by cadastral reference
     * Get parcel by reference
     */
    async getParcelForCadastralReference(requestParameters: GetParcelForCadastralReferenceRequest, initOverrides?: RequestInit): Promise<ParcelDTO> {
        const response = await this.getParcelForCadastralReferenceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a property info by cadastral reference id
     * Get property by id
     */
    async getPropertyByIdRaw(requestParameters: GetPropertyByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PropertyDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPropertyById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/properties/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PropertyDTOFromJSON(jsonValue));
    }

    /**
     * Returns a property info by cadastral reference id
     * Get property by id
     */
    async getPropertyById(requestParameters: GetPropertyByIdRequest, initOverrides?: RequestInit): Promise<PropertyDTO> {
        const response = await this.getPropertyByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a property info by province, town and cadastral reference
     * Get property by reference
     */
    async getPropertyByProvinceAndTownAndCadastralReferenceRaw(requestParameters: GetPropertyByProvinceAndTownAndCadastralReferenceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PropertyDTO>> {
        if (requestParameters.province === null || requestParameters.province === undefined) {
            throw new runtime.RequiredError('province','Required parameter requestParameters.province was null or undefined when calling getPropertyByProvinceAndTownAndCadastralReference.');
        }

        if (requestParameters.town === null || requestParameters.town === undefined) {
            throw new runtime.RequiredError('town','Required parameter requestParameters.town was null or undefined when calling getPropertyByProvinceAndTownAndCadastralReference.');
        }

        if (requestParameters.reference === null || requestParameters.reference === undefined) {
            throw new runtime.RequiredError('reference','Required parameter requestParameters.reference was null or undefined when calling getPropertyByProvinceAndTownAndCadastralReference.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/properties/{province}/town/{town}/reference/{reference}`.replace(`{${"province"}}`, encodeURIComponent(String(requestParameters.province))).replace(`{${"town"}}`, encodeURIComponent(String(requestParameters.town))).replace(`{${"reference"}}`, encodeURIComponent(String(requestParameters.reference))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PropertyDTOFromJSON(jsonValue));
    }

    /**
     * Returns a property info by province, town and cadastral reference
     * Get property by reference
     */
    async getPropertyByProvinceAndTownAndCadastralReference(requestParameters: GetPropertyByProvinceAndTownAndCadastralReferenceRequest, initOverrides?: RequestInit): Promise<PropertyDTO> {
        const response = await this.getPropertyByProvinceAndTownAndCadastralReferenceRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Refresh a parcel info by cadastral reference
     * Refresh parcel by reference
     */
    async refreshParcelForCadastralReferenceRaw(requestParameters: RefreshParcelForCadastralReferenceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ParcelDTO>> {
        if (requestParameters.reference === null || requestParameters.reference === undefined) {
            throw new runtime.RequiredError('reference','Required parameter requestParameters.reference was null or undefined when calling refreshParcelForCadastralReference.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/parcels/refresh/{reference}`.replace(`{${"reference"}}`, encodeURIComponent(String(requestParameters.reference))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ParcelDTOFromJSON(jsonValue));
    }

    /**
     * Refresh a parcel info by cadastral reference
     * Refresh parcel by reference
     */
    async refreshParcelForCadastralReference(requestParameters: RefreshParcelForCadastralReferenceRequest, initOverrides?: RequestInit): Promise<ParcelDTO> {
        const response = await this.refreshParcelForCadastralReferenceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
