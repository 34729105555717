/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentDTO
 */
export interface PaymentDTO {
    /**
     * 
     * @type {string}
     * @memberof PaymentDTO
     */
    amount?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentDTO
     */
    created?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentDTO
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDTO
     */
    platform?: PaymentDTOPlatformEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentDTO
     */
    status?: PaymentDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentDTO
     */
    transactionId?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDTO
     */
    userId?: string;
}

/**
* @export
* @enum {string}
*/
export enum PaymentDTOPlatformEnum {
    Ceca = 'CECA'
}/**
* @export
* @enum {string}
*/
export enum PaymentDTOStatusEnum {
    Accepted = 'ACCEPTED',
    Rejected = 'REJECTED',
    Requested = 'REQUESTED',
    Used = 'USED'
}

export function PaymentDTOFromJSON(json: any): PaymentDTO {
    return PaymentDTOFromJSONTyped(json, false);
}

export function PaymentDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'platform': !exists(json, 'platform') ? undefined : json['platform'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'transactionId': !exists(json, 'transactionId') ? undefined : json['transactionId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function PaymentDTOToJSON(value?: PaymentDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'created': value.created,
        'currency': value.currency,
        'id': value.id,
        'platform': value.platform,
        'status': value.status,
        'transactionId': value.transactionId,
        'userId': value.userId,
    };
}

