import React, { useState } from "react";
import { FileUploader } from "@movicoders/movicoders-components";
import { Grid, Button, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import ReportViewModel from "../../../../../../viewmodels/report/report-view-model";
import { ReportStyle } from "../../../../report-style";
import AddIcon from "@mui/icons-material/Add";
import { MultimediaDTO } from "../../../../../../clients/models";
import { Colors } from "../../../../../../constants";
import { useSnackbar } from "notistack";

export const UploadImagesDialog: React.FC<{
  vm: ReportViewModel;
  buttonText: string;
  mRight?: string;
  collect: (m: MultimediaDTO[] | undefined) => void;
}> = ({ vm, buttonText, mRight, collect }) => {
  const classes = ReportStyle();
  const [files, setFiles] = useState<File[] | undefined>();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleCloseDialog = () => {
    setFiles(undefined);
    setIsDialogOpen(false);
  };

  const { enqueueSnackbar } = useSnackbar();
  const handleOpenSnackBar = (snackKey: string, variants: undefined | "default" | "error" | "success" | "warning" | "info") => {
    enqueueSnackbar(snackKey, { variant: variants, preventDuplicate: true, key: snackKey, autoHideDuration: 3000 });
  };

  const handleFileUpload = async () => {
    if (files) {
      collect(await Promise.all(files.map(vm.createMediaWithoutApi)));
    }
    setFiles(undefined);
    setIsDialogOpen(false);
  };

  const handleOpenDialog = () => setIsDialogOpen(true);

  return (
    <>
      {isDialogOpen ? (
        <Dialog open={true}>
          <DialogTitle>{buttonText}</DialogTitle>
          <DialogContent>
            <Typography fontSize={11}>{vm.translate("file.size.recommendation")}</Typography>
            <FileUploader
              dropzoneText={vm.translate("report.block1.license.file.helper")}
              maxFileSize={500000}
              acceptedFiles={["image/*"]}
              maxFiles={20}
              onChange={(files: File[]) =>
                files.filter((file: File) => file !== undefined && file.name.includes("+" || "%")).length === 0
                  ? files && setFiles(files)
                  : handleOpenSnackBar(vm.translate("uploadNameError"), "error")
              }
              classNameDropzone={classes.report_dropZone}
              classNameParagraphDropzone={classes.report_dropZone}
              getDropRejectMessage={() => vm.translate("file.size.error")}
              showPreviews={false}
              getFileAddedMessage={(filename: string) => `${filename} ${vm.translate("report.block1.license.file.added")}`}
              getFileRemovedMessage={(filename: string) => `${filename} ${vm.translate("report.block1.license.file.removed")}`}
            />
            <Grid item xs={12} style={{ marginTop: ".5rem" }}>
              <Button id={"close-filedialog"} onClick={handleCloseDialog} style={{ marginRight: ".5rem", background: Colors.primary, color: Colors.white }}>
                Cerrar
              </Button>
              <Button id={"close-filedialog"} onClick={handleFileUpload} style={{ background: Colors.primary, color: Colors.white }}>
                Guardar
              </Button>
            </Grid>
          </DialogContent>
        </Dialog>
      ) : null}
      <Button
        id="anadirplano"
        variant="outlined"
        style={{ maxWidth: 250, marginRight: mRight ? mRight : 0 }}
        startIcon={<AddIcon />}
        onClick={handleOpenDialog}
      >
        {buttonText}
      </Button>
    </>
  );
};
