/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserDataPrintDTO
 */
export interface UserDataPrintDTO {
    /**
     * 
     * @type {string}
     * @memberof UserDataPrintDTO
     */
    block?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataPrintDTO
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataPrintDTO
     */
    collegeName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataPrintDTO
     */
    collegeNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataPrintDTO
     */
    door?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataPrintDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataPrintDTO
     */
    floor?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataPrintDTO
     */
    mobile?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataPrintDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataPrintDTO
     */
    nif?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataPrintDTO
     */
    number?: string;
    /**
     * 
     * @type {number}
     * @memberof UserDataPrintDTO
     */
    participation?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDataPrintDTO
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataPrintDTO
     */
    province?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataPrintDTO
     */
    roleEnum?: UserDataPrintDTORoleEnumEnum;
    /**
     * 
     * @type {string}
     * @memberof UserDataPrintDTO
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataPrintDTO
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataPrintDTO
     */
    technicianType?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataPrintDTO
     */
    telephone?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataPrintDTO
     */
    type?: string;
}

/**
* @export
* @enum {string}
*/
export enum UserDataPrintDTORoleEnumEnum {
    HomeownersPresident = 'HOMEOWNERS_PRESIDENT',
    Owner = 'OWNER',
    PropertyManager = 'PROPERTY_MANAGER',
    Representative = 'REPRESENTATIVE',
    Technician = 'TECHNICIAN'
}

export function UserDataPrintDTOFromJSON(json: any): UserDataPrintDTO {
    return UserDataPrintDTOFromJSONTyped(json, false);
}

export function UserDataPrintDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDataPrintDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'block': !exists(json, 'block') ? undefined : json['block'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'collegeName': !exists(json, 'collegeName') ? undefined : json['collegeName'],
        'collegeNumber': !exists(json, 'collegeNumber') ? undefined : json['collegeNumber'],
        'door': !exists(json, 'door') ? undefined : json['door'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'floor': !exists(json, 'floor') ? undefined : json['floor'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'nif': !exists(json, 'nif') ? undefined : json['nif'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'participation': !exists(json, 'participation') ? undefined : json['participation'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'province': !exists(json, 'province') ? undefined : json['province'],
        'roleEnum': !exists(json, 'roleEnum') ? undefined : json['roleEnum'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'technicianType': !exists(json, 'technicianType') ? undefined : json['technicianType'],
        'telephone': !exists(json, 'telephone') ? undefined : json['telephone'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function UserDataPrintDTOToJSON(value?: UserDataPrintDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'block': value.block,
        'city': value.city,
        'collegeName': value.collegeName,
        'collegeNumber': value.collegeNumber,
        'door': value.door,
        'email': value.email,
        'floor': value.floor,
        'mobile': value.mobile,
        'name': value.name,
        'nif': value.nif,
        'number': value.number,
        'participation': value.participation,
        'postalCode': value.postalCode,
        'province': value.province,
        'roleEnum': value.roleEnum,
        'street': value.street,
        'surname': value.surname,
        'technicianType': value.technicianType,
        'telephone': value.telephone,
        'type': value.type,
    };
}

