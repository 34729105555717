/* eslint-disable react-hooks/exhaustive-deps */
import { Icon, IconButton, IconsList, TypographyTheme } from "@movicoders/movicoders-components";
import { Button, Divider, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BuildingCompositionDTOTypologyEnum, PropertyDTO, UsageDTO } from "../../../../../../../clients/models";
import RadioBoxGroup from "../../../../../../../components/radio-group/radio-group";
import TextFieldGrid from "../../../../../../../components/text-fields/textfield-grid";
import TypographyTitle from "../../../../../../../components/typography-title";
import { Colors, Communities } from "../../../../../../../constants";
import { groupBy } from "../../../../../../../utils/book-helper";
import { usageMock } from "../../../../../../../viewmodels/profile/data";
import ReportViewModel from "../../../../../../../viewmodels/report/report-view-model";
import { ReportStyle } from "../../../../../report-style";
import { BuildingDialog } from "./dialog-building-data";
import { EditUsages } from "./dialog-edit-usages";
import { UsagesEditWarningDialog } from "./edit-usages-warning-dialog";
import { UsageDialog } from "./manual-usage-dialog";
import { TypographyTitleDoubleExtended } from "./typography-title-double-extended";

export const GeneralBuildingData: React.FC<{ vm: ReportViewModel }> = observer(({ vm }) => {
  const classes = ReportStyle();
  const [newUsages, setNewUsages] = useState<PropertyDTO[]>(vm.userBook.cadastralInfo!.properties ?? ([] as PropertyDTO[]));
  const [currentPage, setCurrentPage] = useState<UsageDTO[]>([]);
  const pageSize = 12;
  const { t } = useTranslation();
  const [paginationFooter, setPaginationFooter] = useState<{
    totalReferences: number;
    totalElements: number;
    totalPages: number;
    currentPageNumber: number;
  }>({ totalReferences: 0, totalElements: 0, totalPages: 0, currentPageNumber: 0 });
  // eslint-disable-next-line
  const [showNumerics, setShowNumerics] = useState(false);
  const [updateUsagesState, setUpdateUsagesState] = useState(true);

  const formatPropertyAddress = (property?: UsageDTO) => {
    return `${property?.block !== undefined ? vm.translate("report.block0.buildingIdentification.generalBuildingData.BL") + property.block + ", " : ""}${
      property?.stair !== undefined ? vm.translate("report.block0.buildingIdentification.generalBuildingData.ES") + property.stair + ", " : ""
    }${property?.floor !== undefined ? vm.translate("report.block0.buildingIdentification.generalBuildingData.floor") + property.floor + ", " : ""} ${
      property?.door !== undefined ? vm.translate("report.block0.buildingIdentification.generalBuildingData.door") + property.door : ""
    }`;
  };

  useEffect(() => {
    if (vm.userBook?.id && updateUsagesState) {
      fillUsagesPaged();
      getBuildingPolygons();
      setUpdateUsagesState(false);
    }
  }, [vm.userBook.buildingComposition?.usages]);

  const fillUsagesPaged = async () => {
    await vm
      .getUsagePage({ id: vm.userBook.id!, offset: 0, limit: pageSize })
      .then((res) => {
        setCurrentPage(res.content ?? []);
        setPaginationFooter({
          totalReferences: res.totalReferences ?? 0,
          totalElements: res.totalElements ?? 0,
          totalPages: res.totalPages ?? 1,
          currentPageNumber: 0,
        });
        if (res.content) {
          vm.buildingComposition.usages = res.content;
        }
      })
      .catch((err) => console.log(err));
  };

  const getBuildingPolygons = async () => {
    vm.bookRepository.getPolygonsByBookId({ id: vm.userBook.id! }).then((res) => {
      if (vm.userBook?.buildingLocationInfo?.buildingPolygon) vm.userBook.buildingLocationInfo.buildingPolygon = res.buildingPolygon;
    });
  };

  const onChangeBuildingType = (value: boolean) => {
    if (value) {
      vm.buildingComposition.typology = BuildingCompositionDTOTypologyEnum.ClosedBlockBuilding;
    } else {
      vm.buildingComposition.typology = BuildingCompositionDTOTypologyEnum.IsolatedBuilding;
    }
  };

  useEffect(() => {
    setShowNumerics(true);
  }, [vm.buildingComposition.builtOnYear]);

  useEffect(() => {
    if (vm.parent.isManual) setNewUsages([...vm.manualUsages]);
  }, [vm.manualUsages]);

  const editUsage = () => {
    vm.editManualUsage = true;
  };

  const addUsage = () => {
    const newUsage = { ...vm.manualUsage };
    vm.manualUsages = [...newUsages, newUsage];
    vm.buildingComposition.usages = [...newUsages, newUsage];
    vm.manualUsage = usageMock();
  };

  const handlePreviousPage = async () => {
    const prev = paginationFooter.currentPageNumber - 1;
    const currOffset = prev * pageSize;
    if (paginationFooter.currentPageNumber === 0) {
    } else {
      await vm.getUsagePage({ id: vm.userBook.id!, offset: currOffset, limit: pageSize }).then((res) => {
        setCurrentPage(res.content ?? []);
      });
      setPaginationFooter({ ...paginationFooter, currentPageNumber: prev });
    }
  };

  const handleNextPage = async () => {
    const nextPage = paginationFooter.currentPageNumber + 1;
    const currOffset = nextPage * pageSize;
    if (nextPage === paginationFooter.totalPages) return;
    setPaginationFooter({ ...paginationFooter, currentPageNumber: nextPage });
    await vm
      .getUsagePage({ id: vm.userBook.id!, offset: currOffset, limit: pageSize })
      .then((res) => {
        setCurrentPage(res.content ?? []);
      })
      .catch((err) => console.log(err));
  };

  const onClickUsagesButton = () => {
    !vm.isOpenEditUsages && (vm.isWarningEditUsagesOpen = true);
    vm.isOpenEditUsages = false;
    if (!vm.isOpenEditUsages) {
      vm.parcel = undefined;
    }
  };

  return (
    <React.Fragment>
      {vm.selectedBuildingCompositionUsage !== undefined && <BuildingDialog vm={vm} setCurrentPage={setCurrentPage} currentPage={currentPage} />}
      {vm.editManualUsage && <UsageDialog vm={vm} />}
      <Grid item xs={12} md={12} className={classes.report_displayGridTitle}>
        <TypographyTitle id="generalData" marginTop title={vm.translate("report.block0.general.data")} />

        <TextFieldGrid
          id="generalDataSurface"
          gridTextField={{ xs: 6, md: 6 }}
          value={vm.buildingComposition.parcelArea?.toString()}
          numeric
          textFieldClassName={classes.report_numericInputGeneralData_2}
          title={vm.translate("report.block0.surface")}
          onChange={(e: any) => {
            vm.buildingComposition.parcelArea = e.target.value;
          }}
        />

        <TextFieldGrid
          id="generalDataSurfaceBuildt"
          gridTextField={{ xs: 6, md: 6 }}
          value={vm.buildingComposition.builtArea?.toString()}
          numeric
          title={vm.translate("report.block0.surface.buildt")}
          textFieldClassName={classes.report_numericInputGeneralData_2}
          onChange={(e: any) => {
            vm.buildingComposition.builtArea = e.target.value;
          }}
        />

        <TextFieldGrid
          id="generalDataHeight"
          gridTextField={{ xs: 6, md: 6 }}
          value={vm.buildingComposition.heightOverGround?.toString()}
          numeric
          title={vm.translate("report.block0.height")}
          textFieldClassName={classes.report_numericInputGeneralData_2}
          onChange={(e: any) => {
            vm.buildingComposition.heightOverGround = e.target.value;
          }}
        />

        <TextFieldGrid
          id="generalDatafloorsOverGround"
          gridTextField={{ xs: 6, md: 6 }}
          value={vm.buildingComposition.floorsOverGround?.toString()}
          numeric
          title={vm.translate("report.block0.heights")}
          textFieldClassName={classes.report_numericInputGeneralData_2}
          onChange={(e: any) => {
            vm.buildingComposition.floorsOverGround = e.target.value;
          }}
        />

        <TextFieldGrid
          id="floorsUnderGround"
          gridTextField={{ xs: 6, md: 6 }}
          value={vm.buildingComposition.floorsUnderGround?.toString()}
          numeric
          title={vm.translate("report.block0.heights.under")}
          textFieldClassName={classes.report_numericInputGeneralData_2}
          onChange={(e: any) => {
            vm.buildingComposition.floorsUnderGround = e.target.value;
          }}
        />

        <Grid item xs={12} md={12} className={classes.report_generalDataGrid}>
          <TextFieldGrid
            id="generalDataElevators"
            gridTextField={{ xs: 6, md: 6 }}
            value={vm.buildingComposition.elevatorsCount?.toString()}
            numeric
            title={vm.translate("report.block0.elevators")}
            textFieldClassName={classes.report_numericInputGeneralData_2}
            onChange={(e: any) => {
              vm.buildingComposition.elevatorsCount = e.target.value;
            }}
          />

          <TextFieldGrid
            id="generalDataStairs"
            gridTextField={{ xs: 6, md: 6 }}
            value={vm.buildingComposition.stairsCount?.toString()}
            numeric
            title={vm.translate("report.block0.stairs")}
            textFieldClassName={classes.report_numericInputGeneralData_2}
            onChange={(e: any) => {
              vm.buildingComposition.stairsCount = e.target.value;
            }}
          />
        </Grid>

        <TextFieldGrid
          id="generalDataYearBulidings"
          gridTextField={{ xs: 6, md: 6 }}
          value={vm.buildingComposition.builtOnYear?.toString()}
          numeric
          title={vm.translate("report.block0.year.building")}
          textFieldClassName={classes.report_numericInputGeneralData_2}
          onChange={(e: any) => {
            vm.buildingComposition.builtOnYear = e.target.value;
          }}
        />

        <TextFieldGrid
          id="generalDataYear"
          gridTextField={{ xs: 6, md: 6 }}
          value={vm.buildingComposition.lastRehabYear?.toString()}
          numeric
          title={vm.translate("report.block0.year")}
          textFieldClassName={classes.report_numericInputGeneralData_2}
          onChange={(e: any) => {
            vm.buildingComposition.lastRehabYear = e.target.value;
          }}
        />

        <TextFieldGrid
          id="generalDataNumberBuildings"
          gridTextField={{ xs: 6, md: 6 }}
          value={vm.buildingComposition.dwellings?.toString()}
          numeric
          title={vm.translate("report.block0.number.buildings")}
          textFieldClassName={classes.report_numericInputGeneralData_2}
          onChange={(e: any) => {
            vm.buildingComposition.dwellings = e.target.value;
          }}
        />
        <TextFieldGrid
          id="generalDataNumberFloors"
          gridTextField={{ xs: 6, md: 6 }}
          value={vm.buildingComposition.dwellingsByFloor?.toString()}
          numeric
          title={vm.translate("report.block0.number.floors")}
          textFieldClassName={classes.report_numericInputGeneralData_2}
          onChange={(e: any) => {
            vm.buildingComposition.dwellingsByFloor = e.target.value;
          }}
        />

        <Grid item xs={12} md={12} className={classes.report_generalDataGrid}>
          <TextFieldGrid
            id="generalDataNumberParkings"
            gridTextField={{ xs: 6, md: 6 }}
            value={vm.buildingComposition.parkingCountInBuilding?.toString()}
            numeric
            title={vm.translate("report.block0.number.parkings")}
            textFieldClassName={classes.report_numericInputGeneralData_2}
            onChange={(e: any) => {
              vm.buildingComposition.parkingCountInBuilding = e.target.value;
            }}
          />
          <TextFieldGrid
            id="generalDataNumberParkingPlacess"
            gridTextField={{ xs: 6, md: 6 }}
            value={vm.buildingComposition.parkingPlacesInBuilding?.toString()}
            numeric
            title={vm.translate("report.block0.number.parking.places")}
            textFieldClassName={classes.report_numericInputGeneralData_2}
            onChange={(e: any) => {
              vm.buildingComposition.parkingPlacesInBuilding = e.target.value;
            }}
          />
        </Grid>

        <TextFieldGrid
          id="generalDataLocals"
          gridTextField={{ xs: 6, md: 6 }}
          value={vm.buildingComposition.premisesCount?.toString()}
          numeric
          title={vm.translate("report.block0.number.locals")}
          textFieldClassName={classes.report_numericInputGeneralData_2}
          onChange={(e: any) => {
            vm.buildingComposition.premisesCount = e.target.value;
          }}
        />

        <Grid item xs={12} md={12} className={classes.report_generalDataGrid}>
          <TextFieldGrid
            id="generalDataOtherElements"
            title={vm.translate("report.block0.other.elements")}
            onChange={(evt: any) => {
              vm.buildingComposition.otherElements = evt.target.value;
            }}
            textFieldClassName={classes.report_numericInputGeneralData_2}
            value={`${vm.buildingComposition.otherElements}`}
            gridTextField={{ xs: 6, md: 6 }}
          />
          <TextFieldGrid
            id="generalDataStorageRooms"
            gridTextField={{ xs: 6, md: 6 }}
            value={vm.buildingComposition.storageRoomsCount?.toString()}
            numeric
            title={vm.translate("report.block0.number.storage.rooms")}
            textFieldClassName={classes.report_numericInputGeneralData_2}
            onChange={(e: any) => {
              vm.buildingComposition.storageRoomsCount = e.target.value;
            }}
          />
        </Grid>
        {vm.isWarningEditUsagesOpen && <UsagesEditWarningDialog vm={vm} fillUsagesPaged={fillUsagesPaged} />}
        {vm.buildingComposition && (
          <Grid item xs={12} style={{ marginTop: 20, marginBottom: 20 }}>
            <Grid item xs={12} md={12} alignItems="center" style={{ marginBottom: 5 }} className={classes.report_generalDataGrid}>
              {vm.parent.isManual ? (
                <TypographyTitleDoubleExtended vm={vm} />
              ) : (
                <div style={{ display: "flex", flexDirection: "row", columnGap: 10, alignItems: "center" }}>
                  <TypographyTheme id="generalDataUses" bold color={Colors.textSecondaryHeaders} text={vm.translate("report.block0.uses")} />
                  <Button
                    id="generalBuildingTypologyButton"
                    variant={"outlined"}
                    onClick={() => {
                      onClickUsagesButton();
                    }}
                    sx={{
                      display:
                        vm.locationInfo.address?.province && [Communities.NAVARRA, Communities.PAIS_VASCO].includes(vm.locationInfo.address?.province)
                          ? "none"
                          : "flex",
                    }}
                  >
                    {t(!vm.isOpenEditUsages ? "edit" : "cancel")}
                  </Button>
                </div>
              )}
            </Grid>
            <Divider />
            {vm.isOpenEditUsages ? (
              <EditUsages vm={vm} setUsages={setCurrentPage} setPaginationFooter={setPaginationFooter} />
            ) : (
              <>
                {vm.parent.isManual ? (
                  <>
                    <Grid container item xs={12} justifyContent="center" alignItems="flex-end">
                      <TextFieldGrid
                        id={"addgeneralDataUsage"}
                        title={vm.translate("report.cadastral_reference.reference_name")}
                        onChange={(evt: any) => {
                          vm.manualUsage.cadastralReference = evt.target.value;
                        }}
                        value={vm.manualUsage.cadastralReference}
                        gridTextField={{ xs: 12, md: 3 }}
                        style={{ marginRight: 5 }}
                      />
                      <TextFieldGrid
                        id={"addgeneralDataUsage"}
                        title={vm.translate("usage")}
                        onChange={(evt: any) => {
                          vm.manualUsage.description = evt.target.value;
                        }}
                        value={vm.manualUsage.description}
                        gridTextField={{ xs: 12, md: 3 }}
                        style={{ marginRight: 5 }}
                      />
                      <TextFieldGrid
                        id={"addgeneralDataSurface"}
                        title={vm.translate("report.block0.uses.surface")}
                        onChange={(evt: any) => {
                          vm.manualUsage.surface = evt.target.value;
                        }}
                        value={(vm.manualUsage.surface ?? 0).toString()}
                        numeric
                        gridTextField={{ xs: 12, md: 2 }}
                        style={{ marginRight: 5 }}
                      />
                      <TextFieldGrid
                        id={"addgeneralDataUsesLocation"}
                        title={vm.translate("report.block0.uses.location")}
                        onClick={editUsage}
                        onChange={(evt: any) => {}}
                        value={formatPropertyAddress(vm.manualUsage)}
                        gridTextField={{ xs: 12, md: 3 }}
                      />
                      <Grid xs={3} md={1} onClick={addUsage}>
                        <IconButton
                          style={{ margin: 5, padding: 10 }}
                          id={`add-usage`}
                          icon={<Icon element={IconsList.Add} />}
                          onClick={handlePreviousPage}
                          backgroundColor={Colors.primary}
                        />
                      </Grid>
                    </Grid>
                    {newUsages?.map((usage: UsageDTO, index: number) => (
                      <Grid key={index + "" + usage?.cadastralReference} container item xs={12} justifyContent="center" alignItems="flex-end">
                        <TextFieldGrid
                          id={"generalDataUsage" + index}
                          title={vm.translate("report.cadastral_reference.reference_name")}
                          onChange={(evt: any) => {}}
                          disabled
                          value={usage.cadastralReference ?? ""}
                          gridTextField={{ xs: 12, md: 3 }}
                          style={{ marginRight: 5 }}
                        />
                        <TextFieldGrid
                          id={"generalDataUsage" + index}
                          title={vm.translate("usage")}
                          onChange={(evt: any) => {
                            usage.description = evt.target.value;
                            // debounce
                            vm.buildingComposition.usages = [...newUsages];
                          }}
                          value={usage.description ?? ""}
                          gridTextField={{ xs: 12, md: 3 }}
                          style={{ marginRight: 5 }}
                        />
                        <TextFieldGrid
                          id={"generalDataSurface" + index}
                          title={vm.translate("report.block0.uses.surface")}
                          onChange={(evt: any) => {
                            usage.surface = evt.target.value;
                            // debounce
                            vm.buildingComposition.usages = [...newUsages];
                          }}
                          numeric
                          value={(usage.surface ?? 0).toString()}
                          gridTextField={{ xs: 12, md: 2 }}
                          style={{ marginRight: 5 }}
                        />
                        <TextFieldGrid
                          id={"generalDataUsesLocation" + index}
                          title={vm.translate("report.block0.uses.location")}
                          onClick={() => {
                            vm.selectedBuildingCompositionUsage = index;
                          }}
                          onChange={(evt: any) => {}}
                          value={formatPropertyAddress(usage)}
                          gridTextField={{ xs: 12, md: 4 }}
                        />
                      </Grid>
                    ))}
                  </>
                ) : (
                  currentPage?.map((usage: UsageDTO, index: number) => (
                    <Grid key={index} container item xs={12} justifyContent="center" alignItems="flex-end">
                      <TextFieldGrid
                        id={"generalDataUsage" + index}
                        title={vm.translate("report.cadastral_reference.reference_name")}
                        onChange={(evt: any) => {}}
                        disabled
                        value={usage.cadastralReference ?? ""}
                        gridTextField={{ xs: 12, md: 3 }}
                        style={{ marginRight: 5 }}
                      />
                      <TextFieldGrid
                        id={"generalDataUsage" + index}
                        title={vm.translate("usage")}
                        onChange={(evt: any) => {
                          const usage = currentPage[index];
                          usage.description = evt.target.value;
                          setCurrentPage((c) => c.map((u, i) => (i === index ? usage : u)));
                          vm.buildingComposition.usages = [...currentPage];
                        }}
                        value={`${usage.description}` ?? ""}
                        gridTextField={{ xs: 12, md: 3 }}
                        style={{ marginRight: 5 }}
                      />
                      <TextFieldGrid
                        id={"generalDataSurface" + index}
                        title={vm.translate("report.block0.uses.surface")}
                        onChange={(evt: any) => {
                          const usage = currentPage[index];
                          usage.surface = Number(evt.target.value);
                          setCurrentPage((c) => c.map((u, i) => (i === index ? usage : u)));
                          vm.buildingComposition.usages = [...currentPage];
                        }}
                        numeric
                        value={(usage.surface ?? 0).toString()}
                        gridTextField={{ xs: 12, md: 2 }}
                        style={{ marginRight: 5 }}
                      />
                      <TextFieldGrid
                        id={"generalDataUsesLocation" + index}
                        title={vm.translate("report.block0.uses.location")}
                        onClick={() => {
                          vm.selectedBuildingCompositionUsage = index;
                        }}
                        onChange={(evt: any) => /* onChangeUsageAddress(evt.target.value, usage, vm.buildingComposition.usages ?? [])*/ {}}
                        value={formatPropertyAddress(usage)}
                        gridTextField={{ xs: 12, md: 4 }}
                      />
                    </Grid>
                  ))
                )}

                <Divider />

                <Grid xs={12}>
                  <Grid style={{ marginTop: "5px", marginBottom: "4px" }}>
                    <IconButton
                      style={{ margin: 5, padding: 10 }}
                      id={`prev-usage-page`}
                      icon={<Icon element={IconsList.ArrowLeft} />}
                      iconButtonSize="30"
                      onClick={handlePreviousPage}
                      backgroundColor={Colors.primary}
                    />
                    {`${paginationFooter.currentPageNumber + 1} / ${paginationFooter.totalPages}`}
                    <IconButton
                      style={{ margin: 5, padding: 10 }}
                      id={`prev-usage-page`}
                      icon={<Icon element={IconsList.ArrowRight} />}
                      iconButtonSize="30"
                      onClick={handleNextPage}
                      backgroundColor={Colors.primary}
                    />
                    <span>{` - ${vm.translate("report.block0.pagination.totalUsages")}: ${
                      vm.parent.isManual ? vm.buildingComposition?.usages?.length ?? 0 : paginationFooter.totalElements ?? 0
                    } - ${vm.translate("report.block0.pagination.totalCadastralReferences")} : ${
                      vm.parent.isManual
                        ? Object.keys(groupBy(vm.buildingComposition?.usages, "cadastralReference"))?.length ?? 0
                        : paginationFooter.totalReferences ?? 0
                    }`}</span>
                  </Grid>
                </Grid>

                <Divider />
              </>
            )}
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} md={12} className={classes.report_displayGridTitle}>
        <TypographyTheme id="generalBuildingTypology" bold color={Colors.textSecondaryHeaders} text={vm.translate("report.block0.tipology")} />

        <Divider />
        <div style={{ marginLeft: 15 }}>
          <RadioBoxGroup
            id="generalBuildingTypologyRadioGroup"
            selection={vm.buildingComposition.typology === BuildingCompositionDTOTypologyEnum.ClosedBlockBuilding}
            onSelectionChange={onChangeBuildingType}
            filaArray={[
              { label: vm.translate("report.block0.isolation.building"), value: false },
              { label: vm.translate("report.block0.building.between"), value: true },
            ]}
          />
        </div>
      </Grid>
    </React.Fragment>
  );
});
