/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BuildingFacilitiesDTO
 */
export interface BuildingFacilitiesDTO {
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    centralHeating?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    centralHeatingFuel?: number;
    /**
     * 
     * @type {string}
     * @memberof BuildingFacilitiesDTO
     */
    centralHeatingFuelUnknownOthersValue?: string;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    centralHeatingIssuers?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    centralHeatingSupplyPower?: number;
    /**
     * 
     * @type {string}
     * @memberof BuildingFacilitiesDTO
     */
    centralHeatingUnknownOthersValue?: string;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    collectiveCooling?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    collectiveCoolingIssuers?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    collectiveCoolingSupplyPower?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    communicationsFacilities?: number;
    /**
     * 
     * @type {string}
     * @memberof BuildingFacilitiesDTO
     */
    communicationsFacilitiesUnknownOthersValue?: string;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    cooling?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    coolingSystemsShownInFacade?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    directGasForDomesticFacilities?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    directGasForDomesticFacilitiesCounters?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    directGasForDomesticFacilitiesFuel?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    drainPipes?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    drainage?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    electricSupply?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    electricSupplyMeter?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    facilitiesLifting?: number;
    /**
     * 
     * @type {string}
     * @memberof BuildingFacilitiesDTO
     */
    facilitiesLiftingUnknownOthersValue?: string;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    fireProtection?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    fuelTanksAvailable?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    gasRadonProtection?: number;
    /**
     * 
     * @type {string}
     * @memberof BuildingFacilitiesDTO
     */
    gasRadonProtectionUnknownOthersValue?: string;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    hotWaterSupply?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    hotWaterSupplyFuel?: number;
    /**
     * 
     * @type {string}
     * @memberof BuildingFacilitiesDTO
     */
    hotWaterSupplyFuelUnknownOthersValue?: string;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    hotWaterSupplyPower?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    lightningProtection?: number;
    /**
     * 
     * @type {string}
     * @memberof BuildingFacilitiesDTO
     */
    lightningProtectionUnknownOthersValue?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingFacilitiesDTO
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingFacilitiesDTO
     */
    otherDrainPipesValue?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingFacilitiesDTO
     */
    otherElectricSupplyValue?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingFacilitiesDTO
     */
    otherSewerPipeValue?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingFacilitiesDTO
     */
    otherVentilationValue?: string;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    parkingHasVentilation?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    realEstateACSBottledHeaterSystems?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    realEstateACSBottledHeaterSystemsFuel?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    realEstateACSElectricHeaterSystems?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    realEstateACSHeaterSystems?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    realEstateACSHeaterSystemsFuel?: number;
    /**
     * 
     * @type {string}
     * @memberof BuildingFacilitiesDTO
     */
    realEstateACSHeaterSystemsFuelUnknownOthersValue?: string;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    realEstateDieselOilHeaterSystems?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    realEstateDirectHeaterSystems?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    realEstateDirectHeaterSystemsFuel?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    realEstateElectricHeaterSystems?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    realEstateElectricHeaterSystemsValue?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    realEstateIndividualACSProductionSystems?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    realEstateIndividualCoolingSystems?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    realEstateIndividualHeatingProductionSystems?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    realEstateOtherACSHeaterSystems?: number;
    /**
     * 
     * @type {string}
     * @memberof BuildingFacilitiesDTO
     */
    realEstateOtherACSHeaterSystemsValue?: string;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    realEstateOtherHeaterSystems?: number;
    /**
     * 
     * @type {string}
     * @memberof BuildingFacilitiesDTO
     */
    realEstateOtherHeaterSystemsUnknownOthersValue?: string;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    realEstateWetRoomsWithoutVentilation?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    sewerPipe?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    solarPanelsAvailable?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    ventilation?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    waterSupply?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    waterSupplyMeter?: number;
    /**
     * 
     * @type {number}
     * @memberof BuildingFacilitiesDTO
     */
    waterSupplyType?: number;
}

export function BuildingFacilitiesDTOFromJSON(json: any): BuildingFacilitiesDTO {
    return BuildingFacilitiesDTOFromJSONTyped(json, false);
}

export function BuildingFacilitiesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuildingFacilitiesDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'centralHeating': !exists(json, 'centralHeating') ? undefined : json['centralHeating'],
        'centralHeatingFuel': !exists(json, 'centralHeatingFuel') ? undefined : json['centralHeatingFuel'],
        'centralHeatingFuelUnknownOthersValue': !exists(json, 'centralHeatingFuelUnknownOthersValue') ? undefined : json['centralHeatingFuelUnknownOthersValue'],
        'centralHeatingIssuers': !exists(json, 'centralHeatingIssuers') ? undefined : json['centralHeatingIssuers'],
        'centralHeatingSupplyPower': !exists(json, 'centralHeatingSupplyPower') ? undefined : json['centralHeatingSupplyPower'],
        'centralHeatingUnknownOthersValue': !exists(json, 'centralHeatingUnknownOthersValue') ? undefined : json['centralHeatingUnknownOthersValue'],
        'collectiveCooling': !exists(json, 'collectiveCooling') ? undefined : json['collectiveCooling'],
        'collectiveCoolingIssuers': !exists(json, 'collectiveCoolingIssuers') ? undefined : json['collectiveCoolingIssuers'],
        'collectiveCoolingSupplyPower': !exists(json, 'collectiveCoolingSupplyPower') ? undefined : json['collectiveCoolingSupplyPower'],
        'communicationsFacilities': !exists(json, 'communicationsFacilities') ? undefined : json['communicationsFacilities'],
        'communicationsFacilitiesUnknownOthersValue': !exists(json, 'communicationsFacilitiesUnknownOthersValue') ? undefined : json['communicationsFacilitiesUnknownOthersValue'],
        'cooling': !exists(json, 'cooling') ? undefined : json['cooling'],
        'coolingSystemsShownInFacade': !exists(json, 'coolingSystemsShownInFacade') ? undefined : json['coolingSystemsShownInFacade'],
        'directGasForDomesticFacilities': !exists(json, 'directGasForDomesticFacilities') ? undefined : json['directGasForDomesticFacilities'],
        'directGasForDomesticFacilitiesCounters': !exists(json, 'directGasForDomesticFacilitiesCounters') ? undefined : json['directGasForDomesticFacilitiesCounters'],
        'directGasForDomesticFacilitiesFuel': !exists(json, 'directGasForDomesticFacilitiesFuel') ? undefined : json['directGasForDomesticFacilitiesFuel'],
        'drainPipes': !exists(json, 'drainPipes') ? undefined : json['drainPipes'],
        'drainage': !exists(json, 'drainage') ? undefined : json['drainage'],
        'electricSupply': !exists(json, 'electricSupply') ? undefined : json['electricSupply'],
        'electricSupplyMeter': !exists(json, 'electricSupplyMeter') ? undefined : json['electricSupplyMeter'],
        'facilitiesLifting': !exists(json, 'facilitiesLifting') ? undefined : json['facilitiesLifting'],
        'facilitiesLiftingUnknownOthersValue': !exists(json, 'facilitiesLiftingUnknownOthersValue') ? undefined : json['facilitiesLiftingUnknownOthersValue'],
        'fireProtection': !exists(json, 'fireProtection') ? undefined : json['fireProtection'],
        'fuelTanksAvailable': !exists(json, 'fuelTanksAvailable') ? undefined : json['fuelTanksAvailable'],
        'gasRadonProtection': !exists(json, 'gasRadonProtection') ? undefined : json['gasRadonProtection'],
        'gasRadonProtectionUnknownOthersValue': !exists(json, 'gasRadonProtectionUnknownOthersValue') ? undefined : json['gasRadonProtectionUnknownOthersValue'],
        'hotWaterSupply': !exists(json, 'hotWaterSupply') ? undefined : json['hotWaterSupply'],
        'hotWaterSupplyFuel': !exists(json, 'hotWaterSupplyFuel') ? undefined : json['hotWaterSupplyFuel'],
        'hotWaterSupplyFuelUnknownOthersValue': !exists(json, 'hotWaterSupplyFuelUnknownOthersValue') ? undefined : json['hotWaterSupplyFuelUnknownOthersValue'],
        'hotWaterSupplyPower': !exists(json, 'hotWaterSupplyPower') ? undefined : json['hotWaterSupplyPower'],
        'lightningProtection': !exists(json, 'lightningProtection') ? undefined : json['lightningProtection'],
        'lightningProtectionUnknownOthersValue': !exists(json, 'lightningProtectionUnknownOthersValue') ? undefined : json['lightningProtectionUnknownOthersValue'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'otherDrainPipesValue': !exists(json, 'otherDrainPipesValue') ? undefined : json['otherDrainPipesValue'],
        'otherElectricSupplyValue': !exists(json, 'otherElectricSupplyValue') ? undefined : json['otherElectricSupplyValue'],
        'otherSewerPipeValue': !exists(json, 'otherSewerPipeValue') ? undefined : json['otherSewerPipeValue'],
        'otherVentilationValue': !exists(json, 'otherVentilationValue') ? undefined : json['otherVentilationValue'],
        'parkingHasVentilation': !exists(json, 'parkingHasVentilation') ? undefined : json['parkingHasVentilation'],
        'realEstateACSBottledHeaterSystems': !exists(json, 'realEstateACSBottledHeaterSystems') ? undefined : json['realEstateACSBottledHeaterSystems'],
        'realEstateACSBottledHeaterSystemsFuel': !exists(json, 'realEstateACSBottledHeaterSystemsFuel') ? undefined : json['realEstateACSBottledHeaterSystemsFuel'],
        'realEstateACSElectricHeaterSystems': !exists(json, 'realEstateACSElectricHeaterSystems') ? undefined : json['realEstateACSElectricHeaterSystems'],
        'realEstateACSHeaterSystems': !exists(json, 'realEstateACSHeaterSystems') ? undefined : json['realEstateACSHeaterSystems'],
        'realEstateACSHeaterSystemsFuel': !exists(json, 'realEstateACSHeaterSystemsFuel') ? undefined : json['realEstateACSHeaterSystemsFuel'],
        'realEstateACSHeaterSystemsFuelUnknownOthersValue': !exists(json, 'realEstateACSHeaterSystemsFuelUnknownOthersValue') ? undefined : json['realEstateACSHeaterSystemsFuelUnknownOthersValue'],
        'realEstateDieselOilHeaterSystems': !exists(json, 'realEstateDieselOilHeaterSystems') ? undefined : json['realEstateDieselOilHeaterSystems'],
        'realEstateDirectHeaterSystems': !exists(json, 'realEstateDirectHeaterSystems') ? undefined : json['realEstateDirectHeaterSystems'],
        'realEstateDirectHeaterSystemsFuel': !exists(json, 'realEstateDirectHeaterSystemsFuel') ? undefined : json['realEstateDirectHeaterSystemsFuel'],
        'realEstateElectricHeaterSystems': !exists(json, 'realEstateElectricHeaterSystems') ? undefined : json['realEstateElectricHeaterSystems'],
        'realEstateElectricHeaterSystemsValue': !exists(json, 'realEstateElectricHeaterSystemsValue') ? undefined : json['realEstateElectricHeaterSystemsValue'],
        'realEstateIndividualACSProductionSystems': !exists(json, 'realEstateIndividualACSProductionSystems') ? undefined : json['realEstateIndividualACSProductionSystems'],
        'realEstateIndividualCoolingSystems': !exists(json, 'realEstateIndividualCoolingSystems') ? undefined : json['realEstateIndividualCoolingSystems'],
        'realEstateIndividualHeatingProductionSystems': !exists(json, 'realEstateIndividualHeatingProductionSystems') ? undefined : json['realEstateIndividualHeatingProductionSystems'],
        'realEstateOtherACSHeaterSystems': !exists(json, 'realEstateOtherACSHeaterSystems') ? undefined : json['realEstateOtherACSHeaterSystems'],
        'realEstateOtherACSHeaterSystemsValue': !exists(json, 'realEstateOtherACSHeaterSystemsValue') ? undefined : json['realEstateOtherACSHeaterSystemsValue'],
        'realEstateOtherHeaterSystems': !exists(json, 'realEstateOtherHeaterSystems') ? undefined : json['realEstateOtherHeaterSystems'],
        'realEstateOtherHeaterSystemsUnknownOthersValue': !exists(json, 'realEstateOtherHeaterSystemsUnknownOthersValue') ? undefined : json['realEstateOtherHeaterSystemsUnknownOthersValue'],
        'realEstateWetRoomsWithoutVentilation': !exists(json, 'realEstateWetRoomsWithoutVentilation') ? undefined : json['realEstateWetRoomsWithoutVentilation'],
        'sewerPipe': !exists(json, 'sewerPipe') ? undefined : json['sewerPipe'],
        'solarPanelsAvailable': !exists(json, 'solarPanelsAvailable') ? undefined : json['solarPanelsAvailable'],
        'ventilation': !exists(json, 'ventilation') ? undefined : json['ventilation'],
        'waterSupply': !exists(json, 'waterSupply') ? undefined : json['waterSupply'],
        'waterSupplyMeter': !exists(json, 'waterSupplyMeter') ? undefined : json['waterSupplyMeter'],
        'waterSupplyType': !exists(json, 'waterSupplyType') ? undefined : json['waterSupplyType'],
    };
}

export function BuildingFacilitiesDTOToJSON(value?: BuildingFacilitiesDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'centralHeating': value.centralHeating,
        'centralHeatingFuel': value.centralHeatingFuel,
        'centralHeatingFuelUnknownOthersValue': value.centralHeatingFuelUnknownOthersValue,
        'centralHeatingIssuers': value.centralHeatingIssuers,
        'centralHeatingSupplyPower': value.centralHeatingSupplyPower,
        'centralHeatingUnknownOthersValue': value.centralHeatingUnknownOthersValue,
        'collectiveCooling': value.collectiveCooling,
        'collectiveCoolingIssuers': value.collectiveCoolingIssuers,
        'collectiveCoolingSupplyPower': value.collectiveCoolingSupplyPower,
        'communicationsFacilities': value.communicationsFacilities,
        'communicationsFacilitiesUnknownOthersValue': value.communicationsFacilitiesUnknownOthersValue,
        'cooling': value.cooling,
        'coolingSystemsShownInFacade': value.coolingSystemsShownInFacade,
        'directGasForDomesticFacilities': value.directGasForDomesticFacilities,
        'directGasForDomesticFacilitiesCounters': value.directGasForDomesticFacilitiesCounters,
        'directGasForDomesticFacilitiesFuel': value.directGasForDomesticFacilitiesFuel,
        'drainPipes': value.drainPipes,
        'drainage': value.drainage,
        'electricSupply': value.electricSupply,
        'electricSupplyMeter': value.electricSupplyMeter,
        'facilitiesLifting': value.facilitiesLifting,
        'facilitiesLiftingUnknownOthersValue': value.facilitiesLiftingUnknownOthersValue,
        'fireProtection': value.fireProtection,
        'fuelTanksAvailable': value.fuelTanksAvailable,
        'gasRadonProtection': value.gasRadonProtection,
        'gasRadonProtectionUnknownOthersValue': value.gasRadonProtectionUnknownOthersValue,
        'hotWaterSupply': value.hotWaterSupply,
        'hotWaterSupplyFuel': value.hotWaterSupplyFuel,
        'hotWaterSupplyFuelUnknownOthersValue': value.hotWaterSupplyFuelUnknownOthersValue,
        'hotWaterSupplyPower': value.hotWaterSupplyPower,
        'lightningProtection': value.lightningProtection,
        'lightningProtectionUnknownOthersValue': value.lightningProtectionUnknownOthersValue,
        'notes': value.notes,
        'otherDrainPipesValue': value.otherDrainPipesValue,
        'otherElectricSupplyValue': value.otherElectricSupplyValue,
        'otherSewerPipeValue': value.otherSewerPipeValue,
        'otherVentilationValue': value.otherVentilationValue,
        'parkingHasVentilation': value.parkingHasVentilation,
        'realEstateACSBottledHeaterSystems': value.realEstateACSBottledHeaterSystems,
        'realEstateACSBottledHeaterSystemsFuel': value.realEstateACSBottledHeaterSystemsFuel,
        'realEstateACSElectricHeaterSystems': value.realEstateACSElectricHeaterSystems,
        'realEstateACSHeaterSystems': value.realEstateACSHeaterSystems,
        'realEstateACSHeaterSystemsFuel': value.realEstateACSHeaterSystemsFuel,
        'realEstateACSHeaterSystemsFuelUnknownOthersValue': value.realEstateACSHeaterSystemsFuelUnknownOthersValue,
        'realEstateDieselOilHeaterSystems': value.realEstateDieselOilHeaterSystems,
        'realEstateDirectHeaterSystems': value.realEstateDirectHeaterSystems,
        'realEstateDirectHeaterSystemsFuel': value.realEstateDirectHeaterSystemsFuel,
        'realEstateElectricHeaterSystems': value.realEstateElectricHeaterSystems,
        'realEstateElectricHeaterSystemsValue': value.realEstateElectricHeaterSystemsValue,
        'realEstateIndividualACSProductionSystems': value.realEstateIndividualACSProductionSystems,
        'realEstateIndividualCoolingSystems': value.realEstateIndividualCoolingSystems,
        'realEstateIndividualHeatingProductionSystems': value.realEstateIndividualHeatingProductionSystems,
        'realEstateOtherACSHeaterSystems': value.realEstateOtherACSHeaterSystems,
        'realEstateOtherACSHeaterSystemsValue': value.realEstateOtherACSHeaterSystemsValue,
        'realEstateOtherHeaterSystems': value.realEstateOtherHeaterSystems,
        'realEstateOtherHeaterSystemsUnknownOthersValue': value.realEstateOtherHeaterSystemsUnknownOthersValue,
        'realEstateWetRoomsWithoutVentilation': value.realEstateWetRoomsWithoutVentilation,
        'sewerPipe': value.sewerPipe,
        'solarPanelsAvailable': value.solarPanelsAvailable,
        'ventilation': value.ventilation,
        'waterSupply': value.waterSupply,
        'waterSupplyMeter': value.waterSupplyMeter,
        'waterSupplyType': value.waterSupplyType,
    };
}

