import { IBuildingApi } from '../ibuildings-api'
import { Api } from '../../../infrastructure/data/api-client'
import { BaseRepository } from '../../../infrastructure/data/repositories/base-repository'

export default class BuildingRepository extends BaseRepository<IBuildingApi> {
  constructor() {
    super(Api.BuildingApi, false)
  }

  getBuildingInfoByRealEstateIds = async (ids: Array<string>) => {
    const client = await this.apiClient
    return await client.getBuildingInfoByRealEstateIds(ids)
  }
  getIncompletePropertiesIdsFrom = async (ids: string[]) => {
    const client = await this.apiClient
    return await client.getIncompletePropertiesIdsFrom(ids)
  }
}