/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ParcelDTO,
    ParcelDTOFromJSON,
    ParcelDTOFromJSONTyped,
    ParcelDTOToJSON,
    PropertyDTO,
    PropertyDTOFromJSON,
    PropertyDTOFromJSONTyped,
    PropertyDTOToJSON,
    TechnicianParticipationDTO,
    TechnicianParticipationDTOFromJSON,
    TechnicianParticipationDTOFromJSONTyped,
    TechnicianParticipationDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface BuildingDTO
 */
export interface BuildingDTO {
    /**
     * 
     * @type {string}
     * @memberof BuildingDTO
     */
    community?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof BuildingDTO
     */
    dwellings?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof BuildingDTO
     */
    floorsAboveGround?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof BuildingDTO
     */
    floorsUnderGround?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof BuildingDTO
     */
    name?: string;
    /**
     * 
     * @type {Array<ParcelDTO>}
     * @memberof BuildingDTO
     */
    parcels?: Array<ParcelDTO>;
    /**
     * 
     * @type {string}
     * @memberof BuildingDTO
     */
    portalNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingDTO
     */
    portalNumberLetter?: string;
    /**
     * 
     * @type {number}
     * @memberof BuildingDTO
     */
    postalCode?: number;
    /**
     * 
     * @type {Array<PropertyDTO>}
     * @memberof BuildingDTO
     */
    properties?: Array<PropertyDTO>;
    /**
     * 
     * @type {string}
     * @memberof BuildingDTO
     */
    province?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingDTO
     */
    secondaryPortalLetter?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingDTO
     */
    secondaryPortalNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingDTO
     */
    street?: string;
    /**
     * 
     * @type {Array<TechnicianParticipationDTO>}
     * @memberof BuildingDTO
     */
    techniciansParticipation?: Array<TechnicianParticipationDTO>;
    /**
     * 
     * @type {string}
     * @memberof BuildingDTO
     */
    town?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingDTO
     */
    transactionId?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildingDTO
     */
    type?: string;
    /**
     * 
     * @type {number}
     * @memberof BuildingDTO
     */
    year?: number;
}

export function BuildingDTOFromJSON(json: any): BuildingDTO {
    return BuildingDTOFromJSONTyped(json, false);
}

export function BuildingDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuildingDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'community': !exists(json, 'community') ? undefined : json['community'],
        'dwellings': !exists(json, 'dwellings') ? undefined : json['dwellings'],
        'floorsAboveGround': !exists(json, 'floorsAboveGround') ? undefined : json['floorsAboveGround'],
        'floorsUnderGround': !exists(json, 'floorsUnderGround') ? undefined : json['floorsUnderGround'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'parcels': !exists(json, 'parcels') ? undefined : ((json['parcels'] as Array<any>).map(ParcelDTOFromJSON)),
        'portalNumber': !exists(json, 'portalNumber') ? undefined : json['portalNumber'],
        'portalNumberLetter': !exists(json, 'portalNumberLetter') ? undefined : json['portalNumberLetter'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'properties': !exists(json, 'properties') ? undefined : ((json['properties'] as Array<any>).map(PropertyDTOFromJSON)),
        'province': !exists(json, 'province') ? undefined : json['province'],
        'secondaryPortalLetter': !exists(json, 'secondaryPortalLetter') ? undefined : json['secondaryPortalLetter'],
        'secondaryPortalNumber': !exists(json, 'secondaryPortalNumber') ? undefined : json['secondaryPortalNumber'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'techniciansParticipation': !exists(json, 'techniciansParticipation') ? undefined : ((json['techniciansParticipation'] as Array<any>).map(TechnicianParticipationDTOFromJSON)),
        'town': !exists(json, 'town') ? undefined : json['town'],
        'transactionId': !exists(json, 'transactionId') ? undefined : json['transactionId'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'year': !exists(json, 'year') ? undefined : json['year'],
    };
}

export function BuildingDTOToJSON(value?: BuildingDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'community': value.community,
        'dwellings': value.dwellings,
        'floorsAboveGround': value.floorsAboveGround,
        'floorsUnderGround': value.floorsUnderGround,
        'name': value.name,
        'parcels': value.parcels === undefined ? undefined : ((value.parcels as Array<any>).map(ParcelDTOToJSON)),
        'portalNumber': value.portalNumber,
        'portalNumberLetter': value.portalNumberLetter,
        'postalCode': value.postalCode,
        'properties': value.properties === undefined ? undefined : ((value.properties as Array<any>).map(PropertyDTOToJSON)),
        'province': value.province,
        'secondaryPortalLetter': value.secondaryPortalLetter,
        'secondaryPortalNumber': value.secondaryPortalNumber,
        'street': value.street,
        'techniciansParticipation': value.techniciansParticipation === undefined ? undefined : ((value.techniciansParticipation as Array<any>).map(TechnicianParticipationDTOToJSON)),
        'town': value.town,
        'transactionId': value.transactionId,
        'type': value.type,
        'year': value.year,
    };
}

