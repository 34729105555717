import { Grid, GridSize, TextField } from "@mui/material";
import { CSSProperties } from "@mui/styles";
import React, { useState } from "react";
import { ReportStyle } from "../views/reports/report-style";
import { FormValidator } from "../views/reports/reportComponents/block0/urbanData/components/FormValidator";

export interface ITextArea {
  id: string;
  label?: string;
  gridTextField: grid;
  width?: string;
  style?: CSSProperties;
  rows?: number;
  onChange?: (value: any) => void;
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  useMaterial?: boolean;
  textFieldClassName?: string;
  required?: boolean;
  error?: boolean;
  charLimit?: number;
  form?: string;
  validator?: FormValidator;
}

type grid = { xs: GridSize; md: GridSize };

function TextAreaGrid({ onChange = () => {}, value = "", defaultValue = "", charLimit = 1000, required = false, ...props }: ITextArea) {
  const [error, setError] = useState(required && (value === undefined || value === null || value === "") ? true : false);
  const classes = ReportStyle();

  const onBlur = (e: any) => {
    if (required === false || required === undefined) return;
    setError(!e.target.value || e.target.value === "");
  };

  const onFocus = (e: any) => {
    if (required === false || required === undefined) return;
    if (!e.target.value || e.target.value === "") {
      setError(true);
    }
  };

  return (
    <React.Fragment>
      <Grid item className={classes.report_textFieldGrid} xs={props.gridTextField.xs} md={props.gridTextField.md}>
        <TextField
          id={props.id}
          onBlur={onBlur}
          onFocus={onFocus}
          error={error}
          multiline
          placeholder={props.placeholder || ""}
          onChange={(evt: any) => {
            if (evt.target.value.length <= charLimit) {
              onChange(evt);
              props.validator?.setErrorForKey(props.form, props.id, false);
            }
          }}
          required={required}
          variant={"outlined"}
          label={props.label || ""}
          style={{ width: props.width || "100%" }}
          value={value}
          defaultValue={defaultValue}
          className={props.textFieldClassName ?? classes.report_textFieldArea}
          inputProps={{ style: props.style && props.style, className: props.textFieldClassName ?? classes.report_textFieldArea }}
        />
      </Grid>
    </React.Fragment>
  );
}

export default TextAreaGrid;
