import { IParcelApi } from '../iparcel-api'
import { Api } from '../../../infrastructure/data/api-client'
import { BaseRepository } from '../../../infrastructure/data/repositories/base-repository'

export default class ParcelsRepository extends BaseRepository<IParcelApi> {
  constructor() {
    super(Api.ParcelsApi, false)
  }

  getParcelByCadastralReference = async (cadastralReference:string, refresh?: boolean) => {
    const client = await this.apiClient
    return await client.getParcelByCadastralReference(cadastralReference, refresh)
  }
}