export default class LoginRepository {
  async login(userName: string, password: string) {
    // TODO make actual backend call to authorize the user
    return {
      email: userName,
      firstName: 'John',
      lastName: 'Johnattan',
      apiKey: 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsInRlbmFudElkIjoiYzQwMWMzNDgtYzg1OS00ZWY1LTk3YzctOTE1ZGU5Y2FlZTEzIiwiaWF0IjoxNjQzMDA4NjI2LCJleHAiOjE2NDMwOTUwMjZ9.rPP9um8m7ZO_200fjFMrF0bGXSjpCzZXcQk1ZyZjlPMKz9hnKkHmT30DBbTKXOh7Pu42PSeLR36YZZtlfRbWYQ'
    }
  }
}