/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Field,
    FieldFromJSON,
    FieldFromJSONTyped,
    FieldToJSON,
} from './';

/**
 * 
 * @export
 * @interface Sorting
 */
export interface Sorting {
    /**
     * 
     * @type {Array<Field>}
     * @memberof Sorting
     */
    fields?: Array<Field>;
}

export function SortingFromJSON(json: any): Sorting {
    return SortingFromJSONTyped(json, false);
}

export function SortingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Sorting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fields': !exists(json, 'fields') ? undefined : ((json['fields'] as Array<any>).map(FieldFromJSON)),
    };
}

export function SortingToJSON(value?: Sorting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fields': value.fields === undefined ? undefined : ((value.fields as Array<any>).map(FieldToJSON)),
    };
}

