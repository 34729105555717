import React from "react"
import { observer } from "mobx-react-lite"
import { ProfileUserStructure } from "./form-components/profile-user-structure"
import ProfileViewModel from "../../../../viewmodels/profile/profile-view-model"

export const ProfileForm: React.FC<{ vm: ProfileViewModel }> = observer(({ vm }) => {
  return (
    <React.Fragment>
      <ProfileUserStructure vm={vm} />
     { /*<ProfilePassword vm={vm} />*/}
    </React.Fragment>
  )
})