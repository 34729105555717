import { registerView, ViewComponent } from '@frui.ts/views'
import { Icon, IconButton } from '@movicoders/movicoders-components'
import { IconsList } from '@movicoders/movicoders-components/'
import { Popover } from '@mui/material'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { Colors } from '../../constants'
import theme from '../../theme'
import DashboardViewModel from '../../viewmodels/dashboard/dashboard-view-model'
import { Map } from './dashboard-components/Map'
import { CardDetail } from './dashboard-components/mapcomponents/CardDetail'
import { MapFilter } from './dashboard-components/mapcomponents/map-filter'
import { DashboardStyle } from './dashboard-style'

const DashboardView: ViewComponent<DashboardViewModel> = observer(({ vm }) => {
  const classes = DashboardStyle(theme)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => { setAnchorEl(event.currentTarget) }
  const handleClose = () => { setAnchorEl(null) }

  return (
    <React.Fragment >
      <React.Fragment>
        <div className={classes.dashboard_iconButtonDiv}>
          <IconButton
            id="iconButtonDashboardView"
            classIconButton={open ? classes.dashboard_iconButtonOpen : classes.dashboard_iconButton}
            disabled={open}
            aria-describedby={id}
            onClick={handleClick}
            icon={
              <Icon
                color={open ? "transparent" : "white"}
                element={IconsList.FilterTiltShift} size="small"
              />
            }
          />
        </div>
        {vm.markerSelected !== undefined && <CardDetail vm={vm} />}
        <Popover id={id} open={open} className={classes.dashboard_popover}
          style={{ position: "initial", padding: 20 }}
          BackdropProps={{ style: { display: "none" } }}
          anchorEl={anchorEl}
          keepMounted
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div className={classes.dashboard_filterText}>
            {vm.translate("dashboard.filter")}
            <IconButton backgroundColor="transparent" color={Colors.textPrimary} onClick={handleClose} icon={<Icon element={IconsList.Clear} size={"small"}  />}/>
          </div>
          <MapFilter vm={vm} />
          
        </Popover>
      </React.Fragment>
      <Map vm={vm} />
    </React.Fragment>
  )
})

registerView(DashboardView, DashboardViewModel);