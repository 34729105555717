import { Autocomplete, Grid, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { AddressDTO, CommunityDTO } from "../../../../../clients";
import ProfileViewModel from "../../../../../viewmodels/profile/profile-view-model";
import ReportViewModel from "../../../../../viewmodels/report/report-view-model";
import { ReportStyle } from "../../../../reports/report-style";
import { ProfileStyle } from "../../../profile-styles";
import { Communities } from "../../../../../constants";

export const useCommunitySelector = (vm: ProfileViewModel) => {
  const classes = ProfileStyle();
  const reportClasses = ReportStyle();

  const [isCommunitySelected, setIsCommunitySelected] = useState(false);
  const [isManual, setIsManual] = useState(false);
  const [address, setAddress] = useState<AddressDTO>();

  const [defaultCommunity, setDefaultCommunity] = useState<CommunityDTO | undefined>(undefined);

  useEffect(() => {
    setIsCommunitySelected(defaultCommunity === undefined ? false : true);
  }, [defaultCommunity]);

  const view = (
    <Grid item xs={12} md={12} style={{ marginTop: 10 }} className={classes.profile_displayGrid}>
      <Grid item xs={12} md={12} className={classes.profile_generalDataGrid}>
        <Autocomplete
          disablePortal
          id="registerFormCcaa"
          options={vm.parent.communities || []}
          fullWidth
          value={defaultCommunity}
          clearIcon={<></>}
          getOptionLabel={(option: any) => option.name}
          renderInput={(params: any) => (
            <TextField {...params} className={reportClasses.report_textField_ccaa} required size="small" label={vm.translate("report.block0.ccaa")} />
          )}
          onChange={(event: any, value: any) => {
            setAddress({ ...address, community: value.name });
            if (value.name.includes(Communities.NAVARRA) || value.name.includes(Communities.PAIS_VASCO)) setIsManual(true);
            else setIsManual(false);

            (vm.parent.children.find((c) => c.navigationName === "report") as ReportViewModel).selectedCommunityManualEntry = value.name;
            (vm.parent.children.find((r) => r.navigationName === "report") as ReportViewModel).manualAddress.community = value.name;

            if ((vm.parent.children.find((r) => r.navigationName === "report") as ReportViewModel)?.locationInfo?.address)
              (vm.parent.children.find((r) => r.navigationName === "report") as ReportViewModel).locationInfo.address!.community = value.name;

            const element = document.getElementById("cadastralReferenceParcel");
            element?.focus();

            setDefaultCommunity(value);
          }}
        />
        <div style={{ marginRight: 5 }} />
      </Grid>
    </Grid>
  );

  return { view, isManual, isCommunitySelected };
};
