/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MediaPrintedDTO,
    MediaPrintedDTOFromJSON,
    MediaPrintedDTOFromJSONTyped,
    MediaPrintedDTOToJSON,
    NewBuildingFloorsDTO,
    NewBuildingFloorsDTOFromJSON,
    NewBuildingFloorsDTOFromJSONTyped,
    NewBuildingFloorsDTOToJSON,
    NewSecondaryUsagesDTO,
    NewSecondaryUsagesDTOFromJSON,
    NewSecondaryUsagesDTOFromJSONTyped,
    NewSecondaryUsagesDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewDocGeneralData
 */
export interface NewDocGeneralData {
    /**
     * 
     * @type {NewBuildingFloorsDTO}
     * @memberof NewDocGeneralData
     */
    buildingFloors?: NewBuildingFloorsDTO;
    /**
     * 
     * @type {string}
     * @memberof NewDocGeneralData
     */
    buildingTypology?: string;
    /**
     * 
     * @type {number}
     * @memberof NewDocGeneralData
     */
    builtSurface?: number;
    /**
     * 
     * @type {string}
     * @memberof NewDocGeneralData
     */
    cadastralReference?: string;
    /**
     * 
     * @type {string}
     * @memberof NewDocGeneralData
     */
    constructionYear?: string;
    /**
     * 
     * @type {number}
     * @memberof NewDocGeneralData
     */
    dwellings?: number;
    /**
     * 
     * @type {number}
     * @memberof NewDocGeneralData
     */
    dwellingsByFloor?: number;
    /**
     * 
     * @type {number}
     * @memberof NewDocGeneralData
     */
    elevatorsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof NewDocGeneralData
     */
    height?: number;
    /**
     * 
     * @type {Array<MediaPrintedDTO>}
     * @memberof NewDocGeneralData
     */
    images?: Array<MediaPrintedDTO>;
    /**
     * 
     * @type {string}
     * @memberof NewDocGeneralData
     */
    otherElements?: string;
    /**
     * 
     * @type {number}
     * @memberof NewDocGeneralData
     */
    parcelSurface?: number;
    /**
     * 
     * @type {number}
     * @memberof NewDocGeneralData
     */
    parkingFloorCount?: number;
    /**
     * 
     * @type {number}
     * @memberof NewDocGeneralData
     */
    parkingPlacesInBuilding?: number;
    /**
     * 
     * @type {number}
     * @memberof NewDocGeneralData
     */
    premisesCount?: number;
    /**
     * 
     * @type {Array<NewSecondaryUsagesDTO>}
     * @memberof NewDocGeneralData
     */
    secondaryUsages?: Array<NewSecondaryUsagesDTO>;
    /**
     * 
     * @type {number}
     * @memberof NewDocGeneralData
     */
    stairsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof NewDocGeneralData
     */
    storageRoomCount?: number;
}

export function NewDocGeneralDataFromJSON(json: any): NewDocGeneralData {
    return NewDocGeneralDataFromJSONTyped(json, false);
}

export function NewDocGeneralDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewDocGeneralData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buildingFloors': !exists(json, 'buildingFloors') ? undefined : NewBuildingFloorsDTOFromJSON(json['buildingFloors']),
        'buildingTypology': !exists(json, 'buildingTypology') ? undefined : json['buildingTypology'],
        'builtSurface': !exists(json, 'builtSurface') ? undefined : json['builtSurface'],
        'cadastralReference': !exists(json, 'cadastralReference') ? undefined : json['cadastralReference'],
        'constructionYear': !exists(json, 'constructionYear') ? undefined : json['constructionYear'],
        'dwellings': !exists(json, 'dwellings') ? undefined : json['dwellings'],
        'dwellingsByFloor': !exists(json, 'dwellingsByFloor') ? undefined : json['dwellingsByFloor'],
        'elevatorsCount': !exists(json, 'elevatorsCount') ? undefined : json['elevatorsCount'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'images': !exists(json, 'images') ? undefined : ((json['images'] as Array<any>).map(MediaPrintedDTOFromJSON)),
        'otherElements': !exists(json, 'otherElements') ? undefined : json['otherElements'],
        'parcelSurface': !exists(json, 'parcelSurface') ? undefined : json['parcelSurface'],
        'parkingFloorCount': !exists(json, 'parkingFloorCount') ? undefined : json['parkingFloorCount'],
        'parkingPlacesInBuilding': !exists(json, 'parkingPlacesInBuilding') ? undefined : json['parkingPlacesInBuilding'],
        'premisesCount': !exists(json, 'premisesCount') ? undefined : json['premisesCount'],
        'secondaryUsages': !exists(json, 'secondaryUsages') ? undefined : ((json['secondaryUsages'] as Array<any>).map(NewSecondaryUsagesDTOFromJSON)),
        'stairsCount': !exists(json, 'stairsCount') ? undefined : json['stairsCount'],
        'storageRoomCount': !exists(json, 'storageRoomCount') ? undefined : json['storageRoomCount'],
    };
}

export function NewDocGeneralDataToJSON(value?: NewDocGeneralData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buildingFloors': NewBuildingFloorsDTOToJSON(value.buildingFloors),
        'buildingTypology': value.buildingTypology,
        'builtSurface': value.builtSurface,
        'cadastralReference': value.cadastralReference,
        'constructionYear': value.constructionYear,
        'dwellings': value.dwellings,
        'dwellingsByFloor': value.dwellingsByFloor,
        'elevatorsCount': value.elevatorsCount,
        'height': value.height,
        'images': value.images === undefined ? undefined : ((value.images as Array<any>).map(MediaPrintedDTOToJSON)),
        'otherElements': value.otherElements,
        'parcelSurface': value.parcelSurface,
        'parkingFloorCount': value.parkingFloorCount,
        'parkingPlacesInBuilding': value.parkingPlacesInBuilding,
        'premisesCount': value.premisesCount,
        'secondaryUsages': value.secondaryUsages === undefined ? undefined : ((value.secondaryUsages as Array<any>).map(NewSecondaryUsagesDTOToJSON)),
        'stairsCount': value.stairsCount,
        'storageRoomCount': value.storageRoomCount,
    };
}

