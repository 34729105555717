import "./marker.css";
import React from "react";
import { MapStyle } from "./MapStyle";
import { observer } from "mobx-react-lite";
import { MapContainer } from "react-leaflet";
import ReactiveMap from "./mapcomponents/reactive-map";
import BackDropLoading from "../../../components/backdrop-loading";
import DashboardViewModel from "../../../viewmodels/dashboard/dashboard-view-model";

export const Map: React.FC<{ vm: DashboardViewModel }> = observer(({ vm }) => {
  const classes = MapStyle();

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      await vm.loadCoordinates();
      await vm.loadDistricts();
      await vm.loadMarkers();
      setLoading(false);
    })();
    const tm = setTimeout(() => {
      setLoading(false);
    }, 10000);
    return () => clearTimeout(tm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!!vm.parent.technicianData === true) setLoading(false);
  }, [vm.parent.technicianData]);

  return (
    <React.Fragment>
      <BackDropLoading showLoading={loading} />
      <MapContainer
        id="mapContainer"
        style={{ zIndex: 0, position: "fixed", padding: 0, margin: 0, top: "55px", left: 0, width: "100%" }}
        className={classes.map_additionalData}
        center={[vm.latitude, vm.longitude]}
        zoom={13}
        scrollWheelZoom={true}
      >
        <ReactiveMap vm={vm} props={{}} />
      </MapContainer>
    </React.Fragment>
  );
});
