/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MailConfigDTO,
    MailConfigDTOFromJSON,
    MailConfigDTOToJSON,
    MovicodersBadRequestException,
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundException,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceException,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
    UserConfigDTO,
    UserConfigDTOFromJSON,
    UserConfigDTOToJSON,
} from '../models';

export interface AddMailConfigToUserConfigRequest {
    id: string;
    mailConfigDTO?: MailConfigDTO;
}

export interface AddUserConfigRequest {
    userConfigDTO?: UserConfigDTO;
}

export interface DeleteMailConfigByNameRequest {
    id: string;
}

export interface DeleteUserConfigByIdRequest {
    id: string;
}

export interface GetMailConfigByNameRequest {
    name: string;
}

export interface GetUserConfigByIdRequest {
    id: string;
}

/**
 * 
 */
export class ConfigurationApi extends runtime.BaseAPI {

    /**
     * Add a mail configuration to a user configuration
     * Add a mail configuration to a user configuration.
     */
    async addMailConfigToUserConfigRaw(requestParameters: AddMailConfigToUserConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserConfigDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addMailConfigToUserConfig.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/configuration/{id}/mail`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MailConfigDTOToJSON(requestParameters.mailConfigDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserConfigDTOFromJSON(jsonValue));
    }

    /**
     * Add a mail configuration to a user configuration
     * Add a mail configuration to a user configuration.
     */
    async addMailConfigToUserConfig(requestParameters: AddMailConfigToUserConfigRequest, initOverrides?: RequestInit): Promise<UserConfigDTO> {
        const response = await this.addMailConfigToUserConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Saves a user configuration data
     * Save user configuration data
     */
    async addUserConfigRaw(requestParameters: AddUserConfigRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserConfigDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/configuration`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserConfigDTOToJSON(requestParameters.userConfigDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserConfigDTOFromJSON(jsonValue));
    }

    /**
     * Saves a user configuration data
     * Save user configuration data
     */
    async addUserConfig(requestParameters: AddUserConfigRequest, initOverrides?: RequestInit): Promise<UserConfigDTO> {
        const response = await this.addUserConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete one UserConfig by id.
     * Delete a mail configuration by name
     */
    async deleteMailConfigByNameRaw(requestParameters: DeleteMailConfigByNameRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserConfigDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteMailConfigByName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/configuration/mail/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserConfigDTOFromJSON(jsonValue));
    }

    /**
     * Delete one UserConfig by id.
     * Delete a mail configuration by name
     */
    async deleteMailConfigByName(requestParameters: DeleteMailConfigByNameRequest, initOverrides?: RequestInit): Promise<UserConfigDTO> {
        const response = await this.deleteMailConfigByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete one UserConfig by id.
     * Delete a UserConfig by id.
     */
    async deleteUserConfigByIdRaw(requestParameters: DeleteUserConfigByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserConfigDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUserConfigById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/configuration/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserConfigDTOFromJSON(jsonValue));
    }

    /**
     * Delete one UserConfig by id.
     * Delete a UserConfig by id.
     */
    async deleteUserConfigById(requestParameters: DeleteUserConfigByIdRequest, initOverrides?: RequestInit): Promise<UserConfigDTO> {
        const response = await this.deleteUserConfigByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a mail configuration by name
     * Get a mail configuration by name
     */
    async getMailConfigByNameRaw(requestParameters: GetMailConfigByNameRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MailConfigDTO>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getMailConfigByName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/configuration/mail/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailConfigDTOFromJSON(jsonValue));
    }

    /**
     * Returns a mail configuration by name
     * Get a mail configuration by name
     */
    async getMailConfigByName(requestParameters: GetMailConfigByNameRequest, initOverrides?: RequestInit): Promise<MailConfigDTO> {
        const response = await this.getMailConfigByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns one UserConfig by id.
     * Get a UserConfig by id.
     */
    async getUserConfigByIdRaw(requestParameters: GetUserConfigByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserConfigDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUserConfigById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/configuration/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserConfigDTOFromJSON(jsonValue));
    }

    /**
     * Returns one UserConfig by id.
     * Get a UserConfig by id.
     */
    async getUserConfigById(requestParameters: GetUserConfigByIdRequest, initOverrides?: RequestInit): Promise<UserConfigDTO> {
        const response = await this.getUserConfigByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
