import { ISessionStore } from './session-store-interface';
import { localSessionStore } from './stores/local-storage-store';
import { sessionStorageSessionStore } from './stores/session-storage-store';

export enum SessionStoreType {
  SessionAsyncStorage, 
  ContextStorage, 
  LocalStorage, 
  SessionStorage
};

export class SessionStoreFactory {
  private static type: SessionStoreType = SessionStoreType.LocalStorage;
  private static sessionStoreMap = new Map<SessionStoreType, ISessionStore>();

  public static getSessionStore() {
    return SessionStoreFactory.sessionStoreMap.get(SessionStoreFactory.type)!!;
  }

  public static register(type: SessionStoreType, store: ISessionStore) {
    SessionStoreFactory.sessionStoreMap.set(type, store);
  }
}

SessionStoreFactory.register(SessionStoreType.SessionStorage, sessionStorageSessionStore());
SessionStoreFactory.register(SessionStoreType.LocalStorage, localSessionStore());