import { FileUploader, Icon, IconButton, TypographyTheme } from "@movicoders/movicoders-components";
import { IconsList } from "@movicoders/movicoders-components";
import { Grid, ImageList, ImageListItem, ImageListItemBar, Typography, useMediaQuery } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { MultimediaDTO } from "../../../../../../clients/models";
import { Colors, FontSizes } from "../../../../../../constants";
import ReportViewModel from "../../../../../../viewmodels/report/report-view-model";
import { ReportStyle } from "../../../../report-style";
import { DeleteDialog } from "../../buildingIdentification/components/DeleteDialog";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { useSnackbar } from "notistack";
import { CustomReactDropzone } from "../../buildingIdentification/components/CustomReactDropzone";

export const UrbanPlaneSection: React.FC<{ vm: ReportViewModel }> = observer(({ vm }) => {
  const classes = ReportStyle();
  const min = useMediaQuery("(min-width:742px)");

  const [fileToDelete, setFileToDelete] = useState<null | string | undefined>(null);
  const [images, setImages] = useState<MultimediaDTO[]>(vm.building?.parcels ? [(vm.building?.parcels[0] as any)?.media[0]] : []);

  const { enqueueSnackbar } = useSnackbar();
  const handleOpenSnackBar = (snackKey: string, variants: undefined | "default" | "error" | "success" | "warning" | "info") => {
    enqueueSnackbar(snackKey, { variant: variants, preventDuplicate: true, key: snackKey, autoHideDuration: 3000 });
  };

  const onChangeFiles = async (files?: File[]) => {
    if (files && files.length) {
      files.filter((file: File) => file !== undefined && file.name.includes("+" || "%")).length === 0
        ? (vm.urbanMedia = files)
        : handleOpenSnackBar("uploadNameError", "error");
    }
  };

  useEffect(() => {
    (async () => {
      setImages(await Promise.all(vm.userBook.urbanData?.urbanPlan?.map(async (media) => await vm.getMultimediaById(media.id!)) ?? []));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      setImages(await Promise.all(vm.userBook.urbanData?.urbanPlan?.map(async (media) => await vm.getMultimediaById(media.id!)) ?? []));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vm.userBook.urbanData?.urbanPlan]);

  const deleteFile = async () => {
    try {
      if (!fileToDelete) return;
      const res = await vm.deleteFile(fileToDelete);
      if (res) {
        if (vm.urbanData && vm.urbanData.urbanPlan) vm.urbanData.urbanPlan = vm.urbanData?.urbanPlan?.filter((i) => i.id !== fileToDelete);
        setImages(images.filter((item) => item.id !== fileToDelete));
      }
      setFileToDelete(null);
    } catch (err) {
      setFileToDelete(null);
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <Grid item xs={12} md={12} className={classes.report_displayGridTitle}>
        <TypographyTheme size={FontSizes.h1} color={Colors.textPrimaryHeaders} text={vm.translate("report.block0.urban.plan")} />
        <hr className={classes.report_hr} />
        <TypographyTheme size={FontSizes.h4} color={Colors.textPrimary} text={vm.translate("report.block0.urban.plan.description")} />

        {vm.showFileuploader ? (
          <Grid>
            <TypographyTheme color={Colors.textPrimary} size={FontSizes.h4} text={vm.translate("accesibility.evaluation.appears.image")} />
            <CustomReactDropzone
              retrieveFiles={onChangeFiles}
              infoTitle={vm.translate("report.block1.license.file.helper")}
              infoMessage={vm.translate("report.block1.license.file.helper.info")}
              vm={vm}
            />
            <Typography fontSize={11}>{vm.translate("file.size.recommendation")}</Typography>
          </Grid>
        ) : (
          <div style={{ width: "100%", height: "250px" }}></div>
        )}

        {images.length !== 0 && (
          <ImageList sx={{ width: "100%" }} cols={min ? 4 : 2} rowHeight={200}>
            {images.map((item: MultimediaDTO, index: number) =>
              item.extension !== "pdf" ? (
                <React.Fragment>
                  <ImageListItem style={{ height: "200px !important" }} key={item?.id}>
                    <img
                      src={`${item!!.url}`.replace("http://", "https://")}
                      srcSet={`${item?.url}?w=200&h=200&fit=cover&auto=format&dpr=2 2x`}
                      alt={item?.name}
                      loading="lazy"
                    />
                    <ImageListItemBar
                      title={item?.name}
                      className={classes.profile_inlineIcons}
                      actionIcon={
                        <>
                          <IconButton
                            id={"iconButtonDownloadFile" + index}
                            backgroundColor="transparent"
                            onClick={() => vm.downloadFile(item!.url ?? "")}
                            icon={<Icon color={Colors.white} element={IconsList.CloudDownload} />}
                          />
                          <IconButton
                            id={"graphicDocumentationIconButton" + index}
                            style={{ display: "inherit" }}
                            backgroundColor="transparent"
                            onClick={async () => {
                              setFileToDelete(item!.id ?? "");
                            }}
                            icon={<Icon color={Colors.white} element={IconsList.DeleteForever} />}
                          />
                        </>
                      }
                    />
                  </ImageListItem>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <ImageListItem style={{ height: "200px !important" }} key={item?.id}>
                    <FilePresentIcon style={{ width: "100%", height: "100%" }} />
                    <ImageListItemBar
                      title={item?.name}
                      className={classes.profile_inlineIcons}
                      actionIcon={
                        <>
                          <IconButton
                            id={"graphicDocumentationIconButton" + index}
                            backgroundColor="transparent"
                            style={{ display: "inherit" }}
                            onClick={() => vm.downloadFile(item!.url ?? "")}
                            icon={<Icon color={Colors.white} element={IconsList.CloudDownload} />}
                          />
                          <IconButton
                            id={"graphicDocumentationIconButton" + index}
                            style={{ display: "inherit" }}
                            backgroundColor="transparent"
                            icon={<Icon color={Colors.white} element={IconsList.DeleteForever} />}
                            onClick={async () => {
                              setFileToDelete(item!.id);
                            }}
                          />
                        </>
                      }
                    />
                  </ImageListItem>
                </React.Fragment>
              )
            )}
          </ImageList>
        )}
      </Grid>
      {fileToDelete && <DeleteDialog onClose={() => setFileToDelete(null)} onDelete={deleteFile} vm={vm} />}
    </React.Fragment>
  );
});
