import { ISessionStore } from "../session-store-interface";
import User from "../../../models/current-user";

export const localSessionStore = (): ISessionStore => {
  const sessionStore: ISessionStore = {
    getToken() {
      return localStorage.getItem("token");
    },

    setToken(token: string) {
      localStorage.setItem("token", token);
    },

    deleteToken() {
      localStorage.removeItem("token");
    },

    getUser() {
      const user =
        localStorage && localStorage.getItem("user") !== null
          ? JSON.parse(localStorage.getItem("user")!!)
          : undefined;
      return user ?? undefined;
    },

    setUser(curentUser: User) {
      localStorage.setItem("user", JSON.stringify(curentUser));
    },

    deleteUser() {
      localStorage.removeItem("user");
    },

    isLoggedIn: async () => {
      const token = localStorage.getItem("token");
      return !!(token && token.length > 0);
    },

    getCurrentPath: async () => {
      if (!localStorage.getItem("currentPath"))
        localStorage.setItem("currentPath", "/");
      return localStorage.getItem("currentPath")!!;
    },

    setCurrentPath(path: string) {
      localStorage.setItem("currentPath", path);
    },
  };
  return sessionStore;
};
