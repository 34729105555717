import { Api } from "../../../infrastructure/data/api-client";
import { BaseRepository } from "../../../infrastructure/data/repositories/base-repository";
import ITownApi from "../itowns-api";

export default class TownRepository extends BaseRepository<ITownApi> {
  constructor() {
    super(Api.TownApi, false);
  }

  async getTowns() {
    const client = await this.apiClient;
    return await client.getTowns();
  }

  async getTownById(id: string) {
    const client = await this.apiClient;
    return await client.getTownById(id);
  }

  async getStreetsByTownId(id: string) {
    const client = await this.apiClient;
    return await client.getStreetsByTownId(id);
  }

  async getStreetsByTownIdAndName(id: string, name: string) {
    const client = await this.apiClient;
    return await client.getStreetsByTownIdAndName(id, name);
  }
}
