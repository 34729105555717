import * as L from "leaflet";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { GeoJSON, Marker, Popup, TileLayer, useMapEvents } from "react-leaflet";

import DashboardViewModel from "../../../../viewmodels/dashboard/dashboard-view-model";
import * as GeoJsonHelper from "../../../../../src/utils/geojsonHelper";
import { BookMarkerDTO, PolygonDTO } from "../../../../clients";
import BackDropLoading from "../../../../components/backdrop-loading";
import ProfileViewModel from "../../../../viewmodels/profile/profile-view-model";
type TReactiveMap = {
  data?: PolygonDTO[];
};

const ReactiveMap: React.FC<{ vm: DashboardViewModel; props: TReactiveMap }> = observer(({ vm, props }) => {
  const [zoomLevel, setZoomLevel] = useState(13);
  const [loading, setloading] = useState(false);
  const mapEvents = useMapEvents({
    zoomend: () => {
      setZoomLevel(mapEvents.getZoom());
    },
  });

  const editBook = async (id: string) => {
    vm.parent.selectedBook = undefined;
    setloading(true);
    if (id) {
      try {
        const result = await (vm.parent.children.find((c) => c.navigationName === "profile") as ProfileViewModel)
          .bookRepository.getBookById({ id, polygons: false, properties: false, usages: false });
        vm.parent.selectedBook = result;
        if (vm.parent.selectedBook) {
          vm.parent.editingBook = true;
          const report = vm.parent.children.find((x) => x.navigationName === "report")! as any;
          vm.parent.tryActivateChild(report);
          (report! as any).openDetail(vm.parent.selectedBook!!);
          // vm.parent.navigate('report', {})
        }
        setloading(false);
      } catch (e) {
        setloading(false);
      }
    }
  };

  return (
    <React.Fragment>
      <BackDropLoading showLoading={loading} />
      {zoomLevel < 17
        ? vm.allMarkers.map((item, index) => {
          return (
            <Marker
              icon={L.divIcon({
                iconAnchor: [13, 39],
                shadowAnchor: [13, 44],
                popupAnchor: [0, -44],
                className: "leaflet-div-icon",
                html:
                  item.energyEfficiency?.primaryEnergyNoRenewableConsumptionDTO?.qualifier === "A"
                    ? `<svg xmlns="http://www.w3.org/2000/svg" width="25.087" height="44" viewBox="0 0 25.087 36.942"><g id="Grupo_7" data-name="Grupo 7" transform="translate(-949.499 -575.567)"><path id="Unión_13" data-name="Unión 13" d="M-1736.105-476.713c-.028-.035-2.769-3.607-5.554-8.175a67.22,67.22,0,0,1-4.022-7.53,25.756,25.756,0,0,1-2.257-7.438,9.383,9.383,0,0,1,2.451-7.547,14.356,14.356,0,0,1,5.035-3.478,13.209,13.209,0,0,1,4.766-1.121,4.448,4.448,0,0,1,.812.066,17.87,17.87,0,0,1,7.106,3.063,9.092,9.092,0,0,1,3.739,9.016c-.623,4.419-3.873,10.476-6.489,14.78-2.794,4.6-5.47,8.212-5.583,8.363h0Z" transform="translate(2698 1088.068)" fill="#02913F" stroke="#02913F" stroke-width="1"/><g id="Elipse_13" data-name="Elipse 13" transform="translate(956 583)" fill="#fffcfc" stroke="#707070" stroke-width="1"><ellipse cx="6" cy="5.5" rx="6" ry="5.5" stroke="none"/><ellipse cx="6" cy="5.5" rx="5.5" ry="5" fill="none"/></g></g></svg>`
                    : item.energyEfficiency?.primaryEnergyNoRenewableConsumptionDTO?.qualifier === "B"
                      ? `<svg xmlns="http://www.w3.org/2000/svg" width="25.087" height="44" viewBox="0 0 25.087 36.942"><g id="Grupo_7" data-name="Grupo 7" transform="translate(-949.499 -575.567)"><path id="Unión_13" data-name="Unión 13" d="M-1736.105-476.713c-.028-.035-2.769-3.607-5.554-8.175a67.22,67.22,0,0,1-4.022-7.53,25.756,25.756,0,0,1-2.257-7.438,9.383,9.383,0,0,1,2.451-7.547,14.356,14.356,0,0,1,5.035-3.478,13.209,13.209,0,0,1,4.766-1.121,4.448,4.448,0,0,1,.812.066,17.87,17.87,0,0,1,7.106,3.063,9.092,9.092,0,0,1,3.739,9.016c-.623,4.419-3.873,10.476-6.489,14.78-2.794,4.6-5.47,8.212-5.583,8.363h0Z" transform="translate(2698 1088.068)" fill="#6CC52B" stroke="#6CC52B" stroke-width="1"/><g id="Elipse_13" data-name="Elipse 13" transform="translate(956 583)" fill="#fffcfc" stroke="#707070" stroke-width="1"><ellipse cx="6" cy="5.5" rx="6" ry="5.5" stroke="none"/><ellipse cx="6" cy="5.5" rx="5.5" ry="5" fill="none"/></g></g></svg>`
                      : item.energyEfficiency?.primaryEnergyNoRenewableConsumptionDTO?.qualifier === "C"
                        ? `<svg xmlns="http://www.w3.org/2000/svg" width="25.087" height="44" viewBox="0 0 25.087 36.942"><g id="Grupo_7" data-name="Grupo 7" transform="translate(-949.499 -575.567)"><path id="Unión_13" data-name="Unión 13" d="M-1736.105-476.713c-.028-.035-2.769-3.607-5.554-8.175a67.22,67.22,0,0,1-4.022-7.53,25.756,25.756,0,0,1-2.257-7.438,9.383,9.383,0,0,1,2.451-7.547,14.356,14.356,0,0,1,5.035-3.478,13.209,13.209,0,0,1,4.766-1.121,4.448,4.448,0,0,1,.812.066,17.87,17.87,0,0,1,7.106,3.063,9.092,9.092,0,0,1,3.739,9.016c-.623,4.419-3.873,10.476-6.489,14.78-2.794,4.6-5.47,8.212-5.583,8.363h0Z" transform="translate(2698 1088.068)" fill="#D7E106" stroke="#D7E106" stroke-width="1"/><g id="Elipse_13" data-name="Elipse 13" transform="translate(956 583)" fill="#fffcfc" stroke="#707070" stroke-width="1"><ellipse cx="6" cy="5.5" rx="6" ry="5.5" stroke="none"/><ellipse cx="6" cy="5.5" rx="5.5" ry="5" fill="none"/></g></g></svg>`
                        : item.energyEfficiency?.primaryEnergyNoRenewableConsumptionDTO?.qualifier === "D"
                          ? `<svg xmlns="http://www.w3.org/2000/svg" width="25.087" height="44" viewBox="0 0 25.087 36.942"><g id="Grupo_7" data-name="Grupo 7" transform="translate(-949.499 -575.567)"><path id="Unión_13" data-name="Unión 13" d="M-1736.105-476.713c-.028-.035-2.769-3.607-5.554-8.175a67.22,67.22,0,0,1-4.022-7.53,25.756,25.756,0,0,1-2.257-7.438,9.383,9.383,0,0,1,2.451-7.547,14.356,14.356,0,0,1,5.035-3.478,13.209,13.209,0,0,1,4.766-1.121,4.448,4.448,0,0,1,.812.066,17.87,17.87,0,0,1,7.106,3.063,9.092,9.092,0,0,1,3.739,9.016c-.623,4.419-3.873,10.476-6.489,14.78-2.794,4.6-5.47,8.212-5.583,8.363h0Z" transform="translate(2698 1088.068)" fill="#FFD426" stroke="#FFD426" stroke-width="1"/><g id="Elipse_13" data-name="Elipse 13" transform="translate(956 583)" fill="#fffcfc" stroke="#707070" stroke-width="1"><ellipse cx="6" cy="5.5" rx="6" ry="5.5" stroke="none"/><ellipse cx="6" cy="5.5" rx="5.5" ry="5" fill="none"/></g></g></svg>`
                          : item.energyEfficiency?.primaryEnergyNoRenewableConsumptionDTO?.qualifier === "E"
                            ? `<svg xmlns="http://www.w3.org/2000/svg" width="25.087" height="44" viewBox="0 0 25.087 36.942"><g id="Grupo_7" data-name="Grupo 7" transform="translate(-949.499 -575.567)"><path id="Unión_13" data-name="Unión 13" d="M-1736.105-476.713c-.028-.035-2.769-3.607-5.554-8.175a67.22,67.22,0,0,1-4.022-7.53,25.756,25.756,0,0,1-2.257-7.438,9.383,9.383,0,0,1,2.451-7.547,14.356,14.356,0,0,1,5.035-3.478,13.209,13.209,0,0,1,4.766-1.121,4.448,4.448,0,0,1,.812.066,17.87,17.87,0,0,1,7.106,3.063,9.092,9.092,0,0,1,3.739,9.016c-.623,4.419-3.873,10.476-6.489,14.78-2.794,4.6-5.47,8.212-5.583,8.363h0Z" transform="translate(2698 1088.068)" fill="#FF850E" stroke="#FF850E" stroke-width="1"/><g id="Elipse_13" data-name="Elipse 13" transform="translate(956 583)" fill="#fffcfc" stroke="#707070" stroke-width="1"><ellipse cx="6" cy="5.5" rx="6" ry="5.5" stroke="none"/><ellipse cx="6" cy="5.5" rx="5.5" ry="5" fill="none"/></g></g></svg>`
                            : item.energyEfficiency?.primaryEnergyNoRenewableConsumptionDTO?.qualifier === "F"
                              ? `<svg xmlns="http://www.w3.org/2000/svg" width="25.087" height="44" viewBox="0 0 25.087 36.942"><g id="Grupo_7" data-name="Grupo 7" transform="translate(-949.499 -575.567)"><path id="Unión_13" data-name="Unión 13" d="M-1736.105-476.713c-.028-.035-2.769-3.607-5.554-8.175a67.22,67.22,0,0,1-4.022-7.53,25.756,25.756,0,0,1-2.257-7.438,9.383,9.383,0,0,1,2.451-7.547,14.356,14.356,0,0,1,5.035-3.478,13.209,13.209,0,0,1,4.766-1.121,4.448,4.448,0,0,1,.812.066,17.87,17.87,0,0,1,7.106,3.063,9.092,9.092,0,0,1,3.739,9.016c-.623,4.419-3.873,10.476-6.489,14.78-2.794,4.6-5.47,8.212-5.583,8.363h0Z" transform="translate(2698 1088.068)" fill="#FF1222" stroke="#FF1222" stroke-width="1"/><g id="Elipse_13" data-name="Elipse 13" transform="translate(956 583)" fill="#fffcfc" stroke="#707070" stroke-width="1"><ellipse cx="6" cy="5.5" rx="6" ry="5.5" stroke="none"/><ellipse cx="6" cy="5.5" rx="5.5" ry="5" fill="none"/></g></g></svg>`
                              : item.energyEfficiency?.primaryEnergyNoRenewableConsumptionDTO?.qualifier === "G"
                                ? `<svg xmlns="http://www.w3.org/2000/svg" width="25.087" height="44" viewBox="0 0 25.087 36.942"><g id="Grupo_7" data-name="Grupo 7" transform="translate(-949.499 -575.567)"><path id="Unión_13" data-name="Unión 13" d="M-1736.105-476.713c-.028-.035-2.769-3.607-5.554-8.175a67.22,67.22,0,0,1-4.022-7.53,25.756,25.756,0,0,1-2.257-7.438,9.383,9.383,0,0,1,2.451-7.547,14.356,14.356,0,0,1,5.035-3.478,13.209,13.209,0,0,1,4.766-1.121,4.448,4.448,0,0,1,.812.066,17.87,17.87,0,0,1,7.106,3.063,9.092,9.092,0,0,1,3.739,9.016c-.623,4.419-3.873,10.476-6.489,14.78-2.794,4.6-5.47,8.212-5.583,8.363h0Z" transform="translate(2698 1088.068)" fill="#B51A15" stroke="#B51A15" stroke-width="1"/><g id="Elipse_13" data-name="Elipse 13" transform="translate(956 583)" fill="#fffcfc" stroke="#707070" stroke-width="1"><ellipse cx="6" cy="5.5" rx="6" ry="5.5" stroke="none"/><ellipse cx="6" cy="5.5" rx="5.5" ry="5" fill="none"/></g></g></svg>`
                                : `<svg xmlns="http://www.w3.org/2000/svg" width="25.087" height="44" viewBox="0 0 25.087 36.942"><g id="Grupo_7" data-name="Grupo 7" transform="translate(-949.499 -575.567)"><path id="Unión_13" data-name="Unión 13" d="M-1736.105-476.713c-.028-.035-2.769-3.607-5.554-8.175a67.22,67.22,0,0,1-4.022-7.53,25.756,25.756,0,0,1-2.257-7.438,9.383,9.383,0,0,1,2.451-7.547,14.356,14.356,0,0,1,5.035-3.478,13.209,13.209,0,0,1,4.766-1.121,4.448,4.448,0,0,1,.812.066,17.87,17.87,0,0,1,7.106,3.063,9.092,9.092,0,0,1,3.739,9.016c-.623,4.419-3.873,10.476-6.489,14.78-2.794,4.6-5.47,8.212-5.583,8.363h0Z" transform="translate(2698 1088.068)" fill="#0D2B52" stroke="#0D2B52" stroke-width="1"/><g id="Elipse_13" data-name="Elipse 13" transform="translate(956 583)" fill="#fffcfc" stroke="#707070" stroke-width="1"><ellipse cx="6" cy="5.5" rx="6" ry="5.5" stroke="none"/><ellipse cx="6" cy="5.5" rx="5.5" ry="5" fill="none"/></g></g></svg>`,
              })}
              position={[item?.latitude || 0, item?.longitude || 0]}
              key={index}
            >
              <Popup
                onClose={() => {
                  vm.setMarkerSelected(undefined);
                }}
              >
                <div onClick={() => editBook(item.id ?? "")} style={{ cursor: "pointer", color: "darkblue" }}>
                  {item.code}
                </div>
              </Popup>
            </Marker>
          );
        })
        : vm.allMarkers.map((item: BookMarkerDTO) =>
          item?.polygon?.map((value: PolygonDTO, index: number) => {
            return (
              value && (
                <GeoJSON
                  style={{ fillColor: "#5A779C", color: "#0D2B52", opacity: 0.8, fillOpacity: 0.6 }}
                  key={index}
                  data={GeoJsonHelper.geoJsonFromCoordinates(value ?? {}).geometry as any}
                >
                  <Popup
                    onClose={() => {
                      vm.setMarkerSelected(undefined);
                    }}
                  >
                    <div onClick={() => editBook(item.id ?? "")} style={{ cursor: "pointer", color: "darkblue" }}>
                      {item.code}
                    </div>
                  </Popup>
                </GeoJSON>
              )
            );
          })
        )}
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
    </React.Fragment>
  );
});

export default ReactiveMap;
