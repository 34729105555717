import { DialogMovicoders } from "@movicoders/movicoders-components"
import { ConfirmDialogStrings } from "@movicoders/movicoders-components"
import { observer } from "mobx-react-lite"
import React from "react"
import { Colors } from "../../../../../../constants"
import ReportViewModel from "../../../../../../viewmodels/report/report-view-model"
import { ProfileTechnician } from "../../../../forms/profile-technician"
import { TTechnicianRedactors } from "../technician-redactors"

export const TechnicianRedactorsDialog: React.FC<{ vm: ReportViewModel, props: TTechnicianRedactors }> = observer(({ vm, props }) => {
  const handleClose = () => {
    vm.profileTechnician = false
  }

  const dialogStrings: ConfirmDialogStrings = {
    title: vm.translate("report.block0.technician.addTechnicianButton"),
    content: <ProfileTechnician vm={vm} props={props} />
  }

  return (
    <DialogMovicoders
      id="technicianRedactosDialog"
      open={true}
      draggable={false}
      dialogConfig={dialogStrings}
      onConfirm={() => { }}
      onClose={() => handleClose()}
      closeWithX
      maxWidth={"md"}
      backgroundColorTitle={Colors.primary}
      fullWidth
    />
  )
})