import { interfaces } from 'inversify';
import { action, observable } from 'mobx';
import User from '../../data/entities/user-entity';
import UsersViewModel from './users-view-model';
import i18n from '../../infrastructure/localization/i18n';
import UsersRepository from '../../data/repositories/impl/user-repository';
import DetailViewModelBase from '../detail-view-model-base';

export default class UserDetailViewModel extends DetailViewModelBase<User> {
	@observable itemAux: User;
	username: string = ""

	constructor(public usersRepository: UsersRepository, public item: User, public parent: UsersViewModel) {
		super();
		this.item = item;
		this.itemAux = Object.assign({}, this.item);
		this.navigationName = item.id.toString() === '-1' ? 'new' : item.id.toString();
	}

	async onActivate() {
		super.onActivate();
		if (this.parent.loading) this.parent.loadData();
	}

	@action.bound
	async create() {}

	@action.bound
	async update() {}

	@action.bound
	async delete() {
		const response = await this.usersRepository.delete(this.item);
		if (response) {
			this.parent.items.splice(this.parent.items.indexOf(this.item), 1);
		}
	}

	protected loadDetail(): User | Promise<User | undefined> | undefined {
		return this.item;
	}

	@action.bound setItem = (item: User) => {};

	translate(text: string) {
		return i18n.t(text);
	}

  static Factory({ container }: interfaces.Context) {
		return (repository: UsersRepository, item: User) =>
			new UserDetailViewModel(repository, item, container.get(UsersViewModel));
	}
}
