/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ViewAdminTechnicianParticipationDTO
 */
export interface ViewAdminTechnicianParticipationDTO {
    /**
     * 
     * @type {string}
     * @memberof ViewAdminTechnicianParticipationDTO
     */
    block?: string;
    /**
     * 
     * @type {string}
     * @memberof ViewAdminTechnicianParticipationDTO
     */
    bookId?: string;
    /**
     * 
     * @type {string}
     * @memberof ViewAdminTechnicianParticipationDTO
     */
    cadastralparcelreference?: string;
    /**
     * 
     * @type {string}
     * @memberof ViewAdminTechnicianParticipationDTO
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ViewAdminTechnicianParticipationDTO
     */
    community?: string;
    /**
     * 
     * @type {number}
     * @memberof ViewAdminTechnicianParticipationDTO
     */
    created?: number;
    /**
     * 
     * @type {string}
     * @memberof ViewAdminTechnicianParticipationDTO
     */
    door?: string;
    /**
     * 
     * @type {string}
     * @memberof ViewAdminTechnicianParticipationDTO
     */
    floor?: string;
    /**
     * 
     * @type {number}
     * @memberof ViewAdminTechnicianParticipationDTO
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof ViewAdminTechnicianParticipationDTO
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof ViewAdminTechnicianParticipationDTO
     */
    modified?: number;
    /**
     * 
     * @type {string}
     * @memberof ViewAdminTechnicianParticipationDTO
     */
    portalNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ViewAdminTechnicianParticipationDTO
     */
    portalNumberLetter?: string;
    /**
     * 
     * @type {number}
     * @memberof ViewAdminTechnicianParticipationDTO
     */
    postalCode?: number;
    /**
     * 
     * @type {string}
     * @memberof ViewAdminTechnicianParticipationDTO
     */
    province?: string;
    /**
     * 
     * @type {string}
     * @memberof ViewAdminTechnicianParticipationDTO
     */
    secondaryPortalNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ViewAdminTechnicianParticipationDTO
     */
    stair?: string;
    /**
     * 
     * @type {string}
     * @memberof ViewAdminTechnicianParticipationDTO
     */
    status?: ViewAdminTechnicianParticipationDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ViewAdminTechnicianParticipationDTO
     */
    streetCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ViewAdminTechnicianParticipationDTO
     */
    streetName?: string;
    /**
     * 
     * @type {string}
     * @memberof ViewAdminTechnicianParticipationDTO
     */
    streetType?: string;
    /**
     * 
     * @type {string}
     * @memberof ViewAdminTechnicianParticipationDTO
     */
    technician?: string;
    /**
     * 
     * @type {string}
     * @memberof ViewAdminTechnicianParticipationDTO
     */
    town?: string;
    /**
     * 
     * @type {string}
     * @memberof ViewAdminTechnicianParticipationDTO
     */
    transactionId?: string;
}

/**
* @export
* @enum {string}
*/
export enum ViewAdminTechnicianParticipationDTOStatusEnum {
    Approved = 'APPROVED',
    Finished = 'FINISHED',
    InProgress = 'IN_PROGRESS',
    MaintenanceRequested = 'MAINTENANCE_REQUESTED',
    OnMaintenance = 'ON_MAINTENANCE',
    Open = 'OPEN',
    Rejected = 'REJECTED',
    ValidationRequested = 'VALIDATION_REQUESTED',
    WithIncidents = 'WITH_INCIDENTS'
}

export function ViewAdminTechnicianParticipationDTOFromJSON(json: any): ViewAdminTechnicianParticipationDTO {
    return ViewAdminTechnicianParticipationDTOFromJSONTyped(json, false);
}

export function ViewAdminTechnicianParticipationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ViewAdminTechnicianParticipationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'block': !exists(json, 'block') ? undefined : json['block'],
        'bookId': !exists(json, 'bookId') ? undefined : json['bookId'],
        'cadastralparcelreference': !exists(json, 'cadastralparcelreference') ? undefined : json['cadastralparcelreference'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'community': !exists(json, 'community') ? undefined : json['community'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'door': !exists(json, 'door') ? undefined : json['door'],
        'floor': !exists(json, 'floor') ? undefined : json['floor'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'modified': !exists(json, 'modified') ? undefined : json['modified'],
        'portalNumber': !exists(json, 'portalNumber') ? undefined : json['portalNumber'],
        'portalNumberLetter': !exists(json, 'portalNumberLetter') ? undefined : json['portalNumberLetter'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'province': !exists(json, 'province') ? undefined : json['province'],
        'secondaryPortalNumber': !exists(json, 'secondaryPortalNumber') ? undefined : json['secondaryPortalNumber'],
        'stair': !exists(json, 'stair') ? undefined : json['stair'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'streetCode': !exists(json, 'streetCode') ? undefined : json['streetCode'],
        'streetName': !exists(json, 'streetName') ? undefined : json['streetName'],
        'streetType': !exists(json, 'streetType') ? undefined : json['streetType'],
        'technician': !exists(json, 'technician') ? undefined : json['technician'],
        'town': !exists(json, 'town') ? undefined : json['town'],
        'transactionId': !exists(json, 'transactionId') ? undefined : json['transactionId'],
    };
}

export function ViewAdminTechnicianParticipationDTOToJSON(value?: ViewAdminTechnicianParticipationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'block': value.block,
        'bookId': value.bookId,
        'cadastralparcelreference': value.cadastralparcelreference,
        'code': value.code,
        'community': value.community,
        'created': value.created,
        'door': value.door,
        'floor': value.floor,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'modified': value.modified,
        'portalNumber': value.portalNumber,
        'portalNumberLetter': value.portalNumberLetter,
        'postalCode': value.postalCode,
        'province': value.province,
        'secondaryPortalNumber': value.secondaryPortalNumber,
        'stair': value.stair,
        'status': value.status,
        'streetCode': value.streetCode,
        'streetName': value.streetName,
        'streetType': value.streetType,
        'technician': value.technician,
        'town': value.town,
        'transactionId': value.transactionId,
    };
}

