import { Button, Icon, Popover, TableBasic, TypographyTheme } from "@movicoders/movicoders-components";
import { IconsList } from "@movicoders/movicoders-components";
import { Autocomplete, CssBaseline, Grid, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { AgentParticipationDTO, AgentParticipationDTORoleEnum } from "../../../../../../clients";
import { Colors } from "../../../../../../constants";
import ReportViewModel from "../../../../../../viewmodels/report/report-view-model";
import { ReportStyle } from "../../../../report-style";
import { UnbindAgent } from "../../buildingIdentification/components/DeleteDialog";
import TypographyTitle from "../../../../../../components/typography-title";

export const AddButtonsSection: React.FC<{ vm: ReportViewModel }> = observer(({ vm }) => {
  const classes = ReportStyle();
  const [userType] = useState(vm.translate("report.block0.user.owner"));
  const [agentToUnbind, setAgentToUnbind] = React.useState<null | string | undefined>(null);

  const { enqueueSnackbar } = useSnackbar();
  const handleOpenSnackBar = (snackKey: string, variants: undefined | "default" | "error" | "success" | "warning" | "info") => {
    enqueueSnackbar(snackKey, { variant: variants, preventDuplicate: true, key: snackKey, autoHideDuration: 3000 });
  };

  const addUser = () => {
    vm.addUser = true;
  };

  const unbindAgent = async () => {
    try {
      if (!agentToUnbind) return;
      vm.loadingBook = true;

      await vm.bookRepository.deleteAgentById({ id: vm.userBook.id ?? "", agentId: agentToUnbind }).then((res) => {
        vm.userBook.agents = vm.userBook.agents?.filter((agent) => agent.id !== agentToUnbind);
      });

      handleOpenSnackBar(vm.translate("report.agents.unbind_agent.success"), "success");
      setAgentToUnbind(null);
    } catch (err) {
      console.log(err);
      handleOpenSnackBar(vm.translate("report.agents.unbind_agent.error"), "error");
      setAgentToUnbind(null);
      vm.loadingBook = false;
    }
    vm.loadingBook = false;
  };

  const checkRole = (role: AgentParticipationDTORoleEnum): string => {
    switch (role) {
      case AgentParticipationDTORoleEnum.Representative:
        return vm.translate("report.block0.agents.representative");
      case AgentParticipationDTORoleEnum.Owner:
        return vm.translate("report.block0.user.owner");
      case AgentParticipationDTORoleEnum.HomeownersPresident:
        return vm.translate("report.block0.user.pres");
      case AgentParticipationDTORoleEnum.PropertyManager:
        return vm.translate("report.block0.user.admin");
      default:
        return "";
    }
  };

  const optionPopover = (params: any) => {
    return (
      <Popover
        content={[
          {
            id: "editTechnicianParticipation",
            label: vm.translate("report.block0.agent_edit.edit"),
            action: () => {
              vm.currentAgent = params.row;
              vm.addUser = true;
            },
          },
          {
            id: "deleteTechnicianParticipation",
            label: vm.translate("report.block0.agent_edit.remove_"),
            action: () => {
              setAgentToUnbind(params.row.id);
            },
          },
        ]}
        iconButton={{
          id: params.row.id,
          icon: <Icon element={IconsList.MoreHoriz} />,
          backgroundColor: "transparent",
          color: "black",
        }}
      />
    );
  };

  return (
    <React.Fragment>
      <Grid item xs={12} md={12} className={classes.report_generalDataGrid}>
        <Grid item xs={12} style={{ display: "inline-flex", marginTop: "1rem" }}>
          <Grid item xs={12} md={12} className={classes.report_addUsers}>
            <Grid container item xs={12} md={12} alignItems="center" direction="row">
              <TypographyTheme
                id="addUsersButtonsTitle"
                classTypography={classes.report_addTechicianmini}
                color={Colors.textPrimary}
                text={vm.translate("report.block0.agent.addAgentButton")}
              />
              <Button
                id="addUsersButton"
                onClick={() => addUser()}
                width={120}
                backgroundColor={Colors.buttonPrimary}
                icon={<Icon element={IconsList.PersonAdd} />}
                iconPosition="start"
                classButton={classes.report_addButtonMini}
                text=""
                disabled={userType === undefined}
              />
            </Grid>
          </Grid>
        </Grid>
        <div style={{ height: 10 }} />
      </Grid>
      {
        <Grid item xs={12} md={12} mt={5}>
          <CssBaseline />
          <TypographyTitle id="cadastralReferenceTitle" title={vm.translate("report.block0.user.owner")} />
          <TableBasic
            disableCheckboxes
            rowsPerPageOptions={[5]}
            data={
              vm.userBook.agents?.filter(
                (agent) => agent.role === AgentParticipationDTORoleEnum.Owner || agent.role === AgentParticipationDTORoleEnum.HomeownersPresident
              ) ?? []
            }
            headerColor={Colors.primary}
            columns={[
              {
                field: "name",
                headerAlign: "left",
                align: "left",
                flex: 0.7,
                headerName: vm.translate("report.block0.agents.table.name"),
                filterable: false,
                renderCell: (params: any) => {
                  return (
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <Typography id="agentName" style={{ fontSize: 12 }}>
                        {params.row.name}
                      </Typography>
                    </div>
                  );
                },
                sortable: false,
              },
              {
                field: "email",
                headerAlign: "left",
                align: "left",
                headerName: vm.translate("report.block1.email"),
                flex: 1,
                renderCell: (params: any) => {
                  return (
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <Typography id="agentemail" style={{ fontSize: 12 }}>
                        {params.row.email}
                      </Typography>
                    </div>
                  );
                },
                filterable: false,
                sortable: false,
              },
              {
                field: "idNumber",
                headerAlign: "left",
                align: "left",
                headerName: vm.translate("report.block0.nif"),
                flex: 0.5,
                renderCell: (params: any) => {
                  return (
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <Typography id="agentidNumber" style={{ fontSize: 12 }}>
                        {params.row.idNumber}
                      </Typography>
                    </div>
                  );
                },
                filterable: false,
                sortable: false,
              },
              {
                field: "mobilePhone",
                headerAlign: "left",
                align: "left",
                headerName: vm.translate("report.block1.mobile"),
                flex: 0.4,
                renderCell: (params: any) => {
                  return (
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <Typography id="agentmobile" style={{ fontSize: 12 }}>
                        {params.row.mobilePhone}
                      </Typography>
                    </div>
                  );
                },
                filterable: false,
                sortable: false,
              },
              {
                field: "role",
                headerAlign: "left",
                align: "left",
                headerName: vm.translate("report.block0.agents.table.role"),
                flex: 1,
                renderCell: (params: any) => {
                  return (
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <Typography id="agentrole" style={{ fontSize: 12 }}>
                        {checkRole(params.row.role)}
                      </Typography>
                    </div>
                  );
                },
                filterable: false,
                sortable: false,
              },
              {
                field: "participation",
                headerAlign: "left",
                align: "left",
                headerName: vm.translate("report.block0.agents.table.participation"),
                flex: 0.5,
                renderCell: (params: any) => {
                  return (
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <Typography id="agentParticipation" style={{ fontSize: 12 }}>
                        {params.row.participation}
                      </Typography>
                    </div>
                  );
                },
                filterable: false,
                sortable: false,
              },
              {
                headerAlign: "left",
                align: "left",
                field: "",
                hideSortIcons: true,
                headerName: "",
                flex: 0.01,
                disableColumnMenu: true,
                disableReorder: true,
                sortable: false,
                renderCell: optionPopover,
              },
            ]}
            locale={vm.configurationService.language}
          />
          <Agents agents={vm.userBook.agents ?? []} checkRole={checkRole} vm={vm} optionPopover={optionPopover} />
        </Grid>
      }
      {agentToUnbind && <UnbindAgent onClose={() => setAgentToUnbind(null)} onDelete={unbindAgent} vm={vm} />}
    </React.Fragment>
  );
});

const Agents: React.FC<{
  agents: AgentParticipationDTO[];
  checkRole: (role: AgentParticipationDTORoleEnum) => string;
  vm: ReportViewModel;
  optionPopover: (params: any) => React.ReactNode;
}> = ({ agents, checkRole, vm, optionPopover }) => {
  const filteredAgents =
    agents?.filter((agent) => agent.role !== AgentParticipationDTORoleEnum.Owner && agent.role !== AgentParticipationDTORoleEnum.HomeownersPresident) ?? [];

  const columns: any = [
    {
      field: "name",
      headerAlign: "left",
      align: "left",
      flex: 0.7,
      headerName: vm.translate("report.block0.agents.table.name"),
      filterable: false,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Typography id="agentName" style={{ fontSize: 12 }}>
              {params.row.name}
            </Typography>
          </div>
        );
      },
      sortable: false,
    },
    {
      field: "email",
      headerAlign: "left",
      align: "left",
      headerName: vm.translate("report.block1.email"),
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Typography id="agentName" style={{ fontSize: 12 }}>
              {params.row.email}
            </Typography>
          </div>
        );
      },
      filterable: false,
      sortable: false,
    },
    {
      field: "idNumber",
      headerAlign: "left",
      align: "left",
      headerName: vm.translate("report.block0.nif"),
      flex: 0.5,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Typography id="agentName" style={{ fontSize: 12 }}>
              {params.row.idNumber}
            </Typography>
          </div>
        );
      },
      filterable: false,
      sortable: false,
    },
    {
      field: "mobilePhone",
      headerAlign: "left",
      align: "left",
      headerName: vm.translate("report.block1.mobile"),
      flex: 0.4,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Typography id="agentName" style={{ fontSize: 12 }}>
              {params.row.mobilePhone}
            </Typography>
          </div>
        );
      },
      filterable: false,
      sortable: false,
    },
    {
      field: "role",
      headerAlign: "left",
      align: "left",
      headerName: vm.translate("report.block0.agents.table.role"),
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Typography id="agentName" style={{ fontSize: 12 }}>
              {checkRole(params.row.role)}
            </Typography>
          </div>
        );
      },
      filterable: false,
      sortable: false,
    },
    {
      headerAlign: "left",
      align: "left",
      field: "",
      hideSortIcons: true,
      headerName: "",
      flex: 0.01,
      disableColumnMenu: true,
      disableReorder: true,
      sortable: false,
      renderCell: optionPopover,
    },
  ];

  return (
    <Grid mt={5}>
      <TypographyTitle id="cadastralReferenceTitle" title={"Agentes"} />
      <TableBasic
        disableCheckboxes
        rowsPerPageOptions={[5]}
        data={filteredAgents}
        headerColor={Colors.primary}
        columns={columns}
        locale={vm.configurationService.language}
      />
    </Grid>
  );
};
