/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CollegeAccountDTO,
    CollegeAccountDTOFromJSON,
    CollegeAccountDTOToJSON,
    CollegeDTO,
    CollegeDTOFromJSON,
    CollegeDTOToJSON,
    GatewayConfigurationDTO,
    GatewayConfigurationDTOFromJSON,
    GatewayConfigurationDTOToJSON,
    MovicodersBadRequestException,
    MovicodersBadRequestExceptionFromJSON,
    MovicodersBadRequestExceptionToJSON,
    MovicodersNotFoundException,
    MovicodersNotFoundExceptionFromJSON,
    MovicodersNotFoundExceptionToJSON,
    MovicodersServiceException,
    MovicodersServiceExceptionFromJSON,
    MovicodersServiceExceptionToJSON,
} from '../models';

export interface GetAllCollegeRequest {
    offset?: number;
    limit?: number;
}

export interface GetCollegeByIDRequest {
    id: string;
}

export interface SearchCollegeRequest {
    name?: string;
    type?: string;
}

export interface UpdateCollegeRequest {
    collegeId: string;
    gatewayConfigurationDTO?: GatewayConfigurationDTO;
}

/**
 * 
 */
export class CollegesApi extends runtime.BaseAPI {

    /**
     * Returns all the College.
     * Get all College objects.
     */
    async getAllCollegeRaw(requestParameters: GetAllCollegeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<CollegeDTO>>> {
        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/colleges`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CollegeDTOFromJSON));
    }

    /**
     * Returns all the College.
     * Get all College objects.
     */
    async getAllCollege(requestParameters: GetAllCollegeRequest, initOverrides?: RequestInit): Promise<Array<CollegeDTO>> {
        const response = await this.getAllCollegeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns one College object by id.
     * Get a College object by id.
     */
    async getCollegeByIDRaw(requestParameters: GetCollegeByIDRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CollegeDTO>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCollegeByID.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/colleges/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollegeDTOFromJSON(jsonValue));
    }

    /**
     * Returns one College object by id.
     * Get a College object by id.
     */
    async getCollegeByID(requestParameters: GetCollegeByIDRequest, initOverrides?: RequestInit): Promise<CollegeDTO> {
        const response = await this.getCollegeByIDRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns one College object by name.
     * Get a College object by name.
     */
    async searchCollegeRaw(requestParameters: SearchCollegeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CollegeDTO>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/colleges/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollegeDTOFromJSON(jsonValue));
    }

    /**
     * Returns one College object by name.
     * Get a College object by name.
     */
    async searchCollege(requestParameters: SearchCollegeRequest, initOverrides?: RequestInit): Promise<CollegeDTO> {
        const response = await this.searchCollegeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Takes an existing College object, updates it, and returns the College.
     * Update an existing College account with gateway config.
     */
    async updateCollegeRaw(requestParameters: UpdateCollegeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CollegeAccountDTO>> {
        if (requestParameters.collegeId === null || requestParameters.collegeId === undefined) {
            throw new runtime.RequiredError('collegeId','Required parameter requestParameters.collegeId was null or undefined when calling updateCollege.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/colleges/account/{collegeId}`.replace(`{${"collegeId"}}`, encodeURIComponent(String(requestParameters.collegeId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: GatewayConfigurationDTOToJSON(requestParameters.gatewayConfigurationDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CollegeAccountDTOFromJSON(jsonValue));
    }

    /**
     * Takes an existing College object, updates it, and returns the College.
     * Update an existing College account with gateway config.
     */
    async updateCollege(requestParameters: UpdateCollegeRequest, initOverrides?: RequestInit): Promise<CollegeAccountDTO> {
        const response = await this.updateCollegeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
