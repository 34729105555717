import { createStyles, makeStyles } from '@mui/styles'
import { Colors, FontSizes } from './../../constants'

export const RadioGroupStyles = makeStyles(() => createStyles({
  radioGroup_label: {
    marginLeft: "-7px",
    fontSize: FontSizes.h4,
    color: Colors.textSecondaryHeaders +" !important",
    '& .MuiFormControlLabel-root': {
    },
    '& .MuiTypography-body1': {
      fontSize: FontSizes.p,
      color: Colors.textPrimary +" !important",
    }
  },
  radioGroup_label_withoutMarginLeft: {
    fontSize: FontSizes.h4,
    color: Colors.textSecondaryHeaders +" !important",
    '& .MuiFormControlLabel-root': {
    },
    '& .MuiTypography-body1': {
      fontSize: FontSizes.p,
      color: Colors.textPrimary +" !important",
    }
  }
}))