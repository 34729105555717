import { ProfileDTO, UsersApi as UsersApiService } from "../../../clients";
import { BaseAPI, Configuration } from '../../../clients/runtime';
import IUsersApi from "../iusers-api";

export class UsersApi extends BaseAPI implements IUsersApi {
    apiClient: UsersApiService;

    constructor(configuration?: Configuration) {
        super(configuration);
        this.apiClient = new UsersApiService(configuration);
    }

    async getTechnicianByCredentialsID(): Promise<ProfileDTO> {
        const response = await this.apiClient.getProfileByCredentials();
        return response;
    }
}