/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MultimediaDTO
 */
export interface MultimediaDTO {
    /**
     * 
     * @type {string}
     * @memberof MultimediaDTO
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof MultimediaDTO
     */
    extension?: string;
    /**
     * 
     * @type {string}
     * @memberof MultimediaDTO
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof MultimediaDTO
     */
    length?: number;
    /**
     * 
     * @type {string}
     * @memberof MultimediaDTO
     */
    mediaType?: string;
    /**
     * 
     * @type {string}
     * @memberof MultimediaDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MultimediaDTO
     */
    url?: string;
}

export function MultimediaDTOFromJSON(json: any): MultimediaDTO {
    return MultimediaDTOFromJSONTyped(json, false);
}

export function MultimediaDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultimediaDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'extension': !exists(json, 'extension') ? undefined : json['extension'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'length': !exists(json, 'length') ? undefined : json['length'],
        'mediaType': !exists(json, 'mediaType') ? undefined : json['mediaType'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function MultimediaDTOToJSON(value?: MultimediaDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'extension': value.extension,
        'id': value.id,
        'length': value.length,
        'mediaType': value.mediaType,
        'name': value.name,
        'url': value.url,
    };
}

