/* tslint:disable */
/* eslint-disable */
/**
 * Proxy microservice (dev)
 * Proxy microservice
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnergyEfficiencyImprovementDTO,
    EnergyEfficiencyImprovementDTOFromJSON,
    EnergyEfficiencyImprovementDTOFromJSONTyped,
    EnergyEfficiencyImprovementDTOToJSON,
    FireSafetyImprovementDTO,
    FireSafetyImprovementDTOFromJSON,
    FireSafetyImprovementDTOFromJSONTyped,
    FireSafetyImprovementDTOToJSON,
    HealthinessImprovementDTO,
    HealthinessImprovementDTOFromJSON,
    HealthinessImprovementDTOFromJSONTyped,
    HealthinessImprovementDTOToJSON,
    ImprovementDTO,
    ImprovementDTOFromJSON,
    ImprovementDTOFromJSONTyped,
    ImprovementDTOToJSON,
    MediaDTO,
    MediaDTOFromJSON,
    MediaDTOFromJSONTyped,
    MediaDTOToJSON,
    NoiseImprovementDTO,
    NoiseImprovementDTOFromJSON,
    NoiseImprovementDTOFromJSONTyped,
    NoiseImprovementDTOToJSON,
    OtherImprovementDTO,
    OtherImprovementDTOFromJSON,
    OtherImprovementDTOFromJSONTyped,
    OtherImprovementDTOToJSON,
    PhaseDTO,
    PhaseDTOFromJSON,
    PhaseDTOFromJSONTyped,
    PhaseDTOToJSON,
    SecurityImprovementDTO,
    SecurityImprovementDTOFromJSON,
    SecurityImprovementDTOFromJSONTyped,
    SecurityImprovementDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ImprovementActionDTO
 */
export interface ImprovementActionDTO {
    /**
     * 
     * @type {EnergyEfficiencyImprovementDTO}
     * @memberof ImprovementActionDTO
     */
    enEfficiencyImprovement?: EnergyEfficiencyImprovementDTO;
    /**
     * 
     * @type {FireSafetyImprovementDTO}
     * @memberof ImprovementActionDTO
     */
    fireSafetyImprovement?: FireSafetyImprovementDTO;
    /**
     * 
     * @type {HealthinessImprovementDTO}
     * @memberof ImprovementActionDTO
     */
    healthinessImprovement?: HealthinessImprovementDTO;
    /**
     * 
     * @type {string}
     * @memberof ImprovementActionDTO
     */
    id?: string;
    /**
     * 
     * @type {Array<MediaDTO>}
     * @memberof ImprovementActionDTO
     */
    improvementActionMedias?: Array<MediaDTO>;
    /**
     * 
     * @type {Array<ImprovementDTO>}
     * @memberof ImprovementActionDTO
     */
    improvements?: Array<ImprovementDTO>;
    /**
     * 
     * @type {NoiseImprovementDTO}
     * @memberof ImprovementActionDTO
     */
    noiseImprovement?: NoiseImprovementDTO;
    /**
     * 
     * @type {OtherImprovementDTO}
     * @memberof ImprovementActionDTO
     */
    otherImprovement?: OtherImprovementDTO;
    /**
     * 
     * @type {Array<PhaseDTO>}
     * @memberof ImprovementActionDTO
     */
    phases?: Array<PhaseDTO>;
    /**
     * 
     * @type {SecurityImprovementDTO}
     * @memberof ImprovementActionDTO
     */
    securityImprovement?: SecurityImprovementDTO;
    /**
     * 
     * @type {string}
     * @memberof ImprovementActionDTO
     */
    summary?: string;
    /**
     * 
     * @type {number}
     * @memberof ImprovementActionDTO
     */
    timestamp?: number;
}

export function ImprovementActionDTOFromJSON(json: any): ImprovementActionDTO {
    return ImprovementActionDTOFromJSONTyped(json, false);
}

export function ImprovementActionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImprovementActionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enEfficiencyImprovement': !exists(json, 'enEfficiencyImprovement') ? undefined : EnergyEfficiencyImprovementDTOFromJSON(json['enEfficiencyImprovement']),
        'fireSafetyImprovement': !exists(json, 'fireSafetyImprovement') ? undefined : FireSafetyImprovementDTOFromJSON(json['fireSafetyImprovement']),
        'healthinessImprovement': !exists(json, 'healthinessImprovement') ? undefined : HealthinessImprovementDTOFromJSON(json['healthinessImprovement']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'improvementActionMedias': !exists(json, 'improvementActionMedias') ? undefined : ((json['improvementActionMedias'] as Array<any>).map(MediaDTOFromJSON)),
        'improvements': !exists(json, 'improvements') ? undefined : ((json['improvements'] as Array<any>).map(ImprovementDTOFromJSON)),
        'noiseImprovement': !exists(json, 'noiseImprovement') ? undefined : NoiseImprovementDTOFromJSON(json['noiseImprovement']),
        'otherImprovement': !exists(json, 'otherImprovement') ? undefined : OtherImprovementDTOFromJSON(json['otherImprovement']),
        'phases': !exists(json, 'phases') ? undefined : ((json['phases'] as Array<any>).map(PhaseDTOFromJSON)),
        'securityImprovement': !exists(json, 'securityImprovement') ? undefined : SecurityImprovementDTOFromJSON(json['securityImprovement']),
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'timestamp': !exists(json, 'timestamp') ? undefined : json['timestamp'],
    };
}

export function ImprovementActionDTOToJSON(value?: ImprovementActionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enEfficiencyImprovement': EnergyEfficiencyImprovementDTOToJSON(value.enEfficiencyImprovement),
        'fireSafetyImprovement': FireSafetyImprovementDTOToJSON(value.fireSafetyImprovement),
        'healthinessImprovement': HealthinessImprovementDTOToJSON(value.healthinessImprovement),
        'id': value.id,
        'improvementActionMedias': value.improvementActionMedias === undefined ? undefined : ((value.improvementActionMedias as Array<any>).map(MediaDTOToJSON)),
        'improvements': value.improvements === undefined ? undefined : ((value.improvements as Array<any>).map(ImprovementDTOToJSON)),
        'noiseImprovement': NoiseImprovementDTOToJSON(value.noiseImprovement),
        'otherImprovement': OtherImprovementDTOToJSON(value.otherImprovement),
        'phases': value.phases === undefined ? undefined : ((value.phases as Array<any>).map(PhaseDTOToJSON)),
        'securityImprovement': SecurityImprovementDTOToJSON(value.securityImprovement),
        'summary': value.summary,
        'timestamp': value.timestamp,
    };
}

