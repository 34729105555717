import { Api } from "../../../infrastructure/data/api-client";
import { BaseRepository } from "../../../infrastructure/data/repositories/base-repository";
import ICommunityApi from "../icommunity-api";

export default class CommunityRepository extends BaseRepository<ICommunityApi> {
  constructor() {
    super(Api.CommunityApi, false);
  }

  async getAllCommunity() {
    const client = await this.apiClient;
    return await client.getCommunityList();
  }

  async getProvinceByCommunityCode(code: string) {
    const client = await this.apiClient;
    return await client.getProvincesByCommunityCode(code);
  }
}
