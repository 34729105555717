import { createElement } from "react";
/* eslint-disable no-restricted-globals */
export enum PDF_STATUS {
  IN_PROGRESS = "IN_PROGRESS",
  DONE = "DONE",
  ERROR = "ERROR",
}

self.onmessage = async function (event: MessageEvent) {
  try {
    const data = JSON.parse(event.data);

    if (data.status === PDF_STATUS.IN_PROGRESS) {
      const { pdf } = await import("@react-pdf/renderer");
      const { PDF } = await import("../../../pdf-v2");

      // @ts-ignore
      pdf(createElement(PDF, { data: data.printingBook, images: data.compressedImages }))
        .toBlob()
        .then((res: any) => {
          const PDFURL: any = URL.createObjectURL(res);
          // @ts-ignore
          self.postMessage(JSON.stringify({ status: PDF_STATUS.DONE, blob: PDFURL }));
        });
    }
  } catch (e) {}
};

export {};
